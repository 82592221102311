import { getMomentTz } from "@dexteel/mesf-core";
import { isNumeric } from "../../../../../utils/numeric-utils";
import { ProcessData } from "../../../models/ProcessData";
import { useCastersContext } from "../../CastersContext";
import { useSaveProcess } from "../../hooks/useSaveProcess";
const moment = getMomentTz();

export const useFormHandlers = ({
	reloadProcess,
	refreshData,
}: { reloadProcess: Function; refreshData: Function }) => {
	const { state, actions } = useCastersContext();

	const process = state.processData || ({} as ProcessData);

	const { saveProcessData, saveHeat, saveLadleHeat, saveProcess } =
		useSaveProcess();

	const handleBooleanValueChanged = (field: string, value: any) => {
		actions.updateProcessData({ field, value, clear: true });
		saveProcessData(field, value);
	};
	const handleOptionValueChanged = (field: string, value: number) => {
		actions.updateProcessData({ field, value, clear: true });
		saveProcessData(field, value);
	};
	const handleLadleValueChanged = (field: string, value: number) => {
		actions.updateProcessData({
			field,
			value: Number(value).valueOf(),
			clear: true,
		});
		saveLadleHeat(field, value);
	};
	const handleNumericValueChanged = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.updateProcessData({ field, value: Number(value).valueOf() });
	};
	const handleOnBlurTextValue = (field: string, value: string) => {
		if (value.trim() === "") return;
		actions.clearFocusField();
		saveProcessData(field, value);
	};
	const handleOnBlurNumericValueForProcess = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.clearFocusField();
		saveProcessData(field, value);
	};
	const handleOnBlurNumericValueForHeat = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.clearFocusField();
		saveHeat(field, value);
	};
	const handleOnBlurBooleanValueForHeat = (field: string, value: boolean) => {
		actions.clearFocusField();
		saveHeat(field, !!value).then(async () => {
			await refreshData();
		});
	};
	const handleBooleanCountersChanged = (field: string, value: boolean) => {
		actions.updateProcessData({ field, value, clear: true });
		const checked = value ? 1 : null;
		saveProcessData(field, checked);
	};

	const handleProcessUpdate = async () => {
		actions.clearFocusField();
		const ladleOpen = moment.utc(process.LadleOpen);
		let ladleClose = process.LadleClose ? moment.utc(process.LadleClose) : null;

		if (ladleClose) {
			ladleClose.set("year", ladleOpen.get("year"));
			ladleClose.set("month", ladleOpen.get("month"));
			ladleClose.set("date", ladleOpen.get("date"));
			if (ladleClose < ladleOpen) {
				ladleClose.add(1, "days");
			}
		}

		const ladleCloseDate = ladleClose?.toDate() || null;

		const success = await saveProcess(
			process.ProcessId!,
			ladleOpen.toDate(),
			ladleCloseDate,
		);
		await reloadProcess(process.ProcessId!);
	};

	const handleOnFocus = (field: string) =>
		actions.setFocusField({ entity: "PROCESS", index: -1, field });
	const handleValueChanged = (field: string, value: any) =>
		actions.updateProcessData({ field, value });

	return {
		handleBooleanValueChanged,
		handleBooleanCountersChanged,
		handleOptionValueChanged,
		handleLadleValueChanged,
		handleNumericValueChanged,
		handleOnBlurTextValue,
		handleOnBlurNumericValueForProcess,
		handleOnBlurNumericValueForHeat,
		handleOnBlurBooleanValueForHeat,
		handleProcessUpdate,
		handleOnFocus,
		handleValueChanged,
	};
};
