import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		checkbox: {
			userSelect: "none",
		},
		modalBody: {},
		title: {
			fontSize: 15,
		},
		chip: {
			margin: 2,
		},
		errorLabel: {
			fontSize: 12,
			color: "#F44336",
		},
		infoLabel: {
			fontSize: 12,
			color: "#757575",
		},
		autocompleteField: {
			display: "flex",
			alignItems: "center",
			paddingTop: 0,
			paddingBottom: 0,
		},
	}),
);
