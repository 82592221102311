import { CatalogMoldSizeProvider } from "../mold-sizes/context/CatalogMoldSizeContext";
import { CatalogMoldProvider } from "./context/CatalogMoldContext";
import { CatalogMoldPage } from "./pages/CatalogMoldPage";

type Props = {};
const CatalogMold = (props: Props) => {
	return (
		<CatalogMoldSizeProvider>
			<CatalogMoldProvider>
				<CatalogMoldPage />
			</CatalogMoldProvider>
		</CatalogMoldSizeProvider>
	);
};
export default CatalogMold;
