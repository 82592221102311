import React, { useEffect, useRef } from "react";
import { BarTask } from "../../types/bar-task";
import { Asset } from "../../types/public-types";

export type AssetListProps = {
	headerHeight: number;
	rowWidth: number;
	fontFamily: string;
	fontSize: string;
	rowHeight: number;
	ganttHeight: number;
	scrollY: number;
	locale: string;
	assets: Asset[];
	assetListRef: React.RefObject<HTMLDivElement>;
	horizontalContainerClass?: string;
	selectedAsset: BarTask | undefined;
	setSelectedAsset: (asset: string) => void;
	AssetListHeader: React.FC<{
		headerHeight: number;
		rowWidth: number;
		fontFamily: string;
		fontSize: string;
	}>;
	AssetListTable: React.FC<{
		rowHeight: number;
		rowWidth: number;
		fontFamily: string;
		fontSize: string;
		locale: string;
		assets: Asset[];
		selectedAssetId: string;
		setSelectedAsset: (assetId: string) => void;
	}>;
};

export const AssetList: React.FC<AssetListProps> = ({
	headerHeight,
	fontFamily,
	fontSize,
	rowWidth,
	rowHeight,
	scrollY,
	assets,
	selectedAsset,
	setSelectedAsset,
	locale,
	ganttHeight,
	assetListRef,
	horizontalContainerClass,
	AssetListHeader,
	AssetListTable,
}) => {
	const horizontalContainerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (horizontalContainerRef.current) {
			horizontalContainerRef.current.scrollTop = scrollY;
		}
	}, [scrollY]);

	const headerProps = {
		headerHeight,
		fontFamily,
		fontSize,
		rowWidth,
	};
	const selectedAssetId = selectedAsset ? selectedAsset.id : "";
	const tableProps = {
		rowHeight,
		rowWidth,
		fontFamily,
		fontSize,
		assets,
		locale,
		selectedAssetId: selectedAssetId,
		setSelectedAsset,
	};
	const viewBoxHeaderProp =
		"0 0 " + tableProps.rowWidth + " " + headerProps.headerHeight;
	const viewBoxListProp =
		"0 0 " +
		tableProps.rowWidth +
		" " +
		tableProps.assets.length * tableProps.rowHeight;
	return (
		<div ref={assetListRef} style={{ width: tableProps.rowWidth }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fontFamily={tableProps.fontFamily}
				viewBox={viewBoxHeaderProp}
			>
				<AssetListHeader {...headerProps} />
			</svg>
			<div
				ref={horizontalContainerRef}
				className={horizontalContainerClass}
				style={
					ganttHeight
						? { height: ganttHeight, width: "100%" }
						: { width: "100%" }
				}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fontFamily={tableProps.fontFamily}
					viewBox={viewBoxListProp}
				>
					<AssetListTable {...tableProps} />
				</svg>
			</div>
		</div>
	);
};
