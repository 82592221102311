import React from "react";
import { getCellDataValue } from "../../helpers/table-helper";
import { BodyCellSetup } from "../../types/table-body";
import { HeaderCellSetup } from "../../types/table-header";
import { ReportBodyCell, ReportBodyFixedCell } from "./body-cell";

export type ReportBodyTransposedRowProps = {
	header: HeaderCellSetup;
	rows: any[];
	rowSpan: number;
	colSpan: number;
	level: number;
	bodyCells: BodyCellSetup[];
};

export const ReportBodyTransposedRow: React.FC<
	ReportBodyTransposedRowProps
> = ({ header, rows, rowSpan, colSpan, level, bodyCells }) => {
	const cells: any[] = [];
	const getRowContent = (
		header: HeaderCellSetup,
		cells: any[],
		subLevel: number,
	) => {
		const reportBodyFixedCellProps = {
			value: header.Name,
			colSpan,
			rowSpan: rowSpan - subLevel,
			isRowHeader: true,
			isFirstCell: level === 1 && subLevel === 0,
		};
		cells.push([
			<ReportBodyFixedCell {...reportBodyFixedCellProps} key={"cell0"} />,
		]);
		if ((header.children || []).length === 0) {
			// last child , starting to bind data
			rows.forEach((row, index) => {
				const bodyCell = bodyCells.find((x) => x.field === header.Field);
				if (bodyCell === undefined) {
					cells.push(<ReportBodyFixedCell key={index} />);
				} else {
					const data = getCellDataValue(bodyCell.field, row);
					const reportBodyCellProps = {
						cell: bodyCell,
						data,
						cellClass: "",
					};
					cells.push(<ReportBodyCell key={index} {...reportBodyCellProps} />);
				}
			});
		} else {
			if ((header.children || [])[level - 1] !== undefined) {
				subLevel++;
				getRowContent((header.children || [])[level - 1], cells, subLevel);
			}
		}
	};
	getRowContent(header, cells, 0);
	return <tr>{cells}</tr>;
};
