import { ReactNode, createContext, useContext, useEffect } from "react";
import { useComplexState } from "use-complex-state";
import { EAFProcessesReducer } from "../reducers/EAFProcessesReducer";

const EAFProcessesContext = createContext({
	state: EAFProcessesReducer.getInitialState(),
	actions: EAFProcessesReducer.actions,
});

export const useEAFProcessesContext = () => useContext(EAFProcessesContext);

export const EAFProcessesProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: EAFProcessesReducer.getInitialState(),
		reducers: EAFProcessesReducer.caseReducers,
	});

	return (
		<EAFProcessesContext.Provider value={{ state, actions }}>
			{children}
		</EAFProcessesContext.Provider>
	);
};
