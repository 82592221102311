import { useContextMenuMESF } from "@dexteel/mesf-core";
import { Button, Grid, Typography } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import {
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { useMaterialsOptionsFunctions } from "../hooks/useMaterialsOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { CreateMaterial } from "./CreateMaterial";
import { DeleteMaterial } from "./DeleteMaterial";
import { EditMaterial } from "./EditMaterial";
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-cell-label": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "2vh",
		},
		"& .ag-header-cell-text": {
			textAlign: "center !important",
		},

		"& .ag-checkbox-input-wrapper": {
			margin: "0 10px",
		},
		"& .ag-cell": {
			alignItems: "center",
			padding: "0 !important",
			border: "unset !important",
			textAlign: "center",
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 10,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		"& .ag-cell-wrapper": {
			textAlign: "center",
		},
		"& .ag-menu-option": {
			cursor: "pointer",
		},
		"& .MuiDataGrid-row": {
			cursor: "pointer",
		},
		"& .MuiDataGrid-columnHeaderTitle, & .MuiTablePagination-caption": {
			fontWeight: "bold",
			fontSize: "12px",
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			padding: "0",
		},
		"& .MuiDataGrid-cell--textLeft": {
			fontSize: "12px",
		},
	},
	relative: {
		position: "relative",
	},
	textfield: {
		margin: theme.spacing(2),
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	dataTable: {
		padding: theme.spacing(1, 2),
	},
}));
type Props = {
	getCatalogMaterialFromAPI: Function;
	isLoading: boolean;
};
export const TableMaterial = ({
	getCatalogMaterialFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [materialId, setMaterialId] = useState<number | null>(null);
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const { getMenuOptions } = useMaterialsOptionsFunctions({
		setMaterialId,
		setShowEditModal,
		setShowDeleteModal,
		setShowCreateModal,
	});
	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { rows, columnDefs } = useTableData({
		setMaterialId,
		setShowEditModal,
		setShowDeleteModal,
		showContextMenu,
	});
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {
			setShowEditModal(!showEditModal);
			setMaterialId(data.Id);
		},
	});
	columnDefaults.floatingFilter = false;
	const onRowDoubleClicked = (event: any) => {
		setMaterialId(event.data.MaterialId);
		setShowEditModal(true);
	};
	const getContextMenuItems = React.useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data = params.node?.data;
			showContextMenu(event as any, data, "catMaterialsTable");
			return [];
		},
		[rows],
	);
	useEffect(() => {
		registerConfig({
			id: "catMaterialsTable",
			getOptions: getMenuOptions,
		});
	}, []);
	const classes = useStyles();
	return (
		<Grid container justifyContent="center">
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Materials
				</Typography>
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
				style={{ width: "100%" }}
			>
				<Grid
					item
					md={12}
					xs={12}
					style={{ padding: "0 15px" }}
					className={classes.relative}
				>
					<CreateMaterial
						show={showCreateModal}
						onHide={(materialCreated) => {
							setShowCreateModal(false);
							setTimeout(() => {
								if (gridApi && materialCreated) {
									gridApi.paginationGoToLastPage();
								}
							}, 100);
						}}
						refreshData={getCatalogMaterialFromAPI}
					/>
					<EditMaterial
						show={showEditModal}
						onHide={(updated: boolean) => {
							setShowEditModal(false);
							setMaterialId(null);
							if (updated) getCatalogMaterialFromAPI();
						}}
						MaterialId={materialId}
					/>
					<DeleteMaterial
						show={showDeleteModal}
						onHide={(updated: boolean) => {
							setShowDeleteModal(false);
							setMaterialId(null);
							if (updated) getCatalogMaterialFromAPI();
						}}
						MaterialId={materialId}
					/>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							height: "61vh",
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							margin: "20px 0 10px",
						}}
					>
						<div
							style={{ height: "100%", width: "100%" }}
							className="ag-theme-alpine"
						>
							<AgGridReact
								rowData={rows || []}
								columnDefs={columnDefs}
								defaultColDef={columnDefaults}
								rowHeight={34}
								headerHeight={34}
								animateRows={true}
								pagination={true}
								rowSelection="single"
								paginationPageSize={10}
								getContextMenuItems={getContextMenuItems}
								onRowDoubleClicked={onRowDoubleClicked}
								onGridReady={(params) => setGridApi(params.api)}
							/>
						</div>
					</Grid>
					<Grid container justifyContent="flex-end">
						<Grid item md={2} xs={12} className={classes.btnModal}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								style={{ margin: "0px 15px" }}
								onClick={() => setShowCreateModal(!showCreateModal)}
							>
								NEW MATERIAL
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
