import { Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef } from "react";

import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { HeatReportProduct } from "../../../meltshop/models/HeatReport";
import { useGridStyles } from "../../styles/gridStyles";
import { useProductTableData } from "./hooks";

type Props = {
	loadingGrid: boolean;
	rows: HeatReportProduct[];
};
export const CasterHeatReportProductTable = ({ loadingGrid, rows }: Props) => {
	const classes = useGridStyles();

	const gridRef = useRef<AgGridReact>(null);
	const { columns } = useProductTableData();
	const { columnTypes, columnSimpleDefaults } = useGridDefinitions({
		OnEdit: () => {},
	});

	useMemo(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);
	return (
		<Grid container className={classes.root}>
			<Grid item md={12} xs={12} className={"ag-theme-balham"}>
				<AgGridReact
					ref={gridRef}
					rowSelection="single"
					pagination={false}
					animateRows={true}
					rowHeight={35}
					headerHeight={35}
					domLayout={"autoHeight"}
					singleClickEdit={true}
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					rowData={rows}
					columnDefs={columns}
					columnTypes={columnTypes}
					defaultColDef={columnSimpleDefaults}
				/>
			</Grid>
		</Grid>
	);
};
