import { createSlice } from "@reduxjs/toolkit";
import { ShiftInfo } from "../models/Catalogs";
import { CountersByShift } from "../models/CountersByShift";
const CasterCountersInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	errorMessage: string;
	notificationMessage: string;

	shiftId: number | null | false;
	shiftInfo: ShiftInfo;
	counters: CountersByShift[];
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	shiftInfo: {
		CurrentShiftId: 0,
		CurrentProductionDate: new Date(),
		CurrentProductionShift: 0,
		CurrentShift: "",
		CurrentCrew: "",
		CurrentStart: null,
		CurrentEnd: null,
		PreviousShiftId: 0,
		PreviousProductionDate: null,
		PreviousProductionShift: 0,
		PreviousShift: "",
		PreviousCrew: "",
		PreviousStart: null,
		PreviousEnd: null,
		NextShiftId: 0,
		NextProductionDate: null,
		NextProductionShift: 0,
		NextShift: "",
		NextCrew: "",
		NextStart: null,
		NextEnd: null,
	},
	counters: [],
	errorMessage: "",
	notificationMessage: "",
	shiftId: false,
};

export const CasterCountersReducer = createSlice({
	name: "__",
	initialState: CasterCountersInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setShiftInfo(state, { payload }) {
			state.shiftInfo = payload;
		},
		setCounters(state, { payload }) {
			state.counters = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setShiftId(state, { payload }) {
			state.shiftId = payload;
		},
	},
});
