import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import {
	CATALOG_MATERIAL_INITIAL_VALUES,
	CatalogMaterial,
} from "../../../models/CatalogMaterial";
import {
	getCatalogMaterial,
	upsertCatalogMaterial,
} from "../../../repositories/CatalogMaterialRepository";
import { useModalStyles } from "../../../styles/modalStyles";
type Props = {
	MaterialId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};
export const EditMaterial = ({ MaterialId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [materialSelected, setMaterialSelected] = useState<any>();
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<CatalogMaterial>({
		defaultValues: CATALOG_MATERIAL_INITIAL_VALUES,
	});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<CatalogMaterial> = async (data) => {
		setIsSubmitLoading(true);
		const editMaterialResp = await upsertCatalogMaterial(data);
		if (editMaterialResp.ok) {
			setOpen(true);
			onHide(true);
		} else {
			setError(editMaterialResp.message);
		}
		setIsSubmitLoading(false);
	};
	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const response = await getCatalogMaterial(MaterialId);
				if (response.ok) {
					if (
						response === undefined ||
						response.data === undefined ||
						response.data.tables === undefined ||
						response.data.tables[0].rows === undefined ||
						response.data.tables[0].rows[0] === undefined
					)
						return;
					const material = response.data.tables[0].rows[0];
					if (material) {
						setValue("MaterialId", material.MaterialId);
						setValue("L1MaterialId", material.L1MaterialId);
						setValue("MaterialName", material.MaterialName);
						setValue("IsAlloy", material.IsAlloy);
						setValue("UseInEAF", material.UseInEAF);
						setValue("UseInLF", material.UseInLF);
						setValue("Enabled", material.isActive);
						setIsLoading(false);
						setMaterialSelected(material);
					}
				}
			})();
		}
	}, [show]);
	return (
		<>
			<MesfModal
				title="EDIT MATERIAL"
				open={show}
				handleClose={() => onHide(false)}
				id="edit-material-modal"
				maxWidth="lg"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						)}
						{!isLoading && (
							<>
								<Grid container spacing={1}>
									<Grid item xs={9}>
										<TextField
											{...register("MaterialName")}
											label="Name"
											variant="outlined"
											error={!!errors.MaterialName}
											fullWidth
											value={materialSelected?.MaterialName}
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											{...register("L1MaterialId")}
											label="L1MaterialId"
											variant="outlined"
											error={!!errors.L1MaterialId}
											fullWidth
											value={materialSelected?.L1MaterialId}
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={12}>
									<Controller
										name="IsAlloy"
										control={control}
										render={({ field }) => (
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														checked={field.value}
														onChange={field.onChange}
														name="IsAlloy"
														color="primary"
													/>
												}
												label="Is Alloy"
											/>
										)}
									/>
									<Controller
										name="UseInEAF"
										control={control}
										render={({ field }) => (
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														checked={field.value}
														onChange={field.onChange}
														name="UseInEAF"
														color="primary"
													/>
												}
												label="Use in EAF"
											/>
										)}
									/>
									<Controller
										name="UseInLF"
										control={control}
										render={({ field }) => (
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														checked={field.value}
														onChange={field.onChange}
														name="UseInLF"
														color="primary"
													/>
												}
												label="Use in LF"
											/>
										)}
									/>
								</Grid>
							</>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => onHide(false)}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									startIcon={
										isSubmitLoading ? <CircularProgress size="1rem" /> : <></>
									}
									disabled={isSubmitLoading}
									variant="contained"
									color="primary"
									type="submit"
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The material was edited successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Editing Material"
			/>
		</>
	);
};
