import { MenuOptionType, useShiftNavigator } from "@dexteel/mesf-core";
import MemoryIcon from "@material-ui/icons/Memory";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CasterProduction } from "../../../../caster/models/CasterProduction";

export const useOptionsFunctions = () => {
	const navigate = useNavigate();
	const { shiftInfo } = useShiftNavigator();

	const goToDataEntry = (ProcessId: number | null) => {
		if (ProcessId === null) return;
		navigate(`/caster-data-entry/${ProcessId}`);
	};

	//navigate functions
	const goToHeats = (HeatId: number) => {
		if (HeatId)
			navigate(`/meltshop-heats/${shiftInfo?.CurrentShiftId}?heatId=${HeatId}`);
	};

	const getMenuOptions = (data: CasterProduction) => {
		let options: MenuOptionType[] = [];

		if (data.ProcessId) {
			options.push({
				name: "See in Data Entry",
				key: "go_to_caster_data_entry",
				style: { fontWeight: "bold" },
				onClick: () => goToDataEntry(data.ProcessId),
				icon: <MemoryIcon />,
			});
		}
		if (data.HeatId) {
			options = options.concat([
				{
					name: "See in Heat Table",
					key: "go_to_heat",
					onClick: () => goToHeats(data.HeatId),
					icon: (
						<i
							style={{ fontSize: "1.5em", marginLeft: "-3px" }}
							className="material-icons"
						>
							thermostat
						</i>
					),
				},
			]);
		}
		return options;
	};

	return {
		getMenuOptions,
		goToDataEntry,
	};
};
