import { ColDef } from "ag-grid-enterprise";
import { CatalogSection } from "../../../models/CatalogSection";
import { useCatalogSectionContext } from "../CatalogSectionContext";

type Props = {};
export const useTableData = ({}: Props) => {
	const { state } = useCatalogSectionContext();
	const rows: CatalogSection[] =
		state.sections?.map((section) => ({
			...section,
		})) || [];

	const columns: ColDef[] = [
		{ field: "SectionName", headerName: "Name", minWidth: 20, flex: 1 },
		{
			field: "IsCircular",
			headerName: "Is Circular",
			type: [
				"nonSorteable",
				"nonFilterable",
				"centerAligned",
				"booleanCheckbox",
			],
			maxWidth: 100,
		},
		{
			field: "IsActive",
			headerName: "Enabled",
			type: ["nonSorteable", "nonFilterable", "centerAligned", "IsActive"],
			maxWidth: 100,
		},
		{
			field: "actions",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "editButton"],
			maxWidth: 50,
		},
	];

	return { rows, columns };
};
