import * as React from "react";
import { L3MechanicalProvider } from "./L3MechanicalContext";
import { L3MechanicalPage } from "./L3MechanicalPage";

type Props = {};
const L3Mechanical = (props: Props) => {
	return (
		<L3MechanicalProvider>
			<L3MechanicalPage />
		</L3MechanicalProvider>
	);
};
export default L3Mechanical;
