export interface ChemistrySample {
	Id: number | null;
	SampleTypeId: number | null;
	ResultSourceId: number | null;
	SampleSequence: number | null;
	DateTimeReceived: Date | null;
	Comments: string;
	HeatId: number | null;
	DateTimeResults: Date | null;
	DateResults: Date | null;
	StatusCode: string;
	SampleName: string;
	Technician: string;
	DateError: boolean;
	Melter: string;
	DateString: string;

	ElementsId: string | null;
	ElementsValue: string | null;
	ResultsId: string | null;

	elementParams: string[];
	sampleParams: string[];
	isNew: boolean | null;
}

export const CHEMISTRY_SAMPLE_INITIAL_VALUES: ChemistrySample = {
	Id: null,
	SampleTypeId: null,
	SampleSequence: null,
	SampleName: "",
	StatusCode: "V",
	ResultSourceId: null,
	isNew: true,
	HeatId: null,
	Comments: "",
	DateTimeResults: new Date(),
	DateTimeReceived: new Date(),
	DateResults: null,
	Technician: "",
	Melter: "",
	DateError: false,
	DateString: "",
	ElementsId: null,
	ElementsValue: null,
	sampleParams: [],
	elementParams: [],
	ResultsId: null,
};

export const isValidChemistrySample = (model: ChemistrySample) => {
	return !(
		model.SampleTypeId === undefined ||
		model.SampleTypeId === null ||
		model.ResultSourceId === undefined ||
		model.ResultSourceId === null ||
		model.HeatId === undefined ||
		model.HeatId === null
	);
};
