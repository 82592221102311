import { ColDef } from "ag-grid-enterprise";
import { CatalogGrade } from "../../../models/CatalogGrade";
import { useCatalogGradeContext } from "../CatalogGradeContext";

type Props = {};
export const useTableData = ({}: Props) => {
	const { state } = useCatalogGradeContext();
	const rows: CatalogGrade[] =
		state.grades?.map((grade) => ({
			...grade,
		})) || [];

	const columns: ColDef[] = [
		{ field: "GradeName", headerName: "Name", minWidth: 20, flex: 1 },
		{
			field: "IsActive",
			headerName: "Enabled",
			type: [
				"nonSorteable",
				"nonFilterable",
				"centerAligned",
				"booleanCheckbox",
			],
			maxWidth: 100,
		},
		{
			field: "actions",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "editButton"],
			maxWidth: 50,
		},
	];

	return { rows, columns };
};
