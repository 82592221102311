import { ErrorModal } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import { LazyLoading } from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { ElementData } from "../models/ElementData";
import {
	CalculateProcessData,
	GetProcessCatalogs,
	getChemistrySampleByHeatId,
	getProcessDataById,
} from "../repositories/ProductionRepository";
import { useCastersContext } from "./CastersContext";
import { MainPanelCaster } from "./components/MainPanelCaster";
import { useTimer } from "./hooks/useTimer";

export const CastersPage = () => {
	const { state, actions } = useCastersContext();

	const getDataFromAPI = async (forceProbe: boolean = false) => {
		if (state.processId === false) return;
		actions.setIsLoadingBackground(true);
		const response = await getProcessDataById(state.processId);
		actions.setIsLoadingBackground(false);
		if (response.ok) {
			const process = {
				processData: response.data.tables[0].rows[0],
				strands: response.data.tables[1].rows,
				probes: response.data.tables[2].rows,
				elements: {} as ElementData, // response.data.tables[3].rows[0],
			};
			actions.setIsLoadingBackground(true);
			const elementResponse = await getChemistrySampleByHeatId(
				process.processData.HeatId,
			);
			actions.setIsLoadingBackground(false);
			if (elementResponse.ok) {
				process.elements = elementResponse.data.tables[0].rows[0];
			}

			if (state.processId !== process.processData.ProcessId) {
				actions.setProcess(process);
				actions.setProcessId(process.processData.ProcessId);
			} else {
				actions.mergeProcess(process);
			}
		} else {
			actions.setErrorMessage(response.message);
		}
	};
	const getCatalogDataFromAPI = async () => {
		actions.setIsLoadingBackground(true);
		const response = await GetProcessCatalogs();
		if (response.ok) {
			actions.setCatalogs({
				tundishes: response.data.tables[0].rows,
				pourBacks: response.data.tables[1].rows,
				moldSizes: response.data.tables[2].rows,
				tundishShrouds: response.data.tables[3].rows,
				scrapReasons: response.data.tables[4].rows,
				tonnageReasons: response.data.tables[5].rows,
				housings: response.data.tables[6].rows,
				ladles: response.data.tables[7].rows,
			});
		} else {
			actions.setErrorMessage(response.message);
		}
		actions.setIsLoadingBackground(false);
	};
	const getProcessData = async (processId: number | null) => {
		if (processId !== state.processId) actions.setProcessId(processId);
	};
	const calculateProcessData = async () => {
		if (state.processData === null) return;
		actions.clearFocusField();
		actions.setIsLoadingBackground(true);
		const resp = await CalculateProcessData(state.processData.ProcessId ?? 0);
		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			actions.setNotificationMessage(
				"Process data was calculated successfully",
			);
			await getDataFromAPI();
		} else {
			actions.setIsLoadingBackground(false);
			actions.setErrorMessage(resp.message);
		}
	};
	/////
	const updateHistory = (processId: string) => {
		const paths = location.pathname.split("/");
		window.history.replaceState(null, "", `/${paths[1]}` + `/${processId}`);
	};

	useEffect(() => {
		if (
			state.processData?.ProcessId !== null &&
			state.processData?.ProcessId === state.processId
		)
			return;
		(async () => {
			await getDataFromAPI();
		})();
		updateHistory(state.processId ? state.processId?.toString() : "");
	}, [state.processId]);

	useEffect(() => {
		(async () => {
			await getCatalogDataFromAPI();
		})();
	}, []);

	const { restartTime } = useTimer({
		onTimeUp: getDataFromAPI,
	});

	if (state.isLoadingPage) return <LazyLoading />;

	return (
		<>
			<button
				id="dummyDiv"
				style={{
					width: "0px",
					height: "0px",
					opacity: "0",
					position: "absolute",
				}}
			/>
			<Grid container justifyContent="center" alignItems="center">
				<Grid
					item
					md={12}
					xs={12}
					style={{
						margin: 0,
						padding: "15px 15px 15px 5px",
					}}
					container
				>
					{state.processData !== null && (
						<MainPanelCaster
							reloadProcess={getProcessData}
							calculateProcess={calculateProcessData}
							refreshData={async () => {
								await getDataFromAPI();
								restartTime();
							}}
						/>
					)}
					<ErrorModal
						error={state.errorMessage}
						onHide={() => actions.setErrorMessage("")}
					/>
					<NotificationSnackBar
						message={state.notificationMessage}
						onHide={() => actions.setNotificationMessage("")}
					/>
				</Grid>
			</Grid>
		</>
	);
};
