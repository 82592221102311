import { ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../controls/buttons/GridButtons";
import { CatalogMold } from "../../../models/catalogs/CatalogMold";
import { useCatalogMoldSizeContext } from "../../mold-sizes/context/CatalogMoldSizeContext";
import { useCatalogMoldContext } from "../context/CatalogMoldContext";
type Props = {
	setMoldId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	showContextMenu: Function;
};
export const useTableData = ({
	setMoldId,
	setShowEditModal,
	setShowDeleteModal,
	showContextMenu,
}: Props) => {
	const { state } = useCatalogMoldContext();
	const {
		state: { moldSizes },
	} = useCatalogMoldSizeContext();
	const rows: CatalogMold[] =
		state.molds?.map((mold: CatalogMold) => ({
			...mold,
			MoldSize: moldSizes?.find((size) => size.MoldSizeId === mold.MoldSizeId)
				?.MoldSizeName,
		})) || [];
	const columnDefs = [
		{
			field: "MoldName",
			headerName: "Name",
			minWidth: 20,
			flex: 2,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			field: "MoldSize",
			headerName: "Mold Size",
			minWidth: 20,
			flex: 2,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			cellRenderer: GridButtons,
			cellRendererParams: (params: ICellRendererParams) => ({
				setId: setMoldId,
				id: params.data.MoldId,
				setShowEditModal: setShowEditModal,
				setShowDeleteModal: setShowDeleteModal,
				showContextMenu: () =>
					showContextMenu(event, params.data, "moldContext"),
			}),
			wrapText: false,
			autoHeight: false,
			wrapHeaderText: false,
			headerName: "",
			sortable: false,
			flex: 1,
			minWidth: 330,
			maxWidth: 200,
			filter: false,
			autoHeaderHeight: true,
		},
	];
	return { rows, columnDefs };
};
