import { minutesToTime } from "@/pages/eaf/data-entry/utils/durationHelpers";
import { dxtToLocalServerTime, useContextMenuMESF } from "@dexteel/mesf-core";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import { ColDef } from "ag-grid-community";
import { useEffect, useMemo } from "react";
import { LinkCellRenderer } from "../../../../../controls/ag-grid/components/ButtonCellRenderer";
import { statusCodeFormatter } from "../../../../../utils/heats-status-formatter";
import { useGridStyles } from "../../../../caster/styles/gridStyles";
import { useHeatsByShiftContext } from "../../context/HeatsByShiftContext";
import { useLadleContext } from "../../context/LadlesContext";
import { HeatStatusTag } from "../HeatStatusTag";
import { useHeatsOptionsFunctions } from "./useHeatsOptionsFunctions";

type Props = {
	setHeatIdForEdit: Function;
	setModalEditHeat: Function;
	setHeatIdForDelete: Function;
	setModalDeleteHeat: Function;
	setModalCreateHeat: Function;
	goToDataEntry: Function;
	goToEAFDataEntry: Function;
	goToHeatReport: Function;
};

export const useTableData = ({
	setHeatIdForEdit,
	setModalEditHeat,
	setHeatIdForDelete,
	setModalDeleteHeat,
	setModalCreateHeat,
	goToDataEntry,
	goToEAFDataEntry,
	goToHeatReport,
}: Props) => {
	const {
		state: { heats },
	} = useHeatsByShiftContext();
	const {
		state: { ladles },
	} = useLadleContext();

	const classes = useGridStyles();

	const { showContextMenu, registerConfig } = useContextMenuMESF();

	const { getMenuOptions } = useHeatsOptionsFunctions({
		setHeatIdForEdit,
		setModalEditHeat,
		setHeatIdForDelete,
		setModalDeleteHeat,
		setModalCreateHeat,
	});

	useEffect(() => {
		registerConfig({
			id: "meltshop-heats",
			getOptions: getMenuOptions,
		});
	}, []);

	const columnData: ColDef[] = [
		{
			headerName: "Heat",
			field: "HeatName",
			flex: 1,
			minWidth: 52,
		},
		{
			headerName: "Status",
			field: "Status",
			minWidth: 180,
			cellRenderer: (params: any) => {
				return <HeatStatusTag status={params.value} />;
			},
		},
		{
			headerName: "Start",
			minWidth: 54,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data.Start}`, "HH:mm"),
			cellRenderer: (params: any) => {
				if (params.data.HeatEAFDELink)
					return <LinkCellRenderer {...params} linkProperty="HeatEAFDELink" />;
				else return <span style={{ color: "black" }}>{params.value}</span>;
			},
		},
		{
			headerName: "Tap Time",
			minWidth: 76,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data.TapTime}`, "HH:mm"),
		},
		{
			headerName: "Ladle",
			field: "Ladle",
			minWidth: 56,
		},
		{
			headerName: "Ladle Open",
			minWidth: 90,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data.LadleOpen}`, "HH:mm"),
			cellRenderer: (params: any) => {
				if (params.data.HeatNameLink)
					return <LinkCellRenderer {...params} linkProperty="HeatNameLink" />;
				else return <span style={{ color: "black" }}>{params.value}</span>;
			},
		},
		{
			headerName: "Ladle Close",
			minWidth: 91,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data.LadleClose}`, "HH:mm"),
		},
		{
			headerName: "Grade",
			field: "Grade",
			minWidth: 59,
		},
		{
			headerName: "Last Sample",
			field: "LastSample",
			minWidth: 94,
		},
		{
			headerName: "Melt Time",
			minWidth: 82,
			field: "MeltTime",
		},
		{
			headerName: "Cast Time",
			minWidth: 81,
			field: "CastTime",
		},
		{
			headerName: "Mold Size",
			field: "MoldSizeName",
			minWidth: 80,
		},
		{
			headerName: "Order",
			field: "HeatOrder",
			minWidth: 58,
		},
		{
			headerName: "Tons",
			field: "CastTons",
			minWidth: 51,
			valueGetter: (params) => {
				return params.data.CastTons ? params.data.CastTons.toFixed(1) : "";
			},
		},
		{
			headerName: "",
			minWidth: 180,
			field: "links",
			cellRenderer: (params: any) => {
				return (
					<div className={classes.buttons}>
						<Grid container justifyContent="center" alignItems="center">
							<Grid item md={4} xs={4} style={{ padding: "0" }}>
								<Tooltip title="See in EAF Data Entry" arrow>
									<Button
										fullWidth
										aria-label="EAF Page"
										disabled={!params.data.HeatEAFDELink}
										onClick={() => {
											goToEAFDataEntry(params.data.HeatEAFDELink);
										}}
									>
										<span
											className="material-icons"
											style={{
												color: !params.data.HeatEAFDELink
													? "#bababa"
													: "#3f51b5",
											}}
										>
											thermostat
										</span>
									</Button>
								</Tooltip>
							</Grid>
							<Grid item md={4} xs={4} style={{ padding: "0" }}>
								<Tooltip title="See in Caster Data Entry" arrow>
									<Button
										fullWidth
										aria-label="Caster Data Entry"
										disabled={!params.data.HeatNameLink}
										onClick={() => {
											goToDataEntry(params.data.HeatNameLink);
										}}
									>
										<span
											className="material-icons"
											style={{
												color: !params.data.HeatNameLink
													? "#bababa"
													: "#3f51b5",
											}}
										>
											memory
										</span>
									</Button>
								</Tooltip>
							</Grid>
							<Grid item md={4} xs={4} style={{ padding: "0" }}>
								<Tooltip title="See in Lab Report" arrow>
									<Button
										fullWidth
										disabled={!params.data.HeatLABLink}
										aria-label="Lab Page"
										onClick={() => {
											goToHeatReport(params.data.HeatLABLink);
										}}
									>
										<span
											className="material-icons"
											style={{
												color: !params.data.HeatLABLink ? "#bababa" : "#3f51b5",
											}}
										>
											science
										</span>
									</Button>
								</Tooltip>
							</Grid>
						</Grid>
					</div>
				);
			},
		},
		{
			field: "Actions",
			minWidth: 60,
			maxWidth: 60,
			cellRenderer: (params: any) => {
				return (
					<div className={classes.buttons}>
						<Grid container justifyContent="center" alignItems="center">
							<Grid item md={6} xs={6} style={{ padding: "0" }}>
								<IconButton
									aria-label="Options"
									title="Options"
									style={{ cursor: "pointer" }}
									onClick={(e) =>
										showContextMenu(e, params.data, "meltshop-heats")
									}
								>
									<ListIcon
										fontSize="inherit"
										color="primary"
										style={{ cursor: "pointer" }}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</div>
				);
			},
			headerName: " ",
			sortable: false,
		},
	];
	const defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
	};

	const rowData = useMemo(() => {
		return heats.map(
			({
				ProcessId,
				HeatId,
				HeatName,
				Start,
				StartTap,
				HeatNameLink,
				HeatEAFDELink,
				HeatLABLink,
				LadleId,
				LadleOpen,
				LadleClose,
				GradeName,
				HeatStatusCode,
				LastSample,
				MoldSizeName,
				MeltTime,
				CastTime,
				HeatOrder,
				CastTons,
			}) => {
				const Ladle = ladles.find((l) => l.LadleId === LadleId);
				return {
					ProcessId,
					HeatName: HeatName,
					HeatNameLink: HeatNameLink,
					HeatEAFDELink: HeatEAFDELink,
					HeatLABLink,
					Start: Start,
					TapTime: StartTap,
					Ladle: Ladle?.LadleName,
					LadleOpen: LadleOpen,
					LadleClose: LadleClose,
					Grade: GradeName,
					Status: statusCodeFormatter(HeatStatusCode),
					HeatId: HeatId,
					LastSample: LastSample,
					MoldSizeName,
					MeltTime: MeltTime ? minutesToTime(MeltTime) : null,
					CastTime: CastTime ? minutesToTime(CastTime) : null,
					HeatOrder,
					CastTons,
					Id: HeatId,
				};
			},
		);
	}, [heats]);

	return { rowData, columnData, defaultColDef };
};
