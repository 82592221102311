import { isNil } from "lodash-es";
import { DataTypeField, ReportColumn } from "../types/public-types";
import { BodyCellSetup, CellProperty } from "../types/table-body";
import { HeaderCellSetup } from "../types/table-header";
import { getEnumKeyByEnumValue, isNullOrUndefined } from "./other-helper";

export const convertToTransposedCellHeaders = (
	rows: any[],
	field: string,
): HeaderCellSetup[] => {
	const returnObject: HeaderCellSetup[] = [];
	rows.forEach((row) => {
		const cellSetup: HeaderCellSetup = {
			Name: "",
			Type: DataTypeField.String,
			Field: "",
			Width: "",
			Format: "",
			CellStyle: "",
		};
		if (row[field] !== undefined) {
			cellSetup.Name = String(row[field]); // for future use levels
		}
		returnObject.push(cellSetup);
	});
	return returnObject;
};
export const convertToCellHeaders = (
	columns: ReportColumn[],
): HeaderCellSetup[] => {
	const returnObject: HeaderCellSetup[] = [];
	if (columns.length === 0) return returnObject;
	let previousParent = "";
	columns.forEach((column) => {
		const cellSetup: HeaderCellSetup = {
			Name: column.HeaderName,
			Type: column.Type,
			Field: column.Field,
			Width: column.Width,
			Format: column.Format,
			CellStyle: isNullOrUndefined(column.CellStyle) ? "" : column.CellStyle,
		};
		if (isNullOrUndefined(column.ParentName)) returnObject.push(cellSetup);
		else {
			const parentCell = returnObject.find((x) => x.Name === column.ParentName);
			if (parentCell === undefined)
				returnObject.push({
					Name: column.ParentName || "",
					children: [cellSetup],
				});
			else if (previousParent !== column.ParentName)
				returnObject.push({
					Name: column.ParentName || "",
					children: [cellSetup],
				});
			else parentCell.children?.push(cellSetup);
		}
		previousParent = column.ParentName || "";
	});

	return returnObject;
};
export const calculateLevels = (cells: HeaderCellSetup[]): number => {
	let returnObject = 0;
	if (!isNil(cells) && cells.length > 0) {
		cells.forEach((cell) => {
			const newLevel = calculateLevels(cell.children || []) + 1;
			returnObject = newLevel > returnObject ? newLevel : returnObject;
		});
	}
	return returnObject;
};
export const calculateTransposedLevels = (cells: HeaderCellSetup[]): number => {
	let returnObject = 0;
	if (!isNullOrUndefined(cells) && cells.length > 0) {
		cells.forEach((cell) => {
			const newLevel =
				calculateTransposedLevels(cell.children || []) + cells.length;
			returnObject = newLevel > returnObject ? newLevel : returnObject;
		});
	}
	return returnObject;
};
export const getLevelCells = (
	cells: HeaderCellSetup[],
	level: number,
	currentLevel: number,
): HeaderCellSetup[] => {
	let returnObject: HeaderCellSetup[] = [];
	if (!isNullOrUndefined(cells) && cells.length > 0) {
		if (currentLevel === level) {
			returnObject = returnObject.concat(cells);
		} else if (currentLevel < level) {
			cells.forEach((cell) => {
				returnObject = returnObject.concat(
					getLevelCells(cell.children || [], level, currentLevel + 1),
				);
			});
		}
	}
	return returnObject;
};
export const convertToBodyCells = (
	columns: HeaderCellSetup[],
	data: any,
	autoGeneratedColumns: boolean,
): BodyCellSetup[] => {
	let returnObject: BodyCellSetup[] = [];
	const dataCells: BodyCellSetup[] = [];
	if (data === null) return returnObject;
	buildDataKeys(data, dataCells);
	if (!autoGeneratedColumns && columns.length > 0) {
		buildBodyCells(columns, dataCells, returnObject);
	} else returnObject = dataCells;
	return returnObject;
};
export const getCellProperty = (data: any, property: CellProperty) => {
	return data[property] === undefined ? null : data[property];
};
export const getCellDataValue = (field: string, row: any): any => {
	const fields = field.split(".");
	let data: any = row;
	for (let i = 0; i < fields.length; i++) {
		const newData = data[fields[i]];
		if (newData === undefined) {
			data = "";
			break;
		}
		data = newData;
	}
	return data;
};
const buildDataKeys = (
	data: any,
	cells: BodyCellSetup[],
	name?: string,
	parentName?: string,
) => {
	parentName =
		parentName === undefined || parentName === "" ? "" : parentName + ".";
	const cell: BodyCellSetup = {
		name: `${name}`,
		field: `${parentName}${name}`,
		type: DataTypeField.String,
		format: "",
		cellStyle: "",
	};
	if (typeof data !== "object" || isNullOrUndefined(data)) {
		cells.push(cell);
		return;
	}
	const keys = Object.keys(data);
	const childKeys = keys.filter(isColumnChild);
	if (childKeys.length === 0) {
		getCellProperties(data, cell);
		cells.push(cell);
	} else
		childKeys.forEach((childKey) => {
			buildDataKeys(data[childKey], cells, childKey, name || "");
		});
};
const buildBodyCells = (
	columns: HeaderCellSetup[],
	dataKeys: BodyCellSetup[],
	cells: BodyCellSetup[],
) => {
	columns.forEach((column) => {
		if (isNullOrUndefined(column.children)) {
			const dataKey = dataKeys.find((x) => x.field === column.Field);
			if (dataKey !== undefined) {
				dataKey.format = column.Format;
				dataKey.cellStyle = column.CellStyle;
				dataKey.type = column.Type || DataTypeField.String;
				cells.push(dataKey);
			} else {
				const cell: BodyCellSetup = {
					name: column.Name,
					type: column.Type || DataTypeField.String,
					field: column.Field || "",
					format: column.Format,
					cellStyle: column.CellStyle || "",
					properties: [],
				};
				cells.push(cell);
			}
		} else {
			buildBodyCells(column.children || [], dataKeys, cells);
		}
	});
};
const isColumnChild = (key: string) => {
	return (
		key !== "Value" && key !== "Style" && key !== "Title" && key !== "Link"
	);
};
const getCellProperties = (data: any, cell: BodyCellSetup) => {
	cell.properties = [];
	Object.values(CellProperty).forEach((property) => {
		if (data[property] !== undefined) {
			const enumProperty = getEnumKeyByEnumValue(CellProperty, property);
			if (enumProperty !== null && property !== CellProperty.Value)
				cell.properties?.push({
					property: CellProperty[enumProperty],
					value: "",
				});
		}
	});
};
