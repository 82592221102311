import { TimeService, getMomentTz } from "@dexteel/mesf-core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useRef } from "react";
import { useStyles } from "../styles";
import { DateFieldCasterProps } from "./FieldsCaster";

const moment = getMomentTz();

export type InputFieldTableProps = {
	value: any;
	readOnly?: boolean;
	placeholder?: string;
	styleTextBox?: CSSProperties;
	classInput?: string;
	onChange?: (value: string) => void;
	onBlur?: (value: any) => void;
	onFocus?: () => void;
};
const InputFieldTable = ({
	value,
	readOnly = false,
	placeholder = "",
	styleTextBox,
	classInput = "",
	onChange,
	onBlur,
	onFocus,
}: InputFieldTableProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const handleOnFocus = () => {
		if (inputRef.current) inputRef.current.select();
		if (onFocus) onFocus();
	};
	const handleOnChange = (value: string) => {
		if (onChange) onChange(value);
	};
	const handleOnBlur = (value: string) => {
		if (onBlur) onBlur(value);
	};
	const handleOnKeyDown = (event: any) => {
		if (event.key === "Enter" && inputRef.current) {
			inputRef.current.blur();
		}
	};
	return (
		<input
			ref={inputRef}
			type="text"
			className={"form-control " + classInput}
			readOnly={readOnly}
			disabled={readOnly}
			value={value}
			placeholder={placeholder}
			onChange={(e) => handleOnChange(e.target.value)}
			onKeyDown={handleOnKeyDown}
			onBlur={(e) => handleOnBlur(e.target.value)}
			onFocus={handleOnFocus}
			style={styleTextBox || {}}
		/>
	);
};

export type CheckBoxFieldTableProps = {
	checked: boolean;
	readOnly?: boolean;
	title: string;
	styleCheckBox?: CSSProperties;
	classCheckBox?: string;
	onChange?: (value: boolean) => void;
	onBlur?: (value: boolean) => void;
	onFocus?: () => void;
};
const CheckBoxFieldTable = ({
	checked = false,
	readOnly = false,
	title = "",
	styleCheckBox,
	classCheckBox = "",
	onChange,
	onBlur,
	onFocus,
}: CheckBoxFieldTableProps) => {
	const handleOnFocus = () => {
		if (onFocus) onFocus();
	};
	const handleOnChange = (value: boolean) => {
		if (onChange) onChange(value);
	};
	const handleOnBlur = (value: boolean) => {
		if (onBlur) onBlur(value);
	};
	return (
		<input
			type="checkbox"
			checked={checked}
			aria-label={title}
			readOnly={readOnly}
			disabled={readOnly}
			className={classCheckBox}
			onFocus={handleOnFocus}
			onChange={(e) => handleOnChange(e.target.checked)}
			onBlur={(e) => handleOnBlur(e.target.checked)}
			style={styleCheckBox || {}}
		/>
	);
};

export type ListBoxFieldTableProps = {
	value: number;
	readOnly?: boolean;
	options: { id: number; name: string }[];
	styleListBox?: CSSProperties;
	classListBox?: string;
	onChange?: (value: string) => void;
	onBlur?: (value: string) => void;
	onFocus?: () => void;
};
const ListBoxFieldTable = ({
	value,
	readOnly = false,
	options = [],
	styleListBox,
	classListBox = "",
	onChange,
	onBlur,
	onFocus,
}: ListBoxFieldTableProps) => {
	const handleOnFocus = () => {
		if (onFocus) onFocus();
	};
	const handleOnChange = (value: string) => {
		if (onChange) onChange(value);
	};
	const handleOnBlur = (value: string) => {
		if (onBlur) onBlur(value);
	};
	return (
		<select
			className={"custom-select " + classListBox}
			disabled={readOnly}
			value={value}
			onChange={(e) => handleOnChange(e.target.value)}
			style={styleListBox || {}}
			onBlur={(e) => handleOnBlur(e.target.value)}
			onFocus={(e) => handleOnFocus()}
		>
			<option value="">{"Choose"}</option>
			{options?.map((option) => (
				<option key={option.id} value={option.id}>
					{option.name}
				</option>
			))}
		</select>
	);
};

const InputDateFieldTable = ({
	readonly,
	field,
	value,
	onChange,
	onBlur,
	onFocus,
	classInput,
}: DateFieldCasterProps) => {
	const classes = useStyles();

	const inputRef = useRef<HTMLInputElement>(null);
	const valueChanged = (value: Date | null) => {
		if (onChange && readonly !== true) onChange(field, value);
	};
	const valueBlurred = (value: Date | null) => {
		if (onBlur && readonly !== true) onBlur(field, value);
	};
	const valueFocused = () => {
		if (onFocus && readonly !== true) onFocus(field);
	};
	const handleOnKeyDown = (event: any) => {
		if (event.key === "Enter" && inputRef.current) {
			inputRef.current.blur();
		}
	};
	return (
		<div className={classes.dateToTableTimePicker}>
			<DateTimePicker
				timezone={TimeService.getInstance().getServerTimeZone()}
				format="HH:mm"
				readOnly={readonly || false}
				disabled={readonly}
				timeSteps={{ minutes: 1 }}
				value={moment(value)}
				onChange={(value) => valueChanged(value?.toDate() || null)}
				disableOpenPicker
				className={"form-control " + classInput}
				slotProps={{
					textField: {
						onBlur: () => valueBlurred(value),
						onKeyDown: handleOnKeyDown,
						onFocus: valueFocused,
						ref: inputRef,
					},
				}}
			/>
		</div>
	);
};

export {
	InputFieldTable,
	CheckBoxFieldTable,
	ListBoxFieldTable,
	InputDateFieldTable,
};
