import { getMomentTz } from "@dexteel/mesf-core";
import { Button } from "@material-ui/core";
import React from "react";
import { isNumeric } from "../../../../../utils/numeric-utils";
import { ProcessData } from "../../../models/ProcessData";
import { ReadTrimDataFromPLC } from "../../../repositories/ProductionRepository";
import { useCastersContext } from "../../CastersContext";
import { useSaveProcess } from "../../hooks/useSaveProcess";
import { useStyles } from "../styles";
import { InputDateFieldTable, InputFieldTable } from "../ui/FieldTable";

const moment = getMomentTz();

export const LiquidTable = ({ refreshData }: { refreshData: Function }) => {
	const classes = useStyles();
	const { state, actions } = useCastersContext();
	const process = state.processData || ({} as ProcessData);
	const { saveTrimData } = useSaveProcess();

	const onReadFromPLC = async () => {
		const resp = await ReadTrimDataFromPLC({
			processId: process.ProcessId!,
		});
		if (resp.ok) {
			await refreshData();
		} else {
			actions.setErrorMessage(resp.message);
		}
	};

	const handleOnManual = () => {
		actions.updateProcessData({
			field: "IsTrimDataManual",
			value: true,
		});
	};

	/// Events
	const handleOnFocus = (field: string) =>
		actions.setFocusField({ entity: "PROCESS", index: -1, field });
	const handleOnChange = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.updateProcessData({ field, value });
	};
	const handleOnBlur = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.clearFocusField();
		saveTrimData(field, value);
	};
	const handleValueChanged = (field: string, value: any) =>
		actions.updateProcessData({ field, value });

	const handleProcessDataUpdate = (field: string, value: Date) => {
		const valueAsString = value.toISOString();

		actions.clearFocusField();
		actions.updateProcessData({ field, value: valueAsString, clear: true });
		saveTrimData(field, valueAsString);
	};

	const handleDateOnFocus = (field: string) =>
		actions.setFocusField({ entity: "PROCESS", index: -1, field });

	return (
		<>
			<table cellPadding={0} cellSpacing={0}>
				<thead>
					<tr>
						<td style={{ width: "150px", textAlign: "center" }}>
							<Button
								aria-label={"Manual"}
								title={"Manual"}
								variant="text"
								size="small"
								onClick={() => handleOnManual()}
								className={
									classes.simpleButton +
									" " +
									(process.IsTrimDataManual ? classes.manualButton : "")
								}
							>
								M
							</Button>
							<Button
								aria-label={"Automatic"}
								title={"Automatic"}
								variant="text"
								size="small"
								onClick={() => onReadFromPLC()}
								className={
									classes.simpleButton +
									" " +
									(process.IsTrimDataManual ? "" : classes.automaticButton)
								}
							>
								A
							</Button>
						</td>
						<td
							className={classes.simpleCellHeader}
							style={{
								width: "110px",
								maxWidth: "110px",
							}}
						>
							Time
						</td>
						<td className={classes.simpleCellHeader} style={{ width: "110px" }}>
							F
						</td>
						<td
							className={classes.simpleCellHeader}
							style={{
								width: "110px",
							}}
						>
							SH
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className={classes.simpleCellHeader}>Liquidus</td>
						<td>
							<InputFieldTable
								readOnly
								classInput={classes.simpleCellNumeric}
								value={"--"}
							/>
						</td>
						<td>
							<InputFieldTable
								readOnly
								classInput={classes.simpleCellNumeric}
								value={process.F1LiquidusTemperature?.toString() || ""}
							/>
						</td>
						<td>
							<InputFieldTable
								readOnly
								classInput={classes.simpleCellNumeric}
								value={"--"}
							/>
						</td>
					</tr>
					<tr>
						<td
							className={classes.simpleCellHeader}
							style={{ borderBottomLeftRadius: "0.25rem" }}
						>
							Ladle
						</td>
						<td>
							<InputDateFieldTable
								title={"TrimProbeTime"}
								readonly={!process.IsTrimDataManual}
								value={
									process.TrimProbeTime
										? moment.utc(process.TrimProbeTime).toDate()
										: null
								}
								onChange={handleValueChanged}
								onBlur={handleProcessDataUpdate}
								onFocus={handleDateOnFocus}
								field="TrimProbeTime"
								classInput={classes.simpleCellNumeric}
							/>
						</td>
						<td>
							<InputFieldTable
								readOnly={!process.IsTrimDataManual}
								classInput={classes.simpleCellNumeric}
								value={process.TrimProbeTemperature?.toString() || ""}
								onFocus={() => handleOnFocus("TrimProbeTemperature")}
								onChange={(value) =>
									handleOnChange("TrimProbeTemperature", value)
								}
								onBlur={(value) => handleOnBlur("TrimProbeTemperature", value)}
							/>
						</td>
						<td>
							<InputFieldTable
								classInput={classes.simpleCellNumeric}
								readOnly
								styleTextBox={{ borderBottomRightRadius: "0.25rem" }}
								value={process.LadleSuperHeat?.toString() || ""}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};
