import { createSlice } from "@reduxjs/toolkit";
import { CatalogSection } from "../../models/CatalogSection";

const CatalogSectionInitialState: {
	sections: CatalogSection[] | null;
} = {
	sections: null,
};

export const CatalogSectionReducer = createSlice({
	name: "__",
	initialState: CatalogSectionInitialState,
	reducers: {
		setSections(state, { payload }) {
			state.sections = payload;
		},
	},
});
