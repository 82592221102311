export interface CatalogGrade {
	GradeId: number | null;
	GradeName: string;
	IsActive: boolean;
}
export const CATALOG_GRADE_INITIAL_VALUES: CatalogGrade = {
	GradeId: null,
	GradeName: "",
	IsActive: true,
};
