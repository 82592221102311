import { DataTypeField } from "./public-types";

export interface BodyCellSetup {
	name: string;
	field: string;
	type: DataTypeField;
	format?: string;
	cellStyle?: string;
	properties?: BodyCellProperty[];
}
export interface BodyCellProperty {
	property: CellProperty;
	value: string;
}
export enum CellProperty {
	Style = "Style",
	Title = "Title",
	Link = "Link",
	Value = "Value",
}
