import * as React from "react";
import { CatalogMechanicalTestProvider } from "./CatalogMechanicalTestContext";
import { CatalogMechanicalTestPage } from "./CatalogMechanicalTestPage";

type Props = {};
const CatalogMechanicalTest = (props: Props) => {
	return (
		<CatalogMechanicalTestProvider>
			<CatalogMechanicalTestPage />
		</CatalogMechanicalTestProvider>
	);
};
export default CatalogMechanicalTest;
