import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-community";
import { LinkCellRenderer } from "../../../controls/ag-grid/components/ButtonCellRenderer";

export const useChemistryTableData = () => {
	const columnData: ColDef[] = [
		{
			headerName: "HeatName",
			field: "HeatNameValue",
			minWidth: 100,
			maxWidth: 100,
			cellRenderer: LinkCellRenderer,
			cellRendererParams: { linkProperty: "HeatNameLink" },
		},
		{
			headerName: "Grade",
			field: "Grade",
			minWidth: 150,
			maxWidth: 150,
		},
		{
			headerName: "Sample",
			field: "Sample",
			minWidth: 80,
			maxWidth: 80,
		},
		{
			headerName: "Elements",
			field: "Elements",
			flex: 2,
		},
		{
			headerName: "Compatible Grades",
			field: "CompatibleGrades",
			flex: 2,
		},
		{
			headerName: "Results Time",
			field: "ResultsTime",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.ResultsTime}`, "MM/dd HH:mm") ||
				"",
		},
		{
			headerName: "Technician",
			field: "LabTechnician",
			maxWidth: 100,
			minWidth: 100,
		},
		{
			headerName: "Comments",
			field: "Comments",
			flex: 1,
		},
	];

	const defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
		suppressMenu: true,
		autoHeight: true,
		wrapText: true,
	};

	return { columnData, defaultColDef };
};
