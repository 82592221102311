import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { CatalogCategory } from "../../../models/CatalogCategory";
import { INITIAL_VALUES } from "../../../models/FormInitialValues";
import { useModalStyles } from "../../../styles/modalStyles";
import { useCatalogCategoryContext } from "../../context/CategoriesContext";
import {
	getCatalogCategories,
	upsertCatalogCategory,
} from "../../repositories/CatalogCategoriesRepository";
import ColorPicker from "../ColorPicker/ColorPicker";
import { ConfirmModal } from "./ConfirmModal";

type Props = {
	show: boolean;
	onHide: (categoryCreated: boolean) => void;
	refreshData: Function;
};

export const CreateCategory = ({ show, onHide, refreshData }: Props) => {
	const classes = useModalStyles();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [categoryName, setCategoryName] = useState("");

	const {
		state: { assetCodes, assetIdToFilter },
	} = useCatalogCategoryContext();

	const {
		register,
		control,
		handleSubmit,
		reset,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CatalogCategory>({
		defaultValues: { ...INITIAL_VALUES, AssetId: assetIdToFilter },
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const validateNoSpecialChars = (value: string) =>
		/^[a-zA-Z0-9 _-]*$/.test(value) || "No special characters";

	const onSubmit: SubmitHandler<CatalogCategory> = async (
		data: CatalogCategory,
	) => {
		setIsSubmitLoading(true);
		try {
			setIsLoading(true);

			const responseCategories = await getCatalogCategories();
			if (!responseCategories.ok) {
				setError(responseCategories.message);
				return;
			}

			const categories: {
				DelayCategoryName: string;
				AssetId: number;
			}[] = responseCategories.data.tables[0].rows;

			const categoryExists = categories.some(
				(category) =>
					category.DelayCategoryName === data.DelayCategoryName &&
					category.AssetId === data.AssetId,
			);

			if (categoryExists) {
				setCategoryName(data.DelayCategoryName);
				setConfirmOpen(true);
				setIsSubmitLoading(false);
				setIsLoading(false);
				return;
			}

			const editCategoryResp = await upsertCatalogCategory(data);
			if (editCategoryResp.ok) {
				setOpen(true);
				onHide(true);
			} else {
				setError(editCategoryResp.message);
			}
		} catch (e: any) {
			console.error("Error submitting category:", e);
			setError(e.message || "An unexpected error occurred");
		} finally {
			setIsSubmitLoading(false);
			setIsLoading(false);
		}
	};

	const handleConfirmClose = (shouldProceed: boolean) => {
		setConfirmOpen(false);
		if (shouldProceed) {
			handleSubmitProceed();
		}
	};

	const handleSubmitProceed = async () => {
		const values = getValues();
		setIsSubmitLoading(true);
		try {
			const editCategoryResp = await upsertCatalogCategory(values);
			if (editCategoryResp.ok) {
				setOpen(true);
				onHide(true);
			} else {
				setError(editCategoryResp.message);
			}
		} catch (e: any) {
			console.error("Error confirming submission:", e);
			setError(e.message || "An unexpected error occurred");
		} finally {
			setIsSubmitLoading(false);
		}
	};
	useEffect(() => {
		if (show && assetIdToFilter) {
			setValue("AssetId", assetIdToFilter);
		}
	}, [show, assetIdToFilter, setValue]);
	useEffect(() => {
		if (show) {
			reset();
		}
	}, [show]);

	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						id="create-category"
						title="NEW CATEGORY"
						open={show}
						handleClose={() => onHide(false)}
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content style={{ padding: "15px 30px" }}>
								{isLoading ? (
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{ minHeight: "300px" }}
									>
										<LazyLoading />
									</Grid>
								) : (
									<>
										<Grid container spacing={1}>
											<Grid item xs={12} md={12}>
												<TextField
													{...register("DelayCategoryName", {
														required: "Name is required",
														validate: validateNoSpecialChars,
													})}
													label="Name"
													variant="outlined"
													error={!!errors.DelayCategoryName}
													fullWidth
													margin="dense"
													autoComplete="off"
												/>
												{errors.DelayCategoryName && (
													<span className={classes.errorLabel}>
														{errors.DelayCategoryName.message}
													</span>
												)}
											</Grid>
											<Grid item xs={3} md={3}>
												<Controller
													name="Color"
													control={control}
													rules={{ required: "Color is required" }}
													render={({ field }) => (
														<FormControl fullWidth margin="dense">
															<ColorPicker
																onChange={(_: string, color: string) =>
																	field.onChange(color)
																}
																color={field.value}
																disabled={false}
																label="Color"
															/>
															{errors.Color && (
																<span className={classes.errorLabel}>
																	{errors.Color.message}
																</span>
															)}
														</FormControl>
													)}
												/>
											</Grid>
											<Grid item xs={3} md={3}>
												<Controller
													name="IsActive"
													control={control}
													render={({ field }) => (
														<FormControlLabel
															disabled
															className={classes.checkbox}
															control={
																<Checkbox
																	checked={field.value}
																	onChange={field.onChange}
																	name="IsActive"
																	color="primary"
																	disabled
																/>
															}
															label="is Active"
														/>
													)}
												/>
											</Grid>
											<Grid item xs={6} md={6}>
												<Controller
													name="AssetId"
													control={control}
													rules={{ required: "Asset is required" }}
													render={({ field }) => (
														<Autocomplete
															id="clear-on-escape"
															clearOnEscape
															options={assetCodes ?? []}
															getOptionLabel={(option) => option.AssetName}
															onChange={(event, newValue) => {
																field.onChange(
																	newValue ? newValue.AssetId : null,
																);
															}}
															renderInput={(params) => (
																<>
																	<TextField
																		{...params}
																		label="Asset"
																		variant="outlined"
																		error={!!errors.AssetId}
																		size="small"
																		fullWidth
																	/>
																	{errors.AssetId && (
																		<span className={classes.errorLabel}>
																			{errors.AssetId.message}
																		</span>
																	)}
																</>
															)}
															value={assetCodes?.find(
																(asset) => asset.AssetId === field.value,
															)}
														/>
													)}
												/>
											</Grid>
										</Grid>
									</>
								)}
							</MesfModal.Content>

							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(false)}
										>
											CANCEL
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											SAVE
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>

					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The category was created successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>

			<ConfirmModal
				show={confirmOpen}
				onHide={handleConfirmClose}
				title="WARNING"
				message={`The category "${categoryName}" already exists. Do you want to proceed?`}
				isLoading={isSubmitLoading}
			/>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
