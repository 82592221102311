import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { LazyLoading } from "../../../controls/LazyLoading";
import { getHealthStatusReport } from "../repositories/CustomRepository";
import { useHealthStatusContext } from "./HealthStatusContext";
import { HealthStatusSections } from "./componenets/HealthStatusSections";

type Props = {};
export const HealthStatusPage = (props: Props) => {
	const { state, actions } = useHealthStatusContext();
	const getDataFromAPI = async () => {
		actions.setIsLoadingBackground(true);
		const response = await getHealthStatusReport();
		if (response.ok) {
			if (response.data.errorMessage) {
				actions.setErrorMessage(response.data.errorMessage);
				return;
			}
			actions.setDetails(response.data?.tables || {});
		} else {
			actions.setErrorMessage(response.message);
		}
		actions.setIsLoadingBackground(false);
	};
	useEffect(() => {
		console.log(state.details);
	}, [state.details]);
	useEffect(() => {
		getDataFromAPI();
	}, []);
	if (!state.isLoadingPage) {
		return (
			<Grid container justifyContent="center" alignItems="center">
				<Grid
					item
					md={12}
					xs={12}
					style={{
						margin: 0,
						padding: "15px 15px 15px 5px",
					}}
					container
				>
					<Grid item md={12} xs={12}>
						<Grid
							container
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							style={{ padding: "5px 10px" }}
						>
							<Grid item md={10} xs={8}>
								<Typography variant="h5" style={{ fontWeight: 600 }}>
									Health Status
								</Typography>
							</Grid>
							<HealthStatusSections />
						</Grid>
					</Grid>
					<ErrorModal
						error={state.errorMessage}
						onHide={() => actions.setErrorMessage("")}
					/>
				</Grid>
			</Grid>
		);
	}
	return <LazyLoading />;
};
