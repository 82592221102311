import { ContextMenuMESFProvider } from "@dexteel/mesf-core";
import { CasterCountersProvider } from "./CasterCountersContext";
import { CasterCountersPage } from "./CasterCountersPage";

type Props = {};
const CasterCounters = (props: Props) => {
	return (
		<CasterCountersProvider>
			<CasterCountersPage />
		</CasterCountersProvider>
	);
};
export default CasterCounters;
