export interface CatalogResultSource {
	ResultSourceId: number | null;
	ResultSourceName: string;
	IsActive: boolean;
}
export const CATALOG_RESULT_SOURCE_INITIAL_VALUES: CatalogResultSource = {
	ResultSourceId: null,
	ResultSourceName: "",
	IsActive: true,
};
