import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { getHeat } from "../../../repositories/HeatRepository";
import { useModalStyles } from "../../../styles/modalStyles";
import { HeatDataEntryType } from "../../models/Heats/HeatDataEntryType";
import { deleteHeat } from "../../repositories/heatsRepository";

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	heatIdForDelete: number | null;
};

export const DeleteHeatModal = ({ show, onHide, heatIdForDelete }: Props) => {
	const classes = useModalStyles();

	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [openAlertMessage, setOpenAlertMessage] = useState(false);
	const [message, setMessage] = useState("");
	const [messageType, setMessageType] = useState<Color | undefined>(undefined);

	const [error, setError] = useState<string | null>(null);

	const [heatForDelete, setHeatForDelete] = useState<HeatDataEntryType | null>(
		null,
	);

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenAlertMessage(false);
	};

	const handleDelete = async () => {
		setIsSubmitLoading(true);

		if (heatIdForDelete) {
			const res = await deleteHeat(heatIdForDelete);
			if (res.ok) {
				setMessage("The heat was deleted successfully");
				setMessageType("success");
				onHide(true);
				setHeatForDelete(null);
			} else {
				setMessage("Error, please try again");
				setMessageType("error");
				setError(res.message);
			}

			setIsSubmitLoading(false);
			setOpenAlertMessage(true);
		}
	};
	useEffect(() => {
		if (show) {
			(async () => {
				setIsLoading(true);
				if (heatIdForDelete) {
					const resp = await getHeat(heatIdForDelete);
					const heat = resp.data.tables[0].rows[0];
					if (heat) {
						setHeatForDelete(heat);
					} else {
						onHide(true);
						setMessage("Error, heat not found. Try again");
						setOpenAlertMessage(true);
						setHeatForDelete(null);
					}
				}

				setIsLoading(false);
			})();
		}
	}, [show]);

	return (
		<>
			<>
				<MesfModal
					open={show}
					handleClose={() => {
						onHide(true);
					}}
					id="deleteHeat"
					title={"Delete a heat"}
					maxWidth="sm"
				>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "40px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "80px" }}
							>
								<CircularProgress size="3rem" />
							</Grid>
						)}

						{!isLoading && (
							<>
								<Grid container spacing={1}>
									<Typography>
										You will not be able to recover deleted data
									</Typography>
								</Grid>
								<br />
								<Grid container spacing={1}>
									<Typography>
										Are you sure you want to delete{" "}
										<strong>{heatForDelete?.HeatName}</strong>?
									</Typography>
								</Grid>
							</>
						)}
					</MesfModal.Content>
					{!isLoading && (
						<MesfModal.Actions style={{ padding: "1rem" }}>
							<Grid container spacing={2} justifyContent="flex-end">
								<Grid item md={3} xs={12} style={{ margin: 0 }}>
									<Button
										fullWidth
										variant="contained"
										color="default"
										onClick={() => {
											onHide(false);
											setHeatForDelete(null);
										}}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item md={3} xs={12} style={{ margin: 0 }}>
									<Button
										fullWidth
										startIcon={
											isSubmitLoading ? <CircularProgress size="1rem" /> : <></>
										}
										disabled={isSubmitLoading}
										variant="contained"
										color="secondary"
										onClick={handleDelete}
									>
										Delete
									</Button>
								</Grid>
							</Grid>
						</MesfModal.Actions>
					)}
				</MesfModal>
				<Snackbar
					open={openAlertMessage}
					autoHideDuration={2500}
					onClose={handleClose}
				>
					<Alert severity={messageType} onClose={handleClose}>
						{message}
					</Alert>
				</Snackbar>
			</>
			<ErrorModal onHide={() => setError(null)} error={error} />
		</>
	);
};
