import { CatalogMoldSizeProvider } from "./context/CatalogMoldSizeContext";
import { CatalogMoldSizePage } from "./pages/CatalogMoldSizePage";

type Props = {};
const CatalogMoldSize = (props: Props) => {
	return (
		<CatalogMoldSizeProvider>
			<CatalogMoldSizePage />
		</CatalogMoldSizeProvider>
	);
};
export default CatalogMoldSize;
