import { Box, Grid } from "@material-ui/core";
import { ProcessDataCheckboxFieldDE } from "../form-fields/process-data/ProcessDataCheckboxField";
import { ProcessDataNumberTextField } from "../form-fields/process-data/ProcessDataNumberTextField";
import { ProcessDataNumberWithCheckboxField } from "../form-fields/process-data/ProcessDataNumberWithCheckboxField";

export const TableCaveIn = () => {
	return (
		<Box padding={1}>
			<Grid container spacing={1} direction={"column"}>
				<Grid item>
					<ProcessDataNumberTextField
						field={"ElectrodeConsumption"}
						label={"Elec Consumption"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataNumberTextField
						field={"ArcStability"}
						label={"Arc Stability"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataNumberWithCheckboxField
						numberField={"HeatsOnRoof"}
						checkboxField={"ForceHeatsOnRoof"}
						label={"Heats On Roof"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataNumberWithCheckboxField
						numberField={"HeatsOnRunnerSpout"}
						checkboxField={"ForceHeatsOnRunnerSpout"}
						label={"Heats On Runner Spout"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataNumberWithCheckboxField
						numberField={"HeatsOnTapHole"}
						checkboxField={"ForceHeatsOnTapHole"}
						label={"Heats On Tap Hole"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataNumberWithCheckboxField
						numberField={"HeatsOnLining"}
						checkboxField={"ForceHeatsOnLining"}
						label={"Heats On Lining"}
					/>
				</Grid>
				{/* <Grid item>
					<ProcessDataCheckboxFieldDE
						field={"Electrode1"}
						label={"Electrode 1"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataCheckboxFieldDE
						field={"Electrode2"}
						label={"Electrode 2"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataCheckboxFieldDE
						field={"Electrode3"}
						label={"Electrode 3"}
					/>
				</Grid> */}
				{/* <Grid item>
						<ProcessDataNumberTextField field={'Electrode1'} label={'Cave In A'}/>
					</Grid>
					<Grid item>
						<ProcessDataNumberTextField field={'Electrode2'} label={'Cave In B'}/>
					</Grid>
					<Grid item>
						<ProcessDataNumberTextField field={'Electrode3'} label={'Cave In C'}/>
					</Grid>
					<Grid item>
						<ProcessDataNumberTextField field={'AverageCurrentPhaseA'} label={'Avg Curr A'}/>
					</Grid>
					<Grid item>
						<ProcessDataNumberTextField field={'AverageCurrentPhaseB'} label={'Avg Curr B'}/>
					</Grid>
					<Grid item>
						<ProcessDataNumberTextField field={'AverageCurrentPhaseC'} label={'Avg Curr C'}/>
					</Grid> */}
			</Grid>
		</Box>
	);
};
