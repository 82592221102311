import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Checkbox,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { useModalStyles } from "../../../../eaf-processes/styles/modalStyles";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Pourback } from "../../../models/ms.models";
import { getPourbackHeats } from "../../../repositories/eaf.repositories";
import { useClassicStyles } from "../../../theme/theme";
import { DateTimePickerDE } from "../../form-fields/common/DateTimePickerDE";
import { NumberTextFieldDE } from "../../form-fields/common/NumberTextFieldDE";
type Props = {
	show: boolean;
	onHide: () => void;
	pourback: Pourback;
};
type HeatProps = {
	Name: string;
	Id: number;
	SourceProcessId: number;
	PourbackWeight: number;
};
export const EditPourback = ({ show, onHide, pourback }: Props) => {
	const classesButton = useClassicStyles();
	const {
		state: { deProcess },
	} = useDataEntryContext();
	const { savePourbackCharge } = useEAFDataEntryActions();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [heats, setHeats] = useState<HeatProps[]>([]);
	const [showMoreHeats, setShowMoreHeats] = useState<boolean>(false);
	const [isPartialCharge, setIsPartialCharge] = useState<boolean>(false);
	const classes = useModalStyles();
	const {
		getValues,
		handleSubmit,
		setValue,
		control,
		reset,
		setFocus,
		formState: { errors },
	} = useForm<Pourback>({});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<Pourback> = async (data: any) => {
		setIsSubmitLoading(true);
		const resp = await savePourbackCharge(data);
		setIsSubmitLoading(false);
		onHide();
	};
	const getPourbacksHeats = async (newValue: boolean) => {
		const resp = await getPourbackHeats(
			newValue,
			deProcess?.process.HeatId ?? 0,
		);
		if (resp.ok) {
			const heats = resp.data.tables[0].rows ?? [];
			setHeats(heats);
		} else {
			setError(resp.message!);
		}
	};
	useEffect(() => {
		if (show) {
			reset();
			getPourbacksHeats(false);
			setValue("PourbackId", pourback.PourbackId);
			setValue("HeatId", pourback.HeatId);
			setValue("SourceProcessId", pourback.SourceProcessId);
			if (pourback.ChargedTime) setValue("ChargedTime", pourback.ChargedTime);
			else if (deProcess?.process.End === null)
				setValue("ChargedTime", new Date());
			else setValue("ChargedTime", deProcess?.process.Start!);
			setValue("PourbackWeight", pourback.PourbackWeight);
			setValue("ChargedWeight", pourback.ChargedWeight);
			if (pourback.ChargedWeight) setIsPartialCharge(true);
			setValue(
				"SourceProcessId",
				pourback.SourceProcessId || deProcess?.process.ProcessId!,
			);
			setValue("PourbackReasonId", pourback.PourbackReasonId);
		} else {
			setShowMoreHeats(false);
			setIsSubmitLoading(false);
			setIsPartialCharge(false);
		}
	}, [show]);
	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						title="EDIT POURBACK CHARGE"
						open={show}
						handleClose={() => onHide()}
						id="edit-pourback-modal"
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content dividers style={{ padding: "15px 30px" }}>
								<Grid container spacing={1}>
									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<Controller
												name="HeatId"
												control={control}
												rules={{ required: "Heat is required" }}
												render={({ field }) => (
													<Autocomplete
														id="clear-on-escape"
														clearOnEscape
														options={heats ?? []}
														getOptionLabel={(option) => option.Name}
														renderInput={(params) => (
															<TextField
																{...params}
																inputRef={field.ref}
																label="Heat"
																variant="outlined"
																fullWidth
																size="small"
																InputProps={{
																	...params.InputProps,
																	className: classes.autocompleteField,
																}}
															/>
														)}
														value={
															heats?.find((heat) => heat.Id === field.value) ||
															null
														}
														onChange={(event, newValue) => {
															field.onChange(newValue ? newValue.Id : null);
															setValue(
																"SourceProcessId",
																newValue?.SourceProcessId!,
															);
															if (newValue?.PourbackWeight)
																setValue(
																	"PourbackWeight",
																	newValue?.PourbackWeight,
																);
														}}
													/>
												)}
											/>
											{errors.HeatId && (
												<span className={classes.errorLabel}>
													{errors.HeatId.message}
												</span>
											)}
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														checked={showMoreHeats}
														onChange={() => {
															getPourbacksHeats(!showMoreHeats);
															if (!showMoreHeats)
																setTimeout(() => setFocus("HeatId"), 0);
															setShowMoreHeats(!showMoreHeats);
														}}
														name="ShowMoreHeats"
														color="primary"
													/>
												}
												label="Show More Heats"
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<Controller
												name="PourbackWeight"
												control={control}
												rules={{
													validate: (value) =>
														(value >= 0 && value <= 200) ||
														"Must be between 0 and 200",
												}}
												render={({ field }) => (
													<NumberTextFieldDE
														{...field}
														value={getValues("PourbackWeight")}
														label="Pourback Weight [tons]"
														onChange={(val) =>
															setValue("PourbackWeight", val, {
																shouldValidate: true,
															})
														}
														onBlur={() => {}}
													/>
												)}
											/>
											{errors.PourbackWeight && (
												<span className={classes.errorLabel}>
													{errors.PourbackWeight.message}
												</span>
											)}
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														checked={isPartialCharge}
														onChange={() => {
															if (!isPartialCharge)
																setTimeout(() => setFocus("ChargedWeight"), 0);
															setIsPartialCharge(!isPartialCharge);
														}}
														name="IsPartialCharge"
														color="primary"
													/>
												}
												label="Is Partial Charge"
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<Controller
												name="ChargedWeight"
												control={control}
												rules={{
													required: isPartialCharge
														? "Remaining Weight is required"
														: false,
												}}
												render={({ field }) => (
													<NumberTextFieldDE
														{...field}
														disabled={!isPartialCharge}
														value={getValues("ChargedWeight")}
														label="Remaining Weight [tons]"
														onChange={(val) =>
															setValue("ChargedWeight", val, {
																shouldValidate: true,
															})
														}
														onBlur={() => {}}
														inputRef={field.ref}
													/>
												)}
											/>
											{errors.ChargedWeight && (
												<span className={classes.errorLabel}>
													{errors.ChargedWeight.message}
												</span>
											)}
										</Grid>
										<Grid item xs={12} md={6} style={{ marginTop: 5 }}>
											<Controller
												name="ChargedTime"
												control={control}
												rules={{ required: "Time is required" }}
												render={({ field }) => (
													<DateTimePickerDE
														{...field}
														format={"HH:mm"}
														disableOpenPicker
														value={getValues("ChargedTime")}
														label="Time"
														onChange={(val) =>
															setValue("ChargedTime", val, {
																shouldValidate: true,
															})
														}
														onBlur={() => {}}
													/>
												)}
											/>
											{errors.ChargedTime && (
												<span className={classes.errorLabel}>
													{errors.ChargedTime.message}
												</span>
											)}
										</Grid>
									</Grid>
								</Grid>
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<FooterModalButtons
									isSubmitLoading={isSubmitLoading}
									onHide={onHide}
									className={classesButton.defaultButton}
								/>
							</MesfModal.Actions>
						</form>
					</MesfModal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The pourback was edited successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Editing Pourback Charge"
			/>
		</>
	);
};
