import {
	ButtonWithLoading,
	ErrorModal,
	ResponseMESF,
} from "@dexteel/mesf-core";
import { Grid, IconButton } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useState } from "react";
import { useTimerContext } from "../../../context/timer.provider";

interface Props {
	showCreateModal: boolean;
	setShowCreateModal: (show: boolean) => void;
	hasMaxItems: boolean;
	readFromPLC?: (value?: string) => Promise<ResponseMESF>;
	table: "probes" | "charges" | "additions" | "heatings" | "pourbacks";
}

export const AgGridFooterActions = ({
	showCreateModal,
	setShowCreateModal,
	hasMaxItems,
	readFromPLC,
	table,
}: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const { pause } = useTimerContext();

	const onClick = async () => {
		setIsLoading(true);
		if (readFromPLC) {
			const resp = await readFromPLC();
			if (!resp.ok) {
				setError(resp.message);
			}
		}
		setIsLoading(false);
	};

	return (
		<>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item md={6}>
					<IconButton
						color="primary"
						onClick={() => {
							pause();
							setShowCreateModal(!showCreateModal);
						}}
						disabled={hasMaxItems}
						title={`Add ${table}`}
					>
						<AddCircleOutlineOutlinedIcon style={{ cursor: "pointer" }} />
					</IconButton>
				</Grid>
				{readFromPLC && (
					<Grid
						item
						md={6}
						style={{ display: "flex", justifyContent: "flex-end" }}
					>
						<ButtonWithLoading
							isLoading={isLoading}
							fullWidth
							variant="outlined"
							color="primary"
							onClick={() => {
								onClick();
							}}
							title={`Get from ${table === "additions" ? "HMI" : "PLC"}`}
							size="small"
							style={{ width: "20%" }}
						>
							<GetAppIcon />
						</ButtonWithLoading>
					</Grid>
				)}
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
