import { dxtToLocalServerTime } from "@dexteel/mesf-core";

import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import { isNumber } from "lodash-es";

import {
	BooleanCellRenderer,
	CommentIconCellRenderer,
	NumericCellRenderer,
} from "../../../controls/ag-grid/components/ButtonCellRenderer";
import { CasterProduction } from "../../caster/models/CasterProduction";

interface Props {
	setComment: (comment: string) => void;
	setShowCommentModal: (open: boolean) => void;
	casterProductions: CasterProduction[];
}

export const useProductionTableData = ({
	casterProductions,
	setComment,
	setShowCommentModal,
}: Props) => {
	const columnData: (ColDef | ColGroupDef)[] = [
		{
			headerName: "Heat",
			type: ["centerAligned"],
			children: [
				{
					field: "HeatNameValue",
					headerName: "",
					width: 70,
					minWidth: 50,
					type: ["numericColumn", "numericWidth"],
					// cellRenderer: LinkCellRenderer,
					// // cellRendererParams: (params:any)=>({ link: `/caster-data-entry/${params.data.ProcessId}` }),
					// cellRendererParams: { linkProperty: "HeatNameLink" },
				},
			],
		},
		{
			headerName: "Grade",
			children: [
				{
					field: "GradeValue",
					headerName: "",
					width: 58,
					minWidth: 50,
				},
			],
		},
		{
			headerName: "Billet Size",
			children: [
				{
					field: "BilletSizeName",
					headerName: "",
					minWidth: 78,
				},
			],
		},
		{
			headerName: "Open",
			type: ["centerAligned"],
			children: [
				{
					field: "OpenFreeValue",
					headerName: "Free",
					width: 50,
					cellRenderer: BooleanCellRenderer,
					type: ["nonSorteable", "nonFilterable", "centerAligned"],
					cellClass: "ag-button-cell",
				},
				{
					field: "OpenBurnValue",
					headerName: "Burn",
					width: 50,
					cellRenderer: BooleanCellRenderer,
					type: ["nonSorteable", "nonFilterable", "centerAligned"],
					cellClass: "ag-button-cell",
				},
			],
		},
		{
			headerName: "Nozzle",
			children: [
				{
					field: "NozzleSize",
					headerName: "Billet Size Children",
					minWidth: 67,
				},
			],
		},
		{
			headerName: "Ladle Trim",
			children: [
				{
					field: "TrimTemperature",
					headerName: "Temp",
					minWidth: 50,
				},
				{
					field: "TrimSuperHeatValue",
					headerName: "SHeat",
					minWidth: 53,
					type: ["numericColumn", "numericWidth"],
				},
			],
		},
		{
			headerName: "Tundish",
			children: [
				{
					field: "TundishNumber",
					headerName: "Num",
					minWidth: 48,
					type: ["numericColumn", "numericWidth"],
				},
			],
		},
		{
			headerName: "Temperatures",
			children: [
				{
					field: "TemperaturesLiquidus",
					headerName: "Liquidus",
					minWidth: 65,
					type: ["numericColumn", "numericWidth"],
				},
				{
					field: "TemperaturesMinimum",
					headerName: "Min",
					minWidth: 44,
					type: ["numericColumn", "numericWidth"],
				},
				{
					field: "TemperaturesMaximum",
					headerName: "Max",
					minWidth: 45,
					type: ["numericColumn", "numericWidth"],
				},
				{
					field: "TemperaturesMinSHValue",
					headerName: "MinSH",
					minWidth: 55,
					type: ["numericColumn", "numericWidth"],
				},
			],
		},
		{
			headerName: "Times",
			children: [
				{
					field: "LadleOpen",
					headerName: "Open",
					minWidth: 100,
					valueGetter: (params) =>
						dxtToLocalServerTime(`${params.data.LadleOpen}`, "HH:mm") || "",
				},
				{
					field: "LadleClose",
					headerName: "Close",
					minWidth: 100,
					valueGetter: (params) =>
						dxtToLocalServerTime(`${params.data.LadleClose}`, "HH:mm") || "",
				},
				{
					field: "DurationValue",
					headerName: "Duration",
					minWidth: 68,
					valueFormatter: (params) => params.data.DurationValue?.toFixed(1),
				},
			],
		},
		{
			headerName: "Avg Speed",
			children: [
				{
					field: "AvgSpeedS1Value",
					headerName: "S1",
					minWidth: 36,
					type: ["numericColumn", "numericWidth"],
					cellRenderer: NumericCellRenderer,
					cellRendererParams: { numericFormat: "0.0" },
				},
				{
					field: "AvgSpeedS2Value",
					headerName: "S2",
					minWidth: 36,
					type: ["numericColumn", "numericWidth"],
					cellRenderer: NumericCellRenderer,
					cellRendererParams: { numericFormat: "0.0" },
				},
				{
					field: "AvgSpeedS3Value",
					headerName: "S3",
					minWidth: 36,
					type: ["numericColumn", "numericWidth"],
					cellRenderer: NumericCellRenderer,
					cellRendererParams: { numericFormat: "0.0" },
				},
			],
		},
		{
			headerName: "Products",
			children: [
				{
					field: "ProductsPieces",
					headerName: "Pieces",
					minWidth: 55,
					type: ["numericColumn", "numericWidth"],
				},
				{
					field: "ProductsTons",
					headerName: "Tons",
					minWidth: 60,
					type: ["numericColumn", "numericWidth"],
					valueGetter: (params) => {
						if (
							isNumber(params.data.ProductsTons) &&
							params.data.ProductsTons > 0
						)
							return Number.parseFloat(params.data.ProductsTons).toFixed(1);
						else return 0;
					},
				},
			],
		},
		{
			field: "ScrapTons",
			headerName: "Scrap [t]",
			minWidth: 70,
			valueGetter: (params) => {
				if (isNumber(params.data.ScrapTons) && params.data.ScrapTons > 0)
					return Number.parseFloat(params.data.ScrapTons).toFixed(1);
				else return 0;
			},
		},
		{
			headerName: "Comments",
			children: [
				{
					field: "Comments",
					headerName: "",
					minWidth: 81,
					cellRendererFramework: (params: ICellRendererParams) => {
						return (
							<CommentIconCellRenderer
								comments={params.data.Comments}
								onClick={() => {
									setComment(params.data.Comments);
									setShowCommentModal(true);
								}}
							/>
						);
					},
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
		suppressMenu: true,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		minWidth: 30,

		filter: false,
		floatingFilter: false,
		sortable: false,
		editable: false,
	};
	return { columnData, defaultColDef };
};
