import isNumber from "lodash-es/isNumber";
import { upsertHeat } from "../../../meltshop/heats/repositories/heatsRepository";
import {
	updateTrimData,
	upsertProcess,
	upsertProcessData,
} from "../../repositories/ProductionRepository";
import { useCastersContext } from "../CastersContext";

const useSaveProcess = () => {
	const { state, actions } = useCastersContext();

	const saveProcessData = async (field: string, value: any): Promise<void> => {
		if (
			state.processData === null ||
			state.processData.ProcessId === null ||
			(state.processData.HeatId === null && field !== "TundishShroudId") ||
			value === null
		)
			return;
		actions.setIsLoadingBackground(true);
		const resp = await upsertProcessData(
			state.processData.ProcessId,
			field,
			value,
		);
		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			actions.setNotificationMessage(field + " was saved successfully");
		} else {
			actions.setErrorMessage(resp.message);
		}
	};
	const saveTrimData = async (field: string, value: any): Promise<void> => {
		if (
			state.processData === null ||
			state.processData.ProcessId === null ||
			value === null
		)
			return;
		actions.setIsLoadingBackground(true);
		const resp = await updateTrimData(
			state.processData.ProcessId,
			field,
			value,
		);
		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			actions.setNotificationMessage(field + " was saved successfully");
		} else {
			actions.setErrorMessage(resp.message);
		}
	};

	const saveHeat = async (field: string, value: any) => {
		if (
			state.processData === null ||
			state.processData.ProcessId === null ||
			value === null
		) {
			return;
		}

		actions.setIsLoadingBackground(true);

		const resp = await upsertHeat({
			HeatName: state.processData.HeatName,
			ShiftId: state.processData.ShiftId,
			GradeId: null,
			SteelWeight: state.processData.SteelWeight,
			LadleId: state.processData.LadleId,
			IsExternal: null,
			[field]: value,
			HeatId: state.processData.HeatId,
		});

		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			actions.setNotificationMessage(field + " was saved successfully");
		} else {
			actions.setErrorMessage(resp.message);
		}
	};

	const saveLadleHeat = async (field: string, value: any) => {
		if (
			state.processData === null ||
			state.processData.ProcessId === null ||
			value === null
		) {
			return;
		}

		actions.setIsLoadingBackground(true);

		const resp = await upsertHeat({
			HeatName: state.processData.HeatName,
			ShiftId: state.processData.ShiftId,
			GradeId: null,
			SteelWeight: state.processData.SteelWeight,
			LadleId: value,
			IsExternal: null,
			HeatId: state.processData.HeatId,
		});

		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			actions.setNotificationMessage(field + " was saved successfully");
		} else {
			actions.setErrorMessage(resp.message);
		}
	};

	const saveProcess = async (
		processId: number,
		ladleOpen: Date | null,
		ladleClose: Date | null,
	): Promise<boolean> => {
		actions.setIsLoadingBackground(true);

		if (!isNumber(processId)) return false;

		const resp = await upsertProcess(processId, ladleOpen, ladleClose);
		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			actions.setNotificationMessage(
				"Ladle Open and Close were saved successfully",
			);
		} else {
			actions.setErrorMessage(resp.message);
		}
		return resp.ok;
	};
	return {
		saveProcessData,
		saveProcess,
		saveHeat,
		saveLadleHeat,
		saveTrimData,
	};
};
export { useSaveProcess };
