import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogResultSourceReducer } from "./CatalogResultSourceReducer";

const CatalogResultSourceContext = createContext({
	state: CatalogResultSourceReducer.getInitialState(),
	actions: CatalogResultSourceReducer.actions,
});

export const useCatalogResultSourceContext = () =>
	useContext(CatalogResultSourceContext);

export const CatalogResultSourceProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogResultSourceReducer.getInitialState(),
		reducers: CatalogResultSourceReducer.caseReducers,
	});
	return (
		<CatalogResultSourceContext.Provider value={{ state, actions }}>
			{children}
		</CatalogResultSourceContext.Provider>
	);
};
