export const heatStatuses: { [key: string]: any } = {
	S: { name: "Scheduled", color: "#0288D1", letter: "S" },
	M: { name: "Melting", color: "#FF7043", letter: "M" },
	T: { name: "Tapped", color: "#D2AF00", letter: "T" },
	O: { name: "Opened", color: "#43A047", letter: "O" },
	C: { name: "Closed", color: "#607D8B", letter: "C" },
	R: { name: "Returned", color: "#8D6E63", letter: "R" },
	F: { name: "Finished", color: "#0059B3", letter: "F" },
	E: { name: "External", color: "blue", letter: "E" },
};
