import { Grid } from "@material-ui/core";
import { ColDef, GridApi } from "ag-grid-community";
import { useState } from "react";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FirstDataRenderedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useGridStyles } from "../../styles/gridStyles";

type Props = {
	rows: [];
	columnDefs: [];
	defaultColDef: ColDef<any>;
};

export const TableMonthReport = ({
	rows,
	columnDefs,
	defaultColDef,
}: Props) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {},
	});
	columnDefaults.floatingFilter = false;

	const classes = useGridStyles();
	return (
		<Grid
			container
			className={classes.root}
			style={{ width: "100%", display: "flex" }}
		>
			<Grid
				item
				md={12}
				xs={12}
				style={{
					height: "37vh",
					minHeight: "37vh",
					width: "100%",
					border: "4px solid #ccc",
					borderRadius: 10,
					padding: 10,
					margin: "20px 0 10px",
				}}
				className={"ag-theme-balham"}
			>
				<AgGridReact
					className={classes.root}
					rowSelection="single"
					defaultColDef={defaultColDef}
					rowData={rows || []}
					columnDefs={columnDefs}
					columnTypes={columnTypes}
					onGridReady={(params) => setGridApi(params.api)}
					rowHeight={35}
				/>
			</Grid>
		</Grid>
	);
};
