import React from "react";
import { AcquisitionPage } from "./AcquisitionPage";
import { AcquisitionStatsProvider } from "./context/AcquisitionStatsContext";

const Acquisition = () => {
	return (
		<AcquisitionStatsProvider>
			<AcquisitionPage />
		</AcquisitionStatsProvider>
	);
};

export default Acquisition;
