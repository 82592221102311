import { isNil } from "lodash";

export const statusCodeFormatter = (statusCode: string) => {
	let statusName;
	if (isNil(statusCode)) statusName = " ";
	else if (statusCode === "S") statusName = "Scheduled";
	else if (statusCode === "M") statusName = "Melting";
	else if (statusCode === "T") statusName = "Tapped";
	else if (statusCode === "O") statusName = "Opened";
	else if (statusCode === "C") statusName = "Closed";
	else if (statusCode === "R") statusName = "Returned";
	else if (statusCode === "F") statusName = "Finished";

	return statusName;
};
