import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
	CATALOG_MATERIAL_INITIAL_VALUES,
	CatalogMaterial,
} from "../../../models/CatalogMaterial";
import {
	getCatalogMaterials,
	upsertCatalogMaterial,
} from "../../../repositories/CatalogMaterialRepository";
import { useModalStyles } from "../../../styles/modalStyles";
import { useCatalogMaterialContext } from "../CatalogMaterialContext";
import { ConfirmMaterial } from "./ConfirmMaterial";
type Props = {
	show: boolean;
	onHide: (materialCreated: boolean) => void;
	refreshData: Function;
};
export const CreateMaterial = ({ show, onHide, refreshData }: Props) => {
	const classes = useModalStyles();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [proceed, setProceed] = useState(false);
	const { state } = useCatalogMaterialContext();
	const [existingMaterial, setExistingMaterial] = useState("");
	const {
		register,
		control,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CatalogMaterial>({
		defaultValues: CATALOG_MATERIAL_INITIAL_VALUES,
	});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const validateNoSpecialChars = (value: string) =>
		/^[a-zA-Z0-9 _-]*$/.test(value) || "No special characters";
	const validateOnlyNumbers = (value: number | null) => {
		const numericValue = Number(value);

		if (value === null) return "Value cannot be null";
		if (
			state.materials?.some(
				(material: CatalogMaterial) => material.L1MaterialId === numericValue,
			)
		) {
			return "ID already exists in materials";
		}
		return !isNaN(value) || "Only numbers are allowed";
	};
	const onSubmit: SubmitHandler<CatalogMaterial> = async (data: any) => {
		setIsSubmitLoading(true);
		const responseMaterials = await getCatalogMaterials();
		if (responseMaterials.ok) {
			const materials: { MaterialName: string }[] =
				responseMaterials.data.tables[0].rows;
			if (materials.some((material) => material.MaterialName === data.Name)) {
				setExistingMaterial(data.Name);
				setConfirmOpen(true);
			} else {
				await performUpsert(data);
			}
		} else {
			setError(responseMaterials.message);
		}
	};
	const performUpsert = async (data: CatalogMaterial) => {
		const createMaterialResp = await upsertCatalogMaterial(data);
		if (createMaterialResp.ok) {
			onHide(true);
			setOpen(true);
			refreshData();
		} else {
			setError(createMaterialResp.message);
			onHide(false);
		}
		setIsSubmitLoading(false);
	};
	useEffect(() => {
		if (confirmOpen && proceed) {
			setConfirmOpen(false);
			performUpsert(getValues());
			onHide(true);
		} else onHide(false);
		setIsSubmitLoading(false);
	}, [confirmOpen, proceed]);
	useEffect(() => {
		if (show) {
			reset();
		}
	}, [show]);
	return (
		<>
			<Grid container>
				<Grid item>
					<ConfirmMaterial
						show={confirmOpen}
						onHide={(proceed: boolean) => {
							setConfirmOpen(proceed);
							setProceed(proceed);
						}}
						materialName={existingMaterial}
					/>
					<MesfModal
						title="NEW MATERIAL"
						open={show}
						handleClose={() => onHide(false)}
						id="create-material-modal"
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content
								className={classes.modalBody}
								style={{ padding: "15px 30px" }}
							>
								<>
									<Grid container spacing={1}>
										<Grid item xs={9}>
											<TextField
												{...register("MaterialName", {
													required: "Name is required",
													validate: validateNoSpecialChars,
												})}
												label="Name"
												variant="outlined"
												error={!!errors.MaterialName}
												fullWidth
												margin="dense"
												autoComplete="off"
											/>
											{errors.MaterialName && (
												<span className={classes.errorLabel}>
													{errors.MaterialName.message}
												</span>
											)}
										</Grid>
										<Grid item xs={3}>
											<TextField
												{...register("L1MaterialId", {
													required: "Material Id is required",
													validate: validateOnlyNumbers,
												})}
												label="L1 MaterialId"
												variant="outlined"
												error={!!errors.L1MaterialId}
												fullWidth
												margin="dense"
												autoComplete="off"
											/>
											{errors.L1MaterialId && (
												<span className={classes.errorLabel}>
													{errors.L1MaterialId.message}
												</span>
											)}
										</Grid>
									</Grid>
									<Grid item xs={12} md={12}>
										<Controller
											name="IsAlloy"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value}
															onChange={field.onChange}
															name="IsAlloy"
															color="primary"
														/>
													}
													label="Is Alloy"
												/>
											)}
										/>
										<Controller
											name="UseInEAF"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value}
															onChange={field.onChange}
															name="UseInEAF"
															color="primary"
														/>
													}
													label="Use in EAF"
												/>
											)}
										/>
										<Controller
											name="UseInLF"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value}
															onChange={field.onChange}
															name="UseInLF"
															color="primary"
														/>
													}
													label="Use in LF"
												/>
											)}
										/>
									</Grid>
								</>
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(false)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading ? (
													<CircularProgress size="1rem" />
												) : (
													<></>
												)
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The material was created successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Material"
			/>
		</>
	);
};
