import * as React from "react";
import { L3ChemistryProvider } from "./L3ChemistryContext";
import { L3ChemistryPage } from "./L3ChemistryPage";

type Props = {};
const L3Chemistry = (props: Props) => {
	return (
		<L3ChemistryProvider>
			<L3ChemistryPage />
		</L3ChemistryProvider>
	);
};
export default L3Chemistry;
