import { HeatSearch } from "@/controls/heatSearch/components/HeatSearchTextField";
import {
	ErrorModal,
	ShiftDayNavigatorControl,
	ShiftInfo,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HeatsByShiftTable } from "./components/HeatsByShiftTable";
import { useGradeContext } from "./context/GradesContext";
import { useHeatsByShiftContext } from "./context/HeatsByShiftContext";
import { useLadleContext } from "./context/LadlesContext";
import {
	getGrades,
	getHeatsByShift,
	getLadles,
	getLastestHeats,
	searchHeats,
} from "./repositories/heatsRepository";

export const HeatsDataEntryPage = () => {
	const navigate = useNavigate();
	const {
		state: { heats, lastestHeats },
		actions: { setHeatsByShift, setLastestHeats },
	} = useHeatsByShiftContext();
	const {
		actions: { setLadles },
	} = useLadleContext();
	const {
		actions: { setGrades },
	} = useGradeContext();

	const { shiftInfo, moveShift, getShiftDataFromAPI, initShiftNavigator } =
		useShiftNavigator();

	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showExternalsHeats, setShowExternalsHeats] = useState(false);

	const [firstRender, setFirstRender] = useState(true);
	const { shiftId: shiftIdParam } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};
	const getHeatsByShiftFromAPI = async (shift?: ShiftInfo) => {
		setIsLoading(true);
		const shiftId = get(shift ? shift : shiftInfo, "CurrentShiftId", null);
		const heatsResp = await getHeatsByShift(
			Number(shiftId),
			null,
			showExternalsHeats,
		);
		if (heatsResp.ok) {
			setHeatsByShift(heatsResp.data.tables[0].rows);
		} else {
			setError(heatsResp.message);
		}
		setIsLoading(false);
	};

	const getLastestHeatsFromAPI = async () => {
		setIsLoading(true);
		const lastestHeatsResp = await getLastestHeats(showExternalsHeats);
		if (lastestHeatsResp.ok) {
			const data = get(lastestHeatsResp, "data.tables[0].rows", null);
			setLastestHeats(data);
		} else {
			setError(lastestHeatsResp.message);
		}
		setIsLoading(false);
	};
	const handleShowExternalsHeats = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setShowExternalsHeats(event.target.checked);
	};
	const getHeats = async (searchTerm: string) => {
		if (searchTerm.length >= 3) {
			const searchHeatsResp = await searchHeats(searchTerm, showExternalsHeats);
			if (searchHeatsResp.ok) {
				const data = get(searchHeatsResp, "data.tables[0].rows", null);
				return data;
			} else {
				setError(searchHeatsResp.message);
				return [];
			}
		} else {
			return lastestHeats;
		}
	};

	const getCatalogs = async () => {
		const [ladleResp, gradesResp] = await Promise.all([
			getLadles(),
			getGrades(),
		]);

		if (ladleResp.ok && gradesResp.ok) {
			setLadles(ladleResp.data?.tables[0].rows);
			setGrades(gradesResp.data.tables[0].rows);
		} else {
			setError(gradesResp.message || ladleResp.message);
		}
	};

	useEffect(() => {
		if (shiftInfo && !firstRender) {
			const shiftId = get(shiftInfo, "CurrentShiftId", null);
			const heatIdSearch = searchParams.get("heatId");
			const query = heatIdSearch ? `?heatId=${heatIdSearch}` : "";
			navigate(`/meltshop-heats/${shiftId}${query}`);
			(async () => {
				await getHeatsByShiftFromAPI();
				await getLastestHeatsFromAPI();
			})();
		}
	}, [shiftInfo, showExternalsHeats]);

	useEffect(() => {
		if (shiftIdParam) {
			(async () => {
				const shiftId = Number(shiftIdParam);
				getShiftDataFromAPI(null, shiftId).then(async (shift) => {
					if (shift) {
						getCatalogs().then(() => {
							getHeatsByShiftFromAPI(shift).then(() => {
								getLastestHeatsFromAPI();
								setFirstRender(false);
							});
						});
					}
				});
			})();
		} else {
			(async () => {
				getShiftDataFromAPI(null, 0).then(async (shift) => {
					if (shift) {
						getCatalogs().then(() => {
							getHeatsByShiftFromAPI(shift).then(() => {
								getLastestHeatsFromAPI();
								setFirstRender(false);
							});
						});
					}
				});
			})();
		}
	}, []);

	return (
		<div onContextMenu={handleContainerContextMenu}>
			<Paper
				elevation={1}
				style={{
					width: "100%",
					padding: "15px 20px 10px",
					borderRadius: "0.75rem",
					height: "93vh",
				}}
			>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0 }}>
						<Grid item md={12} xs={12}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Typography variant="h5" style={{ fontWeight: 600 }}>
									Heats
								</Typography>
							</Grid>
						</Grid>
						<Grid item md={12} xs={12}>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
							>
								<ShiftDayNavigatorControl preventInit />
								<Paper
									style={{
										minWidth: "550px",
										margin: "10px 10px",
										height: "90px",
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Grid
										item
										container
										xs={12}
										spacing={2}
										justifyContent="flex-start"
										alignItems="center"
										style={{ marginLeft: "10px" }}
									>
										<Grid item xs={6}>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															checked={showExternalsHeats}
															color="primary"
															onChange={handleShowExternalsHeats}
														/>
													}
													label="Show external heats"
												/>
											</FormGroup>
										</Grid>
										<Grid item xs={6}>
											<HeatSearch
												heats={lastestHeats}
												getHeats={getHeats}
												loading={isLoading}
												onBlur={() => {}}
												onChange={(heat) => {
													const query = heat.Id ? `?heatId=${heat.Id}` : "";
													window.location.href = `/meltshop-heats/${heat.ShiftId}${query}`;
												}}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
						<HeatsByShiftTable
							isLoading={isLoading}
							getHeatsByShift={getHeatsByShiftFromAPI}
						/>
					</Grid>
				</Grid>
			</Paper>

			<ErrorModal error={error} onHide={() => setError("")} />
		</div>
	);
};
