export enum DataTypeField {
	String = "string",
	Number = "number",
	Boolean = "boolean",
	DateTime = "datetime",
	Date = "date",
}
export interface ReportColumn {
	ParentName?: string;
	HeaderName: string;
	Field: string;
	Type: DataTypeField;
	Width: string;
	Format: string;
	CellStyle: string;
}
export interface DisplayOption {
	autoGeneratedColumns?: boolean;
	showPaging?: boolean;
	autoPaging?: boolean;
	transposed?: boolean;
	transposeBy?: string;
	autoAlignHeaderNumber?: boolean;
	pageSize?: number;
	pageIndex?: number; //non-zero index,
}
export interface EventOption {
	onRowClick?: (data: any) => void;
}
export interface StylingOption {
	containerClass?: string;
	tableClass?: string;
	cellClass?: string;
}
export interface ReportTableProps
	extends EventOption,
		StylingOption,
		DisplayOption {
	name: string;
	columns: ReportColumn[];
	rows: any[];
}
