import { useUTLSettingsContext } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

function validateBoolean(value: any) {
	if (typeof value === "boolean") {
		return value;
	}
	if (typeof value === "string") {
		value = value.toLowerCase();
		if (
			value === "true" ||
			value === "1" ||
			value === "True" ||
			value === "TRUE" ||
			value === "yes"
		) {
			return true;
		} else if (value === "false" || value === "0") {
			return false;
		}
	} else if (typeof value === "number") {
		return !!value;
	}
	return false; // Default to false for other cases
}

const CustomNavbar = () => {
	const {
		state: { settings },
	} = useUTLSettingsContext();

	const FF_LOGBOOK_ENABLED = validateBoolean(
		get(settings, "FF_LOGBOOK_ENABLED", false),
	);

	return (
		<>
			<Nav.Link as={Link} to="/meltshop-dashboard">
				Home
			</Nav.Link>
			<NavDropdown title="MeltShop" id="nav-dropdown">
				<NavDropdown.Item as={Link} to="/meltshop-heats">
					Heats
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/month-report">
					Month Report
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="EAF" id="nav-dropdown">
				<NavDropdown.Item as={Link} to="/eaf-data-entry">
					Data Entry
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/eaf-processes">
					Processes
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Caster" id="nav-dropdown">
				<NavDropdown.Item as={Link} to="/caster-data-entry">
					Data Entry
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/caster-processes">
					Processes
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/caster-counters">
					Caster Counters
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="LAB" id="nav-dropdown-lab">
				<NavDropdown.Item as={Link} to="/heat-report/chemistry">
					Heat Report
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/lab-l3/chemistry">
					L3 Chemistries
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/lab-l3/mechanical">
					L3 Mechanical
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="LAB Reports" id="nav-dropdown-lab">
				<NavDropdown.Item as={Link} to="/report/mes-lab-activity">
					Chemistry Lab Activity
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/report/mes-lab-mechanical-activity">
					Mechanical Lab Activity
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/report/mes-lab-nuno-chemistries">
					Report Nuno Chemistries
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/report/mes-report-predictor">
					Predictor Report
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/report/mes-lab-hrr">
					HRR Report
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/report/mes-lab-missed-heats">
					Missed Heats Report
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/report/mes-report-last-chemistries">
					Last 20 Chemistries
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Delays" id="nav-dropdown">
				<NavDropdown.Item as={Link} to="/delays-manager">
					Manager
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/delays-report">
					Report
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/delays-pareto">
					Pareto
				</NavDropdown.Item>
			</NavDropdown>
			{FF_LOGBOOK_ENABLED && (
				<Nav.Link as={Link} to="/logbook">
					Logbook
				</Nav.Link>
			)}
		</>
	);
};
export { CustomNavbar };
