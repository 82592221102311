import { Suspense } from "react";
import * as React from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import EAFDataEntry from "../../pages/eaf/data-entry";
import EAFProcesses from "../../pages/eaf/eaf-processes";

export const EafRoutes = [
	<Route
		path="/eaf-data-entry"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<EAFDataEntry />
			</Suspense>
		}
	/>,
	<Route
		path="/eaf-data-entry/:processId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<EAFDataEntry />
			</Suspense>
		}
	>
		{" "}
	</Route>,
	<Route
		path="/eaf-processes"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<EAFProcesses />
			</Suspense>
		}
	/>,
	<Route
		path="/eaf-processes/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<EAFProcesses />
			</Suspense>
		}
	/>,
];
