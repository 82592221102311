import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { LadlesReducer } from "../reducers/LadlesReducer";

const LadleContext = createContext({
	state: LadlesReducer.getInitialState(),
	actions: LadlesReducer.actions,
});

export const useLadleContext = () => useContext(LadleContext);

export const LadleProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: LadlesReducer.getInitialState(),
		reducers: LadlesReducer.caseReducers,
	});

	return (
		<LadleContext.Provider value={{ state, actions }}>
			{children}
		</LadleContext.Provider>
	);
};
