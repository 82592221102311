import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { MenuOptionType } from "../../../../../controls/contextMenu/models/MenuOptionType";
import { CatalogLadle } from "../../../models/CatalogLadle";
type Props = {
	setLadleId: Function;
	setShowModal: (type: "update" | "create" | "delete") => void;
};
export const useLadlesOptionsFunctions = ({
	setLadleId,
	setShowModal,
}: Props) => {
	const getMenuOptions = (data: CatalogLadle) => {
		let options: MenuOptionType[] = [];
		if (!data) {
			options = options.concat([
				{
					name: "New Ladle",
					key: "new_ladle",
					onClick: () => {
						setShowModal("create");
					},
					icon: <PlaylistAddIcon />,
				},
			]);
		} else {
			options = options.concat([
				{
					name: "New Ladle",
					key: "new_ladle",
					onClick: () => {
						setShowModal("create");
					},
					icon: <PlaylistAddIcon />,
				},
				{
					name: "Edit Ladle",
					key: "edit_ladle",
					onClick: () => {
						setLadleId(data.LadleId);
						setShowModal("update");
					},
					icon: <EditIcon />,
				},
				{
					name: "Delete Ladle",
					key: "delete_ladle",
					onClick: () => {
						setLadleId(data.LadleId);
						setShowModal("delete");
					},
					icon: <DeleteIcon />,
				},
			]);
		}
		return options;
	};
	return {
		getMenuOptions,
	};
};
