import { Grid, makeStyles } from "@material-ui/core";
import { Moment } from "moment";
import React from "react";
import { DelayAreaFilter } from "./DelayAreaFilter";
import { EndFilter } from "./EndFilter";
import { StartFilter } from "./StartFilter";

const useStyles = makeStyles((theme) => ({
	filtersContainer: {
		display: "flex",
		gap: theme.spacing(2),
		alignItems: "flex-end",
		marginBottom: theme.spacing(2),
	},
	filterItem: {
		minWidth: 200,
	},
}));

type Props = {
	onFilterChange: (startDate: Moment, endDate: Moment) => void;
	initialStartDate: Moment;
	initialEndDate: Moment;
	delayAreas: Array<{ AssetId: number; AssetName: string }>;
	selectedDelayArea: number | null;
	onDelayAreaChange: (assetId: number | null) => void;
};

export const Filters: React.FC<Props> = ({
	onFilterChange,
	initialStartDate,
	initialEndDate,
	delayAreas,
	selectedDelayArea,
	onDelayAreaChange,
}) => {
	const classes = useStyles();

	const handleStartDateChange = (date: Moment | null) => {
		if (date) {
			onFilterChange(date, initialEndDate);
		}
	};

	const handleEndDateChange = (date: Moment | null) => {
		if (date) {
			onFilterChange(initialStartDate, date);
		}
	};

	return (
		<Grid container className={classes.filtersContainer} spacing={2}>
			<Grid item style={{ maxWidth: 200 }} className={classes.filterItem}>
				<StartFilter
					value={initialStartDate}
					onChange={handleStartDateChange}
				/>
			</Grid>
			<Grid item style={{ maxWidth: 200 }} className={classes.filterItem}>
				<EndFilter value={initialEndDate} onChange={handleEndDateChange} />
			</Grid>
			<Grid item className={classes.filterItem}>
				<DelayAreaFilter
					delayAreas={delayAreas}
					selectedDelayArea={selectedDelayArea}
					onChange={onDelayAreaChange}
				/>
			</Grid>
		</Grid>
	);
};
