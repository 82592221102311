import {
	ErrorModal,
	ShiftDayNavigatorControl,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { EAFProcessesTable } from "./components/EAFProcessesTable";
import { useEAFProcessesContext } from "./context/EAFProcessesContext";
import { getProcessesByShift } from "./repositories/EAFRepository";

export const EAFProcessesPage = () => {
	const { state, actions } = useEAFProcessesContext();
	const [showDeletedProcesses, setShowDeletedProcesses] = useState(false);
	const { shiftInfo } = useShiftNavigator();

	const { shiftId } = useParams();
	const firstRender = useRef(true);

	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getProcessesByShift(
			shiftInfo?.CurrentShiftId || 0,
			showDeletedProcesses,
		);
		if (resp.ok && resp.data.tables.length > 0) {
			actions.setProcesses(resp.data.tables[0].rows);
		} else {
			console.log("something happened 2");
		}
		actions.setIsLoadingGrid(false);
	};

	useEffect(() => {
		(async () => {
			await getDataFromAPI();
		})();
	}, [shiftInfo?.CurrentShiftId, showDeletedProcesses]);

	if (!state.isLoadingPage) {
		return (
			<>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "15px 20px 10px",
						borderRadius: "0.75rem",
						height: "93vh",
					}}
				>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										EAF Processes
									</Typography>
								</Grid>
							</Grid>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<ShiftDayNavigatorControl useRouter />
									<Grid item xs={4} style={{ margin: 0, padding: "15px" }}>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={showDeletedProcesses}
														color="primary"
														onChange={(event) =>
															setShowDeletedProcesses(event.target.checked)
														}
													/>
												}
												label="Show deleted processes"
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
							<ErrorModal
								error={state.errorMessage}
								onHide={() => actions.setErrorMessage("")}
							/>
							<NotificationSnackBar
								message={state.notificationMessage}
								onHide={() => actions.setNotificationMessage("")}
							/>
							<EAFProcessesTable
								loadingGrid={state.isLoadingGrid}
								rows={state.processes || []}
								refreshData={getDataFromAPI}
							/>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
	return <LazyLoading />;
};
