import React from "react";

export enum ViewMode {
	Hour = "Hour",
	QuarterDay = "Quarter Day",
	HalfDay = "Half Day",
	Day = "Day",
	/** ISO-8601 week */
	Week = "Week",
	Month = "Month",
}
export type TaskType = "task" | "taskLine";
export interface Asset {
	id: string;
	name: string;
	processes: Task[];
	color: string;
}
export interface Task {
	id: string;
	type: TaskType;
	name: string;
	start: Date;
	end: Date;
	processStart: Date;
	source: any;
	progress: number;
	styles?: {
		backgroundColor?: string;
		backgroundSelectedColor?: string;
		progressColor?: string;
		progressSelectedColor?: string;
		borderColor?: string;
		borderWidth?: number;
	};
	isDisabled?: boolean;
	dependencies?: string[];
	displayOrder?: number;
	textLeftTop?: string;
	textRightTop?: string;
	textLeftBottom?: string;
	textRightBottom?: string;
	processLines: ProcessLine[];
}
export interface ProcessLine {
	id: string;
	name: string;
	start: Date;
	end: Date;
	isDisabled?: boolean;
	type: TaskType;
	eventCode: string;
	DelayCodeName?: string;
	comments: string;
	styles?: {
		backgroundColor?: string;
		backgroundSelectedColor?: string;
		progressColor?: string;
		progressSelectedColor?: string;
		borderColor?: string;
		borderWidth?: number;
	};
}

export interface EventOption {
	/**
	 * Invokes on bar select on unselect.
	 */
	onSelect?: (task: Task, isSelected: boolean) => void;
	/**
	 * Invokes on bar double click.
	 */
	onDoubleClick?: (task: Task) => void;
	onContextMenu?: (event: any, data: any) => void;
}

export interface DisplayOption {
	viewMode?: ViewMode;
	/**
	 * Specifies the month name language. Able formats: ISO 639-2, Java Locale
	 */
	locale?: string;
	startCalendarDate?: Date;
	endCalendarDate?: Date;
}

export interface StylingOption {
	headerHeight?: number;
	columnWidth?: number;
	showAssetList?: boolean;
	assetCellWidth?: number;
	rowHeight?: number;
	ganttHeight?: number;
	barCornerRadius?: number;
	handleWidth?: number;
	fontFamily?: string;
	fontSize?: string;
	/**
	 * How many of row width can be taken by task.
	 * From 0 to 100
	 */
	barFill?: number;
	barProgressColor?: string;
	barProgressSelectedColor?: string;
	barBackgroundColor?: string;
	barBackgroundSelectedColor?: string;
	barBorderColor?: string;
	borderWidth?: number;

	/**
	 * How many of row width can be taken by line(s).
	 * From 0 to 100
	 */
	lineSectionFill?: number;
	/**
	 * How many of row width can be taken by line(s).
	 * From 0 to 100
	 */
	lineFill?: number;

	showArrowTriangle?: boolean;
	arrowColor?: string;
	arrowIndent?: number;
	todayColor?: string;
	TooltipContent?: React.FC<{
		task: Task;
		fontSize: string;
		fontFamily: string;
	}>;
	AssetListHeader?: React.FC<{
		headerHeight: number;
		rowWidth: number;
		fontFamily: string;
		fontSize: string;
	}>;
	AssetListTable?: React.FC<{
		rowHeight: number;
		rowWidth: number;
		fontFamily: string;
		fontSize: string;
		locale: string;
		assets: Asset[];
		selectedAssetId: string;
		/**
		 * Sets selected task by id
		 */
		setSelectedAsset: (taskId: string) => void;
	}>;
}

export interface GanttProps extends EventOption, DisplayOption, StylingOption {
	assets: Asset[];
}
