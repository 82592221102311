import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogGradeReducer } from "./CatalogGradeReducer";

const CatalogGradeContext = createContext({
	state: CatalogGradeReducer.getInitialState(),
	actions: CatalogGradeReducer.actions,
});

export const useCatalogGradeContext = () => useContext(CatalogGradeContext);

export const CatalogGradeProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogGradeReducer.getInitialState(),
		reducers: CatalogGradeReducer.caseReducers,
	});
	return (
		<CatalogGradeContext.Provider value={{ state, actions }}>
			{children}
		</CatalogGradeContext.Provider>
	);
};
