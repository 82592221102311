export interface CatalogMechanicalTest {
	MechanicatTestId: number | null;
	MechanicalTestName: string;
	Decimals: number;
	Order: number;
	UseInCircular: boolean;
	Enabled: boolean;
}
export const CATALOG_MECHANICAL_TEST_INITIAL_VALUES: CatalogMechanicalTest = {
	MechanicatTestId: null,
	MechanicalTestName: "",
	Decimals: 4,
	Order: 0,
	UseInCircular: false,
	Enabled: true,
};
