import { ErrorModal, MesfModal, useAssetContext } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { CatalogCategory } from "../../../models/CatalogCategory";
import { INITIAL_VALUES } from "../../../models/FormInitialValues";
import { useModalStyles } from "../../../styles/modalStyles";
import {
	deleteCatalogCategory,
	getCatalogCategory,
} from "../../repositories/CatalogCategoriesRepository";
import ColorPicker from "../ColorPicker/ColorPicker";
import { ConfirmModal } from "./ConfirmModal";

type Props = {
	DelayCategoryId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const DeleteCategory = ({ DelayCategoryId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [categorySelected, setCategorySelected] = useState<any>();
	const [deleteAlert, setDeleteAlert] = useState(false);
	const [selectedAssetName, setSelectedAssetName] = useState("");

	const {
		state: { allAssets },
	} = useAssetContext();

	const { control, setValue, handleSubmit } = useForm<CatalogCategory>({
		defaultValues: INITIAL_VALUES,
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit: SubmitHandler<CatalogCategory> = async () => {
		setDeleteAlert(true);
	};

	const handleDelete = async (proceed: boolean) => {
		if (proceed) {
			setIsSubmitLoading(true);
			try {
				const deleteCategoryResp = await deleteCatalogCategory(DelayCategoryId);
				if (deleteCategoryResp.ok) {
					onHide(true);
					setOpen(true);
				} else {
					setError(deleteCategoryResp.message);
				}
			} catch (e: any) {
				setError(e.message);
			} finally {
				setIsSubmitLoading(false);
			}
		}
		setDeleteAlert(false);
	};

	useEffect(() => {
		if (show && DelayCategoryId) {
			setIsLoading(true);
			setIsSubmitLoading(false);

			const selectedAsset = allAssets.find(
				(asset: any) => asset.AssetId === categorySelected?.AssetId,
			);
			if (selectedAsset) {
				setSelectedAssetName(selectedAsset.AssetName);
			}

			(async () => {
				try {
					const response = await getCatalogCategory(DelayCategoryId);
					if (response.ok) {
						const category = response.data.tables[0].rows[0];
						if (category) {
							setValue("DelayCategoryId", category.DelayCategoryId);
							setValue("AssetId", category.AssetId);
							setValue("DelayCategoryName", category.DelayCategoryName);
							setValue("Color", category.DelayCategoryColor);
							setCategorySelected(category);
						} else {
							setError("Category not found");
						}
					} else {
						setError(response.message);
					}
				} catch (e: any) {
					setError(e.message);
				} finally {
					setIsLoading(false);
				}
			})();
		}
	}, [show, categorySelected?.AssetId]);

	return (
		<>
			<MesfModal
				id="delete-category"
				title="DELETE CATEGORY"
				open={show}
				handleClose={() => onHide(false)}
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content style={{ padding: "15px 30px" }}>
						{isLoading ? (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						) : (
							<Grid container spacing={1}>
								<Grid item xs={12} md={12}>
									<TextField
										label="Name"
										disabled
										variant="outlined"
										fullWidth
										value={categorySelected?.DelayCategoryName || ""}
										margin="dense"
										autoComplete="off"
									/>
								</Grid>
								<Grid item xs={3} md={3}>
									<Controller
										name="Color"
										control={control}
										rules={{ required: "Color is required" }}
										render={({ field }) => (
											<FormControl fullWidth margin="dense">
												<ColorPicker
													onChange={(_: string, color: string) =>
														field.onChange(color)
													}
													color={field.value}
													disabled={true}
													label="Color"
												/>
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={3} md={3}>
									<Controller
										name="IsActive"
										control={control}
										render={({ field }) => (
											<FormControlLabel
												className={classes.checkbox}
												disabled
												control={
													<Checkbox
														checked={field.value}
														onChange={field.onChange}
														name="IsActive"
														color="primary"
													/>
												}
												label="is Active"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={6} md={6}>
									<TextField
										label="Asset"
										disabled
										variant="outlined"
										fullWidth
										value={selectedAssetName}
										margin="dense"
										autoComplete="off"
									/>
								</Grid>
							</Grid>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => onHide(false)}
								>
									CANCEL
								</Button>
							</Grid>
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									startIcon={
										isSubmitLoading && <CircularProgress size="1rem" />
									}
									variant="contained"
									color="secondary"
									onClick={handleSubmit(onSubmit)}
									disabled={isSubmitLoading}
								>
									DELETE
								</Button>
							</Grid>
						</Grid>
					</MesfModal.Actions>
				</form>
			</MesfModal>

			<ConfirmModal
				show={deleteAlert}
				onHide={handleDelete}
				title="WARNING"
				message={`Be careful, once deleted you will not be able to recover the category "${categorySelected?.DelayCategoryName}". Are you sure you want to delete it?`}
				isLoading={isSubmitLoading}
				color="secondary"
			/>

			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The category was deleted successfully
				</MuiAlert>
			</Snackbar>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
