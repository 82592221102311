import DelaysPareto from "@/pages/delays/delays-pareto";
import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import DelaysManager from "../../pages/delays/delays-manager";
import DelaysReport from "../../pages/delays/delays-report";

export const DelaysRoutes = [
	<Route
		path="/delays-manager"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<DelaysManager />
			</Suspense>
		}
	/>,
	<Route
		path="/delays-manager/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<DelaysManager />
			</Suspense>
		}
	>
		{" "}
	</Route>,
	<Route
		path="/delays-report"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<DelaysReport />
			</Suspense>
		}
	/>,
	<Route
		path="/delays-report/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<DelaysReport />
			</Suspense>
		}
	/>,
	<Route
		path="/delays-pareto"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<DelaysPareto />
			</Suspense>
		}
	/>,
	<Route
		path="/delays-pareto/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<DelaysPareto />
			</Suspense>
		}
	/>,
];
