import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CasterDashboardReducer } from "./CasterDashboardReducer";

const CasterDashboardContext = createContext({
	state: CasterDashboardReducer.getInitialState(),
	actions: CasterDashboardReducer.actions,
});

export const useCasterDashboardContext = () =>
	useContext(CasterDashboardContext);

export const CasterDashboardProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CasterDashboardReducer.getInitialState(),
		reducers: CasterDashboardReducer.caseReducers,
	});

	return (
		<CasterDashboardContext.Provider value={{ state, actions }}>
			{children}
		</CasterDashboardContext.Provider>
	);
};
