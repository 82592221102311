import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { HeatsByShiftReducer } from "../reducers/HeatsByShiftReducer";

const HeatsByShiftContext = createContext({
	state: HeatsByShiftReducer.getInitialState(),
	actions: HeatsByShiftReducer.actions,
});

export const useHeatsByShiftContext = () => useContext(HeatsByShiftContext);

export const HeatsByShiftProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: HeatsByShiftReducer.getInitialState(),
		reducers: HeatsByShiftReducer.caseReducers,
	});

	return (
		<HeatsByShiftContext.Provider value={{ state, actions }}>
			{children}
		</HeatsByShiftContext.Provider>
	);
};
