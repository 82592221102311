import {
	MesfModal,
	TimeService,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	makeStyles,
} from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers";

import { get, isNil } from "lodash-es";
import isNumber from "lodash-es/isNumber";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { AssetSelector } from "../../../../controls/shared/AssetSelector";
import { useEAFProcessesContext } from "../context/EAFProcessesContext";
import { ProcessByShift } from "../models/ProcessByShift";
import { upsertProcess } from "../repositories/EAFRepository";

const moment = getMomentTz();

const useStyles = makeStyles(() => ({
	dateTimePicker: {},
	"@global": {
		"*::-webkit-scrollbar": {
			width: "10px",
			background: "#fff",
		},
		"*::-webkit-scrollbar-thumb": {
			backgroundColor: "	#C8C8C8",
		},
		"*::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"*::-webkit-scrollbar:horizontal": {
			height: "10px",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	refreshData: Function;
};
export const UpsertEAFProcess = ({ show, onHide, refreshData }: Props) => {
	const classes = useStyles();
	const { shiftInfo } = useShiftNavigator();

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isNew, setIsNew] = useState(false);
	const [isNotEnd, setIsNotEnd] = useState(false);
	const [title, setTitle] = useState("");
	const {
		state: { selectedProcess },
		actions: { setNotificationMessage, setErrorMessage, setSelectedProcess },
	} = useEAFProcessesContext();
	const {
		register,
		setValue,
		control,
		handleSubmit,
		reset,
		getValues,
		formState: { errors },
	} = useForm<ProcessByShift>({
		defaultValues: {},
	});
	const CurrentStart = get(shiftInfo, "CurrentStart", null);
	const CurrentEnd = moment
		.utc(CurrentStart)
		.add(180, "minutes")
		.isAfter(moment.utc())
		? moment.utc()
		: moment.utc(CurrentStart).add(180, "minutes");

	const onSubmit: SubmitHandler<ProcessByShift> = async (
		data: ProcessByShift,
	) => {
		setIsSubmitLoading(true);
		const ladleOpen = data.Start;
		const ladleEnd = data.End;
		const response = await upsertProcess(
			data.ProcessId === 0 ? null : data.ProcessId,
			ladleOpen,
			ladleEnd,
			true,
			data.AssetId,
		);
		if (response.ok) {
			setNotificationMessage(
				"Process was " + (isNew ? "created" : "edited") + " successfully",
			);
			setSelectedProcess(null);
			await refreshData();
		} else {
			setErrorMessage(response.message);
		}
		setIsSubmitLoading(false);
		onHide(true);
	};
	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const process = selectedProcess;
				if (isNil(process)) {
					setIsLoading(false);
					return;
				}
				if (process.ProcessId === 0) {
					// new process
					setTitle("NEW EAF PROCESS");
					setIsNew(true);
				} else {
					// edit process
					const heatName = selectedProcess?.HeatName
						? ` - Heat ${selectedProcess.HeatName}`
						: "";
					setTitle(`EDIT EAF PROCESS${heatName}`);
					setIsNew(false);
				}
				setValue("ProcessId", process.ProcessId);
				setValue(
					"Start",
					process.Start
						? moment.utc(process.Start).toDate()
						: moment.utc(CurrentStart).toDate(),
				);
				setValue(
					"End",
					process.End ? moment.utc(process.End).toDate() : CurrentEnd.toDate(),
				);
				setValue("AssetId", process.AssetId);
				if (process.End === null) setIsNotEnd(true);
				setIsLoading(false);
			})();
		} else {
			reset();
			setIsNotEnd(false);
		}
	}, [show]);
	useEffect(() => {
		if (isNotEnd) {
			setValue("End", null);
		}
	}, [isNotEnd]);
	return (
		<>
			<Grid container>
				<Grid item className={classes.dateTimePicker}>
					<MesfModal
						maxWidth="lg"
						open={show}
						handleClose={() => {
							onHide(true);
						}}
						id="upsertEAFProcess"
						title={title}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content style={{ padding: "15px 30px" }}>
								{isLoading && (
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{ minHeight: "300px" }}
									>
										<LazyLoading />
									</Grid>
								)}
								{!isLoading && (
									<>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Controller
													name="AssetId"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) => (
														<AssetSelector
															error={!!errors.AssetId}
															type={"EAF " as "EAF"} // to show only EAF 1 and EAF 2 and avoid the “EAF” asset
															disabled={isNumber(selectedProcess?.AssetId)}
															value={field.value}
															onChange={field.onChange}
														/>
													)}
												/>
												{errors.AssetId && (
													<p style={{ color: "red" }}>Select a valid asset</p>
												)}
											</Grid>
											<Grid item xs={12} md={6}>
												<Controller
													name="Start"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) => (
														<DateTimePicker
															label="Start"
															timezone={TimeService.getInstance().getServerTimeZone()}
															format="MM/DD/YYYY HH:mm"
															timeSteps={{ minutes: 1 }}
															value={moment(field.value) as any}
															onChange={field.onChange}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	fullWidth: true,
																	variant: "outlined",
																	error: !!errors.Start,
																},
															}}
														/>
													)}
												/>
												{errors.Start && (
													<p style={{ color: "red" }}>
														Invalid Ladle Open Date
													</p>
												)}
											</Grid>
											<Grid item xs={12} md={6}>
												<Controller
													name="End"
													control={control}
													rules={{
														required: !isNotEnd,
														validate: (value) => {
															if (moment.utc(value).isAfter(moment.utc()))
																return "End date cannot be in the future";
															if (
																moment
																	.utc(value)
																	.isBefore(moment.utc(getValues("Start")))
															)
																return "End date cannot be before the Start";
														},
													}}
													render={({ field }) => (
														<DateTimePicker
															label="End"
															format="MM/DD/YYYY  HH:mm"
															timezone={TimeService.getInstance().getServerTimeZone()}
															value={moment(field.value) as any}
															onChange={field.onChange}
															disabled={isNotEnd}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	fullWidth: true,
																	variant: "outlined",
																	error: !!errors.End,
																},
															}}
														/>
													)}
												/>
												{errors.End && (
													<p style={{ color: "red" }}>{errors.End.message}</p>
												)}
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														{...register("IsOpen")}
														color="primary"
														checked={isNotEnd}
														onChange={(event) =>
															setIsNotEnd(event.target.checked)
														}
													/>
												}
												label="Process not finished"
											/>
										</Grid>
									</>
								)}
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(true)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
				</Grid>
			</Grid>
		</>
	);
};
