import { useAssetContext, useUTLSettingsContext } from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { get } from "lodash-es";
import { CategoriesProvider } from "../categories/context/CategoriesContext";
import { CatalogDelayPage } from "./CatalogDelayCodePage";
import { ConfigurationAssetProvider } from "./context/ConfigurationAssetContext";
import { ConfigurationDelayCodeProvider } from "./context/ConfigurationDelayCodeContext";

type Props = {
	delayAreaAssetName?: string;
};
const DEFAULT_DELAY_AREA_ASSET_ID = 14; // EAF

const DelayCodePage = (props: Props) => {
	const { state } = useAssetContext();
	const assetId =
		state.allAssets.find(
			(asset: Asset) => asset.AssetName === props.delayAreaAssetName,
		)?.AssetId || DEFAULT_DELAY_AREA_ASSET_ID;
	return (
		<ConfigurationDelayCodeProvider>
			<ConfigurationAssetProvider>
				<CategoriesProvider>
					<CatalogDelayPage
						delayAreaAssetId={assetId}
						delayAreaAssetName={props.delayAreaAssetName}
					/>
				</CategoriesProvider>
			</ConfigurationAssetProvider>
		</ConfigurationDelayCodeProvider>
	);
};

export default DelayCodePage;
