import { EAFProcessesPage } from "./EAFProcessesPage";
import { EAFProcessesProvider } from "./context/EAFProcessesContext";

type Props = {};
const EAFProcesses = (props: Props) => {
	return (
		<EAFProcessesProvider>
			<EAFProcessesPage />
		</EAFProcessesProvider>
	);
};
export default EAFProcesses;
