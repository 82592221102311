import { MenuOptionType } from "@dexteel/mesf-core";
import { useNavigate } from "react-router-dom";
import { CasterChemistry } from "../../../../caster/models/CasterChemistry";

export const useMissedChemistriesOptionsFunctions = () => {
	const navigate = useNavigate();

	const goToHeatReport = (HeatNameLink: string | null) => {
		if (HeatNameLink === null) return;
		navigate(HeatNameLink);
	};

	const getMissedChemistriesMenuOptions = (data: CasterChemistry) => {
		let options: MenuOptionType[] = [];

		if (data.HeatNameLink) {
			options.push({
				name: "See in Heat Report",
				key: "go_to_heat_report",
				style: { fontWeight: "bold" },
				onClick: () => goToHeatReport(data.HeatNameLink),
				// icon: <Heat Report Icon />
			});
		}
		return options;
	};

	return {
		getMissedChemistriesMenuOptions,
		goToHeatReport,
	};
};
