import { Grid } from "@material-ui/core";
import * as React from "react";
import { ConfigurationDelayComponent } from "./components/DelayConfiguration/ConfigurationDelayComponent";
type Props = {
	delayAreaAssetId?: number;
	delayAreaAssetName?: string;
};
export const CatalogDelayPage = ({
	delayAreaAssetId,
	delayAreaAssetName,
}: Props) => {
	return (
		<>
			<Grid container justifyContent="center" alignItems="center">
				<ConfigurationDelayComponent
					delayAreaAssetId={delayAreaAssetId}
					delayAreaAssetName={delayAreaAssetName}
				/>
			</Grid>
		</>
	);
};
