import Configuration from "@dexteel/mesf-core/dist/configurationMenu";
import { ContextMenuMESFProvider } from "../../../controls/contextMenu/useContextMenuMESF";
import { CategoriesProvider } from "../../delays/categories/context/CategoriesContext";
import { ConfigurationDelayCodeProvider } from "../../delays/codes/context/ConfigurationDelayCodeContext";
import { DelaysManagerPage } from "./DelaysManagerPage";
import { DelaysManagerProvider } from "./context/DelaysManagerContext";

type Props = {};
const DelaysManager = (props: Props) => {
	return (
		<ContextMenuMESFProvider>
			<ConfigurationDelayCodeProvider>
				<CategoriesProvider>
					<DelaysManagerProvider>
						<DelaysManagerPage />
					</DelaysManagerProvider>
				</CategoriesProvider>
			</ConfigurationDelayCodeProvider>
		</ContextMenuMESFProvider>
	);
};
export default DelaysManager;
