import {
	Checkbox,
	CircularProgress,
	InputAdornment,
	TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeEvent } from "react";
import { useDataEntryContext } from "../context/reducer.provider";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexWrap: "nowrap",
		width: "100%",
	},
	field: {
		flex: 1,
		minWidth: 0,
	},
	field2: {
		flex: 2,
		minWidth: 0,
	},
	leftField: {
		"& .MuiOutlinedInput-root": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			borderRight: "none",
		},
	},
	rightField: {
		"& .MuiOutlinedInput-root": {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
	},
	checkbox: {
		padding: 0,
		margin: 0,
	},
	inputAdornment: {
		margin: 0,
	},
	input: {
		paddingRight: theme.spacing(1),
	},
}));

interface Props {
	ladleLife: {
		HeatsOnLadle: number | null;
		ContactTimeOnLadle: number | null;
	};
	value: number | null;
	onChange: (value: number | null) => void;
	disabled: boolean;
}

export const LadleLifeTrimTempCheckbox = ({
	ladleLife,
	onChange,
	value,
}: Props) => {
	const classes = useStyles();
	const {
		state: { isSubmitLoading },
	} = useDataEntryContext();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked ? 1 : null;
		onChange(value);
	};

	return (
		<div className={classes.container}>
			<TextField
				className={`${classes.field} ${classes.leftField}`}
				label="Ladle Life"
				variant="outlined"
				disabled
				size="small"
				value={ladleLife.HeatsOnLadle || ""}
			/>
			<TextField
				className={`${classes.field} ${classes.rightField}`}
				variant="outlined"
				disabled
				size="small"
				value={ladleLife.ContactTimeOnLadle}
				InputProps={{
					classes: { input: classes.input },
					endAdornment: (
						<InputAdornment position="end" className={classes.inputAdornment}>
							<Checkbox
								color="primary"
								size="small"
								className={classes.checkbox}
								checked={!!value}
								disabled={isSubmitLoading}
								onChange={handleChange}
							/>
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};
