import React from "react";
import { createPortal } from "react-dom";
import { AssetItemProps } from "../asset-item";
import { BarDisplay } from "./bar-display";
import styles from "./bar.module.css";

export const Bar: React.FC<AssetItemProps> = ({
	asset,
	onEventStart,
	isSelected,
	processLinesRef,
}) => {
	const isNotDelay = asset.type == "taskLine" && asset.source.eventCode !== "D";
	const borderRadius = isNotDelay ? 0 : asset.barCornerRadius;
	const subEventsHeight = isNotDelay ? 9 : asset.height;
	const subEventY = isNotDelay ? asset.y + asset.height - 11 : asset.y;
	return (
		<g className={styles.barWrapper} tabIndex={0}>
			{createPortal(
				<>
					{asset.drawLine ? (
						<line
							x1={0}
							y1={asset.y + asset.height / 2}
							x2="100%"
							y2={asset.y + asset.height / 2}
							stroke="black"
							strokeOpacity={"1"}
							strokeWidth="0.4"
							pointerEvents="none"
						/>
					) : null}
				</>,
				processLinesRef.current || document.body,
			)}
			<BarDisplay
				id={`${asset.name}-${asset.id}`}
				x={asset.x1}
				y={subEventY}
				width={asset.x2 - asset.x1}
				height={subEventsHeight}
				progressX={asset.progressX}
				progressWidth={asset.progressWidth}
				barCornerRadius={borderRadius}
				styles={asset.styles}
				isSelected={isSelected}
			/>

			<g className="handleGroup"></g>
		</g>
	);
};
