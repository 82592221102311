import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	dxtToLocalServerTime,
	getMomentTz,
} from "@dexteel/mesf-core";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { isNil } from "lodash-es";
import React, { useEffect, useState } from "react";
import { UnLinkedHeat } from "../../models/UnLinkedHeat";
import {
	linkCasterProcess,
	searchUnlinkedHeats,
} from "../../repositories/ProductionRepository";

const moment = getMomentTz();

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	process: {
		ProcessId: number;
		LadleOpen: Date | null;
		HeatName: string | null;
	} | null;
};

export const LinkCasterProcess = ({ show, onHide, process }: Props) => {
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [unlinkedHeats, setUnLinkedHeats] = useState<UnLinkedHeat[]>([]);
	const [selectedHeat, setSelectedHeat] = useState<any>(null);
	const [searchText, setSearchText] = useState("");
	const [error, setError] = useState("");
	const [showWarningModal, setShowWarningModal] = useState(false);

	const onSubmit = async () => {
		try {
			if (selectedHeat === null) {
				setError("Please select a heat");
				return;
			}
			if (isNil(process)) return;

			if (selectedHeat.IsLinked === 1) {
				setShowWarningModal(true);
			} else {
				await proceedWithLinking();
			}
		} catch (e: any) {
			setError(e.message);
		}
	};

	const proceedWithLinking = async () => {
		try {
			setIsSubmitLoading(true);
			const response = await linkCasterProcess(
				process?.ProcessId as number,
				selectedHeat.HeatId,
			);
			if (response.ok) {
				onHide(true);
			} else {
				setError(response.message);
			}
		} catch (e: any) {
			setError(e.message);
		} finally {
			setIsSubmitLoading(false);
			setShowWarningModal(false);
		}
	};

	const handleSearchChange = async () => {
		if (!process) return;
		const heats = await searchUnlinkedHeats(
			searchText,
			process.LadleOpen as Date,
		);
		if (heats.ok && heats.data?.tables?.[0]?.rows) {
			setUnLinkedHeats(heats.data.tables[0].rows);
		}
	};

	const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
		setSearchText(value);
	};

	const handleKeyDown = async (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			await handleSearchChange();
		}
	};

	const handleChange = (
		event: React.ChangeEvent<{}>,
		newValue: UnLinkedHeat | null,
	) => {
		setSelectedHeat(newValue as any);
	};

	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setSelectedHeat(null);
				if (process === undefined || process === null) return;
				setTitle(
					`Link Caster Process / Ladle Open: ${dxtToLocalServerTime(
						`${process.LadleOpen}`,
						"HH:mm",
					)}
					 Heat: ${process.HeatName ? process.HeatName : ""}`,
				);
				const heats = await searchUnlinkedHeats("", process.LadleOpen as Date);
				if (
					heats.ok &&
					heats.data &&
					heats.data.tables &&
					heats.data.tables[0].rows
				) {
					setUnLinkedHeats(heats.data.tables[0].rows);
				}
			})();
		}
	}, [show]);

	return (
		<>
			<MesfModal
				maxWidth="xl"
				open={show}
				handleClose={() => {
					onHide(true);
				}}
				id="linkCasterProcess"
				title={title}
			>
				<MesfModal.Content
					style={{
						padding: "15px 30px",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<Grid container spacing={1} alignItems="center">
						<Grid item xs={12}>
							<Autocomplete
								options={unlinkedHeats}
								getOptionLabel={(option) => option.HeatName}
								value={selectedHeat}
								onChange={handleChange}
								inputValue={searchText}
								onInputChange={handleInputChange}
								renderOption={(option) => (
									<div style={{ display: "flex", alignItems: "center" }}>
										<span>{option.HeatName}</span>
										{option.IsLinked === 1 && (
											<span style={{ color: "red", marginLeft: "10px" }}>
												(linked)
											</span>
										)}
									</div>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label={searchText ? "Heat" : "Search Heat"}
										placeholder="Search Heat"
										variant="outlined"
										fullWidth
										onKeyDown={handleKeyDown}
										InputLabelProps={{
											style: {
												height: "auto",
												marginTop: 5,
												display: "flex",
												alignItems: "center",
											},
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</MesfModal.Content>

				<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item md={3} xs={12} style={{ margin: 0 }}>
							<Button
								fullWidth
								variant="contained"
								color="default"
								onClick={() => onHide(true)}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item md={3} xs={12} style={{ margin: 0 }}>
							<ButtonWithLoading
								fullWidth
								isLoading={isSubmitLoading}
								disabled={isSubmitLoading}
								variant="contained"
								color="primary"
								onClick={onSubmit}
							>
								Save
							</ButtonWithLoading>
						</Grid>
					</Grid>
				</MesfModal.Actions>
			</MesfModal>

			<MesfModal
				open={showWarningModal}
				handleClose={() => setShowWarningModal(false)}
				title="Warning"
				id="warningModal"
			>
				<MesfModal.Content>
					<p style={{ textAlign: "center" }}>
						This heat is already linked in another process. Do you want to
						proceed?
					</p>
				</MesfModal.Content>
				<MesfModal.Actions>
					<Button
						fullWidth
						variant="contained"
						color="default"
						onClick={() => setShowWarningModal(false)}
					>
						Cancel
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={proceedWithLinking}
					>
						Proceed
					</Button>
				</MesfModal.Actions>
			</MesfModal>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
