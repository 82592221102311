import React from "react";
import styles from "./asset-list-header.module.css";

export const AssetListHeaderDefault: React.FC<{
	headerHeight: number;
	rowWidth: number;
	fontFamily: string;
	fontSize: string;
}> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
	const headerX: number = 0;
	const headerY: number = 0;
	const getX = () => {
		const width = rowWidth;
		return headerX + width * 0.5;
	};
	return (
		<g>
			<g>
				<rect
					x={0}
					width={rowWidth}
					y={0}
					height={headerHeight}
					className={styles.header}
				/>
				<text
					x={getX()}
					y={headerY + headerHeight * 0.5}
					className={styles.headerLabel}
					style={{
						fontFamily: fontFamily,
						fontSize: fontSize,
					}}
				>
					&nbsp;Asset
				</text>
			</g>
		</g>
	);
};
