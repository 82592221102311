import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
	convertBooleanToString,
	convertDateToString,
	convertNumberToString,
	convertStringToString,
	getClassObjectFromString,
	getStyleObjectFromString,
} from "../../helpers/other-helper";
import { getCellProperty } from "../../helpers/table-helper";
import { DataTypeField } from "../../types/public-types";
import { BodyCellSetup, CellProperty } from "../../types/table-body";

export type ReportBodyCellProps = {
	cell: BodyCellSetup;
	data: any;
	cellClass: string;
};
export type ReportBodyFixedCellProps = {
	value?: string;
	colSpan?: number;
	rowSpan?: number;
	isRowHeader?: boolean;
	isFirstCell?: boolean;
};

/* Controls */
const CheckBoxTable = (props: { value: boolean }) => {
	return <Checkbox checked={props.value} size="small" color="primary" />;
};
export const ReportBodyFixedCell: React.FC<ReportBodyFixedCellProps> = ({
	value = "",
	colSpan = 1,
	rowSpan = 1,
	isRowHeader = false,
	isFirstCell = false,
}) => {
	// let cellDivClass: string[]= ['cell-table'];
	const cellClass: string[] = [""];
	if (isRowHeader) {
		cellClass.push("cell-header");
	}
	if (isFirstCell) {
		cellClass.push("cell-first");
	}
	return (
		<th colSpan={colSpan} rowSpan={rowSpan} className={cellClass.join(" ")}>
			<span>{value} </span>
		</th>
	);
};
export const ReportBodyCell: React.FC<ReportBodyCellProps> = ({
	cell,
	data,
	cellClass,
}) => {
	/* Styles */
	const useTooltipStyles = makeStyles(() => ({
		tooltip: {
			margin: 0,
		},
	}));
	const toolTipClasses = useTooltipStyles();
	const navigate = useNavigate();
	const clickNavigation = () => {
		if (hasLink) {
			navigate(linkCell);
		}
	};
	const cellAllClass: string[] = [cellClass, "cell-table"];
	let value = "";
	let cellValue: any = data;
	let customCellClass = "";
	let cellTitle = "";
	let isHtml = false;
	let htmlComponent: React.ReactNode = <></>;
	let hasLink = false;
	let linkCell = "";
	let styleCell: any = [];

	/*****Data*****/
	if (data !== null && typeof data === "object") {
		cellValue = getCellProperty(data, CellProperty.Value);
		customCellClass = getCellProperty(data, CellProperty.Style) || "";
		cellTitle = getCellProperty(data, CellProperty.Title) || "";
		linkCell = getCellProperty(data, CellProperty.Link) || "";
		hasLink = linkCell !== "";
	}
	if (cellValue === null || cellValue === undefined) cellValue = "";
	switch (cell.type) {
		case DataTypeField.DateTime:
			value = convertDateToString(cellValue, cell.format);
			break;
		case DataTypeField.Date:
			value = convertDateToString(cellValue, cell.format);
			break;
		case DataTypeField.Number:
			value = convertNumberToString(cellValue, cell.format);
			break;
		case DataTypeField.Boolean:
			if (cell.format === "checkbox") {
				htmlComponent = <CheckBoxTable value={Boolean(cellValue)} />;
				isHtml = true;
			} else value = convertBooleanToString(cellValue, cell.format);
			break;
		case DataTypeField.String:
			value = convertStringToString(cellValue, cell.format);
			break;
		default:
			value = String(cellValue);
			break;
	}
	/**************/
	/*****Styles*****/
	switch (cell.type) {
		case DataTypeField.Number:
			cellAllClass.push("text-right");
			break;
	}
	let hasStyle = false;
	if ((cell.cellStyle || "").startsWith(".")) {
		// is class
		cellAllClass.push(getClassObjectFromString(cell.cellStyle || ""));
	} else if ((cell.cellStyle || "") !== "") {
		styleCell = getStyleObjectFromString(cell.cellStyle || "");
		hasStyle = true;
	}
	if (customCellClass.startsWith(".")) {
		// is class
		cellAllClass.push(getClassObjectFromString(customCellClass));
	} else if (customCellClass !== "") {
		styleCell = hasStyle
			? getStyleObjectFromString(cell.cellStyle + ";" + customCellClass)
			: getStyleObjectFromString(customCellClass);
	}
	if (hasLink) cellAllClass.push("cell-link");
	/**************/
	if (isHtml) {
		return (
			<td>
				<div className={cellAllClass.join(" ")}>{htmlComponent}</div>
			</td>
		);
	} else {
		return (
			<td>
				<Tooltip title={cellTitle} arrow={true} classes={toolTipClasses}>
					<div
						className={cellAllClass.join(" ")}
						onClick={clickNavigation}
						style={styleCell}
					>
						{" "}
						{value}{" "}
					</div>
				</Tooltip>
			</td>
		);
	}
};
