import { Grid } from "@material-ui/core";
import { useHealthStatusContext } from "../HealthStatusContext";
import { SimpleTable } from "./SimpleTable";

export const HealthStatusSections = () => {
	const { state } = useHealthStatusContext();
	return (
		<>
			{/* first row */}
			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="center"
					alignItems="flex-start"
					spacing={3}
					style={{ padding: "5px 10px" }}
				>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<SimpleTable
							columns={(state.details[0] ?? []).columns || []}
							data={(state.details[0] ?? []).rows || []}
							headers={["Grade", "Count", "Examples"]}
							widths={["20%", "10%", "70%"]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<SimpleTable
							columns={(state.details[1] ?? []).columns || []}
							data={(state.details[1] ?? []).rows || []}
							headers={["Section", "Count", "Examples"]}
							widths={["20%", "10%", "70%"]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<SimpleTable
							columns={(state.details[2] ?? []).columns || []}
							data={(state.details[2] ?? []).rows || []}
							headers={["Heat", "Count", "Examples"]}
							widths={["20%", "10%", "70%"]}
						/>
					</Grid>
				</Grid>
			</Grid>
			{/* second row */}
			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="center"
					alignItems="flex-start"
					spacing={3}
					style={{ padding: "5px 10px" }}
				>
					<Grid item xs={12} sm={12} md={8} lg={8}>
						<SimpleTable
							columns={(state.details[3] ?? []).columns || []}
							data={(state.details[3] ?? []).rows || []}
							headers={["Source", "Count", "Examples"]}
							widths={["10%", "5%", "85%"]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<SimpleTable
							columns={(state.details[4] ?? []).columns || []}
							data={(state.details[4] ?? []).rows || []}
							headers={["Grade", "Count", "Examples"]}
							widths={["20%", "10%", "70%"]}
						/>
					</Grid>
				</Grid>
			</Grid>
			{/* third row */}
			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="center"
					alignItems="flex-start"
					spacing={3}
					style={{ padding: "5px 10px" }}
				>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<SimpleTable
							columns={(state.details[5] ?? []).columns || []}
							data={(state.details[5] ?? []).rows || []}
							headers={["Section", "Count", "Examples"]}
							widths={["20%", "10%", "70%"]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={8} lg={8}>
						<SimpleTable
							columns={(state.details[6] ?? []).columns || []}
							data={(state.details[6] ?? []).rows || []}
							headers={["Heat", "Sample", "Count", "Examples"]}
							widths={["10%", "10%", "5%", "75%"]}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="center"
					alignItems="flex-start"
					spacing={3}
					style={{ padding: "5px 10px" }}
				>
					<Grid item xs={12} sm={12} md={8} lg={8}>
						<SimpleTable
							columns={(state.details[7] ?? []).columns || []}
							data={(state.details[7] ?? []).rows || []}
							headers={["Heat", "Sample", "Date", "Start", "End"]}
							widths={["10%", "10%", "20%", "20%", "20%"]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<SimpleTable
							columns={(state.details[8] ?? []).columns || []}
							data={(state.details[8] ?? []).rows || []}
							headers={["Heat", "Section", "Seq.", "Date", "Count", "Examples"]}
							widths={["10%", "10%", "10%", "20%", "10%", "40%"]}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
