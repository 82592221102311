import {
	DataGridControl,
	MasterDetailPanel,
	SimpleTextControl,
} from "@dexteel/mesf-core";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { GridRowParams } from "@material-ui/data-grid";
import numeral from "numeral";
import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { NumericTextControl } from "../../../controls/InputControls";
import { useConfigurationContext } from "../context/ConfigurationContext";
import { AlertConfiguration } from "../models/catalogs/alertConfiguration";
import {
	GetAlertConfiguration,
	saveAlertConfiguration,
} from "../repositories/ProductionRepository";

type Props = {};
export const AlertLimitPage = (props: Props) => {
	const { state, actions } = useConfigurationContext();
	const columns = [
		{
			field: "Name",
			headerName: "Variable",
			width: 250,
			type: "string",
		},
		{
			field: "Value",
			headerName: "Value",
			width: 150,
			type: "number",
			valueFormatter: ({ value }: { value: any }) =>
				numeral(value).format("0.00"),
		},
		{
			field: "CssClass",
			headerName: "Style",
			flex: 1,
			type: "string",
		},
	];
	const [page, setPage] = React.useState<number>(0);
	const save = () => {
		(async () => {
			if (state.selectedAlertConfiguration !== null) {
				const resp = await saveAlertConfiguration(
					state.selectedAlertConfiguration,
				);
				if (resp.ok) {
					actions.setSuccess({ message: "The values were changed." });
					await getAlertConfiguration();
					actions.setSelectedAlertConfiguration(null);
				} else {
					actions.setSelectedAlertConfiguration(null);
					actions.setError(resp.error);
				}
			}
		})();
	};
	const changeData = (value: any, name: string) => {
		if (state.selectedAlertConfiguration === null) return;
		if (name === "value") {
			const object: AlertConfiguration = {
				...state.selectedAlertConfiguration,
				Value: numeral(value).value(),
			};
			actions.setSelectedAlertConfiguration(object);
		} else if (name === "cssClass") {
			actions.setSelectedAlertConfiguration({
				...state.selectedAlertConfiguration,
				CssClass: value,
			});
		}
	};
	const rowClicked = (rowClickedEvent: GridRowParams) => {
		const data = rowClickedEvent.row;
		if (data === undefined) return;
		if (
			state.selectedAlertConfiguration != null &&
			data.id === state.selectedAlertConfiguration.AlertConfigurationId
		)
			return;
		actions.setSelectedAlertConfiguration(data);
	};
	const getAlertConfiguration = async () => {
		actions.setShowLoading(true);
		const resp = await GetAlertConfiguration();
		actions.setShowLoading(false);
		let temp: any[] = [];
		if (resp.ok) {
			temp = resp.data === null ? [] : resp.data[0].rows || [];
			temp.forEach((row: any, index: number) => {
				row.id = row.AlertConfigurationId;
			});
		} else {
			console.log("something happened");
		}
		actions.setAlertConfigurations(temp);
	};
	useEffect(() => {
		(async () => {
			await getAlertConfiguration();
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<>
			<Backdrop open={state.showLoading} style={{ zIndex: 1000 }}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Alert
				show={state.error !== undefined}
				variant="danger"
				dismissible={true}
				onClose={() => {
					actions.setError(undefined);
				}}
			>
				<strong>There was an error</strong>
				<p>
					{" "}
					{state.error === undefined
						? "There was an error"
						: state.error.internalError.message === undefined
							? state.error.internalError
							: state.error.internalError.message}
				</p>
			</Alert>
			<Alert show={state.success !== undefined} variant="success">
				<p>
					{state.success === undefined
						? "Operation was success"
						: state.success.message || "Operation was success"}
				</p>
			</Alert>
			<div className="row">
				<Typography className="mx-2 mt-0 mb-2 font-weight-bold">
					Production Alert Limits
				</Typography>
			</div>
			<div className="row">
				<div className="col-md-6">
					{/* @ts-ignore */}
					<DataGridControl
						columns={columns}
						rows={state.alertConfigurations}
						{...{
							pageSize: 20,
							rowHeight: 30,
							loading: state.showLoading,
							rowsPerPageOptions: [10, 20, 50, 100],
							page: page,
							className: "mes-selectable-datagrid",
							onRowClick: rowClicked,
							onPageChange: (params: any) => {
								setPage(params.page);
							},
						}}
					/>
				</div>
				<div className="col-md-6">
					{state.selectedAlertConfiguration && (
						/* @ts-ignore */
						<MasterDetailPanel
							title={state.selectedAlertConfiguration.Name}
							saveStatus={true}
							showDelete={false}
							onCancel={(event) => {
								actions.setSelectedAlertConfiguration(null);
							}}
							onSave={save}
							onDelete={(event) => {}}
						>
							<div className="row">
								<div className="col-md-6 px-3 py-1">
									{/* @ts-ignore */}
									<NumericTextControl
										styleLabel={{ minWidth: "150px" }}
										title="Value"
										onChange={changeData}
										name="value"
										value={state.selectedAlertConfiguration.Value}
										readOnly={false}
									></NumericTextControl>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 px-3 py-1">
									{/* @ts-ignore */}
									<SimpleTextControl
										styleLabel={{ minWidth: "150px" }}
										title="Css Class"
										onChange={changeData}
										name="cssClass"
										value={state.selectedAlertConfiguration.CssClass}
										readOnly={false}
									></SimpleTextControl>
								</div>
							</div>
						</MasterDetailPanel>
					)}
				</div>
			</div>
		</>
	);
};
