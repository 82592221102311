import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Process } from "../../../models/eaf.models";
import { TextFieldDE } from "../common/TextFieldDE";

export type StringFields<T> = {
	[K in keyof T]: T[K] extends string | null ? K : never;
}[keyof T];

export const ProcessDataTextFieldDE = ({
	field,
	label,
}: { field: StringFields<Process>; label?: string }) => {
	const {
		state: { deProcess },
		actions,
	} = useDataEntryContext();

	const { saveProcessData } = useEAFDataEntryActions();
	const process = deProcess?.process;

	const onTextValueChange = () => {
		saveProcessData({
			processId: process?.ProcessId!,
			field,
			value: process![field],
		});
	};

	return (
		<TextFieldDE
			label={label || field}
			value={process ? process[field] || "" : ""}
			onChange={(value) => {
				actions.setProcess({
					...process,
					[field]: value,
				});
			}}
			onBlur={() => {
				onTextValueChange();
			}}
		/>
	);
};
