import { createSlice } from "@reduxjs/toolkit";
import { Grade } from "../../../caster/models/Catalogs";

const GradesInitialState: {
	grades: Grade[];
} = {
	grades: [],
};

export const GradesReducer = createSlice({
	name: "__",
	initialState: GradesInitialState,
	reducers: {
		setGrades(state, { payload }) {
			state.grades = payload;
		},
	},
});
