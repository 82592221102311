import { Checkbox } from "@material-ui/core";
import { ColDef } from "ag-grid-enterprise";
import { CatalogElement } from "../../../models/CatalogElement";
import { useCatalogElementContext } from "../CatalogElementContext";

type Props = {};
export const useTableData = ({}: Props) => {
	const { state } = useCatalogElementContext();
	const rows: CatalogElement[] =
		state.elements?.map((element) => ({
			...element,
		})) || [];

	const columns: ColDef[] = [
		{ field: "ElementName", headerName: "Name", minWidth: 63, flex: 4 },
		{
			field: "Decimals",
			headerName: "Decimals",
			flex: 4,
			minWidth: 86,
		},
		{
			field: "Order",
			headerName: "Order",
			flex: 4,
			minWidth: 63,
		},
		{
			field: "IsActive",
			headerName: "Enabled",
			cellRenderer: (params: any) => {
				return <Checkbox checked={params.value} disabled={true} />;
			},
			flex: 4,
			minWidth: 86,
		},
		{
			field: "Actions",
			headerName: "",
			type: ["editButton"],
			flex: 1,
			minWidth: 50,
		},
	];
	const defaultColDef: ColDef = {
		flex: 1,
		autoHeight: true,
		wrapText: true,
		sortable: true,
	};

	return { rows, columns, defaultColDef };
};
