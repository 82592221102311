import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { GridReadyEvent } from "ag-grid-community";
import {
	CellClassParams,
	ColDef,
	ValueFormatterParams,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import { useMemo, useRef, useState } from "react";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { GenericReportHeader } from "../../models/GenericReportHeader";
import { useGenericReportStyles } from "../../styles/gridStyles";
import CommentTooltip from "./CommentTooltip";

type Props = {
	headers: GenericReportHeader[];
	rows: any[];
	onGridReady: (event: GridReadyEvent) => void;
};
const moment = getMomentTz();
export const ReportTable = ({ headers, rows, onGridReady }: Props) => {
	const gridRef = useRef<AgGridReact>(null);
	const classes = useGenericReportStyles();
	const { columnGenericDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {},
	});
	const [columns, setColumns] = useState<ColDef[]>([]);
	// functions
	const valueRichFormatter = (params: ValueFormatterParams) => {
		if (params.value === null || params.value === undefined) return "";
		const metaData = params.value.toString().split("%%%");
		if (metaData.length > 0) return metaData[0];
	};
	const cellClassFormatter = (
		params: CellClassParams,
		defaultClass: string,
	) => {
		if (params.value === null || params.value === undefined)
			return defaultClass;
		const metaData = params.value.toString().split("%%%");
		if (metaData.length > 2) {
			return defaultClass + " " + metaData[2];
		}
	};
	const getCellWidth = (className: string) => {
		const startWidth = className.indexOf("pixel-");
		if (startWidth === -1) return 0;
		let endWidth = className.indexOf(" ", startWidth);
		if (endWidth === -1) endWidth = className.length;
		const width = className.substring(startWidth + 6, endWidth);
		return parseInt(width);
	};

	//memos
	const gridStyle = useMemo(() => {
		return {
			width: "100%",
			padding: "0px 15px 0px 0px",
			height: "75vh",
		};
	}, []);
	useMemo(() => {
		const newColumns: ColDef[] = [];
		headers
			.slice()
			.sort((a, b) => a.Order - b.Order)
			.forEach((header) => {
				const column: ColDef = {
					field: header.ColumnData,
					headerName: header.ColumnName,
					headerClass: header.HeaderClass,
					minWidth: getCellWidth(header.HeaderClass),
					maxWidth: getCellWidth(header.HeaderClass),
					// type: ["numericColumn"],
					valueFormatter: (params) => {
						return valueRichFormatter(params);
					},
					valueGetter: (params) => {
						const column = get(params.data, header.ColumnData, "_");

						if (header.Type === 10 && moment.utc(column).isValid())
							return dxtToLocalServerTime(
								moment.utc(column).toDate(),
								"MM/dd/yyyy",
							);
						if (header.Type === 11 && moment.utc(column).isValid())
							return dxtToLocalServerTime(
								moment.utc(column).toDate(),
								"HH:mm:ss",
							);
						if (header.Type === 12 && moment.utc(column).isValid())
							return dxtToLocalServerTime(
								moment.utc(column).toDate(),
								"HH:mm:ss - MM/dd/yyyy",
							);
						else return params.data[header.ColumnData];
					},
					cellClass: (params) => {
						return cellClassFormatter(params, header.CellClass);
					},
					tooltipComponent: CommentTooltip,
					tooltipField: header.ColumnData,
					tooltipComponentParams: { color: "#ececec" },
				};
				newColumns.push(column);
			});
		setColumns(newColumns);
	}, [headers]);

	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			className={classes.root}
		>
			<Grid item style={gridStyle} xs={12} md={12}>
				<AgGridReact
					onGridReady={onGridReady}
					className="ag-theme-alpine"
					rowSelection="single"
					pagination={false}
					animateRows={true}
					rowHeight={35}
					headerHeight={35}
					singleClickEdit={true}
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					rowData={rows}
					columnDefs={columns}
					columnTypes={columnTypes}
					defaultColDef={columnGenericDefaults}
				></AgGridReact>
			</Grid>
		</Grid>
	);
};
