import {
	default as React,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { CasterCountersReducer } from "./CasterCountersReducer";

const CasterCountersContext = createContext({
	state: CasterCountersReducer.getInitialState(),
	actions: CasterCountersReducer.actions,
});

export const useCasterCountersContext = () => useContext(CasterCountersContext);

export const CasterCountersProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CasterCountersReducer.getInitialState(),
		reducers: CasterCountersReducer.caseReducers,
	});
	return (
		<CasterCountersContext.Provider value={{ state, actions }}>
			{children}
		</CasterCountersContext.Provider>
	);
};
