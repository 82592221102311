import { useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import {
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { FooterButton } from "../../../../../controls/buttons/FooterButton";
import { CustomLoadingOverlay } from "../../../../../controls/customLoader/CustomCircularProgress";
import { useGridStyles } from "../../../styles/gridStyles";
import { useLadlesOptionsFunctions } from "../hooks/useLadlesOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { UpsertDeleteLadleModal } from "./modals/UpsertDelete";
type Props = {
	getCatalogLadlesFromAPI: Function;
	isLoading: boolean;
};
export const TableLadle = ({ getCatalogLadlesFromAPI, isLoading }: Props) => {
	const [showModal, setShowModal] = useState<
		"create" | "update" | "delete" | ""
	>("");
	const [ladleId, setLadleId] = useState<number | null>(null);
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const { getMenuOptions } = useLadlesOptionsFunctions({
		setLadleId,
		setShowModal,
	});
	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { rows, columnDefs } = useTableData({
		setLadleId,
		setShowModal,
		showContextMenu,
	});
	const { columnDefaults } = useGridDefinitions({
		OnEdit: (data: any) => {
			setShowModal("update");
			setLadleId(data.Id);
		},
	});
	columnDefaults.floatingFilter = false;
	const onRowDoubleClicked = (event: any) => {
		setLadleId(event.data.LadleId);
		setShowModal("update");
	};
	const getContextMenuItems = React.useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data = params.node?.data;
			showContextMenu(event as any, data, "ladleContext");
			return [];
		},
		[rows],
	);
	const onModalHide = (shouldUpdate: boolean) => {
		if (shouldUpdate)
			getCatalogLadlesFromAPI().then(() => {
				gridApi?.paginationGoToLastPage();
			});
		setShowModal("");
	};
	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else {
			gridApi?.hideOverlay();
		}
	}, [isLoading, rows, gridApi]);
	useEffect(() => {
		registerConfig({
			id: "ladleContext",
			getOptions: getMenuOptions,
		});
	}, []);
	const classes = useGridStyles();
	return (
		<Grid container justifyContent="center">
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Ladles
				</Typography>
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
				style={{ width: "100%" }}
			>
				<Grid
					item
					md={12}
					xs={12}
					style={{ padding: "0 15px" }}
					// className={classes.relative}
				>
					<UpsertDeleteLadleModal
						LadleId={ladleId}
						onHide={onModalHide}
						modal={showModal}
					/>
					<Grid item md={12} xs={12} className={classes.gridContainer}>
						<div
							style={{
								height: "100%",
								width: "100%",
							}}
							className="ag-theme-material"
						>
							<AgGridReact
								rowData={rows || []}
								columnDefs={columnDefs}
								defaultColDef={columnDefaults}
								getRowId={(params) => params.data.LadleId}
								rowHeight={34}
								headerHeight={34}
								animateRows={true}
								rowSelection="single"
								getContextMenuItems={getContextMenuItems}
								onRowDoubleClicked={onRowDoubleClicked}
								onGridReady={(params) => setGridApi(params.api)}
								loadingOverlayComponent={CustomLoadingOverlay}
							/>
						</div>
					</Grid>
					<FooterButton
						label="LADLE"
						showCreateModal={showModal === "create"}
						setShowCreateModal={(showCreateModal) =>
							showCreateModal ? setShowModal("create") : setShowModal("")
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
