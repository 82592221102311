export interface CatalogBucket {
	BucketId: number | null;
	BucketName: string;
	Capacity: number | null;
	IsActive: boolean;
}

export const CATALOG_BUCKET_INITIAL_VALUES: CatalogBucket = {
	BucketId: null,
	BucketName: "",
	Capacity: null,
	IsActive: true,
};
