import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
	colorCircle: {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		display: "inline-block",
	},
});

type ColorCircleProps = {
	value: string;
};

const ColorCircleRenderer: React.FC<ColorCircleProps> = ({ value }) => {
	const classes = useStyles();

	return (
		<div
			className={classes.colorCircle}
			style={{ backgroundColor: value.toLowerCase() }}
		/>
	);
};

export default ColorCircleRenderer;
