import React, { useRef } from "react";
import { BarAsset } from "../../types/bar-asset";
import { BarTask } from "../../types/bar-task";
import {
	GanttContentMoveAction,
	GanttEvent,
} from "../../types/gantt-asset-actions";
import { EventOption } from "../../types/public-types";
import { AssetItem } from "../asset-item/asset-item";

export type AssetGanttContentProps = {
	assets: BarAsset[];
	selectedAsset: BarTask | undefined;
	ganttEvent: GanttEvent;
	rowHeight: number;
	svgWidth: number;
	taskHeight: number;
	arrowColor: string;
	showArrowTriangle: boolean;
	arrowIndent: number;
	fontSize: string;
	fontFamily: string;
	setGanttEvent: (value: GanttEvent) => void;
	setSelectedAsset: (assetId: string) => void;
} & EventOption;

export const AssetGanttContent: React.FC<AssetGanttContentProps> = ({
	assets,
	selectedAsset,
	ganttEvent,
	rowHeight,
	taskHeight,
	arrowColor,
	showArrowTriangle,
	svgWidth,
	arrowIndent,
	fontFamily,
	fontSize,
	setGanttEvent,
	setSelectedAsset,
	onDoubleClick,
	onContextMenu,
}) => {
	const processLinesRef = useRef(null);
	const drawnLines = useRef<any>({});
	/**
	 * Method is Start point of task change
	 */
	const handleBarEventStart = async (
		action: GanttContentMoveAction,
		task: BarTask,
		event?: React.MouseEvent,
	) => {
		if (!event) {
			if (action === "select") {
				setSelectedAsset(task.id);
			}
		}
		// Mouse Events
		else if (action === "mouseenter") {
			setGanttEvent({
				action,
				changedAsset: task,
				originalSelectedTask: task,
			});
		} else if (action === "mouseleave") {
			setGanttEvent({ action: "" });
		} else if (action === "dblclick") {
			!!onDoubleClick && onDoubleClick(task);
		} else if (action === "contextMenu") {
			if (onContextMenu) onContextMenu(event, task.source);
		} else {
			setGanttEvent({
				action,
				changedAsset: task,
				originalSelectedTask: task,
			});
		}
	};
	return (
		<g className="content">
			{/* <g className="arrows" fill={arrowColor} stroke={arrowColor}>
				{assets.map((asset) => {
					return asset.barTasks.map((process) => {
						return process.barChildren.map((child) => {
							return (
								<Arrow
									key={`[Asset ${asset.id}]: Arrow from ${process.id} to ${
										asset.barTasks[child.index].id
									}`}
									taskFrom={process}
									taskTo={asset.barTasks[child.index]}
									rowHeight={rowHeight}
									taskHeight={taskHeight}
									arrowIndent={arrowIndent}
									showArrowTriangle={showArrowTriangle}
								/>
							);
						});
					});
				})}
			</g> */}
			<g className="bar" fontFamily={fontFamily} fontSize={fontSize}>
				<g ref={processLinesRef} id="processLines" />
				{assets.map((asset) => {
					drawnLines.current[asset.id] = {};
					return asset.barTasks.map((process) => {
						if (
							!drawnLines.current[asset.id][process.source.eventCode] &&
							process.type === "taskLine"
						) {
							drawnLines.current[asset.id][process.source.eventCode] = true;
							process = { ...process, drawLine: true };
						}
						return (
							<AssetItem
								asset={process}
								arrowIndent={arrowIndent}
								assetHeight={taskHeight}
								onEventStart={handleBarEventStart}
								key={process.key}
								isSelected={false}
								processLinesRef={processLinesRef}
								svgWidth={svgWidth}
							/>
						);
					});
				})}
			</g>
		</g>
	);
};
