import { Grid, Typography } from "@material-ui/core";
import {
	CellEditingStartedEvent,
	CellEditingStoppedEvent,
	CellValueChangedEvent,
	MenuItemDef,
} from "ag-grid-community";
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	GetContextMenuItemsParams,
	ValueFormatterParams,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { ConfirmationDialogRaw } from "../../../../../controls/modals/ConfirmationDialogRaw";
import { Heat } from "../../../../meltshop/models/Heat";
import { CatalogGradeChemistryLimit } from "../../../models/CatalogGradeChemistryLimit";
import { CatalogSampleType } from "../../../models/CatalogSampleType";
import {
	ChemistryResult,
	isValidChemistryResult,
} from "../../../models/ChemistryResult";
import {
	CHEMISTRY_SAMPLE_INITIAL_VALUES,
	ChemistrySample,
	isValidChemistrySample,
} from "../../../models/ChemistrySample";
import {
	upsertChemistryResult,
	upsertChemistrySample,
} from "../../../repositories/ChemistrySampleRepository";
import { useGridComplexStyles } from "../../../styles/gridStyles";
import { CommentDialogRaw } from "./CommentDialogRaw";
import CommentTooltip from "./CommentTooltip";

type Props = {
	loadingGrid: boolean;
	heat: Heat | null;
	chemistrySamples: ChemistrySample[];
	gradeLimits: CatalogGradeChemistryLimit[];
	sampleTypes: CatalogSampleType[];
	updateEditing: (newValue: boolean) => void;
};
interface DynamicChemistrySample extends ChemistrySample {
	[propertyName: string]: any;
}
export const TableChemistries = ({
	loadingGrid,
	heat,
	chemistrySamples,
	gradeLimits,
	sampleTypes,
	updateEditing,
}: Props) => {
	const classes = useGridComplexStyles();
	const gridRef = useRef<AgGridReact<DynamicChemistrySample>>(null);
	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {},
	});
	const [sampleTypesCodes, setSampleTypesCodes] = useState<number[]>([]);
	const [selectedHeat, setSelectedHeat] = useState<Heat | null>(null);
	const initialColumns: (ColDef | ColGroupDef)[] = [
		{
			headerName: "Sample",
			type: ["centerAligned"],
			children: [
				{
					field: "StatusCode",
					headerName: "St",
					minWidth: 40,
					maxWidth: 40,
					tooltipComponent: CommentTooltip,
					tooltipField: "StatusCode",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "SampleTypeId",
					headerName: "Type",
					minWidth: 60,
					maxWidth: 60,
					editable: true,
					cellEditor: "agSelectCellEditor",
					cellEditorParams: {
						values: sampleTypesCodes,
					},
					filterParams: {
						valueFormatter: (params: ValueFormatterParams) => {
							return lookupSampleValue(sampleTypes, params.value);
						},
					},
					valueFormatter: (params) => {
						return lookupSampleValue(sampleTypes, params.value);
					},
					tooltipComponent: CommentTooltip,
					tooltipField: "SampleTypeId",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "SampleSequence",
					headerName: "Sq",
					minWidth: 50,
					maxWidth: 50,
					type: ["numericColumn"],
					editable: true,
					tooltipComponent: CommentTooltip,
					tooltipField: "SampleSequence",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "Melter",
					headerName: "Melter",
					minWidth: 70,
					maxWidth: 70,
					editable: true,
					tooltipComponent: CommentTooltip,
					tooltipField: "Melter",
					tooltipComponentParams: { color: "#ececec" },
				},
			],
		},
	];
	const endColumns: (ColDef | ColGroupDef)[] = [
		{
			headerName: "Results",
			children: [
				{
					field: "DateString",
					headerName: "Date",
					minWidth: 120,
					tooltipComponent: CommentTooltip,
					tooltipField: "DateString",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "DateTimeResults",
					headerName: "Result",
					minWidth: 80,
					editable: true,
				},
				{
					field: "Technician",
					headerName: "Technician",
					minWidth: 100,
					editable: true,
					tooltipComponent: CommentTooltip,
					tooltipField: "Technician",
					tooltipComponentParams: { color: "#ececec" },
				},
			],
		},
	];
	const [columns, setColumns] = useState<ColDef[]>(
		initialColumns.concat(endColumns),
	);
	const [rows, setRows] = useState<DynamicChemistrySample[]>([]);
	const [showInvalidateModal, setShowInvalidateModal] =
		useState<boolean>(false);
	const [idInvalidating, setIdInvalidating] = useState<number | null>(null);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [idDeleting, setIdDeleting] = useState<number | null>(null);
	const [currentComment, setCurrentComment] = useState<string>("");
	const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
	const [idComment, setIdComment] = useState<number | null>(null);
	const getContextMenuItems = //useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const resultMenu: (string | MenuItemDef)[] = [];
			const data = params.node?.data ?? null;
			if (data === null || data === undefined) return resultMenu;
			if (data.isNew === true) {
				resultMenu.push({
					name: "Cancel",
					action: () => {
						cancel_Sample();
					},
				});
				return resultMenu;
			}
			if (data.StatusCode === "V") {
				resultMenu.push({
					name: "Invalidate Sample",
					action: () => {
						invalidate_Sample(params.node?.data);
					},
				});
				resultMenu.push({
					// custom item
					name: "Change Heat",
					action: () => {
						window.alert("Alerting about " + params.value);
					},
				});
			}
			if (
				data.Comments === null ||
				data.Comments === undefined ||
				data.Comments === ""
			) {
				resultMenu.push({
					name: "Add Comment",
					action: () => {
						comment_Sample(params.node?.data);
					},
				});
			} else {
				resultMenu.push({
					name: "Change Comment",
					action: () => {
						comment_Sample(params.node?.data);
					},
				});
			}
			resultMenu.push("separator");
			const canAdd = rows.find((x) => x.isNew === true) === undefined;
			if (canAdd) {
				resultMenu.push({
					name: "Add Sample",
					action: () => {
						add_Sample();
					},
				});
			}
			resultMenu.push({
				name: "Delete Sample",
				action: () => {
					delete_Sample(params.node?.data);
				},
			});
			return resultMenu;
		};
	// []
	//);

	const lookupSampleValue = (
		samples: CatalogSampleType[],
		sampleId: string,
	) => {
		const id = isNaN(parseInt(sampleId)) ? 0 : parseInt(sampleId);
		const sampleObject = samples.find((sample) => sample.Id === id);
		if (sampleObject === undefined) return "";
		return sampleObject.Name;
	};

	const numberRichFormatter = (
		params: ValueFormatterParams,
		decimals: number,
	) => {
		if (params.value === null || isNaN(params.value)) return "";
		return parseFloat(params.value).toFixed(decimals);
	};
	const cellNumberClass = (
		params: CellClassParams,
		minLimit: number,
		maxLimit: number,
	) => {
		if (params.value === null || isNaN(params.value)) return "error-limit";
		return parseFloat(params.value) < minLimit ||
			parseFloat(params.value) > maxLimit
			? "error-limit"
			: "error-limit2";
	};

	//#  Functional Methods
	const save_Sample = async (
		sample: DynamicChemistrySample,
		columnName: string,
		value: string,
	) => {
		if (sample === undefined || sample === null) {
			return;
		}
		const changedElement = sample.elementParams.find((x) => x === columnName); // IS CHANGING A ELEMENT COLUMNS
		const changedSample = sample.sampleParams.find((x) => x === columnName); // IS CHANGING A  SAMPLE COLUMN
		if (changedElement !== undefined) {
			// UPDATE ELEMENT
			const chemistryResult: ChemistryResult = {
				Id: null,
				ElementId: Number(changedElement),
				ChemistrySampleId: sample.Id,
				Value: value === null ? null : Number(value),
			};
			if (!isValidChemistryResult(chemistryResult)) return;
			try {
				const response = await upsertChemistryResult(chemistryResult);
				if (!response.ok) throw new Error(response.message);
				// const id = response.data.tables[0].rows[0].Id;
				updateEditing(false);
			} catch (e: any) {
				updateEditing(false);
			}
		} else if (changedSample !== undefined) {
			//UPDATE SAMPLE
			if (!isValidChemistrySample(sample)) return;
			try {
				const response = await upsertChemistrySample(sample);
				if (!response.ok) throw new Error(response.message);
				//const id = response.data.tables[0].rows[0].Id;
				updateEditing(false);
				if (sample.StatusCode === "I") setIdInvalidating(null);
				if (sample.StatusCode === "D") {
					setIdDeleting(null);
					const updatedRows = rows.filter((x) => x.StatusCode !== "D");
					setRows(updatedRows);
				}
				if (changedSample === "Comments") {
					setIdComment(null);
				}
			} catch (e: any) {
				updateEditing(false);
			}
		}
	};
	const invalidate_Sample = async (sample: DynamicChemistrySample) => {
		if (sample === null || sample === undefined) return;
		if (sample.StatusCode === "I") return;
		setIdInvalidating(sample.Id);
		setShowInvalidateModal(true);
	};
	const delete_Sample = async (sample: DynamicChemistrySample) => {
		if (sample === null || sample === undefined) return;
		if (sample.StatusCode === "D") return;
		setIdDeleting(sample.Id);
		setShowDeleteModal(true);
	};
	const add_Sample = async () => {
		if (selectedHeat === null) return;
		const newSample = CHEMISTRY_SAMPLE_INITIAL_VALUES;
		updateEditing(true);
		setRows([...rows, newSample]);
	};
	const cancel_Sample = async () => {
		const updatedRows = rows.filter((x) => x.isNew !== true);
		setRows(updatedRows);
		updateEditing(false);
	};
	const comment_Sample = async (sample: DynamicChemistrySample) => {
		if (sample === null || sample === undefined) return;
		setIdComment(sample.Id);
		setCurrentComment(sample.Comments);
		setShowCommentModal(true);
	};
	const invalidate_SampleCallback = async (value?: string) => {
		setShowInvalidateModal(false);
		if (value !== "OK") return;
		if (idInvalidating === null) return;
		const sample = rows.find((x) => x.Id === idInvalidating);
		if (sample === undefined) return;
		sample.StatusCode = "I";
		updateEditing(true);
		await save_Sample(sample, "StatusCode", "I");
	};
	const delete_SampleCallback = async (value?: string) => {
		setShowDeleteModal(false);
		if (value !== "OK") return;
		if (idDeleting === null) return;
		const sample = rows.find((x) => x.Id === idDeleting);
		if (sample === undefined) return;
		sample.StatusCode = "D";
		updateEditing(true);
		await save_Sample(sample, "StatusCode", "D");
	};
	const comment_SampleCallback = async (value?: string, comment?: string) => {
		setCurrentComment("");
		setShowCommentModal(false);
		if (value !== "OK") return;
		if (idComment === null) return;
		const sample = rows.find((x) => x.Id === idComment);
		if (sample === undefined) return;
		if (sample.Comments === (comment ?? "")) return;
		sample.Comments = comment ?? "";
		updateEditing(true);
		await save_Sample(sample, "Comments", comment ?? "");
	};
	//#

	//#  Events
	const startEditing = (params: CellEditingStartedEvent) => updateEditing(true);
	const stopEditing = (params: CellEditingStoppedEvent) => updateEditing(false);
	const valueChanged = (params: CellValueChangedEvent) => {
		save_Sample(params.data, params.column.getColId(), params.value);
	};

	//#
	const gridStyle = useMemo(() => {
		return {
			width: "100%",
			padding: "0px 15px 0px 0px",
			height: "auto",
		};
	}, [chemistrySamples]);
	const minLimits = useMemo(() => {
		{
			return gradeLimits
				.filter((limit) => limit.Enabled === true)
				.map((limit) => (
					<Grid
						item
						className={classes.footerItem}
						key={"Min" + limit.ElementId}
					>
						<Typography className={classes.footerTextItem}>
							{limit.MinValue === null
								? ""
								: limit.MinValue.toFixed(limit.Decimals)}
						</Typography>
					</Grid>
				));
		}
	}, [gradeLimits]);
	const maxLimits = useMemo(() => {
		{
			return gradeLimits
				.filter((limit) => limit.Enabled === true)
				.map((limit) => (
					<Grid
						item
						className={classes.footerItem}
						key={"Min" + limit.ElementId}
					>
						<Typography className={classes.footerTextItem}>
							{limit.MaxValue === null
								? ""
								: limit.MaxValue.toFixed(limit.Decimals)}
						</Typography>
					</Grid>
				));
		}
	}, [gradeLimits]);
	useMemo(() => {
		const newColumns: ColDef[] = [];
		gradeLimits
			.filter((limit) => limit.Enabled == true)
			.forEach((limit) => {
				const column: ColDef = {
					field: limit.ElementId.toString(),
					headerName: limit.ElementName,
					minWidth: 70,
					maxWidth: 70,
					type: ["numericColumn"],
					valueFormatter: (params) => {
						return numberRichFormatter(params, limit.Decimals);
					},
					cellClass: (params) => {
						return cellNumberClass(
							params,
							limit.MinValue ?? 0,
							limit.MaxValue ?? 0,
						);
					},
					editable: true,
				};
				newColumns.push(column);
			});
		const elementColGroup: ColGroupDef[] = [
			{
				headerName: "Elements",
				children: newColumns,
			},
		];
		setColumns(initialColumns.concat(elementColGroup, endColumns));
	}, [gradeLimits]);
	useMemo(() => {
		const newRows: DynamicChemistrySample[] = [];
		chemistrySamples.forEach((sample) => {
			const myObject: DynamicChemistrySample = { ...sample };
			const elementsId = (sample.ElementsId ?? "").split(",");
			const elementsValue = (sample.ElementsValue ?? "").split(",");
			const elementsResult = (sample.ResultsId ?? "").split(",");
			myObject.elementParams = [];
			myObject.sampleParams = [
				"SampleTypeId",
				"SampleSequence",
				"DateTimeResults",
				"StatusCode",
				"ResultSourceId",
				"Comments",
				"Technician",
				"Melter",
			];
			let i;
			for (i = 0; i < elementsResult.length; i++) {
				myObject.elementParams.push(String(elementsId[i]).trim());
				if (!!isNaN(parseFloat(elementsValue[i]))) {
					myObject[String(elementsId[i]).trim()] = null;
				} else {
					myObject[String(elementsId[i]).trim()] = parseFloat(elementsValue[i]);
				}
			}
			newRows.push(myObject);
		});
		setRows(newRows);
	}, [chemistrySamples]);
	useMemo(() => {
		const newSampleTypesCodes: number[] = [];
		sampleTypes
			.filter((sample) => sample.Enabled)
			.forEach((sample) => {
				newSampleTypesCodes.push(sample.Id ?? 0);
			});
		setSampleTypesCodes(newSampleTypesCodes);
	}, [sampleTypes]);
	useMemo(() => {
		setSelectedHeat(heat);
	}, [heat]);
	useMemo(() => {
		if (loadingGrid && gridRef.current !== null) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			className={classes.root}
		>
			<Grid item style={gridStyle} xs={12} md={12}>
				<ConfirmationDialogRaw
					title="Confirmation"
					message="The item will be invalidated.  Do you want to continue?"
					open={showInvalidateModal}
					onClose={invalidate_SampleCallback}
				/>
				<ConfirmationDialogRaw
					title="Confirmation"
					message="The item will be deleted.  Do you want to continue?"
					open={showDeleteModal}
					onClose={delete_SampleCallback}
				/>
				<CommentDialogRaw
					title={"Comment"}
					text={currentComment}
					open={showCommentModal}
					onClose={comment_SampleCallback}
				/>

				<AgGridReact
					ref={gridRef}
					className="ag-theme-alpine"
					rowSelection="single"
					pagination={false}
					animateRows={true}
					rowHeight={45}
					headerHeight={45}
					domLayout={"autoHeight"}
					singleClickEdit={true}
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					rowData={rows}
					columnDefs={columns}
					columnTypes={columnTypes}
					getContextMenuItems={getContextMenuItems}
					defaultColDef={columnSimpleDefaults}
					onCellEditingStarted={startEditing}
					onCellEditingStopped={stopEditing}
					onCellValueChanged={valueChanged}
				></AgGridReact>
			</Grid>
			{/* footer */}
			<Grid item xs={12} md={12} className={classes.footerContainer}>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="stretch"
					style={{ height: "100%" }}
				>
					<Grid item className={classes.footerTitle}>
						<Typography className={classes.footerTextTitle}>
							Grade Min
						</Typography>
					</Grid>
					{minLimits}
				</Grid>
			</Grid>
			<Grid item xs={12} md={12} className={classes.footerContainer}>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="stretch"
					style={{ height: "100%" }}
				>
					<Grid item className={classes.footerTitle}>
						<Typography className={classes.footerTextTitle}>
							Grade Max
						</Typography>
					</Grid>
					{maxLimits}
				</Grid>
			</Grid>
		</Grid>
	);
};
