import { Avatar, Grid, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		userSelect: "none",
		height: "40px",
	},
});

interface Props {
	assetName: number;
	assetId: number;
	heatStatus: string;
}
const heatStatuses: { [key: string]: any } = {
	S: { name: "Scheduled", color: "#0288D1", letter: "S" },
	M: { name: "Melting", color: "#FF7043", letter: "M" },
	T: { name: "Tapped", color: "#D2AF00", letter: "T" },
	O: { name: "Opened", color: "#43A047", letter: "O" },
	C: { name: "Closed", color: "#607D8B", letter: "C" },
	R: { name: "Returned", color: "#8D6E63", letter: "R" },
	F: { name: "Finished", color: "#3F51B5", letter: "F" },
};

export const AssetTitle = ({ assetId, assetName, heatStatus }: Props) => {
	const classes = useStyles();
	const defaultColor = "#D6D6D6";
	const colors: { [assetId: number]: string } = {
		11: "#dae8fc",
		12: "#d5e8d4",
	};
	const value = heatStatuses[heatStatus];

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12} md={12}>
					<Card
						className={classes.root}
						style={{
							backgroundColor: colors[assetId],
						}}
						variant="outlined"
					>
						<CardContent>
							<Typography>{assetName}</Typography>
						</CardContent>
					</Card>
				</Grid>
				{heatStatus && (
					<Grid item xs={12} md={12}>
						<div
							style={{
								backgroundColor: value?.color || defaultColor,
								color: "#fff",
								borderRadius: "4px",
								padding: `4px`,
								textAlign: "center",
							}}
						>
							<Typography>{value?.name}</Typography>
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
};
