export type HeatFormData = {
	HeatName: string;
	ShiftId: number;
	GradeId: number | null;
	SteelWeight: number | null;
	LadleId: number | null;
	IsExternal: boolean | null;
};

export const INITIAL_VALUES: HeatFormData = {
	HeatName: "",
	ShiftId: 0,
	GradeId: null,
	SteelWeight: null,
	LadleId: null,
	IsExternal: false,
};
