import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { DataEntryReducer } from "../reducer/data-entry.reducer";

const DataEntryContext = createContext({
	state: DataEntryReducer.getInitialState(),
	actions: DataEntryReducer.actions,
});

export const useDataEntryContext = () => useContext(DataEntryContext);

export const EAFDataEntryProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: DataEntryReducer.getInitialState(),
		reducers: DataEntryReducer.caseReducers,
	});
	return (
		<DataEntryContext.Provider value={{ state, actions }}>
			{children}
		</DataEntryContext.Provider>
	);
};
