import { TimeService, getMomentTz } from "@dexteel/mesf-core";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useStyles } from "../../styles";

const moment = getMomentTz();

interface ProbeTimeFieldProps {
	value: Date | null;
	onChange: (newValue: Date) => void;
	onFocus: () => void;
	disabled: boolean;
}

export const ProbeTimeField = ({
	value,
	onChange,
	onFocus,
	disabled,
}: ProbeTimeFieldProps) => {
	const classes = useStyles();

	const [internalValue, setInternalValue] = useState<Date | null>(null);
	const firstRender = useRef(true);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			setInternalValue(moment.utc(value).toDate());
		}
	}, [value]);

	return (
		<DateTimePicker
			timezone={TimeService.getInstance().getServerTimeZone()}
			format="HH:mm"
			readOnly={disabled}
			disabled={disabled}
			timeSteps={{ minutes: 1, seconds: 1 }}
			value={moment(internalValue) as any}
			onChange={(newValue: Moment | null) => {
				setInternalValue(newValue ? newValue.toDate() : null);
			}}
			disableOpenPicker
			className={classes.dateTimePickerX}
			slotProps={{
				textField: {
					onBlur: (e) => {
						if (internalValue && internalValue !== value) {
							onChange(internalValue);
						}
					},
					onFocus,
					placeholder: "HH:mm",
					inputProps: {
						className: classes.simpleCellNumeric,
					},
				},
			}}
		/>
	);
};
