import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";

interface Props {
	message: string | null;
	onHide: () => void;
}

export const NotificationSnackBar = ({ message, onHide = () => {} }: Props) => {
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		onHide();
	};
	const [open, setOpen] = useState(true);
	useEffect(() => {
		setOpen(message !== "" && message !== null);
	}, [message]);

	return (
		<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
			<MuiAlert
				elevation={6}
				variant="filled"
				severity="success"
				onClose={handleClose}
			>
				{message}
			</MuiAlert>
		</Snackbar>
	);
};
