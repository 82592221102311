import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { MenuOptionType } from "../../../../controls/contextMenu/models/MenuOptionType";
import { CatalogCategory } from "../../models/CatalogCategory";
type Props = {
	setCategoryId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	setShowCreateModal: Function;
};
export const useCategoriesOptionsFunctions = ({
	setCategoryId,
	setShowEditModal,
	setShowDeleteModal,
	setShowCreateModal,
}: Props) => {
	const getMenuOptions = (data: CatalogCategory) => {
		let options: MenuOptionType[] = [];
		if (!data) {
			options = options.concat([
				{
					name: "New Category",
					key: "new_category",
					onClick: () => {
						setShowCreateModal(true);
					},
					icon: <PlaylistAddIcon />,
				},
			]);
		} else {
			options = options.concat([
				{
					name: "New Category",
					key: "new_category",
					onClick: () => {
						setShowCreateModal(true);
					},
					icon: <PlaylistAddIcon />,
				},
				{
					name: "Edit Category",
					key: "edit_category",
					onClick: () => {
						setCategoryId(data.DelayCategoryId);
						setShowEditModal(true);
					},
					icon: <EditIcon />,
				},
				{
					name: "Delete Category",
					key: "delete_category",
					onClick: () => {
						setCategoryId(data.DelayCategoryId);
						setShowDeleteModal(true);
					},
					icon: <DeleteIcon />,
				},
			]);
		}
		return options;
	};
	return {
		getMenuOptions,
	};
};
