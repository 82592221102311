import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogMaterialReducer } from "./CatalogMaterialReducer";

const CatalogMaterialContext = createContext({
	state: CatalogMaterialReducer.getInitialState(),
	actions: CatalogMaterialReducer.actions,
});

export const useCatalogMaterialContext = () =>
	useContext(CatalogMaterialContext);

export const CatalogMaterialProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogMaterialReducer.getInitialState(),
		reducers: CatalogMaterialReducer.caseReducers,
	});
	return (
		<CatalogMaterialContext.Provider value={{ state, actions }}>
			{children}
		</CatalogMaterialContext.Provider>
	);
};
