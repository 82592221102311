import { useUTLSettingsContext } from "@dexteel/mesf-core";
import { Box, Paper } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDETablesStyles } from "../styles/useEAFDETablesStyles";
import { useTableData } from "./hooks/useHeatingsTableData";

export const TableHeatings = () => {
	const { state } = useUTLSettingsContext();

	const MAX_HEATINGS = get(state.settings, "MAX_HEATINGS", 10);

	const {
		state: { deProcess },
	} = useDataEntryContext();

	const heatings = deProcess?.heatings;
	const rowCount = heatings ? heatings.length : 0;
	const hasMaxHeatings = (heatings && heatings.length >= MAX_HEATINGS) || false;

	const classes = useEAFDETablesStyles({ rowCount });

	const { columns, defaultColDef } = useTableData();

	return (
		<>
			<Paper className={classes.root}>
				<Box padding={1} className={`ag-theme-balham ${classes.smAgGridBox}`}>
					<AgGridReact
						rowSelection="single"
						suppressRowClickSelection={true}
						pagination={false}
						rowHeight={50}
						headerHeight={35}
						singleClickEdit={false}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={heatings}
						columnDefs={columns}
						defaultColDef={defaultColDef}
						domLayout="normal"
					/>
				</Box>
			</Paper>
		</>
	);
};
