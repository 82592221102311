import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import React from "react";
import { useL3ChemistryContext } from "../L3ChemistryContext";

interface Props {
	showAck: boolean;
	setShowAck: (showAck: boolean) => void;
}

export const ShowAckCheckbox = ({ showAck, setShowAck }: Props) => {
	return (
		<>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={showAck}
							color="primary"
							onChange={(event) => setShowAck(event.target.checked)}
						/>
					}
					label="Show Acknowledged"
				/>
			</FormGroup>
		</>
	);
};
