import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import numeral from "numeral";
import React from "react";
import DatePicker from "react-datepicker";
import "./inputControls.css";
import { getMomentTz } from "@dexteel/mesf-core";

const moment = getMomentTz();

interface SingleDropDownControlProps {
	title?: string;
	dataSource?: any;
	selectedValue?: any;
	onChange?: any;
	styleLabel?: any;
	styleList?: any;
	hasChoose?: boolean;
	idValue: string | "id";
	nameValue: string | "description";
}

const useStyles = makeStyles({
	container: {
		backgroundColor: "#fff",
	},
	select: {
		"& ul": {
			backgroundColor: "#fff",
		},
		"& li": {
			fontSize: "1rem",
		},
	},
	selectDisplay: {
		fontSize: "1rem",
		paddingLeft: "20px",
		width: "100%",
	},
});

const SingleDropDownControl = (props: SingleDropDownControlProps) => {
	const options = props.dataSource.map((item: any, index: number) => (
		<MenuItem value={item[props.idValue].toString()} key={index}>
			<span>{item[props.nameValue]}</span>
		</MenuItem>
	));
	const classes = useStyles();
	return (
		<div className="input-group">
			<div className="input-group-prepend">
				<span className="input-group-text" style={props.styleLabel || {}}>
					{props.title}
				</span>
			</div>
			<Select
				MenuProps={{ classes: { paper: classes.select } }}
				SelectDisplayProps={{ className: classes.selectDisplay }}
				className={"custom-select mes-custom-select"}
				onChange={(e) =>
					props.onChange(e.target.value === "DEFAULT" ? "" : e.target.value)
				}
				value={props.selectedValue || "DEFAULT"}
				style={{ backgroundColor: "#fff" }}
			>
				{props.hasChoose && (
					<MenuItem value={"DEFAULT"}>{"Choose " + props.title}</MenuItem>
				)}
				{options}
			</Select>
		</div>
	);
};

interface DateControlProps {
	value?: any;
	onChange?: any;
	title?: string;
	styleGeneral?: any;
	styleLabel?: any;
}

const DateControl = (props: DateControlProps) => {
	return (
		<React.Fragment>
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="input-group-text" style={props.styleLabel || {}}>
						{props.title}
					</span>
				</div>
				<DatePicker
					className="form-control mes-filter-datepicker"
					wrapperClassName="mes-core-filter-datepicker"
					selected={props.value}
					onChange={(date) => props.onChange(date)}
				/>
			</div>
		</React.Fragment>
	);
};
export type NumericTextControlProps = {
	value: any;
	title: any;
	styleLabel: any;
	readOnly: any;
	styleTextBox: any;
	onChange: any;
	name: any;
};
const formatNumber = (value: any, numberFormat: string) => {
	return numeral(value).format(numberFormat);
};
const NumericTextControl = (props: NumericTextControlProps) => {
	const { value, title, styleLabel, readOnly, styleTextBox, onChange, name } =
		props; //typeNumber 1 = real, 2 = integer, 3 = natural
	const [formattedValue, setFormattedValue] = React.useState(
		formatNumber(value, "0.00"),
	);
	const changedValue = (e: any) => {
		setFormattedValue(e.target.value);
	};

	const valueBlurred = (inputValue: string) => {
		const number = Number(inputValue);
		const numeralObj = numeral(inputValue);
		if (isNaN(numeralObj.value() as any)) {
			setFormattedValue(formatNumber(value, "0.00"));
			return;
		}
		onChange(number, name || "");
	};
	React.useEffect(() => {
		setFormattedValue(formatNumber(value, "0.00"));
	}, [value]); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="input-group">
			<div className="input-group-prepend">
				<span className="input-group-text" style={styleLabel || {}}>
					{title}
				</span>
			</div>
			<input
				type="text"
				className={"form-control mes-numeric"}
				placeholder={title}
				readOnly={readOnly}
				value={formattedValue || ""}
				onChange={(e) => changedValue(e)}
				onBlur={(e) => valueBlurred(e.target.value)}
				style={styleTextBox || {}}
			></input>
		</div>
	);
};
export { SingleDropDownControl, DateControl, NumericTextControl };
