import { MesfModal } from "@dexteel/mesf-core";
import { Button, Grid } from "@material-ui/core";

type Props = {
	show: boolean;
	onHide: (proceed: boolean) => void;
	title: string;
	message: string;
	isLoading: boolean;
	color?: "primary" | "secondary" | "default";
};

export const ConfirmModal = ({
	show,
	onHide,
	title,
	message,
	isLoading,
	color = "primary",
}: Props) => {
	return (
		<>
			<MesfModal
				title={title}
				open={show}
				id="confirm-modal"
				handleClose={() => onHide(false)}
			>
				<MesfModal.Content style={{ padding: "15px 30px" }}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12}>
							<p>{message}</p>
						</Grid>
					</Grid>
				</MesfModal.Content>
				<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item md={3} xs={12} style={{ margin: 0 }}>
							<Button
								fullWidth
								variant="contained"
								color="default"
								onClick={() => onHide(false)}
							>
								NO
							</Button>
						</Grid>
						<Grid item md={3} xs={12} style={{ margin: 0 }}>
							<Button
								fullWidth
								onClick={() => onHide(true)}
								variant="contained"
								color={color}
								type="submit"
								disabled={isLoading}
							>
								YES
							</Button>
						</Grid>
					</Grid>
				</MesfModal.Actions>
			</MesfModal>
		</>
	);
};
