import { Grid } from "@material-ui/core";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { GridOptions } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React from "react";
type Props = GridOptions & {};
export const MESAgGrid = (props: Props) => {
	return (
		<>
			<Grid
				item
				style={{ height: "85%", width: "100%", padding: "0 15px" }}
				xs={12}
				md={8}
				className="ag-theme-alpine"
			>
				<AgGridReact
					rowSelection="single"
					pagination={true}
					paginationPageSize={10}
					animateRows={true}
					rowHeight={35}
					{...props}
				></AgGridReact>
			</Grid>
		</>
	);
};
