import { makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles((theme) => ({
	root: {
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-group-text ": {
			fontSize: 11,
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
	},

	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
}));
