import { useAssetContext } from "@dexteel/mesf-core";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

export const AssetSelector: React.FC<{
	type: "EAF" | "Caster";
	value: number;
	error?: boolean;
	onChange: (value: number) => void;
	disabled?: boolean;
}> = ({ value, onChange, disabled = false, type, error = false }) => {
	const {
		state: { allAssets },
	} = useAssetContext();

	const assets = allAssets.filter((asset) => asset.AssetName.includes(type));

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		onChange(event.target.value as number);
	};

	return (
		<FormControl
			error={error}
			variant={"outlined"}
			fullWidth
			disabled={disabled}
		>
			<InputLabel>Asset</InputLabel>
			<Select
				disabled={disabled}
				value={value}
				label={"Asset"}
				onChange={handleChange}
			>
				{assets.map((asset) => (
					<MenuItem key={asset.AssetId} value={asset.AssetId}>
						{asset.AssetName}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
