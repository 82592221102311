import { CasterProcessesProvider } from "./CasterProcessesContext";
import { CasterProcessesPage } from "./CasterProcessesPage";

type Props = {};
const CasterProcesses = (props: Props) => {
	return (
		<CasterProcessesProvider>
			<CasterProcessesPage />
		</CasterProcessesProvider>
	);
};
export default CasterProcesses;
