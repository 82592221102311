import { ErrorModal } from "@dexteel/mesf-core";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDataEntryContext } from "../context/reducer.provider";
import { useEAFDataEntryActions } from "../hooks/useEAFDataEntryActions";
import { Ladle } from "../models/catalogs.models";
import { getLadles } from "../repositories/eaf.repositories";
import { useStyles } from "../styles/useDEStyles";

export const LadleSelector = ({
	value,
	onChange,
	disabled,
}: {
	value: number | null;
	onChange: (value: number) => void;
	disabled: boolean;
}) => {
	const {
		state: { deProcess },
	} = useDataEntryContext();
	const { saveHeat } = useEAFDataEntryActions();
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [options, setOptions] = useState<Ladle[]>([]);
	const [ladle, setLadle] = useState<number>();
	const classes = useStyles();
	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		const selectedValue = event.target.value as number;
		onChange(selectedValue);
		if (selectedValue) {
			saveHeat("LadleId", selectedValue).then(() => {});
		}
		setLadle(selectedValue);
	};

	const loadData = async () => {
		setIsLoading(true);
		const resp = await getLadles();
		setOptions(resp.data!);
		setIsLoading(false);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			loadData().then(() => {});
			return;
		}
	}, []);

	return (
		<>
			<FormControl variant="outlined" fullWidth>
				<InputLabel id="ladleSelector" className={classes.inputLabel}>
					Ladle
				</InputLabel>
				<Select
					fullWidth
					disabled={isLoading || disabled}
					labelId="ladleSelector"
					id="ladleSelector"
					value={value || ""}
					onChange={handleChange}
					label="Ladle"
				>
					{options.map((option) => (
						<MenuItem key={`ladle-${option.LadleId}`} value={option.LadleId}>
							{option.LadleName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
