import {
	ErrorModal,
	ShiftDayNavigatorControl,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../../controls/LazyLoading";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { L3MechanicalModel } from "../../models/L3MechanicalModel";
import {
	getL3Mechanicales,
	saveL3Mechanical,
} from "../../repositories/L3Repository";
import { ShowAckCheckbox } from "../chemistry/components/ShowAckCheckbox";
import { useL3MechanicalContext } from "./L3MechanicalContext";
import { TableL3Mechanical } from "./components/TableL3Mechanical";
type Props = {};
export const L3MechanicalPage = (props: Props) => {
	const navigate = useNavigate();
	const { shiftId: shiftIdParam } = useParams();
	const [params, setParams] = useSearchParams();

	const { shiftInfo, getShiftDataFromAPI } = useShiftNavigator();
	const { state, actions } = useL3MechanicalContext();

	const [error, setError] = useState<string>("");

	const [showSendMechanicalModal, setShowSendMechanicalModal] =
		useState<boolean>(false);
	const [mechanicalToSend, setMechanicalToSend] =
		useState<L3MechanicalModel | null>(null);
	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const shiftId = get(shiftInfo, "CurrentShiftId", null);
		const resp = await getL3Mechanicales(shiftId, state.showAck);
		if (resp.ok) {
			actions.setMechanicales(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		actions.setIsLoadingGrid(false);
	};
	const sendMechanical = (data: L3MechanicalModel) => {
		if (data.Sent) return;
		setMechanicalToSend(data);
		setShowSendMechanicalModal(true);
	};
	const sendMechanicalCallback = async (value?: string) => {
		setShowSendMechanicalModal(false);
		if (value !== "OK") return;
		if (mechanicalToSend === null) return;
		const forced = mechanicalToSend.ChecksPassed !== true;
		//mechanicalToSend.Sent = true;
		if (
			mechanicalToSend.MechanicalSampleId !== null &&
			mechanicalToSend.MechanicalSampleId > 0
		) {
			actions.setIsLoadingBackground(true);
			const resp = await saveL3Mechanical(
				mechanicalToSend.MechanicalSampleId,
				forced,
			);
			if (resp.ok) {
				await getDataFromAPI();
			} else {
				setError(resp.message);
			}
			actions.setIsLoadingBackground(false);
		}
	};
	useEffect(() => {
		if (shiftInfo) {
			const shiftId = get(shiftInfo, "CurrentShiftId", null);
			navigate(`/lab-l3/mechanical/${shiftId}?ack=${state.showAck}`);
			(async () => {
				await getDataFromAPI();
			})();
		}
	}, [shiftInfo, state.showAck]);

	useEffect(() => {
		if (shiftIdParam) {
			(async () => {
				const shiftId = Number(shiftIdParam);
				await getShiftDataFromAPI(null, shiftId);
			})();
		} else {
			(async () => {
				await getShiftDataFromAPI(null, 0);
			})();
		}
		if (params.get("ack") === "true") {
			actions.setShowAck(true);
		}
	}, []);
	if (!state.isLoadingPage) {
		return (
			<>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						borderRadius: "0.75rem",
						height: "93vh",
						padding: "15px 20px 10px",
					}}
				>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										L3 Mechanical
									</Typography>
								</Grid>
							</Grid>

							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<ShiftDayNavigatorControl preventInit />
									<Grid item xs={4} style={{ margin: 0, padding: "15px" }}>
										<ShowAckCheckbox
											showAck={state.showAck}
											setShowAck={actions.setShowAck}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Divider />
							<ConfirmationDialogRaw
								title="Confirmation"
								message="The sample will be sent.  Do you want to continue?"
								open={showSendMechanicalModal}
								onClose={sendMechanicalCallback}
							/>
							<TableL3Mechanical
								loadingGrid={state.isLoadingGrid}
								rows={state.mechanicales || []}
								OnSend={sendMechanical}
							/>
						</Grid>
					</Grid>
				</Paper>
				<ErrorModal error={error} onHide={() => setError("")} />
			</>
		);
	} else return <LazyLoading />;
};
