import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { L3ChemistryReducer } from "./L3ChemistryReducer";

const L3ChemistryContext = createContext({
	state: L3ChemistryReducer.getInitialState(),
	actions: L3ChemistryReducer.actions,
});

export const useL3ChemistryContext = () => useContext(L3ChemistryContext);

export const L3ChemistryProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: L3ChemistryReducer.getInitialState(),
		reducers: L3ChemistryReducer.caseReducers,
	});
	return (
		<L3ChemistryContext.Provider value={{ state, actions }}>
			{children}
		</L3ChemistryContext.Provider>
	);
};
