import { ErrorModal } from "@dexteel/mesf-core";
import { FormHelperText } from "@material-ui/core";
import { useAutocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Section } from "../../../models/MoldSizeSection";
import { getSectionsPerMoldSize } from "../../../repositories/ProductionRepository";
import { useStyles } from "../styles";

interface Params {
	moldSizeId: number | null;
	value: number | null;
	onChange: (value: string | null) => void;
	onFocus: () => void;
	required?: boolean;
	readonly?: boolean;
	className?: string;
	label?: string;
	inputError?: string;
}
export const SectionAutocomplete = ({
	value,
	onChange,
	onFocus,
	moldSizeId,
	readonly,
	required,
	className,
	label,
	inputError,
}: Params) => {
	const [isLoading, setIsLoading] = useState(false);
	const [sectionList, setSectionList] = useState<Section[]>([]);
	const [error, setError] = useState("");
	const [sectionName, setSectionName] = useState<string>("");
	const classes = useStyles();

	const {
		getRootProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
	} = useAutocomplete({
		id: "use-autocomplete-demo",
		options: sectionList,
		getOptionLabel: (option) => option.SectionName,
		value: sectionList.find((section) => section.SectionId == value) || null,
		onChange: (event, newValue) => {
			onChange(newValue?.SectionId.toString() || null);
		},
		onInputChange: (event, newInputValue) => {
			setSectionName(newInputValue);
		},
	});

	const loadData = async () => {
		setIsLoading(true);
		const resp = await getSectionsPerMoldSize(moldSizeId);
		if (resp.ok) {
			const sections = resp.data.tables[0].rows;
			setSectionList(sections);
		} else setError(resp.message);
		setIsLoading(false);
	};

	useEffect(() => {
		loadData().then(() => {});
	}, []);

	return (
		<>
			<div onFocus={() => onFocus()}>
				<div {...getRootProps()}>
					<input
						id="sectionName"
						className={className}
						placeholder={isLoading ? "Loading..." : label}
						{...getInputProps()}
						style={{
							width: "100%",
							height: "35px",
							border: `1px solid ${inputError ? "red" : "#ced4da"}`,
						}}
						value={readonly ? " " : sectionName}
						disabled={readonly}
						required={required}
					/>
					{inputError && <FormHelperText error>{inputError}</FormHelperText>}
				</div>
				{groupedOptions.length > 0 ? (
					<div style={{ width: "20px", position: "relative" }}>
						<ul className={classes.listbox} {...getListboxProps()}>
							<li value={""}> </li>
							{groupedOptions.map((option, index) => (
								<li {...getOptionProps({ option, index })}>
									{option.SectionName}
								</li>
							))}
						</ul>
					</div>
				) : null}
			</div>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};

/*
LifeCycle of a Component

Stateless Components
<input value={} onChange={} onFocus={}/>


Stateful Components

<SectionAutocomplete initialValue={} onChange={} onFocus={}/>

// SectionAutocomplete.tsx
Loads its data and then applies initial value if given

*/
