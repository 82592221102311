import {
	ButtonWithLoading,
	SimpleSelectorControl,
	getMomentTz,
} from "@dexteel/mesf-core";
import { Button, Grid } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";
import { useState } from "react";
import { DynamicGenericReportFilter } from "../../models/GenericReportFilter";
import {
	GenericReportFilterDataSource,
	GenericReportMetadataFilter,
} from "../../models/GenericReportMetadataFilter";
type Props = {
	metadataFilters: GenericReportMetadataFilter[];
	filters: DynamicGenericReportFilter;
	filterSources: GenericReportFilterDataSource[][];
	updateFilters: (newValue: DynamicGenericReportFilter) => void;
	refreshData: () => void;
	onExcelExport: () => void;
};

const moment = getMomentTz();

export const ReportFilterPanel = ({
	metadataFilters,
	filters,
	filterSources,
	updateFilters,
	refreshData,
	onExcelExport,
}: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const getDataSource = (index: number): any[] => {
		const result: any[] = [];
		filterSources[index]?.forEach((item) => {
			result.push({
				id: item.Id,
				description: item.Name,
			});
		});
		return result;
	};
	const onChangeFilter = (value: any, key: string) => {
		const newValues: DynamicGenericReportFilter = {
			...filters,
		};
		if (key === "startDate" || key === "endDate") newValues[key] = value;
		else newValues[key] = value;
		updateFilters(newValues);
	};
	const handleApply = async () => {
		if (refreshData) {
			setIsLoading(true);
			await refreshData();
			setIsLoading(false);
		}
	};
	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				style={{ marginTop: "15px", marginBottom: "15px" }}
			>
				<Grid item md={9} xs={12}>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={1}
					>
						{filters.showStartDate && (
							<Grid item>
								<DatePicker
									label="Start"
									format="DD/MM/YYYY"
									value={moment(filters.startDate) as any}
									onChange={(value: Moment | null) => {
										if (value) onChangeFilter(value!.toDate(), "startDate");
									}}
									slotProps={{
										textField: {
											variant: "outlined",
											size: "small",
											inputProps: {
												style: {
													width: "110px",
												},
											},
										},
									}}
								/>
							</Grid>
						)}
						{filters.showEndDate && (
							<Grid item>
								<DatePicker
									label="End"
									value={moment(filters.endDate) as any}
									format="DD/MM/YYYY"
									onChange={(value: Moment | null) => {
										if (value) onChangeFilter(value!.toDate(), "endDate");
									}}
									disableFuture
									slotProps={{
										textField: {
											variant: "outlined",
											size: "small",
											inputProps: {
												style: {
													width: "110px",
												},
											},
										},
									}}
								/>
							</Grid>
						)}
						<Grid item md={2} xs={12}>
							<ButtonWithLoading
								isLoading={isLoading}
								variant="contained"
								color="primary"
								onClick={() => {
									handleApply();
								}}
								size="large"
							>
								<span style={{ padding: "0 2rem" }}>Apply</span>
							</ButtonWithLoading>
						</Grid>
						{metadataFilters.map((item, index) => (
							<Grid item md={3} xs={12} key={"grid_" + index}>
								<SimpleSelectorControl
									key={"select_" + index}
									title={item.FilterName}
									dataSource={getDataSource(index)}
									hasChoose={true}
									onChange={(value: string) => {
										onChangeFilter(value, item.Parameter);
									}}
									selectedValue={filters[item.Parameter]}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid
					item
					container
					md={3}
					xs={12}
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
				>
					<Grid item>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								onExcelExport();
							}}
							startIcon={<i className="far fa-file-excel"></i>}
						>
							Export to Excel
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

// interface SimpleSelectorControlProps {

//   selectedValue?: any;
//   onChange?: any;

//   styleLabel?: any;
//   styleList?: any;
// }
