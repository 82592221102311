import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import LogbookIndex from "../../pages/logbook/index";

export const LogbookRoutes = [
	<Route
		path="/logbook/*"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<LogbookIndex />
			</Suspense>
		}
	/>,
];
