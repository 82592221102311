import React from "react";
import { getCellDataValue } from "../../helpers/table-helper";
import { BodyCellSetup } from "../../types/table-body";
import { ReportBodyCell } from "./body-cell";

export type ReportBodyRowProps = {
	cells: BodyCellSetup[];
	row: any;
	cellClass: string;
	onRowClick?: (data: any) => void;
};

export const ReportBodyRow: React.FC<ReportBodyRowProps> = ({
	cells,
	row,
	cellClass,
	onRowClick,
}) => {
	const clickRow = (event: React.MouseEvent, data: any) => {
		if (onRowClick !== undefined) onRowClick(data);
	};
	let cellRowClass = "";
	if (onRowClick !== undefined) cellRowClass = "selectableRow";
	const rowContent = cells.map((cell, index) => {
		const data = getCellDataValue(cell.field, row);
		// let fields = cell.field.split('.');
		// let data : any = row;
		// for (let i = 0; i< fields.length; i++) {
		//     let newData = data[fields[i]];
		//     if(newData === undefined){
		//         data = "";
		//         break;
		//     }
		//     data = newData;
		// }
		const reportBodyCellProps = {
			cell,
			data,
			cellClass,
		};
		return <ReportBodyCell key={index} {...reportBodyCellProps} />;
	});
	return (
		<tr
			onClick={(event) => {
				clickRow(event, row);
			}}
			className={cellRowClass}
		>
			{rowContent}
		</tr>
	);
};
