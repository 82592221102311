import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { LinkCellRenderer } from "../../../../controls/ag-grid/components/ButtonCellRenderer";
type Props = {};
export const useTableData = ({}: Props) => {
	const cellNumberClass = (isFirst: boolean) => {
		return isFirst ? "info-limit" : "info-limit2";
	};

	const columns: (ColDef | ColGroupDef)[] = [
		{
			field: "HeatName",
			headerName: "Heat #",
			// cellRenderer: (params: any) => {
			// 	return LinkCellRenderer({
			// 		...params,
			// 		ink: `/heat-report/caster/${params.data.HeatName}`,
			// 	});
			// },
			flex: 1,
			minWidth: 65,
		},
		{
			field: "LadleOpen",
			headerName: "Open",
			minWidth: 55,
			maxWidth: 80,
			cellRenderer: LinkCellRenderer,
			cellRendererParams: { linkProperty: "HeatNameLink" },
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.LadleOpen}`, "HH:mm") || "",
		},
		{
			headerName: "Nozzle",
			type: ["centerAligned"],
			children: [
				{
					field: "NozzleSize",
					headerName: "Size",
					type: ["numericColumn", "numericWidth"],
					minWidth: 48,
					maxWidth: 70,
				},
				{
					field: "NozzleHeats",
					headerName: "Heats",
					type: ["numericColumn", "numericWidth"],
					minWidth: 58,
					maxWidth: 80,
					cellClass: (params) => {
						return cellNumberClass(params.data.NozzleFirst);
					},
				},
			],
		},
		{
			headerName: "Ladle",
			type: ["centerAligned"],
			children: [
				{
					field: "LadleName",
					headerName: "#",
					type: ["numericColumn", "numericWidth"],
					minWidth: 50,
					maxWidth: 120,
				},
				{
					field: "HeatsOnLadle",
					headerName: "Heats",
					type: ["numericColumn", "numericWidth"],
					minWidth: 58,
					maxWidth: 120,
				},
				{
					field: "ContactTimeOnLadle",
					headerName: "Contact Time",
					type: ["numericColumn", "numericWidth"],
					minWidth: 110,
					maxWidth: 120,
				},
			],
		},
		{
			headerName: "Tundish",
			type: ["centerAligned"],
			children: [
				{
					field: "TundishName",
					headerName: "#",
					type: ["numericColumn", "numericWidth"],
					minWidth: 50,
				},
				{
					field: "TundishHeats",
					headerName: "Heats",
					type: ["numericColumn", "numericWidth"],
					minWidth: 58,
					cellClass: (params) => {
						return cellNumberClass(params.data.TundishFirst);
					},
				},
			],
		},

		{
			headerName: "Shroud",
			type: ["centerAligned"],
			children: [
				{
					field: "TundishShroudName",
					headerName: "#",
					type: ["numericColumn", "numericWidth"],
					minWidth: 50,
				},
				{
					field: "TundishShroudHeats",
					headerName: "Heats",
					type: ["numericColumn", "numericWidth"],
					minWidth: 58,
					cellClass: (params) => {
						return cellNumberClass(params.data.TundishShroudFirst);
					},
				},
			],
		},

		{
			headerName: "S1",
			type: ["centerAligned"],
			children: [
				{
					headerName: "Housing",
					type: ["centerAligned"],
					children: [
						{
							field: "S1HousingName",
							headerName: "Name",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 90,
						},
						{
							field: "S1HousingHeats",
							headerName: "Heats",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 80,
							cellClass: (params) => {
								return cellNumberClass(params.data.S1HousingFirst);
							},
						},
					],
				},
				{
					headerName: "Mold",
					type: ["centerAligned"],
					children: [
						{
							field: "S1MoldName",
							headerName: "Name",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 90,
						},
						{
							field: "S1MoldHeats",
							headerName: "Heats",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 80,
							cellClass: (params) => {
								return cellNumberClass(params.data.S1MoldFirst);
							},
						},
					],
				},
			],
		},
		{
			headerName: "S2",
			type: ["centerAligned"],
			children: [
				{
					headerName: "Housing",
					type: ["centerAligned"],
					children: [
						{
							field: "S2HousingName",
							headerName: "Name",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 90,
						},
						{
							field: "S2HousingHeats",
							headerName: "Heats",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 80,
							cellClass: (params) => {
								return cellNumberClass(params.data.S2HousingFirst);
							},
						},
					],
				},
				{
					headerName: "Mold",
					type: ["centerAligned"],
					children: [
						{
							field: "S2MoldName",
							headerName: "Name",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 90,
						},
						{
							field: "S2MoldHeats",
							headerName: "Heats",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 80,
							cellClass: (params) => {
								return cellNumberClass(params.data.S2MoldFirst);
							},
						},
					],
				},
			],
		},
		{
			headerName: "S3",
			type: ["centerAligned"],
			children: [
				{
					headerName: "Housing",
					type: ["centerAligned"],
					children: [
						{
							field: "S3HousingName",
							headerName: "Name",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 90,
						},
						{
							field: "S3HousingHeats",
							headerName: "Heats",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 80,
							cellClass: (params) => {
								return cellNumberClass(params.data.S3HousingFirst);
							},
						},
					],
				},
				{
					headerName: "Mold",
					type: ["centerAligned"],
					children: [
						{
							field: "S3MoldName",
							headerName: "Name",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 90,
						},
						{
							field: "S3MoldHeats",
							headerName: "Heats",
							type: ["numericColumn", "numericWidth"],
							minWidth: 58,
							maxWidth: 60,
							cellClass: (params) => {
								return cellNumberClass(params.data.S3MoldFirst);
							},
						},
					],
				},
			],
		},
	];

	return { columns };
};
