import { get } from "react-hook-form";
import { useDelaysReportContext } from "../context/DelaysReportContext";
import { getShiftsAndCrews } from "../repositories/DelaysReportRepository";

export const useSearchShiftsAndCrews = () => {
	const {
		actions: { setShiftsList, setCrewsList },
	} = useDelaysReportContext();

	return async () => {
		setShiftsList([]);
		setCrewsList([]);
		const res = await getShiftsAndCrews();
		if (res.ok) {
			const rowsSource = get(res, "data", []);
			const shifts = rowsSource?.tables[0].rows;
			const crews = rowsSource?.tables[1].rows;
			const shiftsWithAll = [{ Shift: "All" }, ...shifts];
			const crewsWithAll = [{ Crew: "All" }, ...crews];

			setShiftsList(shiftsWithAll);
			setCrewsList(crewsWithAll);
		}
	};
};
