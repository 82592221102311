import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	dxtToLocalServerTime,
	getMomentTz,
} from "@dexteel/mesf-core";
import { Autocomplete } from "@material-ui/lab";
import { isNil } from "lodash-es";
import React, { ChangeEvent, useEffect, useState } from "react";
import { UnLinkedHeat } from "../../../../caster/models/UnLinkedHeat";
import {
	linkEAFProcess,
	searchUnlinkedHeats,
} from "../../../eaf-processes/repositories/EAFRepository";
import { useDataEntryContext } from "../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../hooks/useEAFDataEntryActions";

const moment = getMomentTz();

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};
export const LinkEAFProcess = ({ show, onHide }: Props) => {
	const { refreshData } = useEAFDataEntryActions();
	const { state, actions } = useDataEntryContext();

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoadingData, setIsLoadingData] = useState(false);

	const [unlinkedHeats, setUnLinkedHeats] = useState<UnLinkedHeat[]>([]);
	const [selectedHeat, setSelectedHeat] = useState<any>(null);
	const [searchText, setSearchText] = useState("");

	const [error, setError] = useState("");
	const [showWarningModal, setShowWarningModal] = useState(false);

	const process = state.deProcess?.process;
	const title = `Link EAF Process / Start: ${dxtToLocalServerTime(
		`${moment.utc(process?.Start)}`,
		"HH:mm",
	)}`;

	const onSubmit = async () => {
		if (process) {
			if (!selectedHeat) {
				setError("Please select a heat");
				return;
			}
			if (isNil(process)) return;

			if (selectedHeat.IsLinked === 1) {
				setShowWarningModal(true);
				return;
			}

			const response = await linkEAFProcess(
				process.ProcessId,
				selectedHeat.HeatId,
			);
			if (response.ok) {
				actions.setNotificationMessage("Process linked successfully");
				await refreshData();
			} else {
				setError(response.message);
			}
		} else {
			setError("Process not found");
		}
		onHide(true);
	};

	const handleSearchChange = async () => {
		if (!process) return;
		const heats = await searchUnlinkedHeats(
			searchText,
			process.Start as Date,
			process.AssetId,
		);
		if (heats.ok && heats.data?.tables?.[0]?.rows) {
			setUnLinkedHeats(heats.data.tables[0].rows);
		}
	};

	const handleInputChange = (event: ChangeEvent<{}>, value: string) => {
		setSearchText(value);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleSearchChange();
		}
	};

	const handleChange = (
		event: ChangeEvent<{}>,
		newValue: UnLinkedHeat | null,
	) => {
		setSelectedHeat(newValue as any);
	};

	useEffect(() => {
		if (show && process) {
			setIsSubmitLoading(false);
			(async () => {
				setSelectedHeat(null);
				setIsLoadingData(true);
				const heats = await searchUnlinkedHeats(
					"",
					process.Start as Date,
					process.AssetId,
				);
				if (
					heats.ok &&
					heats.data &&
					heats.data.tables &&
					heats.data.tables[0].rows
				) {
					setUnLinkedHeats(heats.data.tables[0].rows);
				}
				setIsLoadingData(false);
			})();
		}
	}, [show, process]);

	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						maxWidth="xl"
						open={show}
						handleClose={() => {
							onHide(true);
						}}
						id="linkEAFProcess"
						title={title}
					>
						{isLoadingData ? (
							<>
								<Grid
									container
									style={{
										padding: "60px 280px",
									}}
									justifyContent="center"
									alignContent="center"
								>
									<Grid item>
										<CircularProgress />
									</Grid>
								</Grid>
							</>
						) : (
							<form>
								<MesfModal.Content
									style={{
										padding: "15px 30px",
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									<Grid container spacing={1} alignItems="center">
										<Grid item md={2}>
											<Typography
												style={{
													margin: 0,
													padding: 0,
													display: "flex",
													alignItems: "center",
													height: "56px",
												}}
												variant="h6"
											>
												To Heat
											</Typography>
										</Grid>
										<Grid item md={10}>
											<Autocomplete
												options={unlinkedHeats}
												getOptionLabel={(option) => option.HeatName}
												value={selectedHeat}
												onChange={handleChange}
												inputValue={searchText}
												onInputChange={handleInputChange}
												renderOption={(option) => (
													<div
														style={{ display: "flex", alignItems: "center" }}
													>
														<span>{option.HeatName}</span>
														{option.IsLinked === 1 && (
															<span
																style={{ color: "red", marginLeft: "10px" }}
															>
																(linked)
															</span>
														)}
													</div>
												)}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder="Search Heat"
														variant="outlined"
														fullWidth
														onKeyDown={handleKeyDown}
														InputProps={{
															...params.InputProps,
															style: {
																height: "100%",
																minWidth: "400px",
															},
														}}
													/>
												)}
											/>
										</Grid>
									</Grid>
								</MesfModal.Content>

								<MesfModal.Actions style={{ padding: "20px " }}>
									<Grid container spacing={2} justifyContent="flex-end">
										<Grid item md={3} xs={12}>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={() => onHide(true)}
												size="large"
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={3} xs={12}>
											<ButtonWithLoading
												fullWidth
												isLoading={isSubmitLoading}
												variant="contained"
												color="primary"
												onClick={onSubmit}
												size="large"
											>
												Save
											</ButtonWithLoading>
										</Grid>
									</Grid>
								</MesfModal.Actions>
							</form>
						)}
					</MesfModal>
				</Grid>
			</Grid>

			<MesfModal
				open={showWarningModal}
				handleClose={() => setShowWarningModal(false)}
				title="Warning"
				id="warningModal"
			>
				<MesfModal.Content>
					<p>
						This heat is already linked in another process. Do you want to
						proceed?
					</p>
				</MesfModal.Content>
				<MesfModal.Actions>
					<Button
						fullWidth
						variant="contained"
						color="default"
						onClick={() => setShowWarningModal(false)}
					>
						Cancel
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={async () => {
							setShowWarningModal(false);
							await onSubmit(); // Proceed with the linking process
						}}
					>
						Proceed
					</Button>
				</MesfModal.Actions>
			</MesfModal>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
