export interface CatalogMaterial {
	MaterialId: number | null;
	L1MaterialId: number | null;
	MaterialName: string;
	IsAlloy: boolean;
	UseInEAF: boolean;
	UseInLF: boolean;
	Enabled: boolean;
}
export const CATALOG_MATERIAL_INITIAL_VALUES: CatalogMaterial = {
	MaterialId: null,
	L1MaterialId: null,
	MaterialName: "",
	IsAlloy: false,
	UseInEAF: false,
	UseInLF: false,
	Enabled: true,
};
