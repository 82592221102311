import { createSlice } from "@reduxjs/toolkit";
import { Entry } from "../models/Entry";
import { ENTRY_INITIAL_VALUES } from "../models/EntryInitialValue";
import { FilterParameters } from "../models/FilterParameters";
import { FILTER_INITIAL_VALUES } from "../models/FilterParametersInitialValue";

const LogbookInitialState: {
	EntriesList: Entry[];

	EntrySelectedInTable: Entry;
	FilterParameters: FilterParameters;

	EntryIDToDelete: number | null;
	EntryTitleToDelete: string | null;

	loadingTable: boolean;
	isEditing: boolean;
	exportExcel: boolean;
	CrewList: string[];
} = {
	EntriesList: [],
	EntrySelectedInTable: ENTRY_INITIAL_VALUES,
	FilterParameters: FILTER_INITIAL_VALUES,

	EntryIDToDelete: null,
	EntryTitleToDelete: null,

	loadingTable: false,
	isEditing: true,
	exportExcel: false,
	CrewList: [],
};

export const LogbookReducer = createSlice({
	name: "__",
	initialState: LogbookInitialState,
	reducers: {
		setEntriesList(state, { payload }) {
			state.EntriesList = payload;
		},
		setEntrySelectedInTable(state, { payload }) {
			state.EntrySelectedInTable = payload;
		},
		setEntryIDSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.EntryID = payload;
		},
		setShiftIdSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.ShiftId = payload;
		},
		setShiftSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.Shift = payload;
		},
		setCrewSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.Crew = payload;
		},
		setTitleSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.Title = payload;
		},
		setContentSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.Content = payload;
		},
		setCreateTimestampSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.CreateTimestamp = payload;
		},
		setUpdateTimestampSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.UpdateTimestamp = payload;
		},
		setLogEntryTypeCodeSelectedInTable(state, { payload }) {
			state.EntrySelectedInTable.LogEntryTypeCode = payload;
		},
		setLogEntryUpdatedBy(state, { payload }) {
			state.EntrySelectedInTable.UpdatedBy = payload;
		},
		// FILTER PARAMETERS

		setFilter(state, { payload }) {
			state.FilterParameters = payload;
		},

		setFilterStart(state, { payload }) {
			state.FilterParameters.Start = payload;
		},
		setFilterEnd(state, { payload }) {
			state.FilterParameters.End = payload;
		},
		setFilterSearchText(state, { payload }) {
			state.FilterParameters.SearchText = payload;
		},
		setFilterCrewt(state, { payload }) {
			state.FilterParameters.Crew = payload;
		},
		setEntryIdToDelete(state, { payload }) {
			state.EntryIDToDelete = payload;
		},
		setEntryTitleToDelete(state, { payload }) {
			state.EntryTitleToDelete = payload;
		},
		// ----
		setLoadingTable(state, { payload }) {
			state.loadingTable = payload;
		},
		setIsEditing(state, { payload }) {
			state.isEditing = payload;
		},
		setExportExcel(state, { payload }) {
			state.exportExcel = payload;
		},
		setCrewList(state, { payload }) {
			state.CrewList = payload;
		},
	},
});
