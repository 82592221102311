import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { getCatalogSampleTypes } from "../../repositories/CatalogSampleTypeRepository";
import { useCatalogSampleTypeContext } from "./CatalogSampleTypeContext";
import { TableSampleType } from "./components/TableSampleType";

type Props = {};
export const CatalogSampleTypePage = (props: Props) => {
	const { state, actions } = useCatalogSampleTypeContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");

	const getCatalogSampleTypesFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogSampleTypes();
		if (resp.ok) {
			actions.setSampleTypes(resp.data.tables[0].rows);
			setIsLoading(false);
		} else {
			setError(resp.message);
		}
	};

	useEffect(() => {
		getCatalogSampleTypesFromAPI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (state.sampleTypes) {
		return (
			<>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0 }}>
						<Paper
							elevation={1}
							style={{
								width: "100%",
								padding: "10px 0 20px",
								borderRadius: "0.75rem",
								height: "auto",
							}}
						>
							<TableSampleType
								isLoading={isLoading}
								getCatalogSampleTypesFromAPI={getCatalogSampleTypesFromAPI}
							/>
						</Paper>
					</Grid>
				</Grid>
				<ErrorModal error={error} onHide={() => setError("")} />
			</>
		);
	}
	return <LazyLoading />;
};
