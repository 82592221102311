import { AssetAPI } from "./AssetAPI";

export const DATA_ASSET_INITIAL_VALUES: AssetAPI = {
	AssetId: null,
	ParentAssetId: 1,
	AssetName: "",
	IsAsset: false,
	CanBeDefaultAsset: false,
	ParentAssetName: "",
	IsBranch: null,
};
