export interface CatalogMold {
	MoldId: number | null;
	MoldName: string;
	MoldSizeId: number | null;
	Enabled: boolean;
}
export const CATALOG_MOLD_INITIAL_VALUES: CatalogMold = {
	MoldId: null,
	MoldName: "",
	MoldSizeId: null,
	Enabled: true,
};
