import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
	contextMenu: {
		backgroundColor: "#fff",
		borderRadius: "2px",
		padding: "5px 0",
		width: "180px",
		height: "auto",
		margin: "0",
		position: "fixed",
		listStyle: "none",
		boxShadow: "0 0 20px 0 #ccc",
		"& .MuiMenuItem-root": {
			fontSize: "14px",
		},
		"& .MuiListItemIcon-root": {
			minWidth: "25px",
			padding: "0",
		},
		"& .MuiSvgIcon-root": {
			fontSize: "16px",
		},
	},
}));
