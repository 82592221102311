import { LazyLoading } from "@/controls/LazyLoading";
import { DelayCategorySelector } from "@/controls/selectors/DelayCategorySelector";
import { DelayCodePicker } from "@/controls/selectors/DelayCodePicker";
import { AssetSelectorV2 } from "@/controls/selectors/asset.selector.v2";
import { getDelayById } from "@/pages/caster/repositories/ProductionRepository";
import { DelayByShift } from "@/pages/delays/delays-manager/models/DelayByShift";
import {
	justifyDelay,
	upsertDelay,
} from "@/pages/delays/delays-manager/repositories/DelaysManagerRepository";
import { ErrorModal, MesfModal, TimeService } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	TextField,
	makeStyles,
} from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers";
import { get } from "lodash-es";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

const useStyles = makeStyles(() => ({
	dateTimePicker: {},
	"@global": {
		"*::-webkit-scrollbar": {
			width: "10px",
			background: "#fff",
		},
		"*::-webkit-scrollbar-thumb": {
			backgroundColor: "	#C8C8C8",
		},
		"*::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"*::-webkit-scrollbar:horizontal": {
			height: "10px",
		},
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	commentsInput: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
		"& .MuiOutlinedInput-input": {
			padding: "15px 0px",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	refreshData: Function;
	ifJustified: boolean;
	delayId: number | null;
};

export const UpsertJustifyDelay = ({
	show,
	onHide,
	refreshData,
	delayId,
	ifJustified,
}: Props) => {
	const classes = useStyles();

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isNew, setIsNew] = useState(false);
	const [title, setTitle] = useState("");

	const {
		control,
		handleSubmit,
		getValues,
		reset,
		watch,
		formState: { errors },
	} = useForm<DelayByShift>({
		defaultValues: {},
	});

	const comments = watch("Comments");
	const start = watch("Start");
	const end = watch("End");

	const fetchDelayById = async () => {
		setIsLoading(true);
		try {
			const response = await getDelayById(delayId!);
			if (response && response.ok) {
				let delay = get(response, "data.tables[0].rows[0]", null);
				if (delay) {
					setIsNew(false);
					setTitle(ifJustified ? "JUSTIFY DELAY" : "EDIT DELAY");
					reset({
						DelayId: delay.DelayId,
						AssetId: delay.AssetId,
						DelayCodeId: delay.DelayCodeId,
						Start: delay.Start ? moment.utc(delay.Start).toDate() : null,
						End: delay.End ? moment.utc(delay.End).toDate() : null,
						DelayCategoryId: delay.DelayCategoryId,
						IsManual: delay.IsManual,
						Comments: delay.Comments,
					});
				} else {
					setError("Delay not found.");
				}
			} else {
				setError("Failed to fetch delay details.");
			}
		} catch (error) {
			setError("An error occurred while fetching the delay.");
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmit: SubmitHandler<DelayByShift> = async (data: DelayByShift) => {
		setIsSubmitLoading(true);
		if (ifJustified) {
			const response = await justifyDelay(
				data.DelayId!,
				data.DelayCodeId ?? null,
				data.DelayCategoryId ?? null,
				data.Comments ?? null,
			);
			if (response.ok) {
				await refreshData();
			} else {
				console.log(response.message);
			}
		} else {
			const response = await upsertDelay(
				data.DelayId === 0 ? null : data.DelayId,
				data.AssetId,
				data.Start,
				data.End,
				data.DelayCodeId,
				data.DelayCategoryId,
				data.Comments,
			);
			if (response.ok) {
				await refreshData();
			} else {
				console.log(response.message);
			}
		}
		setIsSubmitLoading(false);
		onHide(true);
	};

	useEffect(() => {
		if (show && delayId) {
			fetchDelayById().then(() => {});
		}
	}, [show, delayId]);

	const duration = useMemo(() => {
		if (start && end) {
			const duration = moment.duration(moment(end).diff(moment(start)));
			const minutes = Math.floor(duration.asMinutes());
			const seconds = duration.seconds();
			return `${minutes}m ${seconds}s`;
		} else {
			return "";
		}
	}, [start, end]);

	if (isLoading)
		return (
			<CircularProgress
				style={{ position: "absolute", top: "50%", left: "50%" }}
			/>
		);
	return (
		<>
			<Grid container>
				<Grid item className={classes.dateTimePicker}>
					<MesfModal
						maxWidth="md"
						open={show}
						handleClose={() => {
							onHide(true);
						}}
						id="upsertDelay"
						title={title}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content style={{ padding: "15px 30px" }}>
								{isLoading && (
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{ minHeight: "300px" }}
									>
										<LazyLoading />
									</Grid>
								)}
								{!isLoading && (
									<>
										<Grid
											container
											spacing={1}
											style={{ alignItems: "center" }}
										>
											<Grid item xs={4} md={4}>
												<Controller
													name="Start"
													control={control}
													rules={{
														required: "Start date is required",
														validate: {
															notAfterEnd: (value) => {
																const endDate = watch("End");
																if (value && endDate && value > endDate) {
																	return "Start date cannot be after End date.";
																}
																return true;
															},
															notInFuture: (value) => {
																if (value && value > new Date()) {
																	return "Start date cannot be in the future.";
																}
																return true;
															},
														},
													}}
													render={({ field }) => (
														<DateTimePicker
															disabled={ifJustified}
															label="Delay Start"
															timezone={TimeService.getInstance().getServerTimeZone()}
															format="MM/DD/YYYY HH:mm:ss"
															ampm={false}
															value={moment(field.value)}
															onChange={field.onChange}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	variant: "outlined",
																	size: "small",
																	error: !!errors.Start,
																},
															}}
														/>
													)}
												/>
												{errors.Start && (
													<p style={{ color: "red" }}>{errors.Start.message}</p>
												)}
											</Grid>
											<Grid item xs={4} md={4}>
												<Controller
													name="End"
													control={control}
													rules={{
														validate: {
															notBeforeStart: (value) => {
																const startDate = watch("Start");
																if (value && startDate && value < startDate) {
																	return "End date cannot be before Start date.";
																}
																return true;
															},
															notInFuture: (value) => {
																if (value && value > new Date()) {
																	return "End date cannot be in the future.";
																}
																return true;
															},
															notSameAsStart: (value) => {
																const startDate = watch("Start");
																if (
																	value &&
																	startDate &&
																	moment(value).isSame(moment(startDate))
																) {
																	return "End date cannot be the same as Start date.";
																}
																return true;
															},
														},
													}}
													render={({ field }) => (
														<DateTimePicker
															disabled={ifJustified}
															label="Delay End"
															format="MM/DD/YYYY HH:mm:ss"
															ampm={false}
															timezone={TimeService.getInstance().getServerTimeZone()}
															value={moment(field.value)}
															onChange={field.onChange}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	variant: "outlined",
																	size: "small",
																	error: !!errors.End,
																},
															}}
														/>
													)}
												/>
												{errors.End && (
													<p style={{ color: "red" }}>{errors.End.message}</p>
												)}
											</Grid>
											<Grid item xs={4} md={4} style={{ marginBottom: "3px" }}>
												<TextField
													label="Duration (min)"
													disabled
													variant="outlined"
													fullWidth
													value={duration}
													margin="dense"
													autoComplete="off"
												/>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											style={{ alignItems: "center", marginTop: "12px" }}
										>
											<Grid item xs={4} md={4}>
												<Controller
													name="AssetId"
													control={control}
													rules={{ required: "Asset is required" }}
													render={({ field }) => (
														<AssetSelectorV2
															value={field.value ?? 0}
															onChange={field.onChange}
															error={errors.AssetId?.message}
															disabled={ifJustified}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={4} md={4} style={{ marginBottom: "3px" }}>
												<Controller
													name="DelayCodeId"
													control={control}
													render={({ field }) => (
														<DelayCodePicker
															assetId={getValues("AssetId")}
															value={field.value}
															onChange={field.onChange}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={4} md={4}>
												<Controller
													name="DelayCategoryId"
													control={control}
													render={({ field }) => (
														<DelayCategorySelector
															value={field.value}
															onChange={field.onChange}
															assetId={getValues("AssetId")}
															error={errors.DelayCategoryId?.message || ""}
														/>
													)}
												/>
											</Grid>
										</Grid>
										<Grid item md={12} xs={12} style={{ marginTop: "12px" }}>
											<Controller
												name="Comments"
												control={control}
												render={({ field: { value, onChange } }) => (
													<TextField
														className={classes.commentsInput}
														style={{ margin: "8px 0" }}
														size="medium"
														maxRows={5}
														label="Comments"
														multiline
														variant="outlined"
														fullWidth
														autoComplete="off"
														value={value || ""}
														onChange={(e) => {
															if (e.target.value.length <= 200) onChange(e);
														}}
													/>
												)}
											/>
											<Grid container style={{ marginBottom: 18 }}>
												<Grid item>
													{(comments?.length as number) >= 1 &&
														(comments?.length as number) !== 200 && (
															<span
																className={classes.infoLabel}
																style={{ marginLeft: 5 }}
															>{`${comments?.length}/200 max.`}</span>
														)}
													{(comments?.length as number) == 200 && (
														<span
															className={classes.infoLabel}
															style={{ marginLeft: 5 }}
														>
															Max. 200
														</span>
													)}
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={6} md={6}>
											<Controller
												name="IsManual"
												control={control}
												render={({ field }) => (
													<FormControlLabel
														control={
															<Checkbox
																color="primary"
																checked={isNew || field.value}
																disabled={true}
															/>
														}
														label="Manual Delay"
													/>
												)}
											/>
										</Grid>
									</>
								)}
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(true)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
				</Grid>
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
