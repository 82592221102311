import { MesfModal } from "@dexteel/mesf-core";
import { Button, Grid, Paper, Typography, makeStyles } from "@material-ui/core";

interface Props {
	comments: string | null;
	onHide: () => void;
	open: boolean;
}

const useStyles = makeStyles((theme) => ({
	commentBox: {
		border: "1px solid #ced4da",
		borderRadius: 4,
		padding: theme.spacing(2),
		backgroundColor: "#fff",
		minHeight: 100,
		overflowY: "auto",
		pointerEvents: "none",
	},
}));
export const CommentsModal = ({ comments, onHide, open }: Props) => {
	const classes = useStyles();
	return (
		<>
			<MesfModal
				open={open}
				handleClose={onHide}
				id="comments-modal"
				title="Comment"
				maxWidth="sm"
			>
				<MesfModal.Content>
					<Grid container>
						<Grid item xs={12}>
							<div className={classes.commentBox}>
								<Typography>{comments}</Typography>
							</div>
						</Grid>
					</Grid>
				</MesfModal.Content>
				<MesfModal.Actions>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item md={3} xs={3} style={{ margin: 0 }}>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={onHide}
								size="small"
							>
								Ok
							</Button>
						</Grid>
					</Grid>
				</MesfModal.Actions>
			</MesfModal>
		</>
	);
};
