import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { isNil } from "lodash-es";

export const ShortDateTimeFormat = (
	datetime: Date | string | undefined | null,
) => {
	return dxtToLocalServerTime(datetime, "MM/dd/yy HH:mm");
};
export const getTimeFormatFromUTC = (
	date: Date | string | null,
	format = "HH:mm",
) => {
	if (isNil(date)) return "";

	if (typeof date === "string") {
		if (date.includes("Z")) {
			return dxtToLocalServerTime(`${date}`, "HH:mm");
		} else {
			return dxtToLocalServerTime(`${date}Z`, "HH:mm");
		}
	}
	return dxtToLocalServerTime(`${date}Z`, "HH:mm");
};
