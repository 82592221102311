import { Grid, TextField } from "@material-ui/core";
import React from "react";

interface LengthInputProps {
	feet: number;
	inches: number;
	onChange: (feet: number, inches: number) => void;
	error: boolean;
	helperText?: string;
	className?: string;
}

export const LengthInput: React.FC<LengthInputProps> = ({
	feet,
	inches,
	onChange,
	error,
	helperText,
	className,
}) => {
	const handleFeetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value === "") {
			onChange(0, inches);
			setTimeout(() => e.target.select(), 0);
			return;
		}
		const newFeet = parseInt(e.target.value, 10);
		if (!isNaN(newFeet) && newFeet >= 0) {
			onChange(newFeet, inches);
		}
	};

	const handleInchesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value === "") {
			onChange(feet, 0);
			setTimeout(() => e.target.select(), 0);
			return;
		}
		const newInches = parseInt(e.target.value, 10);
		if (!isNaN(newInches) && newInches >= 0 && newInches < 12) {
			onChange(feet, newInches);
		}
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={6}>
				<TextField
					onFocus={(e) => e.target.select()}
					className={className}
					label="Ft"
					type="tel"
					value={feet}
					onChange={handleFeetChange}
					error={error}
					helperText={error ? helperText : ""}
					fullWidth
					variant="outlined"
					size="small"
					inputProps={{ min: 0 }}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					onFocus={(e) => e.target.select()}
					className={className}
					label="In"
					type="tel"
					value={inches}
					onChange={handleInchesChange}
					error={error}
					helperText={error ? helperText : ""}
					fullWidth
					variant="outlined"
					size="small"
					inputProps={{ min: 0, max: 11 }}
				/>
			</Grid>
		</Grid>
	);
};
