import React from "react";
import { useStyles } from "./styles";

type Props = {
	columns: string[];
	headers: string[];
	widths?: string[];
	data: any[];
};
export const SimpleTable = ({ columns, headers, widths = [], data }: Props) => {
	const classes = useStyles();
	return (
		<table cellPadding={0} cellSpacing={0} className={classes.tableContainer}>
			<thead>
				<tr>
					{headers.map((col, index) => (
						<th
							key={index}
							className={classes.simpleCellHeader}
							style={{ width: widths[index] ?? "100%" }}
						>
							{col}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, index) => (
					<tr key={index}>
						{columns.map((col, index) => (
							<td key={index}>
								<input
									type="text"
									readOnly
									className={"form-control" + " " + classes.simpleCellText}
									value={row[col] || ""}
								></input>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};
