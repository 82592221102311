import React, { Component, MouseEventHandler } from "react";
type DefaultPanelProps = {
	title: string | "";
	description?: string | "";
	children?: React.ReactNode;
	paddingWrapper?: string;
};

const DefaultPanel = (props: DefaultPanelProps) => {
	let styleWrapper: any = {};
	if (props.paddingWrapper !== undefined)
		styleWrapper = { ...styleWrapper, padding: props.paddingWrapper };
	return (
		<div className="content-wrapper" style={styleWrapper}>
			<div className="row">
				<div className="col-lg-12">
					{/* <Alert show={showPromptError} variant="danger" dismissible={true} onClose={onDismissError}>
                        <strong>There was an error</strong>
                        <p>{error === undefined ? 'There was an error' :
                            (error.internalError.message === undefined ? error.internalError : error.internalError.message)}
                        </p>
                    </Alert>
                    <Alert show={showPromptSuccess} variant="success"
                       onClose={onDismissSuccess}>
                        <p>{successMessage || 'Operation was success'}</p>
                    </Alert> */}
				</div>
			</div>
			<div className="row">
				<div className="col-lg-9 ">
					<h3 style={{ userSelect: "none" }}>
						{props.title}
						<small>{props.description}</small>
					</h3>
				</div>
				<div className="col-lg-3"></div>
			</div>
			{props.children}
		</div>
	);
};
type ExtraButton = {
	name: string;
	onClick: MouseEventHandler;
};
type FilterPanelProps = {
	onClickExport?: Function;
	onClickReset: Function;
	OnClickGo: Function;
	showButtons: boolean;
	showExport: boolean;
	extraButtons?: ExtraButton[];
	children?: React.ReactNode;
};

class FilterPanel extends Component<FilterPanelProps> {
	public onClickGo: MouseEventHandler;
	public onClickReset: MouseEventHandler;
	public onClickExport: MouseEventHandler;

	constructor(props: FilterPanelProps) {
		super(props);
		this.state = {};
		this.onClickGo = this.GetFilterValues.bind(this);
		this.onClickReset = this.ResetFilterValues.bind(this);
		this.onClickExport = this.ExportData.bind(this);
	}

	ExportData() {
		if (this.props.onClickExport !== undefined) this.props.onClickExport();
	}

	ResetFilterValues() {
		if (this.props.onClickReset !== undefined) this.props.onClickReset();
	}

	GetFilterValues() {
		if (this.props.OnClickGo !== undefined) this.props.OnClickGo();
	}

	render() {
		const buttonList =
			this.props.extraButtons === undefined ? [] : this.props.extraButtons;
		const extraButtons = buttonList.map((button, index) => (
			<button
				type="button"
				className="btn btn-primary btn-sm mr-2"
				onClick={button.onClick}
				key={index}
			>
				{button.name}
			</button>
		));
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							{this.props.children}

							<div className="me-auto">
								{this.props.showButtons && (
									<React.Fragment>
										<div className="pt-2">
											<button
												type="button"
												className="btn btn-primary  btn mr-2"
												onClick={this.onClickGo}
											>
												Go
											</button>
											<button
												type="button"
												className="btn btn-danger btn-sm mr-2"
												onClick={this.onClickReset}
											>
												Reset
											</button>
										</div>
									</React.Fragment>
								)}
								{extraButtons}
								{this.props.showExport && (
									<div className="pt-2">
										<button
											type="button"
											className="btn btn-success"
											onClick={this.onClickExport}
										>
											<i className="fa fa-download"></i>Excel
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
class LongFilterPanel extends Component<FilterPanelProps> {
	public onClickGo: MouseEventHandler;
	public onClickReset: MouseEventHandler;
	public onClickExport: MouseEventHandler;

	constructor(props: any) {
		super(props);
		this.state = {};
		this.onClickGo = this.GetFilterValues.bind(this);
		this.onClickReset = this.ResetFilterValues.bind(this);
		this.onClickExport = this.ExportData.bind(this);
	}
	ExportData() {
		if (this.props.onClickExport !== undefined) this.props.onClickExport();
	}
	ResetFilterValues() {
		if (this.props.onClickReset !== undefined) this.props.onClickReset();
	}
	GetFilterValues() {
		if (this.props.OnClickGo !== undefined) this.props.OnClickGo();
	}

	render() {
		const buttonList =
			this.props.extraButtons === undefined ? [] : this.props.extraButtons;
		const extraButtons = buttonList.map((button, index) => (
			<button
				type="button"
				className="btn btn-primary btn-sm mr-2"
				onClick={button.onClick}
				key={index}
			>
				{button.name}
			</button>
		));
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="row mb-3 mt-3 ">
							{this.props.children}
							<div className="col-md-2">
								{this.props.showButtons && (
									<div className="container-fluid">
										<div className="col-md-12">
											<div className="pt-2">
												<button
													type="button"
													className="btn btn-primary  btn mr-2"
													onClick={this.onClickGo}
												>
													Go
												</button>
												<button
													type="button"
													className="btn btn-danger btn-sm mr-2"
													onClick={this.onClickReset}
												>
													Reset
												</button>
												{this.props.showExport && (
													<button
														type="button"
														className="btn btn-success"
														onClick={this.onClickExport}
													>
														<i className="fa fa-download"></i>Excel
													</button>
												)}
												{extraButtons}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export { DefaultPanel, FilterPanel, LongFilterPanel };
