import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogSampleTypeReducer } from "./CatalogSampleTypeReducer";

const CatalogSampleTypeContext = createContext({
	state: CatalogSampleTypeReducer.getInitialState(),
	actions: CatalogSampleTypeReducer.actions,
});

export const useCatalogSampleTypeContext = () =>
	useContext(CatalogSampleTypeContext);

export const CatalogSampleTypeProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogSampleTypeReducer.getInitialState(),
		reducers: CatalogSampleTypeReducer.caseReducers,
	});
	return (
		<CatalogSampleTypeContext.Provider value={{ state, actions }}>
			{children}
		</CatalogSampleTypeContext.Provider>
	);
};
