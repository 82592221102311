import { ProcessLine } from "@/controls/ganttAsset/types/public-types";
import {
	ShiftInfo,
	dxtToLocalServerTime,
	getMomentTz,
} from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import { Asset, Task } from "../../../controls/ganttAsset";
import { Process, ProcessEvent } from "../../caster/models/ProcessGanttChart";
import { useCasterDashboardContext } from "../CasterDashboardContext";
import {
	getColorForAsset,
	getColorForHeat,
	heatNameToColor,
} from "../utils/get-color-helpers";
const moment = getMomentTz();

const ASSET_IDS = {
	EAF1: 11,
	EAF2: 12,
	CASTER: 13,
};

const DEFAULT_COLOR = "#e9ecef";

interface GanttData {
	tables: [{ rows: Process[] }, { rows: ProcessEvent[] }];
}
export const useGanttUpdater = (shiftInfo: ShiftInfo | null) => {
	const [assets, setAssets] = useState<Asset[]>([]);
	const { actions } = useCasterDashboardContext();
	const CurrentStart = get(shiftInfo, "CurrentStart", null);

	// Assets are fixed for this page
	const fixedAssets: Asset[] = useMemo(
		() => [
			{
				id: ASSET_IDS.EAF1.toString(),
				name: "EAF 1",
				processes: [],
				color: getColorForAsset("EAF 1"),
			},
			{
				id: ASSET_IDS.EAF2.toString(),
				name: "EAF 2",
				processes: [],
				color: getColorForAsset("EAF 2"),
			},
			{
				id: ASSET_IDS.CASTER.toString(),
				name: "Caster",
				processes: [],
				color: getColorForAsset("CASTER"),
			},
		],
		[],
	);

	//Processes
	const createTask = (curr: Process, color: string): Task => ({
		id: `${curr.AssetId} - ${curr.HeatName}`,
		start: curr.Start,
		end: curr.End,
		processStart: curr.ProcessStart,
		type: "task",
		progress: 100,
		name: curr.HeatName ?? "unlinked",
		processLines: [],
		source: curr,
		styles: {
			backgroundColor: color,
			progressColor: color,
			borderColor: "black",
		},
		textLeftBottom: dxtToLocalServerTime(curr.Start, "HH:mm"),
		textRightBottom: dxtToLocalServerTime(curr.End, "HH:mm"),
		textLeftTop: curr.LadleName ?? "",
		textRightTop: curr.GradeName ?? "",
	});

	//Process Lines

	const formatDelay = (delayString: string) => {
		const [minutes, seconds] = delayString.split("m ");
		return `${minutes}min ${seconds}`;
	};

	//Process Lines
	const createProcessLine = (
		event: ProcessEvent,
		strandCode?: string,
	): ProcessLine => ({
		id: strandCode
			? `${event.AssetId}-${strandCode}`
			: event.AssetId.toString(),
		DelayId: event.DelayId,
		name: "",
		eventCode: event.EventCode,
		DelayCodeName: event.DelayCodeName,
		start: event.Start,
		end: event.End,
		type: "taskLine" as const,
		comments: (
			<>
				{event.EventCode === "H" && (
					<p>
						<strong>Energy Cons.: {event.Comments}</strong>
					</p>
				)}
				{event.EventCode === "D" && (
					<>
						<p>
							<strong>Delay: {formatDelay(event.Comments)}</strong>
						</p>
						<p>Delay Code: {event.DelayCodeName || "(Not specified)"}</p>
					</>
				)}
				{event.EventCode === "C" && (
					<p>
						<strong>Speed: {event.Comments} ipm</strong>
					</p>
				)}
				{event.EventCode !== "H" &&
					event.EventCode !== "D" &&
					event.EventCode !== "C" && (
						<p>
							<strong>
								Speed: {event.Comments.replace(/[^\d.]/g, "")} ipm
							</strong>
						</p>
					)}
				<p>
					{`From: ${dxtToLocalServerTime(event.Start, "HH:mm:ss")} to: ${dxtToLocalServerTime(event.End, "HH:mm:ss")}`}
				</p>
			</>
		),
		styles: {
			backgroundColor: event.Color || "blue",
			progressColor: event.Color || "blue",
			borderWidth: 1,
			borderColor: "#707175",
		},
	});

	//Process line without data
	const createDefaultProcessLine = (assetId: number, code: string) => ({
		id: `${assetId}-${code}-default`,
		name: "",
		eventCode: code,
		start: CurrentStart || new Date(),
		end: moment(CurrentStart).add(1, "second").toDate(),
		type: "taskLine" as const,
		comments: "",
		styles: {
			backgroundColor: "rgba(0, 0, 0, 0)",
			progressColor: "rgba(0, 0, 0, 0)",
			borderWidth: 1,
			borderColor: "#707175",
		},
	});

	//CASTER
	const handleCasterProcesses = (task: Task, processEvents: ProcessEvent[]) => {
		["1", "2", "3", "D"].forEach((strandCode) => {
			const events = processEvents.filter((e) => e.EventCode === strandCode);
			if (events.length > 0) {
				events.forEach((event) =>
					task.processLines.push(createProcessLine(event, strandCode)),
				);
			} else {
				task.processLines.push(
					createDefaultProcessLine(ASSET_IDS.CASTER, strandCode),
				);
			}
		});
	};

	//EAF
	const handleEAFProcesses = (task: Task, processEvents: ProcessEvent[]) => {
		["Heatings", "Delays"].forEach((line) => {
			const events = processEvents.filter((e) => e.EventCode === line[0]);
			if (events.length > 0) {
				events.forEach((event) =>
					task.processLines.push(createProcessLine(event)),
				);
			} else {
				task.processLines.push(
					createDefaultProcessLine(Number(task.id.split("-")[0]), line[0]),
				);
			}
		});
	};

	//
	const updateGantt = useCallback(
		(data: GanttData) => {
			const processes = data.tables[0].rows.map((row: Process) => ({
				...row,
				ProcessStart: moment.utc(row.ProcessStart).toDate(),
				Start: moment.utc(row.Start).toDate(),
				End: moment.utc(row.End).toDate(),
			})) as Process[];
			const processesEvents = data.tables[1].rows.map((row: ProcessEvent) => ({
				...row,
				Start: moment.utc(row.Start).toDate(),
				End: moment.utc(row.End).toDate(),
			})) as ProcessEvent[];

			actions.setGanttProcesses(processes);
			actions.setGanttProcessEvents(processesEvents);
			const updatedAssets: Asset[] = fixedAssets.map((asset) => ({
				...asset,
				processes: [],
			}));

			processes.forEach((curr: Process) => {
				const assetIndex = updatedAssets.findIndex(
					(a) => Number(a.id) === curr.AssetId,
				);
				if (assetIndex === -1) return;

				const color = curr.HeatName
					? heatNameToColor.get(curr.HeatName) || getColorForHeat(curr.HeatName)
					: DEFAULT_COLOR;

				if (curr.HeatName) heatNameToColor.set(curr.HeatName, color);

				const task = createTask(curr, color);
				const processEvents = processesEvents.filter(
					(event) => event.AssetId === curr.AssetId,
				);

				if (curr.AssetId === ASSET_IDS.CASTER) {
					handleCasterProcesses(task, processEvents);
				} else {
					handleEAFProcesses(task, processEvents);
				}

				updatedAssets[assetIndex].processes.push(task);
			});

			setAssets(updatedAssets);
		},
		[shiftInfo],
	);

	return { assets, updateGantt };
};
