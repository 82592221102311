import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import {
	CATALOG_BUCKET_INITIAL_VALUES,
	CatalogBucket,
} from "../../../models/CatalogBucket";
import {
	deleteCatalogBucket,
	getCatalogBucket,
} from "../../../repositories/CatalogBucketRepository";
import { useModalStyles } from "../../../styles/modalStyles";

type Props = {
	BucketId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const DeleteBucket = ({ BucketId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [bucketSelected, setBucketSelected] = useState<any>();

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<CatalogBucket>({
		defaultValues: CATALOG_BUCKET_INITIAL_VALUES,
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit = async () => {
		setIsSubmitLoading(true);
		const deleteBucketResp = await deleteCatalogBucket(BucketId);
		if (deleteBucketResp.ok) {
			onHide(true);
			setOpen(true);
		} else {
			setError(deleteBucketResp.message);
		}
		setIsSubmitLoading(false);
	};

	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const response = await getCatalogBucket(BucketId);
				if (response.ok) {
					if (
						response.data &&
						response.data.tables &&
						response.data.tables[0].rows &&
						response.data.tables[0].rows[0]
					) {
						const bucket = response.data.tables[0].rows[0];
						setValue("BucketId", bucket.BucketId);
						setValue("BucketName", bucket.BucketName);
						setValue("Capacity", bucket.Capacity);
						setValue("IsActive", bucket.IsActive);
						setIsLoading(false);
						setBucketSelected(bucket);
					}
				}
			})();
		}
	}, [show]);

	return (
		<>
			<MesfModal
				title="DELETE BUCKET"
				open={show}
				handleClose={() => onHide(false)}
				id="delete-bucket-modal"
				maxWidth="lg"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						)}
						{!isLoading && (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField
											{...register("BucketName")}
											label="Name"
											variant="outlined"
											fullWidth
											value={bucketSelected?.BucketName}
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											{...register("Capacity")}
											label="Capacity"
											type="number"
											variant="outlined"
											fullWidth
											value={bucketSelected?.Capacity}
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} style={{ marginTop: "20px" }}>
									<p>Are you sure you want to delete this bucket?</p>
								</Grid>
							</>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => onHide(false)}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									startIcon={
										isSubmitLoading ? <CircularProgress size="1rem" /> : <></>
									}
									disabled={isSubmitLoading}
									variant="contained"
									color="secondary"
									type="submit"
								>
									Delete
								</Button>
							</Grid>
						</Grid>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The bucket was deleted successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Deleting Bucket"
			/>
		</>
	);
};
