import { getDelayAreas } from "@/pages/delays/delays-report/repositories/DelaysReportRepository";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";

type Props = {
	value: number | null;
	onChange: (value: number | null) => void;
	disabled?: boolean;
	error?: string;
};
export const AssetSelectorV2 = ({
	value,
	onChange,
	disabled = false,
	error = "",
}: Props) => {
	const [assets, setAssets] = useState<Asset[]>([]);
	const [fetchError, setFetchError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [internalError, setError] = useState<string | null>(null);

	const fetchDelaysArea = async () => {
		setIsLoading(true);
		try {
			const response = await getDelayAreas();
			if (response && response.ok) {
				setAssets(response.data.tables[0].rows);
			} else {
				setError("Failed to fetch assets.");
			}
		} catch (internalError) {
			setError("An error occurred while fetching assets.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDelaysArea();
	}, []);

	return (
		<Autocomplete<Asset>
			id="clear-on-escape"
			clearOnEscape
			options={assets}
			getOptionSelected={(option, val) => option.AssetId === val.AssetId}
			getOptionLabel={(option) => option.AssetName}
			onChange={(val, asset) => {
				onChange(asset?.AssetId ?? null);
			}}
			disabled={disabled}
			renderInput={(params) => (
				<>
					<TextField
						{...params}
						label="Asset"
						variant="outlined"
						error={!!error}
						size="small"
						fullWidth
						helperText={error}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{isLoading ? (
										<CircularProgress />
									) : (
										params.InputProps.endAdornment
									)}
								</>
							),
						}}
					/>
				</>
			)}
			value={assets?.find((asset: Asset) => asset.AssetId === value) ?? null}
		/>
	);
};
