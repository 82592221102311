import { ContextMenuMESFProvider } from "../../../controls/contextMenu/useContextMenuMESF";
import { CatalogCategoriesPage } from "./CatalogCategoriesPage";
import { CategoriesProvider } from "./context/CategoriesContext";
import { ConfigurationAssetProvider } from "./context/ConfigurationAssetContext";

type Props = {};

const CatalogCategories = (props: Props) => {
	return (
		<ConfigurationAssetProvider>
			<CategoriesProvider>
				<CatalogCategoriesPage />
			</CategoriesProvider>
		</ConfigurationAssetProvider>
	);
};

export default CatalogCategories;
