import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { CatalogMoldSize } from "../../../models/catalogs/CatalogMoldSize";
type Props = {
	setMoldSizeId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	setShowCreateModal: Function;
};
export const useMoldSizeOptionsFunctions = ({
	setMoldSizeId,
	setShowEditModal,
	setShowDeleteModal,
	setShowCreateModal,
}: Props) => {
	const getMenuOptions = (data: CatalogMoldSize) => {
		let options: MenuOptionType[] = [];
		options = options.concat([
			{
				name: "New Mold Size",
				key: "new_mold_size",
				onClick: () => {
					setShowCreateModal(true);
				},
				icon: <PlaylistAddIcon />,
			},
		]);
		if (data) {
			options = options.concat([
				{
					name: "Edit Mold Size",
					key: "edit_mold_size",
					onClick: () => {
						setMoldSizeId(data.MoldSizeId);
						setShowEditModal(true);
					},
					icon: <EditIcon />,
				},
				{
					name: "Delete Mold Size",
					key: "delete_mold_size",
					onClick: () => {
						setMoldSizeId(data.MoldSizeId);
						setShowDeleteModal(true);
					},
					icon: <DeleteIcon />,
				},
			]);
		}
		return options;
	};
	return {
		getMenuOptions,
	};
};
