import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { ColDef } from "ag-grid-community";
import { useEffect, useState } from "react";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { getMonthlyReport } from "../repositories/MonthReportRepository";
import { TableMonthReport } from "./components/TableMonthReport";
import { GradeSelector } from "./components/selector/GradeSelector";
import { CreateDataTable } from "./models/CreateDataTable";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-theme-balham .ag-row .ag-cell:nth-of-type(2)": {
			backgroundColor: "#5e9bc021",
		},
		"& .ag-theme-balham .ag-row .ag-cell:nth-of-type(3)": {
			backgroundColor: "#5e9bc021",
		},
		"& .ag-theme-balham .ag-row .ag-cell:nth-of-type(4)": {
			backgroundColor: "#5e9bc021",
		},
		"& .ag-theme-balham .ag-row .ag-cell": {
			borderBottom: "0.5px solid #bdc3c7 !important",
		},
	},
}));
interface Data {
	EAF: string;
	Heats: number;
	ScrapUsage: number;
	CastTons: number;
	CastScrap: number;
	Month: number;
}

type Row = {
	id: number;
	rowDef: string;
	[key: string]: any;
};

export const MonthReportPage = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [gradeID, setGradeID] = useState<number>(0);
	const [error, setError] = useState("");
	const [rows, setRows] = useState<any>();
	const [columnDefs, setColumnDefs] = useState<any>();
	const [defaultColDef, setDefaultColDef] = useState<ColDef<any>>({});

	const refreshData = async () => {
		await getDataFromAPI();
	};

	const getDataFromAPI = async () => {
		setIsLoading(true);
		try {
			const response = await getMonthlyReport(gradeID);

			if (response.ok) {
				const data = response.data.tables[0].rows;
				const { columnDefs, rowsForTable, defaultColDef } =
					CreateDataTable(data);
				setDefaultColDef(defaultColDef);
				setColumnDefs(columnDefs);
				setRows(rowsForTable);
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setError(response.message);
			}
		} catch (e: any) {
			setError(e);
		}
	};

	useEffect(() => {
		getDataFromAPI();
	}, []);

	const classes = useStyles();

	if (!isLoading) {
		return (
			<>
				<LazyBackDropLoading open={isLoading} />
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0, padding: "15px" }}>
						<Grid item md={12} xs={12}>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
							>
								<Typography variant="h5" style={{ fontWeight: 600 }}>
									Month Report
								</Typography>
							</Grid>
						</Grid>

						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							md={10}
							xs={12}
						>
							<GradeSelector
								onChange={(gradeId: number | null) => {
									setGradeID(gradeId || 0);
								}}
								value={gradeID}
								label={true}
							/>
							<Button
								variant="contained"
								color="primary"
								onClick={refreshData}
								style={{ margin: 30 }}
							>
								Apply
							</Button>
						</Grid>
						<Grid
							container
							direction="row"
							className={classes.root}
							justifyContent="center"
							alignItems="center"
							item
							md={12}
							xs={12}
						>
							<TableMonthReport
								rows={rows}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
							></TableMonthReport>
						</Grid>
					</Grid>
				</Grid>
				<ErrorModal error={error} onHide={() => setError("")} />
			</>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<LazyLoading />
		</div>
	);
};
