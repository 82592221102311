import React, { useEffect } from "react";
interface UseScrollOptions {
	wrapperRef: React.RefObject<HTMLElement>;
	scrollX: number;
	setScrollX: React.Dispatch<React.SetStateAction<number>>;
	scrollY: number;
	setScrollY: React.Dispatch<React.SetStateAction<number>>;
	ganttHeight: number;
	ganttFullHeight: number;
	svgWidth: number;
	setIgnoreScrollEvent: React.Dispatch<React.SetStateAction<boolean>>;
}
export const useCustomScroll = ({
	wrapperRef,
	scrollX,
	setScrollX,
	scrollY,
	setScrollY,
	ganttHeight,
	ganttFullHeight,
	svgWidth,
	setIgnoreScrollEvent,
}: UseScrollOptions) => {
	useEffect(() => {
		const handleWheel = (event: WheelEvent) => {
			if (event.shiftKey || event.deltaX) {
				const scrollMove = event.deltaX ? event.deltaX : event.deltaY;
				let newScrollX = scrollX + scrollMove;
				if (newScrollX < 0) {
					newScrollX = 0;
				} else if (newScrollX > svgWidth) {
					newScrollX = svgWidth;
				}
				setScrollX(newScrollX);
				event.preventDefault();
			} else if (ganttHeight) {
				let newScrollY = scrollY + event.deltaY;
				if (newScrollY < 0) {
					newScrollY = 0;
				} else if (newScrollY > ganttFullHeight - ganttHeight) {
					newScrollY = ganttFullHeight - ganttHeight;
				}
				if (newScrollY !== scrollY) {
					setScrollY(newScrollY);
					event.preventDefault();
				}
			}

			setIgnoreScrollEvent(true);
		};

		// subscribe if scroll is necessary
		if (wrapperRef.current) {
			wrapperRef.current.addEventListener("wheel", handleWheel, {
				passive: false,
			});
		}
		return () => {
			if (wrapperRef.current) {
				wrapperRef.current.removeEventListener("wheel", handleWheel);
			}
		};
	}, [wrapperRef.current, scrollY, scrollX, ganttHeight, svgWidth]);

	return { scrollX, ganttHeight, scrollY, ganttFullHeight, svgWidth };
};
