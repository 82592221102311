import React, {} from "react";
import { Badge, Card } from "react-bootstrap";
import { DataInfoCardViewProps } from "../types/public-types";
import styles from "./data-info-card-view.module.css";

export const DataInfoCardView: React.FunctionComponent<
	DataInfoCardViewProps
> = ({
	title,
	values,
	subTitles = [],
	badges = [],
	headerBackgroundColor = "#FF8080",
	borderColor = "#000000",
	headerSpan = false,
}) => {
	const subsContent = subTitles.map((item, index) => {
		return (
			<Card.Header
				style={{
					backgroundColor: headerBackgroundColor,
					width: `${100 / subTitles.length}%`,
				}}
				className={[styles.header, styles.headerItem].join(" ")}
				key={"subTitle_" + index}
			>
				{item}
			</Card.Header>
		);
	});
	const formattedValues = values.length === 0 ? [""] : values;
	const valuesContent = formattedValues.map((item, index) => {
		let text: any;
		if (String(item).trim().length === 0) text = "\u00A0";
		else text = String(item);
		return (
			<Card.Title
				className={styles.bodyValue}
				style={{ width: `${100 / formattedValues.length}%` }}
				key={"title_" + index}
			>
				{text}{" "}
				{badges[index] !== undefined && badges[index] !== "" && (
					<Badge className={styles.bodyBadge}>{badges[index]}</Badge>
				)}
			</Card.Title>
		);
	});
	return (
		<Card
			className="mb-1"
			style={{
				borderColor: borderColor,
			}}
		>
			<Card.Header
				style={{
					backgroundColor: headerBackgroundColor,
					borderColor: borderColor,
					fontSize: "1rem",
				}}
				className={headerSpan ? styles.headerSpan : styles.header}
			>
				{title}
			</Card.Header>
			{subsContent.length > 0 && (
				<div
					style={{
						display: "table",
					}}
				>
					<Card.Header
						className={styles.subHeader}
						style={{
							backgroundColor: headerBackgroundColor,
							borderColor: borderColor,
						}}
					>
						{subsContent}
					</Card.Header>
				</div>
			)}
			<div
				style={{
					display: "table",
					padding: "0.3rem",
				}}
			>
				<Card.Body prefix="custom-card-body" className={styles.body}>
					{valuesContent}
				</Card.Body>
			</div>
		</Card>
	);
};
