import { Grid, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	colorTag: {
		width: "26px",
		height: "26px",
		borderRadius: 4,
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#fff",
		fontWeight: "bold",
	},
});

type HeatStatus = {
	name: string;
	color: string;
	letter: string;
};

const heatStatuses: { [key: string]: HeatStatus } = {
	Scheduled: { name: "Scheduled", color: "#0288D1", letter: "S" },
	Tapped: { name: "Tapped", color: "#FFD600", letter: "T" },
	Melting: { name: "Melting", color: "#FF7043", letter: "M" },
	OpenInCaster: { name: "Open in Caster", color: "#43A047", letter: "O" },
	InCaster: { name: "In Caster", color: "#607D8B", letter: "C" },
	Returned: { name: "Returned", color: "#8D6E63", letter: "R" },
	Finished: { name: "Finished", color: "#3F51B5", letter: "F" },

	EAF: { name: "In EAF", color: "#FF7043", letter: "E" },
	Closed: { name: "Closed but still in Caster", color: "#FF7043", letter: "P" },
	ReadyToCaster: { name: "Ready to Caster", color: "#FF7043", letter: "U" },
};

type HeatStatusTagProps = {
	status: keyof typeof heatStatuses;
};

export const HeatStatusTag = ({ status }: HeatStatusTagProps) => {
	const classes = useStyles();
	const value = heatStatuses[status];

	if (!value) {
		return null;
	}

	return (
		<Grid container direction="row" spacing={1}>
			<Grid item md={2}>
				<Tooltip title={value.name} arrow>
					<div
						className={classes.colorTag}
						style={{ backgroundColor: value.color }}
					>
						{value.letter}
					</div>
				</Tooltip>
			</Grid>
			<Grid item justifyContent="center" md={10}>
				{value.name}
			</Grid>
		</Grid>
	);
};
