import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { MESAgGrid } from "../../../../../controls/ag-grid/components/MESAgGrid";
import { CatalogMechanicalTest } from "../../../models/CatalogMechanicalTest";
import { useGridStyles } from "../../../styles/gridStyles";
import { CreateMechanicalTest } from "./CreateMechanicalTest";
import { EditMechanicalTest } from "./EditMechanicalTest";
import { useTableData } from "./hooks";

type Props = {
	getCatalogMechanicalTestsFromAPI: Function;
	isLoading: boolean;
};
export const TableMechanicalTest = ({
	getCatalogMechanicalTestsFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [id, setId] = useState<number | null>(null);
	const { rows, columns } = useTableData({});
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: CatalogMechanicalTest) => {
			setShowEditModal(!showEditModal);
			setId(data.MechanicatTestId);
		},
	});
	const classes = useGridStyles();
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			style={{ height: "75vh" }}
			className={classes.root}
		>
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Mechanical Tests
				</Typography>
			</Grid>
			<CreateMechanicalTest
				show={showCreateModal}
				onHide={() => setShowCreateModal(false)}
				refreshData={getCatalogMechanicalTestsFromAPI}
			/>
			<EditMechanicalTest
				show={showEditModal}
				onHide={(updated: boolean) => {
					setShowEditModal(false);
					setId(null);
					if (updated) getCatalogMechanicalTestsFromAPI();
				}}
				mechanicalTestId={id}
			/>
			<MESAgGrid
				rowData={rows || []}
				columnDefs={columns}
				columnTypes={columnTypes}
				defaultColDef={columnDefaults}
			/>
			<Grid container justifyContent="flex-end">
				<Grid item md={3} xs={12} className={classes.btnModal}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{ margin: "0px 15px" }}
						onClick={() => setShowCreateModal(!showCreateModal)}
					>
						NEW MECHANICAL TEST
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
