import { getMomentTz } from "@dexteel/mesf-core";
import { Button, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect, useState } from "react";
import { ConfirmationDialogRaw } from "../../../../../controls/modals/ConfirmationDialogRaw";
import { SimpleDialogRaw } from "../../../../../controls/modals/SimpleDialogRaw";
import { isNumeric } from "../../../../../utils/numeric-utils";
import { ProcessData } from "../../../models/ProcessData";
import { useCastersContext } from "../../CastersContext";
import { useReadPLC } from "../../hooks/useReadPLC";
import { useSaveProbe } from "../../hooks/useSaveProbe";
import { useStyles } from "../styles";
import { InputFieldTable } from "../ui/FieldTable";
import { ProbeTimeField } from "./fields/ProbeTimeFIeld";

type props = {
	refreshData: Function;
};

const moment = getMomentTz();

export const TundishTable = ({ refreshData }: props) => {
	const classes = useStyles();
	const { state, actions } = useCastersContext();
	const process = state.processData || ({} as ProcessData);
	const probes = state.probes || [];
	const [showLogModal, setShowLogModal] = useState(false);
	const [deletedProbeId, setDeletedProbeId] = useState<number | null>(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { save, removeProbe } = useSaveProbe();
	const { readProbe, sucess: readSuccess, error: errorSuccess } = useReadPLC();
	const [probeTime, setProbeTime] = useState<Date | null>(null);

	/// Refactoring
	const handleOnFocus = (probeIndex: number, field: string) =>
		actions.setFocusField({ entity: "PROBE", index: probeIndex, field });
	const handleOnManual = (probeIndex: number) =>
		actions.updateProbeData({
			index: probeIndex,
			field: "IsProbeManual",
			value: !probes[probeIndex].IsProbeManual,
		});
	const handleOnChange = (probeIndex: number, field: string, value: any) => {
		if (!isNumeric(value)) return;
		actions.updateProbeData({ index: probeIndex, field, value });
	};
	const handleOnBlur = (probeIndex: number, field: string, value: any) => {
		if (!isNumeric(value)) return;
		actions.clearFocusField();
		save(probeIndex, field, value);
	};
	const handleOnUpdatedProbeTime = async (
		probeId: number,
		probeIndex: number,
		field: string,
		value: Date,
	) => {
		if (probeId !== 0) return;
		if (probes[probeIndex] === undefined) return;
		if (!probes[probeIndex].IsProbeManual) return;

		// Convert everything to UTC
		const date = moment(value).utc();
		const ladleOpen = moment.utc(process.LadleOpen);
		const ladleClose = moment.utc(process.LadleClose);

		// Normalize dates to a common day
		const normalizeDate = (m: moment.Moment) => {
			return m.clone().year(2000).month(0).date(1);
		};

		const normalizedDate = normalizeDate(date);
		const normalizedLadleOpen = normalizeDate(ladleOpen);
		let normalizedLadleClose = normalizeDate(ladleClose);

		// If ladle close is before ladle open, we assume it crosses midnight
		if (normalizedLadleClose.isBefore(normalizedLadleOpen)) {
			normalizedLadleClose.add(1, "day");
		}

		// If the input date is before ladle open, we assume it's for the next day
		if (normalizedDate.isBefore(normalizedLadleOpen)) {
			normalizedDate.add(1, "day");
		}

		const minutesFromOpen = normalizedDate.diff(normalizedLadleOpen, "minutes");
		const minutesToClose = normalizedLadleClose.diff(normalizedDate, "minutes");

		if (minutesFromOpen < 0 || minutesToClose < 0) {
			actions.setErrorMessage(
				"The time must be between the ladle open and close time",
			);
			return;
		}

		actions.updateProbeData({
			index: probeIndex,
			field,
			value: value.toISOString(),
			clear: true,
		});
	};
	const handleOnReadProbePLC = (probeId: number) =>
		readProbe(state.processData?.ProcessId || null, probeId);
	const handleOnAddProbe = () => {
		let dateToAdd = process?.LadleOpen
			? moment.utc(process.LadleOpen).toDate()
			: process.LadleClose
				? moment.utc(process.LadleClose).toDate()
				: new Date();

		if (state.probes === null) return;
		const newprobe = {
			ProbeId: 0,
			Timestamp: dateToAdd,
			Temperature: 0,
			SuperHeat: 0,
			IsProbeManual: true,
			Oxygen: null,
			IsEdited: false,

			IsAdded: false,
		};
		actions.addProbeData(newprobe);
		setProbeTime(dateToAdd);
		actions.setFocusField({ entity: "PROBE", index: 0, field: "Timestamp" });
	};
	const handleOnRemoveProbe = (probeIndex: number) => {
		(async () => {
			if (probes[probeIndex] === undefined) return;
			if (probes[probeIndex].ProbeId === 0) {
				actions.deleteProbeData(probes[probeIndex].ProbeId);
				return;
			}
			setDeletedProbeId(probes[probeIndex].ProbeId);
			setShowDeleteModal(true);
		})();
	};
	const handleOnDeleteProbeByIndex = async (value?: string) => {
		setShowDeleteModal(false);
		if (value !== "OK") return;
		if (deletedProbeId === null) return;

		actions.deleteProbeData(deletedProbeId);
		await removeProbe(deletedProbeId);
	};
	const handleOnChangeProbeTime = (probeId: number, value: Date) => {
		if (probeId !== 0) return;
		setProbeTime(value);
	};
	useEffect(() => {
		if (readSuccess) {
			actions.setNotificationMessage("PLC was read successfully");
			refreshData();
		}
		if (errorSuccess) {
			actions.setErrorMessage(errorSuccess);
		}
	}, [readSuccess, errorSuccess]);
	return (
		<>
			<SimpleDialogRaw
				title="Log history for Tundish values"
				message={""}
				open={showLogModal}
				onClose={() => setShowLogModal(false)}
			/>
			<ConfirmationDialogRaw
				title="Confirmation"
				message={"The probe will be deleted.  Do you want to continue?"}
				open={showDeleteModal}
				onClose={handleOnDeleteProbeByIndex}
			/>
			<div>
				<table cellPadding={0} cellSpacing={0} style={{ tableLayout: "fixed" }}>
					<thead>
						<tr>
							<th style={{ width: "150px" }}></th>
							<th style={{ width: "110px" }}></th>
							<th style={{ width: "110px" }}></th>
							<th style={{ width: "110px" }}></th>
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{probes?.map((probe, index) => (
							<tr key={`Probe-${probe.ProbeId}`}>
								<td
									className={classes.simpleCellHeader}
									style={{
										padding: "0px 3px 0px 13px",
										minWidth: "100px",
										borderBottomLeftRadius:
											index === probes.length - 1 ? "0.25rem" : "0",
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<span>{index + 1}</span>
										{probe.ProbeId > 0 && (
											<div>
												<Button
													aria-label={"Manual"}
													title={"Manual"}
													variant="text"
													size="small"
													onClick={() => handleOnManual(index)}
													className={
														classes.simpleButton +
														" " +
														(probe.IsProbeManual ? classes.manualButton : "")
													}
												>
													M
												</Button>
												<Button
													aria-label={"Automatic"}
													title={"Automatic"}
													variant="text"
													size="small"
													onClick={() => handleOnReadProbePLC(probe.ProbeId)}
													className={
														classes.simpleButton +
														" " +
														(probe.IsProbeManual ? "" : classes.automaticButton)
													}
												>
													A
												</Button>
											</div>
										)}
									</div>
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									<ProbeTimeField
										disabled={!probe.IsProbeManual}
										value={probe.Timestamp}
										onFocus={() => handleOnFocus(index, "Timestamp")}
										onChange={(value: Date) => {
											handleOnUpdatedProbeTime(
												probe.ProbeId,
												index,
												"Timestamp",
												value,
											);
										}}
									/>
								</td>
								<td>
									<InputFieldTable
										classInput={classes.simpleCellNumeric}
										value={probe.Temperature?.toString() || ""}
										readOnly={!probe.IsProbeManual}
										onFocus={() => handleOnFocus(index, "Temperature")}
										onChange={(value) =>
											handleOnChange(index, "Temperature", value)
										}
										onBlur={(value) =>
											handleOnBlur(index, "Temperature", value)
										}
									/>
								</td>
								<td>
									<InputFieldTable
										classInput={classes.simpleCellNumeric}
										value={probe.SuperHeat?.toString() || ""}
										readOnly={true}
									/>
								</td>
								<td
									className={classes.simpleCellHeader}
									style={{
										position: "absolute",
										height: "35px",
										width: "35px",

										borderBottomRightRadius:
											index === probes.length - 1 ? "0.25rem" : "0",
										borderTopRightRadius: index === 0 ? "0.25rem" : "0",
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<IconButton
											className={classes.deleteButton}
											aria-label={"Delete"}
											title={"Delete"}
											size="small"
											onClick={() => handleOnRemoveProbe(index)}
										>
											<DeleteIcon
												fontSize="inherit"
												style={{ cursor: "pointer" }}
												color="secondary"
											/>
										</IconButton>
									</div>
								</td>
							</tr>
						))}
						{probes.filter((probe) => probe.ProbeId === 0).length === 0 && (
							<div
								style={{
									width: "340%",
									textAlign: "start",
									marginTop: "6px",
								}}
							>
								<button onClick={handleOnAddProbe}>+</button>
							</div>
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};
