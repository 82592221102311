import { DelayCodeAPI } from "./DelayCodeAPI";

export const DATA_DELAY_INITIAL_VALUES: DelayCodeAPI = {
	DelayAreaAssetId: 0,
	DelayCodeId: null,
	DelayCodeName: "",
	DelayCategoryId: 0,
	DelayCategoryName: "",
	ParentDelayCodeId: 0,
	ParentDelayCodeName: "",
	DelayCategoryColor: "",
	IsDelayManual: false,
	IsBranch: false,
	IsActive: true,
};
