import { Button, CircularProgress } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button/Button";
import { ReactNode } from "react";

interface Props extends ButtonProps {
	children: ReactNode;
	isLoading: boolean;
}

export const ButtonWithLoading = ({ children, isLoading, ...props }: Props) => {
	return (
		<Button {...props} disabled={isLoading}>
			{isLoading && <CircularProgress size={"1rem"} />}
			{children}
		</Button>
	);
};
