import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogMold } from "../models/catalogs/CatalogMold";

export const getCatalogMolds = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[CAS].[GetMolds]", []);
};
export const upsertCatalogMold = async (Mold: CatalogMold) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "MoldId", value: Mold.MoldId },
		{ name: "MoldName", value: Mold.MoldName },
		{ name: "MoldSizeId", value: Mold.MoldSizeId },
	];

	return await apiService.callV2("[CAS].[UpsertMold]", parameters);
};

export const deleteCatalogMold = async (MoldId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "MoldId", value: MoldId }];

	return await apiService.callV2("[CAS].[DeleteMold]", parameters);
};

export const getCatalogMold = async (MoldId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "MoldId", value: MoldId }];

	return await apiService.callV2("[CAS].[GetMold]", parameters);
};
