import {
	MenuOptionType,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { CallSplit, Comment } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import MemoryIcon from "@material-ui/icons/Memory";
import { set } from "date-fns";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Process, ProcessEvent } from "../../caster/models/ProcessGanttChart";
import { getDelay } from "../../delays/delays-manager/repositories/DelaysManagerRepository";
import { useCasterDashboardContext } from "../CasterDashboardContext";

const moment = getMomentTz();

export const useGanttOptionsContextMenu = () => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [showLinkModal, setShowLinkModal] = useState(false);
	const [showUnlinkModal, setShowUnlinkModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showActiveModal, setShowActiveModal] = useState(false);
	const [showJustifyModal, setShowJustifyModal] = useState(false);
	const [showSplitModal, setShowSplitModal] = useState(false);

	const [messageDeleteModal, setMessageDeleteModal] = useState("");
	const [messageLinkConfirmation, setMessageLinkConfirmation] = useState("");
	const [messageUnlinkModal, setMessageUnlinkModal] = useState("");
	const {
		state: { selectedAsset },
		actions: { setSelectedProcess },
	} = useCasterDashboardContext();
	const { shiftInfo } = useShiftNavigator();
	const navigate = useNavigate();

	//Create
	const createData = useCallback(
		(assetId: number | null) => {
			setSelectedProcess({
				ProcessId: 0,
				AssetId: Number(assetId),
				ProcessCode: "",
				AssetName: "",
				Start: shiftInfo?.CurrentProductionDate ?? new Date(),
				End: shiftInfo?.CurrentProductionDate ?? new Date(),
				HeatName: null,
				LadleName: null,
				GradeName: null,
				Comments: null,
			});
			setShowEditModal(true);
		},
		[shiftInfo?.CurrentProductionDate, setSelectedProcess],
	);

	//Edit
	const editData = (data: Process) => {
		setSelectedProcess(data);
		setShowEditModal(true);
	};

	//Link
	const linkData = (data: Process) => {
		if (data.HeatName)
			setMessageLinkConfirmation(
				`The process is already linked to a heat ${data.HeatName}.  If you proceed you will unlink it from this heat in order to link it to another heat.`,
			);
		else setShowLinkModal(true);
		setSelectedProcess(data);
	};

	//Justify
	const justifyData = (data: ProcessEvent) => {
		setShowJustifyModal(true);
	};

	//Split
	const splitData = (data: ProcessEvent) => {
		setShowSplitModal(true);
	};

	//Unlink
	const unlinkData = (data: Process) => {
		setSelectedProcess(data);
		const dateOpen = moment(data.Start).format("YYYY/MM/DD HH:mm");
		setMessageUnlinkModal(
			`The heat ${data.HeatName} from ${dateOpen} will be unlinked.  Do you want to continue?`,
		);
		setShowUnlinkModal(true);
	};

	const inactiveData = (data: Process) => {
		setSelectedProcess(data);
		if (data.HeatName) {
			setMessageDeleteModal(
				` The Process starting at ${moment(data.Start).format("YYYY/MM/DD HH:mm")} associated with heat ${data.HeatName} will be deleted. Do you want to continue?`,
			);
		} else {
			setMessageDeleteModal(
				` The Process starting at ${moment(data.Start).format("YYYY/MM/DD HH:mm")} and not assigned to any heat will be deleted. Do you want to continue?`,
			);
		}
		setShowDeleteModal(true);
	};
	//navigate functions
	const goToHeats = (HeatId: number) => {
		if (HeatId)
			navigate(`/meltshop-heats/${shiftInfo?.CurrentShiftId}?heatId=${HeatId}`);
	};

	const goToDataEntry = (process: Process) => {
		if (process.ProcessId === null) return;
		if (process.ProcessCode === "C")
			navigate(`/caster-data-entry/${process.ProcessId}`);
		if (process.ProcessCode === "E")
			navigate(`/eaf-data-entry/${process.ProcessId}`);
	};

	const goToDelaysManager = async (delay: ProcessEvent) => {
		const resp = await getDelay(delay.DelayId);
		if (resp.ok) {
			const delayById = resp.data.tables[0].rows[0];
			navigate(
				`/delays-manager/${delayById.ShiftId}?delayId=${delayById.DelayId}`,
			);
		} else navigate(`/delays-manager/${shiftInfo?.CurrentShiftId}`);
	};

	const getGanttMenuOptions = (data: Process) => {
		let options: MenuOptionType[] = [];

		if (data.ProcessId) {
			options.push({
				name: "See in Data Entry",
				key: "go_to_caster_data_entry",
				style: { fontWeight: "bold" },
				onClick: () => goToDataEntry(data),
				icon: <MemoryIcon />,
			});
		}
		if (data.HeatId) {
			options = options.concat([
				{
					name: "See in Heat Table",
					key: "go_to_heat",
					onClick: () => goToHeats(data.HeatId),
					icon: (
						<i
							style={{ fontSize: "1.5em", marginLeft: "-3px" }}
							className="material-icons"
						>
							thermostat
						</i>
					),
				},
			]);
		}
		if (data.HeatName) {
			options = options.concat([
				{
					name: "Unlink Process",
					key: "unlink",
					onClick: () => unlinkData(data),
					icon: <LinkOffIcon />,
				},
			]);
		}
		if (data.ProcessId) {
			options = options.concat([
				{
					name: "Link Process",
					key: "link",
					onClick: () => linkData(data),
					icon: <LinkIcon />,
				},
			]);
		}
		if (data.ProcessId) {
			options.push(
				{
					name: "Edit Process",
					key: "toggle_status",
					onClick: () => editData(data),
					icon: <EditIcon />,
				},
				{
					name: "Delete Process",
					key: "toggle_status",
					onClick: () => inactiveData(data),
					icon: <DeleteIcon />,
				},
			);
		} else {
			options.push({
				name: "New Process",
				key: "toggle_status",
				onClick: () => createData(Number(selectedAsset?.id)),
				icon: <EditIcon />,
			});
		}

		return options;
	};

	const getGanttDelaysMenuOptions = (data: ProcessEvent) => {
		let options: MenuOptionType[] = [];

		options.push({
			name: "Justify Delay",
			key: "justify_delay",
			onClick: () => justifyData(data),
			icon: <Comment />,
		});
		options.push({
			name: "See in Manager",
			key: "delays_manager",
			onClick: () => goToDelaysManager(data),
			icon: <MemoryIcon />,
		});

		return options;
	};

	return {
		createData,
		editData,
		linkData,
		unlinkData,
		getGanttMenuOptions,
		getGanttDelaysMenuOptions,
		goToHeats,
		goToDataEntry,
		showActiveModal,
		showDeleteModal,
		showEditModal,
		showLinkModal,
		showUnlinkModal,
		showJustifyModal,
		showSplitModal,
		messageLinkConfirmation,
		messageDeleteModal,
		messageUnlinkModal,
		setShowActiveModal,
		setShowDeleteModal,
		setShowEditModal,
		setShowLinkModal,
		setShowUnlinkModal,
		setShowJustifyModal,
		setShowSplitModal,
		setMessageLinkConfirmation,
		setMessageDeleteModal,
		setMessageUnlinkModal,
	};
};
