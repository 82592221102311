import * as React from "react";
import { ChemistryByHeatPage } from "./ChemistryByHeatPage";

import { ChemistryByHeatProvider } from "./ChemistryByHeatContext";

type Props = {};
const ChemistryByHeat = (props: Props) => {
	return (
		<ChemistryByHeatProvider>
			<ChemistryByHeatPage />
		</ChemistryByHeatProvider>
	);
};
export default ChemistryByHeat;
