import { createSlice } from "@reduxjs/toolkit";
import { AlertConfiguration } from "../models/catalogs/alertConfiguration";

const ConfigurationInitialState: {
	showLoading: boolean;
	alertConfigurations: AlertConfiguration[];
	selectedAlertConfiguration: AlertConfiguration | null;
	error: any | undefined;
	success: any | undefined;
} = {
	showLoading: false,
	alertConfigurations: [],
	selectedAlertConfiguration: null,
	error: undefined,
	success: undefined,
};

export const ConfigurationReducer = createSlice({
	name: "__",
	initialState: ConfigurationInitialState,
	reducers: {
		setShowLoading(state, { payload }) {
			state.showLoading = payload;
		},
		setAlertConfigurations(state, { payload }) {
			state.alertConfigurations = payload;
		},
		setSelectedAlertConfiguration(state, { payload }) {
			state.selectedAlertConfiguration = payload;
		},
		setError(state, { payload }) {
			state.error = payload;
		},
		setSuccess(state, { payload }) {
			state.success = payload;
		},
	},
});
