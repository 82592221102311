import { MenuItem, MenuList } from "@material-ui/core";
import { Link } from "react-router-dom";
import AlertLimit from "../pages/caster/alert/AlertLimit";
import CatalogMoldSize from "../pages/caster/catalogs/mold-sizes";
import CatalogMold from "../pages/caster/catalogs/molds";
import CatalogCategories from "../pages/delays/categories";
import DelayCodePage from "../pages/delays/codes";
import CatalogElement from "../pages/laboratory/catalogs/elements";
import CatalogMechanicalTest from "../pages/laboratory/catalogs/mechanicalTest";
import CatalogResultSource from "../pages/laboratory/catalogs/resultSource";
import CatalogSampleType from "../pages/laboratory/catalogs/sampleType";
import CatalogBuckets from "../pages/steel/catalogs/buckets";
import CatalogGrade from "../pages/steel/catalogs/grade";
import CatalogLadle from "../pages/steel/catalogs/ladle";
import CatalogMaterial from "../pages/steel/catalogs/materials";
import CatalogSection from "../pages/steel/catalogs/section";

const components = [
	{
		path: "alerts",
		sidebar: () => <div>alerts</div>,
		main: () => <AlertLimit />,
	},
	{
		path: "lab-element",
		sidebar: () => <div>elements</div>,
		main: () => <CatalogElement />,
	},
	{
		path: "lab-mechanical-test",
		sidebar: () => <div>mechanical tests</div>,
		main: () => <CatalogMechanicalTest />,
	},
	{
		path: "lab-result-source",
		sidebar: () => <div>result sources</div>,
		main: () => <CatalogResultSource />,
	},
	{
		path: "lab-sample-type",
		sidebar: () => <div>sample types</div>,
		main: () => <CatalogSampleType />,
	},
	{
		path: "caster-molds",
		sidebar: () => <div>Molds</div>,
		main: () => <CatalogMold />,
	},
	{
		path: "caster-mold-sizes",
		sidebar: () => <div>Mold Sizes</div>,
		main: () => <CatalogMoldSize />,
	},
	{
		path: "steel-grade",
		sidebar: () => <div>grades</div>,
		main: () => <CatalogGrade />,
	},
	{
		path: "steel-ladle",
		sidebar: () => <div>ladles</div>,
		main: () => <CatalogLadle />,
	},
	{
		path: "steel-section",
		sidebar: () => <div>sections</div>,
		main: () => <CatalogSection />,
	},
	{
		path: "steel-material",
		sidebar: () => <div>materials</div>,
		main: () => <CatalogMaterial />,
	},
	{
		path: "steel-buckets",
		sidebar: () => <div>buckets</div>,
		main: () => <CatalogBuckets />,
	},
	{
		path: "delay-categories",
		sidebar: () => <div>Delay Categories</div>,
		main: () => <CatalogCategories />,
	},
	{
		path: "delay-codes-eaf",
		sidebar: () => <div>EAF Delay Codes</div>,
		main: () => <DelayCodePage delayAreaAssetName="EAF" />,
	},
	{
		path: "delay-codes-caster",
		sidebar: () => <div>CASTER Delay Codes</div>,
		main: () => <DelayCodePage delayAreaAssetName="Caster" />,
	},
];

const sidebar = ({ option }: { option: string }) => (
	<>
		<h3 className="mes-menu-group">Laboratory</h3>
		<MenuList className="mes-submenu">
			<MenuItem selected={option === "lab-element"} className="p-0">
				<Link to="/configuration/lab-element" className="nav-link">
					Elements
				</Link>
			</MenuItem>
			<MenuItem selected={option === "lab-mechanical-test"} className="p-0">
				<Link to="/configuration/lab-mechanical-test" className="nav-link">
					Mechanical Tests
				</Link>
			</MenuItem>
			<MenuItem selected={option === "lab-result-source"} className="p-0">
				<Link to="/configuration/lab-result-source" className="nav-link">
					Result Sources
				</Link>
			</MenuItem>
			<MenuItem selected={option === "lab-sample-type"} className="p-0">
				<Link to="/configuration/lab-sample-type" className="nav-link">
					Sample Types
				</Link>
			</MenuItem>
		</MenuList>
		<h3 className="mes-menu-group">Caster</h3>
		<MenuList className="mes-submenu">
			<MenuItem selected={option === "caster-molds"} className="p-0">
				<Link to="/configuration/caster-molds" className="nav-link">
					Molds
				</Link>
			</MenuItem>
			<MenuItem selected={option === "caster-mold-sizes"} className="p-0">
				<Link to="/configuration/caster-mold-sizes" className="nav-link">
					Mold Sizes
				</Link>
			</MenuItem>
		</MenuList>
		<h3 className="mes-menu-group">Steel</h3>
		<MenuList className="mes-submenu">
			<MenuItem selected={option === "steel-grade"} className="p-0">
				<Link to="/configuration/steel-grade" className="nav-link">
					Grades
				</Link>
			</MenuItem>
			<MenuItem selected={option === "steel-ladle"} className="p-0">
				<Link to="/configuration/steel-ladle" className="nav-link">
					Ladles
				</Link>
			</MenuItem>
			<MenuItem selected={option === "steel-section"} className="p-0">
				<Link to="/configuration/steel-section" className="nav-link">
					Sections
				</Link>
			</MenuItem>
			<MenuItem selected={option === "steel-material"} className="p-0">
				<Link to="/configuration/steel-material" className="nav-link">
					Materials
				</Link>
			</MenuItem>
			<MenuItem selected={option === "steel-buckets"} className="p-0">
				<Link to="/configuration/steel-buckets" className="nav-link">
					Buckets
				</Link>
			</MenuItem>
		</MenuList>
		<h3 className="mes-menu-group">Settings</h3>
		<MenuList className="mes-submenu">
			<MenuItem selected={option === "alerts"} className="p-0">
				<Link to="/configuration/alerts" className="nav-link">
					Alerts
				</Link>
			</MenuItem>
		</MenuList>
		<h3 className="mes-menu-group">Delays</h3>
		<MenuList className="mes-submenu">
			<MenuItem selected={option === "delay-categories"} className="p-0">
				<Link to="/configuration/delay-categories" className="nav-link">
					Categories
				</Link>
			</MenuItem>
			<MenuItem selected={option === "delay-codes-eaf"} className="p-0">
				<Link to="/configuration/delay-codes-eaf" className="nav-link">
					EAF Codes
				</Link>
			</MenuItem>
			<MenuItem selected={option === "delay-codes-caster"} className="p-0">
				<Link to="/configuration/delay-codes-caster" className="nav-link">
					Caster Codes
				</Link>
			</MenuItem>
		</MenuList>
	</>
);

const customConfigurations = [components, sidebar];

export { customConfigurations };
