import { createSlice } from "@reduxjs/toolkit";
import { ProcessByShift } from "../models/ProcessByShift";

const CasterProcessesInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	errorMessage: string;
	notificationMessage: string;
	processes: ProcessByShift[];
	selectedProcess: ProcessByShift | null;
	anchorPoint: any;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	processes: [],
	selectedProcess: null,
	errorMessage: "",
	notificationMessage: "",
	anchorPoint: {
		x: 0,
		y: 0,
	},
};

export const CasterProcessesReducer = createSlice({
	name: "__",
	initialState: CasterProcessesInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setProcesses(state, { payload }) {
			state.processes = payload;
		},
		setSelectedProcess(state, { payload }) {
			state.selectedProcess = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setAnchorPointX(state, { payload }) {
			state.anchorPoint.x = payload;
		},
		setAnchorPointY(state, { payload }) {
			state.anchorPoint.y = payload;
		},
	},
});
