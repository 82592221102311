import { Theme, makeStyles } from "@material-ui/core";
import { ColDef } from "ag-grid-community";

export interface RowFromApi {
	Year: number;
	Month: number | null;
	EAF: null | string;
	Heats: number;
	ScrapUsage: number | null;
	CastTons: null;
	CastScrap: null;
}

const valueFormatter = (params: any) => {
	const specificRowIndexes = [1, 2, 3];
	if (specificRowIndexes.includes(params.node.rowIndex)) {
		return (params.value / 1000).toLocaleString("en-US", {
			minimumFractionDigits: 1,
			maximumFractionDigits: 1,
		});
	}
	return params.value;
};

export const CreateDataTable = (rows: RowFromApi[]) => {
	const now = new Date();
	const currentMonth = now.toLocaleString("en-US", { month: "long" });

	const previousMonthDate = new Date(now.getFullYear(), now.getMonth() - 1);
	const previousMonth = previousMonthDate.toLocaleString("en-US", {
		month: "long",
	});

	const data: DataTable = {
		CurrentMonth: {
			Year: now.getFullYear().toString(),
			Month: (now.getMonth() + 1).toString(),
			Assets: {},
			Total: {
				Heats: 0,
				ScrapsUsage: 0,
				CastedTons: 0,
				ScrapGenerated: 0,
			},
		},
		PreviousMonth: {
			Year: now.getFullYear().toString(),
			Month: now.getMonth().toString(),
			Assets: {},
			Total: {
				Heats: 0,
				ScrapsUsage: 0,
				CastedTons: 0,
				ScrapGenerated: 0,
			},
		},
		YearToDate: {
			Year: now.getFullYear().toString(),
			Month: "",
			Assets: {},
			Total: {
				Heats: 0,
				ScrapsUsage: 0,
				CastedTons: 0,
				ScrapGenerated: 0,
			},
		},
	};

	rows.forEach((row) => {
		const { EAF: asset, Year, Month } = row;

		if (Year === now.getFullYear() && Month === now.getMonth() + 1) {
			if (asset !== null) {
				data.CurrentMonth.Assets[asset] = {
					Heats: row?.Heats ?? 0,
					ScrapsUsage: row?.ScrapUsage ?? 0,
					CastedTons: row?.CastTons ?? 0,
					ScrapGenerated: row?.CastScrap ?? 0,
				};
				data.CurrentMonth.Total.Heats += data.CurrentMonth.Assets[asset].Heats;
				data.CurrentMonth.Total.ScrapsUsage +=
					data.CurrentMonth.Assets[asset].ScrapsUsage;
				data.CurrentMonth.Total.CastedTons +=
					data.CurrentMonth.Assets[asset].CastedTons;
				data.CurrentMonth.Total.ScrapGenerated +=
					data.CurrentMonth.Assets[asset].ScrapGenerated;
			}
		}

		if (Year === now.getFullYear() && Month === now.getMonth()) {
			if (asset !== null) {
				data.PreviousMonth.Assets[asset] = {
					Heats: row?.Heats ?? 0,
					ScrapsUsage: row?.ScrapUsage ?? 0,
					CastedTons: row?.CastTons ?? 0,
					ScrapGenerated: row?.CastScrap ?? 0,
				};
				data.PreviousMonth.Total.Heats +=
					data.PreviousMonth.Assets[asset].Heats;
				data.PreviousMonth.Total.ScrapsUsage +=
					data.PreviousMonth.Assets[asset].ScrapsUsage;
				data.PreviousMonth.Total.CastedTons +=
					data.PreviousMonth.Assets[asset].CastedTons;
				data.PreviousMonth.Total.ScrapGenerated +=
					data.PreviousMonth.Assets[asset].ScrapGenerated;
			}
		}

		if (asset !== null) {
			if (!data.YearToDate.Assets[asset]) {
				data.YearToDate.Assets[asset] = {
					Heats: 0,
					ScrapsUsage: 0,
					CastedTons: 0,
					ScrapGenerated: 0,
				};
			}
			data.YearToDate.Assets[asset].Heats += row?.Heats ?? 0;
			data.YearToDate.Assets[asset].ScrapsUsage += row?.ScrapUsage ?? 0;
			data.YearToDate.Assets[asset].CastedTons += row?.CastTons ?? 0;
			data.YearToDate.Assets[asset].ScrapGenerated += row?.CastScrap ?? 0;

			data.YearToDate.Total.Heats += row?.Heats ?? 0;
			data.YearToDate.Total.ScrapsUsage += row?.ScrapUsage ?? 0;
			data.YearToDate.Total.CastedTons += row?.CastTons ?? 0;
			data.YearToDate.Total.ScrapGenerated += row?.CastScrap ?? 0;
		}
	});

	const columnDefs = [
		{
			headerName: ``,
			flex: 2,
			field: "rowDef",
		},
		{
			headerName: `Current Month - ${currentMonth} ${now.getFullYear()}`,
			children: [
				...Object.keys(data.YearToDate.Assets).map((assetKey) => {
					return {
						field: `${assetKey}-CM`,
						headerName: assetKey,
						flex: 1,
						type: ["numericColumn", "numericWidth"],
						valueFormatter: valueFormatter,
					};
				}),
				{
					field: "Total-CM",
					headerName: "Total",
					flex: 1,
					type: ["numericColumn", "numericWidth"],
					valueFormatter: valueFormatter,
				},
			],
		},
		{
			headerName: `Previous Month - ${previousMonth} ${now.getFullYear()}`,
			children: [
				...Object.keys(data.YearToDate.Assets).map((assetKey) => {
					return {
						field: `${assetKey}-PM`,
						headerName: assetKey,
						flex: 1,
						type: ["numericColumn", "numericWidth"],
						valueFormatter: valueFormatter,
					};
				}),
				{
					field: "Total-PM",
					headerName: "Total",
					flex: 1,
					type: ["numericColumn", "numericWidth"],
					valueFormatter: valueFormatter,
				},
			],
		},
		{
			headerName: `Year To Date - ${now.getFullYear()}`,
			children: [
				...Object.keys(data.YearToDate.Assets).map((assetKey) => {
					return {
						field: `${assetKey}-YD`,
						headerName: assetKey,
						flex: 1,
						type: ["numericColumn", "numericWidth"],
						valueFormatter: valueFormatter,
					};
				}),
				{
					field: "Total-YD",
					headerName: "Total",
					flex: 1,
					type: ["numericColumn", "numericWidth"],
					valueFormatter: valueFormatter,
				},
			],
		},
	];

	const rowsForTable = [
		{
			rowDef: "Heat Count",
			"EAF1-CM": data.CurrentMonth.Assets.EAF1?.Heats ?? 0,
			"EAF2-CM": data.CurrentMonth.Assets.EAF2?.Heats ?? 0,
			"Total-CM": data.CurrentMonth.Total.Heats,
			"EAF1-PM": data.PreviousMonth.Assets.EAF1?.Heats ?? 0,
			"EAF2-PM": data.PreviousMonth.Assets.EAF2?.Heats ?? 0,
			"Total-PM": data.PreviousMonth.Total.Heats,
			"EAF1-YD": data.YearToDate.Assets.EAF1?.Heats ?? 0,
			"EAF2-YD": data.YearToDate.Assets.EAF2?.Heats ?? 0,
			"Total-YD": data.YearToDate.Total.Heats,
		},
		{
			rowDef: "Scrap Tons",
			"EAF1-CM": data.CurrentMonth.Assets.EAF1?.ScrapsUsage ?? 0,
			"EAF2-CM": data.CurrentMonth.Assets.EAF2?.ScrapsUsage ?? 0,
			"Total-CM": data.CurrentMonth.Total.ScrapsUsage,
			"EAF1-PM": data.PreviousMonth.Assets.EAF1?.ScrapsUsage ?? 0,
			"EAF2-PM": data.PreviousMonth.Assets.EAF2?.ScrapsUsage ?? 0,
			"Total-PM": data.PreviousMonth.Total.ScrapsUsage,
			"EAF1-YD": data.YearToDate.Assets.EAF1?.ScrapsUsage ?? 0,
			"EAF2-YD": data.YearToDate.Assets.EAF2?.ScrapsUsage ?? 0,
			"Total-YD": data.YearToDate.Total.ScrapsUsage,
		},
		{
			rowDef: "Cast Tons",
			"EAF1-CM": data.CurrentMonth.Assets.EAF1?.CastedTons ?? 0,
			"EAF2-CM": data.CurrentMonth.Assets.EAF2?.CastedTons ?? 0,
			"Total-CM": data.CurrentMonth.Total.CastedTons,
			"EAF1-PM": data.PreviousMonth.Assets.EAF1?.CastedTons ?? 0,
			"EAF2-PM": data.PreviousMonth.Assets.EAF2?.CastedTons ?? 0,
			"Total-PM": data.PreviousMonth.Total.CastedTons,
			"EAF1-YD": data.YearToDate.Assets.EAF1?.CastedTons ?? 0,
			"EAF2-YD": data.YearToDate.Assets.EAF2?.CastedTons ?? 0,
			"Total-YD": data.YearToDate.Total.CastedTons,
		},
		{
			rowDef: "Caster Scrap Tons",
			"EAF1-CM": data.CurrentMonth.Assets.EAF1?.ScrapGenerated ?? 0,
			"EAF2-CM": data.CurrentMonth.Assets.EAF2?.ScrapGenerated ?? 0,
			"Total-CM": data.CurrentMonth.Total.ScrapGenerated,
			"EAF1-PM": data.PreviousMonth.Assets.EAF1?.ScrapGenerated ?? 0,
			"EAF2-PM": data.PreviousMonth.Assets.EAF2?.ScrapGenerated ?? 0,
			"Total-PM": data.PreviousMonth.Total.ScrapGenerated,
			"EAF1-YD": data.YearToDate.Assets.EAF1?.ScrapGenerated ?? 0,
			"EAF2-YD": data.YearToDate.Assets.EAF2?.ScrapGenerated ?? 0,
			"Total-YD": data.YearToDate.Total.ScrapGenerated,
		},
	];

	const defaultColDef: ColDef = {
		wrapText: true,
		resizable: true,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		minWidth: 50,

		filter: false,
		floatingFilter: false,
		sortable: false,
		editable: false,
	};

	if (Object.keys(data.YearToDate.Assets).length === 0) {
		return {
			columnDefs: [
				{
					headerName: "",
					flex: 1,
					field: "rowDef",
				},
			],
			rowsForTable: [{ rowDef: "No data to show" }],
			defaultColDef,
		};
	}

	return { columnDefs, rowsForTable, defaultColDef };
};
