import { Grid } from "@material-ui/core";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-balham.min.css";
import { useContextMenuMESF } from "@dexteel/mesf-core";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGridStyles } from "../../../caster/styles/gridStyles";
import { useCasterDashboardContext } from "../../CasterDashboardContext";
import { useChemistryTableData } from "../useChemistryTableData";
import { useMissedChemistriesOptionsFunctions } from "./hooks/useMissedChemistriesOptionsFunctions";

export const MissedChemistriesTable = () => {
	const gridRef = useRef<AgGridReact>(null);
	const {
		state: { casterChemistries, isLoadingChemistryGrid },
	} = useCasterDashboardContext();
	const [gridAPI, setGridAPI] = useState<any>(null);
	const [gridColumnAPI, setGridColumnAPI] = useState<any>(null);
	const classes = useGridStyles();

	const { columnData, defaultColDef } = useChemistryTableData();

	const { getMissedChemistriesMenuOptions, goToHeatReport } =
		useMissedChemistriesOptionsFunctions();
	const { showContextMenu, registerConfig } = useContextMenuMESF();

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
		setGridColumnAPI(params.columnApi);
	};

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		params.columnApi.autoSizeAllColumns(true);
	};
	useMemo(() => {
		if (
			isLoadingChemistryGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [isLoadingChemistryGrid]);

	useEffect(() => {
		registerConfig({
			id: "missedChemistries",
			getOptions: getMissedChemistriesMenuOptions,
		});
	}, []);
	return (
		<Grid container className={classes.root}>
			<Grid
				item
				md={12}
				xs={12}
				style={{
					height: "40vh",
					minHeight: "30vh",
					border: "4px solid #ccc",
					borderRadius: 10,
					padding: 10,
					margin: "20px 0 10px",
				}}
				className={"ag-theme-balham"}
			>
				<AgGridReact
					ref={gridRef}
					rowSelection="single"
					rowHeight={35}
					onFirstDataRendered={onFirstDataRendered}
					defaultColDef={defaultColDef}
					onGridReady={onGridReady}
					rowData={casterChemistries}
					columnDefs={columnData}
					suppressContextMenu={true}
					onRowDoubleClicked={(event: any) => {
						goToHeatReport(event.data.HeatNameLink);
					}}
					onCellContextMenu={(event) => {
						if (event.event)
							showContextMenu(
								event.event as any,
								event.data,
								"missedChemistries",
							);
					}}
				/>
			</Grid>
		</Grid>
	);
};
