import { makeStyles } from "@material-ui/core";
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDelaysReportContext } from "../../context/DelaysReportContext";

const useStyles = makeStyles(() => ({
	select: {
		height: 40,
		paddingTop: 5,
		backgroundColor: "#FFF",
		"& .MuiOutlinedInput-input": {
			paddingTop: 10,
			paddingBottom: 10,
		},
	},
}));

type Props = {
	filterShift: string;
	onFilterShiftChange: (value: string) => void;
};

export const ShiftsFilter: React.FC<Props> = ({
	filterShift,
	onFilterShiftChange,
}) => {
	const classes = useStyles();
	const {
		state: { shiftsList },
	} = useDelaysReportContext();

	useEffect(() => {
		if (!filterShift && shiftsList.length > 0) {
			onFilterShiftChange("All");
		}
	}, [shiftsList, filterShift, onFilterShiftChange]);

	const handleFilter = (e: SelectChangeEvent<string>) => {
		onFilterShiftChange(e.target.value);
	};

	return (
		<FormControl variant="outlined">
			<InputLabel id="shift-select-label">Shift</InputLabel>
			<Select
				labelId="shift-select-label"
				id="shift-select"
				value={filterShift}
				onChange={handleFilter}
				label="Shift"
				className={classes.select}
			>
				{shiftsList.map((shift: any) => (
					<MenuItem key={`shift-${shift.Shift}`} value={shift.Shift}>
						{shift.Shift}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
