import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { LazyLoading } from "../../../controls/LazyLoading";
import { TableCategories } from "./components/CategoriesDataTable/TableCategories";
import { useCatalogCategoryContext } from "./context/CategoriesContext";
import {
	getAssetsForDelayArea,
	getCatalogCategories,
} from "./repositories/CatalogCategoriesRepository";

type Props = {};

export const CatalogCategoriesPage = (props: Props) => {
	const {
		state,
		state: { assetIdToFilter },
		actions: { setAssetCodes, setCategories },
	} = useCatalogCategoryContext();

	const [isLoadingInitial, setIsLoadingInitial] = useState(true);
	const [isLoadingCategories, setIsLoadingCategories] = useState(false);
	const [error, setError] = useState("");

	const getCatalogCategoriesFromAPI = async (
		assetIdToFilter: number | null = null,
	) => {
		setIsLoadingCategories(true);
		const includeAncestor = assetIdToFilter !== 0;
		const resp = await getCatalogCategories(assetIdToFilter, includeAncestor);
		if (resp.ok) {
			setCategories(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		setIsLoadingCategories(false);
	};

	const getAssetsFromAPI = async () => {
		setIsLoadingInitial(true);
		const assetResponse = await getAssetsForDelayArea();
		if (assetResponse.ok) {
			setAssetCodes(assetResponse.data.tables[0].rows);
		} else {
			setError(assetResponse.message);
		}
		setIsLoadingInitial(false);
	};

	useEffect(() => {
		getAssetsFromAPI();
	}, []);

	useEffect(() => {
		if (!isLoadingInitial && assetIdToFilter !== null) {
			getCatalogCategoriesFromAPI(assetIdToFilter);
		}
	}, [isLoadingInitial, assetIdToFilter]);

	if (isLoadingInitial || isLoadingCategories) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<LazyLoading />
			</div>
		);
	}

	if (state.categories) {
		return (
			<Grid container justifyContent="center" alignItems="center">
				<Grid item md={12} xs={12} style={{ margin: 0 }}>
					<Paper
						elevation={1}
						style={{
							width: "100%",
							padding: "10px 0 20px",
							borderRadius: "0.75rem",
						}}
					>
						<TableCategories
							isLoading={isLoadingCategories}
							setIsLoading={setIsLoadingCategories}
							getCatalogCategoriesFromAPI={getCatalogCategoriesFromAPI}
						/>
					</Paper>
				</Grid>
			</Grid>
		);
	}

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<ErrorModal error={error} onHide={() => setError("")} />
			<LazyLoading />
		</div>
	);
};
