export interface CatalogLadle {
	LadleId: number | null;
	LadleName: string;
	Active: boolean;
}
export const CATALOG_LADLE_INITIAL_VALUES: CatalogLadle = {
	LadleId: null,
	LadleName: "",
	Active: true,
};
