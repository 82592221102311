import { useCallback } from "react";
import { getCatalogBuckets } from "../../../steel/repositories/CatalogBucketRepository";
import { getCatalogMaterials } from "../../../steel/repositories/CatalogMaterialRepository";
import { useDataEntryContext } from "../context/reducer.provider";
import { useTimerContext } from "../context/timer.provider";
import {
	Addition,
	Charge,
	Heating,
	Probe,
	Process,
	ProcessResponse,
} from "../models/eaf.models";
import { Pourback, PourbackResponse } from "../models/ms.models";
import {
	UpsertAddition,
	UpsertHeating,
	UpsertPourbackCharge,
	deleteAdditionById,
	deleteChargeById,
	deleteHeatingById,
	deletePourbackChargeById,
	deleteProbeById,
	getEAFProcess,
	getPourbacks,
	upsertCharge,
	upsertHeat,
	upsertProbe,
	upsertProcess,
	upsertProcessData,
	upsertTrim,
} from "../repositories/eaf.repositories";

export const useEAFDataEntryActions = () => {
	const {
		state,
		actions: {
			setDEProcess,
			setErrorMessage,
			setIsDataLoading,
			setIsSubmitLoading,
			setNotificationMessage,
			setPourbacks,
		},
	} = useDataEntryContext();

	const { restart } = useTimerContext();

	const loadData = async (processId: number | null) => {
		setIsDataLoading(true);
		const resp = await getEAFProcess({ ProcessId: processId });
		if (resp.ok) {
			const data = resp.data as ProcessResponse;
			setDEProcess({
				process: data.tables["0"].rows[0] as Process,
				probes: data.tables["1"].rows,
				heatings: data.tables["2"].rows,
				charges: data.tables["3"].rows,
				additions: data.tables["4"].rows,
			});
			loadPourbacks(processId);
		} else {
			setErrorMessage(resp.message!);
		}
		setIsDataLoading(false);
	};

	const loadPourbacks = async (processId: number | null) => {
		setIsDataLoading(true);
		const resp = await getPourbacks({ ProcessId: processId });
		if (resp.ok) {
			const data = resp.data as PourbackResponse;
			setPourbacks(data.tables["0"].rows as Pourback[]);
		} else {
			setErrorMessage(resp.message!);
		}
		setIsDataLoading(false);
	};

	const refreshData = useCallback(async () => {
		if (state.deProcess?.process?.ProcessId) {
			loadData(state.deProcess.process.ProcessId).then(() => {
				restart();
			});
		} else {
			loadData(null).then(() => {
				restart();
			});
		}
	}, [state.deProcess?.process?.ProcessId]);

	const saveProcess = async (process: Process) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await upsertProcess({
			ProcessId: process.ProcessId!,
			Start: process.Start!.toISOString(),
			End: process.End?.toISOString() || null,
			AssetId: process.AssetId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Process saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const saveProcessData = async ({
		processId,
		field,
		value,
	}: {
		processId: number;
		field: string;
		value: boolean | string | number | Date | null;
	}) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await upsertProcessData({
			processId,
			field,
			value,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Process saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};
	const saveTrim = async (trim: {
		TrimProbeTemperature: number;
		TrimProbeTimestamp: Date;
	}) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await upsertTrim({
			processId: state.deProcess?.process!.ProcessId!,
			TrimProbeTemperature: trim.TrimProbeTemperature,
			TrimProbeTime: trim.TrimProbeTimestamp,
		});

		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Process saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};
	const saveProbe = async (probe: Probe) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await upsertProbe({
			ProcessId: state.deProcess?.process!.ProcessId!,
			ProbeId: probe.ProbeId!,
			IsProbeManual: true,
			Timestamp: probe.Timestamp!.toISOString(),
			Temperature: probe.Temperature,
			Carbon: probe.Carbon,
			Oxygen: probe.Oxygen,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Process saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const saveHeat = async (field: string, value: any) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await upsertHeat({
			HeatId: state.deProcess?.process!.HeatId!,
			field: field,
			value: value,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Process saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const saveCharge = async (charge: Charge) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await upsertCharge({
			ProcessId: state.deProcess?.process!.ProcessId!,
			ChargeId: charge.ChargeId!,
			Charge: charge.Charge,
			Timestamp: charge.Timestamp!.toISOString(),
			ChargeCode: charge.ChargeCode,
			Weight: charge.Weight,
			BucketId: charge.BucketId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Charge saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const saveAddition = async (addition: Addition) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await UpsertAddition({
			ProcessId: state.deProcess?.process!.ProcessId!,
			AdditionId: addition?.AdditionId,
			Timestamp: addition?.Timestamp!.toISOString(),
			Weight: addition?.Weight,
			MaterialId: addition?.MaterialId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Addition saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const saveHeating = async (heating: Heating) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await UpsertHeating({
			ProcessId: state.deProcess?.process!.ProcessId!,
			HeatingId: heating.HeatingId,
			Start: heating.Start!.toISOString(),
			End: heating.End!.toISOString(),
			MWH: heating.MWH,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Heating saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};
	const savePourbackCharge = async (pourback: Pourback) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await UpsertPourbackCharge({
			PourbackId: pourback?.PourbackId,
			HeatId: pourback?.HeatId,
			DestinationProcessId: state.deProcess?.process!.ProcessId!,
			ChargedWeight: pourback?.PourbackWeight,
			ChargedTime: pourback?.ChargedTime?.toISOString()!,
			RemainingWeight:
				pourback?.ChargedWeight === 0 ? null : pourback?.ChargedWeight,
			Comments: pourback?.Comments,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Pourback saved successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};
	const deleteProbe = async (probeId: number) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await deleteProbeById({
			ProbeId: probeId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Probe deleted successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const deleteCharge = async (chargeId: number) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await deleteChargeById({
			ChargeId: chargeId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Charge deleted successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	const deleteAddition = async (additionId: number) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await deleteAdditionById({
			AdditionId: additionId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Addition deleted successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};
	const deleteHeating = async (heatingId: number) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await deleteHeatingById({
			HeatingId: heatingId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Heating deleted successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};
	const getMaterials = async () => {
		setIsDataLoading(true);
		setErrorMessage("");
		const resp = await getCatalogMaterials();
		if (resp.ok) {
			return resp.data.tables[0].rows;
		} else {
			setErrorMessage(resp.message!);
		}
		setIsDataLoading(false);
	};
	const getBuckets = async () => {
		setIsDataLoading(true);
		setErrorMessage("");
		const resp = await getCatalogBuckets();
		if (resp.ok) {
			return resp.data.tables[0].rows;
		} else {
			setErrorMessage(resp.message!);
		}
		setIsDataLoading(false);
	};

	const deletePourbackCharge = async (pourbackId: number) => {
		setIsSubmitLoading(true);
		setErrorMessage("");
		const resp = await deletePourbackChargeById({
			PourbackId: pourbackId,
		});
		setIsSubmitLoading(false);
		if (resp.ok) {
			setNotificationMessage("Pourback deleted successfully");
			await refreshData();
		} else {
			setErrorMessage(resp.message!);
		}
	};

	return {
		saveProcess,
		loadData,
		refreshData,
		loadPourbacks,
		UpsertPourbackCharge,
		saveProcessData,
		saveProbe,
		saveHeat,
		saveCharge,
		saveTrim,
		saveAddition,
		saveHeating,
		savePourbackCharge,
		deleteProbe,
		deleteCharge,
		deleteAddition,
		deleteHeating,
		deletePourbackCharge,
		getMaterials,
		getBuckets,
	};
};
