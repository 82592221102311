import { MenuOptionType, useShiftNavigator } from "@dexteel/mesf-core";
import MemoryIcon from "@material-ui/icons/Memory";
import { useNavigate } from "react-router-dom";
import { CountersByShift } from "../../models/CountersByShift";

export const useOptionFunctions = () => {
	const navigate = useNavigate();
	const { shiftInfo } = useShiftNavigator();

	//Get Menu options
	const getMenuOptions = (data: CountersByShift) => {
		let options: MenuOptionType[] = [];

		if (data.HeatNameLink) {
			options.push({
				name: "See in Data Entry",
				key: "go_to_caster_data_entry",
				style: { fontWeight: "bold" },
				onClick: () => goToDataEntry(data.HeatNameLink),
				icon: <MemoryIcon />,
			});
		}

		if (data.HeatId) {
			options = options.concat([
				{
					name: "See in Heat Table",
					key: "go_to_heat",
					onClick: () => goToHeats(data.HeatId),
					icon: (
						<i
							style={{ fontSize: "1.5em", marginLeft: "-3px" }}
							className="material-icons"
						>
							thermostat
						</i>
					),
				},
			]);
		}
		return options;
	};

	//navigate functions
	const goToDataEntry = (HeatNameLink: string | null) => {
		if (HeatNameLink === null) return;
		navigate(HeatNameLink);
	};

	const goToHeats = (HeatId: number | null) => {
		if (HeatId)
			navigate(`/meltshop-heats/${shiftInfo?.CurrentShiftId}?heatId=${HeatId}`);
	};

	return {
		getMenuOptions,
		goToHeats,
		goToDataEntry,
	};
};
