import { ErrorModal } from "@dexteel/mesf-core";
import { Divider, Paper, Typography, makeStyles } from "@material-ui/core";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { DelaysParetoGraphic } from "./components/DelaysParetoGraphic";
import { Filters } from "./components/filters";
import { useSearchDelaysForPareto } from "./hooks/useSearchDelaysForPareto";
import { getDelayAreas } from "./repositories/DelaysParetoRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: "15px 20px 10px",
		borderRadius: "0.75rem",
		height: "91vh",
		display: "flex",
		flexDirection: "column",
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	filterContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-end",
		marginBottom: theme.spacing(2),
	},
	filterGroup: {
		display: "flex",
		alignItems: "flex-end",
	},
	buttonGroup: {
		display: "flex",
		gap: theme.spacing(2),
	},
	button: {
		whiteSpace: "nowrap",
	},
	divider: {
		margin: `${theme.spacing(2)}px 0`,
		width: "100%",
	},
	tableContainer: {
		width: "100%",
		height: "calc(100% - 20px)",
		padding: "2px",
		"& .ag-root-wrapper": {
			border: "none",
		},
		"& .ag-header": {
			borderTop: "1px solid #e0e0e0",
		},
	},
}));

export const DelaysParetoPage: React.FC = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [filterStartDate, setFilterStartDate] = useState<Moment>(
		moment().startOf("day"),
	);
	const [filterEndDate, setFilterEndDate] = useState<Moment>(
		moment().endOf("day"),
	);
	const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
		null,
	);
	const [delayAreas, setDelayAreas] = useState<
		Array<{ AssetId: number; AssetName: string }>
	>([]);

	const searchDelaysForPareto = useSearchDelaysForPareto({
		setIsLoading,
		setError,
	});

	useEffect(() => {
		fetchDelayAreas();
		searchDelaysForPareto(
			filterStartDate.toDate(),
			filterEndDate.toDate(),
			selectedDelayArea,
		);
	}, []);

	const fetchDelayAreas = async () => {
		const resp = await getDelayAreas();
		if (resp.ok && resp.data.tables.length > 0) {
			setDelayAreas(resp.data.tables[0].rows);
		} else {
			setError("An error occurred while fetching delay areas.");
		}
	};

	const handleFilterChange = (startDate: Moment, endDate: Moment) => {
		setFilterStartDate(startDate);
		setFilterEndDate(endDate);
		searchDelaysForPareto(
			startDate.toDate(),
			endDate.toDate(),
			selectedDelayArea,
		);
	};

	const handleDelayAreaChange = (assetId: number | null) => {
		setSelectedDelayArea(assetId);
		searchDelaysForPareto(
			filterStartDate.toDate(),
			filterEndDate.toDate(),
			assetId,
		);
	};

	return (
		<Paper elevation={1} className={classes.root}>
			<LazyBackDropLoading open={isLoading} />
			<Typography
				variant="h5"
				className={classes.header}
				style={{ fontWeight: 600 }}
			>
				Pareto Report
			</Typography>
			<div className={classes.filterContainer}>
				<div className={classes.filterGroup}>
					<Filters
						onFilterChange={handleFilterChange}
						initialStartDate={filterStartDate}
						initialEndDate={filterEndDate}
						delayAreas={delayAreas}
						selectedDelayArea={selectedDelayArea}
						onDelayAreaChange={handleDelayAreaChange}
					/>
				</div>
			</div>
			<DelaysParetoGraphic />
			<Divider style={{ marginBottom: 10 }} />
			<div className={classes.tableContainer}>
				<ErrorModal
					error={error}
					onHide={() => {
						setError("");
					}}
				/>
			</div>
		</Paper>
	);
};
