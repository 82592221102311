import { ReactNode, createContext, useContext } from "react";
import { useEAFDataEntryActions } from "../hooks/useEAFDataEntryActions";
import { useCountdownTimer } from "../hooks/useTimer";

const TimerContext = createContext<ReturnType<typeof useCountdownTimer>>({
	isRunning: false,
	restart: () => {},
	pause: () => {},
	resume: () => {},
	seconds: 10,
});

export const useTimerContext = () => useContext(TimerContext);

export const TimerProvider = ({ children }: { children: ReactNode }) => {
	const { refreshData } = useEAFDataEntryActions();

	const { seconds, isRunning, pause, resume, restart } = useCountdownTimer({
		initialSeconds: 10,
		onTimeUp: refreshData,
	});
	return (
		<TimerContext.Provider
			value={{ seconds, isRunning, pause, resume, restart }}
		>
			{children}
		</TimerContext.Provider>
	);
};
