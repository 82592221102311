import { Grid } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchShiftsAndCrews } from "../../hooks/useSearchShiftsAndCrews";
import { CrewsFilter } from "./CrewFilter";
import { DelayAreaFilter } from "./DelayAreaFilter";
import { EndFilter } from "./EndFilter";
import { ShiftsFilter } from "./ShiftFilter";
import { StartFilter } from "./StartFilter";

type Props = {
	onFilterChange: (startDate: Date, endDate: Date) => void;
	delayAreas: Array<{ AssetId: number; AssetName: string }>;
	selectedDelayArea: number | null;
	onDelayAreaChange: (assetId: number | null) => void;
	initialStartDate: Date;
	initialEndDate: Date;
	includeMicrodelays: boolean;
	onIncludeMicrodelaysChange: (include: boolean) => void;
	filterCrew: string;
	onFilterCrewChange: (crew: string) => void;
	filterShift: string | null;
	onFilterShiftChange: (shiftId: string) => void;
};

export const Filters: React.FC<Props> = ({
	onFilterChange,
	delayAreas,
	selectedDelayArea,
	onDelayAreaChange,
	initialStartDate,
	initialEndDate,
	includeMicrodelays,
	onIncludeMicrodelaysChange,
	filterCrew,
	onFilterCrewChange,
	onFilterShiftChange,
	filterShift,
}) => {
	const [startDate, setStartDate] = useState<Date>(initialStartDate);
	const [endDate, setEndDate] = useState<Date>(initialEndDate);

	const searchshiftsAndCrews = useSearchShiftsAndCrews();

	useEffect(() => {
		setStartDate(initialStartDate);
		setEndDate(initialEndDate);
	}, [initialStartDate, initialEndDate]);

	const handleStartDateChange = (date: Date | null) => {
		if (date) {
			setStartDate(date);
			onFilterChange(date, endDate);
		}
	};

	const handleEndDateChange = (date: Date | null) => {
		if (date) {
			setEndDate(date);
			onFilterChange(startDate, date);
		}
	};

	useEffect(() => {
		searchshiftsAndCrews();
	}, [startDate, endDate]);

	return (
		<Grid container style={{ alignItems: "center" }} spacing={1}>
			<Grid item xs={12} md={2}>
				<StartFilter value={startDate} onChange={handleStartDateChange} />
			</Grid>
			<Grid item xs={12} md={2}>
				<EndFilter value={endDate} onChange={handleEndDateChange} />
			</Grid>
			<Grid item xs={12} md={2}>
				<DelayAreaFilter
					delayAreas={delayAreas}
					selectedDelayArea={selectedDelayArea}
					onChange={onDelayAreaChange}
				/>
			</Grid>
			<Grid item xs={12} md={1} style={{ marginLeft: "-35px" }}>
				<ShiftsFilter
					filterShift={filterShift as string}
					onFilterShiftChange={onFilterShiftChange}
				/>
			</Grid>
			<Grid item xs={12} md={1} style={{ marginLeft: "-18px" }}>
				<CrewsFilter
					filterCrew={filterCrew as string}
					onFilterCrewChange={onFilterCrewChange}
				/>
			</Grid>
			<Grid item xs={12} md={3} style={{ marginLeft: "-25px" }}>
				<FormControlLabel
					control={
						<Checkbox
							checked={includeMicrodelays}
							onChange={(e) => onIncludeMicrodelaysChange(e.target.checked)}
						/>
					}
					label="Include Microdelays"
					style={{ marginBottom: 0, marginLeft: 1 }}
				/>
			</Grid>
		</Grid>
	);
};
