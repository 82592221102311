import { ErrorModal } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
	CATALOG_RESULT_SOURCE_INITIAL_VALUES,
	CatalogResultSource,
} from "../../../models/CatalogResultSource";
import { upsertCatalogResultSource } from "../../../repositories/CatalogResultSourceRepository";
import { useModalStyles } from "../../../styles/modalStyles";

type Props = {
	show: boolean;
	onHide: () => void;
	refreshData: Function;
};
export const CreateResultSource = ({ show, onHide, refreshData }: Props) => {
	const classes = useModalStyles();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState("");
	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CatalogResultSource>({
		defaultValues: CATALOG_RESULT_SOURCE_INITIAL_VALUES,
	});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<CatalogResultSource> = async (data: any) => {
		setIsSubmitLoading(true);
		const response = await upsertCatalogResultSource(data);
		if (response.ok) {
			const userId = response.data.tables[0].rows[0].Id;
			setIsSubmitLoading(false);
			setOpen(true);
			onHide();
			refreshData();
		} else {
			setError(response.message);
		}
		setIsSubmitLoading(false);
	};
	useEffect(() => {
		if (show) {
			reset();
		}
	}, [show]);
	return (
		<>
			<Grid container>
				<Grid item>
					<Modal
						{...{ show, onHide }}
						size="lg"
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Modal.Header closeButton style={{ padding: "10px 30px" }}>
								<Modal.Title
									id="contained-modal-title-vcenter"
									style={{ margin: "20px 0 0" }}
								>
									NEW RESULT SOURCE
								</Modal.Title>
							</Modal.Header>
							<Modal.Body style={{ padding: "15px 30px" }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={12}>
										<TextField
											{...register("ResultSourceName", { required: true })}
											label="Name"
											variant="outlined"
											error={!!errors.ResultSourceName}
											fullWidth
											margin="dense"
											autoComplete="off"
										/>
									</Grid>
									<Grid item xs={12} md={6}></Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="IsActive"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value}
															onChange={field.onChange}
															name="Enabled"
															color="primary"
														/>
													}
													label="Enabled"
												/>
											)}
										/>
									</Grid>
								</Grid>
							</Modal.Body>
							<Modal.Footer style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={onHide}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</Modal.Footer>
						</form>
					</Modal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The result source was created successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
