import {
	ErrorModal,
	ShiftDayNavigatorControl,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { getCountersByShift } from "../repositories/ProductionRepository";
import { useCasterCountersContext } from "./CasterCountersContext";
import { CasterCountersTable } from "./components/CasterCountersTable";

type Props = {};
export const CasterCountersPage = (props: Props) => {
	const { state, actions } = useCasterCountersContext();
	const [showDeletedProcesses, setShowDeletedProcesses] = useState(false);

	const { shiftInfo } = useShiftNavigator();

	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getCountersByShift(
			shiftInfo?.CurrentShiftId || 0,
			showDeletedProcesses,
		);
		if (resp.ok && resp.data.tables.length > 0) {
			actions.setCounters(resp.data.tables[0].rows);
		} else {
			console.log("something happened 2");
		}
		actions.setIsLoadingGrid(false);
	};

	useEffect(() => {
		(async () => {
			await getDataFromAPI();
		})();
	}, [shiftInfo, showDeletedProcesses]);

	if (!state.isLoadingPage) {
		return (
			<>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "15px 20px 10px",
						borderRadius: "0.75rem",
						height: "93vh",
					}}
				>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										Caster Counters
									</Typography>
								</Grid>
							</Grid>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<ShiftDayNavigatorControl />
								</Grid>
							</Grid>
							<Divider style={{ marginBottom: 10 }} />
							<ErrorModal
								error={state.errorMessage}
								onHide={() => actions.setErrorMessage("")}
							/>
							<NotificationSnackBar
								message={state.notificationMessage}
								onHide={() => actions.setNotificationMessage("")}
							/>
							<CasterCountersTable
								loadingGrid={state.isLoadingGrid}
								rows={state.counters || []}
								showDeletedProcesses={showDeletedProcesses}
							/>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
	return <LazyLoading />;
};
