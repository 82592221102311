import { ListItemIcon, MenuItem } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useStyles } from "./styles/ContextMenuStyles";
import { useContextMenuMESF } from "./useContextMenuMESF";

export const ContextMenu = () => {
	const { show, position, options } = useContextMenuMESF();
	const classes = useStyles();
	const menuRef = useRef<HTMLUListElement>(null);

	const [x, setX] = useState("0");
	const [y, setY] = useState("0");
	useEffect(() => {
		const positionDiv = () => {
			if (show && menuRef.current) {
				const divRect = menuRef.current.getBoundingClientRect();
				const viewportWidth = window.innerWidth;
				const viewportHeight = window.innerHeight;

				let left = position.x;
				let top = position.y;

				if (left + divRect.width > viewportWidth) {
					left = viewportWidth - divRect.width;
				}

				if (top + divRect.height > viewportHeight) {
					top = viewportHeight - divRect.height;
				}

				setX(`${left}px`);
				setY(`${top}px`);
			} else {
				setX("0");
				setY("0");
			}
		};

		positionDiv();
	}, [show, position.x, position.y]);

	if (!show) return null;

	return (
		<ul
			ref={menuRef}
			className={classes.contextMenu}
			style={{
				position: "fixed",
				opacity: show ? 1 : 0,
				left: x,
				top: y,
			}}
		>
			{options.map((option, i) => (
				<MenuItem
					key={option.key + i}
					style={option.style}
					onClick={option.onClick}
					disabled={option.disabled}
				>
					<ListItemIcon style={{ padding: 0, margin: 0 }}>
						{option.icon}
					</ListItemIcon>
					{option.name}
				</MenuItem>
			))}
		</ul>
	);
};
