import * as React from "react";
import { AlertLimitPage } from "./AlertLimitPage";

import { ConfigurationProvider } from "../context/ConfigurationContext";

type Props = {};
const AlertLimit = (props: Props) => {
	return (
		<ConfigurationProvider>
			<AlertLimitPage />
		</ConfigurationProvider>
	);
};
export default AlertLimit;
