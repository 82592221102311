import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { getCatalogMechanicalTests } from "../../repositories/CatalogMechanicalTestRepository";
import { useCatalogMechanicalTestContext } from "./CatalogMechanicalTestContext";
import { TableMechanicalTest } from "./components/TableMechanicalTest";

type Props = {};
export const CatalogMechanicalTestPage = (props: Props) => {
	const { state, actions } = useCatalogMechanicalTestContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");

	const getCatalogMechanicalTestsFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogMechanicalTests();
		if (resp.ok) {
			actions.setMechanicalTests(resp.data.tables[0].rows);
			setIsLoading(false);
		} else {
			setError(resp.message);
		}
	};

	useEffect(() => {
		getCatalogMechanicalTestsFromAPI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (state.mechanicalTests) {
		return (
			<>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0 }}>
						<Paper
							elevation={1}
							style={{
								width: "100%",
								padding: "10px 0 20px",
								borderRadius: "0.75rem",
								height: "auto",
							}}
						>
							<TableMechanicalTest
								isLoading={isLoading}
								getCatalogMechanicalTestsFromAPI={
									getCatalogMechanicalTestsFromAPI
								}
							/>
						</Paper>
					</Grid>
				</Grid>
				<ErrorModal error={error} onHide={() => setError("")} />
			</>
		);
	}
	return <LazyLoading />;
};
