import { ICellRendererParams } from "ag-grid-community";
import { GridButtons } from "../../../../../controls/buttons/GridButtons";
import { CatalogMoldSize } from "../../../models/catalogs/CatalogMoldSize";
import { useCatalogMoldSizeContext } from "../context/CatalogMoldSizeContext";
type Props = {
	setMoldSizeId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	showContextMenu: Function;
};
export const useTableData = ({
	setMoldSizeId,
	setShowEditModal,
	setShowDeleteModal,
	showContextMenu,
}: Props) => {
	const { state } = useCatalogMoldSizeContext();
	const rows: CatalogMoldSize[] =
		state.moldSizes?.map((moldSize: CatalogMoldSize) => ({
			...moldSize,
		})) || [];
	const columnDefs = [
		{
			field: "MoldSizeName",
			headerName: "Name",
			minWidth: 20,
			flex: 2,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			field: "MinSpeedLimit",
			headerName: "Min Speed",
			minWidth: 20,
			flex: 1,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			field: "LbsPerFoot",
			headerName: "Lbs Per Foot",
			minWidth: 20,
			flex: 1,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			field: "Section",
			headerName: "Section",
			minWidth: 20,
			flex: 1,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			cellRenderer: GridButtons,
			cellRendererParams: (params: ICellRendererParams) => ({
				setId: setMoldSizeId,
				id: params.data.MoldSizeId,
				setShowEditModal: setShowEditModal,
				setShowDeleteModal: setShowDeleteModal,
				showContextMenu: () =>
					showContextMenu(event, params.data, "moldSizeContext"),
			}),
			wrapText: false,
			autoHeight: false,
			wrapHeaderText: false,
			headerName: "",
			sortable: false,
			flex: 1,
			minWidth: 330,
			filter: false,
			autoHeaderHeight: true,
		},
	];
	return { rows, columnDefs };
};
