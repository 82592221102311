import { MESApiService } from "@dexteel/mesf-core";

export const getTappedHeats = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[CAS].[GetTappedHeats]", []);
};

type ChangeHeatStatusProps = {
	heatId: number | null;
	status: string;
};

export const changeCasterHeatStatus = async ({
	heatId,
	status,
}: ChangeHeatStatusProps) => {
	const apiService = new MESApiService();

	return await apiService.callV2("[CAS].[ChangeHeatStatusCommand]", [
		{ name: "HeatId", value: heatId },
		{ name: "HeatStatusCode", value: status.charAt(0) },
		{ name: "IsManual", value: true },
	]);
};
