import { Grid } from "@material-ui/core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { SendButtonCellRenderer } from "../../../../../controls/ag-grid/components/ButtonCellRenderer";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { CustomLoadingOverlay } from "../../../../../controls/customLoader/CustomCircularProgress";
import { L3ChemistryModel } from "../../../models/L3ChemistryModel";
import { useGridStyles } from "../../../styles/gridStyles";
import { useTableData } from "./hooks";

type Props = {
	rows: L3ChemistryModel[];
	loadingGrid: boolean;
	OnSend?: Function;
};
export const TableL3Chemistry = ({ rows, loadingGrid, OnSend }: Props) => {
	const [gridApi, setGridApi] = useState<GridApi>();
	const classes = useGridStyles();
	const { columns } = useTableData({});
	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {},
	});
	const send_data = (data: L3ChemistryModel) => {
		if (OnSend) OnSend(data);
	};
	columnTypes["sendButton"] = {
		field: "Send",
		cellRenderer: SendButtonCellRenderer,
		cellRendererParams: {
			clicked: send_data,
		},
		cellClass: "ag-button-cell",
	};

	const onGridReady = (params: GridReadyEvent) => {
		setGridApi(params.api);
	};

	useEffect(() => {
		if (loadingGrid && gridApi) {
			gridApi.showLoadingOverlay();
		} else {
			gridApi?.hideOverlay();
		}
	}, [loadingGrid, gridApi]);
	return (
		<div>
			<Grid container className={classes.root}>
				<Grid
					item
					style={{
						border: "4px solid #ccc",
						borderRadius: 10,
						padding: 10,
						height: "calc(100vh - 300px)",
						minHeight: "calc(100vh - 300px)",
						margin: "20px 0 10px",
					}}
					xs={12}
					md={12}
					className={"ag-theme-balham"}
				>
					<AgGridReact
						onGridReady={onGridReady}
						rowSelection="single"
						pagination={false}
						animateRows={true}
						rowHeight={35}
						headerHeight={35}
						singleClickEdit={true}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={rows}
						columnDefs={columns}
						columnTypes={columnTypes}
						defaultColDef={columnSimpleDefaults}
						loadingOverlayComponent={CustomLoadingOverlay}
					></AgGridReact>
				</Grid>
			</Grid>
		</div>
	);
};
