import { ErrorModal } from "@dexteel/mesf-core";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialogRaw } from "../../../../../controls/modals/ConfirmationDialogRaw";
import { ProcessData } from "../../../models/ProcessData";
import { useCastersContext } from "../../CastersContext";
import { OpenHeatModal } from "../modals/open-heat-modal/OpenHeatModal";
import {
	changeCasterHeatStatus,
	getTappedHeats,
} from "./repositories/HeatSelectorRepository";

interface Props {
	refreshData: Function;
	process: ProcessData;
}
interface Heat {
	HeatId: number;
	HeatName: string;
	HeatStatusCode: string;
	LadleName: string | null;
	TappedTime: Date | null;
}
export const OpenAndCloseButtons = ({ refreshData, process }: Props) => {
	const navigate = useNavigate();
	const { state, actions } = useCastersContext();
	//Loaders
	const [isOpenLoading, setIsOpenLoading] = useState(false);
	const [isUndoOpenLoading, setIsUndoOpenLoading] = useState(false);
	const [isCloseLoading, setIsCloseLoading] = useState(false);
	const [isUndoCloseLoading, setIsUndoCloseLoading] = useState(false);

	const [heats, setHeats] = useState<Heat[]>([]);
	const [newProcessId, setNewProcessId] = useState<number | null>(null);

	const heatStatus = process.HeatStatusCode;
	const isLastProcess = process.NextProcessId === null;
	const nextProcess = process.NextProcessId;
	const maxWidth = "259px";

	//click listeners
	const [openClicked, setOpenClicked] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [undoCloseClicked, setUndoCloseClicked] = useState(false);
	const [undoOpenClicked, setUndoOpenClicked] = useState(false);

	const [openHeatModal, setOpenHeatModal] = useState(false);

	const [error, setError] = useState("");
	//Handlers
	const handleOpen = async () => {
		const resp = await getTappedHeats();
		setIsOpenLoading(true);
		if (resp.ok) {
			const heats = resp.data.tables[0].rows ?? [];
			setHeats(heats);

			if (heats.length === 0) {
				setOpenDialog(true);
			}
			if (heats.length > 1) {
				setOpenHeatModal(true);
			} else if (heats.length === 1) {
				const response = await changeCasterHeatStatus({
					heatId: heats[0].HeatId!,
					status: "O",
				});
				if (response.ok) {
					actions.setNotificationMessage("Heat was opened successfully");
					setNewProcessId(response.data.returnValue);
				} else {
					setError(response.message!);
				}
			}
		}
		setIsOpenLoading(false);
	};
	const handleOpenWithoutHeat = async (buttonClicked?: string) => {
		setOpenDialog(false);
		if (buttonClicked !== "OK") return;
		const response = await changeCasterHeatStatus({
			heatId: null,
			status: "O",
		});
		if (response.ok) {
			actions.setNotificationMessage("Heat was opened successfully");
			setNewProcessId(response.data.returnValue);
		} else {
			setError(response.message);
		}
	};

	const handleClose = async () => {
		setIsCloseLoading(true);
		if (process.HeatId) {
			const resp = await changeCasterHeatStatus({
				heatId: process.HeatId,
				status: "F",
			});
			if (resp.ok) {
				refreshData();
			} else {
				setError(resp.message);
			}
		} else {
			setError("Heat not provided");
		}
		setIsCloseLoading(false);
	};
	const handleUndoOpen = async (buttonClicked?: string) => {
		setUndoOpenClicked(false);
		if (buttonClicked !== "OK") return;

		setIsUndoOpenLoading(true);
		if (process.HeatId) {
			const resp = await changeCasterHeatStatus({
				heatId: process.HeatId,
				status: "T",
			});
			if (resp.ok) {
				(window.location.href = `/caster-data-entry/`), refreshData();
			} else {
				setError(resp.message);
			}
		} else {
			setError("Heat not provided");
		}
		setIsUndoOpenLoading(false);
	};

	const handleUndoClose = async (buttonClicked?: string) => {
		setUndoCloseClicked(false);
		if (buttonClicked !== "OK") return;

		setIsUndoCloseLoading(true);
		if (process.HeatId) {
			const resp = await changeCasterHeatStatus({
				heatId: process.HeatId,
				status: "O",
			});
			if (resp.ok) {
				refreshData();
			} else {
				setError(resp.message);
			}
		} else {
			setError("Heat not provided");
		}
		setIsUndoCloseLoading(false);
	};

	useEffect(() => {
		if (newProcessId) {
			refreshData().then(
				(window.location.href = `/caster-data-entry/${newProcessId}`),
			);
		}
	}, [newProcessId]);

	return (
		<>
			<Grid item container spacing={1} style={{ padding: "3px 0px", maxWidth }}>
				<Grid item md={12} xs={12}>
					{heatStatus === "O" ? (
						<Button
							fullWidth
							color="primary"
							variant="contained"
							style={{
								backgroundColor:
									!isLastProcess || isUndoOpenLoading ? "#dcdcdd" : "#ff7f00",
							}}
							size="small"
							startIcon={isUndoOpenLoading && <CircularProgress size="1rem" />}
							disabled={!isLastProcess || isUndoOpenLoading}
							onClick={() => setUndoOpenClicked(true)}
						>
							Undo Open
						</Button>
					) : (
						<Button
							fullWidth
							variant="contained"
							size="small"
							color="primary"
							startIcon={isOpenLoading && <CircularProgress size="1rem" />}
							disabled={!isLastProcess || heatStatus !== "F" || isOpenLoading}
							onClick={handleOpen}
						>
							Open
						</Button>
					)}
				</Grid>

				<Grid item md={12} xs={12}>
					{heatStatus === "F" ? (
						<Button
							fullWidth
							color="primary"
							variant="contained"
							style={{
								backgroundColor:
									!isLastProcess || isUndoCloseLoading ? "#dcdcdd" : "#ff7f00",
							}}
							size="small"
							startIcon={isUndoCloseLoading && <CircularProgress size="1rem" />}
							disabled={!isLastProcess || isUndoCloseLoading}
							onClick={() => setUndoCloseClicked(true)}
						>
							Undo Close
						</Button>
					) : (
						<Button
							fullWidth
							variant="contained"
							size="small"
							color="primary"
							startIcon={isCloseLoading && <CircularProgress size="1rem" />}
							disabled={!isLastProcess || heatStatus !== "O" || isCloseLoading}
							onClick={handleClose}
						>
							Close
						</Button>
					)}
				</Grid>
			</Grid>
			<ConfirmationDialogRaw
				open={openDialog}
				onClose={handleOpenWithoutHeat}
				title="Warning"
				message="No tapped heat is available. Do you want to create an Unlinked process?"
				key={"open-confirmation"}
			/>
			<ConfirmationDialogRaw
				open={undoOpenClicked}
				onClose={handleUndoOpen}
				title="Warning"
				message="Are you sure you want to undo the open?"
				key={"undo-open-confirmation"}
			/>
			<ConfirmationDialogRaw
				open={undoCloseClicked}
				onClose={handleUndoClose}
				title="Warning"
				message="Are you sure you want to undo the close?"
				key={"undo-close-confirmation"}
			/>
			<OpenHeatModal
				refreshData={refreshData}
				show={openHeatModal}
				heats={heats}
				onHide={() => {
					setOpenClicked(false);
					setOpenHeatModal(false);
				}}
			/>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
