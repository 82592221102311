export const minutesToTime = (minutes: number): string => {
	const hrs = Math.floor(minutes / 60);
	const mins = minutes % 60;
	return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
};

export const validateAndFormatTimeOnBlur = (
	time: string,
): { formattedTime: string; error: boolean; helperText: string } => {
	let formattedTime = "";
	let error = false;
	let helperText = "";

	const timeStripped = time.replace(/[^0-9]/g, "");

	if (timeStripped === "" || /^0+$/.test(timeStripped)) {
		formattedTime = "00:00";
	} else if (isCompleteTime(time)) {
		const [hoursStr, minutesStr] = time.split(":");
		const hours = parseInt(hoursStr, 10);
		const minutes = parseInt(minutesStr, 10);

		if (isNaN(hours) || isNaN(minutes)) {
			error = true;
			helperText = "Invalid time format";
		} else {
			formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
				.toString()
				.padStart(2, "0")}`;
		}
	} else {
		error = true;
		helperText = "Invalid duration";
	}

	return { formattedTime, error, helperText };
};

export const handleValueChange = (val: string, lastVal: string): string => {
	let newVal = val;

	// Insert ':' after two digits
	if (newVal.length === 2 && lastVal.length !== 3 && !newVal.includes(":")) {
		newVal = newVal + ":";
	}

	// Handle backspace at position 2
	if (newVal.length === 2 && lastVal.length === 3 && lastVal[2] === ":") {
		newVal = newVal.slice(0, 1);
	}

	// Limit maximum length
	if (newVal.length > 5) {
		newVal = lastVal;
	}

	return newVal;
};

export const isValidDuration = (val: string): boolean => {
	if (val === "") return true;
	const regex = /^(\d{1,2})(:)?(\d{0,2})?$/;
	return regex.test(val);
};

export const isCompleteTime = (val: string): boolean => {
	const regex = /^\d{1,2}:\d{2}$/;
	return regex.test(val);
};
