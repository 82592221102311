export const assetColors = new Map<string, string>();
export const heatNameToColor = new Map<string, string>();

const colors = ["#dae8fc", "#d5e8d4", "#f8cfcc", "#d17238", "orange", "yellow"];
let colorIndex = 0;

//Heats
export const getColorForHeat = (heatName: string | null) => {
	if (heatName) {
		if (!assetColors.has(heatName)) {
			const firstCharacter = heatName.charAt(0);
			const colorIndex = parseInt(firstCharacter, 10) - 1;

			if (isNaN(colorIndex) || colorIndex < 0 || colorIndex >= colors.length) {
				return "#f8cfcc";
			}

			const color = colors[colorIndex];
			assetColors.set(heatName, color);
		}

		return assetColors.get(heatName)!;
	} else {
		return "#f8cfcc";
	}
};

//Assets
export const getColorForAsset = (assetName: string) => {
	if (!assetColors.has(assetName)) {
		const color = colors[colorIndex % colors.length];
		assetColors.set(assetName, color);
		colorIndex++;
	}
	return assetColors.get(assetName)!;
};
