import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { ConfigurationAssetReducer } from "../reducers/ConfigurationAssetReducer";

const ConfigurationAssetContext = createContext({
	state: ConfigurationAssetReducer.getInitialState(),
	actions: ConfigurationAssetReducer.actions,
});

export const useConfigurationAssetContext = () =>
	useContext(ConfigurationAssetContext);

export const ConfigurationAssetProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: ConfigurationAssetReducer.getInitialState(),
		reducers: ConfigurationAssetReducer.caseReducers,
	});

	return (
		<ConfigurationAssetContext.Provider value={{ state, actions }}>
			{children}
		</ConfigurationAssetContext.Provider>
	);
};
