import React from "react";
import { calculateLevels, getLevelCells } from "../../helpers/table-helper";
import { HeaderCellSetup } from "../../types/table-header";
import { ReportHeaderCellRow } from "./header-cell";

export type ReportHeaderRowProps = {
	cells: HeaderCellSetup[];
	level: number;
	totalLevel: number;
	transposed: boolean;
	autoAlignHeaderNumber: boolean;
};

export const ReportHeaderRow: React.FC<ReportHeaderRowProps> = ({
	cells,
	level,
	totalLevel,
	transposed,
	autoAlignHeaderNumber,
}) => {
	const levelCells = getLevelCells(cells, level, 1);
	const rowContent = levelCells.map((cell, index) => {
		const rowSpan =
			totalLevel - level - calculateLevels(cell.children || []) + 1;
		return (
			<ReportHeaderCellRow
				colSpan={
					(cell.children || []).length === 0 ? 1 : (cell.children || []).length
				}
				rowSpan={rowSpan}
				cell={cell}
				key={`index-${index}`}
				autoAlignNumber={autoAlignHeaderNumber}
			/>
		);
	});
	const emptyCell: HeaderCellSetup = {
		Name: "",
	};
	return (
		<tr>
			{transposed && (
				<ReportHeaderCellRow
					colSpan={totalLevel + 1}
					rowSpan={1}
					cell={emptyCell}
					autoAlignNumber={autoAlignHeaderNumber}
				/>
			)}
			{rowContent}
		</tr>
	);
};
