import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { getCatalogCategories } from "../../pages/delays/categories/repositories/CatalogCategoriesRepository";
import { CatalogCategory } from "../../pages/delays/models/CatalogCategory";

type DelayCategorySelectorProps = {
	value: number | null;
	onChange: (value: number | null) => void;
	disabled?: boolean;
	assetId: number | null;
	error?: string;
};

const useStyles = makeStyles(() => ({
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
}));

export const DelayCategorySelector: React.FC<DelayCategorySelectorProps> = ({
	value,
	onChange,
	disabled = false,
	assetId,
	error = "",
}) => {
	const [categories, setCategories] = useState<CatalogCategory[]>([]);
	const [fetchError, setFetchError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const classes = useStyles();

	useEffect(() => {
		setIsLoading(true);
		getCatalogCategories(assetId, true)
			.then((resp) => {
				if (resp.ok) {
					setCategories(get(resp.data, "tables[0].rows", []));
				} else {
					setFetchError(resp.message);
				}

				setIsLoading(false);
			})
			.catch((error) => {
				setFetchError(error.message);
				setIsLoading(false);
			});
	}, [assetId]);

	return (
		<>
			<Autocomplete
				id="clear-on-escape"
				clearOnEscape
				getOptionSelected={(option, val) =>
					categories.find(
						(category) => category.DelayCategoryId === val.DelayCategoryId,
					) === option
				}
				options={categories ?? []}
				getOptionLabel={(option) => option.DelayCategoryName}
				onChange={(event, newValue) => {
					onChange(newValue ? newValue.DelayCategoryId : null);
				}}
				renderInput={(params) => (
					<>
						<TextField
							{...params}
							label="Category"
							variant="outlined"
							error={!!error}
							size="small"
							fullWidth
							disabled={disabled}
							helperText={error}
							className={classes.inputEditable}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{isLoading ? (
											<CircularProgress color="inherit" size={20} />
										) : (
											params.InputProps.endAdornment
										)}
									</>
								),
							}}
						/>
					</>
				)}
				value={
					categories.find((category) => category.DelayCategoryId === value) ??
					null
				}
			/>
			<ErrorModal error={fetchError} onHide={() => setFetchError("")} />
		</>
	);
};
