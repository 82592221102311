import { Checkbox } from "@material-ui/core";
import { ColDef } from "ag-grid-enterprise";
import { CatalogSampleType } from "../../../models/CatalogSampleType";
import { useCatalogSampleTypeContext } from "../CatalogSampleTypeContext";

type Props = {};
export const useTableData = ({}: Props) => {
	const { state } = useCatalogSampleTypeContext();
	const rows: CatalogSampleType[] =
		state.sampleTypes?.map((sampleType) => ({
			...sampleType,
		})) || [];

	const columns: ColDef[] = [
		{
			field: "SampleTypeName",
			headerName: "Name",
			flex: 1,
			minWidth: 60,
		},
		{
			field: "IsActive",
			headerName: "Enabled",
			cellRenderer: (params: any) => {
				return <Checkbox checked={params.value} disabled={true} />;
			},
			flex: 1,
			minWidth: 60,
		},
		{
			field: "actions",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "editButton"],
			maxWidth: 100,
		},
	];
	const defaultColDef: ColDef = {
		flex: 1,
		autoHeight: true,
		wrapText: true,
		sortable: true,
	};
	return { rows, columns, defaultColDef };
};
