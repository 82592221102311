import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogMaterial } from "../models/CatalogMaterial";

export const getCatalogMaterials = async () => {
	const apiService = new MESApiService();
	return await apiService.callV2("[STEEL].[GetMaterials]", []);
};
export const getCatalogMaterial = async (MaterialId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "MaterialId", value: MaterialId });
	return await apiService.callV2("[STEEL].[GetMaterial]", parameters);
};
export const upsertCatalogMaterial = async (Material: CatalogMaterial) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "MaterialId", value: Material.MaterialId });
	parameters.push({ name: "L1MaterialId", value: Material.L1MaterialId });
	parameters.push({ name: "MaterialName", value: Material.MaterialName });
	parameters.push({ name: "IsAlloy", value: Material.IsAlloy });
	parameters.push({ name: "UseInEAF", value: Material.UseInEAF });
	parameters.push({ name: "UseInLF", value: Material.UseInLF });
	parameters.push({ name: "Enabled", value: Material.Enabled });

	return await apiService.callV2("[STEEL].[UpsertMaterial]", parameters);
};
export const deleteCatalogMaterial = async (MaterialId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "MaterialId", value: MaterialId });

	return await apiService.callV2("[STEEL].[DeleteMaterial]", parameters);
};
