import { Backdrop, CircularProgress } from "@material-ui/core";

export const LazyLoading = () => {
	return <div>Loading Please Wait..</div>;
};

type LazyBackDropLoadingProps = {
	open: boolean;
};
export const LazyBackDropLoading = (props: LazyBackDropLoadingProps) => {
	return (
		<Backdrop open={props.open} style={{ color: "#fff", zIndex: 100000 }}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};
