import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { CatalogMaterial } from "../../../models/CatalogMaterial";
type Props = {
	setMaterialId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	setShowCreateModal: Function;
};
export const useMaterialsOptionsFunctions = ({
	setMaterialId,
	setShowEditModal,
	setShowDeleteModal,
	setShowCreateModal,
}: Props) => {
	const getMenuOptions = (data: CatalogMaterial) => {
		let options: MenuOptionType[] = [];
		if (!data) {
			options = options.concat([
				{
					name: "New Material",
					key: "new_material",
					onClick: () => {
						setShowCreateModal(true);
					},
					icon: <PlaylistAddIcon />,
				},
			]);
		} else {
			options = options.concat([
				{
					name: "New Material",
					key: "new_material",
					onClick: () => {
						setShowCreateModal(true);
					},
					icon: <PlaylistAddIcon />,
				},
				{
					name: "Edit Material",
					key: "edit_material",
					onClick: () => {
						setMaterialId(data.MaterialId);
						setShowEditModal(true);
					},
					icon: <EditIcon />,
				},
				{
					name: "Delete Material",
					key: "delete_material",
					onClick: () => {
						setMaterialId(data.MaterialId);
						setShowDeleteModal(true);
					},
					icon: <DeleteIcon />,
				},
			]);
		}
		return options;
	};
	return {
		getMenuOptions,
	};
};
