import { createSlice } from "@reduxjs/toolkit";
import { CatalogSampleType } from "../../models/CatalogSampleType";

const CatalogSampleTypeInitialState: {
	sampleTypes: CatalogSampleType[] | null;
} = {
	sampleTypes: null,
};

export const CatalogSampleTypeReducer = createSlice({
	name: "__",
	initialState: CatalogSampleTypeInitialState,
	reducers: {
		setSampleTypes(state, { payload }) {
			state.sampleTypes = payload;
		},
	},
});
