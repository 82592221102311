import {
	default as React,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { GenericReportReducer } from "./GenericReportReducer";

const GenericReportContext = createContext({
	state: GenericReportReducer.getInitialState(),
	actions: GenericReportReducer.actions,
});

export const useGenericReportContext = () => useContext(GenericReportContext);

export const GenericReportProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [state, actions] = useComplexState({
		initialState: GenericReportReducer.getInitialState(),
		reducers: GenericReportReducer.caseReducers,
	});
	const urlParameters = useParams();
	const getUrlFilters = (): string[] => {
		const urlFilters: string[] = [];
		for (const [key, value] of Object.entries(urlParameters)) {
			if (value !== undefined) {
				urlFilters.push(value);
			}
		}
		return urlFilters;
	};
	const [reportParameters] = useState(getUrlFilters());

	useEffect(() => {
		// load report parameters
		(async () => {
			actions.setReportParameters(reportParameters);
		})();
	}, [reportParameters]);
	useEffect(() => {
		// load report code
		(async () => {
			if (
				state.reportParameters[0] !== undefined &&
				state.reportParameters[0] !== null &&
				state.reportParameters[0] !== ""
			) {
				actions.setReportCode(state.reportParameters[0]);
			}
		})();
	}, [state.reportParameters]);
	return (
		<GenericReportContext.Provider value={{ state, actions }}>
			{children}
		</GenericReportContext.Provider>
	);
};
