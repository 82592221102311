import {
	MenuOptionType,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { useNavigate } from "react-router-dom";

import MemoryIcon from "@material-ui/icons/Memory";
import { ProcessByShift } from "../../../../eaf/eaf-processes/models/ProcessByShift";

const moment = getMomentTz();

export const useEAFOptionFunctions = () => {
	const { shiftInfo } = useShiftNavigator();
	const navigate = useNavigate();

	//navigate functions
	const goToHeats = (HeatId: string) => {
		if (HeatId)
			navigate(`/meltshop-heats/${shiftInfo?.CurrentShiftId}?heatId=${HeatId}`);
	};

	const goToDataEntry = (ProcessId: number | null) => {
		if (ProcessId === null) return;
		navigate(`/eaf-data-entry/${ProcessId}`);
	};

	//Get Menu options
	const getMenuOptions = (data: ProcessByShift) => {
		let options: MenuOptionType[] = [];

		if (data.ProcessId && data.IsActive) {
			options.push({
				name: "See in Data Entry",
				key: "go_to_eaf",
				onClick: () => {
					//GO TO EAF
					goToDataEntry(data.ProcessId);
				},
				style: { fontWeight: "bold" },

				icon: <MemoryIcon />,
			});
		}
		if (data.HeatId && data.IsActive) {
			options = options.concat([
				{
					name: "See in Heat Table",
					key: "go_to_heat",
					onClick: () => goToHeats(data.HeatId as string),
					icon: (
						<i
							style={{ fontSize: "1.5em", marginLeft: "-3px" }}
							className="material-icons"
						>
							thermostat
						</i>
					),
				},
			]);
		}

		return options;
	};

	return {
		getMenuOptions,
		goToDataEntry,
	};
};
