import { ContextMenuMESFProvider } from "@dexteel/mesf-core";
import { CategoriesProvider } from "../categories/context/CategoriesContext";
import { ConfigurationDelayCodeProvider } from "../codes/context/ConfigurationDelayCodeContext";
import { DelaysReportPage } from "./DelaysReportPage";
import { DelaysReportProvider } from "./context/DelaysReportContext";

type Props = {};
const DelaysReport = (props: Props) => {
	return (
		<ContextMenuMESFProvider>
			<ConfigurationDelayCodeProvider>
				<CategoriesProvider>
					<DelaysReportProvider>
						<DelaysReportPage />
					</DelaysReportProvider>
				</CategoriesProvider>
			</ConfigurationDelayCodeProvider>
		</ContextMenuMESFProvider>
	);
};
export default DelaysReport;
