import { Grid } from "@material-ui/core";
import {
	ColumnApi,
	FirstDataRenderedEvent,
	GridApi,
	GridReadyEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-balham.min.css";
import { useContextMenuMESF, useShiftNavigator } from "@dexteel/mesf-core";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { useGridStyles } from "../../../caster/styles/gridStyles";
import { useCasterDashboardContext } from "../../CasterDashboardContext";
import { useProductionTableData } from "../useProductionTableData";
import { CommentsModal } from "./components/CommentsModal";
import { useOptionsFunctions } from "./hooks/useOptionsFunctions";

export const CasterProductionTable = () => {
	const gridRef = useRef<AgGridReact>(null);
	const { shiftInfo } = useShiftNavigator();
	const {
		state: { casterProductions, isLoadingProductionGrid },
	} = useCasterDashboardContext();
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);
	const [gridColumnAPI, setGridColumnAPI] = useState<ColumnApi | null>(null);
	const [comment, setComment] = useState<string | null>(null);
	const [showCommentModal, setShowCommentModal] = useState(false);

	const classes = useGridStyles();

	const { columnTypes } = useGridDefinitions({
		OnEdit: () => {},
	});

	const { columnData, defaultColDef } = useProductionTableData({
		casterProductions,
		setComment,
		setShowCommentModal,
	});
	const { getMenuOptions, goToDataEntry } = useOptionsFunctions();
	const { showContextMenu, registerConfig } = useContextMenuMESF();

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
		setGridColumnAPI(params.columnApi);
	};
	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		params.columnApi.autoSizeAllColumns(true);
	};
	useMemo(() => {
		if (isLoadingProductionGrid && gridAPI) {
			gridAPI.showLoadingOverlay();
		} else if (gridAPI) {
			gridAPI.hideOverlay();
		}
	}, [isLoadingProductionGrid, gridAPI]);

	useEffect(() => {
		registerConfig({
			id: "casterProductionTable",
			getOptions: getMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);
	return (
		<>
			<Grid container className={classes.root}>
				<Grid
					item
					md={12}
					xs={12}
					style={{
						height: "40vh",
						minHeight: "30vh",
						border: "4px solid #ccc",
						borderRadius: 10,
						padding: 10,
						margin: "20px 0 10px",
					}}
					className={"ag-theme-balham"}
				>
					<AgGridReact
						ref={gridRef}
						rowSelection="single"
						rowHeight={35}
						onFirstDataRendered={onFirstDataRendered}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
						rowData={casterProductions}
						columnDefs={columnData}
						columnTypes={columnTypes}
						suppressContextMenu={true}
						onRowDoubleClicked={(event: any) => {
							goToDataEntry(event.data.ProcessId);
						}}
						onCellContextMenu={(event) => {
							if (event.event)
								showContextMenu(
									event.event as any,
									event.data,
									"casterProductionTable",
								);
						}}
					/>
				</Grid>
			</Grid>
			<CommentsModal
				comments={comment}
				onHide={() => setShowCommentModal(false)}
				open={showCommentModal}
			/>
		</>
	);
};
