import { MESApiService, getMomentTz, spParameter } from "@dexteel/mesf-core";
const moment = getMomentTz();

export const getShiftsAndCrews = async () => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	return await apiService.callV2("[MES].[GetShiftsAndCrews]", parameters);
};

export const getEntries = async (
	Start?: Date | null,
	End?: Date | null,
	SearchText?: string | null,
	Crew?: string | null,
	EntryID?: number | null,
	Shift?: string | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({
		name: "Start",
		value: Start ? moment(Start).utc().startOf("day").toISOString() : null,
	});
	parameters.push({
		name: "End",
		value: End ? moment(End).utc().endOf("day").toISOString() : null,
	});
	parameters.push({ name: "EntryID", value: EntryID as number });
	parameters.push({ name: "Shift", value: Shift as string });
	parameters.push({
		name: "Crew",
		value: (Crew as string) == "All" ? null : (Crew as string),
	});
	parameters.push({
		name: "SearchText",
		value: SearchText === "" ? null : (SearchText as string),
	});

	return await apiService.callV2("[LB].[GetEntries]", parameters);
};

export const getEntry = async (EntryID?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "EntryID", value: EntryID as number });
	return await apiService.callV2("[LB].[GetEntries]", parameters);
};

export const upsertEntry = async (
	EntryID: number | null,
	ShiftId: number,
	Title: string,
	Content: string,
	CreateTimestamp: Date,
	Author: string,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "EntryID", value: EntryID },
		{ name: "ShiftId", value: ShiftId },
		{ name: "Title", value: Title },
		{ name: "Content", value: Content },
		{
			name: "CreateTimestamp",
			value: moment.utc(CreateTimestamp).local().toISOString(),
		},
		{
			name: "UpdateTimestamp",
			value: moment.utc(new Date()).local().toISOString(),
		},
		{ name: "Author", value: Author },
	];

	return await apiService.callV2("[LB].[UpsertEntry]", parameters);
};

export const deleteEntry = async (EntryID: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "EntryID", value: EntryID }];

	return await apiService.callV2("[LB].[DeleteEntry]", parameters);
};
