import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { LetterButton } from "../../../../../controls/buttons/LetterButton";
import { SimpleDialogRaw } from "../../../../../controls/modals/SimpleDialogRaw";
import { formatNumberWithDecimals } from "../../../../../utils/decimals-formatter";
import { isNumeric } from "../../../../../utils/numeric-utils";
import { useCastersContext } from "../../CastersContext";
import { useReadPLC } from "../../hooks/useReadPLC";
import { useSaveStrand } from "../../hooks/useSaveStrand";
import { useStyles } from "../styles";
import { InputFieldTable } from "../ui/FieldTable";

type props = {
	refreshData: Function;
};
export const StrandTable = ({ refreshData }: props) => {
	const classes = useStyles();
	const { state, actions } = useCastersContext();
	const strands = state.strands || [];
	const [showLogModal, setShowLogModal] = useState(false);
	const [fieldEditing, setFieldEditing] = useState({ index: -1, field: "" });
	const strandNames: string[] = [
		"Mold Water T in",
		"Mold Water DT",
		"Billet Temp",
		"Spray Pres N",
		"Spray Pres E",
		"Spray Pres S",
		"Spray Pres W",
		"Speed",
	];
	const strandVariables: string[] = [
		"MWTempStart",
		"MWTempEnd",
		"MWDTStart",
		"MWDTEnd",
		"BilletTempStart",
		"BilletTempEnd",
		"NSprayStart",
		"NSprayEnd",
		"ESprayStart",
		"ESprayEnd",
		"SSprayStart",
		"SSprayEnd",
		"WSprayStart",
		"WSprayEnd",
		"SpeedStart",
		"SpeedEnd",
	];
	const strandFormatters: string[] = [
		"XXX.X",
		"XXX.X",
		"XXX.X",
		"XXX.X",
		"XXXX",
		"XXXX",
		"XX",
		"XX",
		"XX",
		"XX",
		"XX",
		"XX",
		"XX",
		"XX",
		"XX.XX",
		"XX.XX",
	];
	const { save } = useSaveStrand();
	const { readStrand, sucess: readSuccess, error: errorSuccess } = useReadPLC();

	//GetValueFormatt
	const getNumberValue = (
		strandIndex: number,
		field: string,
		value: any,
		format: string,
	) => {
		if (strands === null || strands[strandIndex] === undefined) return "";
		if (fieldEditing.index === strandIndex && fieldEditing.field === field)
			return value;
		return formatNumberWithDecimals(value, format) || "";
	};
	//Events
	const handleOnFocus = (strandIndex: number, field: string) => {
		setFieldEditing({ index: strandIndex, field });
		actions.setFocusField({ entity: "STRAND", index: strandIndex, field });
	};
	const handleOnChange = (strandIndex: number, field: string, value: any) => {
		if (isNaN(Number(value))) return;
		actions.updateStrandData({ index: strandIndex, field, value });
	};
	const handleOnManual = (strandIndex: number) =>
		actions.updateStrandData({
			index: strandIndex,
			field: "IsStrandDataManual",
			value: !strands[strandIndex].IsStrandDataManual,
		});
	const handleOnBlur = (strandIndex: number, field: string, value: any) => {
		if (!isNumeric(value)) return;
		setFieldEditing({ index: -1, field: "" });
		actions.clearFocusField();
		save(strandIndex, field, value);
	};
	const handleOnReadPLC = (strand: number) =>
		readStrand(state.processData?.ProcessId || null, strand);

	useEffect(() => {
		if (readSuccess) {
			actions.setNotificationMessage("PLC was read successfully");
			refreshData();
		}
		if (errorSuccess) {
			actions.setErrorMessage(errorSuccess);
		}
	}, [readSuccess, errorSuccess]);
	return (
		<>
			<SimpleDialogRaw
				title="Log history for Strand values"
				message={""}
				open={showLogModal}
				onClose={() => setShowLogModal(false)}
			/>
			<div style={{ display: "block", position: "relative" }}>
				<table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<td></td>
							{strands?.map((strand, index) => (
								<React.Fragment key={index}>
									<td
										key={index}
										colSpan={2}
										className={classes.simpleCellHeader}
										style={{
											borderTopLeftRadius: index === 0 ? "0.25rem" : "0",
											borderTopRightRadius:
												index === strands.length - 1 ? "0.25rem" : "0",
										}}
									>
										<span style={{ margin: "0 0.8rem" }}>
											{"S" + (index + 1)}
										</span>

										<Button
											aria-label={"Manual"}
											title={"Manual"}
											variant="text"
											size="small"
											onClick={() => handleOnManual(index)}
											className={
												classes.simpleButton +
												" " +
												(strand.IsStrandDataManual ? classes.manualButton : "")
											}
										>
											M
										</Button>
										<Button
											aria-label={"Automatic"}
											title={"Automatic"}
											variant="text"
											size="small"
											onClick={() => handleOnReadPLC(strand.Strand)}
											className={
												classes.simpleButton +
												" " +
												(strand.IsStrandDataManual
													? ""
													: classes.automaticButton)
											}
										>
											A
										</Button>
									</td>
								</React.Fragment>
							))}
						</tr>
						<tr>
							<td style={{ width: "180px" }}></td>
							{strands?.map((strand, index) => (
								<React.Fragment key={index}>
									<td
										className={classes.simpleCellHeader}
										style={{
											width: "100px",
										}}
									>
										Start
									</td>
									<td
										className={classes.simpleCellHeader}
										style={{ width: "100px" }}
									>
										End
									</td>
								</React.Fragment>
							))}
						</tr>
					</thead>
					<tbody>
						{strandNames.map((name, strandIndex) => {
							return (
								<tr key={name}>
									<td className={classes.simpleCellHeader}>{name}</td>
									{strands?.map((strand, index) => (
										<React.Fragment key={index}>
											<td>
												{strandIndex * 2 < strandVariables.length && (
													<InputFieldTable
														classInput={classes.simpleCellNumeric}
														value={getNumberValue(
															index,
															strandVariables[strandIndex * 2],
															(strand as any)[strandVariables[strandIndex * 2]],
															strandFormatters[strandIndex * 2] || "XX.XX",
														)}
														readOnly={!strand.IsStrandDataManual}
														onFocus={() => {
															handleOnFocus(
																index,
																strandVariables[strandIndex * 2],
															);
														}}
														onChange={(value) =>
															handleOnChange(
																index,
																strandVariables[strandIndex * 2],
																value,
															)
														}
														onBlur={(value) =>
															handleOnBlur(
																index,
																strandVariables[strandIndex * 2],
																value,
															)
														}
													/>
												)}
											</td>
											<td>
												{strandIndex * 2 < strandVariables.length && (
													<InputFieldTable
														classInput={classes.simpleCellNumeric}
														value={getNumberValue(
															index,
															strandVariables[strandIndex * 2 + 1],
															(strand as any)[
																strandVariables[strandIndex * 2 + 1]
															],
															strandFormatters[strandIndex * 2 + 1] || "XX.XX",
														)}
														readOnly={!strand.IsStrandDataManual}
														onFocus={() => {
															handleOnFocus(
																index,
																strandVariables[strandIndex * 2 + 1],
															);
														}}
														onChange={(value) =>
															handleOnChange(
																index,
																strandVariables[strandIndex * 2 + 1],
																value,
															)
														}
														onBlur={(value) =>
															handleOnBlur(
																index,
																strandVariables[strandIndex * 2 + 1],
																value,
															)
														}
													/>
												)}
											</td>
										</React.Fragment>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
				<LetterButton
					letter="H"
					color="purple"
					iconButtonClass={classes.iconButton}
					left="50px"
					top="10px"
					height="32px"
					width="32px"
					onClick={() => setShowLogModal(true)}
				/>
			</div>
		</>
	);
};
