import { Button, Grid, makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect } from "react";
import { useLogbookContext } from "../../context/logbookContext";
import { useSearchCrews } from "../../hooks/useSearchCrews";
import { FILTER_INITIAL_VALUES } from "../../models/FilterParametersInitialValue";
import { CrewFilter } from "./crewFilter";
import { DateRangeFilter } from "./dateFilter";
import { SearchFilter } from "./searchFilter";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1),
		height: "auto",
		margin: 0,
	},
	btnExcel: {
		backgroundColor: "#217346",
		color: "white",
		"&:hover": {
			backgroundColor: "#1e6e3e",
		},
	},
	input: {
		"& .MuiInputBase-root": {
			height: "36px",
		},
	},
}));

type Props = {
	searchEntries: Function;
};

export const Filters = ({ searchEntries }: Props) => {
	const classes = useStyles();
	const useSearchEntry = useSearchCrews();

	const {
		state: {
			FilterParameters: { Start, End },
			exportExcel,
		},
		actions: { setFilterStart, setFilterEnd, setExportExcel, setFilter },
	} = useLogbookContext();

	const handleSearch = () => {
		searchEntries();
	};

	const onKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			searchEntries();
		}
	};

	const handleReset = async () => {
		setFilter(FILTER_INITIAL_VALUES);
	};

	useEffect(() => {
		useSearchEntry();
	}, [Start, End]);

	useEffect(() => {
		searchEntries();
	}, []);

	return (
		<Grid
			container
			spacing={2}
			className={classes.container}
			style={{
				paddingBottom: 0,
				paddingRight: "10px",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Grid item xs={12} md={7} style={{ paddingLeft: 0 }}>
				<DateRangeFilter
					startDate={Start}
					endDate={End}
					setStartDate={setFilterStart}
					setEndDate={setFilterEnd}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<SearchFilter onKeyDown={onKeyDown} />
			</Grid>
			<Grid item xs={12} md={2}>
				<CrewFilter />
			</Grid>
			<Grid
				item
				container
				style={{ padding: 0, paddingRight: "10px", marginTop: 10 }}
			>
				<Grid item xs={12} md={6}>
					<Grid item>
						<Button
							variant="contained"
							startIcon={<GetAppIcon />}
							onClick={() => setExportExcel(!exportExcel)}
							className={classes.btnExcel}
						>
							Export Excel
						</Button>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					style={{ justifyContent: "flex-end", marginBottom: "-10px" }}
				>
					<Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
						<Button
							fullWidth
							variant="contained"
							color="default"
							startIcon={<RefreshIcon />}
							onClick={handleReset}
						>
							Reset
						</Button>
					</Grid>
					<Grid item xs={12} md={6}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							startIcon={<SearchIcon />}
							onClick={handleSearch}
						>
							Search
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
