import { createSlice } from "@reduxjs/toolkit";
import { AssetAPI } from "../../models/AssetAPI";
import { DATA_ASSET_INITIAL_VALUES } from "../../models/InitialValueDataAsset";
import { AssetParameters } from "../../models/TreeParameter";

const ConfigurationAssetInitialState: {
	openAssets: { [assetId: number | string]: number | string };

	allAssetNodes: AssetParameters[];

	dataToCreateAsset: AssetAPI;

	allNodesLoading: boolean;

	anchorPoint: any;

	menuContextAssetId: number | undefined;

	showContextMenu: boolean;

	showCreateNewContextMenu: boolean;

	assetTreeId: number | null;

	assetTreeName: string | null;

	assetCodes: any;

	assetNodeSelectedInTree: AssetAPI;
} = {
	openAssets: {},
	allAssetNodes: [],

	allNodesLoading: false,

	anchorPoint: {
		x: 0,
		y: 0,
	},

	dataToCreateAsset: DATA_ASSET_INITIAL_VALUES,

	menuContextAssetId: undefined,

	showContextMenu: false,

	showCreateNewContextMenu: false,

	assetCodes: {},

	assetTreeId: 1,

	assetTreeName: null,

	assetNodeSelectedInTree: DATA_ASSET_INITIAL_VALUES,
};

export const ConfigurationAssetReducer = createSlice({
	name: "__",
	initialState: ConfigurationAssetInitialState,
	reducers: {
		setOpenAssets(state, { payload }) {
			state.openAssets = payload;
		},
		setAllAssetNodes(state, { payload }) {
			state.allAssetNodes = payload;
		},
		seAllNodesLoading(state, { payload }) {
			state.allNodesLoading = payload;
		},
		setAnchorPointX(state, { payload }) {
			state.anchorPoint.x = payload;
		},
		setAnchorPointY(state, { payload }) {
			state.anchorPoint.y = payload;
		},
		setMenuContextAssetId(state, { payload }) {
			state.menuContextAssetId = payload;
		},
		setShowContextMenu(state, { payload }) {
			state.showContextMenu = payload;
		},
		setShowCreateNewContextMenu(state, { payload }) {
			state.showCreateNewContextMenu = payload;
		},
		setDataToCreateAsset(state, { payload }) {
			state.dataToCreateAsset = payload;
		},
		setAssetNameToCreateAsset(state, { payload }) {
			state.dataToCreateAsset.AssetId = payload;
		},
		setAssetParentAssetIdToCreateAsset(state, { payload }) {
			state.dataToCreateAsset.AssetId = payload;
		},
		setAssetTreeId(state, { payload }) {
			state.assetTreeId = payload;
		},
		setAssetTreeName(state, { payload }) {
			state.assetTreeName = payload;
		},
		setAssetCodes(state, { payload }) {
			state.assetCodes = payload;
		},
		setAssetNodeSelectedInTree(state, { payload }) {
			state.assetNodeSelectedInTree = payload;
		},
		setParentAssetNameInNodeSelectedInTree(state, { payload }) {
			state.assetNodeSelectedInTree.AssetId = payload;
		},
	},
});
