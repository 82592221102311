import { createSlice } from "@reduxjs/toolkit";
import { MESFTable } from "../models/AcquisitionStats";

const AcquisitionStatsInitialState: {
	acquisitionStats: MESFTable;
} = {
	acquisitionStats: {
		columns: [],
		rows: [],
	},
};

export const AcquisitionStatsReducer = createSlice({
	name: "__",
	initialState: AcquisitionStatsInitialState,
	reducers: {
		setAcquisitionStats(state, { payload }) {
			state.acquisitionStats = payload;
		},
	},
});
