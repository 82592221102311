import {
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash-es";
import { HTMLProps, useEffect, useMemo, useState } from "react";

export interface HeatsForSeach {
	Id: number;
	Name: string;
	ShiftId: number;
	IsExternal: boolean;
}

interface Props {
	heats: HeatsForSeach[];
	className?: HTMLProps<HTMLElement>["className"];
	onBlur: () => void;
	onFocus?: () => void;
	loading?: boolean;
	onChange: (heat: HeatsForSeach) => void;
	getHeats: (searchTerm: string) => Promise<HeatsForSeach[]>;
}

export const HeatSearch = ({
	heats,
	className,
	onBlur,
	onChange,
	onFocus,
	loading,
	getHeats,
}: Props) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedHeat, setSelectedHeat] = useState<HeatsForSeach | null>(null);
	const [options, setOptions] = useState<HeatsForSeach[]>(heats || []);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const debouncedGetHeats = useMemo(
		() =>
			debounce(async (search: string) => {
				setIsLoading(true);
				const result = await getHeats(search);
				setOptions(result);
				setIsLoading(false);
			}, 300),
		[getHeats],
	);

	useEffect(() => {
		if (searchTerm.length >= 3) {
			debouncedGetHeats(searchTerm);
		} else {
			setOptions(heats || []);
		}
	}, [searchTerm, heats]);

	return (
		<Grid item xs={12}>
			<Autocomplete
				className={className}
				clearOnEscape
				popupIcon={null}
				options={options || []}
				getOptionLabel={(option) => option?.Name || "Unlinked"}
				renderOption={(option, { selected }) => (
					<Typography
						style={{
							color: option.IsExternal ? "blue" : "inherit",
						}}
						title={option.IsExternal ? "External heat" : ""}
					>
						{option.Name || "Unlinked"}
					</Typography>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Search by heat"
						variant="outlined"
						size="medium"
						style={{ width: "100%", minWidth: "180px", maxWidth: "180px" }}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						InputProps={{
							...params.InputProps,
							style: { padding: "3px 10px" },
							endAdornment: (
								<>
									{loading || isLoading ? (
										<CircularProgress color="inherit" size={25} />
									) : (
										params.InputProps.endAdornment
									)}
								</>
							),
						}}
					/>
				)}
				value={selectedHeat}
				onChange={(event, newValue) => {
					if (newValue) {
						setSelectedHeat(newValue);
						onChange(newValue);
					}
				}}
				onFocus={onFocus}
				loading={loading || isLoading}
				onBlur={() => {
					if (searchTerm === "" && selectedHeat) {
						setSelectedHeat(null);
						getHeats("");
					}
					onBlur();
				}}
			/>
		</Grid>
	);
};
