import { makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles((theme) => ({
	root: {
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-group-text ": {
			fontSize: 11,
		},
		"& .ag-header-group-cell-label ": {
			textAlign: "center !important",
			justifyContent: "center",
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .info-limit": {
			color: "#000",
			backgroundColor: "#b2e5ed",
			fontWeight: 600,
		},
	},
	relative: {
		position: "relative",
	},
	buttons: {
		opacity: 0,
		transition: "opacity 0.1s ease-in-out",
		display: "flex",
		width: "100%",
		justifyContent: "space-around",
		".ag-row-hover &": {
			opacity: 1,
		},
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
}));
