import { ITooltipParams } from "ag-grid-enterprise";
import React, { useMemo } from "react";
export default (props: ITooltipParams & { color: string }) => {
	const data = useMemo(
		() => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data,
		[],
	);
	if (data.Comments.length === 0) return <></>;
	return (
		<div
			className="custom-tooltip"
			style={{ backgroundColor: props.color || "white" }}
		>
			<p>
				<span>Comments: </span> {data.Comments}
			</p>
		</div>
	);
};
