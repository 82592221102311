import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../../controls/LazyLoading";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import {
	CATALOG_MOLD_INITIAL_VALUES,
	CatalogMold,
} from "../../../../models/catalogs/CatalogMold";
import { CatalogMoldSize } from "../../../../models/catalogs/CatalogMoldSize";
import {
	deleteCatalogMold,
	getCatalogMold,
} from "../../../../repositories/CatalogMoldRepository";
import { useModalStyles } from "../../../../styles/modalStyles";
import { useCatalogMoldSizeContext } from "../../../mold-sizes/context/CatalogMoldSizeContext";
type Props = {
	moldId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};
export const DeleteMold = ({ moldId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const {
		state: { moldSizes },
	} = useCatalogMoldSizeContext();
	const { control, setValue, handleSubmit } = useForm<CatalogMold>({
		defaultValues: CATALOG_MOLD_INITIAL_VALUES,
	});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<CatalogMold> = async () => {
		setIsSubmitLoading(true);
		const deleteMoldResp = await deleteCatalogMold(moldId);
		if (deleteMoldResp.ok) {
			onHide(true);
			setOpen(true);
			setIsSubmitLoading(false);
		} else {
			setError(deleteMoldResp.message);
			setIsSubmitLoading(false);
		}
	};
	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const response = await getCatalogMold(moldId);
				if (response.ok) {
					const mold = response.data.tables[0].rows[0];
					if (mold) {
						setValue("MoldId", mold.MoldId);
						setValue("MoldName", mold.MoldName);
						setValue("MoldSizeId", mold.MoldSizeId);
					}
				} else {
					setError(response.message);
					return;
				}
				setIsLoading(false);
			})();
		}
	}, [show]);
	return (
		<>
			<MesfModal
				title="DELETE MOLD"
				open={show}
				handleClose={() => onHide(false)}
				id="delete-mold-modal"
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						)}
						{!isLoading && (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12} md={8}>
										<Controller
											name="MoldName"
											control={control}
											rules={{ required: "Name is required" }}
											render={({ field }) => (
												<TextField
													{...field}
													label="Name"
													variant="outlined"
													error={!!error}
													value={field.value}
													fullWidth
													margin="dense"
													autoComplete="off"
													disabled
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} md={4} style={{ marginTop: 8 }}>
										<Controller
											name="MoldSizeId"
											control={control}
											rules={{ required: "Mold Size is required" }}
											render={({ field: { onChange, value, ...rest } }) => (
												<Autocomplete
													id="clear-on-escape"
													clearOnEscape
													disabled
													options={moldSizes ?? []}
													getOptionLabel={(option) => option.MoldSizeName}
													onChange={(event, newValue) => {
														onChange(newValue ? newValue.MoldSizeId : null);
													}}
													renderInput={(params) => (
														<>
															<TextField
																{...params}
																label="Mold Size"
																variant="outlined"
																error={!!error}
																value={value}
																size="small"
																fullWidth
															/>
														</>
													)}
													value={
														moldSizes?.find(
															(moldSize: CatalogMoldSize) =>
																moldSize.MoldSizeId === value,
														) || null
													}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<FooterModalButtons
							isSubmitLoading={isSubmitLoading}
							onHide={onHide}
							buttonColor="secondary"
							buttonLabel="Delete"
						/>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The mold was deleted successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Deleting Mold"
			/>
		</>
	);
};
