import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	getMomentTz,
	useUTLSettingsContext,
} from "@dexteel/mesf-core";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CasterPourbackReason } from "../../../../models/Catalogs";
import { ProcessData } from "../../../../models/ProcessData";
import { upsertPourback } from "../../../../repositories/ProductionRepository";
import { useCastersContext } from "../../../CastersContext";

interface Props {
	show: boolean;
	onHide: (shouldUpdate?: boolean) => void;
	process: ProcessData;
}
interface FormData {
	pourbackWeight: number | null;
	selectedReason: CasterPourbackReason | null;
}
const moment = getMomentTz();

export const PourbackModal = ({ show, onHide, process }: Props) => {
	const { state, actions } = useCastersContext();
	const {
		state: { settings },
	} = useUTLSettingsContext();

	const MIN_POURBACK_WEIGHT = get(settings, "MinPourbackWeight", 0);
	const MAX_POURBACK_WEIGHT = get(settings, "MaxPourbackWeight", 200);

	const pourBacks = state.pourBacks || [];

	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
		reset,
	} = useForm<FormData>({
		mode: "onChange",
		defaultValues: {
			pourbackWeight: null,
			selectedReason: null,
		},
	});

	const onSubmit = async (data: FormData) => {
		setIsLoading(true);
		if (data.pourbackWeight !== null && data.selectedReason) {
			const resp = await upsertPourback(
				process.PourbackId,
				process.HeatId!, //Always available because the modal is only shown when the process is linked to a heat
				process.ProcessId,
				data.pourbackWeight,
				data.selectedReason.PourbackReasonId,
				process.LadleOpen || moment.utc().toDate(),
			);
			if (resp.ok) {
				actions.setRefreshInPause(false);
				onHide(true);
			} else setError(resp.message);
		} else {
			setError("Please fill all the fields");
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (show) {
			const reason = pourBacks.find(
				(pb) => pb.PourbackReasonId === process.PourbackReasonId,
			);
			reset({
				pourbackWeight: process.PourbackWeight,
				selectedReason: reason || null,
			});
		}
	}, [show, process, pourBacks, reset]);

	return (
		<>
			<MesfModal
				open={show}
				handleClose={onHide}
				title="Pourback Declaration"
				maxWidth="md"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<Controller
									name="pourbackWeight"
									control={control}
									rules={{
										required: "Pourback Weight is required",
										min: {
											value: MIN_POURBACK_WEIGHT,
											message: `Minimum Weight is ${MIN_POURBACK_WEIGHT}`,
										},
										max: {
											value: MAX_POURBACK_WEIGHT,
											message: `Maximum Weight is ${MAX_POURBACK_WEIGHT}`,
										},
									}}
									render={({ field }) => (
										<TextField
											{...field}
											type="number"
											label="Pourback Weight [tons]"
											variant="outlined"
											size="small"
											error={!!errors.pourbackWeight}
											helperText={errors.pourbackWeight?.message}
											onFocus={(e) => e.target.select()}
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={8}>
								<Controller
									name="selectedReason"
									control={control}
									rules={{ required: "Pourback Reason is required" }}
									render={({ field }) => (
										<Autocomplete
											{...field}
											id="pourback-reason-selector"
											options={pourBacks}
											getOptionLabel={(pbReason) => pbReason.PourbackReasonName}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Pourback Reason"
													variant="outlined"
													error={!!errors.selectedReason}
													helperText={errors.selectedReason?.message}
												/>
											)}
											onChange={(_, value) => field.onChange(value)}
											style={{ minWidth: 200 }}
											size="small"
										/>
									)}
								/>
							</Grid>
						</Grid>
					</MesfModal.Content>

					<MesfModal.Actions>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item md={3} xs={3}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => {
										actions.setRefreshInPause(false);
										onHide();
									}}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item md={3} xs={3}>
								<ButtonWithLoading
									isLoading={isLoading}
									fullWidth
									variant="contained"
									color="primary"
									type="submit"
								>
									Save
								</ButtonWithLoading>
							</Grid>
						</Grid>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
