import { createSlice } from "@reduxjs/toolkit";
import { ItemOption } from "../../../controls/autoComplete";
import { HeatReport } from "../models/HeatReport";
import { ResultSearchHeatName } from "../models/ResultSearchHeatName";
const HeatReportInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	errorMessage: string;
	notificationMessage: string;

	searchHeatName: string | null;
	heatResults: ResultSearchHeatName[];
	heatOptions: ItemOption[];
	selectedHeat: ResultSearchHeatName | null;
	selectedOption: ItemOption | null;

	heatReport: HeatReport | null;
	chemistryHeatReport: HeatReport | null;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	errorMessage: "",
	notificationMessage: "",
	selectedHeat: null,
	selectedOption: null,

	searchHeatName: null,
	heatResults: [],
	heatOptions: [],

	heatReport: null,
	chemistryHeatReport: null,
};

export const HeatReportReducer = createSlice({
	name: "__",
	initialState: HeatReportInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},

		setHeatResults(state, { payload }) {
			state.heatResults = payload;
		},
		setHeatReport(state, { payload }) {
			state.heatReport = payload;
		},
		setChemistryHeatReport(state, { payload }) {
			state.chemistryHeatReport = payload;
		},
		setSelectedHeat(state, { payload }) {
			state.selectedHeat = payload;
		},
		setSearchHeatName(state, { payload }) {
			state.searchHeatName = payload;
		},
		setHeatItems(state, { payload }) {
			state.heatOptions = payload;
		},
		setSelectedOption(state, { payload }) {
			state.selectedOption = payload;
		},
		nextHeat(state) {
			if (
				state.selectedHeat !== null &&
				state.selectedHeat.NextHeatName !== null
			) {
				state.searchHeatName = state.selectedHeat.NextHeatName;
			}
		},
		previousHeat(state) {
			if (
				state.selectedHeat !== null &&
				state.selectedHeat.PreviousHeatName !== null
			) {
				state.searchHeatName = state.selectedHeat.PreviousHeatName;
			}
		},
	},
});
