import * as React from "react";
import { CatalogSectionProvider } from "./CatalogSectionContext";
import { CatalogSectionPage } from "./CatalogSectionPage";

type Props = {};
const CatalogSection = (props: Props) => {
	return (
		<CatalogSectionProvider>
			<CatalogSectionPage />
		</CatalogSectionProvider>
	);
};
export default CatalogSection;
