import { Grid, Typography } from "@material-ui/core";
import {
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import React, { useEffect, useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useContextMenuMESF } from "@dexteel/mesf-core";
import { AgGridReact } from "ag-grid-react";
import { FooterButton } from "../../../../../controls/buttons/FooterButton";
import { useGridStyles } from "../../../styles/gridStyles";
import { useMoldSizeOptionsFunctions } from "../hooks/useMoldSizeOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { CreateMoldSize, DeleteMoldSize, EditMoldSize } from "./modals";
type Props = {
	getCatalogMoldSizesFromAPI: Function;
	isLoading: boolean;
};
export const TableMoldSize = ({
	getCatalogMoldSizesFromAPI,
	isLoading,
}: Props) => {
	const classes = useGridStyles();
	const [selectedRowId, setSelectedRowId] = useState("");
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [moldSizeId, setMoldSizeId] = useState<number | null>(null);
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const { getMenuOptions } = useMoldSizeOptionsFunctions({
		setMoldSizeId,
		setShowEditModal,
		setShowDeleteModal,
		setShowCreateModal,
	});
	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { rows, columnDefs } = useTableData({
		setMoldSizeId,
		setShowEditModal,
		setShowDeleteModal,
		showContextMenu,
	});
	const { columnDefaults } = useGridDefinitions({
		OnEdit: (data: any) => {
			setShowEditModal(!showEditModal);
			setMoldSizeId(data.MoldSizeId);
		},
	});
	columnDefaults.floatingFilter = false;
	const onRowDoubleClicked = (event: any) => {
		setMoldSizeId(event.data.MoldSizeId);
		setShowEditModal(true);
	};
	const getContextMenuItems = React.useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data = params.node?.data;
			showContextMenu(event as any, data, "moldSizeContext");
			if (selectedRowId) {
				const previousRow = document.querySelector(
					`.ag-row[row-id="${selectedRowId}"]`,
				);
				if (previousRow) previousRow.classList.remove("custom-selected");
			}
			if (params && params.node && params.node.id) {
				setSelectedRowId(params.node.id);
				const currentRow = document.querySelector(
					`.ag-row[row-id="${params.node.id}"]`,
				);
				if (currentRow) currentRow.classList.add("custom-selected");
			}
			return [];
		},
		[rows],
	);
	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else {
			gridApi?.hideOverlay();
		}
	}, [isLoading, rows, gridApi]);
	useEffect(() => {
		registerConfig({
			id: "moldSizeContext",
			getOptions: getMenuOptions,
		});
	}, []);
	return (
		<Grid container justifyContent="center">
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Mold Sizes
				</Typography>
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
				style={{ width: "100%" }}
			>
				<Grid
					item
					md={12}
					xs={12}
					style={{ padding: "0 15px" }}
					className={classes.relative}
				>
					<CreateMoldSize
						show={showCreateModal}
						onHide={(moldSizeCreated) => {
							setShowCreateModal(false);
							setTimeout(() => {
								if (gridApi && moldSizeCreated) {
									gridApi.paginationGoToLastPage();
									getCatalogMoldSizesFromAPI();
								}
							}, 100);
						}}
					/>
					<EditMoldSize
						show={showEditModal}
						onHide={(updated: boolean) => {
							setShowEditModal(false);
							setMoldSizeId(null);
							if (updated) getCatalogMoldSizesFromAPI();
						}}
						moldSizeId={moldSizeId}
					/>
					<DeleteMoldSize
						show={showDeleteModal}
						onHide={(updated: boolean) => {
							setShowDeleteModal(false);
							setMoldSizeId(null);
							if (updated) getCatalogMoldSizesFromAPI();
						}}
						moldSizeId={moldSizeId}
					/>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							height: "61vh",
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							margin: "20px 0 10px",
						}}
					>
						<div
							style={{ height: "100%", width: "100%" }}
							className="ag-theme-alpine"
						>
							<AgGridReact
								rowData={rows || []}
								columnDefs={columnDefs}
								defaultColDef={columnDefaults}
								rowHeight={34}
								headerHeight={34}
								animateRows={true}
								pagination={true}
								rowSelection="single"
								paginationPageSize={15}
								getContextMenuItems={getContextMenuItems}
								onRowDoubleClicked={onRowDoubleClicked}
								onGridReady={(params) => setGridApi(params.api)}
							/>
						</div>
					</Grid>
					<FooterButton
						label="MOLD SIZE"
						showCreateModal={showCreateModal}
						setShowCreateModal={setShowCreateModal}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
