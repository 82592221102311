export const isTimestampBetweenStartAndEnd = (
	timestamp: Date,
	start: Date,
	end: Date | null,
): boolean => {
	if (!start || !timestamp) return false;

	if (!end) {
		return timestamp.getTime() >= start.getTime();
	}

	// If “end” is less than “start”, add one day to “end”.
	if (end.getTime() < start.getTime()) {
		end = new Date(end.getTime() + 24 * 60 * 60 * 1000); // add 24 hours
	}
	return (
		timestamp.getTime() >= start.getTime() &&
		timestamp.getTime() <= end.getTime()
	);
};
