import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { CatalogMold } from "../../../models/catalogs/CatalogMold";
type Props = {
	setMoldId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	setShowCreateModal: Function;
};
export const useMoldOptionsFunctions = ({
	setMoldId,
	setShowEditModal,
	setShowDeleteModal,
	setShowCreateModal,
}: Props) => {
	const getMenuOptions = (data: CatalogMold) => {
		let options: MenuOptionType[] = [];
		options = options.concat([
			{
				name: "New Mold",
				key: "new_mold",
				onClick: () => {
					setShowCreateModal(true);
				},
				icon: <PlaylistAddIcon />,
			},
		]);
		if (data) {
			options = options.concat([
				{
					name: "Edit Mold",
					key: "edit_mold",
					onClick: () => {
						setMoldId(data.MoldId);
						setShowEditModal(true);
					},
					icon: <EditIcon />,
				},
				{
					name: "Delete Mold",
					key: "delete_mold",
					onClick: () => {
						setMoldId(data.MoldId);
						setShowDeleteModal(true);
					},
					icon: <DeleteIcon />,
				},
			]);
		}
		return options;
	};
	return {
		getMenuOptions,
	};
};
