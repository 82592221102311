import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useDataEntryContext } from "../../../context/reducer.provider";
import {
	handleValueChange,
	isValidDuration,
	minutesToTime,
	validateAndFormatTimeOnBlur,
} from "../../../utils/durationHelpers";

interface DurationInputProps {
	initMinutes?: number;
	disabled?: boolean;
	mountFocus?: boolean;
	label?: string;
	onTimeChange: (time: string) => void;
	type?: string;
	onFocusHandler?: React.FocusEventHandler<HTMLInputElement>;
	placeholder?: string;
	className?: string;
	name?: string;
	onBlurHandler?: React.FocusEventHandler<HTMLInputElement>;
}

const DurationInput: React.FC<DurationInputProps> = ({
	initMinutes = 0,
	disabled = false,
	mountFocus = false,
	onTimeChange,
	type,
	label,
	onFocusHandler,
	placeholder = " ",
	className,
	name,
	onBlurHandler,
}) => {
	const [time, setTime] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const [helperText, setHelperText] = useState<string>("");
	const _input = useRef<HTMLInputElement>(null);
	const [lastVal, setLastVal] = useState<string>("");
	const {
		state: { isSubmitLoading },
	} = useDataEntryContext();

	// Initialize time when component mounts or initMinutes changes
	useEffect(() => {
		const initialTime = minutesToTime(initMinutes);
		setTime(initialTime);
	}, [initMinutes]);

	useEffect(() => {
		if (!disabled && mountFocus) {
			setTimeout(() => {
				_input.current?.focus();
			}, 0);
		}
	}, [disabled, mountFocus]);

	const onChangeHandler = (val: string) => {
		if (val === time) {
			return;
		}
		if (isValidDuration(val)) {
			const newVal = handleValueChange(val, lastVal);
			setLastVal(newVal);
			setTime(newVal);
			setError(false);
			setHelperText("");
		}
	};

	const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const { formattedTime, error, helperText } =
			validateAndFormatTimeOnBlur(time);

		setTime(formattedTime || time);
		setError(error);
		setHelperText(helperText);

		if (!error && formattedTime) {
			onTimeChange(formattedTime);
		}

		if (onBlurHandler) {
			onBlurHandler(e);
		}
	};

	const getType = () => {
		return type || "tel";
	};

	return (
		<TextField
			name={name}
			label={label}
			className={className}
			type={getType()}
			disabled={disabled || isSubmitLoading}
			placeholder={placeholder}
			value={time}
			onChange={(e) => onChangeHandler(e.target.value)}
			onFocus={onFocusHandler}
			onBlur={onBlur}
			inputRef={_input}
			variant="outlined"
			fullWidth
			error={error}
			helperText={helperText}
		/>
	);
};

export default DurationInput;
