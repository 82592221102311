import { createSlice } from "@reduxjs/toolkit";
import { ItemOption } from "../../../controls/autoComplete";
import { HeatResult } from "../models/Catalogs";
const HeatReconciliationInitialState: {
	searchHeatName: string | null;
	showLoading: boolean;
	heatOptions: ItemOption[];
	selectedOption: ItemOption | null;
	selectedHeat: HeatResult | null;
	heatResults: HeatResult[];
	error: any | undefined;
	success: any | undefined;
} = {
	searchHeatName: null,
	showLoading: false,
	heatOptions: [],
	selectedHeat: null,
	selectedOption: null,
	heatResults: [],
	error: undefined,
	success: undefined,
};

export const HeatReconciliationReducer = createSlice({
	name: "__",
	initialState: HeatReconciliationInitialState,
	reducers: {
		setError(state, { payload }) {
			state.error = payload;
		},
		setSuccess(state, { payload }) {
			state.success = payload;
		},
		setHeatResults(state, { payload }) {
			state.heatResults = payload;
		},
		setShowLoading(state, { payload }) {
			state.showLoading = payload;
		},
		setHeatItems(state, { payload }) {
			state.heatOptions = payload;
		},
		setSearchHeatName(state, { payload }) {
			state.searchHeatName = payload;
		},
		setSelectedHeat(state, { payload }) {
			state.selectedHeat = payload;
		},
		setSelectedOption(state, { payload }) {
			state.selectedOption = payload;
		},
		nextHeat(state) {
			if (
				state.selectedHeat !== null &&
				state.selectedHeat.NextHeatName !== null
			) {
				state.searchHeatName = state.selectedHeat.NextHeatName;
			}
		},
		previousHeat(state) {
			if (
				state.selectedHeat !== null &&
				state.selectedHeat.PreviousHeatName !== null
			) {
				state.searchHeatName = state.selectedHeat.PreviousHeatName;
			}
		},
	},
});
