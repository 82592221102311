import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
} from "@material-ui/core";
import { useLogbookContext } from "../../context/logbookContext";

export const CrewFilter = () => {
	const {
		state: {
			CrewList,
			FilterParameters: { Crew },
		},
		actions: { setFilterCrewt },
	} = useLogbookContext();

	const handleFilter: SelectProps["onChange"] = (e) => {
		setFilterCrewt(e.target.value as string);
	};

	return (
		<FormControl
			variant="outlined"
			margin="dense"
			fullWidth
			style={{ paddingBottom: 0 }}
		>
			<InputLabel>Crews</InputLabel>
			<Select
				value={Crew}
				onChange={handleFilter}
				label="Crews"
				labelId="crew-simple-select-label"
				style={{
					height: "40px",
					paddingTop: 10,
					marginBottom: 4,
					overflow: "hidden",
				}}
			>
				{CrewList.length > 0 ? (
					CrewList.map((crew: any) => (
						<MenuItem key={`crew-${crew.Crew}`} value={crew.Crew}>
							<Grid container alignItems="center">
								{crew.Crew}
							</Grid>
						</MenuItem>
					))
				) : (
					<MenuItem value="All">All</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};
