import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Process } from "../../../models/eaf.models";
import { NumberWithCheckboxFieldDE } from "../common/NumberWithCheckboxFieldDE";

export type NumberFields<T> = {
	[K in keyof T]: T[K] extends number | null ? K : never;
}[keyof T];

export const ProcessDataNumberWithCheckboxField = ({
	numberField,
	checkboxField,
	label,
	textFieldDisabled,
	checkboxDisabled,
}: {
	numberField: NumberFields<Process>;
	checkboxField: NumberFields<Process>;
	label?: string;
	textFieldDisabled?: boolean;
	checkboxDisabled?: boolean;
}) => {
	const {
		state: { deProcess },
		actions,
	} = useDataEntryContext();

	const { saveProcessData } = useEAFDataEntryActions();
	const process = deProcess?.process;

	const onTextValueChange = () => {
		saveProcessData({
			processId: process?.ProcessId!,
			field: numberField,
			value: process![numberField],
		});
	};

	return (
		<NumberWithCheckboxFieldDE
			label={label || numberField}
			textFieldValue={process ? process[numberField] : null}
			checkboxValue={process ? !!process[checkboxField] || null : null}
			textFieldDisabled={textFieldDisabled}
			checkboxDisabled={checkboxDisabled}
			onNumberFieldChange={(value) => {
				actions.setProcess({
					...process,
					[numberField]: value,
				});
			}}
			onCheckboxChange={(value) => {
				actions.setProcess({
					...process,
					[checkboxField]: value ? 1 : null,
				});
				saveProcessData({
					processId: process?.ProcessId!,
					field: checkboxField,
					value: value ? 1 : null,
				});
			}}
			onBlur={() => {
				onTextValueChange();
			}}
		/>
	);
};
