import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogElementReducer } from "./CatalogElementReducer";

const CatalogElementContext = createContext({
	state: CatalogElementReducer.getInitialState(),
	actions: CatalogElementReducer.actions,
});

export const useCatalogElementContext = () => useContext(CatalogElementContext);

export const CatalogElementProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogElementReducer.getInitialState(),
		reducers: CatalogElementReducer.caseReducers,
	});
	return (
		<CatalogElementContext.Provider value={{ state, actions }}>
			{children}
		</CatalogElementContext.Provider>
	);
};
