import { MESApiService, getMomentTz, spParameter } from "@dexteel/mesf-core";
import { GradesResponse, LadlesResponse } from "../models/catalogs.models";
import { ProcessResponse } from "../models/eaf.models";
import { PourbackResponse } from "../models/ms.models";

const moment = getMomentTz();

export const getEAFProcess = async ({
	ProcessId = null,
}: { ProcessId: number | null }) => {
	const apiService = new MESApiService();

	const resp = await apiService.callV2("[EAF].[DEGetProcess]", [
		{ name: "ProcessId", value: ProcessId },
	]);

	if (resp.ok) {
		const data = resp.data as ProcessResponse;

		const process = data.tables[0].rows[0];
		data.tables[0].rows[0] = {
			...data.tables[0].rows[0],
			Start: moment.utc(process["Start"]).toDate(),
			End: process.End ? moment.utc(process["End"]).toDate() : null,
			StartTap: process.StartTap
				? moment.utc(process["StartTap"]).toDate()
				: null,
			ProductionDate: process.ProductionDate
				? moment.utc(process["ProductionDate"]).toDate()
				: null,
			TrimProbeTime: process.TrimProbeTime
				? moment.utc(process["TrimProbeTime"]).toDate()
				: null,
		};
		data.tables[1].rows = data.tables[1].rows.map((row) => {
			row.Timestamp = moment.utc(row["Timestamp"]).toDate();
			return row;
		});
		data.tables[2].rows = data.tables[2].rows.map((heatingRow) => {
			heatingRow.Start = moment.utc(heatingRow["Start"]).toDate();
			heatingRow.End = moment.utc(heatingRow["End"]).toDate();
			return heatingRow;
		});
		data.tables[3].rows = data.tables[3].rows.map((row) => {
			row.Timestamp = moment.utc(row["Timestamp"]).toDate();
			return row;
		});
		data.tables[4].rows = data.tables[4].rows.map((row) => {
			row.Timestamp = moment.utc(row["Timestamp"]).toDate();
			return row;
		});
		return {
			ok: true,
			data,
		};
	} else {
		return {
			ok: false,
			message: resp.message,
		};
	}
};

export const getLadles = async () => {
	const apiService = new MESApiService();

	const resp = await apiService.callV2("[MS].[GetLadles]", []);
	if (resp.ok) {
		const data = resp.data as LadlesResponse;
		return {
			ok: true,
			data: data.tables[0].rows,
		};
	} else {
		return {
			ok: false,
			message: resp.message!,
		};
	}
};

export const getGrades = async () => {
	const apiService = new MESApiService();

	const resp = await apiService.callV2("[STEEL].[GetGrades]", []);
	if (resp.ok) {
		const data = resp.data as GradesResponse;
		return {
			ok: true,
			data: data.tables[0].rows,
		};
	} else {
		return {
			ok: false,
			message: resp.message!,
		};
	}
};

export type UpsertProcessParams = {
	ProcessId: number | null;
	Start: string;
	End: string | null;
	AssetId: number | null;
};
export const upsertProcess = async ({
	ProcessId,
	Start,
	End,
	AssetId,
}: UpsertProcessParams) => {
	const apiService = new MESApiService();

	const parameters = [
		{ name: "ProcessId", value: ProcessId },
		{ name: "Start", value: Start },
		{ name: "End", value: End },
		{ name: "IsProcessManual", value: true },
		{ name: "AssetId", value: AssetId },
	];

	return await apiService.callV2("[EAF].[UpsertProcess]", parameters);
};

type UpsertProcessDataParams = {
	processId: number;
	field: string;
	value: boolean | string | number | Date | null;
};

export const upsertProcessData = async ({
	processId,
	field,
	value,
}: UpsertProcessDataParams) => {
	if (value instanceof Date) {
		value = value.toISOString();
	}
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "ProcessId", value: processId },
		{ name: field, value: value },
		{ name: "IsManual", value: true },
	];

	return await apiService.callV2("[EAF].[UpdateProcessData]", parameters);
};

type UpsertTrimParams = {
	processId: number;
	TrimProbeTime: Date;
	TrimProbeTemperature: number | null;
};
export const upsertTrim = async ({
	processId,
	TrimProbeTime,
	TrimProbeTemperature,
}: UpsertTrimParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "ProcessId", value: processId },
		{ name: "TrimProbeTime", value: TrimProbeTime.toISOString() },
		{ name: "TrimProbeTemperature", value: TrimProbeTemperature },
		{ name: "IsManual", value: true },
	];

	return await apiService.callV2("[EAF].[UpdateProcessData]", parameters);
};

type UpsertChargeParams = {
	ChargeId: number | null;
	ProcessId: number;
	Timestamp: string;
	Charge: number;
	ChargeCode: string;
	BucketId: number;
	Weight: number;
};

export const upsertCharge = async ({
	ChargeId,
	ProcessId,
	Timestamp,
	Charge,
	ChargeCode,
	Weight,
	BucketId,
}: UpsertChargeParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "ChargeId", value: ChargeId },
		{ name: "ProcessId", value: ProcessId },
		{ name: "Timestamp", value: Timestamp },
		// { name: "Charge", value: Charge },
		// { name: "ChargeCode", value: ChargeCode },
		{ name: "Weight", value: Weight },
		{ name: "BucketId", value: BucketId },
		{ name: "IsChargeManual", value: true },
	];

	return await apiService.callV2("[EAF].[UpsertCharge]", parameters);
};

type UpsertProbeParams = {
	ProbeId: number | null;
	ProcessId: number;
	Timestamp: string;
	Temperature: number | null;
	Carbon: number | null;
	Oxygen: number | null;
	IsProbeManual: boolean;
};

export const upsertProbe = async ({
	ProbeId,
	ProcessId,
	Timestamp,
	Temperature,
	Oxygen,
	IsProbeManual,
}: UpsertProbeParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "ProbeId", value: ProbeId },
		{ name: "ProcessId", value: ProcessId },
		{ name: "Timestamp", value: Timestamp },
		{ name: "Temperature", value: Temperature },
		{ name: "Oxygen", value: Oxygen },
		{ name: "IsProbeManual", value: IsProbeManual },
	];

	return await apiService.callV2("[EAF].[UpsertProbe]", parameters);
};

type UpsertHeatParams = {
	HeatId: number;
	field: string;
	value: any;
};

export const upsertHeat = async ({
	HeatId,
	field,
	value,
}: UpsertHeatParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "HeatId", value: HeatId },
		{ name: field, value: value },
	];

	return await apiService.callV2("[MS].[UpsertHeat]", parameters);
};

type UpsertAdditionParams = {
	AdditionId: number;
	ProcessId: number;
	Timestamp: string;
	Weight: number;
	MaterialId: number;
};

export const UpsertAddition = async ({
	AdditionId,
	ProcessId,
	Timestamp,
	Weight,
	MaterialId,
}: UpsertAdditionParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "AdditionId", value: AdditionId },
		{ name: "ProcessId", value: ProcessId },
		{ name: "Timestamp", value: Timestamp },
		{ name: "Weight", value: Weight },
		{ name: "MaterialId", value: MaterialId },
		{ name: "IsAdditionManual", value: true },
	];

	return await apiService.callV2("[EAF].[UpsertAddition]", parameters);
};
type UpsertHeatingParams = {
	HeatingId: number | null;
	ProcessId: number;
	Start: string;
	End: string;
	MWH: number | null;
};
export const UpsertHeating = async ({
	HeatingId,
	ProcessId,
	Start,
	End,
	MWH,
}: UpsertHeatingParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "HeatingId", value: HeatingId },
		{ name: "ProcessId", value: ProcessId },
		{ name: "Start", value: Start },
		{ name: "End", value: End },
		{ name: "MWH", value: MWH },
		{ name: "IsHeatingManual", value: true },
	];
	return await apiService.callV2("[EAF].[UpsertHeating]", parameters);
};

type UpsertPourbackParams = {
	PourbackId: number;
	HeatId: number;
	RemainingWeight: number | null;
	DestinationProcessId: number;
	ChargedTime: string;
	ChargedWeight: number;
	Comments: string;
};

export const UpsertPourbackCharge = async ({
	PourbackId,
	HeatId,
	RemainingWeight,
	DestinationProcessId,
	ChargedTime,
	ChargedWeight,
	Comments,
}: UpsertPourbackParams) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "PourbackId", value: PourbackId },
		{ name: "HeatId", value: HeatId },
		{ name: "RemainingWeight", value: RemainingWeight },
		{ name: "DestinationProcessId", value: DestinationProcessId },
		{ name: "ChargedTime", value: ChargedTime },
		{ name: "ChargedWeight", value: ChargedWeight },
		{ name: "Comments", value: Comments },
	];

	return await apiService.callV2("[MS].[UpsertPourback]", parameters);
};

export const readProbesFromPLC = async ({
	ProcessId,
}: { ProcessId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "ProcessId", value: ProcessId }];

	return await apiService.callV2("[EAF].[ReadProbesFromPLC]", parameters);
};

export const readChargesFromPLC = async ({
	ProcessId,
}: { ProcessId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "ProcessId", value: ProcessId }];

	return await apiService.callV2("[EAF].[ReadChargesFromPLC]", parameters);
};

export const readAdditionsFromHMI = async ({
	ProcessId,
}: { ProcessId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "ProcessId", value: ProcessId }];

	return await apiService.callV2("[SWV].[ReadAdditionsFromHMI]", parameters);
};

export const readHeatingsFromPLC = async ({
	ProcessId,
}: { ProcessId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "ProcessId", value: ProcessId }];

	return await apiService.callV2("[EAF].[ReadHeatingsFromPLC]", parameters);
};

export const deleteProbeById = async ({ ProbeId }: { ProbeId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "ProbeId", value: ProbeId }];

	return await apiService.callV2("[EAF].[DeleteProbe]", parameters);
};

export const deleteChargeById = async ({ ChargeId }: { ChargeId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "ChargeId", value: ChargeId }];

	return await apiService.callV2("[EAF].[DeleteCharge]", parameters);
};

export const deleteAdditionById = async ({
	AdditionId,
}: { AdditionId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "AdditionId", value: AdditionId }];

	return await apiService.callV2("[EAF].[DeleteAddition]", parameters);
};
export const deleteHeatingById = async ({
	HeatingId,
}: { HeatingId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "HeatingId", value: HeatingId }];

	return await apiService.callV2("[EAF].[DeleteHeating]", parameters);
};

export const deletePourbackChargeById = async ({
	PourbackId,
}: { PourbackId: number }) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [{ name: "PourbackId", value: PourbackId }];

	return await apiService.callV2("[MS].[DeletePourbackCharge]", parameters);
};

export const getPourbackHeats = async (
	showMoreHeats: boolean,
	heatId: number,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "IncludeAllHeats", value: showMoreHeats },
		{ name: "HeatId", value: heatId },
	];

	return await apiService.callV2("[MS].[GetPourbackHeats]", parameters);
};

export const getPourbacks = async ({
	ProcessId = null,
}: { ProcessId: number | null }) => {
	const apiService = new MESApiService();

	const resp = await apiService.callV2("[MS].[GetPourbackCharges]", [
		{ name: "ProcessId", value: ProcessId },
	]);
	if (resp.ok) {
		const data = resp.data as PourbackResponse;

		data.tables[0].rows.map((pourback) => {
			pourback.SourceTime = moment.utc(pourback["SourceTime"]).toDate();
			pourback.ChargedTime = pourback.ChargedTime
				? moment.utc(pourback["ChargedTime"]).toDate()
				: null;
		});

		return {
			ok: true,
			data,
		};
	} else {
		return {
			ok: false,
			message: resp.message!,
		};
	}
};

export const getLastestProcesses = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	return await apiService.callV2("[SWV].[GetLastestNProcesses]", parameters);
};

export const searchProcesses = async (searchTerm: string) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "SearchTerm", value: searchTerm },
		{ name: "MaxResults", value: 100 },
	];

	return await apiService.callV2("[SWV].[SearchProcesses]", parameters);
};
