/**
 * Converts a time string in "hh:mm" format to total minutes.
 * @param timeStr - The time string to convert (e.g., "01:30").
 * @returns The total minutes as a number.
 * @throws Error if the input format is invalid.
 */
export function stringTimeToMinutes(timeStr: string): number {
	// Regular expression to match "hh:mm" format
	const regex = /^(\d{1,2}):(\d{2})$/;
	const match = timeStr.match(regex);

	if (!match) {
		throw new Error('Invalid time format. Expected "hh:mm".');
	}

	const hours = parseInt(match[1], 10);
	const minutes = parseInt(match[2], 10);

	// Validate ranges
	if (hours < 0 || hours > 23) {
		throw new Error("Hours must be between 0 and 23.");
	}
	if (minutes < 0 || minutes > 59) {
		throw new Error("Minutes must be between 0 and 59.");
	}

	return hours * 60 + minutes;
}
