export const validateField = (
	value: number,
	min: number,
	max: number,
	field: string,
) => {
	if (value < min || value > max) {
		return `${field} must be between ${min} and ${max}.`;
	}
	return true;
};
