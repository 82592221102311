import { ProcessData } from "@/pages/caster/models/ProcessData";
import {
	getLastestProcesses,
	searchProcesses,
} from "@/pages/caster/repositories/ProductionRepository";
import { ErrorModal } from "@dexteel/mesf-core";
import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	makeStyles,
} from "@material-ui/core";
import {
	ArrowBackRounded,
	ArrowForwardRounded,
	SkipNext,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash-es";
import {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
	inputRoot: {
		"& .MuiInputLabel-outlined": {
			fontSize: 18,
			transform: "translate(14px, 14px) scale(1)", // Initial label position
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			transform: "translate(12px, -6px) scale(0.75)", // Shrink label position
		},
		"& .MuiOutlinedInput-input": {
			padding: "27px 14px 10px", // Input padding
		},
	},
}));
interface HeatProps {
	HeatName: string;
	ProcessId: number;
}

interface Props {
	iconButton?: ReactElement;
	showNext?: boolean;
	showPrevious?: boolean;
	isProcessLoading?: boolean;
	value: ProcessData;
	secondValue?: string;

	onButtonClick?: () => void;
	onNext?: () => void;
	onPrevious?: () => void;
	onLast?: () => void;
	onChange?: (value: number) => void;
	onBlur?: () => void;
	onFocus?: () => void;
}

export const ProcessNavigatorWithSearch = ({
	iconButton,
	showNext,
	showPrevious,
	value,
	isProcessLoading = false,
	secondValue,
	onButtonClick,
	onNext,
	onPrevious,
	onLast,
	onChange,
	onBlur,
	onFocus,
}: Props) => {
	const navigate = useNavigate();
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const [processes, setProcesses] = useState<HeatProps[]>([]);
	const [selectedHeat, setSelectedHeat] = useState<HeatProps | null>({
		HeatName: value.HeatName,
		ProcessId: value.ProcessId!,
	});
	const [isFocused, setFocused] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	const classes = useStyles();
	const currentSearch = useRef(searchTerm);

	currentSearch.current = searchTerm;

	const debouncedSearch = useCallback(
		debounce(async (term: string) => {
			setLoading(true);
			try {
				if (term.length >= 2) {
					const resp = await searchProcesses(term);
					if (resp.ok) {
						const data = resp.data.tables[0].rows;
						if (currentSearch.current === term) setProcesses(data);
					} else {
						setError(resp.message);
					}
				}
				if (term.length === 0) {
					const resp = await getLastestProcesses();
					if (resp.ok) {
						if (currentSearch.current === term)
							setProcesses(resp.data.tables[0].rows);
					} else {
						setError(resp.message);
					}
				}
			} catch (error) {
				setError("An error occurred while searching");
				setProcesses([]);
			} finally {
				setLoading(false);
			}
		}, 1500),
		[],
	);
	const handleSelectionChange = (event: any, newValue: HeatProps | null) => {
		if (newValue) {
			if (newValue.ProcessId !== value.ProcessId) {
				setSelectedHeat(newValue);
				navigate(`/caster-data-entry/${newValue.ProcessId}`);
				if (onChange) {
					onChange(newValue.ProcessId);
				}

				setTimeout(() => {
					document.getElementById("dummyDiv")?.focus();
				}, 500);
			}
		} else {
			setSelectedHeat(null);
		}
	};

	useEffect(() => {
		debouncedSearch(searchTerm);
		return () => {
			debouncedSearch.cancel();
		};
	}, [searchTerm, debouncedSearch]);

	const options = useMemo(() => {
		if (
			selectedHeat &&
			!processes.some((heat) => heat.ProcessId === selectedHeat.ProcessId)
		) {
			return [selectedHeat, ...processes];
		}
		return processes;
	}, [processes, selectedHeat]);

	useEffect(() => {
		if (!isFocused && !loading && !isProcessLoading) {
			if (searchTerm !== value.HeatName) {
				setSelectedHeat({
					HeatName: value.HeatName,
					ProcessId: value.ProcessId!,
				});
			}
		}
	}, [isFocused, searchTerm, value, loading, isProcessLoading]);

	return (
		<>
			<Paper>
				<Box padding={1}>
					<Grid
						container
						direction={"row"}
						justifyContent={"space-around"}
						alignItems={"center"}
						spacing={1}
					>
						<Grid item>
							<IconButton
								size={"small"}
								aria-label="previous"
								color={"primary"}
								disabled={!showPrevious}
								onClick={onPrevious}
							>
								<ArrowBackRounded style={{ cursor: "pointer" }} />
							</IconButton>
						</Grid>
						<Grid item style={{ minWidth: "129px" }}>
							<Autocomplete<HeatProps>
								autoHighlight
								blurOnSelect
								clearOnEscape
								selectOnFocus
								popupIcon={null}
								options={options}
								getOptionLabel={(option) => option?.HeatName || "Unlinked"}
								value={selectedHeat}
								onChange={handleSelectionChange}
								inputValue={searchTerm}
								onInputChange={(event, newInputValue) => {
									setSearchTerm(newInputValue);
								}}
								onFocus={(ev) => {
									setFocused(true);
									onFocus && onFocus();
								}}
								onBlur={(ev: any) => {
									setFocused(false);
									onBlur && onBlur();
								}}
								loading={loading}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Heat name"
										variant="outlined"
										size="small"
										className={classes.inputRoot}
										InputProps={{
											...params.InputProps,
											style: { padding: "0 3px 0px 8px" },
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														size={"small"}
														title={value.HeatId ? "Unlink" : "Link"}
														onClick={onButtonClick}
														style={{
															color: value.HeatId ? "red" : "#3f51b5",
														}}
													>
														{loading ? (
															<CircularProgress size="1rem" />
														) : (
															iconButton
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<IconButton
								aria-label="next"
								color={"primary"}
								disabled={!showNext}
								onClick={onNext}
								size="small"
							>
								<ArrowForwardRounded style={{ cursor: "pointer" }} />
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton
								color={"primary"}
								onClick={onLast}
								disabled={!showNext}
								size="small"
								title="Last process"
							>
								<SkipNext style={{ cursor: "pointer" }} />
							</IconButton>
						</Grid>
					</Grid>
					<Divider style={{ margin: "9px 0px" }} />
					<Grid
						container
						justifyContent="center"
						alignContent="center"
						style={{ height: "10px" }}
					>
						<Grid item>
							<Typography variant="body2">{secondValue}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
