import { ButtonGroup, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
interface Props {
	deleteItem: (itemId: number) => void;
	edit: (itemId: number) => void;
	id: number;
}

export const EditAndDeleteButtons = ({ deleteItem, edit, id }: Props) => {
	return (
		<>
			<ButtonGroup>
				<IconButton
					color={"primary"}
					onClick={() => {
						edit(id);
					}}
					title="Edit"
				>
					<EditIcon style={{ cursor: "pointer" }} fontSize="small" />
				</IconButton>
				<IconButton
					color={"secondary"}
					onClick={() => {
						deleteItem(id);
					}}
					title="Delete"
				>
					<DeleteIcon style={{ cursor: "pointer" }} fontSize="small" />
				</IconButton>
			</ButtonGroup>
		</>
	);
};
