import {
	ResponseMESF,
	getMomentTz,
	useUTLSettingsContext,
} from "@dexteel/mesf-core";
import { Box, Paper } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import { useState } from "react";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useTimerContext } from "../../../context/timer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { readAdditionsFromHMI } from "../../../repositories/eaf.repositories";
import { CreateAddition } from "../../modals/addition/CreateAddition";
import { EditAddition } from "../../modals/addition/EditAddition";
import { AgGridFooterActions } from "../components/AgGridFooterActions";
import { useEAFDETablesStyles } from "../styles/useEAFDETablesStyles";
import { useTableData } from "./hooks/useAdditionsDataTable";

const moment = getMomentTz();
export const TableAdditions = () => {
	const { state } = useUTLSettingsContext();
	const MAX_ADDITIONS = get(state.settings, "MAX_ADDITIONS", 10);

	const {
		state: { deProcess },
		actions: { setErrorMessage },
	} = useDataEntryContext();
	const { deleteAddition, refreshData } = useEAFDataEntryActions();
	const { pause, resume } = useTimerContext();

	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedAddition, setSelectedAddition] = useState<any>();

	const additions = deProcess?.additions;
	const rowCount = additions ? additions.length : 0;
	const classes = useEAFDETablesStyles({ rowCount });
	const hasMaxAdditions =
		(additions && additions.length >= MAX_ADDITIONS) || false;

	const editAddition = (AdditionId: number) => {
		pause();
		const addition = additions?.find((a) => a.AdditionId === AdditionId);
		setSelectedAddition(addition);
		setShowEditModal(true);
	};

	const readFromHMI = async () => {
		const ProcessId = deProcess?.process?.ProcessId;
		if (ProcessId) {
			const resp = await readAdditionsFromHMI({ ProcessId });
			if (resp.ok) {
				await refreshData();
			} else {
				setErrorMessage(resp.message);
			}
			return resp;
		} else {
			setErrorMessage("Process not found");
			return { ok: false, message: "Process not found" } as ResponseMESF;
		}
	};

	const { columns, defaultColDef } = useTableData({
		deleteAddition,
		editAddition,
	});
	return (
		<>
			<Paper className={classes.root}>
				<Box
					padding={1}
					className={`ag-theme-balham ${classes.agGridBox} ${classes.dynamicHeight}`}
				>
					<AgGridReact
						rowSelection="single"
						suppressRowClickSelection={true}
						pagination={false}
						rowHeight={50}
						headerHeight={35}
						singleClickEdit={false}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={additions}
						columnDefs={columns}
						defaultColDef={defaultColDef}
						domLayout="normal"
					/>
					<AgGridFooterActions
						hasMaxItems={hasMaxAdditions}
						showCreateModal={showCreateModal}
						setShowCreateModal={setShowCreateModal}
						readFromPLC={readFromHMI}
						table="additions"
					/>
				</Box>
			</Paper>

			<CreateAddition
				show={showCreateModal}
				onHide={() => {
					resume();
					setShowCreateModal(false);
				}}
				date={
					deProcess
						? moment(deProcess.process.Start).add(1, "minute").toDate()
						: new Date()
				}
			/>
			<EditAddition
				show={showEditModal}
				onHide={() => {
					resume();
					setShowEditModal(false);
				}}
				addition={selectedAddition}
			/>
		</>
	);
};
