import { MesfModal } from "@dexteel/mesf-core";
import { Button, Grid } from "@material-ui/core";
type Props = {
	label: string;
	show: boolean;
	onHide: (proceed: boolean) => void;
	existingName: string;
};
export const ConfirmModal = ({ label, show, onHide, existingName }: Props) => {
	return (
		<>
			<MesfModal
				title="CONFIRM"
				open={show}
				id="confirm-modal"
				handleClose={() => onHide(false)}
			>
				<MesfModal.Content style={{ padding: "15px 30px" }}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12}>
							<p>{`A ${label} with the name '${existingName}' already exists. Do you want to proceed?`}</p>
						</Grid>
					</Grid>
				</MesfModal.Content>
				<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item md={3} xs={12} style={{ margin: 0 }}>
							<Button
								fullWidth
								variant="contained"
								color="default"
								onClick={() => onHide(false)}
							>
								No
							</Button>
						</Grid>
						<Grid item md={3} xs={12} style={{ margin: 0 }}>
							<Button
								fullWidth
								onClick={() => onHide(true)}
								variant="contained"
								color="primary"
								type="submit"
							>
								Yes
							</Button>
						</Grid>
					</Grid>
				</MesfModal.Actions>
			</MesfModal>
		</>
	);
};
