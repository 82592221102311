import { BarAsset } from "../types/bar-asset";
import { BarTask } from "../types/bar-task";
import { Asset, Task } from "../types/public-types";

export const sortAssets = (assetA: Task, assetB: Task) => {
	const orderA = assetA.displayOrder || Number.MAX_VALUE;
	const orderB = assetB.displayOrder || Number.MAX_VALUE;
	if (orderA > orderB) {
		return 1;
	} else if (orderA < orderB) {
		return -1;
	} else {
		return 0;
	}
};
export const getAllTasks = (assets: Asset[]) => {
	const allTasks: Task[] = [];
	assets.forEach((asset) => {
		asset.processes.forEach((process) => {
			allTasks.push(process);
		});
	});
	return allTasks;
};
export const getAllBarTasks = (assets: BarAsset[]) => {
	const allTasks: BarTask[] = [];
	assets.forEach((asset) => {
		asset.barTasks.forEach((barTask) => {
			allTasks.push(barTask);
		});
	});
	return allTasks;
};
