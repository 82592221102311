import { useEffect, useState } from "react";
import {
	deleteProbe,
	upsertProbeData,
} from "../../repositories/ProductionRepository";
import { useCastersContext } from "../CastersContext";

const useSaveProbe = () => {
	const [error, setError] = useState<string | null>();
	const [sucess, setSucess] = useState<string | null>(null);
	const { state, actions } = useCastersContext();
	const save = async (index: number, field: string, value: any) => {
		if (
			state.processData === null ||
			state.processData.ProcessId === null ||
			value === null
		)
			return;
		if (
			state.probes === null ||
			state.probes[index] === null ||
			state.probes[index].ProbeId === null
		)
			return;
		actions.setIsLoadingBackground(true);
		const resp = await upsertProbeData(
			state.probes[index].ProbeId,
			state.processData.ProcessId,
			state.probes[index].Timestamp,
			field,
			value,
		);
		actions.setIsLoadingBackground(false);
		if (resp.ok) {
			setSucess(field + " was saved successfully");
			if (state.probes[index].ProbeId === 0) actions.clearNewProbeData(index);
		} else setError(resp.message);
	};
	const removeProbe = async (probeId: number) => {
		actions.setIsLoadingBackground(true);
		const resp = await deleteProbe(probeId);
		if (resp.ok) setSucess("Probe was deleted");
		else setError(resp.message);
		actions.setIsLoadingBackground(false);
	};
	useEffect(() => {
		if (sucess) {
			actions.setNotificationMessage(sucess);
			setSucess("");
		}
		if (error) {
			actions.setErrorMessage(error);
			setError(null);
		}
	}, [sucess, error]);
	return { save, removeProbe, error, sucess };
};
export { useSaveProbe };
