import { CircularProgress, Grid } from "@material-ui/core";

export const CustomLoadingOverlay = () => (
	<Grid
		container
		justifyContent="center"
		alignItems="center"
		style={{ minHeight: "150px" }}
	>
		<CircularProgress size="3rem" />
	</Grid>
);
