import { dxtToLocalServerTime, useShiftNavigator } from "@dexteel/mesf-core";
import { ColDef, ValueGetterParams } from "ag-grid-enterprise";
import { get } from "lodash-es";
import {
	LinkCellRenderer,
	ManualIconCellRenderer,
} from "../../../../controls/ag-grid/components/ButtonCellRenderer";

type Props = {};

const applyCommonColumnSettings = (column: ColDef): ColDef => {
	return {
		...column,
		sortable: true,
		wrapText: true,
		wrapHeaderText: true,
	};
};

export const useTableData = ({}: Props) => {
	const { shiftInfo } = useShiftNavigator();
	const shiftId = get(shiftInfo, "CurrentShiftId", null);
	const columns: ColDef[] = [
		applyCommonColumnSettings({
			field: "AssetName",
			headerName: "EAF",
			minWidth: 55,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "HeatName",
			headerName: "Heat",

			cellRenderer: (params: any) => {
				return LinkCellRenderer({
					...params,
					link: `/meltshop-heats/${shiftId}?heatId=${params.data.HeatId}`,
				});
			},
			minWidth: 55,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "GradeName",
			headerName: "Grade",
			minWidth: 60,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "LadleName",
			headerName: "Ladle",
			minWidth: 60,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Start",
			headerName: "Start",
			cellRenderer: (params: any) => {
				return LinkCellRenderer({
					...params,
					link: `/eaf-data-entry/${params.data.ProcessId}`,
				});
			},
			minWidth: 60,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.Start}`, "HH:mm") || "",
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "End",
			headerName: "End",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.End}`, "HH:mm") || "",
			minWidth: 60,

			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "TapToTap",
			headerName: "Tap To Tap",
			type: ["numericColumn", "numericWidth"],
			minWidth: 85,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "PowerOn",
			headerName: "Power On",
			type: ["numericColumn", "numericWidth"],
			minWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "MWH",
			headerName: "MWH",
			valueGetter: (params: ValueGetterParams) => {
				return params.data.MWH ? params.data.MWH.toFixed(1) : "";
			},
			minWidth: 55,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "OxygenVolume",
			headerName: "O2 Vol [scf]",
			valueGetter: (params: ValueGetterParams) => {
				return params.data.OxygenVolume
					? params.data.OxygenVolume.toFixed(1)
					: "";
			},
			minWidth: 92,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Charges",
			headerName: "Charges",
			type: ["numericColumn", "numericWidth"],
			minWidth: 71,

			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "ScrapTons",
			headerName: "Scrap [lbs]",
			type: ["numericColumn", "numericWidth"],
			minWidth: 86,

			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "LastTemperature",
			headerName: "Last Temp.",
			type: ["numericColumn", "numericWidth"],
			minWidth: 86,

			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "TrimProbeTemperature",
			headerName: "Trim Temp",
			type: ["numericColumn", "numericWidth"],
			minWidth: 84,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "DeltaT",
			headerName: "Delta T",
			type: ["numericColumn", "numericWidth"],
			minWidth: 66,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "SteelWeight",
			headerName: "Steel Weight",
			type: ["numericColumn", "numericWidth"],
			minWidth: 98,

			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "IsProcessManual",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "centerAligned"],
			cellRenderer: ManualIconCellRenderer,
			cellClass: "ag-button-cell",
			minWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Name",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "optionsButton"],
			maxWidth: 150,
			minWidth: 150,
			editable: false,
		}),
	];

	return { columns };
};
