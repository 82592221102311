import { isNumeric } from "../../../../../utils/numeric-utils";
import { useCastersContext } from "../../CastersContext";
import { useSaveProcess } from "../../hooks/useSaveProcess";
import { useStyles } from "../styles";
import { InputFieldTable, ListBoxFieldTable } from "../ui/FieldTable";
import { DualInputFieldTableScrap } from "../ui/dual-input-field-scrap";

export const ScrapTable = () => {
	const { state, actions } = useCastersContext();
	const classes = useStyles();
	const process = state.processData || null;
	const scrapReasons = state.scrapReasons || [];
	const { saveProcessData } = useSaveProcess();

	// /// Events
	const handleOnFocus = (field: string) =>
		actions.setFocusField({ entity: "PROCESS", index: -1, field });
	const handleOnChange = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.updateProcessData({ field, value });
	};
	const handleOnScrapCodeChange = (field: string, value: string) => {
		actions.updateProcessData({ field, value, clear: true });
		saveProcessData(field, value);
	};
	const handleOnBlur = (field: string, value: string) => {
		if (!isNumeric(value)) return;
		actions.clearFocusField();
		saveProcessData(field, value);
	};
	return (
		<>
			{process !== null ? (
				<table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<td
								colSpan={3}
								className={classes.simpleCellHeader}
								style={{
									borderTopRightRadius: "0.25rem",
									borderTopLeftRadius: "0.25rem",
								}}
							>
								<p style={{ margin: "0 auto" }}>Scrap</p>
							</td>
						</tr>
						<tr>
							<td
								className={classes.simpleCellHeader}
								style={{
									width: "100px",
								}}
							>
								Length
								<td
									className={classes.simpleCellHeader}
									style={{ width: "50px", paddingBottom: 0 }}
								>
									ft
								</td>
								<td
									className={classes.simpleCellHeader}
									style={{ width: "50px", paddingBottom: 0 }}
								>
									in
								</td>
							</td>

							<td
								className={classes.simpleCellHeader}
								style={{ width: "100px", paddingBottom: 33 }}
							>
								Pieces
							</td>
							<td
								className={classes.simpleCellHeader}
								style={{
									width: "100px",
									paddingBottom: 33,
								}}
							>
								Code
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<DualInputFieldTableScrap
									classInput={"form-control" + " " + classes.simpleCellNumeric}
									feetValue={Math.floor((process.Scrap1Length || 0) / 12)}
									inchesValue={(process.Scrap1Length || 0) % 12}
									onFocus={() => handleOnFocus("Scrap1Length")}
									onChange={(value: number) =>
										handleOnChange("Scrap1Length", value.toString())
									}
									onBlur={(value: number) =>
										handleOnBlur("Scrap1Length", value.toString())
									}
								/>
							</td>

							<td>
								<InputFieldTable
									classInput={classes.simpleCellNumeric}
									value={process.Scrap1Pieces || ""}
									onFocus={() => handleOnFocus("Scrap1Pieces")}
									onChange={(value) => handleOnChange("Scrap1Pieces", value)}
									onBlur={(value) => handleOnBlur("Scrap1Pieces", value)}
								/>
							</td>
							<td>
								<ListBoxFieldTable
									value={process.Scrap1CodeId || 0}
									options={scrapReasons?.map((option) => ({
										id: option.ScrapReasonId,
										name: option.ScrapReasonName,
									}))}
									classListBox={classes.inputNormalClass}
									onChange={(value) =>
										handleOnScrapCodeChange("Scrap1CodeId", value)
									}
									onFocus={() => handleOnFocus("Scrap1CodeId")}
									styleListBox={{ borderRadius: "0", fontSize: "0.75rem" }}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<DualInputFieldTableScrap
									classInput={"form-control" + " " + classes.simpleCellNumeric}
									feetValue={Math.floor((process.Scrap2Length || 0) / 12)}
									inchesValue={(process.Scrap2Length || 0) % 12}
									onFocus={() => handleOnFocus("Scrap2Length")}
									onChange={(value: number) =>
										handleOnChange("Scrap2Length", value.toString())
									}
									onBlur={(value: number) =>
										handleOnBlur("Scrap2Length", value.toString())
									}
								/>
							</td>
							<td>
								<InputFieldTable
									classInput={classes.simpleCellNumeric}
									value={process.Scrap2Pieces || ""}
									onFocus={() => handleOnFocus("Scrap2Pieces")}
									onChange={(value) => handleOnChange("Scrap2Pieces", value)}
									onBlur={(value) => handleOnBlur("Scrap2Pieces", value)}
								/>
							</td>
							<td>
								<ListBoxFieldTable
									value={process.Scrap2CodeId || 0}
									options={scrapReasons?.map((option) => ({
										id: option.ScrapReasonId,
										name: option.ScrapReasonName,
									}))}
									classListBox={classes.inputNormalClass}
									onChange={(value) =>
										handleOnScrapCodeChange("Scrap2CodeId", value)
									}
									onFocus={() => handleOnFocus("Scrap2CodeId")}
									styleListBox={{ borderRadius: "0", fontSize: "0.75rem" }}
								/>
							</td>
						</tr>
						<tr>
							<td
								className={classes.simpleCellHeader}
								style={{ borderBottomLeftRadius: "0.25rem" }}
							>
								Tons
							</td>
							<td colSpan={2}>
								<InputFieldTable
									classInput={classes.simpleCellNumeric}
									value={process.ScrapTons || ""}
									readOnly={true}
									styleTextBox={{ borderBottomRightRadius: "0.25rem" }}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			) : (
				<label>No process data</label>
			)}
		</>
	);
};
