import { Theme, makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header": {
			backgroundColor: "#f5f5f5",
			"&:hover": {
				backgroundColor: "#f5f5f5",
			},
		},
		"& .ag-cell": {},
		"& .ag-header-cell-text": {},
		"& .ag-drag-handle": {},
		"& .ag-right-aligned-cell": {},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .ag-header-cell-label": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
}));
