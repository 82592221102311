import { getTimeFormatFromUTC } from "../../../../../utils/date-time-formatter";
import { useCastersContext } from "../../CastersContext";
import { useStyles } from "../styles";
import { InputFieldTable } from "../ui/FieldTable";

export const ElementTable = () => {
	const classes = useStyles();
	const { state } = useCastersContext();
	const element = state.element || null;
	const elementNames: string[] = [
		"C",
		"LC",
		"Mn",
		"P",
		"S",
		"LS",
		"Si",
		"Cu",
		"Cr",
		"Ni",
		"Mo",
		"V",
		"Nb",
		"Sn",
		"Al",
		"Ce",
	];
	return (
		<>
			{element !== null ? (
				<div>
					<table cellPadding={0} cellSpacing={0}>
						<tbody>
							<tr>
								<td className={classes.simpleCellHeader} colSpan={2}>
									{(element?.SampleTypeName || "") +
										(element?.SampleSequence || "")}
								</td>
								<td colSpan={2}>
									<InputFieldTable
										value={getTimeFormatFromUTC(element?.Reception || null)}
										readOnly
										styleTextBox={{ width: "100%" }}
										classInput={classes.simpleCellText}
									/>
								</td>
							</tr>
							{elementNames.map((name, index) => {
								if (index % 4 === 0) {
									return (
										<tr key={name}>
											<td
												className={classes.simpleCellHeader}
												style={{ width: "50px" }}
											>
												{name}
											</td>
											<td style={{ width: "80px" }}>
												<InputFieldTable
													value={(element as any)[name] || ""}
													readOnly
													classInput={classes.simpleCellNumeric}
												/>
											</td>
											{index + 1 < elementNames.length && (
												<>
													<td
														className={classes.simpleCellHeader}
														style={{ width: "50px" }}
													>
														{elementNames[index + 1]}
													</td>
													<td style={{ width: "80px" }}>
														<InputFieldTable
															value={
																(element as any)[elementNames[index + 1]] || ""
															}
															readOnly
															classInput={classes.simpleCellNumeric}
														/>
													</td>
												</>
											)}
											{index + 2 < elementNames.length && (
												<>
													<td
														className={classes.simpleCellHeader}
														style={{ width: "50px" }}
													>
														{elementNames[index + 2]}
													</td>
													<td style={{ width: "80px" }}>
														<InputFieldTable
															value={
																(element as any)[elementNames[index + 2]] || ""
															}
															readOnly
															classInput={classes.simpleCellNumeric}
														/>
													</td>
												</>
											)}
											{index + 3 < elementNames.length && (
												<>
													<td
														className={classes.simpleCellHeader}
														style={{ width: "50px" }}
													>
														{elementNames[index + 3]}
													</td>
													<td style={{ width: "80px" }}>
														<InputFieldTable
															value={
																(element as any)[elementNames[index + 3]] || ""
															}
															readOnly
															classInput={classes.simpleCellNumeric}
														/>
													</td>
												</>
											)}
										</tr>
									);
								}
								return null;
							})}
						</tbody>
					</table>
				</div>
			) : (
				<label>No data</label>
			)}
		</>
	);
};
