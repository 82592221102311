import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { BucketReducer } from "./CatalogBucketReducer";
const BucketContext = createContext({
	state: BucketReducer.getInitialState(),
	actions: BucketReducer.actions,
});

export const useBucketContext = () => useContext(BucketContext);

export const BucketProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: BucketReducer.getInitialState(),
		reducers: BucketReducer.caseReducers,
	});
	return (
		<BucketContext.Provider value={{ state, actions }}>
			{children}
		</BucketContext.Provider>
	);
};
