import { createSlice } from "@reduxjs/toolkit";
import { CatalogLadle } from "../../../models/CatalogLadle";

const CatalogLadleInitialState: {
	ladles: CatalogLadle[] | null;
} = {
	ladles: null,
};

export const CatalogLadleReducer = createSlice({
	name: "__",
	initialState: CatalogLadleInitialState,
	reducers: {
		setLadles(state, { payload }) {
			state.ladles = payload;
		},
	},
});
