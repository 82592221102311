export interface CatalogElement {
	ElementId: number | null;
	ElementName: string;
	Decimals: number;
	Order: number;
	IsActive: boolean;
}
export const CATALOG_ELEMENT_INITIAL_VALUES: CatalogElement = {
	ElementId: null,
	ElementName: "",
	Decimals: 4,
	Order: 0,
	IsActive: true,
};
