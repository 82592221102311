import React from "react";
import style from "./bar.module.css";

type BarDisplayProps = {
	id: string;
	x: number;
	y: number;
	width: number;
	height: number;
	isSelected: boolean;
	/* progress start point */
	progressX: number;
	progressWidth: number;
	barCornerRadius: number;
	styles: {
		backgroundColor: string;
		backgroundSelectedColor: string;
		progressColor: string;
		progressSelectedColor: string;
		borderColor: string;
		borderWidth: number;
	};
};
export const BarDisplay: React.FC<BarDisplayProps> = ({
	id,
	x,
	y,
	width,
	height,
	isSelected,
	progressX,
	progressWidth,
	barCornerRadius,
	styles,
}) => {
	const getProcessColor = () => {
		return isSelected ? styles.progressSelectedColor : styles.progressColor;
	};

	const getBarColor = () => {
		return isSelected ? styles.backgroundSelectedColor : styles.backgroundColor;
	};

	return (
		<g>
			<rect
				data-id={`id-${id}-background`}
				x={x}
				width={width}
				y={y}
				height={height}
				ry={barCornerRadius}
				rx={barCornerRadius}
				fill={getBarColor()}
				className={style.barBackground}
				stroke={styles.borderColor}
			/>
			<rect
				data-id={id + "-progress"}
				x={progressX}
				width={progressWidth}
				y={y}
				height={height}
				ry={barCornerRadius}
				rx={barCornerRadius}
				fill={getProcessColor()}
				stroke={styles.borderColor}
				strokeWidth={styles.borderWidth}
			/>
		</g>
	);
};
