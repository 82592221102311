import { TextField } from "@material-ui/core";
import React from "react";
import { useProductBatchesStyles } from "../../styles/productBatchStyles";

interface WeightPerPieceInputProps {
	value: number | string;
	error: boolean;
	helperText: string | undefined;
	className?: string;
}

export const WeightPerPieceInput: React.FC<WeightPerPieceInputProps> = ({
	value,
	error,
	helperText,
	className,
}) => {
	return (
		<TextField
			error={error}
			helperText={helperText}
			value={value}
			InputProps={{
				readOnly: true,
			}}
			fullWidth
			variant="outlined"
			size="small"
			className={className}
		/>
	);
};
