import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
	CATALOG_BUCKET_INITIAL_VALUES,
	CatalogBucket,
} from "../../../models/CatalogBucket";
import {
	getCatalogBuckets,
	upsertCatalogBucket,
} from "../../../repositories/CatalogBucketRepository";
import { useModalStyles } from "../../../styles/modalStyles";
import { useBucketContext } from "../CatalogBucketContext";

type Props = {
	show: boolean;
	onHide: (bucketCreated: boolean) => void;
	refreshData: Function;
};

export const CreateBucket = ({ show, onHide, refreshData }: Props) => {
	const classes = useModalStyles();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const { state } = useBucketContext();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CatalogBucket>({
		defaultValues: CATALOG_BUCKET_INITIAL_VALUES,
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const validateNoSpecialChars = (value: string) =>
		/^[a-zA-Z0-9 _-]*$/.test(value) || "No special characters";

	const onSubmit: SubmitHandler<CatalogBucket> = async (data: any) => {
		setIsSubmitLoading(true);
		const responseBuckets = await getCatalogBuckets();
		if (responseBuckets.ok) {
			const buckets: { BucketName: string }[] =
				responseBuckets.data.tables[0].rows;
			if (buckets.some((bucket) => bucket.BucketName === data.BucketName)) {
				setError("A bucket with this name already exists");
			} else {
				await performUpsert(data);
			}
		} else {
			setError(responseBuckets.message);
		}
		setIsSubmitLoading(false);
	};

	const performUpsert = async (data: CatalogBucket) => {
		const createBucketResp = await upsertCatalogBucket(data);
		if (createBucketResp.ok) {
			onHide(true);
			setOpen(true);
			refreshData();
		} else {
			setError(createBucketResp.message);
			onHide(false);
		}
	};

	useEffect(() => {
		if (show) {
			reset();
		}
	}, [show]);

	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						title="NEW BUCKET"
						open={show}
						handleClose={() => onHide(false)}
						id="create-bucket-modal"
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content
								className={classes.modalBody}
								style={{ padding: "15px 30px" }}
							>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField
											{...register("BucketName", {
												required: "Name is required",
												validate: validateNoSpecialChars,
											})}
											label="Name"
											variant="outlined"
											error={!!errors.BucketName}
											fullWidth
											margin="dense"
											autoComplete="off"
										/>
										{errors.BucketName && (
											<span className={classes.errorLabel}>
												{errors.BucketName.message}
											</span>
										)}
									</Grid>
									<Grid item xs={12}>
										<TextField
											{...register("Capacity", {
												required: "Capacity is required",
												min: { value: 0, message: "Capacity must be positive" },
											})}
											label="Capacity"
											type="number"
											variant="outlined"
											error={!!errors.Capacity}
											fullWidth
											margin="dense"
											autoComplete="off"
										/>
										{errors.Capacity && (
											<span className={classes.errorLabel}>
												{errors.Capacity.message}
											</span>
										)}
									</Grid>
								</Grid>
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(false)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading ? (
													<CircularProgress size="1rem" />
												) : (
													<></>
												)
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The bucket was created successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Bucket"
			/>
		</>
	);
};
