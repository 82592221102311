import { useContextMenuMESF } from "@dexteel/mesf-core";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import UnCheckBoxIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import UnLinkIcon from "@material-ui/icons/LinkOff";
import ListIcon from "@material-ui/icons/List";
import RestoreIcon from "@material-ui/icons/Restore";
import SendIcon from "@material-ui/icons/Send";
import { ICellRendererParams } from "ag-grid-enterprise";
import numeral from "numeral";
import { useStyles } from "../../../pages/caster/caster-data-entry/components/styles";

type Props = ICellRendererParams & {
	dateTimeFormat?: string;
	convertToLocalTime?: boolean;
	numericFormat?: string;
	clicked: Function;
	isEnabled: string;
	linkProperty?: string;
	link?: string;
	showContextMenu?: (e: React.MouseEvent) => void;
};
interface CommentIconCellRendererProps {
	comments: string | null | undefined;
	onClick: () => void;
}
export const ToggleStatusButtonCellRenderer = (props: Props) => {
	if (props.data[props.isEnabled] === true)
		return (
			<>
				<DeleteButtonCellRenderer {...props} />
			</>
		);
	else
		return (
			<>
				<ActiveButtonCellRenderer {...props} />
			</>
		);
};
export const ToggleActiveButtonCellRenderer = (props: Props) => {
	if (props.value === true)
		return (
			<>
				<DeleteButtonCellRenderer {...props} />
			</>
		);
	else
		return (
			<>
				<ActiveButtonCellRenderer {...props} />
			</>
		);
};
export const EditButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="edit"
				size="small"
				title="Edit"
				color="primary"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<EditIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};
export const DeleteButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			if (props.clicked === undefined) return;
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="delete"
				size="small"
				title="Delete"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<DeleteIcon
					fontSize="inherit"
					color="secondary"
					style={{ cursor: "pointer" }}
				/>
			</IconButton>
		</>
	);
};
export const ActiveButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		if (props.clicked === undefined) return;
		props.clicked(props.data);
	}
	return (
		<>
			<IconButton
				aria-label="active"
				title="Active"
				size="small"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<RestoreIcon
					fontSize="inherit"
					color="primary"
					style={{ cursor: "pointer" }}
				/>
			</IconButton>
		</>
	);
};
export const SendButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	if (props.data.Sent === true) return <></>;

	if (props.data.ChecksPassed === false) {
		return (
			<>
				<IconButton
					aria-label="edit"
					size="small"
					title="Edit"
					color="primary"
					style={{ cursor: "pointer" }}
					onClick={btnClickedHandler}
				>
					<SendIcon fontSize="inherit" style={{ cursor: "pointer" }} />
				</IconButton>
			</>
		);
	} else return <></>;
};
export const LinkButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="link"
				size="small"
				title="Link"
				color="primary"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<LinkIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};
export const BooleanCellRenderer = (props: Props) => {
	if (props.value === true)
		return (
			<>
				<CheckBoxIcon fontSize="small" color="primary" />
			</>
		);
	return (
		<>
			<UnCheckBoxIcon fontSize="small" color="primary" />
		</>
	);
};
export const BooleanTextCellRenderer = (props: Props) => {
	if (props.value === true)
		return (
			<>
				<span>Y</span>
			</>
		);
	return (
		<>
			<span>F</span>
		</>
	);
};
export const ManualIconCellRenderer = (props: Props) => {
	const isManual = props.data.IsManual || props.data.IsProcessManual;
	const classes = useStyles();
	return (
		<>
			{isManual ? (
				<div>
					<Button
						aria-label={"Manual"}
						title={"Manual"}
						variant="text"
						size="small"
						className={`${classes.simpleButton} ${classes.manualButton}`}
						style={{ cursor: "auto" }}
					>
						M
					</Button>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
export const CommentIconCellRenderer = ({
	comments,
	onClick,
}: CommentIconCellRendererProps) => {
	const classes = useStyles();
	const hasComments = comments;

	return (
		<>
			<IconButton
				aria-label="comments"
				size="small"
				title="See comments"
				color="primary"
				style={{ cursor: "pointer" }}
				disabled={!hasComments}
				onClick={() => onClick()}
			>
				<CommentIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};
export const UnLinkButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="unlink"
				size="small"
				title="Unlink"
				color="primary"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<UnLinkIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};

export const NumericCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;

	const m = numeral(props.value);
	const format = props.numericFormat === "" ? "0.00" : props.numericFormat;
	const formatCell = Number.isNaN(m.value) ? "NaN" : m.format(format);

	return <span>{formatCell}</span>;
};
export const LinkCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;
	if (props.link) {
		return (
			<>
				<a href={props.link} target="_self" rel="noopener noreferrer">
					{props.value}
				</a>
			</>
		);
	}
	const link = props.data[props.linkProperty ?? ""] ?? "";
	return (
		<>
			<a href={link} target="_self" rel="noopener noreferrer">
				{props.value}
			</a>
		</>
	);
};
export const LinkAndIconCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;
	const link = props.data[props.linkProperty ?? ""] ?? "";
	const isManual = props.data.IsManual;
	const classes = useStyles();
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div>
				<a href={link} target="_self" rel="noopener noreferrer">
					{props.value}
				</a>
			</div>
			{!isManual && (
				<div style={{ padding: "0 2rem" }}>
					<Button
						aria-label={"Manual"}
						title={"Manual"}
						variant="text"
						size="small"
						className={`${classes.simpleButton} ${classes.manualButton}`}
						style={{ cursor: "auto" }}
					>
						M
					</Button>
				</div>
			)}
		</div>
	);
};
export const PopupCellRenderer = () => {
	const { showContextMenu } = useContextMenuMESF();

	const onClickHandler = (e: React.MouseEvent) => {
		// setTimeout(()=>{
		// 	showContextMenu(e,data, options);
		// },0)
	};

	return (
		<IconButton
			aria-label="Options"
			title="Options"
			style={{ cursor: "pointer" }}
			onClick={onClickHandler}
		>
			<ListIcon
				fontSize="inherit"
				color="primary"
				style={{ cursor: "pointer" }}
			/>
		</IconButton>
	);
};
