import { makeStyles } from "@material-ui/core";
import { he } from "date-fns/locale";

export const useDeliveryTicketStyles = makeStyles((theme) => ({
	input: {
		"& .MuiInputBase-input": {
			fontSize: "18px !important",
			color: theme.palette.text.primary,
			height: "20px!important",
		},
		"& .MuiInputBase-root": {
			height: "40px",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.dark,
			},
		},
		'& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
			{
				opacity: 1,
				height: "18px",
				position: "absolute",
				right: "5px",
				top: "50%",
				transform: "translateY(-50%)",
			},
	},
	disabledInput: {
		"& .MuiInputBase-input": {
			backgroundColor: "#e9ecef",
		},
	},
	textFieldMUI: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		width: "100%",
		padding: "19px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		fontFamily: theme.typography.fontFamily,
		fontSize: "18px !important",
		color: theme.palette.text.primary,
		height: "20px!important",
	},
	addButton: {
		minWidth: "35px",
		maxWidth: "35px",
		maxHeight: "35px",
		minHeight: "35px",
		fontSize: "1.5rem",
		margin: "8px",
	},
}));
