import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { AcquisitionStatsReducer } from "../reducers/AcquisitionStatsReducer";

const AcquisitionStatsContext = createContext({
	state: AcquisitionStatsReducer.getInitialState(),
	actions: AcquisitionStatsReducer.actions,
});

export const useAcquisitionStatsContext = () =>
	useContext(AcquisitionStatsContext);

export const AcquisitionStatsProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: AcquisitionStatsReducer.getInitialState(),
		reducers: AcquisitionStatsReducer.caseReducers,
	});

	return (
		<AcquisitionStatsContext.Provider value={{ state, actions }}>
			{children}
		</AcquisitionStatsContext.Provider>
	);
};
