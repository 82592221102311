import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { Heating } from "../../../../models/eaf.models";
const moment = getMomentTz();
export const useTableData = () => {
	const columns: ColGroupDef[] = [
		{
			headerName: "Heatings",
			children: [
				{
					field: "Start",
					headerName: "Start",
					flex: 1,
					minWidth: 70,
					valueGetter: (params: any) => {
						if (params.data.Start) {
							return dxtToLocalServerTime(
								(params.data as Heating).Start,
								"HH:mm:ss",
							);
						}
					},
				},
				{
					field: "End",
					headerName: "End",
					flex: 1,
					minWidth: 70,
					valueGetter: (params: any) => {
						if (params.data.End) {
							return dxtToLocalServerTime(
								(params.data as Heating).End,
								"HH:mm:ss",
							);
						}
					},
				},
				{
					field: "Duration",
					headerName: "Duration",
					flex: 1,
					minWidth: 85,
					valueGetter: (params: any) => {
						const { Start, End } = params.data as Heating;

						if (End && Start) {
							const durationInSeconds = moment(End).diff(
								moment(Start),
								"seconds",
							);
							const minutes = Math.floor(durationInSeconds / 60);
							const seconds = durationInSeconds % 60;
							return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
						}
					},
				},
				{
					field: "MWH",
					headerName: "Energy [kwh]",
					flex: 1,
					minWidth: 113,
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	return { columns, defaultColDef };
};
