import { HealthStatusProvider } from "./HealthStatusContext";
import { HealthStatusPage } from "./HealthStatusPage";

type Props = {};
const HealthStatus = (props: Props) => {
	return (
		<HealthStatusProvider>
			<HealthStatusPage />
		</HealthStatusProvider>
	);
};
export default HealthStatus;
