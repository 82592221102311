import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { AutoComplete } from "../../autoComplete";
import { HeatNavigationProps } from "../types/public-types";
export const HeatNavigation: React.FunctionComponent<HeatNavigationProps> = ({
	heats,
	title = "Heat",
	defaultText = "Enter a Heat Number",
	onValueChanged,
	onTextChanged,
	onNextClicked,
	onPreviousClicked,
	textColor = "black",
	heatStatus = null,
}) => {
	const searchHeat = async (textSearch: string) => {
		if (onTextChanged) {
			onTextChanged(textSearch);
		}
	};
	return (
		<Grid item container alignItems="center" spacing={2}>
			<Grid item xs={12} md={6} style={{ marginLeft: "15px" }}>
				<div className="input-group">
					<div className="input-group-prepend">
						<span className="input-group-text">{title}</span>
						<button
							className="btn btn-secondary"
							type="button"
							onClick={onPreviousClicked}
						>
							<i className="fas fa-chevron-left"></i>
						</button>
					</div>
					<AutoComplete
						items={heats}
						defaultText={defaultText}
						noOptionsText={"No heats to show"}
						width={200}
						containerPadding={"0 0.5rem"}
						onTextSearch={searchHeat}
						onChangeValue={onValueChanged}
						color={textColor}
					/>
					<div className="input-group-append">
						<button
							className="btn btn-secondary"
							type="button"
							onClick={onNextClicked}
						>
							<i className="fas fa-chevron-right"></i>
						</button>
					</div>
					{heatStatus && (
						<div
							style={{
								backgroundColor: heatStatus?.color || "grey",
								color: "#fff",
								borderRadius: "4px",
								padding: `10px`,
								textAlign: "center",
								marginLeft: "10px",
								minWidth: "100px",
							}}
						>
							<Typography>{heatStatus?.name}</Typography>
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
};
