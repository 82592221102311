import { ErrorModal } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
} from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { SyntheticEvent, useState } from "react";
import { useConfigurationAssetContext } from "../../context/ConfigurationAssetContext";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import { removeAsset } from "../../repositories/ConfigurationAssetRepository";
import { removeDelay } from "../../repositories/ConfigurationDelayRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const DeleteDelay = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const [assetRemovedSuccess, setAssetRemovedSuccess] =
		useState<boolean>(false);

	const [errorRemoveAsset, setErrorRemoveAsset] = useState<string>("");
	const [isCreateSubmitLoading, setIsCreateSubmitLoading] = useState(false);

	const {
		state: { delayNodeSelectedInTree },
	} = useConfigurationDelayCodeContext();

	const handleCancel = () => {
		setIsCreateSubmitLoading(false);
		onHide(false);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setAssetRemovedSuccess(false);
	};

	const handleRemoveAsset = async () => {
		setIsCreateSubmitLoading(true);
		const res = await removeDelay(
			delayNodeSelectedInTree.DelayCodeId as number,
		);
		if (res.ok) {
			setAssetRemovedSuccess(true);
			setIsCreateSubmitLoading(false);
			onHide(true);
		} else {
			setIsCreateSubmitLoading(false);
			setErrorRemoveAsset(res.message);
		}
	};

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
				disableEnforceFocus
				className={classes.root}
			>
				<DialogTitle id="responsive-dialog-title">{"WARNING"}</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ padding: 0, margin: 0 }}>
						Be careful, once deleted you will not be able to recover this delay
						code <b>{delayNodeSelectedInTree?.DelayCodeName}</b>, are you sure
						you want to delete?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ padding: "16px 24px" }}>
					<Button onClick={() => onHide(false)} autoFocus color="default">
						CANCEL
					</Button>
					<Button
						autoFocus
						color="secondary"
						startIcon={
							isCreateSubmitLoading && <CircularProgress size="1rem" />
						}
						onClick={handleRemoveAsset}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<ErrorModal
				error={errorRemoveAsset}
				onHide={() => setErrorRemoveAsset("")}
			/>
			<Snackbar
				open={assetRemovedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Asset removed succesfully
				</Alert>
			</Snackbar>
		</>
	);
};
