import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { getCatalogBuckets } from "../../repositories/CatalogBucketRepository";
import { useBucketContext } from "./CatalogBucketContext";
import { TableBucket } from "./components/BucketTable";

type Props = {};
export const BucketPage = (props: Props) => {
	const { state, actions } = useBucketContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");

	const getBucketsFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogBuckets();
		if (resp.ok) {
			actions.setBuckets(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getBucketsFromAPI();
	}, []);

	if (state.buckets) {
		return (
			<Grid container justifyContent="center" alignItems="center">
				<Grid item md={12} xs={12} style={{ margin: 0 }}>
					<Paper
						elevation={1}
						style={{
							width: "100%",
							padding: "10px 0 20px",
							borderRadius: "0.75rem",
							height: "auto",
						}}
					>
						<TableBucket
							isLoading={isLoading}
							getBucketsFromAPI={getBucketsFromAPI}
						/>
					</Paper>
				</Grid>
				<ErrorModal error={error} onHide={() => setError("")} />
			</Grid>
		);
	}

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<LazyLoading />
		</div>
	);
};
