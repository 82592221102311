import * as React from "react";
import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import CasterCounters from "../../pages/caster/caster-counters";
import CasterDataEntry from "../../pages/caster/caster-data-entry";
import { CasterHeatReportPage } from "../../pages/caster/caster-heat-report/CasterHeatReportPage";
import CasterProcesses from "../../pages/caster/caster-processes";
import HeatReconciliation from "../../pages/caster/heat-reconciliation/HeatReconciliation";
import CasterProduction from "../../pages/caster/production/CasterProduction";
import { ChemistryHeatReportPage } from "../../pages/laboratory/chemistry-heat-report/ChemistryHeatReportPage";
import { CasterHeatReportProvider } from "../../pages/meltshop/heat-report/HeatReportContext";
export const CasterRoutes = [
	<Route
		path="/caster-data-entry"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterDataEntry />
			</Suspense>
		}
	/>,
	<Route
		path="/caster-data-entry/:processId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterDataEntry />
			</Suspense>
		}
	>
		{" "}
	</Route>,
	<Route
		path="/caster-production"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterProduction />
			</Suspense>
		}
	/>,
	<Route
		path="/caster-processes"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterProcesses />
			</Suspense>
		}
	/>,
	<Route
		path="/caster-processes/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterProcesses />
			</Suspense>
		}
	/>,
	<Route
		path="/caster-counters"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterCounters />
			</Suspense>
		}
	/>,
	<Route
		path="/caster-counters/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<CasterCounters />
			</Suspense>
		}
	/>,

	<Route path="/heat-report" element={<CasterHeatReportProvider />}>
		<Route
			path="caster"
			element={
				<Suspense fallback={<LazyLoading></LazyLoading>}>
					<CasterHeatReportPage />
				</Suspense>
			}
		/>
		<Route
			path="caster/:heatName"
			element={
				<Suspense fallback={<LazyLoading></LazyLoading>}>
					<CasterHeatReportPage />
				</Suspense>
			}
		/>

		<Route
			path="chemistry"
			element={
				<Suspense fallback={<LazyLoading></LazyLoading>}>
					<ChemistryHeatReportPage />{" "}
				</Suspense>
			}
		/>
		<Route
			path="chemistry/:heatName"
			element={
				<Suspense fallback={<LazyLoading></LazyLoading>}>
					<ChemistryHeatReportPage />{" "}
				</Suspense>
			}
		/>
		{/* <Route path="*" element={<Navigate to="caster" replace />} /> */}
	</Route>,
	<Route
		path="/heat-reconciliation"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<HeatReconciliation />
			</Suspense>
		}
	/>,
];
