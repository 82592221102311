import { Button, Grid, GridSize } from "@material-ui/core";
import { useButtonStyles } from "./styles/buttonStyle";
type GridSizes = {
	lg?: GridSize;
	md?: GridSize;
	sm?: GridSize;
	xs?: GridSize;
};
type Props = {
	label: string;
	showCreateModal: boolean;
	setShowCreateModal: (showCreateModal: boolean) => void;
	sizes?: GridSizes;
};

export const FooterButton = ({
	label,
	showCreateModal,
	setShowCreateModal,
	sizes = { lg: 2, md: 2, sm: 6, xs: 6 },
}: Props) => {
	const classes = useButtonStyles();

	return (
		<Grid container justifyContent="flex-end">
			<Grid
				item
				lg={sizes.lg}
				md={sizes.md}
				sm={sizes.sm}
				xs={sizes.xs}
				className={classes.btnModal}
			>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					style={{ margin: "0px 15px" }}
					onClick={() => setShowCreateModal(!showCreateModal)}
				>
					NEW {label}
				</Button>
			</Grid>
		</Grid>
	);
};
