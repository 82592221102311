import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { HealthStatusReducer } from "./HealthStatusReducer";

const HealthStatusContext = createContext({
	state: HealthStatusReducer.getInitialState(),
	actions: HealthStatusReducer.actions,
});

export const useHealthStatusContext = () => useContext(HealthStatusContext);

export const HealthStatusProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: HealthStatusReducer.getInitialState(),
		reducers: HealthStatusReducer.caseReducers,
	});
	return (
		<HealthStatusContext.Provider value={{ state, actions }}>
			{children}
		</HealthStatusContext.Provider>
	);
};
