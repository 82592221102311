import { createSlice } from "@reduxjs/toolkit";
import { ItemOption } from "../../../../controls/autoComplete";
import { Heat } from "../../../meltshop/models/Heat";
import { ResultSearchHeatName } from "../../../meltshop/models/ResultSearchHeatName";
import { CatalogGradeMechanicalLimit } from "../../models/CatalogGradeMechanicalLimit";
import { CatalogMechanicalTest } from "../../models/CatalogMechanicalTest";
import { CatalogResultSource } from "../../models/CatalogResultSource";
import { MechanicalSample } from "../../models/MechanicalSample";

const MechanicalByHeatInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	showOnlyValid: boolean;
	searchHeatNameText: string | null;
	resultHeats: ResultSearchHeatName[] | null;
	heatOptions: ItemOption[];
	heat: Heat | null;
	mechanicalTests: CatalogMechanicalTest[] | null;
	mechanicalSamples: MechanicalSample[] | null;
	grades: CatalogGradeMechanicalLimit[] | null;
	sources: CatalogResultSource[] | null;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	searchHeatNameText: null,
	resultHeats: null,
	heatOptions: [],
	heat: null,
	showOnlyValid: true,
	mechanicalTests: null,
	mechanicalSamples: null,
	grades: null,
	sources: null,
};

export const MechanicalByHeatReducer = createSlice({
	name: "__",
	initialState: MechanicalByHeatInitialState,
	reducers: {
		setShowOnlyValid(state, { payload }) {
			state.showOnlyValid = payload;
		},
		setSearchHeatNameText(state, { payload }) {
			state.searchHeatNameText = payload;
		},
		setResultHeats(state, { payload }) {
			state.resultHeats = payload;
		},
		setHeatOptions(state, { payload }) {
			state.heatOptions = payload;
		},
		setHeat(state, { payload }) {
			state.heat = payload;
		},
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setMechanicalTests(state, { payload }) {
			state.mechanicalTests = payload;
		},
		setMechanicalSamples(state, { payload }) {
			state.mechanicalSamples = payload;
		},
		setGrades(state, { payload }) {
			state.grades = payload;
		},
		setSources(state, { payload }) {
			state.sources = payload;
		},
	},
});
