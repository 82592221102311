import { useContextMenuMESF, useShiftNavigator } from "@dexteel/mesf-core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Ganttv2, Task } from "../../../../controls/ganttAsset";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { Process } from "../../../caster/models/ProcessGanttChart";
import {
	deleteProcess,
	unLinkProcess as unlinkCasterProcess,
} from "../../../caster/repositories/ProductionRepository";
import { unLinkEAFProcess } from "../../../eaf/eaf-processes/repositories/EAFRepository";
import { useCasterDashboardContext } from "../../CasterDashboardContext";
import { useGanttOptionsContextMenu } from "../../hooks/useGanttOptionsContextMenu";
import { LinkProcess } from "./LinkProcess";
import { UpsertJustifyDelay } from "./UpsertJustifyDelay";
import { UpsertProcess } from "./UpsertProcess";

interface Props {
	columnWidth: number;
	assets: any[];
	showArrowTriangle: boolean;
	startCalendarDate: Date;
	endCalendarDate: Date;
	rowHeight: number;
	barFill: number;
	lineSectionFill: number;
	lineFill: number;
	refreshData: () => void;
}

export const GanttSection = ({
	columnWidth,
	assets,
	showArrowTriangle,
	startCalendarDate,
	endCalendarDate,
	rowHeight,
	barFill,
	lineSectionFill,
	lineFill,
	refreshData,
}: Props) => {
	const { state, actions } = useCasterDashboardContext();
	const [comparisonDate, setComparisonDate] = useState<Date | null>(null);
	const [delayId, setDelayId] = useState<number | null>(null);
	const navigate = useNavigate();
	const { shiftInfo } = useShiftNavigator();
	const {
		getGanttMenuOptions,
		getGanttDelaysMenuOptions,
		showEditModal,
		setShowEditModal,
		showLinkModal,
		setShowLinkModal,
		showJustifyModal,
		setShowJustifyModal,
		showSplitModal,
		setShowSplitModal,
		showDeleteModal,
		messageDeleteModal,
		setShowDeleteModal,
		setMessageDeleteModal,
		messageLinkConfirmation,
		setMessageLinkConfirmation,
		showUnlinkModal,
		messageUnlinkModal,
		setShowUnlinkModal,
		setMessageUnlinkModal,
	} = useGanttOptionsContextMenu();

	const { showContextMenu, registerConfig } = useContextMenuMESF();

	const selectProcessGantt = (process: Task) => {
		if ((process.source as Process).ProcessCode === "C")
			navigate(`/caster-data-entry/${process.source.ProcessId}`);

		if ((process.source as Process).ProcessCode === "E")
			navigate(`/eaf-data-entry/${process.source.ProcessId}`);
	};

	const handleUnlinkProcess = async (value?: string) => {
		setShowUnlinkModal(false);
		setMessageUnlinkModal("");
		if (value !== "OK") return;
		if (state.selectedProcess === null) return;
		actions.setIsLoadingBackground(true);

		//Use the unlinkProcess function based on the process code
		const unlinkProcess =
			state.selectedProcess.ProcessCode === "C"
				? unlinkCasterProcess
				: unLinkEAFProcess;

		const resp = await unlinkProcess(state.selectedProcess.ProcessId);
		if (resp.ok) {
			actions.setIsLoadingBackground(false);
			actions.setNotificationMessage("Process unlinked successfully");
			await refreshData();
		} else {
			actions.setIsLoadingBackground(false);
			actions.setErrorMessage(resp.message);
		}
	};
	const handleDeleteProcess = async (value?: string) => {
		setShowDeleteModal(false);
		setMessageDeleteModal("");
		if (value !== "OK") return;
		if (state.selectedProcess === null) return;
		actions.setIsLoadingBackground(true);
		const resp = await deleteProcess(state.selectedProcess.ProcessId);
		if (resp.ok) {
			actions.setIsLoadingBackground(false);
			actions.setNotificationMessage("Process deleted successfully");
			await refreshData();
		} else {
			actions.setIsLoadingBackground(false);
			actions.setErrorMessage(resp.message);
		}
	};

	useEffect(() => {
		registerConfig({
			id: "ganttSection",
			getOptions: getGanttMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);

	useEffect(() => {
		registerConfig({
			id: "ganttSectionDelaysMenu",
			getOptions: getGanttDelaysMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);

	return (
		<>
			<Ganttv2
				columnWidth={columnWidth}
				assets={assets}
				showArrowTriangle={showArrowTriangle}
				startCalendarDate={startCalendarDate}
				endCalendarDate={endCalendarDate}
				rowHeight={rowHeight}
				barFill={barFill}
				lineSectionFill={lineSectionFill}
				lineFill={lineFill}
				onDoubleClick={(process: Task) => selectProcessGantt(process)}
				onContextMenu={(event: any, data: any) => {
					if (data.timestamp) {
						setComparisonDate(data.timestamp);
					}
					if (data.type === "asset") {
						actions.setSelectedAsset(data.asset);
					}
					if (data.type !== "taskLine") {
						showContextMenu(event, data, "ganttSection");
					}
					if (data.type === "taskLine" && data.eventCode === "D") {
						setDelayId(data.DelayId);
						showContextMenu(event, data, "ganttSectionDelaysMenu");
					}
				}}
			/>
			<ConfirmationDialogRaw
				title="Warning!"
				message={messageLinkConfirmation}
				open={!!messageLinkConfirmation}
				onClose={(param) => {
					if (param === "OK") setShowLinkModal(true);
					setMessageLinkConfirmation("");
				}}
			/>
			<ConfirmationDialogRaw
				title="Confirmation"
				message={messageUnlinkModal}
				open={showUnlinkModal}
				onClose={handleUnlinkProcess}
			/>
			<ConfirmationDialogRaw
				title="Confirmation"
				message={messageDeleteModal}
				open={showDeleteModal}
				onClose={handleDeleteProcess}
			/>
			<LinkProcess
				show={showLinkModal}
				onHide={async () => {
					setShowLinkModal(false);
					actions.setNotificationMessage("Process linked successfully");
					await refreshData();
				}}
				process={state.selectedProcess}
			/>
			<UpsertProcess
				show={showEditModal}
				onHide={() => setShowEditModal(false)}
				refreshData={refreshData}
				comparisonDate={comparisonDate}
			/>
			<UpsertJustifyDelay
				show={showJustifyModal}
				onHide={() => setShowJustifyModal(false)}
				refreshData={refreshData}
				ifJustified={true}
				delayId={delayId}
			/>
		</>
	);
};
