import {
	Box,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
} from "@material-ui/core";
import {
	ArrowBackRounded,
	ArrowForwardRounded,
	Link,
	LinkOff,
	SkipNext,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { useDataEntryContext } from "../context/reducer.provider";
import { useTimerContext } from "../context/timer.provider";
import { useEAFDataEntryActions } from "../hooks/useEAFDataEntryActions";
import { useLinkUnlinkProcess } from "../hooks/useLinkUnlinkProcess";
import { Process } from "../models/eaf.models";
import {
	getLastestProcesses,
	searchProcesses,
} from "../repositories/eaf.repositories";
import { useStyles } from "../styles/useDEStyles";
import { LinkEAFProcess } from "./modals/LinkEAFDE";

type HeatProps = {
	Name: string;
	Id?: number;
};

export const ProcessNavigator = () => {
	const navigate = useNavigate();
	const params = useParams();
	const {
		state: { deProcess },
		actions: { setErrorMessage },
	} = useDataEntryContext();
	const process = deProcess?.process;
	const [heats, setHeats] = useState<HeatProps[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [loading, setLoading] = useState(false);

	const [selectedHeat, setSelectedHeat] = useState<HeatProps | null>({
		Name: loading ? "" : process?.HeatName,
		Id: process?.ProcessId,
	});
	const [isFocused, setFocused] = useState(false);

	const classes = useStyles();

	const { pause, resume } = useTimerContext();
	const { loadData } = useEAFDataEntryActions();

	const {
		handleLinkHeat,
		handleUnlinkHeat,
		showLinkModal,
		showUnlinkModal,
		unlinkProcess,
		setShowLinkModal,
	} = useLinkUnlinkProcess(process || ({} as Process));

	const processNavigatorClass = `${classes.processNavigator} ${process?.HeatName ? classes.linkedHeat : classes.unlinkedHeat}`;
	const currentSearch = useRef(searchTerm);

	currentSearch.current = searchTerm;

	const debouncedSearch = useCallback(
		debounce(async (term: string) => {
			setLoading(true);
			try {
				if (term.length >= 2) {
					const resp = await searchProcesses(term);
					if (resp.ok) {
						const data = resp.data.tables[0].rows;
						if (currentSearch.current === term) setHeats(data);
						params;
					} else {
						setErrorMessage(resp.message);
						setHeats([]);
					}
				}
				if (term.length === 0) {
					const resp = await getLastestProcesses();
					if (resp.ok) {
						if (currentSearch.current === term)
							setHeats(resp.data.tables[0].rows);
					} else {
						setErrorMessage(resp.message);
						setHeats([]);
					}
				}
			} catch (error) {
				console.error("Error in search:", error);
				setErrorMessage("An error occurred while searching");
				setHeats([]);
			} finally {
				setLoading(false);
			}
		}, 1500),
		[],
	);

	const handleSelectionChange = (event: any, newValue: HeatProps | null) => {
		if (newValue) {
			if (newValue.Id !== process?.ProcessId) {
				setSelectedHeat(newValue);
				navigate(`/eaf-data-entry/${newValue.Id}`);
				if (newValue.Id) {
					loadData(newValue.Id);
				}

				setTimeout(() => {
					document.getElementById("dummyDiv")?.focus();
				}, 500);
			}
		} else {
			setSelectedHeat(null);
		}
	};

	useEffect(() => {
		debouncedSearch(searchTerm);
		return () => {
			debouncedSearch.cancel();
		};
	}, [searchTerm]);

	useEffect(() => {
		if (!isFocused && !loading) {
			if (searchTerm !== process?.HeatName) {
				setSelectedHeat({
					Name: process?.HeatName,
					Id: process?.ProcessId!,
				});
			}
		}
	}, [isFocused, searchTerm, process, loading]);

	useEffect(() => {
		if (params.processId) {
			loadData(Number(params.processId)).then(() => {});
		} else {
			loadData(null).then(() => {});
		}
		debouncedSearch("");
	}, []);
	return (
		<>
			<Paper>
				<Box padding={1}>
					<Grid
						container
						direction={"row"}
						justifyContent={"space-around"}
						alignItems={"center"}
						spacing={1}
					>
						<Grid item>
							<IconButton
								size={"small"}
								aria-label="previous"
								color={"primary"}
								disabled={!process?.PrevProcessId}
								onClick={() => {
									loadData(process?.PrevProcessId!);
								}}
							>
								<ArrowBackRounded style={{ cursor: "pointer" }} />
							</IconButton>
						</Grid>
						<Grid item style={{ maxWidth: "150px" }}>
							<Autocomplete
								className={processNavigatorClass}
								autoHighlight
								blurOnSelect
								clearOnEscape
								selectOnFocus
								disableClearable={true}
								popupIcon={null}
								options={heats || []}
								getOptionLabel={(option) => option?.Name || "Unlinked"}
								inputValue={searchTerm}
								onInputChange={(event, newInputValue) => {
									setSearchTerm(newInputValue);
								}}
								value={selectedHeat}
								onChange={(event, newValue) => {
									handleSelectionChange(event, newValue);
									// if (newValue) {
									// 	setSelectedHeat(newValue);
									// 	loadData(newValue.Id!);
									// }
								}}
								onFocus={(ev) => {
									setFocused(true);
									pause();
								}}
								loading={loading}
								onBlur={(ev) => {
									setFocused(false);
									resume();
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Heat Name"
										variant="outlined"
										size="small"
										InputProps={{
											...params.InputProps,
											style: { padding: "0 17px 20px 5px" },
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														size="small"
														title={process?.HeatName ? "Unlink" : "Link"}
														onClick={(e) => {
															pause();
															return (
																process &&
																(process?.HeatName
																	? unlinkProcess()
																	: handleLinkHeat(e, process))
															);
														}}
														style={{
															color: process?.HeatName ? "red" : "#3f51b5",
														}}
													>
														{process?.HeatName ? (
															<LinkOff style={{ cursor: "pointer" }} />
														) : (
															<Link style={{ cursor: "pointer" }} />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<IconButton
								aria-label="delete"
								color={"primary"}
								disabled={!process?.NextProcessId}
								onClick={() => {
									loadData(process?.NextProcessId!);
								}}
								size="small"
							>
								<ArrowForwardRounded style={{ cursor: "pointer" }} />
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton
								aria-label="delete"
								color={"primary"}
								disabled={!process?.NextProcessId}
								onClick={() => {
									loadData(null);
								}}
								size="small"
								title="Last process"
							>
								<SkipNext style={{ cursor: "pointer" }} />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			<ConfirmationDialogRaw
				title="Confirmation"
				message={showUnlinkModal.message}
				open={showUnlinkModal.show}
				onClose={handleUnlinkHeat}
			/>
			<LinkEAFProcess
				show={showLinkModal}
				onHide={() => {
					setShowLinkModal(false);
					resume();
				}}
			/>
		</>
	);
};
