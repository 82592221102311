import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	useUTLSettingsContext,
} from "@dexteel/mesf-core";
import { Button, CircularProgress, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useModalStyles } from "../../../../eaf-processes/styles/modalStyles";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Probe } from "../../../models/eaf.models";
import { DateTimePickerDE } from "../../form-fields/common/DateTimePickerDE";
import { NumberTextFieldDE } from "../../form-fields/common/NumberTextFieldDE";
import { validateField } from "../../tables/utils/validateMinMax";
type Props = {
	show: boolean;
	onHide: () => void;
	probe: Probe;
};
export const EditProbe = ({ show, onHide, probe }: Props) => {
	const { state } = useUTLSettingsContext();
	const MIN_TEMP_MANUAL = get(state.settings, "MAX_TEMP_PROBE", 2500);
	const MAX_TEMP_MANUAL = get(state.settings, "MIN_TEMP_PROBE", 3500);

	const MIN_O2_MANUAL = get(state.settings, "MAX_02_PROBE", 0);
	const MAX_O2_MANUAL = get(state.settings, "MIN_02_PROBE", 2000);

	const { saveProbe } = useEAFDataEntryActions();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const classes = useModalStyles();
	const {
		getValues,
		handleSubmit,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm<Probe>({});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<any> = async (data: any) => {
		setIsSubmitLoading(true);
		const resp = await saveProbe({ ...data, ProbeId: probe.ProbeId });
		setIsSubmitLoading(false);
		onHide();
	};
	useEffect(() => {
		if (show) {
			reset();
			setValue("Timestamp", probe?.Timestamp);
			setValue("Temperature", probe?.Temperature);
			setValue("Oxygen", probe?.Oxygen);
		}
	}, [show]);
	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						title="EDIT MANUAL PROBE"
						open={show}
						handleClose={() => onHide()}
						id="edit-probe-modal"
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content dividers style={{ padding: "15px 30px" }}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Controller
											name="Temperature"
											control={control}
											defaultValue={
												probe?.Temperature
													? parseInt(probe?.Temperature.toFixed(0))
													: 0
											}
											rules={{
												required: "Temperature is required",
												validate: (value) =>
													validateField(
														value,
														MIN_TEMP_MANUAL,
														MAX_TEMP_MANUAL,
														"Temperature",
													),
											}}
											render={({ field }) => (
												<NumberTextFieldDE
													{...field}
													value={parseInt(getValues("Temperature").toFixed(0))}
													label="Temperature [F]"
													onChange={(val) => setValue("Temperature", val)}
													onBlur={() => {}}
												/>
											)}
										/>
										{errors.Temperature && (
											<span className={classes.errorLabel}>
												{errors.Temperature.message}
											</span>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="Oxygen"
											control={control}
											rules={{
												validate: (value) =>
													validateField(
														value,
														MIN_O2_MANUAL,
														MAX_O2_MANUAL,
														"Oxygen",
													),
											}}
											defaultValue={probe?.Oxygen}
											render={({ field }) => (
												<NumberTextFieldDE
													{...field}
													value={getValues("Oxygen")}
													label="Oxygen"
													onChange={(val) => setValue("Oxygen", val)}
													onBlur={() => {}}
												/>
											)}
										/>
										{errors.Oxygen && (
											<span className={classes.errorLabel}>
												{errors.Oxygen.message}
											</span>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="Timestamp"
											control={control}
											defaultValue={probe?.Timestamp}
											rules={{ required: "Timestamp is required" }}
											render={({ field }) => (
												<DateTimePickerDE
													{...field}
													format={"HH:mm"}
													disableOpenPicker
													value={getValues("Timestamp")}
													label="Time"
													onChange={(val) => setValue("Timestamp", val)}
													onBlur={() => {}}
												/>
											)}
										/>
										{errors.Timestamp && (
											<span className={classes.errorLabel}>
												{errors.Timestamp.message}
											</span>
										)}
									</Grid>
								</Grid>
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											size="large"
											onClick={() => onHide()}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12}>
										<ButtonWithLoading
											fullWidth
											isLoading={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
											size="large"
										>
											Save
										</ButtonWithLoading>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The probe was edited successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Editing Manual Probe"
			/>
		</>
	);
};
