import { createSlice } from "@reduxjs/toolkit";
import { HealthStatusTableModel } from "../models/HealtStatus";
const HealthStatusInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	errorMessage: string;
	notificationMessage: string;
	details: HealthStatusTableModel[];
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	errorMessage: "",
	notificationMessage: "",
	details: [],
};

export const HealthStatusReducer = createSlice({
	name: "__",
	initialState: HealthStatusInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setDetails(state, { payload }) {
			state.details = payload;
		},
	},
});
