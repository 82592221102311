import React, { useMemo } from "react";
import { calculateLevels } from "../../helpers/table-helper";
import { HeaderCellSetup } from "../../types/table-header";
import { ReportHeaderRow } from "./header-row";

export type ReportHeaderProps = {
	cells: HeaderCellSetup[];
	transposed: boolean;
	transposeBy: string;
	autoAlignHeaderNumber: boolean;
};

export const ReportHeader: React.FC<ReportHeaderProps> = ({
	cells,
	transposed,
	autoAlignHeaderNumber,
}) => {
	const levels = useMemo(() => {
		return calculateLevels(cells);
	}, [cells]);
	return (
		<thead>
			{[...Array(levels)].map((level, i) => (
				<ReportHeaderRow
					key={`level-${i}`}
					{...{
						cells,
						level: i + 1,
						totalLevel: levels,
						transposed,
						autoAlignHeaderNumber,
					}}
				/>
			))}
		</thead>
	);
};
