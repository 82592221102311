import * as React from "react";

import { CatalogGradeProvider } from "./CatalogGradeContext";
import { CatalogGradePage } from "./CatalogGradePage";

type Props = {};
const CatalogGrade = (props: Props) => {
	return (
		<CatalogGradeProvider>
			<CatalogGradePage />
		</CatalogGradeProvider>
	);
};
export default CatalogGrade;
