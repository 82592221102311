import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import {
	CATALOG_BUCKET_INITIAL_VALUES,
	CatalogBucket,
} from "../../../models/CatalogBucket";
import {
	getCatalogBucket,
	upsertCatalogBucket,
} from "../../../repositories/CatalogBucketRepository";
import { useModalStyles } from "../../../styles/modalStyles";

type Props = {
	BucketId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const EditBucket = ({ BucketId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CatalogBucket>({
		defaultValues: CATALOG_BUCKET_INITIAL_VALUES,
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit: SubmitHandler<CatalogBucket> = async (data) => {
		setIsSubmitLoading(true);
		const editBucketResp = await upsertCatalogBucket({ ...data, BucketId });
		if (editBucketResp.ok) {
			setOpen(true);
			onHide(true);
		} else {
			setError(editBucketResp.message);
		}
		setIsSubmitLoading(false);
	};

	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			setIsLoading(true);
			getCatalogBucket(BucketId).then((response) => {
				if (response.ok) {
					const bucket = response.data.tables[0].rows[0];
					if (bucket) {
						reset(bucket);
					}
				}
				setIsLoading(false);
			});
		}
	}, [show, BucketId, reset]);

	return (
		<>
			<MesfModal
				title="EDIT BUCKET"
				open={show}
				handleClose={() => onHide(false)}
				id="edit-bucket-modal"
				maxWidth="lg"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading ? (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						) : (
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Controller
										name="BucketName"
										control={control}
										rules={{ required: "Name is required" }}
										render={({ field }) => (
											<TextField
												{...field}
												label="Name"
												variant="outlined"
												error={!!errors.BucketName}
												helperText={errors.BucketName?.message}
												fullWidth
												margin="dense"
												autoComplete="off"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="Capacity"
										control={control}
										rules={{
											required: "Capacity is required",
											min: { value: 0, message: "Capacity must be positive" },
										}}
										render={({ field }) => (
											<TextField
												{...field}
												label="Capacity"
												type="number"
												variant="outlined"
												error={!!errors.Capacity}
												helperText={errors.Capacity?.message}
												fullWidth
												margin="dense"
												autoComplete="off"
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => onHide(false)}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									startIcon={
										isSubmitLoading ? <CircularProgress size="1rem" /> : <></>
									}
									disabled={isSubmitLoading}
									variant="contained"
									color="primary"
									type="submit"
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The bucket was edited successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Editing Bucket"
			/>
		</>
	);
};
