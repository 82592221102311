import { createSlice } from "@reduxjs/toolkit";
import { CatalogElement } from "../../models/CatalogElement";

const CatalogElementInitialState: {
	elements: CatalogElement[] | null;
} = {
	elements: null,
};

export const CatalogElementReducer = createSlice({
	name: "__",
	initialState: CatalogElementInitialState,
	reducers: {
		setElements(state, { payload }) {
			state.elements = payload;
		},
	},
});
