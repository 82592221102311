import { BarAsset } from "../types/bar-asset";
import { BarTask, TaskTypeInternal } from "../types/bar-task";
import { Asset, Task } from "../types/public-types";
interface ConvertableToGraphic {
	ganttObject: Task;
	index: number;
	indexAsset: number;
	indexLine: number;
	totalLines: number;
	dates: Date[];
	dateDelta: number;
	columnWidth: number;
	rowHeight: number;
	processHeight: number;
	lineSectionHeight: number;
	lineHeight: number;
	barCornerRadius: number;
	handleWidth: number;
	barProgressColor: string;
	barProgressSelectedColor: string;
	barBackgroundColor: string;
	barBackgroundSelectedColor: string;
	barBorderColor: string;
	borderWidth: number;
}

export const convertToBarAssets = (
	assets: Asset[],
	dates: Date[],
	columnWidth: number,
	rowHeight: number,
	processHeight: number,
	lineSectionHeight: number,
	lineHeight: number,
	barCornerRadius: number,
	handleWidth: number,
	barProgressColor: string,
	barProgressSelectedColor: string,
	barBackgroundColor: string,
	barBackgroundSelectedColor: string,
	barBorderColor: string,
	borderWidth: number,
): BarAsset[] => {
	const dateDelta =
		dates[1].getTime() -
		dates[0].getTime() -
		dates[1].getTimezoneOffset() * 60 * 1000 +
		dates[0].getTimezoneOffset() * 60 * 1000;
	const barAssets = assets.map((asset, i) => {
		return convertToBarAsset(
			asset,
			i,
			dates,
			dateDelta,
			columnWidth,
			rowHeight,
			processHeight,
			lineSectionHeight,
			lineHeight,
			barCornerRadius,
			handleWidth,
			barProgressColor,
			barProgressSelectedColor,
			barBackgroundColor,
			barBackgroundSelectedColor,
			barBorderColor,
			borderWidth,
		);
	});
	return barAssets;
};
export const convertToBarAsset = (
	asset: Asset,
	index: number,
	dates: Date[],
	dateDelta: number,
	columnWidth: number,
	rowHeight: number,
	processHeight: number,
	lineSectionHeight: number,
	lineHeight: number,
	barCornerRadius: number,
	handleWidth: number,
	barProgressColor: string,
	barProgressSelectedColor: string,
	barBackgroundColor: string,
	barBackgroundSelectedColor: string,
	barBorderColor: string,
	borderWidth: number,
): BarAsset => {
	const barAsset: BarAsset = {
		...asset,
		index: index,
		barTasks: [],
	};
	let indexGraphic = 0;

	// Processes
	barAsset.barTasks = (asset.processes || []).map((process) => {
		const barProcess = convertToGraphic({
			ganttObject: process,
			index: indexGraphic++,
			indexAsset: barAsset.index,
			indexLine: 0,
			totalLines: 0,
			dates,
			dateDelta,
			columnWidth,
			rowHeight,
			processHeight,
			lineSectionHeight,
			lineHeight,
			barCornerRadius,
			handleWidth,
			barProgressColor,
			barProgressSelectedColor,
			barBackgroundColor,
			barBackgroundSelectedColor,
			barBorderColor,
			borderWidth,
		});
		return barProcess;
	});
	const compareEventCodes = (a: string, b: string): number => {
		if (asset.id === "11" || asset.id === "12") {
			if (a === "H" && b === "D") return -1;
			if (a === "D" && b === "H") return 1;
		}
		const numA = parseInt(a, 10);
		const numB = parseInt(b, 10);
		if (!isNaN(numA) && !isNaN(numB)) {
			return numA - numB;
		}
		return a.localeCompare(b);
	};
	// Collect all unique event codes from the processes
	const uniqueEventCodes = new Set<string>();
	asset.processes.forEach((process) => {
		process.processLines.forEach((line) => {
			uniqueEventCodes.add(line.eventCode);
		});
	});

	// Sort event codes in ascending order
	const uniqueEventCodesArray =
		Array.from(uniqueEventCodes).sort(compareEventCodes);
	const totalLines = uniqueEventCodesArray.length;

	// Lines
	asset.processes.forEach((process) => {
		process.processLines.forEach((line) => {
			const indexLine = uniqueEventCodesArray.indexOf(line.eventCode);

			const task: Task = {
				source: line,
				type: line.type,
				start: line.start,
				end: line.end,
				id: line.id,
				name: line.name,
				processLines: [],
				progress: 100,
				styles: line.styles,
			};

			// Convert the task to its graphic representation
			const graphicObject = convertToGraphic({
				ganttObject: task,
				index: indexGraphic++,
				indexAsset: barAsset.index,
				indexLine,
				totalLines,
				dates,
				dateDelta,
				columnWidth,
				rowHeight,
				processHeight,
				lineSectionHeight,
				lineHeight,
				barCornerRadius,
				handleWidth,
				barProgressColor,
				barProgressSelectedColor,
				barBackgroundColor,
				barBackgroundSelectedColor,
				barBorderColor,
				borderWidth,
			});
			barAsset.barTasks.push(graphicObject);
		});
	});

	// Set dependencies between bar tasks
	barAsset.barTasks = (barAsset.barTasks || []).map((process) => {
		const dependencies = process.dependencies || [];
		for (let j = 0; j < dependencies.length; j++) {
			const dependence = (barAsset.barTasks || []).findIndex(
				(value) => value.id === dependencies[j],
			);
			if (dependence !== -1)
				(barAsset.barTasks || [])[dependence].barChildren.push(process);
		}
		return process;
	});

	return barAsset;
};

const convertToGraphic = ({
	ganttObject,
	index,
	indexAsset,
	indexLine,
	totalLines,
	dates,
	dateDelta,
	columnWidth,
	rowHeight,
	processHeight,
	lineSectionHeight,
	lineHeight,
	barCornerRadius,
	handleWidth,
	barProgressColor,
	barProgressSelectedColor,
	barBackgroundColor,
	barBackgroundSelectedColor,
	barBorderColor,
	borderWidth,
}: ConvertableToGraphic): BarTask => {
	let barAsset: BarTask;
	switch (ganttObject.type) {
		case "taskLine":
			barAsset = convertToBarLine({
				asset: ganttObject,
				index,
				indexAsset,
				indexLine,
				totalLines,
				dates,
				dateDelta,
				columnWidth,
				rowHeight,
				processHeight,
				lineSectionHeight,
				lineHeight,
				barCornerRadius,
				handleWidth,
				barProgressColor,
				barProgressSelectedColor,
				barBackgroundColor,
				barBackgroundSelectedColor,
				barBorderColor,
				borderWidth,
			});
			break;
		default:
			barAsset = convertToBar(
				ganttObject,
				index,
				indexAsset,
				dates,
				dateDelta,
				columnWidth,
				rowHeight,
				processHeight,
				lineSectionHeight,
				barCornerRadius,
				handleWidth,
				barProgressColor,
				barProgressSelectedColor,
				barBackgroundColor,
				barBackgroundSelectedColor,
				barBorderColor,
				borderWidth,
			);
			break;
	}
	return barAsset;
};
const convertToBar = (
	asset: Task,
	index: number,
	indexAsset: number,
	dates: Date[],
	dateDelta: number,
	columnWidth: number,
	rowHeight: number,
	processHeight: number,
	lineSectionHeight: number,
	barCornerRadius: number,
	handleWidth: number,
	barProgressColor: string,
	barProgressSelectedColor: string,
	barBackgroundColor: string,
	barBackgroundSelectedColor: string,
	barBorderColor: string,
	borderWidth: number,
): BarTask => {
	let x1: number;
	let x2: number;
	x1 = assetXCoordinate(asset.start, dates, dateDelta, columnWidth);
	x2 = assetXCoordinate(asset.end, dates, dateDelta, columnWidth);

	let typeInternal: TaskTypeInternal = asset.type;
	if (typeInternal === "task" && x2 - x1 < handleWidth * 2) {
		typeInternal = "smalltask";
		x2 = x1 + handleWidth * 2;
	}

	const [progressWidth, progressX] = progressWithByParams(
		x1,
		x2,
		asset.progress,
	);
	const y = processYCoordinate(
		indexAsset,
		rowHeight,
		processHeight,
		lineSectionHeight,
	);

	const styles = {
		backgroundColor: barBackgroundColor,
		backgroundSelectedColor: barBackgroundSelectedColor,
		progressColor: barProgressColor,
		progressSelectedColor: barProgressSelectedColor,
		borderColor: barBorderColor,
		borderWidth: borderWidth,
		...asset.styles,
	};
	return {
		...asset,
		typeInternal,
		x1,
		x2,
		y,
		index,
		progressX,
		progressWidth,
		barCornerRadius,
		handleWidth,
		height: processHeight,
		barChildren: [],
		styles,
		key: `bar-${index}`,
	};
};
interface ConvertToBarLineParams {
	asset: Task;
	index: number;
	indexAsset: number;
	indexLine: number;
	totalLines: number;
	dates: Date[];
	dateDelta: number;
	columnWidth: number;
	rowHeight: number;
	processHeight: number;
	lineSectionHeight: number;
	lineHeight: number;
	barCornerRadius: number;
	handleWidth: number;
	barProgressColor: string;
	barProgressSelectedColor: string;
	barBackgroundColor: string;
	barBackgroundSelectedColor: string;
	barBorderColor: string;
	borderWidth: number;
}
const convertToBarLine = ({
	asset,
	index,
	indexAsset,
	indexLine,
	totalLines,
	dates,
	dateDelta,
	columnWidth,
	rowHeight,
	processHeight,
	lineSectionHeight,
	lineHeight,
	barCornerRadius,
	handleWidth,
	barProgressColor,
	barProgressSelectedColor,
	barBackgroundColor,
	barBackgroundSelectedColor,
	barBorderColor,
	borderWidth,
}: ConvertToBarLineParams): BarTask => {
	let x1: number;
	let x2: number;
	x1 = assetXCoordinate(asset.start, dates, dateDelta, columnWidth);
	x2 = assetXCoordinate(asset.end, dates, dateDelta, columnWidth);

	let typeInternal: TaskTypeInternal = asset.type;
	if (typeInternal === "task" && x2 - x1 < handleWidth * 2) {
		typeInternal = "smalltask";
		x2 = x1 + handleWidth * 2;
	}
	const [progressWidth, progressX] = progressWithByParams(
		x1,
		x2,
		asset.progress,
	);
	const y = lineYCoordinate(
		indexAsset,
		rowHeight,
		processHeight,
		lineSectionHeight,
		lineHeight,
		indexLine,
		totalLines,
	);
	const styles = {
		backgroundColor: "blue", // default value is 'rgba(0, 0, 0, 0.1)' if asset.styles?.backgroundColor is undefined, null, etc
		backgroundSelectedColor: "blue",
		progressColor: "blue",
		progressSelectedColor: "blue",
		borderColor: "blue",
		borderWidth: borderWidth,
		...asset.styles,
	};
	return {
		...asset,
		typeInternal,
		x1,
		x2,
		y,
		index,
		progressX,
		progressWidth,
		barCornerRadius,
		handleWidth,
		height: lineHeight,
		barChildren: [],
		styles,
		key: `line-${index}`,
	};
};
export const xCoordinateToDate = (
	x: number,
	dates: Date[],
	columnWidth: number,
) => {
	const dateDelta =
		dates[1].getTime() -
		dates[0].getTime() -
		dates[1].getTimezoneOffset() * 60 * 1000 +
		dates[0].getTimezoneOffset() * 60 * 1000;

	// First, we find the approximate index
	let index = Math.floor(x / columnWidth);

	// Ensure the index is within bounds
	if (index < 0) index = 0;
	if (index >= dates.length) index = dates.length - 1;

	// Calculate the offset within the column
	const offsetInColumn = x - index * columnWidth;
	const offsetInTime = (offsetInColumn / columnWidth) * dateDelta;

	// Get the base date at the index
	const baseDate = dates[index];

	// Create the new date adjusting for timezone
	const resultDate = new Date(
		baseDate.getTime() +
			offsetInTime +
			(baseDate.getTimezoneOffset() - new Date().getTimezoneOffset()) *
				60 *
				1000,
	);

	return resultDate;
};

const assetXCoordinate = (
	xDate: Date,
	dates: Date[],
	dateDelta: number,
	columnWidth: number,
) => {
	let index = ~~(
		(xDate.getTime() -
			dates[0].getTime() +
			xDate.getTimezoneOffset() -
			dates[0].getTimezoneOffset()) /
		dateDelta
	);
	if (index < 0) index = 0;
	if (index >= dates.length) index = dates.length - 1;
	const x = Math.round(
		(index +
			(xDate.getTime() -
				dates[index].getTime() -
				xDate.getTimezoneOffset() * 60 * 1000 +
				dates[index].getTimezoneOffset() * 60 * 1000) /
				dateDelta) *
			columnWidth,
	);
	return x;
};
const processYCoordinate = (
	index: number,
	rowHeight: number,
	processHeight: number,
	lineSectionHeight: number,
) => {
	const y =
		index * rowHeight + (rowHeight - processHeight - lineSectionHeight) / 2;
	return y;
};
const lineYCoordinate = (
	index: number,
	rowHeight: number, // asset height
	processHeight: number, // example: heat process height
	lineSectionHeight: number,
	lineHeight: number,
	indexLine: number,
	totalLines: number,
) => {
	const yProcess =
		index * rowHeight +
		processHeight +
		(rowHeight - processHeight - lineSectionHeight) / 2;

	let lineSpace = lineSectionHeight / totalLines;
	const y =
		totalLines === 1
			? yProcess + lineHeight
			: yProcess + lineSpace * indexLine + (lineSpace - lineHeight);
	return y;
};

export const progressWithByParams = (
	assetX1: number,
	assetX2: number,
	progress: number,
) => {
	const progressWidth = (assetX2 - assetX1) * progress * 0.01;
	let progressX: number;
	progressX = assetX1;
	return [progressWidth, progressX];
};
export const progressByProgressWidth = (
	progressWidth: number,
	barAsset: BarTask,
) => {
	const barWidth = barAsset.x2 - barAsset.x1;
	const progressPercent = Math.round((progressWidth * 100) / barWidth);
	if (progressPercent >= 100) return 100;
	else if (progressPercent <= 0) return 0;
	else return progressPercent;
};
