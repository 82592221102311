import * as React from "react";
import { MechanicalByHeatPage } from "./MechanicalByHeatPage";

import { MechanicalByHeatProvider } from "./MechanicalByHeatContext";

type Props = {};
const ChemistryByHeat = (props: Props) => {
	return (
		<MechanicalByHeatProvider>
			<MechanicalByHeatPage />
		</MechanicalByHeatProvider>
	);
};
export default ChemistryByHeat;
