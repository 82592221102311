import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	CasterHousing,
	CasterLowTonnageReason,
	CasterMoldSize,
	CasterPourbackReason,
	CasterScrapReason,
	CasterTundish,
	CasterTundishShroud,
	Ladle,
} from "../models/Catalogs";
import { ElementData } from "../models/ElementData";
import { ProbeData } from "../models/ProbeData";
import { ProcessData } from "../models/ProcessData";
import { StrandData } from "../models/StrandData";

const CastersInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	errorMessage: string;
	notificationMessage: string;
	refreshStatus: string;
	infoMessage: string;
	lazyStart: Date | null;
	processId: number | null | false;
	processData: ProcessData | null;
	canRefresh: boolean;
	refreshInPause: boolean;
	editingField: string;
	strandEditing: number | null;
	probeEditing: number | null;
	tundishes: CasterTundish[];
	strands: StrandData[] | null;
	element: ElementData | null;
	probes: ProbeData[] | null;
	pourBacks: CasterPourbackReason[] | null;
	moldSizes: CasterMoldSize[] | null;
	tundishShrouds: CasterTundishShroud[] | null;
	tonnageReasons: CasterLowTonnageReason[] | null;
	scrapReasons: CasterScrapReason[] | null;
	housings: CasterHousing[] | null;
	ladles: Ladle[] | null;

	//Refatoring
	entityEditting: {
		entity: "PROCESS" | "STRAND" | "PROBE" | null;
		index: number;
		field: string;
	} | null;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	errorMessage: "",
	notificationMessage: "",
	refreshStatus: "",
	infoMessage: "",
	lazyStart: null,
	processId: false,
	processData: null,
	canRefresh: true,
	refreshInPause: false,
	editingField: "",
	strandEditing: null,
	probeEditing: null,
	tundishes: [],
	strands: null,
	probes: null,
	pourBacks: null,
	moldSizes: null,
	tundishShrouds: null,
	tonnageReasons: null,
	element: null,
	scrapReasons: null,
	housings: null,
	ladles: null,

	//Refatoring
	entityEditting: null,
};

export const CastersReducer = createSlice({
	name: "__",
	initialState: CastersInitialState,
	reducers: {
		setRefreshInPause(state, { payload }) {
			if (typeof payload == "function") {
				state.refreshInPause = payload(state.refreshInPause);
			} else {
				state.refreshInPause = payload;
			}
		},
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setProcessId(state, { payload }) {
			state.processId = payload;
		},
		setProcess(state, { payload }) {
			const { processData, strands, probes, elements } = payload;
			state.processData = processData;
			state.strands = strands;
			state.probes = probes;
			state.element = elements;
		},
		setProbes(state, { payload }) {
			state.probes = payload;
		},
		setCatalogs(state, { payload }) {
			const {
				tundishes,
				pourBacks,
				moldSizes,
				tundishShrouds,
				tonnageReasons,
				scrapReasons,
				housings,
				ladles,
			} = payload;
			state.tundishes = tundishes;
			state.pourBacks = pourBacks;
			state.moldSizes = moldSizes;
			state.tundishShrouds = tundishShrouds;
			state.tonnageReasons = tonnageReasons;
			state.scrapReasons = scrapReasons;
			state.housings = housings;
			state.ladles = ladles;
		},
		addRefreshStatus(state, { payload }) {
			state.refreshStatus = payload;
		},
		getProbeFocusField(state, { payload }) {
			state.canRefresh = false;
			const { probeId, field } = payload;
			state.probeEditing = probeId;
			state.editingField = field;
			state.infoMessage = `<strong>Editing Tundish Id ${probeId} - ${field}</strong>...`;
			state.lazyStart = new Date();
		},
		lostFocusField(state) {
			state.entityEditting = null;
			state.canRefresh = true;
			state.editingField = "";
			state.strandEditing = null;
			state.probeEditing = null;
			state.infoMessage = "";
			state.lazyStart = null;
		},

		//Refatoring
		mergeProcess(
			state,
			action: PayloadAction<{
				processData: ProcessData;
				strands: StrandData[];
				probes: ProbeData[];
				elements: ElementData;
			}>,
		) {
			const { processData, strands, probes, elements } = action.payload;
			const addedProbe = (state.probes || []).find((p) => p.ProbeId === 0); // Get new Probe
			if (state.entityEditting === null) {
				state.processData = processData;
				state.strands = strands;
				state.probes = probes;
				state.element = elements;
			} else {
				const { entity, index, field } = state.entityEditting;
				//Process
				if (state.processData != null && entity === "PROCESS" && field !== "") {
					state.processData = {
						...processData,
						[field]: state.processData[field as keyof ProcessData],
					};
				} else state.processData = processData;
				//Strands
				if (state.strands !== null && entity === "STRAND" && index > -1) {
					const value = state.strands[index][field as keyof StrandData];
					const tempStrands = [...strands];
					tempStrands[index] = { ...state.strands[index], [field]: value };
					state.strands = tempStrands;
				} else state.strands = strands;
				//Probes
				if (state.probes !== null && entity === "PROBE" && index > -1) {
					const tempProbes = [...probes];
					if (state.probes[index]?.ProbeId === 0) {
						//added
					} else {
						const value = state.probes[index][field as keyof ProbeData];
						tempProbes[index] = { ...state.probes[index], [field]: value };
					}
					state.probes = tempProbes;
				} else state.probes = probes;
				state.element = elements;
			}
			if (addedProbe !== undefined && !addedProbe.IsAdded)
				state.probes = [...state.probes, addedProbe]; // Add new Probe
		},
		setFocusField(
			state,
			action: PayloadAction<{
				entity: "PROCESS" | "STRAND" | "PROBE";
				index: number;
				field: string;
			}>,
		) {
			const { entity, index, field } = action.payload;
			if (entity === "PROCESS" && state.processData === null) return;
			if (
				entity === "STRAND" &&
				(state.strands === null ||
					state.strands[index] === undefined ||
					!state.strands[index].IsStrandDataManual)
			)
				return;
			if (
				entity === "PROBE" &&
				(state.probes === null ||
					state.probes[index] === undefined ||
					!state.probes[index].IsProbeManual)
			)
				return;
			state.canRefresh = false;
			state.entityEditting = { entity: entity, index: index, field };
			state.lazyStart = new Date();
		},
		clearFocusField(state) {
			state.entityEditting = null;
			state.canRefresh = true;
			state.lazyStart = null;
		},
		updateStrandData(
			state,
			action: PayloadAction<{
				index: number;
				field: string;
				value: any;
				clear?: boolean;
				forced?: boolean;
			}>,
		) {
			const {
				index,
				field,
				value,
				clear = false,
				forced = false,
			} = action.payload;
			if (
				state.strands === null ||
				state.strands[index] === undefined ||
				(!state.strands[index].IsStrandDataManual &&
					field !== "IsStrandDataManual" &&
					!forced)
			)
				return;
			state.strands[index] = { ...state.strands[index], [field]: value };
			if (clear) {
				state.entityEditting = null;
				state.canRefresh = true;
				state.lazyStart = null;
			}
		},
		updateProcessData(
			state,
			action: PayloadAction<{ field: string; value: any; clear?: boolean }>,
		) {
			const { field, value, clear = false } = action.payload;
			if (state.processData === null) return;
			let ladleSuperHeat = state.processData.LadleSuperHeat || 0;
			if (field === "TrimProbeTemperature") {
				ladleSuperHeat =
					(state.processData.F1LiquidusTemperature || 0) -
					Number(value).valueOf();
			}
			state.processData = {
				...state.processData,
				[field]: value,
				LadleSuperHeat: ladleSuperHeat,
			};
			if (clear) {
				state.entityEditting = null;
				state.canRefresh = true;
				state.lazyStart = null;
			}
		},
		updateProbeData(
			state,
			action: PayloadAction<{
				index: number;
				field: string;
				value: any;
				clear?: boolean;
			}>,
		) {
			const { index, field, value, clear = false } = action.payload;
			if (
				state.probes === null ||
				state.probes[index] === undefined ||
				(!state.probes[index].IsProbeManual && field !== "IsProbeManual")
			)
				return;
			let superHeat = state.probes[index].SuperHeat || 0;
			if (field === "Temperature") {
				let superHeat =
					(state.processData?.F1LiquidusTemperature || 0) -
					Number(value).valueOf();
			}
			state.probes[index] = {
				...state.probes[index],
				[field]: value,
				SuperHeat: superHeat,
			};
			if (clear) {
				state.entityEditting = null;
				state.canRefresh = true;
				state.lazyStart = null;
			}
		},
		// UPSERT HEAT
		upsertHeatData(
			state,
			action: PayloadAction<{
				index: number;
				field: string;
				value: any;
				clear?: boolean;
			}>,
		) {
			const { index, field, value, clear = false } = action.payload;
			if (
				state.probes === null ||
				state.probes[index] === undefined ||
				(!state.probes[index].IsProbeManual && field !== "IsProbeManual")
			)
				return;
			let superHeat = state.probes[index].SuperHeat || 0;
			if (field === "Temperature") {
				let superHeat =
					(state.processData?.F1LiquidusTemperature || 0) -
					Number(value).valueOf();
			}
			state.probes[index] = {
				...state.probes[index],
				[field]: value,
				SuperHeat: superHeat,
			};
			if (clear) {
				state.entityEditting = null;
				state.canRefresh = true;
				state.lazyStart = null;
			}
		},

		addProbeData(state, action: PayloadAction<ProbeData>) {
			if (state.probes === null) return;
			state.probes.push(action.payload);

			state.entityEditting = {
				entity: "PROBE",
				index: state.probes.length - 1,
				field: "",
			};
			state.canRefresh = true;
			state.lazyStart = null;
		},
		deleteProbeData(state, action: PayloadAction<number>) {
			if (state.probes === null) return;
			state.probes = state.probes.filter(
				(probe) => probe.ProbeId !== action.payload,
			);
		},
		clearNewProbeData(state, action: PayloadAction<number>) {
			if (state.probes === null) return;
			state.probes[action.payload] = {
				...state.probes[action.payload],
				IsAdded: true,
			};
		},
	},
});
