import { TextField } from "@material-ui/core";
import { useTimerContext } from "../../../context/timer.provider";

interface TextFieldDEProps {
	value: string;
	label?: string;
	disabled?: boolean;
	onChange: (value: string) => void;
	onBlur: (value: string) => void;
}

export const TextFieldDE = ({
	value,
	label,
	onChange,
	onBlur,
	disabled = false,
}: TextFieldDEProps) => {
	const { pause, resume } = useTimerContext();
	const onFocus = () => {
		pause();
	};

	return (
		<TextField
			fullWidth
			disabled={disabled}
			label={label}
			variant={"outlined"}
			value={value || ""}
			onFocus={onFocus}
			onChange={(e) => {
				onChange(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					resume();
					onBlur(value);
				}
			}}
			onBlur={() => {
				resume();
				onBlur(value);
			}}
			style={{
				minWidth: "50px",
			}}
		/>
	);
};
