import { dxtToLocalServerTime, useShiftNavigator } from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-enterprise";
import { get } from "lodash-es";
import {
	LinkCellRenderer,
	ManualIconCellRenderer,
	NumericCellRenderer,
} from "../../../../controls/ag-grid/components/ButtonCellRenderer";
type Props = {};
export const useTableData = ({}: Props) => {
	const { shiftInfo } = useShiftNavigator();
	const shiftId = get(shiftInfo, "CurrentShiftId", null);
	const columns: ColDef[] = [
		{
			field: "HeatName",
			headerName: "Heat",
			cellRenderer: (params: any) => {
				return LinkCellRenderer({
					...params,
					link: `/meltshop-heats/${shiftId}?heatId=${params.data.HeatId}`,
				});
			},
			flex: 1,
			minWidth: 80,
		},
		{
			field: "GradeName",
			headerName: "Grade",
			flex: 1,
			minWidth: 80,
		},
		{
			field: "LadleName",
			headerName: "Ladle",
			flex: 1,
			minWidth: 80,
		},
		{
			field: "HeatOrder",
			headerName: "Order",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
		},
		{
			field: "MoldSizeName",
			headerName: "Mold Size",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
		},
		{
			field: "Start",
			headerName: "Open",
			cellRenderer: LinkCellRenderer,
			cellRendererParams: { linkProperty: "HeatNameLink" },
			flex: 1,
			minWidth: 80,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.Start}`, "HH:mm") || "",
		},
		{
			field: "End",
			headerName: "Close",
			flex: 1,
			minWidth: 80,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.End}`, "HH:mm") || "",
		},
		{
			field: "Duration",
			headerName: "Duration",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0.0" },
		},
		{
			field: "CastTons",
			headerName: "Tons",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0.0" },
		},
		{
			field: "ScrapTons",
			headerName: "Scrap",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0.00" },
		},

		{
			field: "TrimProbeTemperature",
			headerName: "Trim Temp.",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
		},
		{
			field: "FirstProbeTemperature",
			headerName: "First Temp.",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
		},
		{
			field: "LastProbeTemperature",
			headerName: "Last Temp.",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
		},
		{
			field: "LastSuperheat",
			headerName: "Last SH",
			type: ["numericColumn", "numericWidth"],
			flex: 1,
			minWidth: 80,
		},
		{
			field: "IsProcessManual",
			headerName: "",
			flex: 1,
			minWidth: 80,
			cellRenderer: ManualIconCellRenderer,
			type: ["nonSorteable", "nonFilterable", "centerAligned"],
			cellClass: "ag-button-cell",
		},
		{
			field: "Actions",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "optionsButton"],
			flex: 1,
			minWidth: 150,
			editable: false,
		},
	];

	return { columns };
};
