import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { getCatalogLadles } from "../../../repositories/CatalogLadleRepository";
import { TableLadle } from "../components/TableLadle";
import { useCatalogLadleContext } from "../context/CatalogLadleContext";

type Props = {};
export const CatalogLadlePage = (props: Props) => {
	const { state, actions } = useCatalogLadleContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const getCatalogLadlesFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogLadles();
		if (resp.ok) {
			actions.setLadles(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getCatalogLadlesFromAPI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Grid container>
				<Grid item md={12} xs={12} style={{ margin: 0 }}>
					<Paper
						elevation={1}
						style={{
							width: "100%",
							padding: "10px 0 20px",
							borderRadius: "0.75rem",
							height: "auto",
						}}
					>
						<TableLadle
							isLoading={isLoading}
							getCatalogLadlesFromAPI={getCatalogLadlesFromAPI}
						/>
					</Paper>
				</Grid>
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
