import { useEffect, useState } from "react";

interface MousePosition {
	x: number;
	y: number;
}

const useMousePosition = (): MousePosition => {
	const [mousePosition, setMousePosition] = useState<MousePosition>({
		x: 0,
		y: 0,
	});

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		const updateMousePosition = (ev: MouseEvent) => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			timeoutId = setTimeout(() => {
				setMousePosition({ x: ev.clientX, y: ev.clientY });
			}, 300); //  debounce time
		};

		window.addEventListener("mousemove", updateMousePosition);

		return () => {
			window.removeEventListener("mousemove", updateMousePosition);
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, []);

	return mousePosition;
};

export default useMousePosition;
