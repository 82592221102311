import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { ConfirmModal } from "../../../../../../controls/modals/ConfirmModal";
import {
	CATALOG_MOLD_INITIAL_VALUES,
	CatalogMold,
} from "../../../../models/catalogs/CatalogMold";
import { CatalogMoldSize } from "../../../../models/catalogs/CatalogMoldSize";
import { upsertCatalogMold } from "../../../../repositories/CatalogMoldRepository";
import { useModalStyles } from "../../../../styles/modalStyles";
import { useCatalogMoldSizeContext } from "../../../mold-sizes/context/CatalogMoldSizeContext";
import { useCatalogMoldContext } from "../../context/CatalogMoldContext";

type Props = {
	show: boolean;
	onHide: (moldCreated: boolean) => void;
};

export const CreateMold = ({ show, onHide }: Props) => {
	const { state } = useCatalogMoldContext();
	const classes = useModalStyles();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [existingMold, setExistingMold] = useState("");
	const {
		state: { moldSizes },
	} = useCatalogMoldSizeContext();
	const { control, getValues, handleSubmit, reset } = useForm<CatalogMold>({
		defaultValues: CATALOG_MOLD_INITIAL_VALUES,
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit: SubmitHandler<CatalogMold> = async (data: any) => {
		setIsSubmitLoading(true);
		const molds: CatalogMold[] = state.molds || [];
		if (molds.some((mold) => mold.MoldName === data.MoldName)) {
			setExistingMold(data.MoldName);
			setConfirmOpen(true);
		} else {
			await performUpsert(data);
		}
	};

	const performUpsert = async (data: CatalogMold) => {
		const createMoldResp = await upsertCatalogMold(data);
		if (createMoldResp.ok) {
			onHide(true);
			setOpen(true);
		} else {
			setError(createMoldResp.message);
			onHide(false);
		}
		setIsSubmitLoading(false);
	};

	const onConfirmModalHandler = async (proceed: boolean) => {
		setConfirmOpen(false);
		if (proceed) {
			await performUpsert(getValues());
		}
	};

	useEffect(() => {
		if (show) {
			reset();
			setConfirmOpen(false);
		}
	}, [show]);

	return (
		<>
			<ConfirmModal
				show={confirmOpen}
				onHide={onConfirmModalHandler}
				existingName={existingMold}
				label="Mold"
			/>
			<MesfModal
				title="NEW MOLD"
				open={show}
				handleClose={() => onHide(false)}
				id="create-mold-modal"
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content style={{ padding: "15px 30px" }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={8}>
								<Controller
									name="MoldName"
									control={control}
									rules={{ required: "Name is required" }}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											label="Name"
											variant="outlined"
											error={!!error}
											helperText={error ? error.message : null}
											fullWidth
											margin="dense"
											autoComplete="off"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={4} style={{ marginTop: 8 }}>
								<Controller
									name="MoldSizeId"
									control={control}
									rules={{ required: "Mold Size is required" }}
									render={({
										field: { onChange, value, ...rest },
										fieldState: { error },
									}) => (
										<Autocomplete
											id="clear-on-escape"
											clearOnEscape
											options={moldSizes ?? []}
											getOptionLabel={(option) => option.MoldSizeName}
											onChange={(event, newValue) => {
												onChange(newValue ? newValue.MoldSizeId : null);
											}}
											renderInput={(params) => (
												<>
													<TextField
														{...params}
														label="Mold Size"
														variant="outlined"
														error={!!error}
														helperText={error ? error.message : null}
														size="small"
														fullWidth
													/>
												</>
											)}
											value={
												moldSizes?.find(
													(moldSize: CatalogMoldSize) =>
														moldSize.MoldSizeId === value,
												) || null
											}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<FooterModalButtons
							isSubmitLoading={isSubmitLoading}
							onHide={onHide}
						/>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The mold was created successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Mold"
			/>
		</>
	);
};
