import { Button, Grid, Theme, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { ColDef, EditableCallbackParams } from "ag-grid-enterprise";
import { CatalogLadle } from "../../../models/CatalogLadle";
import { useCatalogLadleContext } from "../context/CatalogLadleContext";
const useStyles = makeStyles((theme: Theme) => ({
	buttons: {
		opacity: 0,
		transition: "opacity 0.1s ease-in-out",
		".ag-row-hover &": {
			opacity: 1,
		},
		".ag-row-focus &": {
			opacity: 1,
		},
		".ag-row-selected &": {
			opacity: 1,
		},
	},
}));
type Props = {
	setLadleId: Function;
	showContextMenu: (event: any, data: CatalogLadle, context: string) => void;
	setShowModal: (type: "update" | "create" | "delete") => void;
};
export const useTableData = ({
	setLadleId,
	setShowModal,
	showContextMenu,
}: Props) => {
	const { state } = useCatalogLadleContext();
	const classes = useStyles();

	const rows: CatalogLadle[] =
		state.ladles?.map((ladle: CatalogLadle) => ({
			...ladle,
		})) || [];
	const columnDefs: ColDef[] = [
		{
			field: "LadleName",
			headerName: "Name",
			flex: 1,
			minWidth: 63,
			filter: false,
			autoHeaderHeight: true,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		{
			cellRenderer: (params: EditableCallbackParams) => {
				return (
					<Grid
						container
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						className={classes.buttons}
					>
						<Grid
							item
							xs={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Button
								onClick={() => {
									(async () => {
										setLadleId(params.data.LadleId);
										setShowModal("update");
									})();
								}}
								title="Edit"
							>
								<EditIcon style={{ height: "auto" }} color="primary" />
							</Button>
							<Button
								onClick={() => {
									(async () => {
										setLadleId(params.data.LadleId);
										setShowModal("delete");
									})();
								}}
								title="Delete"
							>
								<DeleteIcon style={{ height: "auto" }} color="secondary" />
							</Button>
							<Button
								style={{}}
								onClick={(e) => showContextMenu(e, params.data, "ladleContext")}
								title="Options"
							>
								<FormatListBulletedSharpIcon
									style={{ height: "auto" }}
									color="action"
								/>
							</Button>
						</Grid>
					</Grid>
				);
			},
			wrapText: false,
			autoHeight: false,
			wrapHeaderText: false,
			headerName: "",
			sortable: false,
			flex: 5,
			maxWidth: 200,
			filter: false,
			autoHeaderHeight: true,
		},
	];
	return { rows, columnDefs };
};
