import { Button, Grid } from "@material-ui/core";
import { Probe } from "../../../models/eaf.models";
import { useManualAutoButtonStyles } from "../styles/useEAFDETablesStyles";

interface Props {
	isManual: Probe;
}

export const ManualAutoButton = ({ isManual }: Props) => {
	const classes = useManualAutoButtonStyles();
	return (
		<Grid container justifyContent="center">
			<Grid item md={12}>
				<Button
					title={isManual ? "Manual" : "Automatic"}
					variant={"contained"}
					color={"primary"}
					className={classes.manualAutoButton}
					style={{
						cursor: "auto",
						backgroundColor: isManual ? "primary" : "green",
					}}
				>
					{isManual ? "M" : "A"}
				</Button>
			</Grid>
		</Grid>
	);
};
