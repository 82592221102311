import { getMomentTz } from "@dexteel/mesf-core";
import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";

const moment = getMomentTz();

const useStyles = makeStyles((theme) => ({
	datePicker: {
		width: "50%",
		"& .MuiInputBase-root": {
			height: "40px",
		},
		"& .MuiOutlinedInput-root": {
			height: "40px",
		},
	},
}));

type Props = {
	startDate: Date;
	endDate: Date;
	setStartDate: (date: Date) => void;
	setEndDate: (date: Date) => void;
};

export const DateRangeFilter = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
}: Props) => {
	const classes = useStyles();

	const handleStartDateChange = (value: Moment | null) => {
		if (value && value.isValid()) {
			const newStartDate = value.startOf("day").toDate();
			setStartDate(newStartDate);
			if (moment(newStartDate).isAfter(endDate)) {
				setEndDate(newStartDate);
			}
		}
	};

	const handleEndDateChange = (value: Moment | null) => {
		if (value && value.isValid()) {
			const newEndDate = value.endOf("day").toDate();
			setEndDate(newEndDate);
			if (moment(newEndDate).isBefore(startDate)) {
				setStartDate(moment(value).startOf("day").toDate());
			}
		}
	};

	return (
		<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
			<DatePicker
				label="From"
				format="MM/DD/YYYY"
				value={moment(startDate)}
				onChange={handleStartDateChange}
				maxDate={moment(endDate)}
				slotProps={{
					textField: {
						variant: "outlined",
						size: "small",
						className: classes.datePicker,
					},
				}}
			/>
			<DatePicker
				label="To"
				format="MM/DD/YYYY"
				value={moment(endDate)}
				onChange={handleEndDateChange}
				minDate={moment(startDate)}
				slotProps={{
					textField: {
						variant: "outlined",
						size: "small",
						className: classes.datePicker,
						style: { marginLeft: "8px" },
					},
				}}
			/>
		</div>
	);
};
