import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-enterprise";
import { Charge } from "../../../../models/eaf.models";
import { EditAndDeleteButtons } from "../../components/EditAndDeleteButtons";
import { ManualAutoButton } from "../../components/ManualAutoButton";

type Props = {
	deleteCharge: (chargeId: number) => void;
	editCharge: (chargeId: number) => void;
};

export const useTableData = ({ deleteCharge, editCharge }: Props) => {
	const columns: ColGroupDef[] = [
		{
			headerName: "Charges",
			children: [
				{
					field: "manual-auto",
					headerName: " ",
					flex: 1,
					minWidth: 25,
					maxWidth: 40,
					cellRenderer: ManualAutoButton,
					cellRendererParams: (params: ICellRendererParams) => ({
						isManual: (params.data as Charge).IsChargeManual,
					}),
				},
				{
					field: "Timestamp",
					headerName: "Time",
					flex: 1,
					minWidth: 55,
					valueGetter: (params: any) => {
						if (params.data.Timestamp) {
							return dxtToLocalServerTime(
								(params.data as Charge).Timestamp,
								"HH:mm",
							);
						}
					},
				},
				{
					field: "BucketName",
					headerName: "Bucket",
					flex: 1,
					minWidth: 64,
				},
				{
					field: "Weight",
					headerName: "Scrap Weight [lbs]",
					flex: 1,
					minWidth: 76,
				},
				{
					field: "actions",
					headerName: " ",
					flex: 1,
					minWidth: 60,
					maxWidth: 80,
					cellRenderer: EditAndDeleteButtons,
					cellRendererParams: (params: ICellRendererParams) => ({
						deleteItem: deleteCharge,
						edit: editCharge,
						id: (params.data as Charge).ChargeId,
					}),
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	return { columns, defaultColDef };
};
