import { HourglassEmpty } from "@material-ui/icons";
import { ProcessData } from "../../../models/ProcessData";
import { useCastersContext } from "../../CastersContext";

export const useCasterDENavigation = ({
	reloadProcess,
}: { reloadProcess: Function }) => {
	const { state, actions } = useCastersContext();
	const process = state.processData || ({} as ProcessData);

	const handleOnNextProcess = () => {
		if (process === null || process.NextProcessId === null) return;
		console.log("handleOnNextProcess");
		actions.deleteProbeData(0);
		actions.clearFocusField();
		(async () => {
			await reloadProcess(process.NextProcessId);
		})();
	};
	const handleOnPreviousProcess = () => {
		if (process === null || process.PrevProcessId === null) return;
		actions.deleteProbeData(0);
		actions.clearFocusField();
		(async () => {
			await reloadProcess(process.PrevProcessId);
		})();
	};
	const handleOnLastProcess = () => {
		actions.deleteProbeData(0);
		actions.clearFocusField();
		(async () => {
			await reloadProcess(null);
		})();
	};
	const handleGoToProcess = (processId: number) => {
		(async () => {
			await reloadProcess(processId);
		})();
	};

	const handleGoToProcessesByShiftId = (shiftId: number | null) => {
		if (shiftId === null) return;
		window.location.href = "/caster-processes/" + shiftId;
	};
	const handleGotToHeatReport = (heatName: string | null) => {
		if (heatName === null) return;
		//window.location.href = "/heat-report/caster/" + heatName;
		window.location.href = "/heat-report/chemistry/" + heatName;
	};

	return {
		handleOnNextProcess,
		handleOnPreviousProcess,
		handleOnLastProcess,
		handleGoToProcessesByShiftId,
		handleGoToProcess,
		handleGotToHeatReport,
	};
};
