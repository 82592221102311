import {
	default as React,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { CasterProcessesReducer } from "./CasterProcessesReducer";

const CasterProcessesContext = createContext({
	state: CasterProcessesReducer.getInitialState(),
	actions: CasterProcessesReducer.actions,
});

export const useCasterProcessesContext = () =>
	useContext(CasterProcessesContext);

export const CasterProcessesProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CasterProcessesReducer.getInitialState(),
		reducers: CasterProcessesReducer.caseReducers,
	});
	return (
		<CasterProcessesContext.Provider value={{ state, actions }}>
			{children}
		</CasterProcessesContext.Provider>
	);
};
