import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getDelays = async (
	startDate: Date,
	endDate: Date,
	delayAreaAssetId: number,
	Shift: string,
	Crew: string,
	includeMicrodelays: boolean = false,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "StartDate", value: startDate });
	parameters.push({ name: "EndDate", value: endDate });
	parameters.push({
		name: "DelayAreaAssetId",
		value: delayAreaAssetId || null,
	});

	parameters.push({
		name: "Shift",
		value: (Shift as string) === "All" ? null : (Shift as string),
	});

	parameters.push({
		name: "Crew",
		value: (Crew as string) === "All" ? null : (Crew as string),
	});

	parameters.push({ name: "IncludeMicrodelays", value: includeMicrodelays });

	const resp = await apiService.callV2("[DEL].[GetDelays]", parameters);
	return resp;
};

export const getDelayAreas = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	return await apiService.callV2("[DEL].[GetDelayAssets]", parameters);
};

export const getShiftsAndCrews = async () => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	return await apiService.callV2("[MES].[GetShiftsAndCrews]", parameters);
};
