import { getMomentTz } from "@dexteel/mesf-core";
import { isNil } from "lodash-es";
import { useState } from "react";
import { unLinkEAFProcess } from "../../eaf-processes/repositories/EAFRepository";
import { useDataEntryContext } from "../context/reducer.provider";
import { Process } from "../models/eaf.models";
import { useEAFDataEntryActions } from "./useEAFDataEntryActions";
const moment = getMomentTz();

export const useLinkUnlinkProcess = (process: Process) => {
	const { state, actions } = useDataEntryContext();
	const { refreshData } = useEAFDataEntryActions();

	const [showLinkModal, setShowLinkModal] = useState(false);
	const [showUnlinkModal, setShowUnlinkModal] = useState<{
		show: boolean;
		message: string;
	}>({ show: false, message: "" });

	//Link
	const handleLinkHeat = (e: any, process: Process) => {
		actions.setDEProcess({ ...state.deProcess, process: process });
		setShowLinkModal(true);
	};

	//Unlink
	const unlinkProcess = async () => {
		const dateOpen = moment(process.Start).format("YYYY/MM/DD HH:mm");
		setShowUnlinkModal({
			show: true,
			message:
				"The heat " +
				process.HeatName +
				" from " +
				dateOpen +
				" will be unlinked.  Do you want to continue?",
		});
	};

	const handleUnlinkHeat = async (value?: string) => {
		setShowUnlinkModal({ show: false, message: "" });
		if (value !== "OK") return;
		await unLinkProcessData();
	};

	const unLinkProcessData = async () => {
		if (isNil(process) || isNil(process.ProcessId) || isNil(process.HeatId)) {
			actions.setErrorMessage("Process not found");
		} else {
			actions.setIsDataLoading(true);

			const resp = await unLinkEAFProcess(process?.ProcessId);
			actions.setIsDataLoading(false);
			if (resp.ok) {
				actions.setNotificationMessage("Process unlinked successfully");
				await refreshData();
			} else {
				actions.setErrorMessage(resp.message);
			}

			actions.setIsDataLoading(false);
		}
	};

	return {
		unlinkProcess,
		handleLinkHeat,
		handleUnlinkHeat,
		showLinkModal,
		showUnlinkModal,
		setShowLinkModal,
		setShowUnlinkModal,
	};
};
