import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { getCatalogMolds } from "../../../repositories/CatalogMoldRepository";
import { getCatalogMoldSizes } from "../../../repositories/CatalogMoldSizeRepository";
import { useCatalogMoldSizeContext } from "../../mold-sizes/context/CatalogMoldSizeContext";
import { TableMold } from "../components/TableMold";
import { useCatalogMoldContext } from "../context/CatalogMoldContext";
type Props = {};

export const CatalogMoldPage = (props: Props) => {
	const { state, actions } = useCatalogMoldContext();
	const {
		actions: { setMoldSizes },
	} = useCatalogMoldSizeContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const searchMoldSizes = async () => {
		setIsLoading(true);
		const res = await getCatalogMoldSizes();
		if (res.ok) {
			setMoldSizes(res.data.tables[0].rows);
		} else {
			setError(res.message);
		}
		setIsLoading(false);
	};
	const getCatalogMoldsFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogMolds();
		if (resp.ok) {
			actions.setMolds(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getCatalogMoldsFromAPI();
		searchMoldSizes();
	}, []);
	if (state.molds) {
		return (
			<>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0 }}>
						<Paper
							elevation={1}
							style={{
								width: "100%",
								padding: "10px 0 20px",
								borderRadius: "0.75rem",
								height: "auto",
							}}
						>
							<TableMold
								isLoading={isLoading}
								getCatalogMoldsFromAPI={getCatalogMoldsFromAPI}
							/>
						</Paper>
					</Grid>
				</Grid>
			</>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<ErrorModal error={error} onHide={() => setError("")} />
			<LazyLoading />
		</div>
	);
};
