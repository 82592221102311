import { TextField } from "@material-ui/core";
import React from "react";
import { isValidNumber } from "../../../../utils/IsValidNumber";
import { useDeliveryTicketStyles } from "../../styles/deliveryTicketStyles";
import { useProductBatchesStyles } from "../../styles/productBatchStyles";

interface PiecesInputProps {
	value: number | string;
	onChange: (value: string) => void;
	error: boolean;
	helperText: string | undefined;
	className?: string;
}

export const PiecesInput: React.FC<PiecesInputProps> = ({
	value,
	onChange,
	error,
	helperText,
	className,
}) => {
	return (
		<TextField
			className={className}
			error={error}
			helperText={helperText}
			value={value}
			onChange={(e) => {
				if (isValidNumber(e.target.value)) {
					onChange(e.target.value);
				}
			}}
			variant="outlined"
			size="small"
			onFocus={(e) => e.target.select()}
		/>
	);
};
