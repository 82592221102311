import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import React, { useState } from "react";
import { useTagColorsStyles } from "./TagColorsStyles";
import { tagColors } from "./tagColors";

interface SwatchProps {
	color: string;
	onSelect: (color: string) => void;
}

const Swatch = ({ color, onSelect }: SwatchProps) => {
	const classes = useTagColorsStyles();
	return (
		<div
			className={classes.swatch}
			style={{ backgroundColor: color }}
			onClick={() => onSelect(color)}
		/>
	);
};

interface Props {
	onChange: Function;
	color: string;
	elementId?: number;
	disabled?: boolean;
	label?: string;
}

const ColorPicker = ({
	onChange,
	color,
	elementId,
	disabled = false,
	label,
}: Props) => {
	const classes = useTagColorsStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div>
			<Button
				aria-describedby={id}
				variant="outlined"
				onClick={handleClick}
				style={{
					backgroundColor: color,
					width: "24px",
					height: "24px",
					minWidth: "24px",
					padding: 0,
					marginBottom: "6px",
				}}
				disabled={disabled}
			/>
			{label && <span style={{ marginLeft: "8px" }}>{label}</span>}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div className={classes.popover}>
					{tagColors.map((color) => (
						<Swatch
							key={color}
							color={color}
							onSelect={(color: string) => {
								onChange(elementId, color);
								handleClose();
							}}
						/>
					))}
				</div>
			</Popover>
		</div>
	);
};

export default ColorPicker;
