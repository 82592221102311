import {
	ErrorModal,
	ShiftDayNavigatorControl,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../../controls/LazyLoading";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { L3ChemistryModel } from "../../models/L3ChemistryModel";
import {
	getChemistriesForL3,
	sendChemistryToL3,
} from "../../repositories/L3Repository";
import { useL3ChemistryContext } from "./L3ChemistryContext";
import { ShowAckCheckbox } from "./components/ShowAckCheckbox";
import { TableL3Chemistry } from "./components/TableL3Chemistry";
type Props = {};

export const L3ChemistryPage = (props: Props) => {
	const navigate = useNavigate();
	const { shiftId: shiftIdParam } = useParams();

	const [params, setParams] = useSearchParams();

	const { state, actions } = useL3ChemistryContext();
	const { shiftInfo, getShiftDataFromAPI } = useShiftNavigator();

	const [error, setError] = useState<string>("");
	const [showSendChemistryModal, setShowSendChemistryModal] =
		useState<boolean>(false);
	const [chemistryToSend, setChemistryToSend] =
		useState<L3ChemistryModel | null>(null);

	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const shiftId = get(shiftInfo, "CurrentShiftId", null);
		const resp = await getChemistriesForL3(shiftId, state.showAck);
		if (resp.ok) {
			actions.setChemistries(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		actions.setIsLoadingGrid(false);
	};
	const sendChemistry = (data: L3ChemistryModel) => {
		if (data.Sent) return;
		setChemistryToSend(data);
		setShowSendChemistryModal(true);
	};
	const sendChemistryCallback = async (value?: string) => {
		setShowSendChemistryModal(false);
		if (value !== "OK") return;
		if (chemistryToSend === null) return;
		const forced = chemistryToSend.ChecksPassed !== true;
		//chemistryToSend.Sent = true;
		if (
			chemistryToSend.ChemistrySampleId !== null &&
			chemistryToSend.ChemistrySampleId > 0
		) {
			actions.setIsLoadingBackground(true);
			const resp = await sendChemistryToL3(
				chemistryToSend.ChemistrySampleId,
				forced,
			);
			if (resp.ok) {
				await getDataFromAPI();
			} else {
				setError(resp.message);
			}
			actions.setIsLoadingBackground(false);
		}
	};

	useEffect(() => {
		if (shiftInfo) {
			const shiftId = get(shiftInfo, "CurrentShiftId", null);
			navigate(`/lab-l3/chemistry/${shiftId}?ack=${state.showAck}`);
			(async () => {
				await getDataFromAPI();
			})();
		}
	}, [shiftInfo, state.showAck]);

	useEffect(() => {
		if (shiftIdParam) {
			(async () => {
				const shiftId = Number(shiftIdParam);
				await getShiftDataFromAPI(null, shiftId);
			})();
		} else {
			(async () => {
				await getShiftDataFromAPI(null, 0);
			})();
		}
		if (params.get("ack") === "true") {
			actions.setShowAck(true);
		}
	}, []);

	if (!state.isLoadingPage) {
		return (
			<>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						borderRadius: "0.75rem",
						height: "93vh",
						padding: "15px 20px 10px",
					}}
				>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										L3 Chemistry
									</Typography>
								</Grid>
							</Grid>

							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<ShiftDayNavigatorControl preventInit />
									<Grid item xs={4} style={{ margin: 0, padding: "15px" }}>
										<ShowAckCheckbox
											showAck={state.showAck}
											setShowAck={actions.setShowAck}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Divider />
							<ConfirmationDialogRaw
								title="Confirmation"
								message="The sample will be sent.  Do you want to continue?"
								open={showSendChemistryModal}
								onClose={sendChemistryCallback}
							/>

							<TableL3Chemistry
								loadingGrid={state.isLoadingGrid}
								rows={state.chemistries || []}
								OnSend={sendChemistry}
							/>
						</Grid>
					</Grid>
				</Paper>
				<ErrorModal error={error} onHide={() => setError("")} />
			</>
		);
	} else return <LazyLoading />;
};
