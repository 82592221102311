export interface CatalogSampleType {
	SampleTypeId: number | null;
	SampleTypeName: string;
	IsActive: boolean;
}
export const CATALOG_SAMPLE_TYPE_INITIAL_VALUES: CatalogSampleType = {
	SampleTypeId: null,
	SampleTypeName: "",
	IsActive: true,
};
