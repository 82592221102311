import { HeatsDataEntryPage } from "./HeatsDataEntryPage";
import { GradesProvider } from "./context/GradesContext";
import { HeatsByShiftProvider } from "./context/HeatsByShiftContext";
import { LadleProvider } from "./context/LadlesContext";

const HeatsDataEntry = () => {
	return (
		<HeatsByShiftProvider>
			<LadleProvider>
				<GradesProvider>
					<HeatsDataEntryPage />
				</GradesProvider>
			</LadleProvider>
		</HeatsByShiftProvider>
	);
};

export default HeatsDataEntry;
