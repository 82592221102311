import { Button, Grid, Typography } from "@material-ui/core";
import { useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { CatalogElement } from "../../../models/CatalogElement";
import { useGridStyles } from "../../../styles/gridStyles";
import { CreateElement } from "./CreateElement";
import { EditElement } from "./EditElement";
import { useTableData } from "./hooks";
type Props = {
	getCatalogElementsFromAPI: Function;
	isLoading: boolean;
};

export const TableElement = ({
	getCatalogElementsFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [idElement, setIdElement] = useState<number | null>(null);
	const { rows, columns, defaultColDef } = useTableData({});
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: CatalogElement) => {
			setShowEditModal(!showEditModal);
			setIdElement(data.ElementId);
		},
	});

	const classes = useGridStyles();
	return (
		<Grid container justifyContent="center">
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Elements
				</Typography>
			</Grid>
			<CreateElement
				show={showCreateModal}
				onHide={() => setShowCreateModal(false)}
				refreshData={getCatalogElementsFromAPI}
			/>
			<EditElement
				show={showEditModal}
				onHide={(updated: boolean) => {
					setShowEditModal(false);
					setIdElement(null);
					if (updated) getCatalogElementsFromAPI();
				}}
				elementId={idElement}
			/>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
				style={{ width: "100%" }}
			>
				<Grid item md={12} xs={12} className={classes.gridContainer}>
					<div
						style={{ height: "100%", width: "100%" }}
						className="ag-theme-material"
					>
						<AgGridReact
							rowData={rows || []}
							columnDefs={columns}
							getRowId={(params) => params.data.ElementId}
							columnTypes={columnTypes}
							defaultColDef={defaultColDef}
							headerHeight={34}
							rowSelection="single"
						/>
					</div>
				</Grid>
			</Grid>

			<Grid container justifyContent="flex-end">
				<Grid item md={2} xs={12} className={classes.btnModal}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{ margin: "0px 15px" }}
						onClick={() => setShowCreateModal(!showCreateModal)}
					>
						NEW ELEMENT
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
