import { createSlice } from "@reduxjs/toolkit";
import { DEProcess, Process } from "../models/eaf.models";
import { Pourback } from "../models/ms.models";

const DataEntryReducerInitialState: {
	isDataLoading: boolean;
	isSubmitLoading: boolean;

	errorMessage: string;
	notificationMessage: string;

	deProcess: DEProcess | null;
	pourbacks: Pourback[];
} = {
	errorMessage: "",
	notificationMessage: "",
	isDataLoading: false,
	isSubmitLoading: false,

	deProcess: null,
	pourbacks: [],
};

export const DataEntryReducer = createSlice({
	name: "__",
	initialState: DataEntryReducerInitialState,
	reducers: {
		setIsDataLoading(state, { payload }) {
			state.isDataLoading = payload;
		},
		setIsSubmitLoading(state, { payload }) {
			state.isSubmitLoading = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setDEProcess(state, { payload }) {
			state.deProcess = payload;
		},
		setPourbacks(state, { payload }) {
			state.pourbacks = payload;
		},
		setProcess(state, { payload }) {
			if (state.deProcess) {
				state.deProcess.process = payload;
			}
		},
	},
});
