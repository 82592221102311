export interface CatalogSection {
	SectionId: number | null;
	SectionName: string;
	IsCircular: boolean;
	IsActive: boolean;
}
export const CATALOG_SECTION_INITIAL_VALUES: CatalogSection = {
	SectionId: null,
	SectionName: "",
	IsCircular: false,
	IsActive: true,
};
