import { useContextMenuMESF, useShiftNavigator } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef } from "react";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { ProcessByShift } from "../../../eaf/eaf-processes/models/ProcessByShift";
import { useTableData } from "./hooks/hooks";
import { useEAFOptionFunctions } from "./hooks/useEAFOptionFunctions";
import { useGridStyles } from "./styles/gridStyles";

type Props = {
	loadingGrid: boolean;
	rows: ProcessByShift[];
};

export const EAFProcessesReadOnlyTable = ({ loadingGrid, rows }: Props) => {
	const classes = useGridStyles();
	const gridRef = useRef<AgGridReact>(null);
	const { columns } = useTableData({});

	const { getMenuOptions, goToDataEntry } = useEAFOptionFunctions();

	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { shiftInfo } = useShiftNavigator();

	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};
	const getRowStyle = (params: RowClassParams) => {
		if (!params.data.IsActive) {
			return { background: "#E8EBEB" };
		}
	};
	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({});

	useEffect(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);

	useEffect(() => {
		registerConfig({
			id: "eafProcesses",
			getOptions: getMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);

	return (
		<div onContextMenu={handleContainerContextMenu}>
			<div>
				<Grid container className={classes.root}>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							height: "40vh",
							minHeight: "30vh",
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							margin: "20px 0 10px",
						}}
						className={"ag-theme-balham"}
					>
						<AgGridReact
							ref={gridRef}
							rowSelection="single"
							pagination={false}
							animateRows={true}
							rowHeight={35}
							headerHeight={35}
							singleClickEdit={true}
							tooltipShowDelay={0}
							tooltipHideDelay={2000}
							rowData={rows}
							columnDefs={columns}
							columnTypes={columnTypes}
							defaultColDef={columnSimpleDefaults}
							getRowStyle={getRowStyle}
							suppressContextMenu={true}
							onRowDoubleClicked={(event: any) => {
								if (event.data.IsActive) {
									goToDataEntry(event.data.ProcessId);
								}
							}}
							onCellContextMenu={(event) => {
								event.api.deselectAll();
								event.node.setSelected(true);
								if (event.event)
									showContextMenu(
										event.event as any,
										event.data,
										"eafProcesses",
									);
							}}
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
