import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import {
	CATALOG_LADLE_INITIAL_VALUES,
	CatalogLadle,
} from "../../../../models/CatalogLadle";
import {
	deleteCatalogLadle,
	getCatalogLadle,
	upsertCatalogLadle,
} from "../../../../repositories/CatalogLadleRepository";

type Props = {
	LadleId: number | null;
	modal: "create" | "update" | "delete" | "";
	onHide: (shouldUpdate: boolean) => void;
};

export const UpsertDeleteLadleModal = ({ LadleId, modal, onHide }: Props) => {
	const [title, setTitle] = useState<string>("CREATE");

	const [message, setNotificationMessage] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const successfullyMessage = `The ladle was ${modal}d successfully`;

	const { control, setValue, reset, handleSubmit } = useForm<CatalogLadle>({
		defaultValues: CATALOG_LADLE_INITIAL_VALUES,
		mode: "onChange",
	});

	const onSubmit: SubmitHandler<CatalogLadle> = async (data) => {
		setIsSubmitLoading(true);
		if (modal === "delete") {
			const ladleResp = await deleteCatalogLadle(data.LadleId);
			if (ladleResp.ok) {
				setNotificationMessage(successfullyMessage);
				onHide(true);
			} else {
				setError(ladleResp.message);
			}
		} else {
			const ladleResp = await upsertCatalogLadle(data);
			if (ladleResp.ok) {
				setNotificationMessage(successfullyMessage);
				onHide(true);
			} else {
				setError(ladleResp.message);
			}
		}
		setIsSubmitLoading(false);
	};

	useEffect(() => {
		if (modal !== "") {
			setIsSubmitLoading(false);
			reset();
			setTitle(
				modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE",
			);
			if (modal !== "create")
				(async () => {
					setIsLoading(true);
					const response = await getCatalogLadle(LadleId);
					if (response.ok) {
						const ladle = get(
							response,
							"data.tables[0].rows[0]",
							null,
						) as CatalogLadle;
						if (ladle) {
							setValue("LadleId", ladle.LadleId);
							setValue("LadleName", ladle.LadleName);
						}
					} else {
						setError(response.message!);
						return;
					}
					setIsLoading(false);
				})();
		}
	}, [modal]);

	return (
		<>
			<MesfModal
				title={`${title} LADLE`}
				open={modal !== ""}
				handleClose={() => onHide(false)}
				maxWidth="sm"
				id="ladle-modal"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content dividers style={{ padding: "10px 30px" }}>
						{!isLoading && (
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Controller
										name="LadleName"
										control={control}
										rules={{ required: "Name is required" }}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												label="Name"
												variant="outlined"
												fullWidth
												size="small"
												error={!!error}
												helperText={error?.message}
												disabled={modal === "delete"}
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<FooterModalButtons
							isSubmitLoading={isSubmitLoading}
							onHide={onHide}
							buttonLabel={modal === "delete" ? "Delete" : "Save"}
							buttonColor={modal === "delete" ? "secondary" : "primary"}
						/>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<NotificationSnackBar
				message={message}
				onHide={() => setNotificationMessage("")}
			/>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error In Ladle"
			/>
		</>
	);
};
