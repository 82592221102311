import { Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { get } from "lodash-es";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultPanel, LongFilterPanel } from "../../../controls/PanelControls";
import { HeatNavigation } from "../../../controls/heatNavigation";
import { DataInfoCardView } from "../../../controls/infoCardView";
import { ReportColumn } from "../../../controls/reportTable";
import { heatStatuses } from "../../../utils/heat-status-list";
import { getChemistryHeatReport } from "../../caster/repositories/ProductionRepository";
import { useHeatReportContext } from "../../meltshop/heat-report/HeatReportContext";
import style from "./caster-heat-report.module.css";
import { ChemistryTable } from "./components/ChemistryTable";
import "./heatReport.css";
type Props = {};

const useStyles = makeStyles((theme) => ({
	infoCardContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "flex-start",
		},
	},
	infoCard: {
		maxWidth: "200px",
		minWidth: "170px",
		width: "100%",
	},
}));

export const ChemistryHeatReportPage = (props: Props) => {
	const classes = useStyles();
	const { state, actions } = useHeatReportContext();
	const [selectedTab] = useState(3);
	const heatStatus = state.chemistryHeatReport?.IsExternal
		? get(heatStatuses, "E", null)
		: get(heatStatuses, `${state.chemistryHeatReport?.HeatStatusCode}`, null);
	const navigate = useNavigate();
	const [reportColumns, setReportColumns] = React.useState<ReportColumn[]>([]);
	const [dataSource, setDataSource] = React.useState<any[]>([]);
	const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
		switch (newValue) {
			case 0:
				break;
			case 1:
				break;
			case 2:
				navigate("../caster");
				break;
			case 3:
				navigate("chemistry");
				break;
		}
		//setSelectedTab(newValue);
	};
	const getStringValue = (dataValue: any | undefined) => {
		if (dataValue === undefined || dataValue === null) return "";
		return String(dataValue);
	};
	useEffect(() => {
		(async () => {
			if (state.selectedHeat === null) {
				actions.setChemistryHeatReport(null);
				return;
			}
			actions.setIsLoadingBackground(true);
			const resp = await getChemistryHeatReport(state.selectedHeat.HeatId);
			if (resp.data !== null || true || resp.data.heat !== undefined) {
				actions.setChemistryHeatReport(resp.data.heat[0]);
				const temp = resp.data.rows === null ? [] : resp.data.rows || [];
				temp.forEach((row: any, index: number) => {
					row.id = index;
				});
				setDataSource(temp);
				setReportColumns(
					resp.data.columns === null ? [] : resp.data.columns || [],
				);
			}
			actions.setIsLoadingBackground(false);
		})();
	}, [state.selectedHeat]);
	return (
		<>
			<Backdrop open={state.isLoadingBackground} style={{ zIndex: 1000 }}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<DefaultPanel title={"Chemistry Heat Report"}>
				<LongFilterPanel
					showButtons={false}
					onClickReset={() => {}}
					OnClickGo={async () => {}}
					showExport={false}
				>
					<HeatNavigation
						onTextChanged={actions.setSearchHeatName}
						onValueChanged={actions.setSelectedOption}
						heats={state.heatOptions}
						onNextClicked={actions.nextHeat}
						onPreviousClicked={actions.previousHeat}
						defaultText={""}
						textColor={state.chemistryHeatReport?.IsExternal ? "blue" : "black"}
						heatStatus={heatStatus}
					/>
					{/* <div className="col-md-8 col-lg-8 col-xl-8">
						{state.chemistryHeatReport !== undefined &&
							state.chemistryHeatReport !== null && (
								<Tabs
									value={selectedTab}
									textColor="primary"
									onChange={handleChangeTab}
									className={style.Tabs}
								>
									<Tab label="EAF" disabled />
									<Tab label="Trimming" disabled />
									<Tab label="Caster" disabled />
									<Tab label="Chemistry" />
								</Tabs>
							)}
					</div> */}
				</LongFilterPanel>
				{state.chemistryHeatReport !== undefined &&
					state.chemistryHeatReport !== null && (
						<Grid container spacing={3} style={{ paddingTop: "3rem" }}>
							<Grid item xs={2} className={classes.infoCardContainer}>
								<div className={classes.infoCard}>
									<DataInfoCardView
										title="Date"
										values={[
											getStringValue(state.chemistryHeatReport?.ProductionDate),
										]}
										headerBackgroundColor="#479dc4"
										headerSpan={true}
									/>
								</div>
							</Grid>
							<Grid item xs={2} className={classes.infoCardContainer}>
								<div className={classes.infoCard}>
									<DataInfoCardView
										title="Programmed Grade"
										values={[
											getStringValue(
												state.chemistryHeatReport?.ProgrammedGrade,
											),
										]}
										headerBackgroundColor="#479dc4"
										headerSpan={true}
									/>
								</div>
							</Grid>
							<Grid item xs={2} className={classes.infoCardContainer}>
								<div className={classes.infoCard}>
									<DataInfoCardView
										title="Shift"
										values={[
											getStringValue(
												state.chemistryHeatReport?.ProductionShift,
											),
										]}
										headerBackgroundColor="#479dc4"
										headerSpan={true}
									/>
								</div>
							</Grid>
							<Grid item xs={2} className={classes.infoCardContainer}>
								<div className={classes.infoCard}>
									<DataInfoCardView
										title="Crew"
										values={[getStringValue(state.chemistryHeatReport?.Crew)]}
										headerBackgroundColor="#479dc4"
										headerSpan={true}
									/>
								</div>
							</Grid>
							<Grid item xs={2} className={classes.infoCardContainer}>
								<div className={classes.infoCard}>
									<DataInfoCardView
										title="Casted Grade"
										values={[getStringValue(state.chemistryHeatReport?.Grade)]}
										headerBackgroundColor="#479dc4"
										headerSpan={true}
									/>
								</div>
							</Grid>
							<Grid item xs={2} className={classes.infoCardContainer}>
								<div className={classes.infoCard}>
									<DataInfoCardView
										title="Sections"
										values={[
											getStringValue(state.chemistryHeatReport?.Sections),
										]}
										headerBackgroundColor="#479dc4"
										headerSpan={true}
									/>
								</div>
							</Grid>
						</Grid>
					)}
			</DefaultPanel>
			<Divider />
			{state.chemistryHeatReport !== undefined &&
				state.chemistryHeatReport !== null && (
					<>
						<Grid container style={{ padding: "10px" }}>
							<Grid item xs={12}>
								<Typography variant="h5" style={{ padding: "20px" }}>
									Chemistry
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<ChemistryTable
									reportColumns={reportColumns}
									dataSource={dataSource}
									style={style.casterHRTable}
									cellClass={style.casterReportCellTable}
								/>
							</Grid>
						</Grid>
					</>
				)}
		</>
	);
};
