import * as React from "react";
import { CasterProductionProvider } from "../context/CasterProductionContext";
import { CasterProductionPage } from "./CasterProductionPage";

type Props = {};
const CasterProduction = (props: Props) => {
	return (
		<CasterProductionProvider>
			<CasterProductionPage />
		</CasterProductionProvider>
	);
};
export default CasterProduction;
