import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getHealthStatusReport = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	try {
		const resp = await apiService.call("[SWV].[GetHealthStatus]", parameters);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
