import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-enterprise";
import { Pourback } from "../../../../models/ms.models";
import { EditAndDeleteButtons } from "../../components/EditAndDeleteButtons";

type Props = {
	deletePourbackCharge: (pourbackId: number) => void;
	editPourback: (pourbackId: number) => void;
};

export const useTableData = ({ deletePourbackCharge, editPourback }: Props) => {
	const columns: ColGroupDef[] = [
		{
			headerName: "Pourbacks Charges",
			children: [
				{
					field: "ChargedTime",
					headerName: "Time",
					flex: 2,
					valueGetter: (params: any) => {
						if (params.data.ChargedTime) {
							return dxtToLocalServerTime(
								(params.data as Pourback).ChargedTime,
								"HH:mm",
							);
						}
					},
				},
				{
					field: "HeatName",
					headerName: "From Heat",
					flex: 2,
				},
				{
					field: "ChargedWeight",
					headerName: "Weight [tons]",
					flex: 2,
					minWidth: 116,
					valueGetter: (params: any) => {
						if (params.data.ChargedWeight) {
							return Math.floor((params.data as Pourback).ChargedWeight);
						}
					},
				},
				{
					field: "actions",
					headerName: " ",
					flex: 1,
					cellRenderer: EditAndDeleteButtons,
					cellRendererParams: (params: ICellRendererParams) => ({
						deleteItem: deletePourbackCharge,
						edit: editPourback,
						id: (params.data as Pourback).PourbackId,
					}),
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
		suppressMenu: true,
	};

	return { columns, defaultColDef };
};
