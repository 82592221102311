import React, { useEffect } from "react";
import { SectionAutocomplete } from "../../../../ui/SectionAutocomplete";

interface SectionInputProps {
	value: number;
	onChange: (value: string) => void;
	className: string;
	error: boolean;
	helperText: string | undefined;
}

export const SectionInput: React.FC<SectionInputProps> = ({
	value,
	onChange,
	className,
	helperText,
}) => {
	return (
		<SectionAutocomplete
			moldSizeId={null}
			value={value}
			onChange={(value) => {
				if (value) {
					onChange(value);
				}
			}}
			className={className}
			onFocus={() => {}}
			required
			inputError={helperText}
		/>
	);
};
