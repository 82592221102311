import React, { useEffect, useState } from "react";
import {
	convertToBodyCells,
	convertToCellHeaders,
	convertToTransposedCellHeaders,
} from "../../helpers/table-helper";
import { ReportTableProps } from "../../types/public-types";
import { BodyCellSetup } from "../../types/table-body";
import { HeaderCellSetup } from "../../types/table-header";
import { ReportBody, ReportBodyProps } from "../body/body";
import { ReportHeader, ReportHeaderProps } from "../header/header";
import "./reportTableModule.css";

interface ReportFooterProps {
	autoPaging: boolean;
	pageSize: number;
	pageIndex: number; //non-zero index,
	dataKey: string;
}

export const ReportTable: React.FunctionComponent<ReportTableProps> = ({
	name,
	columns,
	rows,
	autoGeneratedColumns = false,
	showPaging = false,
	autoPaging = false,
	transposed = false,
	transposeBy = "id",
	pageSize = 20,
	pageIndex = 0,
	autoAlignHeaderNumber = false,
	containerClass = "",
	tableClass = "",
	cellClass = "",
	onRowClick,
}) => {
	const [headerCells, setHeaderCells] = useState<HeaderCellSetup[]>([]);
	const [transposedHeaderCells, setTransposedHeaderCells] = useState<
		HeaderCellSetup[]
	>([]);
	const [bodyCells, setBodyCells] = useState<BodyCellSetup[]>([]);

	useEffect(() => {
		const headerCells = convertToCellHeaders(columns);
		setHeaderCells(headerCells);
		const data: any = rows.length === 0 ? null : rows[0];
		setBodyCells(convertToBodyCells(headerCells, data, autoGeneratedColumns));
		if (transposed) {
			setTransposedHeaderCells(
				convertToTransposedCellHeaders(rows, transposeBy),
			);
		}
	}, [columns, rows, autoGeneratedColumns, transposed]);
	useEffect(() => {}, [bodyCells]);

	// //New Methods
	const reportHeaderProps: ReportHeaderProps = {
		cells: transposed ? transposedHeaderCells : headerCells,
		transposed,
		transposeBy,
		autoAlignHeaderNumber,
	};
	const reportBodyProps: ReportBodyProps = {
		headers: headerCells,
		cells: bodyCells,
		rows: rows,
		transposed,
		cellClass,
		onRowClick,
	};
	const tableClassName: string[] = ["report-table", tableClass];
	if (transposed) tableClassName.push("table-transposed");
	//end new Methods
	return (
		<div className="container-fluid my-3">
			<table className={tableClassName.join(" ")}>
				<ReportHeader {...reportHeaderProps} />
				<ReportBody {...reportBodyProps} />
				{/* { showPaging &&
                    <ReportFooter
                    autoPaging={autoPaging||false}
                    pageIndex={pageIndex||1}
                    pageSize={pageSize||20}
                    key={`${name}_footer`}
                    dataKey={`${name}_footer`}
                     />
                } */}
			</table>
		</div>
	);
};

// const ReportFooter = (props : ReportFooterProps) => {
//     return (
//       <tfoot>
//           {/* <tr><td>sssss</td></tr> */}
//       </tfoot>
//     );
// };
