import { useContextMenuMESF, useShiftNavigator } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef } from "react";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { CountersByShift } from "../../models/CountersByShift";
import { useGridStyles } from "../../styles/gridStyles";
import { useOptionFunctions } from "../hooks/useOptionFunctions";
import { useTableData } from "./hooks";

type Props = {
	loadingGrid: boolean;
	rows: CountersByShift[];
	showDeletedProcesses: boolean;
};

export const CasterCountersTable = ({
	showDeletedProcesses,
	loadingGrid,
	rows,
}: Props) => {
	const classes = useGridStyles();

	const gridRef = useRef<AgGridReact>(null);
	const { columns } = useTableData({});
	const { shiftInfo } = useShiftNavigator();
	const { goToDataEntry, goToHeats, getMenuOptions } = useOptionFunctions();
	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: () => {},
	});
	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};

	useMemo(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);

	useEffect(() => {
		registerConfig({
			id: "casterCounters",
			getOptions: getMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);
	return (
		<div onContextMenu={handleContainerContextMenu}>
			<Grid container className={classes.root}>
				<Grid
					item
					md={12}
					xs={12}
					style={{
						border: "4px solid #ccc",
						borderRadius: 10,
						padding: 10,
						height: "calc(100vh - 300px)",
						minHeight: "calc(100vh - 300px)",
						margin: "20px 0 10px",
					}}
					className={"ag-theme-balham"}
				>
					<AgGridReact
						ref={gridRef}
						rowSelection="single"
						pagination={false}
						animateRows={true}
						rowHeight={35}
						headerHeight={35}
						singleClickEdit={true}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={rows}
						columnDefs={columns}
						columnTypes={columnTypes}
						defaultColDef={columnSimpleDefaults}
						suppressContextMenu={true}
						onRowDoubleClicked={(event: any) => {
							if (event.data.HeatNameLink)
								goToDataEntry(event.data.HeatNameLink);
						}}
						onCellContextMenu={(event) => {
							event.api.deselectAll();
							event.node.setSelected(true);
							if (event.event && !showDeletedProcesses)
								showContextMenu(
									event.event as any,
									event.data,
									"casterCounters",
								);
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};
