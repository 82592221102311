import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CasterProductionReducer } from "../reducers/CasterProductionReducer";

const CasterProductionContext = createContext({
	state: CasterProductionReducer.getInitialState(),
	actions: CasterProductionReducer.actions,
});

export const useCasterProductionContext = () =>
	useContext(CasterProductionContext);

export const CasterProductionProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CasterProductionReducer.getInitialState(),
		reducers: CasterProductionReducer.caseReducers,
	});

	return (
		<CasterProductionContext.Provider value={{ state, actions }}>
			{children}
		</CasterProductionContext.Provider>
	);
};
