import React from "react";

export const useHandleKeyDown = ({
	rowHeight,
	columnWidth,
	svgWidth,
	setScrollX,
	setScrollY,
	ganttFullHeight,
	ganttHeight,
	setIgnoreScrollEvent,
}: {
	rowHeight: number;
	columnWidth: number;
	svgWidth: number;
	setScrollX: (scrollX: number) => void;
	setScrollY: (scrollY: number) => void;
	ganttFullHeight: number;
	ganttHeight: number;
	setIgnoreScrollEvent: (ignore: boolean) => void;
}) => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		event.preventDefault();
		let newScrollY = scrollY;
		let newScrollX = scrollX;
		let isX = true;
		switch (event.key) {
			case "Down":
			// IE/Edge specific value
			case "ArrowDown":
				newScrollY += rowHeight;
				isX = false;
				break;
			case "Up":
			// IE/Edge specific value
			case "ArrowUp":
				newScrollY -= rowHeight;
				isX = false;
				break;
			case "Left":
			case "ArrowLeft":
				newScrollX -= columnWidth;
				break;
			case "Right":
			// IE/Edge specific value
			case "ArrowRight":
				newScrollX += columnWidth;
				break;
		}
		if (isX) {
			if (newScrollX < 0) {
				newScrollX = 0;
			} else if (newScrollX > svgWidth) {
				newScrollX = svgWidth;
			}
			setScrollX(newScrollX);
		} else {
			if (newScrollY < 0) {
				newScrollY = 0;
			} else if (newScrollY > ganttFullHeight - ganttHeight) {
				newScrollY = ganttFullHeight - ganttHeight;
			}
			setScrollY(newScrollY);
		}
		setIgnoreScrollEvent(true);
	};

	return { handleKeyDown };
};
