import {
	MesfModal,
	TimeService,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
} from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { ProcessByShift } from "../../models/ProcessByShift";
import { upsertProcess } from "../../repositories/ProductionRepository";
import { useCasterProcessesContext } from "../CasterProcessesContext";

const moment = getMomentTz();

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	refreshData: Function;
};

export const UpsertCasterProcess = ({ show, onHide, refreshData }: Props) => {
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isNew, setIsNew] = useState(false);
	const [isNotClosed, setIsNotClosed] = useState(false);
	const [title, setTitle] = useState("");
	const { state, actions } = useCasterProcessesContext();
	const [warning, setWarning] = useState(false);
	const [endStartWarning, setEndStartWarning] = useState(false);
	const { shiftInfo } = useShiftNavigator();

	const {
		register,
		setValue,
		control,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<ProcessByShift>({
		defaultValues: {},
	});

	const CurrentStart = get(shiftInfo, "CurrentStart", null);
	const handleDateChange = (fieldOnChange: any) => (date: any) => {
		const currentStart = moment.utc(CurrentStart);
		const dateToCompare = moment.utc(date);

		fieldOnChange(date);
		if (!dateToCompare.isSame(currentStart)) {
			setWarning(true);
		} else {
			setWarning(false);
		}
	};

	const onSubmit: SubmitHandler<ProcessByShift> = async (
		data: ProcessByShift,
	) => {
		setIsSubmitLoading(true);
		const ladleOpen = data.LadleOpen;
		const ladleClose = data.LadleClose;
		const response = await upsertProcess(
			data.ProcessId === 0 ? null : data.ProcessId,
			ladleOpen,
			ladleClose,
		);
		if (response.ok) {
			actions.setNotificationMessage(
				"Process was " + (isNew ? "created" : "edited") + " successfully",
			);
			await refreshData();
		} else {
			actions.setErrorMessage(response.message);
		}
		setIsSubmitLoading(false);
		onHide(true);
	};

	const open = watch("LadleOpen");
	const close = watch("LadleClose");

	useEffect(() => {
		if (Math.abs(moment(open).diff(moment(close), "hours")) > 3) {
			setEndStartWarning(true);
		} else {
			setEndStartWarning(false);
		}
	}, [open, close]);

	useEffect(() => {
		setWarning(false);
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const process = state.selectedProcess;
				if (process === undefined || process === null) return;
				if (process.ProcessId === 0) {
					// new process
					setTitle("NEW CASTER PROCESS");
					setIsNew(true);
				} else {
					// edit process
					setTitle("EDIT CASTER PROCESS");
					setIsNew(false);
				}
				setValue("ProcessId", process.ProcessId);
				setValue(
					"LadleOpen",
					process.LadleOpen
						? moment.utc(process.LadleOpen).toDate()
						: moment.utc(CurrentStart).toDate(),
				);
				setValue(
					"LadleClose",
					process.LadleClose
						? moment.utc(process.LadleClose).toDate()
						: moment.utc(CurrentStart).add(180, "minutes").toDate(),
				);
				if (process.LadleClose === null) setIsNotClosed(true);
				setIsLoading(false);
			})();
		} else {
			reset();
			setIsNotClosed(false);
		}
	}, [show]);

	useEffect(() => {
		if (isNotClosed) {
			setValue("LadleClose", null);
		}
	}, [isNotClosed]);

	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						maxWidth="md"
						open={show}
						handleClose={() => {
							onHide(true);
						}}
						id="upsertCasterProcess"
						title={title}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content style={{ padding: "15px 30px" }}>
								{isLoading && (
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{ minHeight: "300px" }}
									>
										<LazyLoading />
									</Grid>
								)}
								{!isLoading && (
									<>
										<Grid container spacing={1}>
											<Grid item xs={12} md={6}>
												<Controller
													name="LadleOpen"
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) => (
														<DateTimePicker
															label="Ladle Open"
															timezone={TimeService.getInstance().getServerTimeZone()}
															format="MM/DD/YYYY HH:mm"
															timeSteps={{ minutes: 1 }}
															value={moment(field.value) as any}
															onChange={handleDateChange(field.onChange)}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	variant: "outlined",
																	error: !!errors.LadleOpen,
																},
															}}
														/>
													)}
												/>
												{errors.LadleOpen && (
													<p style={{ color: "red" }}>
														Invalid Ladle Open Date
													</p>
												)}
												{warning && (
													<p style={{ color: "red", fontSize: 12 }}>
														You are changing the date from the Current Start
													</p>
												)}
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12} md={6} style={{ marginTop: 10 }}>
												<Controller
													name="LadleClose"
													control={control}
													rules={{
														required: !isNotClosed,
													}}
													render={({ field }) => (
														<DateTimePicker
															label="Ladle Close"
															timezone={TimeService.getInstance().getServerTimeZone()}
															format="MM/DD/YYYY HH:mm"
															timeSteps={{ minutes: 1 }}
															value={moment(field.value) as any}
															onChange={field.onChange}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	variant: "outlined",
																	error: !!errors.LadleClose,
																},
															}}
														/>
													)}
												/>
												{errors.LadleClose && (
													<p style={{ color: "red" }}>
														Invalid Ladle Closed Date
													</p>
												)}
											</Grid>
											<Grid item xs={12} md={6} style={{ marginTop: 20 }}>
												<FormControlLabel
													control={
														(
															<Checkbox
																{...register("IsOpen")}
																color="primary"
																checked={isNotClosed}
																onChange={(event) =>
																	setIsNotClosed(event.target.checked)
																}
															/>
														) as any
													}
													label="Ladle is not Closed"
												/>
											</Grid>
										</Grid>
										<Grid item xs={6} md={6}>
											{endStartWarning && (
												<p style={{ color: "red", fontSize: 12 }}>
													Open and Close must be within 3 hours of each other
												</p>
											)}
										</Grid>
									</>
								)}
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(true)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
				</Grid>
			</Grid>
		</>
	);
};
