import { ShiftInfo } from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { createSlice } from "@reduxjs/toolkit";
import { DelayByShift } from "../models/DelayByShift";

const DelaysReportInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	errorMessage: string;
	notificationMessage: string;

	shiftId: number | null | false;
	shiftInfo: ShiftInfo;
	delays: DelayByShift[];
	assetForDelayArea: Asset[] | null;
	selectedDelay: DelayByShift | null;
	anchorPoint: any;
	shiftsList: string[];
	crewsList: string[];
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	shiftInfo: {
		CurrentShiftId: 0,
		CurrentProductionDate: new Date(),
		CurrentProductionShift: 0,
		CurrentShift: "",
		CurrentCrew: "",
		CurrentStart: new Date(),
		CurrentEnd: new Date(),
		PreviousShiftId: 0,
		PreviousProductionDate: new Date(),
		PreviousProductionShift: 0,
		PreviousShift: "",
		PreviousCrew: "",
		PreviousStart: new Date(),
		PreviousEnd: new Date(),
		NextShiftId: 0,
		NextProductionDate: null,
		NextProductionShift: 0,
		NextShift: "",
		NextCrew: "",
		NextStart: null,
		NextEnd: null,
		LastShiftId: 0,
		LastProductionDate: new Date(),
		LastProductionShift: 0,
		LastShift: "",
		LastCrew: "",
		LastStart: new Date(),
		LastEnd: new Date(),
	},
	delays: [],
	assetForDelayArea: null,
	selectedDelay: null,
	errorMessage: "",
	notificationMessage: "",
	shiftId: false,
	anchorPoint: {
		x: 0,
		y: 0,
	},
	shiftsList: [],
	crewsList: [],
};

export const DelaysReportReducer = createSlice({
	name: "__",
	initialState: DelaysReportInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setShiftInfo(state, { payload }) {
			state.shiftInfo = payload;
		},
		setDelays(state, { payload }) {
			state.delays = payload;
		},
		setAssetForDelayArea(state, { payload }) {
			state.assetForDelayArea = payload;
		},
		setSelectedDelay(state, { payload }) {
			state.selectedDelay = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setShiftId(state, { payload }) {
			state.shiftId = payload;
		},
		setAnchorPointX(state, { payload }) {
			state.anchorPoint.x = payload;
		},
		setAnchorPointY(state, { payload }) {
			state.anchorPoint.y = payload;
		},
		setShiftsList(state, { payload }) {
			state.shiftsList = payload;
		},
		setCrewsList(state, { payload }) {
			state.crewsList = payload;
		},
	},
});
