import { createSlice } from "@reduxjs/toolkit";
import { CatalogMechanicalTest } from "../../models/CatalogMechanicalTest";

const CatalogMechanicalTestInitialState: {
	mechanicalTests: CatalogMechanicalTest[] | null;
} = {
	mechanicalTests: null,
};

export const CatalogMechanicalTestReducer = createSlice({
	name: "__",
	initialState: CatalogMechanicalTestInitialState,
	reducers: {
		setMechanicalTests(state, { payload }) {
			state.mechanicalTests = payload;
		},
	},
});
