import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { MESAgGrid } from "../../../../../controls/ag-grid/components/MESAgGrid";
import { CatalogResultSource } from "../../../models/CatalogResultSource";
import { useGridStyles } from "../../../styles/gridStyles";
import { CreateResultSource } from "./CreateResultSource";
import { EditResultSource } from "./EditResultSource";
import { useTableData } from "./hooks";
type Props = {
	getCatalogResultSourcesFromAPI: Function;
	isLoading: boolean;
};

export const TableResultSource = ({
	getCatalogResultSourcesFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [idResultSource, setIdResultSource] = useState<number | null>(null);
	const { rows, columns } = useTableData({});
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: CatalogResultSource) => {
			setShowEditModal(!showEditModal);
			setIdResultSource(data.ResultSourceId);
		},
	});

	const classes = useGridStyles();
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			style={{ height: "75vh" }}
			className={classes.root}
		>
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Result Sources
				</Typography>
			</Grid>
			<CreateResultSource
				show={showCreateModal}
				onHide={() => setShowCreateModal(false)}
				refreshData={getCatalogResultSourcesFromAPI}
			/>
			<EditResultSource
				show={showEditModal}
				onHide={(updated: boolean) => {
					setShowEditModal(false);
					setIdResultSource(null);
					if (updated) getCatalogResultSourcesFromAPI();
				}}
				resultSourceId={idResultSource}
			/>
			<MESAgGrid
				rowData={rows || []}
				columnDefs={columns}
				columnTypes={columnTypes}
				defaultColDef={columnDefaults}
			/>
			<Grid container justifyContent="flex-end">
				<Grid item md={2} xs={12} className={classes.btnModal}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{ margin: "0px 15px" }}
						onClick={() => setShowCreateModal(!showCreateModal)}
					>
						NEW RESULT SOURCE
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
