import * as React from "react";
import { CatalogElementPage } from "./CatalogElementPage";

import { CatalogElementProvider } from "./CatalogElementContext";

type Props = {};
const CatalogElement = (props: Props) => {
	return (
		<CatalogElementProvider>
			<CatalogElementPage />
		</CatalogElementProvider>
	);
};
export default CatalogElement;
