import { createSlice } from "@reduxjs/toolkit";
import { GenericReport } from "../models/GenericReport";
import {
	GenericReportFilterDataSource,
	GenericReportMetadataFilter,
} from "../models/GenericReportMetadataFilter";

const GenericReportInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	reportParameters: string[];
	reportCode: string | null;
	reportInfo: GenericReport | null;
	reportMetadataFilters: GenericReportMetadataFilter[] | null;
	filterSources: GenericReportFilterDataSource[][] | null;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	reportParameters: [],
	reportCode: null,
	reportInfo: null,
	reportMetadataFilters: null,
	filterSources: null,
};

export const GenericReportReducer = createSlice({
	name: "__",
	initialState: GenericReportInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setReportParameters(state, { payload }) {
			state.reportParameters = payload;
		},
		setReportCode(state, { payload }) {
			state.reportCode = payload;
		},
		setReportInfo(state, { payload }) {
			state.reportInfo = payload;
		},
		setReportMetadataFilters(state, { payload }) {
			state.reportMetadataFilters = payload;
		},
		setFilterSources(state, { payload }) {
			state.filterSources = payload;
		},
	},
});
