import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { Divider, Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { HeatNavigation } from "../../../controls/heatNavigation";
import { DataInfoCardView } from "../../../controls/infoCardView";
import { DataTypeField, ReportColumn } from "../../../controls/reportTable";
import { useHeatReportContext } from "../../meltshop/heat-report/HeatReportContext";
import { getCasterHeatReport } from "../repositories/ProductionRepository";
import style from "./caster-heat-report.module.css";
import { CasterHeatReportProductTable } from "./components/CasterHeatReportProductTable";
import { CasterHeatReportScrapTable } from "./components/CasterHeatReportScrapTable";
import { CasterHeatReportStrandTable } from "./components/CasterHeatReportStrandTable";
import { CasterHeatReportTemperatureTable } from "./components/CasterHeatReportTemperatureTable";
import "./heatReport.css";

const moment = getMomentTz();
type Props = {};

export const CasterHeatReportPage = (props: Props) => {
	const [selectedTab] = React.useState(2);
	const navigate = useNavigate();
	const [strandColumns, setStrandColumns] = React.useState<ReportColumn[]>([]);

	const { state, actions } = useHeatReportContext();

	const getStringValue = (dataValue: any | undefined) => {
		if (dataValue === undefined || dataValue === null) return "";
		return String(dataValue);
	};
	const getTimeValue = (dataValue: any | undefined) => {
		return dxtToLocalServerTime(dataValue, "HH:mm");
	};
	const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
		switch (newValue) {
			case 0:
				break;
			case 1:
				break;
			case 2:
				break;
			case 3:
				navigate("../chemistry");
				break;
		}
	};
	useEffect(() => {
		(async () => {
			if (state.selectedHeat === null) {
				actions.setHeatReport(null);
				return;
			}
			try {
				actions.setIsLoadingBackground(true);
				const resp = await getCasterHeatReport(
					state.selectedHeat.ProcessId ?? 0,
				);
				actions.setIsLoadingBackground(false);
				if (resp.ok) {
					const heatReport: any = resp.data.tables[0]?.rows[0] ?? null;
					heatReport.Temperatures = resp.data.tables[1]?.rows ?? [];
					heatReport.Strands = resp.data.tables[2]?.rows ?? [];
					heatReport.Products = resp.data.tables[3]?.rows ?? [];
					heatReport.Scraps = resp.data.tables[4]?.rows ?? [];
					actions.setHeatReport(heatReport);
				} else {
					actions.setErrorMessage(resp.message);
				}
			} catch (e: any) {
				actions.setErrorMessage(e.message);
			}
			actions.setIsLoadingBackground(false);
		})();
	}, [state.selectedHeat]);
	useEffect(() => {
		console.log("state.heatReport", state.heatReport);
		if (state.heatReport === null && false) {
			setStrandColumns([]);
			return;
		}
		setStrandColumns([
			{
				HeaderName: "Housing",
				Field: "Housing",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				HeaderName: "Tube",
				Field: "Tube",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Mold Water Flow GPM",
				HeaderName: "Start",
				Field: "Mold Water GPM.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Mold Water Flow GPM",
				HeaderName: "End",
				Field: "Mold Water GPM.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Mold Water Temp F",
				HeaderName: "Start",
				Field: "Mold Water Temp.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Mold Water Temp F",
				HeaderName: "End",
				Field: "Mold Water Temp.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Mold Water Delta T F",
				HeaderName: "Start",
				Field: "Mold Water DT.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Mold Water Delta T F",
				HeaderName: "End",
				Field: "Mold Water DT.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Speed in/min",
				HeaderName: "Start",
				Field: "Speed.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Speed in/min",
				HeaderName: "End",
				Field: "Speed.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Billet Temp F",
				HeaderName: "Start",
				Field: "Billet Temp.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Billet Temp F",
				HeaderName: "End",
				Field: "Billet Temp.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray North",
				HeaderName: "Start",
				Field: "Spray.North.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray North",
				HeaderName: "End",
				Field: "Spray.North.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray East",
				HeaderName: "Start",
				Field: "Spray.East.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray East",
				HeaderName: "End",
				Field: "Spray.East.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray South",
				HeaderName: "Start",
				Field: "Spray.South.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray South",
				HeaderName: "End",
				Field: "Spray.South.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray West",
				HeaderName: "Start",
				Field: "Spray.West.Start",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
			{
				ParentName: "Spray West",
				HeaderName: "End",
				Field: "Spray.West.End",
				Type: DataTypeField.Number,
				Width: "100",
				Format: "0",
				CellStyle: "",
			},
		]);
	}, [state.heatReport]);
	if (!state.isLoadingPage) {
		return (
			<>
				<LazyBackDropLoading open={state.isLoadingBackground} />
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "15px 20px 10px",
						borderRadius: "0.75rem",
					}}
				>
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										Heat Report
									</Typography>
								</Grid>
							</Grid>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<Grid
										item
										lg={6}
										xs={12}
										style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
									>
										<HeatNavigation
											onTextChanged={actions.setSearchHeatName}
											onValueChanged={actions.setSelectedOption}
											heats={state.heatOptions}
											onNextClicked={actions.nextHeat}
											onPreviousClicked={actions.previousHeat}
											defaultText={""}
										/>
									</Grid>
									<Grid
										item
										lg={6}
										xs={12}
										style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
									>
										{/* {state.heatReport !== undefined &&
											state.heatReport !== null && ( */}
										<Tabs
											value={selectedTab}
											textColor="primary"
											onChange={handleChangeTab}
											className={style.Tabs}
										>
											<Tab label="EAF" disabled />
											<Tab label="Trimming" disabled />
											<Tab label="Caster" />
											<Tab
												label="Chemistry"
												style={{ color: "#212529", fontWeight: "bolder" }}
											/>
										</Tabs>
										{/* )} */}
									</Grid>
								</Grid>
							</Grid>
							{state.heatReport !== undefined && state.heatReport !== null && (
								<>
									<Grid item md={12} xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid
												item
												lg={2}
												md={3}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Date"
													values={[
														getStringValue(state.heatReport?.ProductionDate),
													]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Shift"
													values={[
														getStringValue(state.heatReport?.ProductionShift),
													]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												md={2}
												lg={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Crew"
													values={[getStringValue(state.heatReport?.Crew)]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												md={2}
												lg={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Grade"
													values={[getStringValue(state.heatReport?.Grade)]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												md={3}
												lg={2}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Ladle"
													subTitles={["Number", "Heats"]}
													values={[
														getStringValue(state.heatReport?.Ladle),
														getStringValue(state.heatReport?.HeatCountLadle),
													]}
													headerBackgroundColor="#479dc4"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Divider style={{ marginBottom: 10 }} />
									<Grid item md={12} xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Typography variant="h5" style={{ fontWeight: 400 }}>
												Caster Section
											</Typography>
										</Grid>
									</Grid>
									<Grid item md={12} xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid
												item
												lg={4}
												md={4}
												xl={3}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Times"
													subTitles={["Open", "Close", "Duration"]}
													values={[
														getTimeValue(state.heatReport?.TimesLadleOpen),
														getTimeValue(state.heatReport?.TimesLadleClose),
														getStringValue(state.heatReport?.TimesDuration),
													]}
													badges={["", "", "min"]}
													headerBackgroundColor="#479dc4"
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Open"
													values={[getStringValue(state.heatReport?.Open)]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												lg={3}
												md={2}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Nozzle"
													subTitles={["Size", "Heats"]}
													values={[
														getStringValue(state.heatReport?.NozzleSize),
														getStringValue(state.heatReport?.NozzleHeats),
													]}
													headerBackgroundColor="#479dc4"
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Shroud Gas"
													values={[
														getStringValue(state.heatReport?.NozzleShroud),
													]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												lg={3}
												md={2}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Tundish"
													subTitles={["Number", "Heats"]}
													values={[
														getStringValue(state.heatReport?.TundishNumber),
														getStringValue(state.heatReport?.TundishHeats),
													]}
													headerBackgroundColor="#479dc4"
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Billet Size"
													values={[
														getStringValue(state.heatReport?.BilletSizeName),
													]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={12} xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid
												item
												lg={3}
												md={4}
												xl={3}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<CasterHeatReportTemperatureTable
													rows={state.heatReport.Temperatures ?? []}
													loadingGrid={state.isLoadingBackground}
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Liquidus"
													values={[
														getStringValue(
															state.heatReport?.LiquidusTemperature,
														),
													]}
													headerBackgroundColor="#479dc4"
													badges={["F"]}
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={3}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Pourback"
													subTitles={["Tons", "Reason"]}
													values={[
														getStringValue(state.heatReport?.PourbackTons),
														getStringValue(state.heatReport?.PourbackReason),
													]}
													headerBackgroundColor="#479dc4"
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={3}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Pourback"
													subTitles={["Tons", "Reason"]}
													values={[
														getStringValue(state.heatReport?.PourbackTons),
														getStringValue(state.heatReport?.PourbackReason),
													]}
													headerBackgroundColor="#479dc4"
												/>
											</Grid>
											<Grid
												item
												lg={2}
												md={2}
												xl={1}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="First Heat"
													values={[state.heatReport?.FirstHeat ? "Yes" : "No"]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
											<Grid
												item
												lg={3}
												md={3}
												xl={2}
												style={{ margin: 0, padding: "1rem 0 1.5rem 0.5rem" }}
											>
												<DataInfoCardView
													title="Foreman"
													values={[getStringValue(state.heatReport?.Foreman)]}
													headerBackgroundColor="#479dc4"
													headerSpan={true}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Divider style={{ marginBottom: 10 }} />
									<Grid item md={12} xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Grid
												item
												lg={6}
												md={6}
												xl={4}
												style={{ margin: 0, padding: "1rem 0 0.5rem 0.5rem" }}
											>
												<Typography variant="h5" style={{ fontWeight: 400 }}>
													Strands
												</Typography>
											</Grid>
											<Grid
												item
												lg={6}
												md={6}
												xl={4}
												style={{ margin: 0, padding: "1rem 0 0.5rem 0.5rem" }}
											>
												<Typography variant="h5" style={{ fontWeight: 400 }}>
													Products
												</Typography>
											</Grid>
											<Grid
												item
												lg={6}
												md={6}
												xl={4}
												style={{ margin: 0, padding: "1rem 0 0.5rem 0.5rem" }}
											>
												<Typography variant="h5" style={{ fontWeight: 400 }}>
													Scrap
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={12} xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Grid
												item
												lg={6}
												md={6}
												xl={4}
												style={{ margin: 0, padding: "0.5rem 0 0.5rem 0.5rem" }}
											>
												<CasterHeatReportStrandTable
													rows={state.heatReport.Strands ?? []}
													loadingGrid={state.isLoadingBackground}
												/>
											</Grid>
											<Grid
												item
												lg={6}
												md={6}
												xl={4}
												style={{ margin: 0, padding: "0.5rem 0 0.5rem 0.5rem" }}
											>
												<CasterHeatReportProductTable
													rows={state.heatReport.Products ?? []}
													loadingGrid={state.isLoadingBackground}
												/>
											</Grid>
											<Grid
												item
												lg={6}
												md={6}
												xl={4}
												style={{ margin: 0, padding: "0.5rem 0 0.5rem 0.5rem" }}
											>
												<CasterHeatReportScrapTable
													rows={state.heatReport.Scraps ?? []}
													loadingGrid={state.isLoadingBackground}
												/>
											</Grid>
										</Grid>
									</Grid>
								</>
							)}
							{state.heatReport === null && (
								<Grid item md={12} xs={12}>
									<Typography variant="h6" style={{ fontWeight: 400 }}>
										No caster data available for this heat.
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
	return <LazyLoading />;
};
