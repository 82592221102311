import * as React from "react";
import { HeatReconciliationProvider } from "../context/HeatReconciliationContext";
import { HeatReconciliationPage } from "./HeatReconciliationPage";
type Props = {};
const HeatReconciliation = (props: Props) => {
	return (
		<HeatReconciliationProvider>
			<HeatReconciliationPage />
		</HeatReconciliationProvider>
	);
};
export default HeatReconciliation;
