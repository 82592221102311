import { createSlice } from "@reduxjs/toolkit";
import { Ladle } from "../models/Ladles/LadleModel";

const LadlesInitialState: {
	ladles: Ladle[];
} = {
	ladles: [],
};

export const LadlesReducer = createSlice({
	name: "__",
	initialState: LadlesInitialState,
	reducers: {
		setLadles(state, { payload }) {
			state.ladles = payload;
		},
	},
});
