import React, { useEffect, useRef, useState } from "react";
import { BarTask } from "../../types/bar-task";
import { GanttContentMoveAction } from "../../types/gantt-asset-actions";
import style from "./asset-item.module.css";
import { Bar } from "./bar/bar";
import { BarSmall } from "./bar/bar-small";

export type AssetItemProps = {
	asset: BarTask;
	arrowIndent: number;
	assetHeight: number;
	isSelected: boolean;
	svgWidth: number;
	processLinesRef: React.MutableRefObject<SVGElement | null>;
	onEventStart: (
		action: GanttContentMoveAction,
		selectedAsset: BarTask,
		event?: React.MouseEvent,
	) => any;
};

export const AssetItem: React.FC<AssetItemProps> = (props) => {
	const {
		asset,
		arrowIndent,
		assetHeight,
		isSelected,
		onEventStart,
		svgWidth,
	} = {
		...props,
	};
	const textRef = useRef<SVGTextElement>(null);
	const [assetItem, setAssetItem] = useState<JSX.Element>(<div />);
	const [isTextInside, setIsTextInside] = useState(true);

	let begin = asset.x1 < 0 ? 0 : asset.x1;
	let end = asset.x2 > svgWidth ? svgWidth : asset.x2;

	let hasMinWidth = end - begin > 50;

	useEffect(() => {
		switch (asset.typeInternal) {
			case "smalltask":
				setAssetItem(<BarSmall {...props} />);
				break;
			default:
				setAssetItem(<Bar {...props} />);
				break;
		}
	}, [asset, isSelected]);

	useEffect(() => {
		if (textRef.current) {
			setIsTextInside(textRef.current.getBBox().width < asset.x2 - asset.x1);
		}
	}, [textRef, asset]);

	const getX = () => {
		if (asset.x1 < 0) {
			if (asset.x2 < svgWidth) {
				return asset.x2 / 2;
			} else {
				return svgWidth / 2;
			}
		}
		if (asset.x2 > svgWidth) {
			if (asset.x1 > 0) {
				return asset.x1 + (svgWidth - asset.x1) / 2;
			} else {
				return svgWidth / 2;
			}
		}
		const width = asset.x2 - asset.x1;
		const hasChild = asset.barChildren.length > 0;

		if (isTextInside) {
			return asset.x1 + width * 0.5;
		}
		return asset.x1 + width + arrowIndent * +hasChild + arrowIndent * 0.2;
	};
	const subLabelYPadding: number = 0.2;
	const subLabelXPadding: number = 0.05;

	const getSubX = (left: boolean) => {
		let begin = asset.x1;
		let end = asset.x2;

		if (asset.x1 < 0) {
			begin = 0;
			if (asset.x2 < svgWidth) {
				end = asset.x2;
			} else {
				end = svgWidth;
			}
		}

		if (asset.x2 > svgWidth) {
			end = svgWidth;
			if (asset.x1 > 0) {
				begin = asset.x1;
			} else {
				begin = 0;
			}
		}

		return left
			? begin + (end - begin) * subLabelXPadding
			: begin + (end - begin) * (1 - subLabelXPadding);
	};

	return (
		<g
			onMouseEnter={(e) => {
				onEventStart("mouseenter", asset, e);
			}}
			onMouseLeave={(e) => {
				onEventStart("mouseleave", asset, e);
			}}
			onDoubleClick={(e) => {
				onEventStart("dblclick", asset, e);
			}}
			onFocus={() => {
				onEventStart("select", asset);
			}}
			onContextMenu={(e) => {
				onEventStart("contextMenu", asset, e);
			}}
		>
			{assetItem}

			<text
				x={getX()}
				y={asset.y + assetHeight * 0.5}
				className={
					isTextInside
						? style.barLabel
						: style.barLabel && style.barLabelOutside
				}
				ref={textRef}
			>
				{asset.name}
			</text>
			{hasMinWidth ? (
				<>
					<text
						x={getSubX(true)}
						y={asset.y + assetHeight * subLabelYPadding}
						className={style.barSubLabel}
						ref={textRef}
					>
						{asset.textLeftTop}
					</text>
					<text
						x={getSubX(false)}
						y={asset.y + assetHeight * subLabelYPadding}
						className={[style.barSubLabel, style.barSubLabelRight].join(" ")}
						ref={textRef}
					>
						{asset.textRightTop}
					</text>
					<text
						x={getSubX(true)}
						y={asset.y + assetHeight * (1 - subLabelYPadding)}
						className={style.barSubLabel}
						ref={textRef}
					>
						{asset.textLeftBottom}
					</text>
					<text
						x={getSubX(false)}
						y={asset.y + assetHeight * (1 - subLabelYPadding)}
						className={[style.barSubLabel, style.barSubLabelRight].join(" ")}
						ref={textRef}
					>
						{asset.textRightBottom}
					</text>
				</>
			) : (
				""
			)}
		</g>
	);
};
