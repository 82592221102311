import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogMoldSizeReducer } from "./CatalogMoldSizeReducer";

const CatalogMoldSizeContext = createContext({
	state: CatalogMoldSizeReducer.getInitialState(),
	actions: CatalogMoldSizeReducer.actions,
});

export const useCatalogMoldSizeContext = () =>
	useContext(CatalogMoldSizeContext);

export const CatalogMoldSizeProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogMoldSizeReducer.getInitialState(),
		reducers: CatalogMoldSizeReducer.caseReducers,
	});
	return (
		<CatalogMoldSizeContext.Provider value={{ state, actions }}>
			{children}
		</CatalogMoldSizeContext.Provider>
	);
};
