import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		checkbox: {
			userSelect: "none",
		},
		modalBody: {},
		title: {
			fontSize: 15,
		},
		chip: {
			margin: 2,
		},
		errorLabel: {
			fontSize: 12,
			color: "#F44336",
		},
		noPrint: {
			"@media print": {
				display: "none !important",
			},
		},
		modalContent: {
			"@media print": {
				width: "100%",
			},
			overflowY: "auto",
		},
		pageBreakAvoid: {
			"@media print": {
				pageBreakInside: "avoid",
				pageBreakAfter: "auto",
			},
		},
		addButton: {
			minWidth: "35px",
			maxWidth: "35px",
			maxHeight: "35px",
			minHeight: "35px",
			fontSize: "1.5rem",
			margin: "8px",
		},
		textFieldMUI: {
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.background.paper,
			border: "1px solid #ced4da",
			fontSize: "20px",
			width: "100%",
			padding: "25px 5px",
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			fontFamily: theme.typography.fontFamily,
		},
	}),
);
