import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-balham.min.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { useAcquisitionStatsContext } from "../context/AcquisitionStatsContext";
import { useTableData } from "./useTableData";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-group-text ": {
			fontSize: 11,
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell": {
			textAlign: "center !important",
			fontSize: 10,
		},
		"& .ag-font-size": {
			fontSize: 0,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
	},
}));

const dummyData: any[] = [
	{
		ProductionDate: "2023-09-22",
		ProductionShift: 1,
		Crew: "A",
		Heatname: 14865,
		MSProcess: 0,
		CASProcesses: 0,
		CASStrandProcess: 0,
		CASProbes: 0,
		CASProducts: null,
		LABChemistries: 6,
		LABMechanicals: 6,
		creation: "2023-09-22 12:34:15.663",
		id: 1,
		columnData: "2",
	},
	{
		ProductionDate: "2023-09-22",
		ProductionShift: 1,
		Crew: "A",
		Heatname: 26853,
		MSProcess: 1,
		CASProcesses: 1,
		CASStrandProcess: 3,
		CASProbes: 0,
		CASProducts: 89,
		LABChemistries: 6,
		LABMechanicals: 6,
		creation: "2023-09-22 11:02:15.117",
		id: 2,
		columnData: "2",
	},
	{
		ProductionDate: "2023-09-21",
		ProductionShift: 2,
		Crew: "B",
		Heatname: 14864,
		MSProcess: 1,
		CASProcesses: 1,
		CASStrandProcess: 3,
		CASProbes: 0,
		CASProducts: 86,
		LABChemistries: 6,
		LABMechanicals: 6,
		creation: "2023-09-22 09:36:14.547",
		id: 3,
		columnData: "2",
	},
];

export const AcquisitionStatsTable = ({
	isLoading,
}: { isLoading: boolean }) => {
	const classes = useStyles();

	const { state } = useAcquisitionStatsContext();

	const [quickFilterInput, setQuickFilterInput] = useState("");
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);
	const [gridColumnAPI, setGridColumnAPI] = useState<any>(null);

	const { rowData, columnData, defaultColDef } = useTableData(
		state.acquisitionStats,
	);

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
		setGridColumnAPI(params.columnApi);
	};

	useEffect(() => {
		if (gridAPI) {
			gridAPI.setQuickFilter(quickFilterInput);
		}
	}, [quickFilterInput, gridAPI]);

	useEffect(() => {
		if (gridAPI) {
			if (isLoading) gridAPI.showLoadingOverlay();
			else gridAPI.hideOverlay();
		}
	}, [gridAPI, isLoading]);

	return (
		<Grid container className={classes.root}>
			<TextField
				variant="outlined"
				fullWidth
				placeholder="Filter..."
				value={quickFilterInput}
				onChange={(e) => setQuickFilterInput(e.target.value)}
				size="small"
			/>

			<Grid
				item
				md={12}
				xs={12}
				style={{
					height: "80vh",
					minHeight: "30vh",
					border: "4px solid #ccc",
					borderRadius: 10,
					padding: 10,
					margin: "20px 0 10px",
				}}
				className={"ag-theme-balham"}
			>
				<AgGridReact
					defaultColDef={defaultColDef}
					onGridReady={onGridReady}
					rowData={rowData}
					columnDefs={columnData}
					overlayLoadingTemplate={"Loading..."}
				/>
			</Grid>
		</Grid>
	);
};
