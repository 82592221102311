import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogSampleType } from "../models/CatalogSampleType";

export const getCatalogSampleTypes = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[LAB].[GetCatSampleTypes]", []);
};
export const upsertCatalogSampleType = async (
	sampleType: CatalogSampleType,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: sampleType.SampleTypeId });
	parameters.push({ name: "Name", value: sampleType.SampleTypeName });
	parameters.push({ name: "Enabled", value: sampleType.IsActive });

	return await apiService.callV2("[LAB].[UpsertCatSampleType]", parameters);
};
export const getCatalogSampleType = async (SampleTypeId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "SampleTypeId", value: SampleTypeId });

	return await apiService.callV2("[LAB].[GetCatSampleType]", parameters);
};
