import { getMomentTz } from "@dexteel/mesf-core";
import { isNil } from "lodash-es";
import numeral from "numeral";

const moment = getMomentTz();

export const isNullOrUndefined = (value: any) => {
	return isNil(value);
};
export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
	myEnum: T,
	enumValue: string,
): keyof T | null {
	const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue); // eslint-disable-next-line
	return keys.length > 0 ? keys[0] : null;
}
export const convertDateToString = (value: any, format = "") => {
	if (value === "" || value === null || value === undefined) return "";
	const m = moment(value);
	if (!m.isValid()) return "NaD";
	format = format === "" ? "MM-DD-YYYY" : format;
	return m.format(format);
};
export const convertNumberToString = (value: any, format = "") => {
	if (value === "" || value === null || value === undefined) return "";
	const m = numeral(value);
	if (Number.isNaN(m.value)) return "NaN";
	format = format === "" ? "0.00" : format;
	return m.format(format);
};
export const convertBooleanToString = (value: any, format = "") => {
	if (value === "" || value === null || value === undefined) return "";
	if (format === "") return String(value);
	const cellValues = format.split("/");
	if (value === true && cellValues.length > 0) return cellValues[0];
	if (value === false && cellValues.length > 1) return cellValues[1];
	else return String(value);
};
export const convertStringToString = (value: any, format = "") => {
	if (format === "") return String(value);
	return format.replace("{value}", value);
};
const formatStringToCamelCase = (str: string) => {
	const splitted = str.split("-");
	if (splitted.length === 1) return splitted[0];
	return (
		splitted[0] +
		splitted
			.slice(1)
			.map((word) => word[0].toUpperCase() + word.slice(1))
			.join("")
	);
};

export const getStyleObjectFromString = (str: string) => {
	const style: any = {};
	str.split(";").forEach((el) => {
		const [property, value] = el.split(":");
		if (!property) return;

		const formattedProperty = formatStringToCamelCase(property.trim());
		style[formattedProperty] = value === undefined ? "" : value.trim();
	});
	return style;
};
export const getClassObjectFromString = (str: string) => {
	let classReturn = "";
	str.split(".").forEach((el) => {
		classReturn += el;
	});
	return classReturn;
};
