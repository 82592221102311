import { ErrorModal } from "@dexteel/mesf-core";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { CatalogBucket } from "../../../../../../steel/models/CatalogBucket";
import { getCatalogBuckets } from "../../../../../../steel/repositories/CatalogBucketRepository";
import { useModalStyles } from "../../../../../eaf-processes/styles/modalStyles";

interface Props {
	value: number | null;
	onChange: (value: number | null) => void;
	onBlur?: () => void;
}

export const BucketsSelector = ({ value, onChange }: Props) => {
	const [buckets, setBuckets] = useState<CatalogBucket[]>([]);
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const classes = useModalStyles();

	const getBuckets = async () => {
		setIsLoading(true);
		setError("");
		const resp = await getCatalogBuckets();
		if (resp.ok) {
			setBuckets(resp.data.tables[0].rows);
		} else {
			setError(resp.message!);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			await getBuckets();
		})();
	}, []);

	return (
		<>
			<Autocomplete
				id="bucket-list"
				clearOnEscape
				options={buckets}
				getOptionLabel={(option) => option.BucketName}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Buckets"
						variant="outlined"
						fullWidth
						size="small"
						InputProps={{
							...params.InputProps,
							className: classes.autocompleteField,
						}}
					/>
				)}
				value={buckets.find((option) => option.BucketId === value) || null}
				onChange={(event, newValue) => {
					if (newValue && newValue.BucketId) {
						onChange(newValue.BucketId);
					} else {
						onChange(null);
					}
				}}
			/>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
