import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
	contextMenu: {
		fontSize: "14px",
		backgroundColor: "#fff",
		borderRadius: "2px",
		padding: "5px 0 5px 0",
		width: "170px",
		height: "auto",
		margin: "0",
		position: "absolute",
		listStyle: "none",
		boxShadow: "0 0 20px 0 #ccc",
	},
	fixedBottom: {
		position: "fixed",
		bottom: 0,
		right: 0,
		padding: "0px 1.5rem",
		textAlign: "right",
	},
	labelNormalClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		border: "none",
		backgroundColor: "#ced2cc",
		borderTopLeftRadius: "0.25rem !important",
		borderBottomLeftRadius: "0.25rem !important",
	},
	labelNormalButtonClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		border: "none",
		backgroundColor: "#ced2cc",
		cursor: "pointer",
	},
	labelCheckboxClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.50rem",
		border: "none",
		backgroundColor: "#ced2cc",
		borderTopLeftRadius: "0.25rem !important",
		borderBottomLeftRadius: "0.25rem !important",
	},
	labelScheduledClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#B1C381 !important",
		border: "none",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
	},
	labelMeltingClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#AFC8AD !important",
		border: "none",
	},
	labelTappedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#F2D3A2 !important",
		border: "none",
	},
	labelOpenedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#BEADFA !important",
		border: "none",
	},
	labelClosedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#FF8080 !important",
		border: "none",
	},
	labelReturnedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#82A0D8 !important",
		border: "none",
	},
	labelFinishedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#7C9D96 !important",
		border: "none",
	},

	inputHeatNavigator: {
		textAlign: "center",
		fontSize: "22px",
		maxWidth: "130px",
	},
	inputShiftNavigator: {
		cursor: "pointer",
		textDecoration: "underline",
		textDecorationColor: "blue",
		color: "blue",
	},

	inputNormalClass: {
		fontSize: "0.60rem",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
	},
	inputForTundishClass: {
		fontSize: "0.60rem",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
		backgroundColor: "green",
	},
	styleLabel: {
		width: "150px",
		maxWidth: "150px",
	},

	normalItem: {
		padding: "4px 0px",
	},
	groupCheckBoxItem: {
		backgroundColor: "transparent !important",
		position: "relative",
		flex: "1 1 auto",
		width: "1%",
		minWidth: "0",
		marginBottom: "0",
	},
	checkBoxItem: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderTopRightRadius: "0.25rem !important",
		borderBottomRightRadius: "0.25rem !important",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
		backgroundColor: "#fff !important",
		margin: "auto",
		//maxWidth: "10rem",
		padding: " 0",
		// width: "10rem",
		//minWidth: "0",
		border: "1px solid #ced4da",
	},
	simpleCellHeader: {
		padding: "0.275rem 0.45rem",
		fontSize: "0.75rem",
		backgroundColor: "#ced2cc   !important",
		border: "1px solid #ced4da",
		color: "#495057",
		textAlign: "center",
	},
	simpleCellText: {
		padding: "0.275rem 0.45rem",
		fontSize: "0.75rem",
		borderRadius: "0px",
		height: "35px",
	},
	simpleCellCheckBox: {
		border: "1px solid #ced4da",
		textAlign: "center",
		margin: "auto",
		height: "2.7rem",
	},
	simpleCellNumeric: {
		fontSize: "0.75rem",
		borderRadius: "0px",
		padding: "0px 0.6rem",
		height: "35px",
		minWidth: "30px",
		textAlign: "right",
	},

	simpleButton: {
		borderRadius: "0.25rem",
		backgroundColor: "white",
		width: " 25px",
		fontSize: "14px",
		minWidth: " 25px",
		height: "25px",
		cursor: "pointer !important",
		border: "1px solid black",
		"&:hover": {
			backgroundColor: "white",
		},
		margin: "0px 2px",
		color: "black",
	},

	manualButton: {
		backgroundColor: "#3f51b5",
		"&:hover": {
			backgroundColor: "#3f51b5",
		},
		color: "white",
	},
	automaticButton: {
		backgroundColor: "#008A00",
		"&:hover": {
			backgroundColor: "#008A00",
		},
		color: "white",
	},

	historicButton: {
		backgroundColor: "blue",
		width: " 30px",
		height: "30px",
		color: "white",
	},
	lockButton: {
		borderRadius: "0px",
		backgroundColor: "#efefef",
		width: " 20px",
		height: "20px",
		cursor: "pointer !important",
		border: "1px solid #000",
		"&:hover": {
			backgroundColor: "#efefef",
		},
		margin: "0px 2px",
	},
	deleteButton: {
		borderRadius: "0px",

		width: " 20px",
		height: "20px",
		cursor: "pointer !important",
		"&:hover": {
			backgroundColor: "#efefef",
		},
		color: "red",
		margin: "0px 2px",
	},

	headerButton: {
		minWidth: "150px",
	},
	dateTimePicker: {
		"& *": {
			font: "none",
			fontSize: "0.65rem !important",
		},
		"& .MuiInputBase-input": {
			padding: "0 !important",
			paddingLeft: "10px !important",
			// height: "30px !important",
			width: "187px!important ",
			border: 0,
		},
	},
	dateTimePickerX: {
		"& .MuiInputBase-root": {
			height: "35px",
			width: "100%",
			"& .MuiInputBase-input": {
				padding: "6px 10px",
				font: "none",
				fontSize: "0.65rem !important",
				borderRadius: "4px",
			},
		},
	},
	dateToTableTimePicker: {
		"& *": {
			font: "none",
			fontSize: "0.65rem !important",
		},
		"& .MuiInputBase-input": {
			padding: "0 !important",
			paddingLeft: "10px !important",
			// height: "30px !important",
			width: "110px!important ",
			border: 0,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 0,
			height: 35,
		},
	},
	iconButton: {
		color: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "14px",
		borderRadius: "0.25rem",
	},
	customInput: {
		width: "100%",
		"& .MuiInputBase-input": {
			//   padding: '10px',
			fontSize: "1.2rem",
		},
	},
	labelNormalForDatesClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		border: "none",
		backgroundColor: "#ced2cc",
		borderTopLeftRadius: "0.25rem !important",
		borderBottomLeftRadius: "0.25rem !important",
		width: "100px",
		maxWidth: "120px",
	},
	listbox: {
		width: 115,
		margin: 0,
		padding: 0,
		position: "absolute",
		listStyle: "none",
		backgroundColor: theme.palette.background.paper,
		overflow: "auto",
		maxHeight: 200,
		border: "1px solid rgba(0,0,0,.25)",
		'& li[data-focus="true"]': {
			backgroundColor: "#4a8df6",
			color: "white",
			cursor: "pointer",
		},
		"& li:active": {
			backgroundColor: "#2977f5",
			color: "white",
		},
	},
}));
