import { ErrorModal } from "@dexteel/mesf-core";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDataEntryContext } from "../context/reducer.provider";

export const Notifications = () => {
	const {
		state: { notificationMessage, errorMessage },
		actions: { setNotificationMessage, setErrorMessage },
	} = useDataEntryContext();

	return (
		<>
			<Snackbar
				open={!!notificationMessage}
				color={"primary"}
				autoHideDuration={4000}
				onClose={() => setNotificationMessage("")}
			>
				<Alert>{notificationMessage}</Alert>
			</Snackbar>
			<ErrorModal error={errorMessage} onHide={() => setErrorMessage("")} />
		</>
	);
};
