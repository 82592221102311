import { useAutocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useStyles } from "../styles";

interface Params {
	value: number | null;
	options: { id: number; name: string }[];
	onChange: (value: string | null) => void;
	onFocus: () => void;
}
export const MoldAutocomplete = ({
	value,
	options,
	onChange,
	onFocus,
}: Params) => {
	const [moldName, setMoldName] = useState("");
	const classes = useStyles();
	const {
		getRootProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
	} = useAutocomplete({
		id: "use-autocomplete-demo",
		options: options,
		getOptionLabel: (option) => option.name,
		onChange: (event, newValue) => {
			onChange(newValue?.id.toString() || null);
		},
		onInputChange: (event, newInputValue) => {
			setMoldName(newInputValue);
		},
	});

	useEffect(() => {
		const selectedOption = options.find((option) => option.id === value);
		if (selectedOption) {
			setMoldName(selectedOption.name);
		} else {
			setMoldName("");
		}
	}, [value, options]);

	return (
		<>
			<div onFocus={() => onFocus()}>
				<div {...getRootProps()}>
					<input
						id="moldName"
						className={
							!moldName
								? "custom-select " + classes.inputNormalClass
								: classes.simpleCellNumeric
						}
						{...getInputProps()}
						style={{
							width: "100%",
							height: "35px",
							border: "1px solid #ced4da",
						}}
						value={moldName}
					/>
				</div>
				{groupedOptions.length > 0 ? (
					<div style={{ width: "20px" }}>
						<ul className={classes.listbox} {...getListboxProps()}>
							<li value={""}> </li>
							{groupedOptions.map((option, index) => (
								<li {...getOptionProps({ option, index })} key={option.id}>
									{option.name}
								</li>
							))}
						</ul>
					</div>
				) : null}
			</div>
		</>
	);
};
