import { ErrorModal } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import {
	GetContextMenuItemsParams,
	MenuItemDef,
	RowClassParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";
import { useDelaysReportContext } from "../context/DelaysReportContext";
import { DelayByShift } from "../models/DelayByShift";
import { useGridStyles } from "../styles/gridStyles";
import { useTableData } from "./hooks";

type Props = {
	loadingGrid: boolean;
	rows: DelayByShift[];
	refreshData: Function;
	gridRef: React.RefObject<AgGridReact>;
};

export const DelaysReportTable = ({ loadingGrid, rows, gridRef }: Props) => {
	const gridStyles = useGridStyles();
	const { columns } = useTableData();

	const {
		state: {
			anchorPoint: { x, y },
			errorMessage,
		},
		actions: { setErrorMessage },
	} = useDelaysReportContext();

	const getRowStyle = (params: RowClassParams) => {
		if (!params.data.IsActive || params.data.IsMicroDelay) {
			return { background: "#E8EBEB" };
		}
	};

	useEffect(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);

	const getContextMenuItems = (
		params: GetContextMenuItemsParams,
	): (string | MenuItemDef)[] => {
		const data = params.node?.data;

		const addNewDelay: (string | MenuItemDef)[] = [];

		return addNewDelay;
	};

	return (
		<div onContextMenu={(e) => e.preventDefault()}>
			<div>
				<Grid container className={gridStyles.root}>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							height: "calc(100vh - 300px)",
							minHeight: "calc(100vh - 300px)",
							margin: "20px 0 10px",
						}}
						className={"ag-theme-balham"}
					>
						<AgGridReact
							ref={gridRef}
							rowSelection="single"
							pagination={false}
							animateRows={true}
							rowHeight={40}
							headerHeight={40}
							singleClickEdit={true}
							tooltipShowDelay={0}
							tooltipHideDelay={2000}
							rowData={rows}
							columnDefs={columns}
							getContextMenuItems={getContextMenuItems}
							getRowStyle={getRowStyle}
						/>
					</Grid>
				</Grid>
			</div>
			<ErrorModal error={errorMessage} onHide={() => setErrorMessage("")} />
		</div>
	);
};
