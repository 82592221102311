import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getChemistriesForL3 = async (
	ShiftId: number | null,
	ShowAck: boolean = false,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ShiftId", value: ShiftId });
	parameters.push({ name: "ShowAck", value: ShowAck });

	const resp = await apiService.callV2(
		"[SWV].[GetChemistriesForL3]",
		parameters,
	);
	return resp;
};
export const sendChemistryToL3 = async (
	chemistrySampleId: number,
	forced: boolean,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ChemistrySampleId", value: chemistrySampleId });
	parameters.push({ name: "Forced", value: forced });

	const resp = await apiService.callV2("[SWV].[SendChemistryToL3]", parameters);
	return resp;
};

export const getL3Mechanicales = async (
	ShiftId: number | null,
	ShowAck: boolean = false,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ShiftId", value: ShiftId });
	parameters.push({ name: "ShowAck", value: ShowAck });

	const resp = await apiService.callV2(
		"[SWV].[GetMechanicalsForL3]",
		parameters,
	);
	return resp;
};
export const saveL3Mechanical = async (
	mechanicalSampleId: number,
	forced: boolean,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "MechanicalSampleId", value: mechanicalSampleId });
	parameters.push({ name: "Forced", value: forced });
	const resp = await apiService.callV2(
		"[SWV].[SendMechanicalToL3]",
		parameters,
	);
	return resp;
};
