import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { HeatReconciliationReducer } from "../reducers/HeatReconciliationReducer";

const HeatReconciliationContext = createContext({
	state: HeatReconciliationReducer.getInitialState(),
	actions: HeatReconciliationReducer.actions,
});

export const useHeatReconciliationContext = () =>
	useContext(HeatReconciliationContext);

export const HeatReconciliationProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [state, actions] = useComplexState({
		initialState: HeatReconciliationReducer.getInitialState(),
		reducers: HeatReconciliationReducer.caseReducers,
	});

	return (
		<HeatReconciliationContext.Provider value={{ state, actions }}>
			{children}
		</HeatReconciliationContext.Provider>
	);
};
