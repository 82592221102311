import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CategoriesReducer } from "../reducers/CatalogCategoriesReducer";

const CategoriesContext = createContext({
	state: CategoriesReducer.getInitialState(),
	actions: CategoriesReducer.actions,
});

export const useCatalogCategoryContext = () => useContext(CategoriesContext);

export const CategoriesProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CategoriesReducer.getInitialState(),
		reducers: CategoriesReducer.caseReducers,
	});

	return (
		<CategoriesContext.Provider value={{ state, actions }}>
			{children}
		</CategoriesContext.Provider>
	);
};
