import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { NumericCellRenderer } from "../../../../controls/ag-grid/components/ButtonCellRenderer";

export const useTemperatureTableData = () => {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			headerName: "Temperatures",
			type: ["centerAligned"],
			children: [
				{
					field: "Row",
					headerName: "#",
					flex: 1,
					type: ["numericColumn", "numericWidth"],
				},
				{
					field: "Time",
					headerName: "Time",
					minWidth: 100,
					maxWidth: 100,
					valueGetter: (params) =>
						dxtToLocalServerTime(`${params.data.Time}`, "HH:mm") || "",
				},
				{
					field: "Temperature",
					headerName: "Temp.",
					minWidth: 100,
					maxWidth: 100,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: { numericFormat: "0.00" },
					type: ["numericColumn", "numericWidth"],
				},
				{
					field: "SuperHeat",
					headerName: "SH",
					minWidth: 100,
					maxWidth: 100,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: { numericFormat: "0.00" },
					type: ["numericColumn", "numericWidth"],
				},
			],
		},
	];

	return { columns };
};

export const useProductTableData = () => {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			field: "RowNumber",
			headerName: "",
			flex: 1,
			type: ["numericColumn", "numericWidth"],
		},
		{
			field: "Pieces",
			headerName: "Count",
			minWidth: 100,
			maxWidth: 100,
			type: ["numericColumn", "numericWidth"],
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0" },
		},
		{
			field: "Section",
			headerName: "Section.",
			minWidth: 100,
			maxWidth: 100,
			type: ["numericColumn", "numericWidth"],
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0" },
		},
		{
			field: "Length",
			headerName: "Length",
			minWidth: 100,
			maxWidth: 100,
			type: ["numericColumn", "numericWidth"],
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0" },
		},
	];

	return { columns };
};
export const useScrapTableData = () => {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			field: "RowNumber",
			headerName: "",
			minWidth: 80,
			maxWidth: 80,
			type: ["numericColumn", "numericWidth"],
		},
		{
			field: "Pieces",
			headerName: "Count",
			minWidth: 100,
			maxWidth: 100,
			type: ["numericColumn", "numericWidth"],
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0" },
		},
		{
			field: "Length",
			headerName: "Length",
			minWidth: 100,
			maxWidth: 100,
			type: ["numericColumn", "numericWidth"],
			cellRenderer: NumericCellRenderer,
			cellRendererParams: { numericFormat: "0" },
		},
		{
			field: "ScrapReason",
			headerName: "Reason",
			flex: 1,
		},
	];

	return { columns };
};
