import { Backdrop, CircularProgress } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import {
	DateControl,
	SingleDropDownControl,
} from "../../../controls/InputControls";
import { DefaultPanel, LongFilterPanel } from "../../../controls/PanelControls";
import { ReportColumn, ReportTable } from "../../../controls/reportTable";
import { useCasterProductionContext } from "../context/CasterProductionContext";
import {
	GetProcessCatalogs,
	exportCasterProductions,
	getCasterProductions,
} from "../repositories/ProductionRepository";

type Props = {};
export const CasterProductionPage = (props: Props) => {
	const { state, actions } = useCasterProductionContext();
	//const [loading, setLoading]   = React.useState(false);

	const [reportColumns, setReportColumns] = React.useState<ReportColumn[]>([]);

	const [dataSource, setDataSource] = React.useState<any[]>([]);
	const searchCasterProductions = async () => {
		actions.setShowLoading(true);
		const resp = await getCasterProductions(state.casterProductionFilter);
		actions.setShowLoading(false);
		if (resp.ok) {
			//   setLoading(false);
			const temp = resp.data === null ? [] : resp.data.rows || [];

			temp.forEach((row: any, index: number) => {
				row.id = index;
			});
			setDataSource(temp);
			setReportColumns(resp.data === null ? [] : resp.data.columns || []);
			actions.setCasterProductions(dataSource);
		} else {
			// setLoading(false);
			console.log("something happened");
		}
	};
	const exportExcel = async () => {
		await exportCasterProductions(state.casterProductionFilter);
	};
	useEffect(() => {
		(async () => {
			const resp = await GetProcessCatalogs();
			if (resp.ok) {
				actions.setBilletSizes(resp.data.tables[2].rows);
				actions.setGrades([]); // TODO , change getcatalogs to get  grades
				await searchCasterProductions();
			} else {
				console.log("something happened");
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<>
			<Backdrop open={state.showLoading} style={{ zIndex: 1000 }}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<DefaultPanel title={"Caster Production"}>
				<LongFilterPanel
					showButtons={true}
					onClickReset={() => {}}
					OnClickGo={async () => {
						await searchCasterProductions();
					}}
					onClickExport={exportExcel}
					showExport={true}
				>
					<>
						<div className="col-md-2 ">
							<DateControl
								value={state.casterProductionFilter.startDate}
								onChange={(date: Date) => {
									actions.setCasterProductionFilters({
										...state.casterProductionFilter,
										startDate: date,
									});
								}}
								title={"Start"}
								styleGeneral={{ style: "flex" }}
							/>
						</div>
						<div className="col-md-2">
							<DateControl
								value={state.casterProductionFilter.endDate}
								onChange={(date: Date) => {
									actions.setCasterProductionFilters({
										...state.casterProductionFilter,
										endDate: date,
									});
								}}
								title="End"
								styleGeneral={{ style: "flex" }}
							/>
						</div>
						<div className="col-md-3">
							<SingleDropDownControl
								title="Billet Size"
								dataSource={state.billetSizes}
								selectedValue={state.casterProductionFilter.billetSizeId}
								onChange={(value: number) => {
									actions.setCasterProductionFilters({
										...state.casterProductionFilter,
										billetSizeId: value,
									});
								}}
								styleList={{ maxWidth: "60%" }}
								idValue={"MoldSizeId"}
								nameValue={"MoldSizeName"}
								hasChoose={true}
							/>
						</div>
						<div className="col-md-3">
							<SingleDropDownControl
								title="Grade"
								dataSource={state.grades}
								selectedValue={state.casterProductionFilter.gradeId}
								onChange={(value: number) => {
									actions.setCasterProductionFilters({
										...state.casterProductionFilter,
										gradeId: value,
									});
								}}
								styleList={{ maxWidth: "60%" }}
								idValue={"GradeId"}
								nameValue={"GradeName"}
								hasChoose={true}
							/>
						</div>
					</>
				</LongFilterPanel>
				<div className="row" style={{ overflow: "auto" }}>
					<ReportTable
						name={"tblProductions"}
						columns={reportColumns}
						rows={dataSource}
						showPaging={true}
						autoPaging={true}
					/>
				</div>
			</DefaultPanel>
		</>
	);
};
