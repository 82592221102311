import { ContextMenuMESFProvider } from "@dexteel/mesf-core";
import { CatalogMaterialProvider } from "./CatalogMaterialContext";
import { CatalogMaterialPage } from "./CatalogMaterialPage";

type Props = {};
const CatalogMaterial = (props: Props) => {
	return (
		<ContextMenuMESFProvider>
			<CatalogMaterialProvider>
				<CatalogMaterialPage />
			</CatalogMaterialProvider>
		</ContextMenuMESFProvider>
	);
};
export default CatalogMaterial;
