import { Asset } from "@/controls/ganttAsset";
import { createSlice } from "@reduxjs/toolkit";
import { CasterChemistry } from "../caster/models/CasterChemistry";
import { CasterProduction } from "../caster/models/CasterProduction";
import { ShiftInfo } from "../caster/models/Catalogs";
import {
	Process,
	ProcessEvent,
	ProcessGanttChart,
} from "../caster/models/ProcessGanttChart";
import { ProcessByShift } from "../eaf/eaf-processes/models/ProcessByShift";

const CasterDashboardInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingProductionGrid: boolean;
	isLoadingChemistryGrid: boolean;
	errorMessage: string;
	notificationMessage: string;
	variable: string | null;
	shiftInfo: ShiftInfo;
	casterProductions: CasterProduction[];
	selectedProcess: Process | null;
	selectedAsset: Asset | null;

	eafProcesses: ProcessByShift[];
	casterChemistries: CasterChemistry[];
	processGanttChart: ProcessGanttChart[];
	ganttData: {
		Processes: Process[];
		ProcessesEvents: ProcessEvent[];
	};
	showLoading: boolean;
} = {
	variable: null,
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingProductionGrid: false,
	isLoadingChemistryGrid: false,
	errorMessage: "",
	notificationMessage: "",
	showLoading: false,
	shiftInfo: {
		CurrentShiftId: 0,
		CurrentProductionDate: new Date(),
		CurrentProductionShift: 0,
		CurrentShift: "",
		CurrentCrew: "",
		CurrentStart: null,
		CurrentEnd: null,
		PreviousShiftId: 0,
		PreviousProductionDate: null,
		PreviousProductionShift: 0,
		PreviousShift: "",
		PreviousCrew: "",
		PreviousStart: null,
		PreviousEnd: null,
		NextShiftId: 0,
		NextProductionDate: null,
		NextProductionShift: 0,
		NextShift: "",
		NextCrew: "",
		NextStart: null,
		NextEnd: null,
	},
	casterProductions: [],
	eafProcesses: [],
	processGanttChart: [],
	casterChemistries: [],
	selectedProcess: null,
	selectedAsset: null,
	ganttData: { Processes: [], ProcessesEvents: [] },
};

export const CasterDashboardReducer = createSlice({
	name: "__",
	initialState: CasterDashboardInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingProductionGrid(state, { payload }) {
			state.isLoadingProductionGrid = payload;
		},
		setIsLoadingChemistryGrid(state, { payload }) {
			state.isLoadingChemistryGrid = payload;
		},
		setErrorMessage(state, { payload }) {
			state.errorMessage = payload;
		},
		setNotificationMessage(state, { payload }) {
			state.notificationMessage = payload;
		},
		setVariable(state, { payload }) {
			state.variable = payload;
		},
		setShiftInfo(state, { payload }) {
			state.shiftInfo = payload;
		},
		setProcessGanttChart(state, { payload }) {
			state.processGanttChart = payload;
		},
		setShowLoading(state, { payload }) {
			state.showLoading = payload;
		},
		setEAFProcesses(state, { payload }) {
			state.eafProcesses = payload;
		},
		setCasterProductions(state, { payload }) {
			state.casterProductions = payload;
		},
		setCasterChemistries(state, { payload }) {
			state.casterChemistries = payload;
		},
		setSelectedProcess(state, { payload }) {
			state.selectedProcess = payload;
		},
		setSelectedAsset(state, { payload }) {
			state.selectedAsset = payload;
		},
		setGanttProcesses(state, { payload }) {
			state.ganttData.Processes = payload;
		},
		setGanttProcessEvents(state, { payload }) {
			state.ganttData.ProcessesEvents = payload;
		},
	},
});
