import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { getCatalogMaterials } from "../../repositories/CatalogMaterialRepository";
import { useCatalogMaterialContext } from "./CatalogMaterialContext";
import { TableMaterial } from "./components/TableMaterial";

type Props = {};
export const CatalogMaterialPage = (props: Props) => {
	const { state, actions } = useCatalogMaterialContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");

	const getCatalogMaterialFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogMaterials();
		if (resp.ok) {
			actions.setMaterials(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getCatalogMaterialFromAPI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (state.materials) {
		return (
			<Grid container justifyContent="center" alignItems="center">
				<Grid item md={12} xs={12} style={{ margin: 0 }}>
					<Paper
						elevation={1}
						style={{
							width: "100%",
							padding: "10px 0 20px",
							borderRadius: "0.75rem",
							height: "auto",
						}}
					>
						<TableMaterial
							isLoading={isLoading}
							getCatalogMaterialFromAPI={getCatalogMaterialFromAPI}
						/>
					</Paper>
				</Grid>
				<ErrorModal error={error} onHide={() => setError("")} />
			</Grid>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<LazyLoading />
		</div>
	);
};
