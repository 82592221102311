import React from "react";
import { Asset } from "../../types/public-types";
import styles from "./asset-list-table.module.css";

export const AssetListTableDefault: React.FC<{
	rowHeight: number;
	rowWidth: number;
	fontFamily: string;
	fontSize: string;
	assets: Asset[];
	selectedAssetId: string;
	setSelectedAsset: (assetId: string) => void;
}> = ({ rowHeight, rowWidth, fontFamily, fontSize, assets }) => {
	const headerX: number = 0;
	const cellPadding: number = 0.1;
	return (
		<g>
			<rect
				x={headerX}
				y={0}
				width={rowWidth}
				height={rowHeight * assets.length}
				className={styles.graphicWrapper}
			/>
			{assets.map((t, index) => {
				return (
					<g key={`${t.id}g`}>
						<rect
							key={`${t.id}row`}
							x={headerX}
							y={index * rowHeight}
							width={rowWidth}
							height={rowHeight}
							className={
								index % 2 === 0 ? styles.graphicRow : styles.graphicRowAlt
							}
						></rect>
						<rect
							key={`${t.id}cell`}
							x={headerX + cellPadding * rowWidth}
							y={index * rowHeight + cellPadding * rowHeight}
							width={rowWidth * (1 - cellPadding * 2)}
							height={rowHeight * (1 - cellPadding * 2)}
							className={styles.graphicCell}
							style={{ fill: t.color }}
						></rect>
						<text
							key={`${t.id}text`}
							x={headerX + rowWidth * 0.5}
							y={index * rowHeight + rowHeight * 0.5}
							className={styles.graphicLabel}
							style={{
								fontFamily: fontFamily,
								fontSize: 20,
								fill: "black",
							}}
						>
							{t.name}
						</text>
					</g>
				);
			})}
		</g>
	);
};
