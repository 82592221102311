import { ColDef } from "ag-grid-enterprise";
import { CatalogResultSource } from "../../../models/CatalogResultSource";
import { useCatalogResultSourceContext } from "../CatalogResultSourceContext";

type Props = {};
export const useTableData = ({}: Props) => {
	const { state } = useCatalogResultSourceContext();
	const rows: CatalogResultSource[] =
		state.resultSources?.map((resultSource) => ({
			...resultSource,
		})) || [];

	const columns: ColDef[] = [
		{ field: "ResultSourceName", headerName: "Name", minWidth: 20, flex: 1 },
		{
			field: "IsActive",
			headerName: "Enabled",
			type: ["nonSorteable", "nonFilterable", "centerAligned", "IsActive"],
			maxWidth: 100,
		},
		{
			field: "actions",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "editButton"],
			maxWidth: 50,
		},
	];

	return { rows, columns };
};
