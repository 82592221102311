import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../../controls/LazyLoading";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import {
	CATALOG_MOLD_SIZE_INITIAL_VALUES,
	CatalogMoldSize,
} from "../../../../models/catalogs/CatalogMoldSize";
import {
	deleteCatalogMoldSize,
	getCatalogMoldSize,
} from "../../../../repositories/CatalogMoldSizeRepository";
import { useModalStyles } from "../../../../styles/modalStyles";
type Props = {
	moldSizeId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};
export const DeleteMoldSize = ({ moldSizeId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [moldSizeSelected, setMoldSizeSelected] = useState<any>();
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<CatalogMoldSize>({
		defaultValues: CATALOG_MOLD_SIZE_INITIAL_VALUES,
	});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<CatalogMoldSize> = async () => {
		setIsSubmitLoading(true);
		const deleteMoldSizeResp = await deleteCatalogMoldSize(moldSizeId);
		if (deleteMoldSizeResp.ok) {
			onHide(true);
			setOpen(true);
			setIsSubmitLoading(false);
		} else {
			setError(deleteMoldSizeResp.message);
			setIsSubmitLoading(false);
		}
	};
	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const response = await getCatalogMoldSize(moldSizeId);
				if (response.ok) {
					const moldSize = response.data.tables[0].rows[0];
					if (moldSize) {
						setValue("MoldSizeId", moldSize.MoldSizeId);
						setValue("MoldSizeName", moldSize.MoldSizeName);
						setValue("MinSpeedLimit", moldSize.MinSpeedLimit);
						setValue("LbsPerFoot", moldSize.LbsPerFoot);
						setValue("Section", moldSize.Section);
						setValue("Enabled", moldSize.IsActive);
						setIsLoading(false);
					}
				} else {
					setError(response.message);
					return;
				}
			})();
		}
	}, [show]);
	return (
		<>
			<MesfModal
				title="DELETE MOLD SIZE"
				open={show}
				handleClose={() => onHide(false)}
				id="delete-mold-size-modal"
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						)}
						{!isLoading && (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField
											{...register("MoldSizeName")}
											label="Name"
											variant="outlined"
											error={!!errors.MoldSizeName}
											fullWidth
											value={moldSizeSelected?.MoldSizeName}
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											{...register("MinSpeedLimit")}
											label="Min Speed"
											variant="outlined"
											error={!!errors.MinSpeedLimit}
											type="number"
											fullWidth
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											{...register("LbsPerFoot")}
											label="Lbs Per Foot"
											variant="outlined"
											error={!!errors.LbsPerFoot}
											type="number"
											fullWidth
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											{...register("Section")}
											label="Section"
											variant="outlined"
											error={!!errors.Section}
											type="number"
											fullWidth
											margin="dense"
											autoComplete="off"
											disabled
										/>
									</Grid>
								</Grid>
							</>
						)}
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<FooterModalButtons
							isSubmitLoading={isSubmitLoading}
							onHide={onHide}
							buttonColor="secondary"
							buttonLabel="Delete"
						/>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The mold size was deleted successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Deleting Mold Size"
			/>
		</>
	);
};
