import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogSectionReducer } from "./CatalogSectionReducer";

const CatalogSectionContext = createContext({
	state: CatalogSectionReducer.getInitialState(),
	actions: CatalogSectionReducer.actions,
});

export const useCatalogSectionContext = () => useContext(CatalogSectionContext);

export const CatalogSectionProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogSectionReducer.getInitialState(),
		reducers: CatalogSectionReducer.caseReducers,
	});
	return (
		<CatalogSectionContext.Provider value={{ state, actions }}>
			{children}
		</CatalogSectionContext.Provider>
	);
};
