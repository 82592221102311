import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogGrade } from "../models/CatalogGrade";

export const getCatalogGrades = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[STEEL].[GetCatGrades]", []);
};
export const upsertCatalogGrade = async (grade: CatalogGrade) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: grade.GradeId });
	parameters.push({ name: "Name", value: grade.GradeName });
	parameters.push({ name: "Enabled", value: grade.IsActive });

	return await apiService.callV2("[STEEL].[UpsertCatGrade]", parameters);
};
export const getCatalogGrade = async (GradeId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "GradeId", value: GradeId });

	return await apiService.callV2("[STEEL].[GetCatGrade]", parameters);
};
