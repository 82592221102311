import { createSlice } from "@reduxjs/toolkit";
import { DelaysForPareto } from "../models/DelayForPareto";

const DelaysParetoInitialState: {
	delaysListForPareto: DelaysForPareto[];
	shiftId: number | null | false;
} = {
	delaysListForPareto: [],
	shiftId: false,
};

export const DelaysParetoReducer = createSlice({
	name: "__",
	initialState: DelaysParetoInitialState,
	reducers: {
		setDelaysListForPareto(state, { payload }) {
			state.delaysListForPareto = payload;
		},
		setShiftId(state, { payload }) {
			state.shiftId = payload;
		},
	},
});
