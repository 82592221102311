import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
	processNavigator: {
		"& .MuiInputAdornment-root": {
			padding: "8px 0px",
			margin: "0px",
			width: "20px",
		},
	},

	linkedHeat: {
		"& .MuiOutlinedInput-input": {
			paddingLeft: "11px",
			width: "10px",
			fontSize: "1.5rem !important",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			minHeight: "40px",
			height: "45px",
		},
	},
	unlinkedHeat: {
		"& .MuiOutlinedInput-input": {
			paddingLeft: "11px",
			width: "10px",
			fontSize: "1rem !important",
		},
	},

	inputLabel: {
		fontSize: "0.8rem",
		"&.MuiInputLabel-shrink": {
			fontSize: "0.8rem",
		},
	},
	checkbox: {
		padding: 0,
		margin: 0,
	},
	inputAdornment: {
		margin: 0,
	},
	input: {
		paddingRight: theme.spacing(1),
	},
}));
