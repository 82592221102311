import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MemoryIcon from "@material-ui/icons/Memory";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { isNil } from "lodash-es";
import { useNavigate } from "react-router-dom";
import { HeatDataEntryType } from "../../models/Heats/HeatDataEntryType";

type Props = {
	setHeatIdForEdit: Function;
	setModalEditHeat: Function;
	setHeatIdForDelete: Function;
	setModalDeleteHeat: Function;
	setModalCreateHeat: Function;
};
export const useHeatsOptionsFunctions = ({
	setHeatIdForEdit,
	setModalEditHeat,
	setHeatIdForDelete,
	setModalDeleteHeat,
	setModalCreateHeat,
}: Props) => {
	const navigate = useNavigate();

	const goToDataEntry = (HeatNameLink: string | null) => {
		if (!isNil(HeatNameLink)) {
			navigate(HeatNameLink);
		}
	};
	const goToEAFDataEntry = (HeatEAFDELink: string | null) => {
		if (!isNil(HeatEAFDELink)) navigate(HeatEAFDELink);
	};
	const goToHeatReport = (HeatLABLink: string | null) => {
		if (!isNil(HeatLABLink)) navigate(HeatLABLink);
	};
	const getMenuOptions = (data: HeatDataEntryType) => {
		let options: MenuOptionType[] = [];

		if (data.HeatEAFDELink) {
			options.push({
				name: "See in EAF",
				key: "go_to_eaf",
				onClick: () => goToEAFDataEntry(data.HeatEAFDELink),
				icon: (
					<i
						style={{ fontSize: "1.5em", marginLeft: "-3px" }}
						className="material-icons"
					>
						thermostat
					</i>
				),
			});
		}
		if (data.HeatNameLink) {
			options.push({
				name: "See in Caster",
				key: "go_to_caster_data_entry",
				style: { fontWeight: "bold" },
				onClick: () => goToDataEntry(data.HeatNameLink),
				icon: <MemoryIcon />,
			});
		}
		if (data.HeatLABLink) {
			options.push({
				name: "See in Lab",
				key: "go_to_heat_report",
				onClick: () => goToHeatReport(data.HeatLABLink),
				icon: (
					<span
						className="material-icons"
						style={{ fontSize: "1.5em", marginLeft: "-3px" }}
					>
						science
					</span>
				),
			});
		}

		if (data.HeatId) {
			options = options.concat([
				{
					name: "Delete Heat",
					key: "delete_heat",
					onClick: () => {
						setHeatIdForDelete(data.HeatId);
						setModalDeleteHeat(true);
						// goToHeats(data.HeatId)
					},
					icon: <DeleteIcon />,
					// icon:   data.IsActive ? <DeleteIcon  /> : <AddBoxIcon  />
				},
				{
					name: "Edit Heat",
					key: "edit_heat",
					onClick: () => {
						setHeatIdForEdit(data.HeatId);
						setModalEditHeat(true);
					},
					icon: <EditIcon />,
				},
				{
					name: "New Heat",
					key: "new_heat",
					onClick: () => {
						setModalCreateHeat(true);
					},
					icon: <PlaylistAddIcon />,
				},
			]);
		}
		return options;
	};

	return {
		getMenuOptions,
		goToDataEntry,
		goToEAFDataEntry,
		goToHeatReport,
	};
};
