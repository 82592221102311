import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { ConfirmModal } from "../../../../../../controls/modals/ConfirmModal";
import {
	CATALOG_MOLD_SIZE_INITIAL_VALUES,
	CatalogMoldSize,
} from "../../../../models/catalogs/CatalogMoldSize";
import { upsertCatalogMoldSize } from "../../../../repositories/CatalogMoldSizeRepository";
import { useModalStyles } from "../../../../styles/modalStyles";
import { validateNoSpecialChars } from "../../../validates";
import { useCatalogMoldSizeContext } from "../../context/CatalogMoldSizeContext";

type Props = {
	show: boolean;
	onHide: (moldSizeCreated: boolean) => void;
};

export const CreateMoldSize = ({ show, onHide }: Props) => {
	const { state } = useCatalogMoldSizeContext();
	const classes = useModalStyles();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [existingMoldSize, setExistingMoldSize] = useState("");
	const {
		register,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CatalogMoldSize>({
		defaultValues: CATALOG_MOLD_SIZE_INITIAL_VALUES,
	});

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit: SubmitHandler<CatalogMoldSize> = async (data: any) => {
		setIsSubmitLoading(true);
		const moldSizes: CatalogMoldSize[] = state.moldSizes || [];
		if (
			moldSizes.some((moldSize) => moldSize.MoldSizeName === data.MoldSizeName)
		) {
			setExistingMoldSize(data.MoldSizeName);
			setConfirmOpen(true);
		} else {
			await performUpsert(data);
		}
	};

	const performUpsert = async (data: CatalogMoldSize) => {
		const createMoldSizeResp = await upsertCatalogMoldSize(data);
		if (createMoldSizeResp.ok) {
			onHide(true);
			setOpen(true);
		} else {
			setError(createMoldSizeResp.message);
			onHide(false);
		}
		setIsSubmitLoading(false);
	};

	const onConfirmModalHandler = async (proceed: boolean) => {
		setConfirmOpen(false);
		if (proceed) {
			await performUpsert(getValues());
		}
	};

	useEffect(() => {
		if (show) {
			reset();
			setConfirmOpen(false);
		}
	}, [show]);

	return (
		<>
			<ConfirmModal
				show={confirmOpen}
				onHide={onConfirmModalHandler}
				existingName={existingMoldSize}
				label="Mold Size"
			/>
			<MesfModal
				title="NEW MOLD SIZE"
				open={show}
				handleClose={() => onHide(false)}
				id="create-mold-size-modal"
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content dividers style={{ padding: "15px 30px" }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={12}>
								<TextField
									{...register("MoldSizeName", {
										required: "Name is required",
										validate: validateNoSpecialChars,
									})}
									label="Name"
									variant="outlined"
									error={!!errors.MoldSizeName}
									fullWidth
									margin="dense"
									autoComplete="off"
								/>
								{errors.MoldSizeName && (
									<span className={classes.errorLabel}>
										{errors.MoldSizeName.message}
									</span>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									{...register("MinSpeedLimit")}
									label="Min Speed"
									variant="outlined"
									error={!!errors.MinSpeedLimit}
									type="number"
									fullWidth
									margin="dense"
									autoComplete="off"
									inputProps={{ min: 0 }}
								/>
								{errors.MinSpeedLimit && (
									<span className={classes.errorLabel}>
										{errors.MinSpeedLimit.message}
									</span>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									{...register("LbsPerFoot")}
									label="Lbs Per Foot"
									variant="outlined"
									error={!!errors.LbsPerFoot}
									type="number"
									fullWidth
									margin="dense"
									autoComplete="off"
									inputProps={{ min: 0 }}
								/>
								{errors.LbsPerFoot && (
									<span className={classes.errorLabel}>
										{errors.LbsPerFoot.message}
									</span>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									{...register("Section")}
									label="Section"
									variant="outlined"
									error={!!errors.Section}
									type="number"
									fullWidth
									margin="dense"
									autoComplete="off"
									inputProps={{ min: 0 }}
								/>
								{errors.Section && (
									<span className={classes.errorLabel}>
										{errors.Section.message}
									</span>
								)}
							</Grid>
						</Grid>
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<FooterModalButtons
							isSubmitLoading={isSubmitLoading}
							onHide={onHide}
						/>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The mold size was created successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Mold Size"
			/>
		</>
	);
};
