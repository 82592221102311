import { ErrorModal } from "@dexteel/mesf-core";
import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AcquisitionStatsTable } from "./components/AcquisitionStatsTable";
import { useAcquisitionStatsContext } from "./context/AcquisitionStatsContext";
import { getHeatAcquisitionStats } from "./repositories/AcquisitionRepository";

export const AcquisitionPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const { state, actions } = useAcquisitionStatsContext();

	const getHeatAcquisitionStatsFromAPI = async () => {
		setIsLoading(true);
		const resp = await getHeatAcquisitionStats();

		if (resp.ok) actions.setAcquisitionStats(resp.data.tables[0]);
		else setError(resp.message);
		setIsLoading(false);
	};
	useEffect(() => {
		getHeatAcquisitionStatsFromAPI();
	}, []);

	return (
		<>
			<Paper
				elevation={1}
				style={{
					width: "100%",
					padding: "15px 20px 10px",
					borderRadius: "0.75rem",
					minHeight: "93vh",
				}}
			>
				<AcquisitionStatsTable isLoading={isLoading} />
			</Paper>
			<ErrorModal
				error={error}
				onHide={() => {
					setError("");
				}}
			/>
		</>
	);
};
