import { ErrorModal } from "@dexteel/mesf-core";
import { TextField } from "@material-ui/core";
import {
	Autocomplete,
	AutocompleteChangeDetails,
	AutocompleteChangeReason,
} from "@material-ui/lab";
import { useEffect, useState } from "react";
import { ChangeEvent } from "react";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { getGrades } from "../../../heats/repositories/heatsRepository";

interface Grade {
	GradeId: number | null;
	GradeName: string;
}

type Props = {
	onChange: (value: number) => void;
	value: number | null;
	label?: boolean;
	initialValue?: Grade;
	className?: string;
};

export const GradeSelector = ({
	onChange,
	value,
	label,
	initialValue,
	className,
}: Props) => {
	const [grades, setGrades] = useState<Grade[]>([]);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const optionsWithAll: Grade[] = [
		initialValue || { GradeId: 0, GradeName: "ALL" },
		...grades,
	];

	const selectedGrade = optionsWithAll.find((grade) => grade.GradeId === value);

	const handleChange = (
		event: ChangeEvent<{}>,
		value: Grade | null,
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<Grade> | undefined,
	) => {
		onChange(value?.GradeId || 0);
	};

	const loadData = async () => {
		setIsLoading(true);
		try {
			const responseGrades = await getGrades();
			if (responseGrades.ok) {
				setGrades(responseGrades.data.tables[0].rows);
			} else {
				setError(responseGrades.message);
			}
			setIsLoading(false);
		} catch (e: any) {
			setError(e);
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	if (isLoading) return <LazyLoading />;

	return (
		<>
			<Autocomplete
				id=""
				value={selectedGrade}
				onChange={handleChange}
				options={optionsWithAll}
				getOptionLabel={(grade) => grade.GradeName}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label ? "Grade" : ""}
						variant="outlined"
						className={className}
					/>
				)}
				style={{ minWidth: 200 }}
				size="small"
			/>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
