import { useEffect, useState } from "react";
import { upsertStrandData } from "../../repositories/ProductionRepository";
import { useCastersContext } from "../CastersContext";

const useSaveStrand = () => {
	const [error, setError] = useState<string | null>();
	const [sucess, setSucess] = useState<string | null>(null);
	const { state, actions } = useCastersContext();

	const save: (index: number, field: string, value: any) => Promise<void> =
		async (index, field, value) => {
			if (
				state.processData === null ||
				state.processData.ProcessId === null ||
				value === null
			)
				return;
			if (
				state.strands === null ||
				state.strands[index] === null ||
				state.strands[index].Strand === null
			)
				return;

			actions.setIsLoadingBackground(true);
			let resp;
			resp = await upsertStrandData(
				state.processData.ProcessId,
				state.strands[index].Strand,
				field,
				value,
			);
			actions.setIsLoadingBackground(false);
			if (resp?.ok) setSucess(field + " was saved successfully");
			else setError(resp?.message);
		};
	useEffect(() => {
		if (sucess) {
			actions.setNotificationMessage(sucess);
			setSucess("");
		}
		if (error) {
			actions.setErrorMessage(error);
			setError(null);
		}
	}, [sucess, error]);
	return { save, error, sucess };
};
export { useSaveStrand };
