import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogElement } from "../models/CatalogElement";

export const getCatalogElements = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[LAB].[GetCatElements]", []);
};
export const upsertCatalogElement = async (element: CatalogElement) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: element.ElementId });
	parameters.push({ name: "Name", value: element.ElementName });
	parameters.push({ name: "Decimals", value: element.Decimals });
	parameters.push({ name: "Order", value: element.Order });
	parameters.push({ name: "Enabled", value: element.IsActive });

	return await apiService.callV2("[LAB].[UpsertCatElement]", parameters);
};
export const getCatalogElement = async (ElementId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "ElementId", value: ElementId });

	return await apiService.callV2("[LAB].[GetCatElement]", parameters);
};
