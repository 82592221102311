import { isNumber } from "lodash-es";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Process } from "../../../models/eaf.models";
import { NumberTextFieldDE } from "../common/NumberTextFieldDE";

export type NumberFields<T> = {
	[K in keyof T]: T[K] extends number | null ? K : never;
}[keyof T];

export const ProcessDataNumberTextField = ({
	field,
	label,
	decimals = null,
}: {
	field: NumberFields<Process>;
	label?: string;
	decimals?: number | null;
}) => {
	const {
		state: { deProcess },
		actions,
	} = useDataEntryContext();

	const { saveProcessData } = useEAFDataEntryActions();
	const process = deProcess?.process;

	const value =
		process && process[field] !== undefined
			? isNumber(decimals)
				? Number(process[field]?.toFixed(decimals))
				: process[field]
			: null;

	const onTextValueChange = () => {
		saveProcessData({
			processId: process?.ProcessId!,
			field,
			value: process![field],
		});
	};

	return (
		<NumberTextFieldDE
			label={label || field}
			value={value}
			onChange={(value) => {
				actions.setProcess({
					...process,
					[field]: value,
				});
			}}
			onBlur={() => {
				onTextValueChange();
			}}
		/>
	);
};
