import React, { useEffect, useRef } from "react";
import { xCoordinateToDate } from "../../helpers/bar-helper";
import { Calendar, CalendarProps } from "../calendar/calendar";
import { Grid, GridProps } from "../grid/grid";
import {
	AssetGanttContent,
	AssetGanttContentProps,
} from "./asset-gantt-content";
import styles from "./gantt.module.css";

export type AssetGanttProps = {
	gridProps: GridProps;
	calendarProps: CalendarProps;
	barProps: AssetGanttContentProps;
	ganttHeight: number;
	scrollX: number;
	scrollY: number;
};

export const AssetGantt: React.FunctionComponent<AssetGanttProps> = ({
	gridProps,
	calendarProps,
	barProps,
	ganttHeight,
	scrollX,
	scrollY,
}) => {
	const ganttSVGRef = useRef<SVGSVGElement>(null);
	const horizontalContainerRef = useRef<HTMLDivElement>(null);
	const verticalGanttContainerRef = useRef<HTMLDivElement>(null);
	const newBarProps = { ...barProps, svg: ganttSVGRef };

	const getAssetIdFromEvent = (event: React.MouseEvent): string | null => {
		let target = event.target as SVGElement | null;

		while (target) {
			if (target.hasAttribute("data-asset-id")) {
				return target.getAttribute("data-asset-id");
			}
			target = target.parentElement as SVGElement | null;
		}

		return null;
	};

	const viewBoxCalendarProp =
		"0 0 " + gridProps.svgWidth + " " + calendarProps.headerHeight;
	const viewBoxGridProp =
		"0 0 " +
		gridProps.svgWidth +
		" " +
		barProps.rowHeight * barProps.assets.length;
	useEffect(() => {
		if (horizontalContainerRef.current) {
			horizontalContainerRef.current.scrollTop = scrollY;
		}
	}, [scrollY]);

	useEffect(() => {
		if (verticalGanttContainerRef.current) {
			verticalGanttContainerRef.current.scrollLeft = scrollX;
		}
	}, [scrollX]);
	return (
		<div
			className={styles.ganttVerticalContainer}
			ref={verticalGanttContainerRef}
			style={{ width: gridProps.svgWidth }}
			dir="ltr"
			onContextMenu={(e) => {
				e.preventDefault();
				const assetId = getAssetIdFromEvent(e);

				const svg = ganttSVGRef.current;
				const ctm = svg?.getScreenCTM();
				const pt = new DOMPoint(e.clientX, e.clientY);
				const svgPoint = pt.matrixTransform(ctm?.inverse());

				if (assetId) {
					const asset = barProps.assets.find((a) => a.id === assetId);

					if (barProps.onContextMenu) {
						barProps.onContextMenu(e, {
							type: "asset",
							asset: asset,
							timestamp: xCoordinateToDate(
								svgPoint.x,
								calendarProps.dateSetup.dates,
								calendarProps.columnWidth,
							),
						});
					}
				}
			}}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				// width={gridProps.svgWidth}
				// height={calendarProps.headerHeight}
				fontFamily={barProps.fontFamily}
				viewBox={viewBoxCalendarProp}
			>
				<Calendar {...calendarProps} />
			</svg>
			<div
				ref={horizontalContainerRef}
				className={styles.horizontalContainer}
				style={
					ganttHeight
						? { height: ganttHeight, width: "100%" }
						: { width: "100%" }
				}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					// width={gridProps.svgWidth}
					// height={barProps.rowHeight * barProps.assets.length}
					fontFamily={barProps.fontFamily}
					viewBox={viewBoxGridProp}
					ref={ganttSVGRef}
				>
					<Grid {...gridProps} />
					<AssetGanttContent {...newBarProps} />
				</svg>
			</div>
		</div>
	);
};
