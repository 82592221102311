import { Grid, makeStyles } from "@material-ui/core";
import { ReportColumn, ReportTable } from "../../../../controls/reportTable";

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		width: "100%",
		overflowX: "auto",
	},
	tableWrapper: {
		minWidth: "100%",
		maxWidth: "100%",
		"& .report-table": {
			width: "100%",
			tableLayout: "fixed",
		},
	},
	cell: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
}));

interface Props {
	reportColumns: ReportColumn[];
	dataSource: any[];
	style?: string;
	cellClass?: string;
}

export const ChemistryTable = ({
	reportColumns,
	dataSource,
	style,
	cellClass,
}: Props) => {
	const classes = useStyles();

	return (
		<Grid container>
			<Grid item xs={12}>
				<div className={classes.tableWrapper}>
					<ReportTable
						name={"tblTemperatures"}
						columns={reportColumns}
						rows={dataSource}
						// tableClass={`${style || ''} ${classes.fullWidth}`}
						// cellClass={cellClass}
					/>
				</div>
			</Grid>
		</Grid>
	);
};
