import { Typography, makeStyles } from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import { NodeModel, RenderParams } from "@minoru/react-dnd-treeview";
import React, { useCallback, useEffect } from "react";
import { FileProperties } from "../../models/FileProperties";
import { useConfigurationAssetContext } from "../context/ConfigurationAssetContext";
import { useConfigurationDelayCodeContext } from "../context/ConfigurationDelayCodeContext";
import { TypeIcon } from "./DelayConfiguration/TypeIcon";

const useStyles = makeStyles((theme) => ({
	root: {
		alignItems: "center",
		display: "flex",
		height: "32px",
	},
	customNodeRoot: {
		listStyleType: "none",
		margin: 0,
		padding: 0,
	},
	arrow: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		height: "24px",
		justifyContent: "center",
		width: "24px",
		transition: "transform linear 0.1s",
		transform: "rotate(0deg)",
	},

	"arrow > div": {
		alignItems: "center",
		display: "flex",
	},

	"arrow.is-open": {
		transform: "rotate(90deg)",
	},

	filetype: {
		display: "flex",
	},

	label: {
		paddingInlineStart: "8px",
	},

	selected: {
		backgroundColor: "#e8f0fe",
	},

	dragging: {
		opacity: 0.5,
	},
}));

const customStyles = `
  ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

type Props = RenderParams & {
	node: NodeModel<FileProperties>;
	setContextMenuOver: (
		data: NodeModel<FileProperties>,
		e: React.MouseEvent<HTMLElement>,
	) => void;
	onDoubleClick: (
		data: NodeModel<FileProperties>,
		e: React.MouseEvent<HTMLElement>,
	) => void;
	isSelected: boolean;
	isDragging: boolean;
	testIdPrefix?: string;
	onClick: (e: React.MouseEvent, node: NodeModel<FileProperties>) => void;
	"data-node-id"?: string;
};

export const CustomNode: React.FC<Props> = ({
	setContextMenuOver,
	onDoubleClick,
	testIdPrefix = "",
	isSelected,
	...props
}) => {
	const {
		state: { openDelays },
		actions: { setOpenDelays },
	} = useConfigurationDelayCodeContext();

	const { id, droppable, data } = props.node;
	const indent = props.depth * 24;

	const classes = useStyles();
	const nodeClasses = `${classes.customNodeRoot} ${classes.root} ${
		isSelected ? classes.selected : ""
	}`;

	const handleClick = (e: React.MouseEvent) => {
		props.onClick(e, props.node);
	};

	const handleToggle = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (openDelays[id]) {
			const newOpenAssets = { ...openDelays };
			delete newOpenAssets[id as number];
			setOpenDelays(newOpenAssets);
		} else {
			setOpenDelays({ ...openDelays, [id]: id });
		}
		props.onToggle();
	};

	const handleContextMenu = useCallback(
		(ev) => {
			ev.stopPropagation();
			ev.preventDefault();
			setContextMenuOver(props.node, ev);
		},
		[id, setContextMenuOver],
	);

	const handleViewAssetDialog = useCallback(
		(e) => {
			e.stopPropagation();
			e.preventDefault();
			onDoubleClick(props.node, e);
			setContextMenuOver(
				{
					id: "",
					parent: "",
					text: "",
					droppable: false,
				},
				e,
			);
		},
		[id, onDoubleClick],
	);

	useEffect(() => {
		setOpenDelays({ ...openDelays, [id]: id });
		props.containerRef.current?.addEventListener(
			"contextmenu",
			handleContextMenu,
		);
		props.containerRef.current?.addEventListener(
			"dblclick",
			handleViewAssetDialog,
		);

		return () => {
			props.containerRef.current?.removeEventListener(
				"contextmenu",
				handleContextMenu,
			);
			props.containerRef.current?.removeEventListener(
				"dblclick",
				handleViewAssetDialog,
			);
		};
	}, []);

	return (
		<div
			className={nodeClasses}
			style={{ paddingInlineStart: indent }}
			data-node-id={id}
			onClick={handleClick}
			title={`${props.node.id} - ${props.node.text}`}
		>
			<style>{customStyles}</style>
			{props.node.droppable ? (
				<div
					className={`${classes.arrow} ${
						props.isOpen ? classes["arrow.is-open"] : ""
					}`}
				>
					<div onClick={handleToggle}>
						<ArrowRight data-testid={`arrow-right-icon-${id}`} />
					</div>
				</div>
			) : null}
			<div className={classes.filetype}>
				<TypeIcon droppable={droppable as boolean} fileType={data?.fileType} />
			</div>
			<div className={classes.label}>
				<Typography variant="body2">{props.node.text}</Typography>
			</div>
		</div>
	);
};
