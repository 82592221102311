import { createSlice } from "@reduxjs/toolkit";
import { AssetAPI } from "../../models/AssetAPI";
import { DelayCodeAPI } from "../../models/DelayCodeAPI";
import { DATA_DELAY_INITIAL_VALUES } from "../../models/InitialValueDataDelay";
import { AssetParameters } from "../../models/TreeParameter";

const ConfigurationDelayCodeInitialState: {
	openDelays: { [delayId: number | string]: number | string };
	allDelayNodes: AssetParameters[];
	dataToCreateDelay: DelayCodeAPI;
	allNodesLoading: boolean;
	delayAreaAsset?: AssetAPI;
	anchorPoint: any;
	menuContextDelayId: number | undefined;
	showContextMenu: boolean;
	showCreateNewContextMenu: boolean;
	delayTreeId: number | null;
	delayTreeName: string | null;
	allDelayCodes: any;
	delayCodes: any;
	delayNodeSelectedInTree: DelayCodeAPI;
} = {
	openDelays: {},
	allDelayNodes: [],
	allNodesLoading: false,
	anchorPoint: {
		x: 0,
		y: 0,
	},
	dataToCreateDelay: DATA_DELAY_INITIAL_VALUES,
	menuContextDelayId: undefined,
	showContextMenu: false,
	showCreateNewContextMenu: false,
	allDelayCodes: {},
	delayCodes: {},
	delayTreeId: 1,
	delayTreeName: null,
	delayNodeSelectedInTree: DATA_DELAY_INITIAL_VALUES,
};

export const ConfigurationDelayCodeReducer = createSlice({
	name: "__",
	initialState: ConfigurationDelayCodeInitialState,
	reducers: {
		setOpenDelays(state, { payload }) {
			state.openDelays = payload;
		},
		setDelayAreaAsset(state, { payload }) {
			state.delayAreaAsset = payload;
		},
		setAllDelayNodes(state, { payload }) {
			state.allDelayNodes = payload;
		},
		seAllNodesLoading(state, { payload }) {
			state.allNodesLoading = payload;
		},
		setAnchorPointX(state, { payload }) {
			state.anchorPoint.x = payload;
		},
		setAnchorPointY(state, { payload }) {
			state.anchorPoint.y = payload;
		},
		setMenuContextDelayId(state, { payload }) {
			state.menuContextDelayId = payload;
		},
		setShowContextMenu(state, { payload }) {
			state.showContextMenu = payload;
		},
		setShowCreateNewContextMenu(state, { payload }) {
			state.showCreateNewContextMenu = payload;
		},
		setDataToCreateDelay(state, { payload }) {
			state.dataToCreateDelay = payload;
		},
		setDelayNameToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.DelayCodeName = payload;
		},
		setDelayParentDelayIdToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.ParentDelayCodeId = payload;
		},
		setDelayTreeId(state, { payload }) {
			state.delayTreeId = payload;
		},
		setDelayTreeName(state, { payload }) {
			state.delayTreeName = payload;
		},
		setDelayCodes(state, { payload }) {
			state.delayCodes = payload;
		},
		setAllDelayCodes(state, { payload }) {
			state.allDelayCodes = payload;
		},
		setDelayNodeSelectedInTree(state, { payload }) {
			state.delayNodeSelectedInTree = payload;
		},
		setDelayParentNameToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.ParentDelayCodeName = payload;
		},
		setDelayCategoryToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.DelayCategoryId = payload;
		},
	},
});
