import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { ConfigurationReducer } from "../reducers/ConfigurationReducer";

const ConfigurationContext = createContext({
	state: ConfigurationReducer.getInitialState(),
	actions: ConfigurationReducer.actions,
});

export const useConfigurationContext = () => useContext(ConfigurationContext);

export const ConfigurationProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: ConfigurationReducer.getInitialState(),
		reducers: ConfigurationReducer.caseReducers,
	});

	return (
		<ConfigurationContext.Provider value={{ state, actions }}>
			{children}
		</ConfigurationContext.Provider>
	);
};
