import { ErrorModal, MesfModal, useShiftNavigator } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	TextField,
} from "@material-ui/core";
import { Autocomplete, Color } from "@material-ui/lab";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useModalStyles } from "../../../styles/modalStyles";
import { useGradeContext } from "../../context/GradesContext";
import { useHeatsByShiftContext } from "../../context/HeatsByShiftContext";
import { useLadleContext } from "../../context/LadlesContext";
import { HeatDataEntryType } from "../../models/Heats/HeatDataEntryType";
import { INITIAL_VALUES } from "../../models/Heats/Initial-values";
import { upsertHeat } from "../../repositories/heatsRepository";

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const CreateHeatModal = ({ show, onHide }: Props) => {
	const classes = useModalStyles();

	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [message, setMessage] = useState("");
	const [messageType, setMessageType] = useState<Color | undefined>(undefined);

	const [openAlertMessage, setOpenAlertMessage] = useState(false);

	const [heatForEdit, setHeatForEdit] = useState<any | null>(null);
	const [error, setError] = useState<string | null>(null);

	const { shiftInfo } = useShiftNavigator();

	const {
		state: { ladles },
	} = useLadleContext();
	const {
		state: { grades },
	} = useGradeContext();

	const {
		control,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<any>({ defaultValues: INITIAL_VALUES });

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit: SubmitHandler<HeatDataEntryType> = async (data) => {
		setIsSubmitLoading(true);
		const currentShiftId = get(shiftInfo, "CurrentShiftId", null);

		if (currentShiftId) {
			const res = await upsertHeat({
				...data,
				ShiftId: currentShiftId,
			});
			if (res.ok) {
				setMessage("The heat was created successfully");
				setMessageType("success");
				onHide(true);
			} else {
				setMessage("Error, please try again");
				setMessageType("error");
				setError(res.message);
			}
		} else {
			setMessage("Error, please try again");
			setMessageType("error");
			setError("Go to valid shift");
		}

		setIsSubmitLoading(false);
		setOpenAlertMessage(true);
	};

	useEffect(() => {
		if (show) {
			reset();
		}
	}, [show]);

	return (
		<>
			<>
				<MesfModal
					open={show}
					handleClose={() => {
						onHide(true);
					}}
					id="editHeat"
					title="Create a heat"
					maxWidth="sm"
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<MesfModal.Content
							className={classes.modalBody}
							style={{ padding: "15px 30px" }}
						>
							{isLoading && (
								<Grid
									container
									justifyContent="center"
									alignItems="center"
									style={{ minHeight: "150px" }}
								>
									<CircularProgress size="5rem" />
								</Grid>
							)}

							{!isLoading && (
								<>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Controller
												name="HeatName"
												rules={{ required: true }}
												control={control}
												render={({ field }) => (
													<TextField
														label="Name"
														value={field.value}
														onChange={field.onChange}
														variant="outlined"
														error={!!errors.HeatName}
														fullWidth
														margin="dense"
													/>
												)}
											/>

											{errors.HeatName?.type === "required" && (
												<span className={classes.errorLabel}>
													Name is required
												</span>
											)}
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="GradeId"
												control={control}
												render={({ field }) => (
													<Autocomplete
														id="clear-on-escape"
														clearOnEscape
														options={grades}
														getOptionLabel={(option) => option.GradeName}
														onChange={(event, newValue) => {
															field.onChange(
																newValue ? newValue.GradeId : null,
															);
														}}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Grade"
																variant="outlined"
																fullWidth
															/>
														)}
														value={
															grades.find(
																(grade) => grade.GradeId === field.value,
															) || null
														}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Controller
												name="SteelWeight"
												control={control}
												render={({ field }) => (
													<TextField
														type={"string"}
														label="Steel Weight"
														value={field.value || ""}
														onChange={field.onChange}
														variant="outlined"
														fullWidth
														margin="dense"
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="LadleId"
												control={control}
												render={({ field }) => (
													<Autocomplete
														options={ladles}
														getOptionLabel={(option) => option.LadleName}
														onChange={(event, newValue) => {
															field.onChange(
																newValue ? newValue.LadleId : null,
															);
														}}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Ladle"
																variant="outlined"
																fullWidth
															/>
														)}
														value={
															ladles.find(
																(ladle) => ladle.LadleId === field.value,
															) || null
														}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="IsExternal"
												control={control}
												render={({ field }) => (
													<FormControlLabel
														className={classes.formControlLabel}
														control={
															(
																<Checkbox
																	defaultChecked={false}
																	checked={field.value}
																	onChange={(e) =>
																		field.onChange(e.target.checked)
																	}
																	color="primary"
																/>
															) as any
														}
														label="External"
													/>
												)}
											/>
										</Grid>
									</Grid>
								</>
							)}
						</MesfModal.Content>
						{!isLoading && (
							<MesfModal.Actions style={{ padding: "2rem" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => {
												onHide(false);
											}}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading ? (
													<CircularProgress size="1rem" />
												) : (
													<></>
												)
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Create
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						)}
					</form>
				</MesfModal>
			</>
			<ErrorModal onHide={() => setError(null)} error={error} />
		</>
	);
};
