import { useCallback, useEffect, useRef, useState } from "react";

interface UseCountdownTimerProps {
	initialSeconds: number;
	onTimeUp?: () => void;
}

interface UseCountdownTimerReturn {
	seconds: number;
	isRunning: boolean;
	pause: () => void;
	resume: () => void;
	restart: () => void;
}

export const useCountdownTimer = ({
	initialSeconds,
	onTimeUp,
}: UseCountdownTimerProps): UseCountdownTimerReturn => {
	const [seconds, setSeconds] = useState(initialSeconds);
	const [isRunning, setIsRunning] = useState(true);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const onTimeUpCalledRef = useRef(false);

	const tick = useCallback(() => {
		setSeconds((prevSeconds) => {
			if (prevSeconds <= 1) {
				if (!onTimeUpCalledRef.current && onTimeUp) {
					onTimeUp();
					onTimeUpCalledRef.current = true;
				}
				return initialSeconds;
			}
			return prevSeconds - 1;
		});
	}, [initialSeconds, onTimeUp]);

	const pause = useCallback(() => {
		setIsRunning(false);
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	}, []);

	const resume = useCallback(() => {
		setIsRunning(true);
		onTimeUpCalledRef.current = false;
	}, []);

	const restart = useCallback(() => {
		setSeconds(initialSeconds);
		onTimeUpCalledRef.current = false;
		if (!isRunning) {
			setIsRunning(true);
		}
	}, [initialSeconds, isRunning]);

	useEffect(() => {
		if (isRunning) {
			intervalRef.current = setInterval(tick, 1000);
		} else if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [isRunning, tick]);

	useEffect(() => {
		onTimeUpCalledRef.current = false;
	}, [seconds]);

	return { seconds, isRunning, pause, resume, restart };
};
