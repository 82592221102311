// import {Grade} from "../models/catalogs.models";
import { ErrorModal } from "@dexteel/mesf-core";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Theme,
	makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDataEntryContext } from "../context/reducer.provider";
import { useEAFDataEntryActions } from "../hooks/useEAFDataEntryActions";
import { Grade } from "../models/catalogs.models";
import { getGrades } from "../repositories/eaf.repositories";
import { useStyles } from "../styles/useDEStyles";

export const GradeSelector = ({
	value,
	onChange,
	disabled,
}: {
	value: number | null;
	onChange: (value: number) => void;
	disabled: boolean;
}) => {
	const {
		state: { deProcess },
	} = useDataEntryContext();
	const { saveHeat } = useEAFDataEntryActions();
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [options, setOptions] = useState<Grade[]>([]);
	const [grade, setGrade] = useState<number>();
	const classes = useStyles();

	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		const selectedValue = event.target.value as number;
		onChange(selectedValue);
		if (selectedValue) {
			saveHeat("GradeId", selectedValue).then(() => {});
		}
		setGrade(selectedValue);
	};

	const loadData = async () => {
		setIsLoading(true);
		const resp = await getGrades();
		setOptions(resp.data!);
		setIsLoading(false);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			loadData().then(() => {});
			return;
		}
	}, []);

	return (
		<>
			<FormControl variant="outlined" fullWidth>
				<InputLabel id="ladleSelector" className={classes.inputLabel}>
					Grade
				</InputLabel>
				<Select
					fullWidth
					disabled={isLoading || disabled}
					labelId="ladleSelector"
					id="ladleSelector"
					value={value || ""}
					onChange={handleChange}
					label="Grade"
				>
					{options.map((option) => (
						<MenuItem key={`grade-${option.GradeId}`} value={option.GradeId}>
							{option.GradeName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
