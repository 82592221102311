import { TimeService, getMomentTz } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";
import { ReactElement, useRef } from "react";
import { useStyles } from "../styles";

const moment = getMomentTz();

export interface FieldCasterProps {
	value: string;
	field: string;
	secondValue?: string;
	checked?: boolean;
	title?: any;
	readonly?: boolean;

	selectedValue?: number;
	dataSource?: { value: number; label: string }[];
	hasButton?: boolean;
	iconButton?: ReactElement;
	showNext?: boolean;
	showPrevious?: boolean;

	styleInput?: CSSProperties;
	styleLabel?: CSSProperties;
	styleTextBox?: CSSProperties;
	styleContainer?: CSSProperties;

	classLabel?: string;
	classContainer?: string;
	classInput?: string;
	classCheckBoxGroup?: string;

	onButtonClick?: () => void;
	onNext?: () => void;
	onPrevious?: () => void;
	onLast?: () => void;
	onChange?: (field: string, value: any) => void;
	onBlur?: (field: string, value: any) => void;
	onFocus?: (field: string) => void;

	className?: any;
}

export type DateFieldCasterProps = {
	value: Date | null;
	field: string;
	secondValue?: string;
	checked?: boolean;
	title?: any;
	readonly?: boolean;

	selectedValue?: number;
	dataSource?: { value: number; label: string }[];
	hasButton?: boolean;
	iconButton?: ReactElement;
	showNext?: boolean;
	showPrevious?: boolean;

	styleLabel?: CSSProperties;
	styleTextBox?: CSSProperties;
	styleContainer?: CSSProperties;

	classLabel?: string;
	classContainer?: string;
	classInput?: string;
	classCheckBoxGroup?: string;

	onButtonClick?: () => void;
	onNext?: () => void;
	onPrevious?: () => void;
	onLast?: () => void;
	onChange?: (field: string, value: any) => void;
	onBlur?: (field: string, value: any) => void;
	onFocus?: (field: string) => void;
};

const ProcessNavigatorField = ({
	classContainer,
	classInput,
	iconButton,
	value,
	secondValue,
	onButtonClick,
	onNext,
	onPrevious,
	onLast,
	showNext,
	showPrevious,
}: FieldCasterProps) => {
	const processClick = () => {
		if (onButtonClick) onButtonClick();
	};
	return (
		<div
			className={"input-group " + classContainer}
			style={{ textAlign: "center", margin: "auto", width: "270px" }}
		>
			{showPrevious && (
				<div className="input-group-prepend">
					<button
						className="btn btn-secondary"
						type="button"
						onClick={onPrevious}
					>
						<i className="fas fa-chevron-left"></i>
					</button>
				</div>
			)}
			<div style={{ position: "relative" }}>
				<input
					type="text"
					readOnly={true}
					value={value || ""}
					className={"form-control " + classInput}
					placeholder="Process"
					style={{ borderBottomRightRadius: 0 }}
				/>

				<div
					style={{
						position: "absolute",
						top: "13px",
						left: "6rem",
						right: "5px",
						margin: "auto",
						height: "1rem",
						width: "1rem",
					}}
				>
					<button
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "14px",
							borderRadius: "0.25rem",
							height: "22px",
							width: "22px",
							backgroundColor: "#ced2cc",
							border: "1px solid black",
						}}
						onClick={processClick}
					>
						{iconButton}
					</button>
				</div>
				<input
					type="text"
					readOnly={true}
					value={secondValue || ""}
					className="form-control"
					style={{
						textAlign: "center",
						fontSize: "14px",
						maxWidth: "130px",
						width: "130px",
						borderTopRightRadius: 0,
					}}
					placeholder="Year"
				/>
			</div>
			{showNext && (
				<div className="input-group-append">
					<button
						className="btn btn-secondary"
						title="Next process"
						type="button"
						onClick={onNext}
					>
						<i className="fas fa-chevron-right"></i>
					</button>
				</div>
			)}
			{showNext && (
				<div className="input-group-append">
					<button
						className="btn btn-secondary"
						title="Last process"
						type="button"
						onClick={onLast}
					>
						<i className="fas fa-chevron-right"></i>
						<i className="fas fa-chevron-right"></i>
					</button>
				</div>
			)}
		</div>
	);
};
const TextFieldCaster = ({
	title,
	readonly,
	field,
	value,

	hasButton,

	styleLabel,
	styleTextBox,
	styleContainer,

	classLabel,
	classContainer,
	classInput,

	onChange,
	onBlur,
	onFocus,
	onButtonClick,

	iconButton = <></>,
}: FieldCasterProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const valueClicked = () => {
		if (onButtonClick) onButtonClick();
	};
	const valueChanged = (value: string) => {
		if (onChange && readonly !== true) onChange(field, value);
	};
	const valueBlurred = (value: string) => {
		if (onBlur && readonly !== true) onBlur(field, value);
	};
	const valueFocused = () => {
		if (inputRef.current) inputRef.current.select();
		if (onFocus && readonly !== true) onFocus(field);
	};
	const handleOnKeyDown = (event: any) => {
		if (event.key === "Enter" && inputRef.current) {
			inputRef.current.blur(); // Blur the input element on "Enter" key press
		}
	};
	return (
		<Grid container>
			<Grid xs={12} item>
				<div
					className={"input-group " + classContainer}
					style={styleContainer || {}}
				>
					<div className="input-group-prepend">
						<span
							className={"input-group-text " + classLabel}
							style={styleLabel || {}}
						>
							{title}
						</span>
					</div>
					<input
						type="text"
						readOnly={readonly || false}
						disabled={readonly}
						ref={inputRef}
						value={value}
						onChange={(e) => valueChanged(e.target.value)}
						onBlur={(e) => valueBlurred(e.target.value)}
						onKeyDown={handleOnKeyDown}
						onFocus={valueFocused}
						className={"form-control " + classInput}
						style={styleTextBox || {}}
					></input>
					{hasButton && (
						<div className={"input-group-append"} onClick={valueClicked}>
							<span className={"input-group-text " + classLabel}>
								{iconButton}
							</span>
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
};
const CheckboxFieldCaster = ({
	title,
	field,
	checked,
	readonly,
	styleLabel,
	styleContainer,
	classContainer,
	classLabel,
	classInput,
	classCheckBoxGroup,
	styleTextBox,
	onChange,
	onBlur,
	onFocus,
}: FieldCasterProps) => {
	const valueChanged = (value: boolean) => {
		if (onChange) onChange(field, value);
	};
	const valueBlurred = (value: boolean) => {
		if (onBlur) onBlur(field, value);
	};
	const valueFocused = () => {
		if (onFocus && readonly !== true) onFocus(field);
	};
	return (
		<Grid container>
			<Grid xs={12} item>
				<div
					className={"input-group " + classContainer}
					style={styleContainer || {}}
				>
					<div className="input-group-prepend">
						<span
							className={"input-group-text " + classLabel}
							style={styleLabel || {}}
						>
							{title}
						</span>
					</div>

					<div className={"input-group-text " + classCheckBoxGroup}>
						<input
							className={" " + classInput}
							type="checkbox"
							checked={checked}
							onChange={(e) => valueChanged(e.target.checked)}
							onBlur={(e) => valueBlurred(e.target.checked)}
							onFocus={valueFocused}
							aria-label="Checkbox for following text input"
						/>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};
const DropdownFieldCaster = ({
	title,
	selectedValue,
	field,
	readonly,
	dataSource,
	styleLabel,
	styleInput,
	styleContainer,
	classLabel,
	classContainer,
	classInput,
	onChange,
	onBlur,
	onFocus,
	className,
}: FieldCasterProps) => {
	const valueChanged = (value: string) => {
		if (onChange && value !== "") onChange(field, parseInt(value));
	};
	const valueBlurred = (value: string) => {
		if (onBlur && value !== "") onBlur(field, parseInt(value));
	};
	const valueFocused = () => {
		if (onFocus && readonly !== true) onFocus(field);
	};
	return (
		<Grid container>
			<Grid xs={12} item>
				<div
					className={"input-group " + classContainer}
					style={styleContainer || {}}
				>
					<div className="input-group-prepend">
						<span
							className={"input-group-text " + classLabel}
							style={styleLabel || {}}
						>
							{title}
						</span>
					</div>
					<select
						className={"custom-select " + classInput}
						value={selectedValue}
						onChange={(e) => valueChanged(e.target.value)}
						onBlur={(e) => valueBlurred(e.target.value)}
						onFocus={valueFocused}
						style={{
							fontSize: "0.75rem",
							...styleInput,
						}}
						disabled={readonly}
					>
						<option value="">{"Choose"}</option>
						{dataSource?.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</Grid>
		</Grid>
	);
};

const DropdownFieldForCheckboxCaster = ({
	title,
	selectedValue,
	field,
	readonly,
	dataSource,
	styleLabel,
	styleContainer,
	classLabel,
	classContainer,
	classInput,
	onChange,
	onBlur,
	onFocus,
	className,
}: FieldCasterProps) => {
	const valueChanged = (value: string) => {
		if (onChange && value !== "") onChange(field, parseInt(value));
	};
	const valueBlurred = (value: string) => {
		if (onBlur && value !== "") onBlur(field, parseInt(value));
	};
	const valueFocused = () => {
		if (onFocus && readonly !== true) onFocus(field);
	};
	return (
		<Grid container>
			<Grid xs={12} item>
				<div
					className={"input-group " + classContainer}
					style={styleContainer || {}}
				>
					<div className="input-group-prepend">
						<span
							className={"input-group-text " + classLabel}
							style={styleLabel || {}}
						>
							{title}
						</span>
					</div>
					<div>
						<select
							className={"custom-select " + classInput}
							value={selectedValue}
							onChange={(e) => valueChanged(e.target.value)}
							onBlur={(e) => valueBlurred(e.target.value)}
							onFocus={valueFocused}
							style={{
								fontSize: "0.75rem",
								width: "100% !important",
								minWidth: "85px",
							}}
						>
							<option value="">{"Choose"}</option>
							{dataSource?.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};
const TextFieldForCheckBoxCaster = ({
	title,
	readonly,
	field,
	value,

	hasButton,

	styleLabel,
	styleTextBox,
	styleContainer,

	classLabel,
	classContainer,
	classInput,

	onChange,
	onBlur,
	onFocus,
	onButtonClick,

	iconButton = <></>,
}: FieldCasterProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const valueClicked = () => {
		if (onButtonClick) onButtonClick();
	};
	const valueChanged = (value: string) => {
		if (onChange && readonly !== true) onChange(field, value);
	};
	const valueBlurred = (value: string) => {
		if (onBlur && readonly !== true) onBlur(field, value);
	};
	const valueFocused = () => {
		if (inputRef.current) inputRef.current.select();
		if (onFocus && readonly !== true) onFocus(field);
	};
	const handleOnKeyDown = (event: any) => {
		if (event.key === "Enter" && inputRef.current) {
			inputRef.current.blur(); // Blur the input element on "Enter" key press
		}
	};
	return (
		<Grid container>
			<Grid xs={12} item>
				<div
					className={"input-group " + classContainer}
					style={styleContainer || {}}
				>
					<div className="input-group-prepend">
						<span
							className={"input-group-text " + classLabel}
							style={styleLabel || {}}
						>
							{title}
						</span>
					</div>
					<div style={{ width: "50px" }}>
						<input
							ref={inputRef}
							type="text"
							className={"form-control " + classInput}
							readOnly={readonly || false}
							disabled={readonly}
							value={value}
							onChange={(e) => valueChanged(e.target.value)}
							onBlur={(e) => valueBlurred(e.target.value)}
							onKeyDown={handleOnKeyDown}
							onFocus={valueFocused}
							style={
								styleTextBox || {
									fontSize: "0.75rem",
									width: "100% !important",
									minWidth: "85px",
								}
							}
						></input>
					</div>
					{hasButton && (
						<div className={"input-group-append"} onClick={valueClicked}>
							<span className={"input-group-text " + classLabel}>
								{iconButton}
							</span>
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

const DateTimeFieldCaster = ({
	title,
	readonly,
	field,
	value,
	styleContainer,
	classLabel,
	classContainer,
	onChange,
	onBlur,
	onFocus,
}: DateFieldCasterProps) => {
	const classes = useStyles();

	const inputRef = useRef<HTMLInputElement>(null);
	const valueChanged = (value: Date | null) => {
		if (onChange && readonly !== true) onChange(field, value);
	};
	const valueBlurred = (value: Date | null) => {
		if (onBlur && readonly !== true) onBlur(field, value);
	};
	const valueFocused = () => {
		if (onFocus && readonly !== true) onFocus(field);
	};
	const handleOnKeyDown = (event: any) => {
		if (event.key === "Enter" && inputRef.current) {
			inputRef.current.blur();
		}
	};
	return (
		<Grid container className={classes.customInput}>
			<Grid xs={12} md={12} item>
				<div
					className={"input-group " + classContainer}
					style={
						styleContainer || {
							display: "flex",
							flexWrap: "nowrap",
						}
					}
				>
					<div className="input-group-prepend">
						<span className={"input-group-text " + classLabel}>{title}</span>
					</div>
					<DateTimePicker
						timezone={TimeService.getInstance().getServerTimeZone()}
						format="HH:mm"
						readOnly={readonly || false}
						disabled={readonly}
						timeSteps={{ minutes: 1 }}
						value={moment(value) as any}
						onChange={(value: Moment | null) => {
							valueChanged(value?.toDate() || null);
						}}
						disableOpenPicker
						className={classes.dateTimePicker}
						slotProps={{
							textField: {
								onBlur: () => valueBlurred(value),
								onKeyDown: handleOnKeyDown,
								onFocus: valueFocused,
								ref: inputRef,
							},
						}}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export {
	CheckboxFieldCaster,
	DropdownFieldCaster,
	ProcessNavigatorField,
	TextFieldCaster,
	DateTimeFieldCaster,
	DropdownFieldForCheckboxCaster,
	TextFieldForCheckBoxCaster,
};
