import * as React from "react";
import { CatalogResultSourcePage } from "./CatalogResultSourcePage";

import { CatalogResultSourceProvider } from "./CatalogResultSourceContext";

type Props = {};
const CatalogResultSource = (props: Props) => {
	return (
		<CatalogResultSourceProvider>
			<CatalogResultSourcePage />
		</CatalogResultSourceProvider>
	);
};
export default CatalogResultSource;
