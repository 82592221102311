import React from "react";
import { AssetItemProps } from "../asset-item";
import { BarDisplay } from "./bar-display";
import styles from "./bar.module.css";

export const BarSmall: React.FC<AssetItemProps> = ({
	asset,
	onEventStart,
	isSelected,
}) => {
	return (
		<g className={styles.barWrapper} tabIndex={0}>
			<BarDisplay
				id={`bar-${asset.id}`}
				x={asset.x1}
				y={asset.y}
				width={asset.x2 - asset.x1}
				height={asset.height}
				progressX={asset.progressX}
				progressWidth={asset.progressWidth}
				barCornerRadius={asset.barCornerRadius}
				styles={asset.styles}
				isSelected={isSelected}
			/>
			<g className="handleGroup"></g>
		</g>
	);
};
