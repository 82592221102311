import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		width: "100%",
	},
	colorCircle: {
		width: "26px",
		height: "26px",
		borderRadius: 4,
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#fff",
		fontWeight: "bold",
		marginRight: "8px",
	},
});

type CategoryTagProps = {
	value: {
		name: string;
		color: string;
	};
};

export const CategoryTag = ({
	value,
}: {
	value: CategoryTagProps["value"];
}) => {
	const classes = useStyles();

	if (!value || !value.name || !value.color) {
		return null;
	}

	return (
		<div className={classes.container}>
			<div
				title={value.name}
				className={classes.colorCircle}
				style={{ backgroundColor: value.color }}
			>
				{value.name.charAt(0).toUpperCase()}
			</div>
			<span>{value.name}</span>
		</div>
	);
};
