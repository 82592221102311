import { useContextMenuMESF } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { FooterButton } from "../../../../../controls/buttons/FooterButton";
import { useGridStyles } from "../../../styles/gridStyles";
import { useCatalogCategoryContext } from "../../context/CategoriesContext";
import { useCategoriesOptionsFunctions } from "../../hooks/useCategoriesOptionsFunctions";
import { useTableData } from "../../hooks/useTableData";
import { CreateCategory } from "./CreateCategory";
import { DeleteCategory } from "./DeleteCategory";
import { EditCategory } from "./EditCategory";
import { Filters } from "./Filters";

type Props = {
	getCatalogCategoriesFromAPI: Function;
	isLoading: boolean;
	setIsLoading: Function;
};
export const TableCategories = ({
	getCatalogCategoriesFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [categoryId, setCategoryId] = useState<number | null>(null);
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
	const [error, setError] = useState<string>("");
	const { getMenuOptions } = useCategoriesOptionsFunctions({
		setCategoryId,
		setShowEditModal,
		setShowDeleteModal,
		setShowCreateModal,
	});
	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { rows, columnDefs } = useTableData({
		setCategoryId,
		setShowEditModal,
		setShowDeleteModal,
		showContextMenu,
	});

	const {
		state: { assetCodes, assetIdToFilter },
	} = useCatalogCategoryContext();
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {
			setShowEditModal(!showEditModal);
			setCategoryId(data.DelayCategoryId);
		},
	});
	columnDefaults.floatingFilter = false;
	const onRowDoubleClicked = (event: any) => {
		setCategoryId(event.data.DelayCategoryId);
		setShowEditModal(true);
	};

	const getContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data = params.node?.data;
			showContextMenu(event as any, data, "categoriesContext");
			return [];
		},
		[rows],
	);

	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else {
			gridApi?.hideOverlay();
		}
	}, [isLoading, rows, gridApi]);
	useEffect(() => {
		registerConfig({
			id: "categoriesContext",
			getOptions: getMenuOptions,
		});
	}, []);
	const classes = useGridStyles();

	return (
		<Grid container justifyContent="center">
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "20px 0 20px", fontWeight: 600 }}
				>
					Delays Categories
				</Typography>
				<Filters />
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
			>
				<Grid
					item
					md={12}
					xs={12}
					style={{ padding: "0 15px" }}
					// className={classes.relative}
				>
					<CreateCategory
						show={showCreateModal}
						onHide={(categoryCreated) => {
							setShowCreateModal(false);
							if (categoryCreated) {
								getCatalogCategoriesFromAPI(assetIdToFilter);
							}
						}}
						refreshData={getCatalogCategoriesFromAPI}
					/>
					<EditCategory
						show={showEditModal}
						onHide={(updated: boolean) => {
							setShowEditModal(false);
							if (updated) {
								getCatalogCategoriesFromAPI(assetIdToFilter);
							}
						}}
						DelayCategoryId={categoryId}
					/>
					<DeleteCategory
						show={showDeleteModal}
						onHide={(updated: boolean) => {
							setShowDeleteModal(false);
							if (updated) {
								getCatalogCategoriesFromAPI(assetIdToFilter);
							}
						}}
						DelayCategoryId={categoryId}
					/>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							height: "61vh",
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							margin: "20px 0 10px",
						}}
					>
						<div
							style={{ height: "100%", width: "100%" }}
							className="ag-theme-material"
						>
							<AgGridReact
								rowData={rows || []}
								columnDefs={columnDefs}
								defaultColDef={columnDefaults}
								columnTypes={columnTypes}
								getRowId={(params) => params.data.DelayCategoryId}
								rowHeight={34}
								headerHeight={28}
								animateRows={true}
								rowSelection="single"
								getContextMenuItems={getContextMenuItems}
								onRowDoubleClicked={onRowDoubleClicked}
								onGridReady={(params) => setGridApi(params.api)}
							/>
						</div>
					</Grid>
					<FooterButton
						label="CATEGORY"
						showCreateModal={showCreateModal}
						setShowCreateModal={setShowCreateModal}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
