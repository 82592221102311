import { createSlice } from "@reduxjs/toolkit";
import { CatalogGrade } from "../../models/CatalogGrade";

const CatalogGradeInitialState: {
	grades: CatalogGrade[] | null;
} = {
	grades: null,
};

export const CatalogGradeReducer = createSlice({
	name: "__",
	initialState: CatalogGradeInitialState,
	reducers: {
		setGrades(state, { payload }) {
			state.grades = payload;
		},
	},
});
