import { MesfModal, useAssetContext } from "@dexteel/mesf-core";
import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogProps,
	FormControlLabel,
	TextField,
	Typography,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DelayCodeAPI } from "../../../models/DelayCodeAPI";
import { DATA_DELAY_INITIAL_VALUES } from "../../../models/InitialValueDataDelay";
import { useConfigurationAssetContext } from "../../context/ConfigurationAssetContext";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import {
	getDelay,
	getDelays,
} from "../../repositories/ConfigurationDelayRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiPaper-root": {
			height: "auto",
		},
		"& .MuiDialogContent-dividers": {
			borderBottom: "unset",
		},
	},
	customDialog: {
		"& .MuiDivider-root:last-child": {
			display: "none",
		},
	},
	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
};

export const ViewDelay = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const {
		state: {
			dataToCreateDelay,
			delayNodeSelectedInTree,
			delayAreaAsset,
			allDelayCodes,
		},
	} = useConfigurationDelayCodeContext();
	const {
		control,
		reset,
		setValue,
		formState: { errors },
	} = useForm<DelayCodeAPI>({
		defaultValues: dataToCreateDelay
			? dataToCreateDelay
			: DATA_DELAY_INITIAL_VALUES,
	});

	const handleCancel = () => {
		onHide(false);
	};

	useEffect(() => {
		if (show) {
			reset();
			if (delayNodeSelectedInTree.DelayCodeId)
				(async () => {
					const response = await getDelay(
						delayNodeSelectedInTree.DelayCodeId ?? 0,
					);
					if (response.ok) {
						if (
							response === undefined ||
							response.data === undefined ||
							response.data.tables === undefined ||
							response.data.tables[0].rows === undefined ||
							response.data.tables[0].rows[0] === undefined
						)
							return;
						const delayCode = response.data.tables[0].rows[0];
						if (delayCode) {
							setValue("ParentDelayCodeId", delayCode.ParentDelayCodeId);
							const parentDelayNode = allDelayCodes.find(
								(node: any) =>
									node.DelayCodeId ===
									delayNodeSelectedInTree?.ParentDelayCodeId,
							);
							setValue("ParentDelayCodeName", parentDelayNode?.DelayCodeName);
							setValue("DelayCodeId", delayCode.DelayCodeId);
							setValue("DelayCodeName", delayCode.DelayCodeName);
							setValue("DelayCategoryName", delayCode.DelayCategoryName);
							setValue("IsBranch", delayCode?.IsBranch);
						}
					}
				})();
		}
	}, [show]);

	return (
		<div className={classes.root}>
			<MesfModal
				maxWidth={maxWidth}
				title="VIEW DELAY CODE"
				open={show}
				handleClose={() => handleCancel()}
			>
				<form onSubmit={() => {}}>
					<Grid container style={{ alignItems: "center" }}>
						<MesfModal.Content dividers style={{ padding: "10px 30px" }}>
							<Grid container spacing={1}>
								<Grid item md={3} sm={12} xs={10}>
									<Controller
										name="DelayAreaAssetId"
										control={control}
										render={() => (
											<>
												<TextField
													label="Asset"
													variant="outlined"
													value={delayAreaAsset?.AssetName}
													disabled
													margin="dense"
													size="small"
													fullWidth
												/>
											</>
										)}
									/>
								</Grid>
								<Grid item md={9} sm={12} xs={10}>
									<Controller
										name="DelayCodeName"
										control={control}
										rules={{ required: "Name is required" }}
										render={({ field: { onChange, value } }) => (
											<>
												<TextField
													label="Delay Code Name"
													variant="outlined"
													error={!!errors.DelayCodeName}
													fullWidth
													disabled
													margin="dense"
													autoComplete="off"
													onChange={onChange}
													value={value}
												/>
												{errors.DelayCodeName && (
													<span className={classes.errorLabel}>
														{errors.DelayCodeName.message}
													</span>
												)}
											</>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item md={12} xs={12}>
								<Controller
									name="ParentDelayCodeName"
									control={control}
									render={({ field: { onChange, value } }) => (
										<>
											{delayNodeSelectedInTree.ParentDelayCodeId && (
												<TextField
													label="Parent"
													variant="outlined"
													error={!!errors.ParentDelayCodeName}
													fullWidth
													margin="dense"
													autoComplete="off"
													onChange={onChange}
													value={value || "Delay Charge"}
													disabled={true}
												/>
											)}
										</>
									)}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<Controller
									name="DelayCategoryName"
									control={control}
									render={({ field: { onChange, value } }) => (
										<>
											<TextField
												label="Default Category"
												variant="outlined"
												error={!!errors.DelayCategoryName}
												fullWidth
												margin="dense"
												autoComplete="off"
												onChange={onChange}
												value={value}
												disabled={true}
											/>
										</>
									)}
								/>
							</Grid>
							<Grid container style={{ marginTop: "5px", padding: "0 8px" }}>
								<Grid item xs={6} md={6}>
									<Controller
										name="IsBranch"
										control={control}
										defaultValue={false}
										render={({ field }) => (
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														checked={field.value as boolean}
														onChange={(e) => field.onChange(e.target.checked)}
														name="IsBranch"
														color="primary"
														disabled={true}
													/>
												}
												label="Has Children"
											/>
										)}
									/>
								</Grid>
							</Grid>
						</MesfModal.Content>
					</Grid>
				</form>
			</MesfModal>
		</div>
	);
};
