import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogMechanicalTestReducer } from "./CatalogMechanicalTestReducer";

const CatalogMechanicalTestContext = createContext({
	state: CatalogMechanicalTestReducer.getInitialState(),
	actions: CatalogMechanicalTestReducer.actions,
});

export const useCatalogMechanicalTestContext = () =>
	useContext(CatalogMechanicalTestContext);

export const CatalogMechanicalTestProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogMechanicalTestReducer.getInitialState(),
		reducers: CatalogMechanicalTestReducer.caseReducers,
	});
	return (
		<CatalogMechanicalTestContext.Provider value={{ state, actions }}>
			{children}
		</CatalogMechanicalTestContext.Provider>
	);
};
