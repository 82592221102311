import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getDelaysForPareto = async (
	startDate?: Date,
	endDate?: Date,
	delayCodeId?: number,
	delayCategoryId?: number,
	shiftId?: number,
	crew?: string,
	delayAreaAssetId?: number,
	includeInactives: boolean = false,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "Start", value: startDate || null });
	parameters.push({ name: "End", value: endDate || null });
	parameters.push({ name: "DelayCodeId", value: delayCodeId || null });
	parameters.push({ name: "DelayCategoryId", value: delayCategoryId || null });
	parameters.push({ name: "ShiftId", value: shiftId || null });
	parameters.push({
		name: "Crew",
		value: (crew as string) === "All" ? null : crew || null,
	});
	parameters.push({
		name: "DelayAreaAssetId",
		value: delayAreaAssetId || null,
	});
	parameters.push({ name: "IncludeInactives", value: includeInactives });

	const resp = await apiService.callV2(
		"[DEL].[GetDelaysForPareto]",
		parameters,
	);
	return resp;
};

export const getDelayAreas = async () => {
	const apiService = new MESApiService();
	const resp = await apiService.callV2("[DEL].[GetDelayAssets]", []);
	return resp;
};
