import { getMomentTz } from "@dexteel/mesf-core";
import { useState } from "react";
import { ProcessData } from "../../../models/ProcessData";
import {
	linkCasterProcess,
	unLinkProcess,
} from "../../../repositories/ProductionRepository";
import { useCastersContext } from "../../CastersContext";
const moment = getMomentTz();

export const useLinkUnlinkProcesses = ({
	refreshData,
}: { refreshData: Function }) => {
	const { state, actions } = useCastersContext();
	const process = state.processData || ({} as ProcessData);

	const [showChangeModal, setShowChangeModal] = useState(false);
	const [showLinkModal, setShowLinkModal] = useState(false);
	const [showUnlinkModal, setShowUnlinkModal] = useState<{
		show: boolean;
		message: string;
	}>({ show: false, message: "" });

	const onProcessClicked = () => {
		if (process === null || process.ProcessId === null) return;
		if (process.HeatId === null) {
			setShowLinkModal(true);
		} else {
			const dateOpen = moment
				.utc(process.LadleOpen)
				.local() // del server
				.format("YYYY/MM/DD HH:mm");
			setShowUnlinkModal({
				show: true,
				message:
					"The heat " +
					process.HeatName +
					" from " +
					dateOpen +
					" will be unlinked.  Do you want to continue?",
			});
		}
	};
	const unlinkCasterProcess = async (value?: string) => {
		setShowUnlinkModal({ show: false, message: "" });
		if (value !== "OK") return;
		await unLinkProcessData();
	};

	const unLinkProcessData = async () => {
		try {
			if (
				state.processData === null ||
				state.processData.ProcessId === null ||
				state.processData.HeatId === null
			)
				return;
			actions.setIsLoadingBackground(true);
			const resp = await unLinkProcess(state.processData.ProcessId);
			actions.setIsLoadingBackground(false);
			if (resp.ok) {
				actions.setNotificationMessage("Process unlinked successfully");
				await refreshData();
			} else {
				actions.setErrorMessage(resp.message);
			}
		} catch (error: any) {
			actions.setIsLoadingBackground(false);
			actions.setErrorMessage(error.message);
		}
	};
	const linkProcessData = async (heatId: number) => {
		try {
			if (
				state.processData === null ||
				state.processData.ProcessId === null ||
				state.processData.HeatId !== null
			)
				return;
			actions.setIsLoadingBackground(true);
			const resp = await linkCasterProcess(state.processData.ProcessId, heatId);
			actions.setIsLoadingBackground(false);
			if (resp.ok) {
				actions.setNotificationMessage("Process linked successfully");
				await refreshData();
			} else {
				actions.setErrorMessage(resp.message);
			}
		} catch (error: any) {
			actions.setIsLoadingBackground(false);
			actions.setErrorMessage(error.message);
		}
	};

	return {
		onProcessClicked,
		unlinkCasterProcess,
		unLinkProcessData,
		linkProcessData,
		showChangeModal,
		showLinkModal,
		showUnlinkModal,
		setShowChangeModal,
		setShowLinkModal,
	};
};
