export interface ChemistryResult {
	Id: number | null;
	ChemistrySampleId: number | null;
	ElementId: number | null;
	Value: number | null;
}

export const isValidChemistryResult = (model: ChemistryResult) => {
	if (
		model.ChemistrySampleId === undefined ||
		model.ChemistrySampleId === null ||
		model.ElementId === undefined ||
		model.ElementId === null
	)
		return false;
	return !(
		model.Value === undefined ||
		model.Value === null ||
		isNaN(Number(model.Value))
	);
};
