import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControlLabel: {
			border: "1px solid #c4c4c4",
			margin: "1px 0px 0px 0px",
			padding: "2px 26.5rem 2px 0px",
			borderRadius: "4px",
			"& .MuiTypography-body1": {
				fontSize: "1.2rem",
			},
		},
		checkbox: {
			userSelect: "none",
		},
		modalBody: {},
		title: {
			fontSize: 15,
		},
		chip: {
			margin: 2,
		},
		errorLabel: {
			fontSize: 12,
			color: "#F44336",
		},
	}),
);
