import { ErrorModal, MesfModal, TreePickerControl } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	DialogProps,
	TextField,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DelayCategorySelector } from "../../../../../controls/selectors/DelayCategorySelector";
import { useCatalogCategoryContext } from "../../../categories/context/CategoriesContext";
import { DelayCodeAPI } from "../../../models/DelayCodeAPI";
import { DATA_DELAY_INITIAL_VALUES } from "../../../models/InitialValueDataDelay";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import { updateDelayData } from "../../repositories/ConfigurationDelayRepository";
import { useDelayCodes } from "../hooks/useDelayCodes";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiPaper-root": {
			height: "auto",
		},
	},
	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const CreateDelay = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const [error, setError] = useState<string>("");
	const [IsSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
	const {
		state: { categories },
	} = useCatalogCategoryContext();
	const {
		state: {
			dataToCreateDelay,
			delayNodeSelectedInTree,
			delayCodes,
			allDelayCodes,
			delayAreaAsset,
		},
		actions: {
			setDelayParentDelayIdToCreateDelay,
			setDelayParentNameToCreateDelay,
			setDelayNameToCreateDelay,
		},
	} = useConfigurationDelayCodeContext();
	const { loadFilterDelays } = useDelayCodes();
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<DelayCodeAPI>({
		defaultValues: DATA_DELAY_INITIAL_VALUES,
	});
	const handleCancel = () => {
		onHide(false);
	};
	const onSubmit: SubmitHandler<DelayCodeAPI> = async (data: any) => {
		setIsSubmitLoading(true);
		const resp = await updateDelayData(data);
		if (resp.ok) {
			onHide(true);
		} else {
			setError(resp.message);
			onHide(false);
		}
		setIsSubmitLoading(false);
	};
	const changeParentDelayId = (value: any, description: any) => {
		setValue("ParentDelayCodeId", parseInt(value));
		setDelayParentDelayIdToCreateDelay(parseInt(value));
		setDelayParentNameToCreateDelay(description);
	};
	useEffect(() => {
		if (show) {
			reset();
			loadFilterDelays();
			if (delayNodeSelectedInTree?.DelayCategoryId) {
				const parentDelayNode = allDelayCodes.find(
					(node: any) =>
						node.DelayCodeId === delayNodeSelectedInTree?.DelayCodeId,
				);
				setDelayParentNameToCreateDelay(parentDelayNode.DelayCodeName);
				setValue("ParentDelayCodeId", parentDelayNode.DelayCodeId);
				setValue("DelayCategoryId", delayNodeSelectedInTree.DelayCategoryId);
				setValue(
					"DelayAreaAssetId",
					delayAreaAsset ? delayAreaAsset.AssetId : 0,
				);
			} else {
				const parentRoot = allDelayCodes.find(
					(node: any) => node.ParentDelayCodeId === null,
				);
				if (parentRoot) {
					setDelayParentDelayIdToCreateDelay(parentRoot.DelayCodeId);
					setDelayParentNameToCreateDelay(parentRoot.DelayCodeName);
					setValue("ParentDelayCodeId", parentRoot.DelayCodeId);
				}
				setDelayNameToCreateDelay("");
				setValue(
					"DelayCategoryId",
					categories ? categories[0].DelayCategoryId : 0,
				);
				setValue(
					"DelayAreaAssetId",
					delayAreaAsset ? delayAreaAsset.AssetId : 0,
				);
			}
		}
	}, [show]);
	return (
		<div className={classes.root}>
			<MesfModal
				title="NEW DELAY CODE"
				maxWidth={maxWidth}
				open={show}
				handleClose={() => handleCancel()}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container style={{ alignItems: "center" }}>
						<MesfModal.Content dividers style={{ padding: "10px 30px" }}>
							<Grid container spacing={1}>
								<Grid item md={3} sm={12} xs={10}>
									<Controller
										name="DelayAreaAssetId"
										control={control}
										render={() => (
											<>
												<TextField
													label="Asset"
													variant="outlined"
													value={delayAreaAsset?.AssetName}
													disabled
													margin="dense"
													size="small"
													fullWidth
												/>
											</>
										)}
									/>
								</Grid>
								<Grid item md={9} sm={12} xs={10}>
									<Controller
										name="DelayCodeName"
										control={control}
										rules={{ required: "Name is required" }}
										render={({ field: { onChange, value } }) => (
											<>
												<TextField
													label="Delay Code Name"
													variant="outlined"
													error={!!errors.DelayCodeName}
													fullWidth
													margin="dense"
													size="small"
													autoComplete="off"
													onChange={onChange}
													value={value}
												/>
												{errors.DelayCodeName && (
													<span className={classes.errorLabel}>
														{errors.DelayCodeName.message}
													</span>
												)}
											</>
										)}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item md={12} sm={12} xs={10}>
									<Controller
										name="ParentDelayCodeId"
										control={control}
										rules={{ required: "Parent is required" }}
										render={({ field }) => (
											<>
												<TreePickerControl
													inputTitle="Parent"
													title="Delay Code Parent"
													dataSource={delayCodes}
													value={field.value}
													description={dataToCreateDelay?.ParentDelayCodeName}
													onSelect={(value, description) => {
														field.onChange(value);
														changeParentDelayId(value, description);
													}}
													selectBranch={true}
													selectActive={false}
													selectInternal={false}
												/>
												{errors.ParentDelayCodeId && (
													<span className={classes.errorLabel}>
														{errors.ParentDelayCodeId.message}
													</span>
												)}
											</>
										)}
									/>
								</Grid>
								<Grid item sm={12} xs={10}>
									<Controller
										name="DelayCategoryId"
										control={control}
										rules={{ required: "Category is required" }}
										render={({ field }) => (
											<DelayCategorySelector
												value={field.value}
												onChange={field.onChange}
												assetId={
													delayAreaAsset
														? (delayAreaAsset.AssetId as number)
														: null
												}
												error={errors.DelayCategoryId?.message || ""}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</MesfModal.Content>
						<Grid item md={12} xs={12}>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container justifyContent="flex-end" style={{ margin: 0 }}>
									<Grid
										container
										item
										style={{
											marginLeft: "auto",
											marginTop: 5,
											marginBottom: 0,
										}}
									>
										<Grid
											item
											md={6}
											xs={12}
											style={{ paddingLeft: "0", paddingRight: "16px" }}
										>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={() => handleCancel()}
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={6} xs={12}>
											<Button
												fullWidth
												startIcon={
													IsSubmitLoading && <CircularProgress size="1rem" />
												}
												disabled={IsSubmitLoading}
												variant="contained"
												color="primary"
												type="submit"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</Grid>
					</Grid>
				</form>
			</MesfModal>
			<ErrorModal error={error} onHide={() => setError("")} />
		</div>
	);
};
