import { HeatsForSeach } from "@/controls/heatSearch/components/HeatSearchTextField";
import { createSlice } from "@reduxjs/toolkit";
import { HeatDataEntryType } from "../models/Heats/HeatDataEntryType";

const HeatsByShiftInitialState: {
	heats: HeatDataEntryType[];
	lastestHeats: HeatsForSeach[];
} = {
	heats: [],
	lastestHeats: [],
};

export const HeatsByShiftReducer = createSlice({
	name: "__",
	initialState: HeatsByShiftInitialState,
	reducers: {
		setHeatsByShift(state, { payload }) {
			state.heats = payload;
		},
		setLastestHeats(state, { payload }) {
			state.lastestHeats = payload;
		},
	},
});
