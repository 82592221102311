import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { DelayByShift } from "../models/DelayByShift";
import { MeltShopProductionShiftFilter } from "../models/Filters";

const DELAY_AREA_ASSETID: number = 10;

export const getShiftByParameters = async (
	parameters: MeltShopProductionShiftFilter,
) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "ShiftId",
			value: parameters.shiftId === 0 ? null : parameters.shiftId,
		});
		sqlParameters.push({
			name: "ProductionDate",
			value: JSON.parse(JSON.stringify(parameters.productionDate)),
		});
		const apiService = new MESApiService();
		const resp = await apiService.callV2(
			"[MES].[GetShiftByParameters]",
			sqlParameters,
		);
		if (resp.ok) {
			const data = get(resp, "data.tables[0].rows[0]", {});
			if (data.CurrentProductionDate) {
				return {
					ok: true,
					data: data,
				};
			}
			return {
				ok: true,
				data: get(resp, "data.tables[0].rows[0]", {}),
			};
		}
		return resp;
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const getDelaysByShift = async (
	shiftId: number,
	delayAreaAssetId: number | null,
	includeMicrodelays: boolean = false,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ShiftId", value: shiftId });
	parameters.push({ name: "DelayAreaAssetId", value: delayAreaAssetId });
	parameters.push({ name: "IncludeMicrodelays", value: includeMicrodelays });
	return await apiService.callV2("[DEL].[GetDelaysByShift]", parameters);
};

export const getAssetsForDelayArea = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayAreaAssetId", value: DELAY_AREA_ASSETID });
	return await apiService.callV2("[DEL].[GetAssetsFordelayArea]", parameters);
};

export const upsertDelay = async (
	DelayId: number | null,
	AssetId: number | null,
	Start: Date | null,
	End: Date | null,
	DelayCodeId: number | null,
	DelayCategoryId: number | null,
	Comments: string | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "DelayId", value: DelayId });
	parameters.push({ name: "AssetId", value: AssetId });
	parameters.push({
		name: "Start",
		value: Start ? Start.toISOString() : null,
	});
	parameters.push({
		name: "End",
		value: End ? End.toISOString() : null,
	});
	parameters.push({ name: "DelayCodeId", value: DelayCodeId });
	parameters.push({ name: "DelayCategoryId", value: DelayCategoryId });
	parameters.push({ name: "Comments", value: Comments });

	return await apiService.callV2("[DEL].[UpsertManualDelay]", parameters);
};

export const justifyDelay = async (
	delayId: number,
	delayCodeId: number | null,
	delayCategoryId: number | null,
	comments: string | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "DelayId", value: delayId });
	parameters.push({ name: "DelayCodeId", value: delayCodeId });
	parameters.push({ name: "DelayCategoryId", value: delayCategoryId });
	parameters.push({ name: "Comments", value: comments });

	return await apiService.callV2("[DEL].[JustifyDelay]", parameters);
};

export const getDelayCodes = async (delayAreaAssetId: number | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayAreaAssetId", value: delayAreaAssetId });
	return await apiService.callV2("[DEL].[GetDelayCodes]", parameters);
};

export const getDelayCategories = async (delayAreaAssetId: number | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayAreaAssetId", value: delayAreaAssetId });
	return await apiService.callV2("[DEL].[GetDelayCategories]", parameters);
};

export const getDelay = async (DelayId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayId", value: DelayId });
	return await apiService.callV2("[DEL].[GetDelay]", parameters);
};

// export const upsertDelayData = async (
//   delayId: number,
//   field: string,
//   value: any
// ) => {
//   const apiService = new MESApiService();
//   const parameters: spParameter[] = [];
//   parameters.push({ name: "DelayId", value: delayId });
//   parameters.push({ name: field, value: value });
//   parameters.push({ name: "IsManual", value: true });

//   try {
//     return await apiService.callV2("[DEL].[UpsertManualDelay]", parameters);
//   } catch (e: any) {
//     return { ok: false, message: e.toString() };
//   }
// };

export const deleteDelay = async (delayId: number | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayId", value: delayId });

	return await apiService.callV2("[DEL].[DeleteManualDelay]", parameters);
};

export const splitDelay = async (
	delay1: DelayByShift,
	delay2: DelayByShift,
) => {
	const apiService = new MESApiService();
	const splitTime = delay1.End?.toISOString();
	const parameters: spParameter[] = [
		{ name: "DelayId", value: delay1.DelayId },
		{ name: "SplitTime", value: splitTime! },
		{ name: "DelayCodeId1", value: delay1.DelayCodeId },
		{ name: "DelayCodeId2", value: delay2.DelayCodeId },
		{ name: "DelayCategoryId1", value: delay1.DelayCategoryId },
		{ name: "DelayCategoryId2", value: delay2.DelayCategoryId },
		{ name: "Comments1", value: delay1.Comments },
		{ name: "Comments2", value: delay2.Comments },
	];
	return await apiService.callV2("[DEL].[SplitDelay]", parameters);
};
