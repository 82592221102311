import { createSlice } from "@reduxjs/toolkit";
import { CasterMoldSize, Grade } from "../models/Catalogs";
import { CasterProductionFilter } from "../models/Filters";

const fromDate: Date = new Date();
fromDate.setDate(fromDate.getDate() - 5);
fromDate.setHours(0, 0, 0, 0);
const toDate: Date = new Date();
toDate.setHours(23, 59, 59, 0);
const CasterProductionInitialState: {
	casterProductions: any[] | null;
	casterProductionFilter: CasterProductionFilter;
	billetSizes: CasterMoldSize[];
	grades: Grade[];
	showLoading: boolean;
} = {
	casterProductions: null,
	billetSizes: [],
	grades: [],
	casterProductionFilter: {
		startDate: fromDate,
		endDate: toDate,
		billetSizeId: 0,
		gradeId: 0,
		shiftId: 0,
	},
	showLoading: false,
};

export const CasterProductionReducer = createSlice({
	name: "__",
	initialState: CasterProductionInitialState,
	reducers: {
		setCasterProductions(state, { payload }) {
			state.casterProductions = payload;
		},
		setBilletSizes(state, { payload }) {
			state.billetSizes = payload;
		},
		setGrades(state, { payload }) {
			state.grades = payload;
		},
		setCasterProductionFilters(state, { payload }) {
			state.casterProductionFilter = payload;
		},
		setShowLoading(state, { payload }) {
			state.showLoading = payload;
		},
	},
});
