import { Button, Grid, Theme, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { ColDef, EditableCallbackParams } from "ag-grid-enterprise";
import { useState } from "react";
import { CatalogBucket } from "../../../models/CatalogBucket";
import { useBucketContext } from "../CatalogBucketContext";

const useStyles = makeStyles((theme: Theme) => ({
	buttons: {
		opacity: 0,
		transition: "opacity 0.1s ease-in-out",
		".ag-row-hover &": {
			opacity: 1,
		},
		".ag-row-focus &": {
			opacity: 1,
		},
		".ag-row-selected &": {
			opacity: 1,
		},
	},
}));

const cellStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

type Props = {
	setBucketId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	showContextMenu: Function;
};

export const useTableData = ({
	setBucketId,
	setShowEditModal,
	setShowDeleteModal,
	showContextMenu,
}: Props) => {
	const { state } = useBucketContext();
	const classes = useStyles();

	const rows: CatalogBucket[] =
		state.buckets?.map((bucket: CatalogBucket) => ({
			...bucket,
		})) || [];

	const [columnDefs] = useState<ColDef[]>([
		{
			field: "BucketName",
			headerName: "Name",
			minWidth: 20,
			flex: 3,
			cellStyle: cellStyle,
		},
		{
			field: "Capacity",
			headerName: "Capacity",
			minWidth: 20,
			flex: 2,
			cellStyle: cellStyle,
		},
		{
			cellRenderer: (params: EditableCallbackParams) => {
				return (
					<Grid
						container
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						className={classes.buttons}
					>
						<Grid
							item
							xs={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Button
								onClick={() => {
									(async () => {
										setBucketId(params.data.BucketId);
										setShowEditModal(true);
									})();
								}}
							>
								<EditIcon style={{ height: "auto" }} color="primary" />
							</Button>
							<Button
								onClick={() => {
									(async () => {
										setBucketId(params.data.BucketId);
										setShowDeleteModal(true);
									})();
								}}
							>
								<DeleteIcon style={{ height: "auto" }} color="secondary" />
							</Button>
							<Button
								style={{}}
								onClick={(e) =>
									showContextMenu(e, params.data, "catBucketsTable")
								}
							>
								<FormatListBulletedSharpIcon
									style={{ height: "auto" }}
									color="action"
								/>
							</Button>
						</Grid>
					</Grid>
				);
			},
			wrapText: false,
			autoHeight: false,
			wrapHeaderText: false,
			headerName: "",
			sortable: false,
			flex: 1,
			minWidth: 330,
			maxWidth: 380,
			filter: false,
			autoHeaderHeight: true,
		},
	]);

	return { rows, columnDefs };
};
