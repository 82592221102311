import { Grid } from "@material-ui/core";
import {
	CellEditingStartedEvent,
	CellEditingStoppedEvent,
	CellValueChangedEvent,
	GetContextMenuItemsParams,
	MenuItemDef,
} from "ag-grid-community";
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	ValueFormatterParams,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { Heat } from "../../../../meltshop/models/Heat";
import { CatalogGradeMechanicalLimit } from "../../../models/CatalogGradeMechanicalLimit";
import { CatalogMechanicalTest } from "../../../models/CatalogMechanicalTest";
import { CatalogResultSource } from "../../../models/CatalogResultSource";
import { MechanicalSample } from "../../../models/MechanicalSample";
import { useGridComplexStyles } from "../../../styles/gridStyles";

type Props = {
	loadingGrid: boolean;
	heat: Heat | null;
	mechanicalSamples: MechanicalSample[];
	gradeLimits: CatalogGradeMechanicalLimit[];
	resultSources: CatalogResultSource[];
	mechanicalTests: CatalogMechanicalTest[];
	updateEditing: (newValue: boolean) => void;
};
interface DynamicMechanicalSample extends MechanicalSample {
	[propertyName: string]: any;
}
export const TableMechanical = ({
	loadingGrid,
	heat,
	mechanicalSamples,
	gradeLimits,
	resultSources,
	mechanicalTests,
	updateEditing,
}: Props) => {
	//constants
	const classes = useGridComplexStyles();
	const gridRef = useRef<AgGridReact<DynamicMechanicalSample>>(null);
	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: any) => {},
	});
	const [resultSourceCodes, setResultSourceCodes] = useState<number[]>([]);
	const initialColumns: (ColDef | ColGroupDef)[] = [
		{
			headerName: "Sample",
			type: ["centerAligned"],
			children: [
				{
					field: "StatusCode",
					headerName: "St",
					minWidth: 40,
					maxWidth: 40,
					// tooltipComponent: CommentTooltip,
					tooltipField: "StatusCode",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "SampleSequence",
					headerName: "Sq",
					minWidth: 50,
					maxWidth: 50,
					type: ["numericColumn"],
					editable: true,
					//    tooltipComponent: CommentTooltip,
					tooltipField: "SampleSequence",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "HeatName",
					headerName: "Heat",
					minWidth: 150,
					maxWidth: 150,
					editable: true,
					// tooltipComponent: CommentTooltip,
					tooltipField: "HeatName",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "GradeName",
					headerName: "Grade",
					minWidth: 100,
					maxWidth: 100,
					// tooltipComponent: CommentTooltip,
					tooltipField: "HeatName",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "SectionName",
					headerName: "Section",
					minWidth: 100,
					maxWidth: 100,
					editable: true,
					// tooltipComponent: CommentTooltip,
					tooltipField: "SectionName",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "Diameter",
					headerName: "Diameter",
					minWidth: 100,
					maxWidth: 100,
					type: ["numericColumn"],
					editable: true,
					//    tooltipComponent: CommentTooltip,
					tooltipField: "Diameter",
					tooltipComponentParams: { color: "#ececec" },
					valueFormatter: (params) => {
						return numberRichFormatter(params, 4);
					},
					headerClass: "stripe-header",
				},
				{
					field: "Width",
					headerName: "Width",
					minWidth: 100,
					maxWidth: 100,
					type: ["numericColumn"],
					editable: true,
					//    tooltipComponent: CommentTooltip,
					tooltipField: "Width",
					tooltipComponentParams: { color: "#ececec" },
					valueFormatter: (params) => {
						return numberRichFormatter(params, 4);
					},
					headerClass: "stripe-header",
				},
				{
					field: "Thickness",
					headerName: "Thickness",
					minWidth: 100,
					maxWidth: 100,
					type: ["numericColumn"],
					editable: true,
					//    tooltipComponent: CommentTooltip,
					tooltipField: "Thickness",
					tooltipComponentParams: { color: "#ececec" },
					valueFormatter: (params) => {
						return numberRichFormatter(params, 4);
					},
					headerClass: "stripe-header",
				},
				{
					field: "ResultSourceId",
					headerName: "Source",
					minWidth: 150,
					maxWidth: 150,
					editable: true,
					cellEditor: "agSelectCellEditor",
					cellEditorParams: {
						values: resultSourceCodes,
					},
					filterParams: {
						valueFormatter: (params: ValueFormatterParams) => {
							return lookupResultSourceValue(resultSources, params.value);
						},
					},
					valueFormatter: (params) => {
						return lookupResultSourceValue(resultSources, params.value);
					},
					//tooltipComponent: CommentTooltip,
					tooltipField: "ResultSourceId",
					tooltipComponentParams: { color: "#ececec" },
				},
				{
					field: "Method",
					headerName: "Method",
					minWidth: 150,
					maxWidth: 150,
					editable: true,
					// tooltipComponent: CommentTooltip,
					tooltipField: "Method",
					tooltipComponentParams: { color: "#ececec" },
				},
			],
		},
	];
	const endColumns: (ColDef | ColGroupDef)[] = [
		{
			headerName: "Results",
			children: [
				{
					field: "DateTimeResults",
					headerName: "Result",
					minWidth: 150,
					editable: true,
				},
				{
					field: "Technician",
					headerName: "Technician",
					minWidth: 120,
					editable: true,
					//     tooltipComponent: CommentTooltip,
					tooltipField: "Technician",
					tooltipComponentParams: { color: "#ececec" },
				},
			],
		},
	];
	const lookupResultSourceValue = (
		resultSources: CatalogResultSource[],
		resultSourceId: string,
	) => {
		const id = isNaN(parseInt(resultSourceId)) ? 0 : parseInt(resultSourceId);
		const sampleObject = resultSources.find(
			(resultSource) => resultSource.Id === id,
		);
		if (sampleObject === undefined) return "";
		return sampleObject.Name;
	};
	const numberRichFormatter = (
		params: ValueFormatterParams,
		decimals: number,
	) => {
		if (params.value === null || isNaN(params.value)) return "";
		return parseFloat(params.value).toFixed(decimals);
	};
	const cellNumberClass = (params: CellClassParams) => {
		if (params.value === null || isNaN(params.value)) return "error-limit";
		const sample: DynamicMechanicalSample = params.data;
		if (sample === null || sample.Limits === undefined) return "error-limit";
		const limit = sample.Limits?.find(
			(limit) =>
				limit.MechanicalTestId === parseInt(params.colDef.field ?? "0"),
		);
		if (limit === undefined) return "error-limit2";
		const minLimit = limit.MinValue;
		const maxLimit = limit.MaxValue;
		return (minLimit !== null && parseFloat(params.value) < minLimit) ||
			(maxLimit !== null && parseFloat(params.value) > maxLimit)
			? "error-limit"
			: "error-limit2";
	};
	const getContextMenuItems = //useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const resultMenu: (string | MenuItemDef)[] = [];
			const data = params.node?.data ?? null;
			if (data === null || data === undefined) return resultMenu;
			if (data.isNew === true) {
				resultMenu.push({
					name: "Cancel",
					action: () => {
						// cancel_Sample();
					},
				});
				return resultMenu;
			}
			if (data.StatusCode === "V") {
				resultMenu.push({
					name: "Invalidate Sample",
					action: () => {
						//invalidate_Sample(params.node?.data);
					},
				});
			}
			if (
				data.Comments === null ||
				data.Comments === undefined ||
				data.Comments === ""
			) {
				resultMenu.push({
					name: "Add Comment",
					action: () => {
						//comment_Sample(params.node?.data);
					},
				});
			} else {
				resultMenu.push({
					name: "Change Comment",
					action: () => {
						//comment_Sample(params.node?.data);
					},
				});
			}
			resultMenu.push("separator");
			const canAdd = rows.find((x) => x.isNew === true) === undefined;
			if (canAdd) {
				resultMenu.push({
					name: "Add Sample",
					action: () => {
						//add_Sample();
					},
				});
			}
			resultMenu.push({
				name: "Delete Sample",
				action: () => {
					// delete_Sample(params.node?.data);
				},
			});
			return resultMenu;
		};
	//states
	const [rows, setRows] = useState<DynamicMechanicalSample[]>([]);
	const [columns, setColumns] = useState<ColDef[]>(
		initialColumns.concat(endColumns),
	);
	//events
	const startEditing = (params: CellEditingStartedEvent) => updateEditing(true);
	const stopEditing = (params: CellEditingStoppedEvent) => updateEditing(false);
	const valueChanged = (params: CellValueChangedEvent) => {
		save_Sample(params.data, params.column.getColId(), params.value);
	};
	//#  Functional Methods
	const save_Sample = async (
		sample: DynamicMechanicalSample,
		columnName: string,
		value: string,
	) => {};
	//memos
	const gridStyle = useMemo(() => {
		return {
			width: "100%",
			padding: "0px 15px 0px 0px",
			height: "auto",
		};
	}, [mechanicalSamples]);
	useMemo(() => {
		const newRows: DynamicMechanicalSample[] = [];
		mechanicalSamples.forEach((sample) => {
			const myObject: DynamicMechanicalSample = { ...sample };
			const samplesId = (sample.SamplesId ?? "").split(",");
			const samplesValue = (sample.SamplesValue ?? "").split(",");
			const samplesResult = (sample.ResultsId ?? "").split(",");
			myObject.elementParams = [];
			myObject.sampleParams = [
				"SampleSequence",
				"DateTimeResults",
				"StatusCode",
				"ResultSourceId",
				"Comments",
				"Technician",
				"Method",
				"HeatId",
				"Width",
				"Thickness",
				"Diameter",
			];
			//Setup Limits
			const limits = gradeLimits.filter(
				(limit) => limit.GradeId === sample.GradeId,
			);
			if (limits !== undefined) myObject.Limits = limits;

			//set with null mechanical test into the model
			mechanicalTests.forEach((testModel) => {
				myObject[String(testModel.Id).trim()] = null;
				myObject.elementParams.push(String(testModel.Id).trim());
			});
			let i;
			for (i = 0; i < samplesResult.length; i++) {
				if (!!isNaN(parseFloat(samplesValue[i]))) {
					myObject[String(samplesId[i]).trim()] = null;
				} else {
					myObject[String(samplesId[i]).trim()] = parseFloat(samplesValue[i]);
				}
			}
			newRows.push(myObject);
		});
		setRows(newRows);
	}, [mechanicalSamples, gradeLimits]);
	useMemo(() => {
		const newResultSourceCodes: number[] = [];
		resultSources
			.filter((resultSource) => resultSource.Enabled)
			.forEach((resultSource) => {
				newResultSourceCodes.push(resultSource.Id ?? 0);
			});
		setResultSourceCodes(newResultSourceCodes);

		const newColumns: ColDef[] = [];
		mechanicalTests
			.filter((mechanicalTest) => mechanicalTest.Enabled)
			.forEach((mechanicalTest) => {
				const column: ColDef = {
					field: mechanicalTest.Id?.toString(),
					headerName: mechanicalTest.Name,
					minWidth: 100,
					maxWidth: 100,
					type: ["numericColumn"],
					headerClass: mechanicalTest.UseInCircular ? "stripe-header" : "",
					valueFormatter: (params) => {
						return numberRichFormatter(params, mechanicalTest.Decimals);
					},
					cellClass: (params) => {
						return cellNumberClass(params);
					},
					editable: true,
				};
				newColumns.push(column);
			});
		const mechanicalColGroup: ColGroupDef[] = [
			{
				headerName: "Mechanical Tests",
				children: newColumns,
			},
		];
		setColumns(initialColumns.concat(mechanicalColGroup, endColumns));
	}, [mechanicalTests, resultSources]);

	useMemo(() => {
		if (loadingGrid && gridRef.current !== null) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);
	//effects
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			className={classes.root}
		>
			<Grid item style={gridStyle} xs={12} md={12}>
				<AgGridReact<DynamicMechanicalSample>
					ref={gridRef}
					className="ag-theme-alpine"
					rowSelection="single"
					pagination={false}
					animateRows={true}
					rowHeight={45}
					headerHeight={45}
					domLayout={"autoHeight"}
					singleClickEdit={true}
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					rowData={rows}
					columnDefs={columns}
					columnTypes={columnTypes}
					getContextMenuItems={getContextMenuItems}
					defaultColDef={columnSimpleDefaults}
					onCellEditingStarted={startEditing}
					onCellEditingStopped={stopEditing}
					onCellValueChanged={valueChanged}
				></AgGridReact>
			</Grid>
		</Grid>
	);
};
