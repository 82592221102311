import { createSlice } from "@reduxjs/toolkit";
import { CatalogBucket } from "../../models/CatalogBucket";

const BucketInitialState: {
	buckets: CatalogBucket[] | null;
} = {
	buckets: null,
};

export const BucketReducer = createSlice({
	name: "__",
	initialState: BucketInitialState,
	reducers: {
		setBuckets(state, { payload }) {
			state.buckets = payload;
		},
	},
});
