import { isNil } from "lodash-es";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { DelaysReportReducer } from "../reducers/DelaysReportReducer";

const DelaysReportContext = createContext({
	state: DelaysReportReducer.getInitialState(),
	actions: DelaysReportReducer.actions,
});

export const useDelaysReportContext = () => useContext(DelaysReportContext);

export const DelaysReportProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const { shiftId: shiftIdParam } = useParams();

	const [state, actions] = useComplexState({
		initialState: DelaysReportReducer.getInitialState(),
		reducers: DelaysReportReducer.caseReducers,
	});
	useEffect(() => {
		const shiftId = Number(shiftIdParam);
		if (isNil(shiftIdParam) || isNaN(shiftId)) {
			actions.setShiftId(null);
		} else {
			actions.setShiftId(shiftId);
		}
	}, [shiftIdParam]);
	return (
		<DelaysReportContext.Provider value={{ state, actions }}>
			{children}
		</DelaysReportContext.Provider>
	);
};
