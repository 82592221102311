import { makeStyles } from "@material-ui/core";

export const useEAFDETablesStyles = makeStyles((theme) => ({
	root: {
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 15,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-group-text ": {
			fontSize: 16,
			fontWeight: "bolder",
		},
		"& .ag-header-group-cell": {
			backgroundColor: "#f5f5f5",
		},
		"& .ag-header-cell": {
			fontSize: 14,
			fontWeight: "bold",
			color: "#74797d",
			backgroundColor: "#f5f5f5",
			borderLeft: "1px solid #ccc",
		},
		"& .ag-header-group-cell-label ": {
			textAlign: "center !important",
			justifyContent: "center",
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .info-limit": {
			color: "#000",
			backgroundColor: "#b2e5ed",
			fontWeight: 600,
		},
	},
	agGridBox: {
		border: "1px solid #ccc",
		borderRadius: "4px",
		padding: "10px 10px 50px 10px",
		minHeight: "350px",
		maxHeight: "350px", // 350px for 4 rows
	},
	middleTextfields: {
		border: "1px solid #ccc",
		borderRadius: "4px",
		padding: "10px 10px 50px 10px",
		minHeight: "473px",
		maxHeight: "475px",
	},
	dynamicHeight: {
		height: (props?: { rowCount?: number }) => {
			const rowCount = props?.rowCount ?? 4; // Default to 4 if rowCount is not provided
			return `${Math.min(350 + (rowCount - 4) * 50, 550)}px`;
		},
		// 350px base for 4 rows, 50px for each additional row, maximum 550px (for 8 rows)
	},
	readonlyAgGridBox: {
		border: "1px solid #ccc",
		borderRadius: "4px",
		padding: "10px ",
		height: "400px",
		minHeight: "400px",
	},
	manualAutoButton: {
		minWidth: "5px",
		padding: "6px 8px",
		cursor: "auto",
	},
	smAgGridBox: {
		border: "1px solid #ccc",
		borderRadius: "4px",
		padding: "10px 10px 10px 10px",
		height: "281px",
		minHeight: "350px",
	},
	// Notes
	outlinedTextarea: {
		width: "100%",
		padding: "10px 12px",
		border: `1px solid ${theme.palette.grey[400]}`,
		borderRadius: theme.shape.borderRadius,
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		"&:hover": {
			borderColor: theme.palette.text.primary,
		},
		"&:focus": {
			borderColor: theme.palette.primary.main,
			boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
			outline: "none",
		},
		"&::placeholder": {
			color: theme.palette.text.secondary,
			opacity: 1,
		},
	},
}));

export const useManualAutoButtonStyles = makeStyles((theme) => ({
	manualAutoButton: {
		minWidth: "5px",
		padding: "6px 8px",
		cursor: "auto",
	},
}));
