import { useContextMenuMESF } from "@dexteel/mesf-core";
import { Button, Grid } from "@material-ui/core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGridStyles } from "../../styles/gridStyles";
import { CreateHeatModal } from "./CreateModal/CreateHeatModal";
import { DeleteHeatModal } from "./DeleteModal/DeleteHeatModal";
import { EditHeatModal } from "./EditModal/EditHeatModal";
import { useHeatsOptionsFunctions } from "./hooks/useHeatsOptionsFunctions";
import { useTableData } from "./hooks/useTableData";

type Props = {
	isLoading: boolean;
	getHeatsByShift: Function;
};

export const HeatsByShiftTable = ({ isLoading, getHeatsByShift }: Props) => {
	const [modalEditHeat, setModalEditHeat] = useState(false);
	const [modalDeleteHeat, setModalDeleteHeat] = useState(false);
	const [modalCreateHeat, setModalCreateHeat] = useState(false);
	const [heatIdForEdit, setHeatIdForEdit] = useState<number | null>(null);
	const [heatIdForDelete, setHeatIdForDelete] = useState<number | null>(null);
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);
	const [gridColumnAPI, setGridColumnAPI] = useState<any>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const classes = useGridStyles();

	const { getMenuOptions, goToDataEntry, goToEAFDataEntry, goToHeatReport } =
		useHeatsOptionsFunctions({
			setHeatIdForEdit,
			setModalEditHeat,
			setHeatIdForDelete,
			setModalDeleteHeat,
			setModalCreateHeat,
		});
	const { showContextMenu, registerConfig } = useContextMenuMESF();

	const { rowData, columnData, defaultColDef } = useTableData({
		setHeatIdForEdit,
		setModalEditHeat,
		setHeatIdForDelete,
		setModalDeleteHeat,
		setModalCreateHeat,
		goToDataEntry,
		goToEAFDataEntry,
		goToHeatReport,
	});

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
		setGridColumnAPI(params.columnApi);
	};
	const onRowSelected = (event: any) => {
		if (event.node.selected)
			setSearchParams({ heatId: event.data.HeatId }, { replace: true });
	};

	const heatIdSearch = searchParams.get("heatId");

	useEffect(() => {
		if (gridAPI) {
			if (isLoading) gridAPI.showLoadingOverlay();
			else gridAPI.hideOverlay();
		}
	}, [gridAPI, isLoading]);

	useEffect(() => {
		if (!isLoading && gridAPI && rowData) {
			const heatId = heatIdSearch;

			if (heatId) {
				const node = gridAPI.getRowNode(heatId);
				if (node) node?.setSelected(true, true);
				else setSearchParams({}, { replace: true });
			}
		}
	}, [gridAPI, isLoading, rowData, heatIdSearch]);

	useEffect(() => {
		registerConfig({
			id: "heatsByShiftTable",
			getOptions: getMenuOptions,
		});
	}, []);

	return (
		<Grid container className={classes.root}>
			<Grid
				item
				md={12}
				xs={12}
				style={{
					height: "calc(100vh - 300px)",
					minHeight: "calc(100vh - 300px)",
					border: "4px solid #ccc",
					borderRadius: 10,
					padding: 10,
					margin: "20px 0 10px",
				}}
				className={"ag-theme-balham"}
			>
				<DeleteHeatModal
					heatIdForDelete={heatIdForDelete}
					show={modalDeleteHeat}
					onHide={(shouldUpdate: boolean) => {
						setModalDeleteHeat(false);
						if (shouldUpdate) getHeatsByShift().then(() => {});
					}}
				/>{" "}
				<EditHeatModal
					heatIdForEdit={heatIdForEdit}
					show={modalEditHeat}
					onHide={(shouldUpdate: boolean) => {
						setModalEditHeat(false);
						if (shouldUpdate) getHeatsByShift().then(() => {});
					}}
				/>
				<CreateHeatModal
					show={modalCreateHeat}
					onHide={(shouldUpdate: boolean) => {
						setModalCreateHeat(false);
						if (shouldUpdate) getHeatsByShift().then(() => {});
					}}
				/>
				<AgGridReact
					defaultColDef={defaultColDef}
					onGridReady={onGridReady}
					rowHeight={35}
					rowData={rowData}
					columnDefs={columnData}
					rowSelection="single"
					overlayLoadingTemplate={"Loading..."}
					suppressContextMenu={true}
					getRowId={(params) => params.data.HeatId}
					onRowDoubleClicked={(event: any) => {
						goToDataEntry(event.data.HeatNameLink);
					}}
					onCellContextMenu={(event) => {
						if (event.event)
							showContextMenu(
								event.event as any,
								event.data,
								"heatsByShiftTable",
							);
					}}
					onRowSelected={onRowSelected}
				/>
				<Grid container justifyContent="flex-end">
					<Grid item md={2} xs={12} className={classes.btnModal}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setModalCreateHeat(!modalCreateHeat)}
							style={{ margin: "3rem 0px 0px" }}
						>
							New Heat
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
