export const calculateWeightPerPiece = (
	weightNum: number | string,
	piecesNum: number | string,
) => {
	if (typeof weightNum === "string") {
		weightNum = parseFloat(weightNum);
	}
	if (typeof piecesNum === "string") {
		piecesNum = parseFloat(piecesNum);
	}
	if (!isNaN(weightNum) && !isNaN(piecesNum) && piecesNum !== 0) {
		return (weightNum / piecesNum).toFixed(2);
	}
	return "";
};
