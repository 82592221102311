import React from "react";
import {
	calculateLevels,
	calculateTransposedLevels,
} from "../../helpers/table-helper";
import { BodyCellSetup } from "../../types/table-body";
import { HeaderCellSetup } from "../../types/table-header";
import { ReportBodyRow } from "./body-row";
import { ReportBodyTransposedRow } from "./body-transposed-row";

export type ReportBodyProps = {
	headers: HeaderCellSetup[];
	cells: BodyCellSetup[];
	rows: any[];
	transposed: boolean;
	cellClass: string;
	onRowClick?: (data: any) => void;
};

export const ReportBody: React.FC<ReportBodyProps> = ({
	headers,
	cells,
	rows,
	transposed,
	cellClass,
	onRowClick,
}) => {
	const getTransposedRow = (
		headerCells: HeaderCellSetup[],
		totalLevels: number,
		level: number,
		rowResult: React.ReactNode[],
	) => {
		level++;
		headerCells.forEach((headerCell) => {
			const levels = calculateLevels(headerCell.children || []);
			const colSpan = totalLevels - level - levels + 1;
			let rowSpan = calculateTransposedLevels(headerCell.children || []);
			rowSpan = rowSpan === 0 ? 1 : rowSpan;
			rowResult.push(
				<ReportBodyTransposedRow
					header={headerCell}
					rows={rows}
					rowSpan={rowSpan}
					colSpan={colSpan}
					key={rowResult.length + 1}
					level={level}
					bodyCells={cells}
				/>,
			);
			const tempChildren: HeaderCellSetup[] = [];
			for (
				let index = level;
				index < (headerCell.children || []).length;
				index++
			) {
				tempChildren.push((headerCell.children || [])[index]);
			}
			if (tempChildren.length > 0)
				getTransposedRow(tempChildren, totalLevels, level, rowResult);
		});
	};
	if (transposed) {
		const bodyContent: React.ReactNode[] = [];
		const totalLevels = calculateLevels(headers);
		getTransposedRow(headers, totalLevels, 0, bodyContent);
		return <tbody>{bodyContent}</tbody>;
	} else {
		const bodyContent = rows.map((row, index) => {
			const reportBodyRowProps = {
				cells,
				row: row,
				cellClass,
				onRowClick,
			};
			return <ReportBodyRow key={index} {...reportBodyRowProps} />;
		});
		return <tbody>{bodyContent}</tbody>;
	}
};
