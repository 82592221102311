import { ErrorModal } from "@dexteel/mesf-core";
import { TextField } from "@material-ui/core";
import {
	Autocomplete,
	AutocompleteChangeDetails,
	AutocompleteChangeReason,
} from "@material-ui/lab";
import { ChangeEvent, useEffect, useState } from "react";
import { LazyLoading } from "../../../../../../../controls/LazyLoading";
import { getMoldSizes } from "../../../../../repositories/ProductionRepository";

interface MoldSize {
	MoldSizeId: number | null;
	MoldSizeName: string;
}

type Props = {
	onChange: (value: number) => void;
	value: number | null;
	label?: boolean;
	className?: string;
};

export const MoldSizeSelector = ({
	onChange,
	value,
	label,
	className,
}: Props) => {
	const [moldSizes, setMoldSizes] = useState<MoldSize[]>([]);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [selectedMoldSize, setSelectedMoldSize] = useState<MoldSize>();

	const handleChange = (
		event: ChangeEvent<{}>,
		value: MoldSize | null,
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<MoldSize> | undefined,
	) => {
		if (value && value.MoldSizeId) onChange(value.MoldSizeId);
	};

	const loadData = async () => {
		setIsLoading(true);

		const responseMoldSizes = await getMoldSizes();
		if (responseMoldSizes.ok) {
			setMoldSizes(responseMoldSizes.data.tables[0].rows);
			setSelectedMoldSize(
				optionsWithAll.find((moldSize) => moldSize.MoldSizeId === value),
			);
		} else setError(responseMoldSizes.message);
		setIsLoading(false);
	};

	const optionsWithAll: MoldSize[] = [
		{ MoldSizeId: null, MoldSizeName: "" },
		...moldSizes,
	];

	useEffect(() => {
		if (value) {
			const selectedMoldSize = optionsWithAll.find(
				(moldSize) => moldSize.MoldSizeId === value,
			);
			setSelectedMoldSize(selectedMoldSize);
		}
	}, [value, moldSizes]);

	useEffect(() => {
		loadData();
	}, []);

	if (isLoading) return <LazyLoading />;

	return (
		<>
			<Autocomplete
				id="mold-size-selector"
				value={selectedMoldSize}
				onChange={handleChange}
				options={optionsWithAll}
				getOptionLabel={(moldSize) => moldSize.MoldSizeName}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label ? "MoldSize" : ""}
						variant="outlined"
						className={className}
					/>
				)}
				style={{ minWidth: 200 }}
				size="small"
			/>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
