import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { GradesReducer } from "../reducers/GradesReducer";

const GradesContext = createContext({
	state: GradesReducer.getInitialState(),
	actions: GradesReducer.actions,
});

export const useGradeContext = () => useContext(GradesContext);

export const GradesProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: GradesReducer.getInitialState(),
		reducers: GradesReducer.caseReducers,
	});

	return (
		<GradesContext.Provider value={{ state, actions }}>
			{children}
		</GradesContext.Provider>
	);
};
