import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogResultSource } from "../models/CatalogResultSource";

export const getCatalogResultSources = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[LAB].[GetCatResultSources]", []);
};
export const upsertCatalogResultSource = async (
	resultSource: CatalogResultSource,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: resultSource.ResultSourceId });
	parameters.push({ name: "Name", value: resultSource.ResultSourceName });
	parameters.push({ name: "Enabled", value: resultSource.IsActive });

	return await apiService.callV2("[LAB].[UpsertCatResultSource]", parameters);
};
export const getCatalogResultSource = async (ResultSourceId: number | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ResultSourceId", value: ResultSourceId });

	return await apiService.callV2("[LAB].[GetCatResultSource]", parameters);
};
