import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { CatalogBucket } from "../../../models/CatalogBucket";

type Props = {
	setBucketId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	setShowCreateModal: Function;
};

export const useBucketsOptionsFunctions = ({
	setBucketId,
	setShowEditModal,
	setShowDeleteModal,
	setShowCreateModal,
}: Props) => {
	const getMenuOptions = (data: CatalogBucket) => {
		let options: MenuOptionType[] = [];
		if (!data) {
			options = options.concat([
				{
					name: "New Bucket",
					key: "new_bucket",
					onClick: () => {
						setShowCreateModal(true);
					},
					icon: <PlaylistAddIcon />,
				},
			]);
		} else {
			options = options.concat([
				{
					name: "New Bucket",
					key: "new_bucket",
					onClick: () => {
						setShowCreateModal(true);
					},
					icon: <PlaylistAddIcon />,
				},
				{
					name: "Edit Bucket",
					key: "edit_bucket",
					onClick: () => {
						setBucketId(data.BucketId);
						setShowEditModal(true);
					},
					icon: <EditIcon />,
				},
				{
					name: "Delete Bucket",
					key: "delete_bucket",
					onClick: () => {
						setBucketId(data.BucketId);
						setShowDeleteModal(true);
					},
					icon: <DeleteIcon />,
				},
			]);
		}
		return options;
	};

	return {
		getMenuOptions,
	};
};
