import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	dxtToLocalServerTime,
	getMomentTz,
} from "@dexteel/mesf-core";
import { Autocomplete } from "@material-ui/lab";
import { isNil } from "lodash-es";
import React, { ChangeEvent, useEffect, useState } from "react";
import { UnLinkedHeat } from "../../../caster/models/UnLinkedHeat";
import { useEAFProcessesContext } from "../context/EAFProcessesContext";
import {
	linkEAFProcess,
	searchUnlinkedHeats,
} from "../repositories/EAFRepository";

const moment = getMomentTz();

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	refreshData: Function;
};
export const LinkEAFProcess = ({ show, onHide, refreshData }: Props) => {
	const { state, actions } = useEAFProcessesContext();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [unlinkedHeats, setUnLinkedHeats] = useState<UnLinkedHeat[]>([]);
	const [selectedHeat, setSelectedHeat] = useState<any>(null);
	const [searchText, setSearchText] = useState("");
	const [error, setError] = useState("");
	const [showWarningModal, setShowWarningModal] = useState(false);

	const process = state.selectedProcess;

	const onSubmit = async (data: any) => {
		if (!process) return;
		try {
			if (selectedHeat === null) {
				setError("Please select a heat");
				return;
			}
			if (isNil(process)) return;
			if (selectedHeat.IsLinked === 1) {
				setShowWarningModal(true);
			} else {
				await proceedWithLinking();
			}
		} catch (e: any) {
			setError(e.message);
		}
	};

	const proceedWithLinking = async () => {
		try {
			setIsSubmitLoading(true);
			const response = await linkEAFProcess(
				process?.ProcessId as number,
				selectedHeat.HeatId,
			);
			if (response.ok) {
				actions.setNotificationMessage("Process linked successfully");
				await refreshData();
			} else {
				setError(response.message);
			}
		} catch (e: any) {
			setError(e.message);
		} finally {
			setIsSubmitLoading(false);
			setShowWarningModal(false);
			onHide(true);
		}
	};

	const handleSearchChange = async () => {
		if (!process) return;
		const heats = await searchUnlinkedHeats(
			searchText,
			process.Start as Date,
			process.AssetId,
		);
		if (heats.ok && heats.data?.tables?.[0]?.rows) {
			setUnLinkedHeats(heats.data.tables[0].rows);
		}
	};

	const handleInputChange = (event: ChangeEvent<{}>, value: string) => {
		setSearchText(value);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleSearchChange();
		}
	};

	const handleChange = (
		event: ChangeEvent<{}>,
		newValue: UnLinkedHeat | null,
	) => {
		setSelectedHeat(newValue as any);
	};

	useEffect(() => {
		if (show && process) {
			setIsSubmitLoading(false);
			(async () => {
				setSelectedHeat(null);
				setTitle(
					`Link EAF Process / Start: ${dxtToLocalServerTime(
						`${process.Start}`,
						"HH:mm",
					)}
					 Heat: ${process.HeatName ? process.HeatName : ""}`,
				);
				const heats = await searchUnlinkedHeats(
					"",
					process.Start as Date,
					process.AssetId,
				);
				if (
					heats.ok &&
					heats.data &&
					heats.data.tables &&
					heats.data.tables[0].rows
				) {
					setUnLinkedHeats(heats.data.tables[0].rows);
				}
			})();
		}
	}, [show, process]);

	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						maxWidth="xl"
						open={show}
						handleClose={() => {
							onHide(true);
						}}
						id="linkCasterProcess"
						title={title}
					>
						<form>
							<MesfModal.Content
								style={{
									padding: "15px 30px",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
								}}
							>
								<Grid container spacing={1} alignItems="center">
									<Grid item md={2}>
										<Typography
											style={{
												margin: 0,
												padding: 0,
												display: "flex",
												alignItems: "center",
												height: "56px",
											}}
											variant="h6"
										>
											To Heat
										</Typography>
									</Grid>
									<Grid item md={10}>
										<Autocomplete
											options={unlinkedHeats}
											getOptionLabel={(option) => option.HeatName}
											value={selectedHeat}
											onChange={handleChange}
											inputValue={searchText}
											onInputChange={handleInputChange}
											renderOption={(option) => (
												<div style={{ display: "flex", alignItems: "center" }}>
													<span>{option.HeatName}</span>
													{option.IsLinked === 1 && (
														<span style={{ color: "red", marginLeft: "10px" }}>
															(linked)
														</span>
													)}
												</div>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label={searchText ? "Heat" : "Search Heat"}
													placeholder="Search Heat"
													variant="outlined"
													fullWidth
													onKeyDown={handleKeyDown}
													InputLabelProps={{
														style: {
															height: "auto",
															marginTop: 5,
															display: "flex",
															alignItems: "center",
														},
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</MesfModal.Content>

							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(true)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<ButtonWithLoading
											fullWidth
											isLoading={isSubmitLoading}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											onClick={onSubmit}
										>
											Save
										</ButtonWithLoading>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
				</Grid>
			</Grid>

			<MesfModal
				open={showWarningModal}
				handleClose={() => setShowWarningModal(false)}
				title="Warning"
				id="warningModal"
			>
				<MesfModal.Content>
					<p>
						This heat is already linked in another process. Do you want to
						proceed?
					</p>
				</MesfModal.Content>
				<MesfModal.Actions>
					<Button
						fullWidth
						variant="contained"
						color="default"
						onClick={() => setShowWarningModal(false)}
					>
						Cancel
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={proceedWithLinking}
					>
						Proceed
					</Button>
				</MesfModal.Actions>
			</MesfModal>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
