import React from "react";
import { DataTypeField } from "../../types/public-types";
import { HeaderCellSetup } from "../../types/table-header";

export type ReportHeaderCellProps = {
	cell: HeaderCellSetup;
	colSpan: number;
	rowSpan: number;
	autoAlignNumber: boolean;
};

export const ReportHeaderCellRow: React.FC<ReportHeaderCellProps> = ({
	cell,
	colSpan,
	rowSpan,
	autoAlignNumber,
}) => {
	const cellClass: string[] = [];
	const cellStyle: any = {};
	//Class
	switch (cell.Type) {
		case DataTypeField.Number:
			if (autoAlignNumber) cellClass.push("text-right");
			else cellClass.push("text-center");
			break;
		default:
			cellClass.push("text-center");
			break;
	}
	//Style
	if (cell.Width !== "") cellStyle["minWidth"] = cell.Width;
	return (
		<th
			colSpan={colSpan}
			rowSpan={rowSpan}
			className={colSpan > 1 ? "text-center" : cellClass.join(" ")}
			style={cellStyle}
		>
			<span onClick={() => {}}>{cell.Name}</span>
		</th>
	);
};
