import { Button, CircularProgress, Grid } from "@material-ui/core";
type Props = {
	isSubmitLoading: boolean;
	onHide: (proceed: boolean) => void;
	buttonColor?: "default" | "inherit" | "primary" | "secondary" | undefined;
	buttonLabel?: "Delete" | "Save";
	className?: string;
};
export const FooterModalButtons = ({
	isSubmitLoading,
	onHide,
	buttonColor = "primary",
	buttonLabel = "Save",
	className,
}: Props) => {
	return (
		<Grid container spacing={2} justifyContent="flex-end">
			<Grid item md={3} xs={12} style={{ margin: 0 }}>
				<Button
					fullWidth
					variant="contained"
					color="default"
					onClick={() => onHide(false)}
					className={className}
				>
					Cancel
				</Button>
			</Grid>
			<Grid item md={3} xs={12} style={{ margin: 0 }}>
				<Button
					fullWidth
					startIcon={isSubmitLoading && <CircularProgress size="1rem" />}
					disabled={isSubmitLoading}
					variant="contained"
					color={buttonColor}
					type="submit"
					className={className}
				>
					{buttonLabel}
				</Button>
			</Grid>
		</Grid>
	);
};
