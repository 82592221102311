import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { AutoCompleteProps, ItemOption } from "../types/public-types";

export const AutoComplete: React.FunctionComponent<AutoCompleteProps> = ({
	items,
	minSearchTextLength = 2,
	defaultText = "Select an option",
	noOptionsText = "No options",
	autoHighlight = true,
	width = 300,
	color = "black",
	containerPadding = "0px",
	onTextSearch,
	onChangeValue,
}) => {
	const [textSearch, setTextSearch] = useState("");
	const [itemValue, setItemValue] = useState<ItemOption | null>(null);
	const [isTyping, setIsTyping] = useState(false);
	useEffect(() => {}, []);
	useEffect(() => {
		if (textSearch.length <= minSearchTextLength) {
			return;
		}
		if (onTextSearch) {
			onTextSearch(textSearch);
			setIsTyping(true);
		}
	}, [textSearch]);
	useEffect(() => {
		if (onChangeValue) onChangeValue(itemValue);
		setIsTyping(false);
	}, [itemValue]);
	useEffect(() => {
		if (!isTyping && items.length > 0) {
			setItemValue(items[0]);
		}
		if (items.length === 0) {
			setItemValue(null);
			setTextSearch("");
		}
	}, [items]);
	return (
		<div
			style={{
				padding: containerPadding,
			}}
		>
			<Autocomplete
				renderOption={(option: ItemOption) => (
					<Typography noWrap style={{ color }}>
						{option.name}
					</Typography>
				)}
				getOptionLabel={(option) => `${option.name}`}
				renderInput={(params) => (
					<TextField
						{...params}
						value={textSearch}
						label={defaultText}
						onChange={(e) => setTextSearch(e.target.value)}
						InputProps={{
							...params.InputProps,
							style: { color },
						}}
					/>
				)}
				options={items}
				noOptionsText={noOptionsText}
				value={itemValue}
				onChange={(e, value) => setItemValue(value)}
				style={{ width: width }}
				autoHighlight={autoHighlight}
			/>
		</div>
	);
};
