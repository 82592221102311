import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Process } from "../../../models/eaf.models";
import { DateTimePickerDE } from "../common/DateTimePickerDE";

export type StringFields<T> = {
	[K in keyof T]: T[K] extends Date | null ? K : never;
}[keyof T];

type ProcessDateTimeFieldDEProps = {
	field: StringFields<Process>;
	disableOpenPicker?: boolean;
	disabled?: boolean;
	label?: string;
	format?: string;
};
export const ProcessDateTimeFieldDE = ({
	field,
	label,
	format = "HH:mm",
	disabled = false,
	disableOpenPicker = true,
}: ProcessDateTimeFieldDEProps) => {
	const {
		state: { deProcess },
		actions,
	} = useDataEntryContext();

	const { saveProcess } = useEAFDataEntryActions();
	const process = deProcess?.process;

	const onTextValueChange = () => {
		saveProcess(process!);
	};

	return (
		<DateTimePickerDE
			disableOpenPicker={disableOpenPicker}
			disabled={disabled}
			format={format}
			label={label || field}
			value={process ? process![field] : null}
			onChange={(value) => {
				actions.setProcess({
					...process,
					[field]: value,
				});
			}}
			onBlur={() => {
				onTextValueChange();
			}}
		/>
	);
};
