import { Button, Grid, Theme, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { ColDef, EditableCallbackParams } from "ag-grid-community";
import { CatalogCategory } from "../../models/CatalogCategory";
import ColorCircleRenderer from "../components/ColorPicker/ColorCircleRendered";
import { useCatalogCategoryContext } from "../context/CategoriesContext";

const useStyles = makeStyles((theme: Theme) => ({
	buttons: {
		opacity: 0,
		transition: "opacity 0.1s ease-in-out",
		".ag-row-hover &": {
			opacity: 1,
		},
		".ag-row-focus &": {
			opacity: 1,
		},
		".ag-row-selected &": {
			opacity: 1,
		},
	},
}));

type Props = {
	setCategoryId: Function;
	setShowEditModal: Function;
	setShowDeleteModal: Function;
	showContextMenu: Function;
};

export const useTableData = ({
	setCategoryId,
	setShowEditModal,
	setShowDeleteModal,
	showContextMenu,
}: Props) => {
	const { state } = useCatalogCategoryContext();
	const classes = useStyles();

	const rows: CatalogCategory[] =
		state.categories?.map((category) => ({
			...category,
		})) || [];

	const columnDefs: ColDef[] = [
		{
			field: "DelayCategoryName",
			headerName: "Name",
			flex: 1,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			minWidth: 130,
		},
		{
			field: "AssetName",
			headerName: "Asset",
			flex: 1,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			minWidth: 130,
		},
		{
			field: "DelayCategoryColor",
			headerName: "Color",
			sortable: false,
			flex: 1,
			cellRenderer: ColorCircleRenderer,
			cellStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			minWidth: 130,
		},
		{
			cellRenderer: (params: EditableCallbackParams) => {
				return (
					<Grid
						container
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						className={classes.buttons}
					>
						<Grid
							item
							xs={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Button
								onClick={() => {
									(async () => {
										setCategoryId(params.data.DelayCategoryId);
										setShowEditModal(true);
									})();
								}}
							>
								<EditIcon style={{ height: "auto" }} color="primary" />
							</Button>
							<Button
								onClick={() => {
									(async () => {
										setCategoryId(params.data.DelayCategoryId);
										setShowDeleteModal(true);
									})();
								}}
							>
								<DeleteIcon style={{ height: "auto" }} color="secondary" />
							</Button>
							<Button
								style={{}}
								onClick={(e) =>
									showContextMenu(e, params.data, "categoriesContext")
								}
							>
								<FormatListBulletedSharpIcon
									style={{ height: "auto" }}
									color="action"
								/>
							</Button>
						</Grid>
					</Grid>
				);
			},
			wrapText: false,
			autoHeight: false,
			wrapHeaderText: false,
			headerName: "",
			sortable: false,
			flex: 1,
			minWidth: 330,
			filter: false,
			autoHeaderHeight: true,
		},
	];

	return { rows, columnDefs };
};
