import { useState } from "react";
import {
	ReadStrandDataFromPLC,
	readProbesFromPLC,
} from "../../repositories/ProductionRepository";
import { useCastersContext } from "../CastersContext";

const useReadPLC = () => {
	const [error, setError] = useState<string | null>();
	const [sucess, setSucess] = useState<string | null>(null);
	const { actions } = useCastersContext();

	const readStrand = async (processId: number | null, strand: number) => {
		if (processId === null || strand <= 0) return;
		setSucess(null);
		setError(null);
		actions.setIsLoadingBackground(true);
		const resp = await ReadStrandDataFromPLC(processId, strand, true);
		actions.setIsLoadingBackground(false);
		if (resp.ok) setSucess("PLC was read successfully");
		else setError(resp.message);
	};
	const readProbe = async (processId: number | null, probeId: number) => {
		if (processId === null || probeId <= 0) return;
		setSucess(null);
		setError(null);
		actions.setIsLoadingBackground(true);
		const resp = await readProbesFromPLC(processId, true);
		actions.setIsLoadingBackground(false);
		if (resp.ok) setSucess("PLC was read successfully");
		else setError(resp.message);
	};
	return { readStrand, readProbe, error, sucess };
};
export { useReadPLC };
