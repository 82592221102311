import {
	ErrorModal,
	MesfModal,
	ResponseMESF,
	dxtToLocalServerTime,
	getMomentTz,
} from "@dexteel/mesf-core";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { DeliveryTicket } from "../../../../models/DeliveryTicket";
import {
	getDeliveryTicket,
	getReadProductBatches,
	upsertProductBatch,
} from "../../../../repositories/ProductionRepository";
import { useModalStyles } from "../../../../styles/modalStyles";
import { useCastersContext } from "../../../CastersContext";
import { ProcessData } from "./ProcessData";
import { ProductBatchSection } from "./ProductBatch/ProductBatch";

interface Props {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	processId: number | null;
}

const moment = getMomentTz();

export const DeliveryTicketModal = ({ show, onHide, processId }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [deliveryTicket, setDeliveryTicket] = useState<DeliveryTicket | null>(
		null,
	);

	const { state, actions } = useCastersContext();
	const classes = useModalStyles();

	const [errors, setErrors] = useState<Map<string, string>>(new Map());

	const handleHeatInputChange = (field: string, value: any) => {
		setDeliveryTicket((prevState) => ({
			...prevState!,
			heatData: {
				...prevState!.heatData,
				[field]: value,
			},
		}));
	};

	const handleProductBatchChange = (
		index: number,
		key: string,
		value: string,
	) => {
		setDeliveryTicket((prevState) => {
			if (!prevState) return null;

			if (key === "updateAllBatches") {
				return {
					...prevState,
					productBatches: JSON.parse(value),
				};
			}

			const updatedBatches = [...prevState.productBatches];
			if (index >= 0) {
				if (key === "Length") {
					updatedBatches[index] = {
						...updatedBatches[index],
						[key]: Number(value),
					};
				} else {
					updatedBatches[index] = {
						...updatedBatches[index],
						[key]: value,
					};
				}
			}

			return {
				...prevState,
				productBatches: updatedBatches,
			};
		});
	};
	const dateTimeFormatted = useMemo(() => {
		let castDateFormatted;
		let tapTime;

		//Cast date
		if (deliveryTicket?.heatData.CastDate)
			castDateFormatted = moment
				.utc(deliveryTicket?.heatData.CastDate)
				.format("YYYY-MM-DD");

		//Tap time
		if (moment.utc(deliveryTicket?.heatData.TapTime).isValid())
			tapTime = dxtToLocalServerTime(
				moment.utc(deliveryTicket?.heatData.TapTime).toDate(),
				"HH:mm",
			);
		else tapTime = deliveryTicket?.heatData.TapTime;

		return { castDateFormatted, tapTime };
	}, [deliveryTicket?.heatData.TapTime, deliveryTicket?.heatData.CastDate]);

	const handleClickPrint = async () => {
		if (errors.size > 0) {
			setError("Please check all the fields");
			return;
		}
		let hasSectionError = false;
		deliveryTicket?.productBatches.forEach((batch) => {
			if (batch.SectionId == 0) {
				setErrors((prev) => {
					const newErrors = new Map(prev);
					newErrors.set(`Section-${batch.ProductBatchId}`, "Please select one");
					return newErrors;
				});
				hasSectionError = true;
			}
		});

		if (hasSectionError) {
			return;
		}

		if (deliveryTicket && deliveryTicket.productBatches.length) {
			const totalPieces = deliveryTicket.productBatches.reduce(
				(acc, batch) => acc + batch.Pieces,
				0,
			);
			if (!totalPieces) {
				setError("Please add at least one piece of each");
				return;
			}
		}

		setIsLoading(true);

		if (deliveryTicket && deliveryTicket.productBatches && state.processId) {
			try {
				const promises = deliveryTicket.productBatches.map((batch) =>
					upsertProductBatch(
						batch.ProductBatchId < 0 ? null : batch.ProductBatchId,
						state.processId as number,
						batch.Length,
						batch.SectionId,
						batch.Pieces,
						batch.Weight,
					),
				);

				const results = await Promise.all<ResponseMESF>(promises);

				const errors = results
					.filter((resp) => !resp.ok)
					.map((resp) => (resp as { ok: false; message: string }).message);

				await loadData();
				if (errors.length > 0) {
					setError(errors.join(", "));
				} else {
					window.open(
						`/delivery-ticket/${state.processId}`,
						"_blank",
						"noopener,noreferrer",
					);
				}
			} catch (error) {
				setError("An unexpected error occurred");
			}
		}

		setIsLoading(false);
	};

	const loadData = async () => {
		setIsLoading(true);
		if (!processId) {
			setError("No process id provided.");
		} else {
			const resp = await getDeliveryTicket(processId);
			if (resp.ok) {
				const [heatTable, productBatchesTable] = resp.data.tables;
				setDeliveryTicket({
					heatData: heatTable.rows[0],
					productBatches: productBatchesTable.rows,
				});
			} else setError(resp.message);
		}
		setIsLoading(false);
	};
	const refreshProductBatches = async () => {
		setIsLoading(true);
		if (!processId) {
			setError("No process id provided.");
		} else {
			const productBatchesResponse = await getReadProductBatches(processId);
			if (!productBatchesResponse.ok) setError(productBatchesResponse.message);
		}
		loadData();
	};

	useEffect(() => {
		if (show) {
			loadData();
		}
	}, [show]);
	return (
		<>
			<MesfModal
				open={show}
				handleClose={() => {
					onHide(false);
				}}
				id="delivery-ticket-modal"
				maxWidth="md"
			>
				{isLoading ? (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						style={{ padding: "560px 450px" }}
					>
						<CircularProgress size="5rem" />
					</Grid>
				) : (
					<>
						<MesfModal.Content
							className={`${classes.modalContent} ${classes.pageBreakAvoid}`}
							style={{
								border: "4px solid #ccc",
								borderRadius: 10,
								margin: "10px 20px",
							}}
						>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								direction="column"
								spacing={1}
								style={{ marginBottom: "30px" }}
							>
								<Grid item>
									<Typography
										variant="h6"
										align="center"
										style={{ fontWeight: "bold" }}
									>
										STEEL OF WEST VIRGINIA
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant="h6"
										align="center"
										style={{ fontWeight: "bold" }}
									>
										MELTSHOP DELIVERY TICKET
									</Typography>
								</Grid>
							</Grid>
							<form noValidate autoComplete="off">
								<Grid container spacing={2} justifyContent="center">
									{/* Campos superiores */}
									<ProcessData
										dateTimeFormatted={dateTimeFormatted}
										deliveryTicket={deliveryTicket}
										handleHeatInputChange={handleHeatInputChange}
									/>

									{/* Sección inferior para los strands */}
									<ProductBatchSection
										errors={errors}
										setErrors={setErrors}
										deliveryTicket={deliveryTicket}
										handleProductBatchChange={handleProductBatchChange}
										refreshProductBatches={refreshProductBatches}
										isLoading={isLoading}
									/>
								</Grid>
							</form>
						</MesfModal.Content>

						<MesfModal.Actions
							className={classes.noPrint}
							style={{ padding: "1rem 3rem" }}
						>
							<Grid container spacing={2} justifyContent="flex-end">
								<Grid item md={3} xs={3} style={{ margin: 0 }}>
									<Button
										fullWidth
										variant="contained"
										color="default"
										onClick={() => {
											actions.setRefreshInPause(false);
											errors.clear();
											onHide(true);
										}}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item md={3} xs={3} style={{ margin: 0 }}>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										onClick={handleClickPrint}
									>
										Save & print
									</Button>
								</Grid>
							</Grid>
						</MesfModal.Actions>
					</>
				)}
			</MesfModal>
			<ErrorModal
				error={error}
				onHide={() => {
					setError("");
				}}
			/>
		</>
	);
};
