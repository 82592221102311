import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogMoldReducer } from "./CatalogMoldReducer";

const CatalogMoldContext = createContext({
	state: CatalogMoldReducer.getInitialState(),
	actions: CatalogMoldReducer.actions,
});

export const useCatalogMoldContext = () => useContext(CatalogMoldContext);

export const CatalogMoldProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogMoldReducer.getInitialState(),
		reducers: CatalogMoldReducer.caseReducers,
	});
	return (
		<CatalogMoldContext.Provider value={{ state, actions }}>
			{children}
		</CatalogMoldContext.Provider>
	);
};
