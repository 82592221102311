import { CatalogLadleProvider } from "./context/CatalogLadleContext";
import { CatalogLadlePage } from "./pages/CatalogLadlePage";

type Props = {};
const CatalogLadle = (props: Props) => {
	return (
		<CatalogLadleProvider>
			<CatalogLadlePage />
		</CatalogLadleProvider>
	);
};
export default CatalogLadle;
