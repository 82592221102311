import { useUTLSettingsContext } from "@dexteel/mesf-core";
import { Box, Paper } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import { useState } from "react";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useTimerContext } from "../../../context/timer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { CreatePourback } from "../../modals/pourback/CreatePourback";
import { EditPourback } from "../../modals/pourback/EditPourback";
import { AgGridFooterActions } from "../components/AgGridFooterActions";
import { useEAFDETablesStyles } from "../styles/useEAFDETablesStyles";
import { useTableData } from "../table-pourbacks/hooks/usePourbacksDataTable";

export const TablePourbacks = () => {
	const { state } = useUTLSettingsContext();
	const MAX_POURBACKS = get(state.settings, "MAX_POURBACKS", 10);

	const {
		state: { pourbacks },
	} = useDataEntryContext();

	const { pause, resume } = useTimerContext();

	const { deletePourbackCharge, refreshData } = useEAFDataEntryActions();
	const [selectedPourback, setSelectedPourback] = useState<any>();

	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);

	const rowCount = pourbacks ? pourbacks.length : 0;
	const hasMaxPourbacks =
		(pourbacks && pourbacks.length >= MAX_POURBACKS) || false;

	const classes = useEAFDETablesStyles({ rowCount });
	const editPourback = (PourbackId: number) => {
		pause();
		const pourback = pourbacks?.find((p) => p.PourbackId === PourbackId);
		setSelectedPourback(pourback);
		setShowEditModal(true);
	};
	const { columns, defaultColDef } = useTableData({
		deletePourbackCharge,
		editPourback,
	});
	return (
		<Paper className={classes.root}>
			<Box
				padding={1}
				className={`ag-theme-balham ${classes.agGridBox} ${classes.dynamicHeight}`}
			>
				<AgGridReact
					rowSelection="single"
					suppressRowClickSelection={true}
					pagination={false}
					rowHeight={50}
					headerHeight={35}
					singleClickEdit={false}
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					rowData={pourbacks}
					columnDefs={columns}
					defaultColDef={defaultColDef}
				/>
				<AgGridFooterActions
					hasMaxItems={false}
					showCreateModal={showCreateModal}
					setShowCreateModal={setShowCreateModal}
					table="pourbacks"
				/>
			</Box>
			<CreatePourback
				show={showCreateModal}
				onHide={() => {
					resume();
					setShowCreateModal(false);
				}}
			/>
			<EditPourback
				show={showEditModal}
				onHide={() => {
					resume();
					setShowEditModal(false);
				}}
				pourback={selectedPourback}
			/>
		</Paper>
	);
};
