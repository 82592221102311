import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogSection } from "../models/CatalogSection";

export const getCatalogSections = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[STEEL].[GetCatSections]", []);
};
export const upsertCatalogSection = async (section: CatalogSection) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: section.SectionId });
	parameters.push({ name: "Name", value: section.SectionName });
	parameters.push({ name: "IsCircular", value: section.IsCircular });
	parameters.push({ name: "Enabled", value: section.IsActive });

	return await apiService.callV2("[STEEL].[UpsertCatSection]", parameters);
};
export const getCatalogSection = async (SectionId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "SectionId", value: SectionId });

	return await apiService.callV2("[STEEL].[GetCatSection]", parameters);
};
