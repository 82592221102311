import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { useCastersContext } from "../../../CastersContext";
import { changeCasterHeatStatus } from "../../buttons/repositories/HeatSelectorRepository";
type Props = {
	show: boolean;
	onHide: (arrive: boolean) => void;
	refreshData: Function;
	heats: HeatProps[];
};
type HeatProps = {
	HeatName: string;
	HeatId?: number;
};

export interface ReadyToCastHeat {
	HeatId: number;
	HeatName: string;
}
export const OpenHeatModal = ({ show, onHide, refreshData, heats }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const { actions } = useCastersContext();
	const [newProcessId, setNewProcessId] = useState<number | null>(null);
	const { handleSubmit, control, reset } = useForm<ReadyToCastHeat>({});

	const changeHeatStatus = async (heatId: number, status: string) => {
		setIsLoading(true);
		const resp = await changeCasterHeatStatus({
			heatId: heatId,
			status: status,
		});
		if (resp.ok) {
			const data = resp.data;
			await refreshData();
			setNewProcessId(resp.data.returnValue);
			return data;
		} else {
			setError(resp.message!);
		}
		setIsLoading(false);
	};

	const onSubmit: SubmitHandler<any> = async (data: any) => {
		setIsLoading(true);
		const response = await changeHeatStatus(data?.HeatId!, "O");
		actions.setRefreshInPause(false);
		if (response.ok) {
			setNewProcessId(response.data.returnValue);
		} else {
			setError(response.message);
		}
		setIsLoading(false);
		onHide(true);
	};

	useEffect(() => {
		setIsLoading(false);
		if (show) {
			actions.setRefreshInPause(true);
		}
		reset();
	}, [show]);

	useEffect(() => {
		if (newProcessId) {
			refreshData().then(
				(window.location.href = `/caster-data-entry/${newProcessId}`),
			);
		}
	}, [newProcessId]);
	return (
		<>
			<MesfModal
				title="SELECT HEAT"
				open={show}
				handleClose={() => onHide(false)}
				id="select-heat-modal"
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content dividers style={{ padding: "15px 30px" }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={12}>
								<Controller
									name="HeatId"
									control={control}
									rules={{ required: "Heat is required" }}
									render={({ field, fieldState: { error } }) => (
										<Autocomplete
											id="clear-on-escape"
											clearOnEscape
											options={heats ?? []}
											getOptionLabel={(option) => option.HeatName}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Heat"
													variant="outlined"
													fullWidth
													error={!!error}
													size="small"
													helperText={error ? error.message : null}
												/>
											)}
											value={
												heats.find((heat) => heat.HeatId === field.value) ||
												null
											}
											onChange={(event, newValue) => {
												field.onChange(newValue ? newValue.HeatId : null);
											}}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
						<FooterModalButtons isSubmitLoading={isLoading} onHide={onHide} />
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
