import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogBucket } from "../models/CatalogBucket";

export const getCatalogBuckets = async () => {
	const apiService = new MESApiService();
	return await apiService.callV2("[MS].[GetBuckets]", []);
};

export const getCatalogBucket = async (BucketId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "BucketId", value: BucketId });

	return await apiService.callV2("[MS].[GetBucket]", parameters);
};

export const upsertCatalogBucket = async (Bucket: CatalogBucket) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "BucketId", value: Bucket.BucketId });
	parameters.push({ name: "BucketName", value: Bucket.BucketName });
	parameters.push({ name: "Capacity", value: Bucket.Capacity });
	parameters.push({ name: "IsActive", value: Bucket.IsActive });

	return await apiService.callV2("[MS].[UpsertBucket]", parameters);
};

export const deleteCatalogBucket = async (BucketId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "BucketId", value: BucketId });

	return await apiService.callV2("[MS].[DeleteBucket]", parameters);
};
