import { ButtonWithLoading } from "@dexteel/mesf-core";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { Pause, PlayArrow } from "@material-ui/icons";
import { useEffect } from "react";
import { useDataEntryContext } from "../context/reducer.provider";
import { useTimerContext } from "../context/timer.provider";
import { useEAFDataEntryActions } from "../hooks/useEAFDataEntryActions";

export const RefreshButtons = () => {
	const {
		state: { isDataLoading, deProcess },
	} = useDataEntryContext();
	const { refreshData } = useEAFDataEntryActions();

	const { seconds, isRunning, pause, restart, resume } = useTimerContext();

	useEffect(() => {
		restart();
	}, [deProcess?.process?.ProcessId]);

	return (
		<Grid
			item
			container
			direction={"column"}
			spacing={1}
			style={{ width: "100%" }}
		>
			<Grid item style={{ width: "100%" }}>
				<ButtonGroup fullWidth>
					<ButtonWithLoading
						onClick={() => {
							pause();
							refreshData().then(() => {
								restart();
								resume();
							});
						}}
						fullWidth
						variant={"contained"}
						color={"primary"}
						isLoading={isDataLoading}
						style={{ fontSize: "0.9rem" }}
					>
						Refresh in {seconds}
					</ButtonWithLoading>
					<Button
						style={{
							maxWidth: "40px",
						}}
						onClick={() => {
							isRunning ? pause() : resume();
						}}
					>
						{isRunning ? <Pause /> : <PlayArrow />}
					</Button>
				</ButtonGroup>
			</Grid>
		</Grid>
	);
};
