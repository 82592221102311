import { createSlice } from "@reduxjs/toolkit";
import { L3ChemistryModel } from "../../models/L3ChemistryModel";
const L3ChemistryInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	chemistries: L3ChemistryModel[] | null;
	showAck: boolean;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	chemistries: null,
	showAck: false,
};

export const L3ChemistryReducer = createSlice({
	name: "__",
	initialState: L3ChemistryInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setChemistries(state, { payload }) {
			state.chemistries = payload;
		},
		setShowAck(state, { payload }) {
			state.showAck = payload;
		},
	},
});
