import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { LogbookReducer } from "../reducers/logbookReducer";

const LogbookContext = createContext({
	state: LogbookReducer.getInitialState(),
	actions: LogbookReducer.actions,
});

export const useLogbookContext = () => useContext(LogbookContext);

export const LogbookProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: LogbookReducer.getInitialState(),
		reducers: LogbookReducer.caseReducers,
	});

	return (
		<LogbookContext.Provider value={{ state, actions }}>
			{children}
		</LogbookContext.Provider>
	);
};
