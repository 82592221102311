import {
	default as React,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { CastersReducer } from "./CastersReducer";

const CastersContext = createContext({
	state: CastersReducer.getInitialState(),
	actions: CastersReducer.actions,
});

export const useCastersContext = () => useContext(CastersContext);

export const CastersProvider = ({ children }: { children: ReactNode }) => {
	const urlParameters = useParams();
	const [processIdParam] = useState(
		urlParameters.processId === undefined ? null : urlParameters.processId,
	);

	const [state, actions] = useComplexState({
		initialState: CastersReducer.getInitialState(),
		reducers: CastersReducer.caseReducers,
	});
	useEffect(() => {
		const processId = Number(processIdParam);
		if (processIdParam == null || isNaN(processId)) {
			actions.setProcessId(null);
		} else {
			actions.setProcessId(processId);
		}
	}, [processIdParam]);
	return (
		<CastersContext.Provider value={{ state, actions }}>
			{children}
		</CastersContext.Provider>
	);
};
