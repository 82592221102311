import { CastersProvider } from "./CastersContext";
import { CastersPage } from "./CastersPage";

type Props = {};
const CasterDataEntry = (props: Props) => {
	return (
		<CastersProvider>
			<CastersPage />
		</CastersProvider>
	);
};
export default CasterDataEntry;
