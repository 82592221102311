import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogLadleReducer } from "./CatalogLadleReducer";

const CatalogLadleContext = createContext({
	state: CatalogLadleReducer.getInitialState(),
	actions: CatalogLadleReducer.actions,
});

export const useCatalogLadleContext = () => useContext(CatalogLadleContext);

export const CatalogLadleProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogLadleReducer.getInitialState(),
		reducers: CatalogLadleReducer.caseReducers,
	});
	return (
		<CatalogLadleContext.Provider value={{ state, actions }}>
			{children}
		</CatalogLadleContext.Provider>
	);
};
