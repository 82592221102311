import Grid from "@material-ui/core/Grid";
import { TypesFilter } from "./typesFilter";

export const Filters = () => {
	return (
		<Grid
			container
			item
			md={10}
			xs={12}
			spacing={2}
			justifyContent="flex-start"
			alignItems="center"
			style={{ padding: "8px 8px 8px 0" }}
		>
			<Grid item md={2} xs={6}>
				<TypesFilter />
			</Grid>
		</Grid>
	);
};
