import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { ChemistryResult } from "../models/ChemistryResult";
import { ChemistrySample } from "../models/ChemistrySample";

//ChemistrySamples
export const getChemistrySamples = async (
	heatId: number,
	statusCode: string | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "HeatId", value: heatId });
	parameters.push({ name: "StatusCode", value: statusCode });
	try {
		const resp = await apiService.call(
			"[LAB].[GetChemistrySamples]",
			parameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const upsertChemistrySample = async (model: ChemistrySample) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: model.Id });
	parameters.push({ name: "SampleTypeId", value: model.SampleTypeId });
	parameters.push({ name: "SampleSequence", value: model.SampleSequence });
	parameters.push({ name: "DateTimeResults", value: model.DateTimeResults });
	parameters.push({ name: "StatusCode", value: model.StatusCode });
	parameters.push({ name: "ResultSourceId", value: model.ResultSourceId });
	parameters.push({ name: "Comments", value: model.Comments });
	parameters.push({ name: "HeatId", value: model.HeatId });
	parameters.push({ name: "Technician", value: model.Technician });
	parameters.push({ name: "Melter", value: model.Melter });

	try {
		const resp = await apiService.call(
			"[LAB].[UpsertChemistrySample]",
			parameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

//ChemistryResults
export const upsertChemistryResult = async (model: ChemistryResult) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({
		name: "ChemistrySampleId",
		value: model.ChemistrySampleId,
	});
	parameters.push({ name: "ElementId", value: model.ElementId });
	parameters.push({ name: "Value", value: model.Value });

	try {
		const resp = await apiService.call(
			"[LAB].[UpsertChemistryResult]",
			parameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
