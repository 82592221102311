import React, { useEffect, useState } from "react";

type Props = {
	feetValue: number;
	inchesValue: number;
	classInput: string;
	onFocus: (field: string) => void;
	onChange: (value: number) => void;
	onBlur: (value: number) => void;
};

export const DualInputFieldTableScrap = ({
	feetValue,
	inchesValue,
	classInput,
	onFocus,
	onChange,
	onBlur,
}: Props) => {
	const [feet, setFeet] = useState<string | number>(feetValue);
	const [inches, setInches] = useState<string | number>(inchesValue);

	useEffect(() => {
		setFeet(feetValue);
		setInches(inchesValue);
	}, [feetValue, inchesValue]);

	const handleFeetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (
			value === "" ||
			(parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)
		) {
			setFeet(value === "" ? "" : parseInt(value, 10));
			onChange(feetToInches(value === "" ? 0 : parseInt(value, 10), inches));
		}
	};

	const handleInchesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (
			value === "" ||
			(parseInt(value, 10) >= 0 && parseInt(value, 10) <= 11)
		) {
			setInches(value === "" ? "" : parseInt(value, 10));
			onChange(feetToInches(feet, value === "" ? 0 : parseInt(value, 10)));
		}
	};

	const feetToInches = (feet: string | number, inches: string | number) => {
		const feetValue = typeof feet === "string" ? parseInt(feet, 10) || 0 : feet;
		const inchesValue =
			typeof inches === "string" ? parseInt(inches, 10) || 0 : inches;
		return feetValue * 12 + inchesValue;
	};

	const handleBlur = () => {
		const feetValue = feet === "" ? 0 : feet;
		const inchesValue = inches === "" ? 0 : inches;
		setFeet(feetValue);
		setInches(inchesValue);
		onBlur(feetToInches(feetValue, inchesValue));
	};

	return (
		<div style={{ display: "flex", borderColor: "unset" }}>
			<input
				style={{
					width: 60,
					borderColor: "unset",
					border: "1px solid #ced4da",
					textAlign: "left",
				}}
				type="number"
				className={classInput}
				value={feet}
				onFocus={() => onFocus("feet")}
				onChange={handleFeetChange}
				onBlur={handleBlur}
				min={0}
				max={100}
				placeholder="ft"
			/>
			<input
				style={{
					width: 55,
					borderColor: "unset",
					border: "1px solid #ced4da",
					textAlign: "left",
				}}
				type="number"
				className={classInput}
				value={inches}
				onFocus={() => onFocus("inches")}
				onChange={handleInchesChange}
				onBlur={handleBlur}
				min={0}
				max={11}
				placeholder="in"
			/>
		</div>
	);
};
