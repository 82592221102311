import TextField from "@material-ui/core/TextField";
import { KeyboardEventHandler } from "react";
import { useLogbookContext } from "../../context/logbookContext";

type Props = {
	onKeyDown: KeyboardEventHandler<HTMLDivElement> | undefined;
};
// MuiGrid-item
export const SearchFilter = ({ onKeyDown }: Props) => {
	const {
		state: {
			FilterParameters: { SearchText },
		},
		actions: { setFilterSearchText },
	} = useLogbookContext();

	const handleFilter = (e: any) => {
		setFilterSearchText(e.target.value);
	};

	return (
		<div style={{ position: "relative" }}>
			<TextField
				style={{
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
					height: "40px",
					marginBottom: 9,
				}}
				InputProps={{
					style: { height: "40px" },
				}}
				label="Search"
				variant="outlined"
				margin="dense"
				onKeyDown={onKeyDown}
				value={SearchText}
				onChange={handleFilter}
				fullWidth
				autoComplete="off"
			/>
		</div>
	);
};
