import { Box, Grid, Paper } from "@material-ui/core";
import { ProcessDataNumberTextField } from "../form-fields/process-data/ProcessDataNumberTextField";
import { ProcessDataTextFieldDE } from "../form-fields/process-data/ProcessDataTextFieldDE";

export const TableForemanEtc = () => {
	return (
		<Box padding={1}>
			<Grid container spacing={1} direction={"column"}>
				<Grid item>
					<ProcessDataTextFieldDE field={"Foreman"} />
				</Grid>
				<Grid item>
					<ProcessDataNumberTextField field={"MWH"} />
				</Grid>
				<Grid item>
					<ProcessDataNumberTextField field={"Heel"} label={"Heel [tons]"} />
				</Grid>
				<Grid item>
					<ProcessDataNumberTextField
						field={"InjectedCarbon"}
						label={"Inj Carbon [lbs]"}
					/>
				</Grid>
				<Grid item>
					<ProcessDataNumberTextField field={"Lime"} label={"Lime [lbs]"} />
				</Grid>
				<Grid item>
					<ProcessDataNumberTextField
						field={"OxygenVolume"}
						label={"O2 Volume"}
						decimals={1}
					/>
				</Grid>
				{/*
                    <Grid item>
                        <ProcessDataNumberTextField field={'TimeInAuto'} label={'Time Auto'}/>
                    </Grid> */}
			</Grid>
		</Box>
	);
};
