import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { MESAgGrid } from "../../../../../controls/ag-grid/components/MESAgGrid";
import { CatalogSection } from "../../../models/CatalogSection";
import { useGridStyles } from "../../../styles/gridStyles";
import { CreateSection } from "./CreateSection";
import { EditSection } from "./EditSection";
import { useTableData } from "./hooks";
type Props = {
	getCatalogSectionsFromAPI: Function;
	isLoading: boolean;
};

export const TableSection = ({
	getCatalogSectionsFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [idSection, setIdSection] = useState<number | null>(null);
	const { rows, columns } = useTableData({});
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: CatalogSection) => {
			setShowEditModal(!showEditModal);
			setIdSection(data.SectionId);
		},
	});

	const classes = useGridStyles();
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			style={{ height: "75vh" }}
			className={classes.root}
		>
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Sections
				</Typography>
			</Grid>
			<CreateSection
				show={showCreateModal}
				onHide={() => setShowCreateModal(false)}
				refreshData={getCatalogSectionsFromAPI}
			/>
			<EditSection
				show={showEditModal}
				onHide={(updated: boolean) => {
					setShowEditModal(false);
					setIdSection(null);
					if (updated) getCatalogSectionsFromAPI();
				}}
				SectionId={idSection}
			/>
			<MESAgGrid
				rowData={rows || []}
				columnDefs={columns}
				columnTypes={columnTypes}
				defaultColDef={columnDefaults}
			/>
			<Grid container justifyContent="flex-end">
				<Grid item md={2} xs={12} className={classes.btnModal}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{ margin: "0px 15px" }}
						onClick={() => setShowCreateModal(!showCreateModal)}
					>
						NEW SECTION
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
