import { useEffect, useState } from "react";
import { useCastersContext } from "../CastersContext";

export const useTimer = ({ onTimeUp }: { onTimeUp: Function }) => {
	const { state, actions } = useCastersContext();

	const [ticks, setTicks] = useState(0);
	const [refreshTime, setRefreshTime] = useState(0);
	const updatePeriod = 10;
	const lazyPeriod = 15;

	useEffect(() => {
		(async () => {
			setRefreshTime((refreshTime) => refreshTime + 1);
			if (state.lazyStart !== null) {
				const currentDate = new Date();
				const lazyStartMilliseconds = state.lazyStart.getTime();
				const secondsDiff =
					(currentDate.getTime() - lazyStartMilliseconds) / 1000;
				if (secondsDiff > lazyPeriod) {
					document.getElementById("dummyDiv")?.focus(); //Lost focus
					actions.clearFocusField();
				}
			}
		})();
	}, [ticks]);
	useEffect(() => {
		(async () => {
			if (refreshTime > 0 && refreshTime % updatePeriod === 0) {
				await onTimeUp();
				setRefreshTime(0);
			}
			actions.addRefreshStatus(updatePeriod - (refreshTime % updatePeriod));
		})();
	}, [refreshTime]);

	useEffect(() => {
		let interval: NodeJS.Timeout | null;
		if (!state.refreshInPause) {
			interval = setInterval(() => {
				setTicks((ticks) => ticks + 1);
			}, 1000);
		}
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [state.refreshInPause]);

	return {
		restartTime: () => setRefreshTime(0),
	};
};
