import { ErrorModal } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import {
	CATALOG_SECTION_INITIAL_VALUES,
	CatalogSection,
} from "../../../models/CatalogSection";
import {
	getCatalogSection,
	upsertCatalogSection,
} from "../../../repositories/CatalogSectionRepository";
import { useModalStyles } from "../../../styles/modalStyles";
type Props = {
	SectionId: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};
export const EditSection = ({ SectionId, show, onHide }: Props) => {
	const classes = useModalStyles();
	const [open, setOpen] = useState(false);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<CatalogSection>({
		defaultValues: CATALOG_SECTION_INITIAL_VALUES,
	});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<CatalogSection> = async (data) => {
		setIsSubmitLoading(true);
		const res = await upsertCatalogSection(data);
		if (res.ok) {
			const id = res.data.tables[0].rows[0].Id;
			onHide(true);
			setOpen(true);
		} else {
			setError(res.message);
		}
		setIsSubmitLoading(false);
	};
	useEffect(() => {
		if (show) {
			setIsSubmitLoading(false);
			(async () => {
				setIsLoading(true);
				const response = await getCatalogSection(SectionId);
				if (response.ok) {
					const sectionSelected = response.data.tables[0].rows[0];
					if (sectionSelected) {
						setValue("SectionId", sectionSelected.SectionId);
						setValue("SectionName", sectionSelected.SectionName);
						setValue("IsCircular", sectionSelected.IsCircular);
						setValue("IsActive", sectionSelected.IsActive);
						setIsLoading(false);
					}
				} else {
					setError(response.message);
				}
			})();
		}
	}, [show]);
	return (
		<>
			<Modal
				{...{ show }}
				onHide={() => onHide(false)}
				size="lg"
				aria-labelledby="contained-modal-title-center"
				centered
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Modal.Header closeButton style={{ padding: "10px 30px" }}>
						<Modal.Title
							id="contained-modal-title-center"
							style={{ margin: "20px 0 0" }}
						>
							EDIT SECTION
						</Modal.Title>
					</Modal.Header>
					<Modal.Body
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "300px" }}
							>
								<LazyLoading />
							</Grid>
						)}
						{!isLoading && (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField
											{...register("SectionName", { required: true })}
											label="Name"
											variant="outlined"
											error={!!errors.SectionName}
											fullWidth
											margin="dense"
											autoComplete="off"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="IsCircular"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value}
															onChange={field.onChange}
															name="IsCircular"
															color="primary"
														/>
													}
													label="Is Circular"
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="IsActive"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value}
															onChange={field.onChange}
															name="Enabled"
															color="primary"
														/>
													}
													label="Enabled"
												/>
											)}
										/>
									</Grid>
								</Grid>
							</>
						)}
					</Modal.Body>
					<Modal.Footer style={{ padding: "20px 30px 30px" }}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => onHide(false)}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item md={3} xs={12} style={{ margin: 0 }}>
								<Button
									fullWidth
									startIcon={
										isSubmitLoading ? <CircularProgress size="1rem" /> : <></>
									}
									disabled={isSubmitLoading}
									variant="contained"
									color="primary"
									type="submit"
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</Modal.Footer>
				</form>
			</Modal>
			<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={handleClose}
				>
					The section was edited successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
