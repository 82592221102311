import { Theme, makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-group-text ": {
			fontSize: 11,
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .info-limit": {
			color: "#000",
			backgroundColor: "#b2e5ed",
			fontWeight: 600,
		},
		"& .ag-header": {
			backgroundColor: "#f5f5f5",
			"&:hover": {
				backgroundColor: "#f5f5f5",
			},
			fontWeight: "bold",
		},
		"& .ag-row-hover": {
			backgroundColor: "#ecf0f1 !important",
		},
		"& .ag-row-odd.ag-row-hover": {
			backgroundColor: "#ecf0f1 !important",
		},
		"& .ag-row": {
			transition: "background-color 0.2s",
		},
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	gridContainer: {
		height: "61vh",
		border: "4px solid #ccc",
		borderRadius: 10,
		padding: 10,
		margin: "20px 10px",
	},
}));
export const useGridComplexStyles = makeStyles((theme: Theme) => ({
	root: {
		// "& .ag-theme-alpine": {
		//   height: "auto"
		// },
		"& .ag-icon-menu": {
			display: "none",
		},
		//Groups
		"& .ag-header-group-cell": {
			padding: "0px !important",
			color: "#fff !important",
		},
		"& .ag-header-group-cell-label": {
			margin: "auto !important",
		},
		//Headers
		"& .ag-header": {
			backgroundColor: "#479dc4 !important",
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			color: "#fff !important",
		},
		"& .ag-cell-label-container": {
			padding: "0px !important",
		},
		"& .ag-header-cell-text": {
			margin: "auto !important",
		},
		//Cells
		"& .ag-cell": {
			fontSize: "12px !important",
			padding: "8px !important",
		},
		"& .error-limit": {
			color: "#fff",
			backgroundColor: "#f07171",
			fontWeight: 600,
		},
		"& .stripe-header ": {
			color: "#fff",
			background:
				"repeating-linear-gradient(45deg,#65c2ec,#65c2ec 5px,#479dc4 5px,#479dc4 10px)",
		},

		"& .ag-drag-handle": {},
		"& .ag-right-aligned-cell": {},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .custom-tooltip": {
			width: "150px",
			minHeight: "70px",
			border: "1px solid cornflowerblue",
			overflow: "hidden",
		},

		"& .custom-tooltip p": {
			margin: "5px",
			whiteSpace: "pre-line",
			wordWrap: "break-word",
		},

		"& .custom-tooltip span:first-of-type": {
			fontWeight: "bold",
		},
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	footerTitle: {
		width: 220,
		padding: "8px 0",
		textAlign: "center",
		backgroundColor: "#eee",
		borderBottom: "solid 1px #babfc7",
		borderLeft: "solid 1px #babfc7",
	},
	footerContainer: {
		backgroundColor: "#fff",
		height: "45px",
	},
	footerItem: {
		width: 70,
		padding: "8px 0",
		textAlign: "center",
		backgroundColor: "#eee",
		borderBottom: "solid 1px #babfc7",
	},
	footerTextItem: {
		fontSize: 12,
		verticalAlign: "middle",
	},
	footerTextTitle: {
		fontSize: 12,
		verticalAlign: "middle",
		fontWeight: 700,
	},
}));
