import { ITooltipParams } from "ag-grid-enterprise";
import React, { useMemo } from "react";
export default (props: ITooltipParams & { color: string }) => {
	const data = useMemo(() => {
		if (props.value === null || props.value === undefined) return "";
		const metaData = props.value.toString().split("%%%");
		if (metaData.length > 1) return metaData[1];
	}, []);
	if (data === undefined || data === null || data.length === 0) return <></>;
	return (
		<div
			className="custom-tooltip"
			style={{ backgroundColor: props.color || "white" }}
		>
			<p>{data}</p>
		</div>
	);
};
