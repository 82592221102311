import { ErrorModal } from "@dexteel/mesf-core";
import { useState } from "react";
import { isNumeric } from "../../../../../utils/numeric-utils";
import { useCastersContext } from "../../CastersContext";
import { useSaveStrand } from "../../hooks/useSaveStrand";
import { useStyles } from "../styles";
import { InputFieldTable } from "../ui/FieldTable";
import { SectionAutocomplete } from "../ui/SectionAutocomplete";
import { DualInputFieldTableProduction } from "../ui/dual-input-field-production";

export const ProductionTable = ({
	onUpdate,
	readonly,
}: { onUpdate: () => void; readonly: boolean }) => {
	const { state, actions } = useCastersContext();
	const classes = useStyles();
	const strands = state.strands || [];
	const [error, setError] = useState("");
	const process = state.processData || null;
	const { save } = useSaveStrand();

	///Events
	const onHandleFocus = (strandIndex: number, field: string) => {
		actions.setFocusField({ entity: "STRAND", index: strandIndex, field });
	};

	const onHandleChange = (strandIndex: number, field: string, value: any) => {
		if (!isNumeric(value)) return;
		actions.updateStrandData({
			index: strandIndex,
			field,
			value: Number(value).valueOf(),
			forced: true,
		});
	};
	const handleOnBlur = (strandIndex: number, field: string, value: any) => {
		if (!isNumeric(value)) return;
		actions.clearFocusField();
		save(strandIndex, field, value).then(() => {
			onUpdate();
		});
	};

	return (
		<>
			{process !== null ? (
				<table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<td></td>
							<td
								colSpan={3}
								className={classes.simpleCellHeader}
								style={{ borderTopRightRadius: "0.25rem" }}
							>
								Production
							</td>
						</tr>
						<tr>
							<td style={{ width: "50px" }}></td>
							<td
								className={classes.simpleCellHeader}
								style={{
									width: "100px",
								}}
							>
								Length
								<td
									className={classes.simpleCellHeader}
									style={{ width: "50px", paddingBottom: 0, textAlign: "left" }}
								>
									ft
								</td>
								<td
									className={classes.simpleCellHeader}
									style={{ width: "50px", paddingBottom: 0, textAlign: "left" }}
								>
									in
								</td>
							</td>
							<td
								className={classes.simpleCellHeader}
								style={{ width: "100px" }}
							>
								Pieces
							</td>
							<td
								className={classes.simpleCellHeader}
								style={{
									width: "100px",
								}}
							>
								Section
							</td>
						</tr>
					</thead>
					<tbody>
						{strands?.map((strand, index) => (
							<tr key={index}>
								<td className={classes.simpleCellHeader}>{index + 1}</td>
								<td>
									<DualInputFieldTableProduction
										classInput={
											"form-control" + " " + classes.simpleCellNumeric
										}
										feetValue={Math.floor((strand.PieceLength || "" || 0) / 12)}
										inchesValue={(strand.PieceLength || 0) % 12}
										onFocus={() => onHandleFocus(index, "PieceLength")}
										onChange={(value) =>
											onHandleChange(index, "PieceLength", value.toString())
										}
										onBlur={(value: any) =>
											handleOnBlur(index, "PieceLength", value.toString())
										}
									/>
								</td>
								<td>
									<InputFieldTable
										classInput={classes.simpleCellNumeric}
										value={strand.Pieces?.toString() || ""}
										onFocus={() => onHandleFocus(index, "Pieces")}
										onChange={(value) => onHandleChange(index, "Pieces", value)}
										onBlur={(value) => handleOnBlur(index, "Pieces", value)}
									/>
								</td>
								<td>
									<SectionAutocomplete
										key={`process-${process.ProcessId}-strand-${index}`}
										readonly={readonly}
										value={strands[index].SectionId}
										onFocus={() => onHandleFocus(index, "SectionId")}
										moldSizeId={strands[index].MoldSizeId}
										onChange={(value) =>
											handleOnBlur(index, "SectionId", value)
										}
										className={
											readonly
												? "custom-select " + classes.inputNormalClass
												: classes.simpleCellNumeric
										}
										label="Choose"
									/>
								</td>
							</tr>
						))}
						<tr>
							<td
								colSpan={2}
								className={classes.simpleCellHeader}
								style={{ borderBottomLeftRadius: "0.25rem" }}
							>
								Tons Cast
							</td>
							<td colSpan={2}>
								<InputFieldTable
									value={process.CastTons?.toFixed(1) || ""}
									readOnly
									styleTextBox={{ borderBottomRightRadius: "0.25rem" }}
									classInput={classes.simpleCellNumeric}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			) : (
				<label>No process data</label>
			)}
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
