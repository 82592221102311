export interface CatalogMoldSize {
	MoldSizeId: number | null;
	MoldSizeName: string;
	MinSpeedLimit: number;
	LbsPerFoot: number;
	Section: number;
	Enabled: boolean;
}
export const CATALOG_MOLD_SIZE_INITIAL_VALUES: CatalogMoldSize = {
	MoldSizeId: null,
	MoldSizeName: "",
	MinSpeedLimit: 0,
	LbsPerFoot: 0,
	Section: 0,
	Enabled: true,
};
