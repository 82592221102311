import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogMoldSize } from "../models/catalogs/CatalogMoldSize";

export const getCatalogMoldSizes = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[CAS].[GetMoldSizes]", []);
};
export const upsertCatalogMoldSize = async (MoldSize: CatalogMoldSize) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "MoldSizeId", value: MoldSize.MoldSizeId },
		{ name: "MoldSizeName", value: MoldSize.MoldSizeName },
		{ name: "MinSpeedLimit", value: MoldSize.MinSpeedLimit },
		{ name: "LbsPerFoot", value: MoldSize.LbsPerFoot },
		{ name: "Section", value: MoldSize.Section },
	];

	return await apiService.callV2("[CAS].[UpsertMoldSize]", parameters);
};

export const deleteCatalogMoldSize = async (MoldSizeId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "MoldSizeId", value: MoldSizeId }];

	return await apiService.callV2("[CAS].[DeleteMoldSize]", parameters);
};

export const getCatalogMoldSize = async (MoldSizeId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "MoldSizeId", value: MoldSizeId }];

	return await apiService.callV2("[CAS].[GetMoldSize]", parameters);
};
