import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogMechanicalTest } from "../models/CatalogMechanicalTest";

export const getCatalogMechanicalTests = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[LAB].[GetCatMechanicalTests]", []);
};
export const upsertCatalogMechanicalTest = async (
	mechanicalTest: CatalogMechanicalTest,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Id", value: mechanicalTest.MechanicatTestId });
	parameters.push({ name: "Name", value: mechanicalTest.MechanicalTestName });
	parameters.push({ name: "Decimals", value: mechanicalTest.Decimals });
	parameters.push({ name: "Order", value: mechanicalTest.Order });
	parameters.push({
		name: "UseInCircular",
		value: mechanicalTest.UseInCircular,
	});
	parameters.push({ name: "Enabled", value: mechanicalTest.Enabled });

	return await apiService.callV2("[LAB].[UpsertCatMechanicalTest]", parameters);
};
export const getCatalogMechanicalTest = async (
	MechanicalTestId: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "MechanicalTestId", value: MechanicalTestId });

	return await apiService.callV2("[LAB].[GetCatMechanicalTest]", parameters);
};
