import { createSlice } from "@reduxjs/toolkit";
import { L3MechanicalModel } from "../../models/L3MechanicalModel";

const L3MechanicalInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	mechanicales: L3MechanicalModel[] | null;
	showAck: boolean;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	mechanicales: null,
	showAck: false,
};

export const L3MechanicalReducer = createSlice({
	name: "__",
	initialState: L3MechanicalInitialState,
	reducers: {
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
		setMechanicales(state, { payload }) {
			state.mechanicales = payload;
		},
		setShowAck(state, { payload }) {
			state.showAck = payload;
		},
	},
});
