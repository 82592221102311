import { ColDef } from "ag-grid-enterprise";
import { CatalogMechanicalTest } from "../../../models/CatalogMechanicalTest";
import { useCatalogMechanicalTestContext } from "../CatalogMechanicalTestContext";

type Props = {};
export const useTableData = ({}: Props) => {
	const { state } = useCatalogMechanicalTestContext();
	const rows: CatalogMechanicalTest[] =
		state.mechanicalTests?.map((mechanicalTest) => ({
			...mechanicalTest,
		})) || [];

	const columns: ColDef[] = [
		{ field: "MechanicalTestName", headerName: "Name", minWidth: 20, flex: 1 },
		{
			field: "Decimals",
			headerName: "Decimals",
			type: ["numericColumn", "numericWidth"],
			maxWidth: 200,
		},
		{
			field: "Order",
			headerName: "Order",
			type: ["numericColumn"],
			maxWidth: 150,
		},
		{
			field: "UseInCircular",
			headerName: "Use In Circular",
			type: ["nonSorteable", "nonFilterable", "centerAligned", "IsActive"],
			maxWidth: 100,
		},
		{
			field: "IsActive",
			headerName: "Enabled",
			type: ["nonSorteable", "nonFilterable", "centerAligned", "IsActive"],
			maxWidth: 100,
		},
		{
			field: "Name",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "editButton"],
			maxWidth: 50,
		},
	];

	return { rows, columns };
};
