import {
	ErrorModal,
	ShiftDayNavigatorControl,
	ShiftInfo,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { getDelayAreas } from "../delays-report/repositories/DelaysReportRepository";
import { DelayAreaFilter } from "./components/DelayAreaFilter";
import { DelaysManagerTable } from "./components/DelaysManagerTable";
import { useDelaysManagerContext } from "./context/DelaysManagerContext";
import { getDelaysByShift } from "./repositories/DelaysManagerRepository";

type Props = {};
export const DelaysManagerPage = (props: Props) => {
	const { state, actions } = useDelaysManagerContext();
	const [currentDate, setCurrentDate] = useState<Date | null>(null);
	const [showMicroDelays, setShowMicroDelays] = useState(false);
	const [error, setError] = useState("");
	const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
		null,
	);
	const { shiftInfo, moveShift, getShiftDataFromAPI, initShiftNavigator } =
		useShiftNavigator();
	const [isLoadingFilter, setIsLoadingFilter] = useState(false);
	const { shiftId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [firstRender, setFirstRender] = useState(true);

	const navigate = useNavigate();

	const getDataFromAPI = async (shiftParam?: ShiftInfo) => {
		try {
			actions.setIsLoadingGrid(true);
			setIsLoadingFilter(true);

			if (!state.assetForDelayArea || state.assetForDelayArea.length === 0) {
				const response = await getDelayAreas();
				if (response.ok) {
					actions.setAssetForDelayArea(response.data.tables[0].rows);
				} else {
					setError("Error loading delay areas");
					return;
				}
			}
			const shift = get(
				shiftParam ? shiftParam : shiftInfo,
				"CurrentShiftId",
				0,
			);
			const resp = await getDelaysByShift(
				shift || 0,
				selectedDelayArea,
				showMicroDelays,
			);
			if (resp.ok && resp.data.tables.length > 0) {
				actions.setDelays(resp.data.tables[0].rows);
			} else {
				setError("Error loading delays");
			}
		} catch (error) {
			setError("An unexpected error occurred");
		} finally {
			actions.setIsLoadingGrid(false);
			setIsLoadingFilter(false);
		}
	};

	useEffect(() => {
		if (shiftInfo && !firstRender) {
			const shiftId = get(shiftInfo, "CurrentShiftId", null);
			const delayId = searchParams.get("delayId");
			const query = delayId ? `?delayId=${delayId}` : "";
			navigate(`/delays-manager/${shiftId}${query}`);
			(async () => {
				await getDataFromAPI();
			})();
		}
	}, [shiftInfo, selectedDelayArea, showMicroDelays]);

	useEffect(() => {
		if (shiftId) {
			(async () => {
				getShiftDataFromAPI(null, Number(shiftId)).then(async (shift) => {
					if (shift) {
						getDataFromAPI(shift).then(() => {
							setFirstRender(false);
						});
					}
				});
			})();
		} else {
			(async () => {
				getShiftDataFromAPI(null, 0).then(async (shift) => {
					if (shift) {
						getDataFromAPI(shift).then(() => {
							setFirstRender(false);
						});
					}
				});
			})();
		}
	}, []);

	if (!state.isLoadingPage) {
		return (
			<>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "15px 20px 10px",
						borderRadius: "0.75rem",
						height: "91vh",
					}}
				>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										Delays Manager
									</Typography>
								</Grid>
							</Grid>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={2}
								>
									<Grid item>
										<ShiftDayNavigatorControl preventInit />
									</Grid>
									<Grid item>
										<DelayAreaFilter
											delayAreas={state.assetForDelayArea || []}
											selectedDelayArea={selectedDelayArea}
											isLoading={isLoadingFilter}
											onChange={(assetId) => {
												setSelectedDelayArea(assetId);
											}}
										/>
									</Grid>
									<Grid item style={{ margin: 0, padding: "15px" }}>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={showMicroDelays}
														color="primary"
														onChange={(event) =>
															setShowMicroDelays(event.target.checked)
														}
													/>
												}
												label="Include Microdelays"
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
							<Divider style={{ marginBottom: 10 }} />
							<ErrorModal
								error={state.errorMessage}
								onHide={() => actions.setErrorMessage("")}
							/>
							<NotificationSnackBar
								message={state.notificationMessage}
								onHide={() => actions.setNotificationMessage("")}
							/>
							<DelaysManagerTable
								loadingGrid={state.isLoadingGrid}
								rows={state.delays || []}
								refreshData={getDataFromAPI}
							/>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
	return <LazyLoading />;
};
