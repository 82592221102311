import { makeStyles } from "@material-ui/core";
import ReactECharts from "echarts-for-react";
import { useDelaysParetoContext } from "../context/DelaysParetoContext";

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		width: "100%",
		height: "calc(100% - 20px)",
		"& .ag-root-wrapper": {
			border: "none",
		},
		"& .ag-header": {
			borderTop: "1px solid #e0e0e0",
		},
	},
	gridWrapper: {
		border: "4px solid #ccc",
		borderRadius: 10,
		padding: 10,
		height: "calc(100vh - 300px)",
		minHeight: "calc(100vh - 300px)",
		width: "100%",
		margin: "20px 0 10px",
	},
}));

export const DelaysParetoGraphic = () => {
	const classes = useStyles();
	const {
		state: { delaysListForPareto },
	} = useDelaysParetoContext();

	// Función auxiliar para formatear minutos
	const formatMinutes = (minutes: number) => {
		return new Intl.NumberFormat("en-US").format(minutes);
	};

	const getChartOptions = () => {
		const paretoData = delaysListForPareto
			.filter(
				(item) =>
					item.DelayCodeName !== null && item.DelayCodeName !== undefined,
			)
			.map((item) => ({
				...item,
				DelayCodeName: item.DelayCodeName || "",
				DelayCategoryName: item.DelayCategoryName || "",
			}));

		const options = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
				formatter: (params: any) => {
					const dataPoint = params[0];
					const currentItem = paretoData[dataPoint.dataIndex];
					const categoryInfo = currentItem.DelayCategoryName
						? `<br/>Category: ${currentItem.DelayCategoryName}`
						: "";

					return `${currentItem.DelayCodeName}<br/>
                  Total Minutes: ${formatMinutes(dataPoint.value)} min${categoryInfo}`;
				},
			},
			grid: {
				left: "5%", // Aumentado para dar espacio a los números formateados
				right: "4%",
				bottom: "3%",
				containLabel: true,
			},
			xAxis: [
				{
					type: "category",
					data: paretoData.map((item) => item.DelayCodeName),
					axisTick: {
						alignWithLabel: true,
					},
					axisLabel: {
						rotate: 45,
						interval: 0,
					},
				},
			],
			yAxis: [
				{
					type: "value",
					name: "Minutes",
					nameLocation: "middle",
					nameGap: 50,
					splitLine: {
						show: true,
					},
					axisLine: {
						show: true,
					},
					axisTick: {
						show: true,
					},
					axisLabel: {
						show: true,
						formatter: (value: number) => {
							return formatMinutes(value);
						},
					},
				},
			],
			series: [
				{
					name: "Delay Minutes",
					type: "bar",
					barWidth: "60%",
					data: paretoData.map(
						(item) => (item.TotalDelayMinutes as string) || 0,
					),
					itemStyle: {
						color: "#1890ff",
					},
					label: {
						show: true,
						position: "top",
						formatter: (params: any) => {
							return formatMinutes(params.value);
						},
					},
				},
			],
		};

		return options;
	};

	return (
		<div onContextMenu={(e) => e.preventDefault()}>
			<div className={classes.gridWrapper}>
				<ReactECharts
					option={getChartOptions()}
					style={{ height: "100%", width: "100%" }}
					opts={{ renderer: "svg" }}
				/>
			</div>
		</div>
	);
};
