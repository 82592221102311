import { Suspense } from "react";
import * as React from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import HealthStatus from "../../pages/custom/health-status";
import GenericReport from "../../pages/report/genericReport";

export const ReportRoutes = [
	<Route
		path="/report"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/health-status"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<HealthStatus />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/:reportCode"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/:reportCode/:param1"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/:reportCode/:param1/:param2"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/:reportCode/:param1/:param2/:param3"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/:reportCode/:param1/:param2/:param3/:param4"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
	<Route
		path="/report/:reportCode/:param1/:param2/:param3/:param4/:param5"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<GenericReport />
			</Suspense>
		}
	></Route>,
];
