import { ErrorModal, MesfModal, useShiftNavigator } from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { getHeat } from "../../../repositories/HeatRepository";
import { useModalStyles } from "../../../styles/modalStyles";
import { useGradeContext } from "../../context/GradesContext";
import { useLadleContext } from "../../context/LadlesContext";
import { HeatDataEntryType } from "../../models/Heats/HeatDataEntryType";
import { INITIAL_VALUES } from "../../models/Heats/Initial-values";
import { upsertHeat } from "../../repositories/heatsRepository";

type Props = {
	heatIdForEdit: number | null;
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const EditHeatModal = ({ heatIdForEdit, show, onHide }: Props) => {
	const classes = useModalStyles();

	const [openAlertMessage, setOpenAlertMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [messageType, setMessageType] = useState<Color | undefined>(undefined);

	const [heatForEdit, setHeatForEdit] = useState<any | null>(null);
	const [error, setError] = useState<string | null>(null);

	const { shiftInfo } = useShiftNavigator();
	const {
		state: { ladles },
	} = useLadleContext();
	const {
		state: { grades },
	} = useGradeContext();

	const {
		control,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<any>({ defaultValues: INITIAL_VALUES });

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenAlertMessage(false);
	};

	const onSubmit: SubmitHandler<HeatDataEntryType> = async (data) => {
		setIsSubmitLoading(true);

		if (heatIdForEdit) {
			const res = await upsertHeat({ ...data, HeatId: heatIdForEdit });

			if (res.ok) {
				setMessage("The heat was updated successfully");
				setMessageType("success");
				onHide(true);
			} else {
				setMessage("Error, please try again");
				setMessageType("error");
				setIsSubmitLoading(false);
				setError(res.message);
			}
		}
		setIsSubmitLoading(false);
		setOpenAlertMessage(true);
	};

	useEffect(() => {
		if (show) {
			(async () => {
				setIsLoading(true);
				if (heatIdForEdit) {
					const resp = await getHeat(heatIdForEdit);
					const heat = resp.data.tables[0].rows[0];
					if (heat) {
						setHeatForEdit(heat);
					} else {
						onHide(true);
						setMessage("Error, heat not found. Try again");
						setOpenAlertMessage(true);
						setHeatForEdit(null);
					}
				}

				setIsLoading(false);
			})();
		}
	}, [show]);

	useEffect(() => {
		if (show && heatForEdit) {
			const {
				HeatId,
				Name,
				GradeId,
				LadleId,
				IsExternal,
				Shift,
				SteelWeight,
				ProductionYear,
			} = heatForEdit;

			setValue("HeatId", HeatId);
			setValue("HeatName", Name);
			setValue("ShiftId", get(shiftInfo, "CurrentShiftId", null));
			setValue("GradeId", GradeId);
			setValue("SteelWeight", SteelWeight);
			setValue("LadleId", LadleId);
			setValue("IsExternal", IsExternal);
			setValue("ProductionYear", ProductionYear);
		}
	}, [heatForEdit]);

	return (
		<>
			<MesfModal
				open={show}
				handleClose={() => {
					onHide(true);
				}}
				id="editHeat"
				title="Update a heat"
				maxWidth="sm"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content
						className={classes.modalBody}
						style={{ padding: "15px 30px" }}
					>
						{isLoading && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ minHeight: "150px" }}
							>
								<CircularProgress size="5rem" />
							</Grid>
						)}

						{!isLoading && (
							<>
								<Grid container spacing={3}>
									<Grid item md={8} xs={12}>
										<Controller
											name="HeatName"
											rules={{ required: true }}
											control={control}
											render={({ field }) => {
												return (
													<TextField
														label="Name"
														value={field.value}
														onChange={field.onChange}
														variant="outlined"
														error={!!errors.HeatName}
														fullWidth
														margin="dense"
													/>
												);
											}}
										/>

										{errors.HeatName?.type === "required" && (
											<span className={classes.errorLabel}>
												Name is required
											</span>
										)}
									</Grid>
									<Grid item xs={4}>
										<Controller
											name="ProductionYear"
											control={control}
											render={({ field }) => {
												return (
													<TextField
														disabled
														label="Year"
														value={field.value}
														onChange={field.onChange}
														variant="outlined"
														error={!!errors.HeatName}
														fullWidth
														margin="dense"
													/>
												);
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="GradeId"
											control={control}
											render={({ field }) => (
												<Autocomplete
													id="clear-on-escape"
													clearOnEscape
													options={grades}
													getOptionLabel={(option) => option.GradeName}
													onChange={(event, newValue) => {
														field.onChange(newValue ? newValue.GradeId : null);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Grade"
															variant="outlined"
															fullWidth
														/>
													)}
													value={
														grades.find(
															(grade) => grade.GradeId === field.value,
														) || null
													}
												/>
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<Controller
											name="SteelWeight"
											control={control}
											render={({ field }) => (
												<TextField
													type={"number"}
													label="Steel Weight"
													value={field.value}
													onChange={field.onChange}
													variant="outlined"
													fullWidth
													margin="dense"
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="LadleId"
											control={control}
											render={({ field }) => (
												<Autocomplete
													options={ladles}
													getOptionLabel={(option) => option.LadleName}
													onChange={(event, newValue) => {
														field.onChange(newValue ? newValue.LadleId : null);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Ladle"
															variant="outlined"
															fullWidth
														/>
													)}
													value={
														ladles.find(
															(ladle) => ladle.LadleId === field.value,
														) || null
													}
												/>
											)}
										/>
									</Grid>

									<Grid item xs={12} md={12}>
										<Controller
											name="IsExternal"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.formControlLabel}
													control={
														(
															<Checkbox
																checked={field.value === true}
																onChange={(e) =>
																	field.onChange(e.target.checked)
																}
																color="primary"
																style={{ width: "100% !important" }}
															/>
														) as any
													}
													label="External"
												/>
											)}
										/>
									</Grid>
								</Grid>
							</>
						)}
					</MesfModal.Content>
					{!isLoading && (
						<MesfModal.Actions style={{ padding: "2rem" }}>
							<Grid container spacing={2} justifyContent="flex-end">
								<Grid item md={3} xs={12} style={{ margin: 0 }}>
									<Button
										fullWidth
										variant="contained"
										color="default"
										onClick={() => {
											onHide(false);
										}}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item md={3} xs={12} style={{ margin: 0 }}>
									<Button
										fullWidth
										startIcon={
											isSubmitLoading ? <CircularProgress size="1rem" /> : <></>
										}
										disabled={isSubmitLoading}
										variant="contained"
										color="primary"
										type="submit"
									>
										Save
									</Button>
								</Grid>
							</Grid>
						</MesfModal.Actions>
					)}
				</form>
			</MesfModal>

			<Snackbar
				open={openAlertMessage}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity={messageType} onClose={handleClose}>
					{message}
				</Alert>
			</Snackbar>
			<ErrorModal onHide={() => setError("")} error={error} />
		</>
	);
};
