import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { getCatalogMoldSizes } from "../../../repositories/CatalogMoldSizeRepository";
import { TableMoldSize } from "../components/TableMoldSize";
import { useCatalogMoldSizeContext } from "../context/CatalogMoldSizeContext";
type Props = {};

export const CatalogMoldSizePage = (props: Props) => {
	const { state, actions } = useCatalogMoldSizeContext();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const getCatalogMoldSizesFromAPI = async () => {
		setIsLoading(true);
		const resp = await getCatalogMoldSizes();
		if (resp.ok) {
			actions.setMoldSizes(resp.data.tables[0].rows);
		} else {
			setError(resp.message);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getCatalogMoldSizesFromAPI();
	}, []);
	if (state.moldSizes) {
		return (
			<>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0 }}>
						<Paper
							elevation={1}
							style={{
								width: "100%",
								padding: "10px 0 20px",
								borderRadius: "0.75rem",
								height: "auto",
							}}
						>
							<TableMoldSize
								isLoading={isLoading}
								getCatalogMoldSizesFromAPI={getCatalogMoldSizesFromAPI}
							/>
						</Paper>
					</Grid>
				</Grid>
			</>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<ErrorModal error={error} onHide={() => setError("")} />
			<LazyLoading />
		</div>
	);
};
