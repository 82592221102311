import {
	default as React,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { getLastHeat } from "../../../meltshop/repositories/HeatRepository";
import { MechanicalByHeatReducer } from "./MechanicalByHeatReducer";

const MechanicalByHeatContext = createContext({
	state: MechanicalByHeatReducer.getInitialState(),
	actions: MechanicalByHeatReducer.actions,
});

export const useMechanicalByHeatContext = () =>
	useContext(MechanicalByHeatContext);

export const MechanicalByHeatProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [state, actions] = useComplexState({
		initialState: MechanicalByHeatReducer.getInitialState(),
		reducers: MechanicalByHeatReducer.caseReducers,
	});
	const GetLastHeatFromAPI = async () => {
		const resp = await getLastHeat();
		if (resp.ok) {
			if (
				resp.data !== null ||
				true ||
				resp.data.tables != undefined ||
				resp.data.tables[0].rows.length > 0
			) {
				actions.setSearchHeatNameText(resp.data.tables[0].rows[0].Name);
			}
		} else {
			console.log("something happened!");
		}
	};

	const urlParameters = useParams();
	const [heatNameParam] = useState(
		urlParameters.heatName === undefined ? "" : urlParameters.heatName,
	);
	const [validParam] = useState(
		urlParameters.valid === undefined ? true : urlParameters.valid === "true",
	);

	useEffect(() => {
		(async () => {
			if (state.heat === null && heatNameParam === "") {
				await GetLastHeatFromAPI();
			} else if (state.heat === null || state.heat?.Name !== heatNameParam) {
				actions.setSearchHeatNameText(heatNameParam);
			}

			if (state.showOnlyValid !== validParam)
				actions.setShowOnlyValid(validParam);
		})();
	}, [heatNameParam, validParam]);
	return (
		<MechanicalByHeatContext.Provider value={{ state, actions }}>
			{children}
		</MechanicalByHeatContext.Provider>
	);
};
