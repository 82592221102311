import { LazyLoading } from "@/controls/LazyLoading";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Acquisition from "../pages/acquisition";
import { CasterRoutes } from "./routes/caster.routes";
import { DelaysRoutes } from "./routes/delays.routes";
import { EafRoutes } from "./routes/eaf.routes";
import { LabRoutes } from "./routes/lab.routes";
import { LogbookRoutes } from "./routes/logbook.routes";
import { MeltshopRoutes } from "./routes/meltshop.routes";
import { ReportRoutes } from "./routes/report.routes";

const CustomRoutes = () => (
	<Routes>
		{MeltshopRoutes}
		{EafRoutes}
		{CasterRoutes}
		{LabRoutes}
		{ReportRoutes}
		{DelaysRoutes}
		{LogbookRoutes}

		<Route
			path="/acquisition-stats"
			element={
				<Suspense fallback={<LazyLoading></LazyLoading>}>
					<Acquisition />
				</Suspense>
			}
		/>
		<Route path={""} element={<Navigate to={"/meltshop-dashboard"} />}></Route>
	</Routes>
);

export { CustomRoutes };
