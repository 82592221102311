import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import {
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../../controls/LazyLoading";
import { ItemOption } from "../../../../controls/autoComplete";
import { HeatNavigation } from "../../../../controls/heatNavigation";
import { ResultSearchHeatName } from "../../../meltshop/models/ResultSearchHeatName";
import {
	getHeat,
	searchHeatNames,
} from "../../../meltshop/repositories/HeatRepository";
import { getCatalogGradeChemistryLimit } from "../../repositories/CatalogGradeChemistryLimitRepository";
import { getCatalogSampleTypes } from "../../repositories/CatalogSampleTypeRepository";
import { getChemistrySamples } from "../../repositories/ChemistrySampleRepository";
import { useChemistryByHeatContext } from "./ChemistryByHeatContext";
import { TableChemistries } from "./components/TableChemistries";

type Props = {};
export const ChemistryByHeatPage = (props: Props) => {
	const { state, actions } = useChemistryByHeatContext();
	const [isEditing, setIsEditing] = useState(false);
	const [ticks, setTicks] = useState(0);
	const [selectedOption, setSelectedOption] = useState<ItemOption | null>(null);
	const [selectedHeat, setSelectedHeat] = useState<ResultSearchHeatName | null>(
		null,
	);
	const location = useLocation();

	const updateHistory = (heatName: string, isValid: boolean) => {
		const paths = location.pathname.split("/");
		const newUrl =
			(paths[0] !== undefined && paths[0] !== "/" ? `${paths[0]}` : ``) +
			(paths[1] !== undefined && paths[1] !== "/" ? `${paths[1]}` : ``) +
			(paths[2] !== undefined && paths[2] !== "/" ? `${paths[2]}` : ``) +
			`/${heatName}` +
			`/${isValid}`;
		window.history.replaceState(null, "", newUrl);
	};
	const updateEditing = (newValue: boolean) => {
		setIsEditing(newValue);
	};
	const resetGrid = () => {
		actions.setChemistrySamples([]);
		actions.setGrades([]);
	};
	const nextHeat = () => {
		if (selectedHeat !== null && selectedHeat.NextHeatName !== null) {
			resetGrid();
			actions.setSearchHeatNameText(selectedHeat.NextHeatName);
		}
	};
	const previousHeat = () => {
		if (selectedHeat !== null && selectedHeat.PreviousHeatName !== null) {
			resetGrid();
			actions.setSearchHeatNameText(selectedHeat.PreviousHeatName);
		}
	};
	const showValidSamples = (event: React.ChangeEvent<HTMLInputElement>) => {
		actions.setShowOnlyValid(event.target.checked);
	};
	const refreshGrid = async () => {
		updateHistory(
			state.heat === null ? "" : state.heat?.Name ?? "",
			state.showOnlyValid,
		);
		if (state.heat === null || state.heat.Id === null) {
			resetGrid();
			return;
		}
		await getGradeLimitsFromAPI();
	};
	const searchHeatsFromAPI = async (searchText: string) => {
		const resp = await searchHeatNames(searchText);
		if (resp.ok) {
			if (resp.data !== null || true) {
				actions.setResultHeats(resp.data.tables[0].rows);
			}
		} else {
			console.log("something happened 1!");
		}
	};
	const getHeatFromAPI = async (id: number) => {
		if (id === 0) {
			actions.setHeat(null);
			return;
		}

		actions.setIsLoadingBackground(true);
		const resp = await getHeat(id);
		if (
			resp.ok &&
			resp.data !== null &&
			resp.data !== undefined &&
			resp.data.tables != undefined &&
			resp.data.tables[0].rows.length > 0
		) {
			actions.setHeat(resp.data.tables[0].rows[0]);
		} else {
			console.log("something happened 3!");
		}
		actions.setIsLoadingBackground(false);
	};
	const getCatalogSampleTypesFromAPI = async () => {
		actions.setIsLoadingPage(true);
		const resp = await getCatalogSampleTypes();
		if (resp.ok) {
			actions.setSampleTypes(resp.data.tables[0].rows);
		} else {
			console.log("something happened 2!");
		}
		actions.setIsLoadingPage(false);
	};
	const getGradeLimitsFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getCatalogGradeChemistryLimit(state.heat?.GradeId ?? 0);
		if (resp.ok) {
			actions.setGrades(resp.data.tables[0].rows);
		} else {
			console.log("something happened 4!");
		}
		actions.setIsLoadingGrid(false);
	};
	const getSampleFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getChemistrySamples(
			state.heat?.Id ?? 0,
			state.showOnlyValid ? "V" : null,
		);
		if (resp.ok) {
			actions.setChemistrySamples(resp.data.tables[0].rows);
		} else {
			console.log("something happened 5!");
		}
		actions.setIsLoadingGrid(false);
	};

	const heatDateString = useMemo(() => {
		return state.heat == null
			? ""
			: dxtToLocalServerTime(state.heat?.CreationDate, "yyyy-MM-dd HH:mm");
	}, [state.heat]);
	useEffect(() => {
		getCatalogSampleTypesFromAPI();
		const interval = setInterval(() => {
			setTicks((ticks) => ticks + 1);
		}, 20000);

		return () => {
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		(async () => {
			if (
				state.searchHeatNameText !== "" &&
				state.searchHeatNameText !== null
			) {
				await searchHeatsFromAPI(state.searchHeatNameText);
			} else {
				actions.setResultHeats([]);
			}
		})();
	}, [state.searchHeatNameText]);
	useEffect(() => {
		const itemList: ItemOption[] = [];
		(state.resultHeats ?? []).forEach((heat: ResultSearchHeatName) => {
			const itemOption: ItemOption = {
				id: heat.HeatId.toString(),
				name: heat.HeatName,
			};
			itemList.push(itemOption);
		});
		actions.setHeatOptions(itemList);
	}, [state.resultHeats]);
	useEffect(() => {
		let heat: ResultSearchHeatName | null = null;
		if (selectedOption !== null) {
			const id = false ? 0 : selectedOption.id;
			const searchedHeat = (state.resultHeats ?? []).find(
				(x) => x.HeatId === Number(id),
			);
			heat = searchedHeat === undefined ? null : searchedHeat;
		}
		setSelectedHeat(heat);
	}, [selectedOption]);
	useEffect(() => {
		(async () => {
			if (selectedHeat === null) actions.setHeat(null);
			await getHeatFromAPI(selectedHeat?.HeatId ?? 0);
		})();
	}, [selectedHeat]);
	useEffect(() => {
		(async () => {
			await refreshGrid();
		})();
	}, [state.heat]);
	useEffect(() => {
		(async () => {
			await refreshGrid();
		})();
	}, [state.showOnlyValid]);
	useEffect(() => {
		(async () => {
			await refreshGrid();
		})();
	}, [state.heat]);
	useEffect(() => {
		(async () => {
			await getSampleFromAPI();
		})();
	}, [state.grades]);
	useEffect(() => {
		(async () => {
			if (ticks > 0 && !isEditing) await refreshGrid();
		})();
	}, [ticks]);
	if (!state.isLoadingPage) {
		return (
			<>
				<LazyBackDropLoading open={state.isLoadingBackground} />
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0, padding: "15px" }}>
						<Grid item md={12} xs={12}>
							<Typography variant="h5" style={{ fontWeight: 600 }}>
								Chemistries
							</Typography>
						</Grid>
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							<Grid item md={3} xs={12}>
								<HeatNavigation
									onTextChanged={actions.setSearchHeatNameText}
									onValueChanged={setSelectedOption}
									heats={state.heatOptions}
									onNextClicked={nextHeat}
									onPreviousClicked={previousHeat}
									defaultText={""}
								/>
							</Grid>
							<Grid item xs="auto">
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											checked={state.showOnlyValid}
											onChange={showValidSamples}
										/>
									}
									label="Show valid samples"
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							style={{ marginTop: "15px", marginBottom: "15px" }}
						>
							<Grid item md={2} xs={12}>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">Heat Start</span>
									</div>
									<input
										type="text"
										className={"form-control"}
										readOnly={true}
										value={heatDateString}
									></input>
								</div>
							</Grid>
							<Grid item md={2} xs={12}>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">Grade</span>
									</div>
									<input
										type="text"
										className={"form-control"}
										readOnly={true}
										value={state.heat?.GradeName ?? ""}
									></input>
								</div>
							</Grid>
							<Grid item md={2} xs={12}>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">Melted Grade</span>
									</div>
									<input
										type="text"
										className={"form-control"}
										readOnly={true}
										value={state.heat?.MeltedGradeName ?? ""}
									></input>
								</div>
							</Grid>
							<Grid item md={2} xs={12}>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">Status</span>
									</div>
									<input
										type="text"
										className={"form-control"}
										readOnly={true}
										value={state.heat?.StatusCode ?? ""}
									></input>
								</div>
							</Grid>
						</Grid>
						<Divider />
						<TableChemistries
							loadingGrid={state.isLoadingGrid}
							heat={state.heat}
							chemistrySamples={state.chemistrySamples ?? []}
							gradeLimits={state.grades ?? []}
							sampleTypes={state.sampleTypes ?? []}
							updateEditing={updateEditing}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
	return <LazyLoading />;
};
