import {
	ErrorModal,
	MesfModal,
	TreePickerControl,
	useAssetContext,
} from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogProps,
	FormControlLabel,
	Snackbar,
	TextField,
	Typography,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { DelayCategorySelector } from "../../../../../controls/selectors/DelayCategorySelector";
import { useCatalogCategoryContext } from "../../../categories/context/CategoriesContext";
import { CatalogCategory } from "../../../models/CatalogCategory";
import { DelayCodeAPI } from "../../../models/DelayCodeAPI";
import { DATA_DELAY_INITIAL_VALUES } from "../../../models/InitialValueDataDelay";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import {
	getDelay,
	updateDelayData,
} from "../../repositories/ConfigurationDelayRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiPaper-root": {
			height: "auto",
		},
	},
	customDialog: {
		"& .MuiDivider-root:last-child": {
			display: "none",
		},
	},
	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const EditDelay = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const {
		state: {
			delayAreaAsset,
			delayNodeSelectedInTree,
			delayCodes,
			allDelayCodes,
		},
		actions: {
			setDelayParentDelayIdToCreateDelay,
			setDelayParentNameToCreateDelay,
			setDelayNameToCreateDelay,
		},
	} = useConfigurationDelayCodeContext();
	const {
		state: { categories },
	} = useCatalogCategoryContext();

	const {
		state: { allAssets },
	} = useAssetContext();

	const {
		control,
		reset,
		setValue,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<DelayCodeAPI>({
		defaultValues: delayNodeSelectedInTree
			? delayNodeSelectedInTree
			: DATA_DELAY_INITIAL_VALUES,
	});

	const handleCancel = () => {
		onHide(false);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
	};
	const changeParentDelayId = (value: any, description: any) => {
		setValue("ParentDelayCodeId", parseInt(value));
		setDelayParentDelayIdToCreateDelay(parseInt(value));
		setDelayParentNameToCreateDelay(description);
	};
	const updateAssetData = async (data: DelayCodeAPI) => {
		setIsSubmitLoading(true);
		const res = await updateDelayData(data);
		if (res.ok) {
			setIsSubmitLoading(false);
			onHide(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	const handleFormSubmit = handleSubmit(updateAssetData);

	useEffect(() => {
		if (show) {
			setIsLoading(true);
			reset();
			if (delayNodeSelectedInTree.DelayCodeId)
				(async () => {
					const response = await getDelay(
						delayNodeSelectedInTree.DelayCodeId ?? 0,
					);
					if (response.ok) {
						if (
							response === undefined ||
							response.data === undefined ||
							response.data.tables === undefined ||
							response.data.tables[0].rows === undefined ||
							response.data.tables[0].rows[0] === undefined
						)
							return;
						const delayCode = response.data.tables[0].rows[0];
						if (delayCode) {
							setValue("ParentDelayCodeId", delayCode.ParentDelayCodeId);
							const parentDelayNode = allDelayCodes.find(
								(node: any) =>
									node.DelayCodeId ===
									delayNodeSelectedInTree?.ParentDelayCodeId,
							);
							setValue("DelayAreaAssetId", delayCode.DelayAreaAssetId);
							setValue("ParentDelayCodeName", parentDelayNode?.DelayCodeName);
							setValue("DelayCodeId", delayCode.DelayCodeId);
							setValue("DelayCodeName", delayCode.DelayCodeName);
							setValue("DelayCategoryId", delayCode.DelayCategoryId);
							setValue("IsBranch", delayCode?.IsBranch);
							setIsLoading(false);
						}
					}
				})();
		}
	}, [show]);

	if (!isLoading)
		return (
			<div className={classes.root}>
				<MesfModal
					maxWidth={maxWidth}
					title="EDIT DELAY CODE"
					open={show}
					handleClose={() => handleCancel()}
				>
					<form onSubmit={handleFormSubmit}>
						<Grid container style={{ alignItems: "center" }}>
							<MesfModal.Content dividers style={{ padding: "10px 30px" }}>
								<Grid container spacing={1}>
									<Grid item md={3} sm={12} xs={10}>
										<Controller
											name="DelayAreaAssetId"
											control={control}
											render={() => (
												<>
													<TextField
														label="Asset"
														variant="outlined"
														value={delayAreaAsset?.AssetName}
														disabled
														margin="dense"
														size="small"
														fullWidth
													/>
												</>
											)}
										/>
									</Grid>
									<Grid item md={9} sm={12} xs={10}>
										<Controller
											name="DelayCodeName"
											control={control}
											rules={{ required: "Name is required" }}
											render={({ field: { onChange, value } }) => (
												<>
													<TextField
														label="Delay Code Name"
														variant="outlined"
														error={!!errors.DelayCodeName}
														fullWidth
														margin="dense"
														autoComplete="off"
														onChange={onChange}
														value={value}
													/>
													{errors.DelayCodeName && (
														<span className={classes.errorLabel}>
															{errors.DelayCodeName.message}
														</span>
													)}
												</>
											)}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={1}>
									<Grid item md={12} xs={12}>
										<Controller
											name="ParentDelayCodeName"
											control={control}
											render={({ field: { value } }) => (
												<>
													{delayNodeSelectedInTree.ParentDelayCodeId && (
														<TreePickerControl
															inputTitle="Parent"
															title="Parent"
															dataSource={delayCodes}
															value={
																delayNodeSelectedInTree?.ParentDelayCodeId || 1
															}
															description={value || "Delay Charge"}
															onSelect={changeParentDelayId}
															selectBranch={true}
															selectActive={false}
															selectInternal={false}
														/>
													)}
												</>
											)}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<Controller
											name="DelayCategoryId"
											control={control}
											rules={{ required: "Category is required" }}
											render={({ field }) => (
												<DelayCategorySelector
													value={field.value}
													onChange={field.onChange}
													assetId={
														(getValues("DelayAreaAssetId") as number) || null
													}
													error={errors.DelayCategoryId?.message || ""}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6} md={6}>
										<Controller
											name="IsBranch"
											control={control}
											defaultValue={false}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value as boolean}
															onChange={(e) => field.onChange(e.target.checked)}
															name="IsAsset"
															color="primary"
															disabled={true}
														/>
													}
													label="Has Children"
												/>
											)}
										/>
									</Grid>
								</Grid>
							</MesfModal.Content>
							<Grid item md={12} xs={12}>
								<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
									<Grid
										container
										justifyContent="flex-end"
										style={{ margin: 0 }}
									>
										<Grid
											container
											item
											style={{
												marginLeft: "auto",
												marginTop: 5,
												marginBottom: 0,
											}}
										>
											<Grid
												item
												md={6}
												xs={12}
												style={{ paddingLeft: "0", paddingRight: "16px" }}
											>
												<Button
													fullWidth
													variant="contained"
													color="default"
													onClick={() => handleCancel()}
												>
													Cancel
												</Button>
											</Grid>
											<Grid item md={6} xs={12}>
												<Button
													fullWidth
													startIcon={
														isSubmitLoading && <CircularProgress size="1rem" />
													}
													disabled={isSubmitLoading}
													variant="contained"
													color="primary"
													type="submit"
												>
													Save
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</MesfModal.Actions>
								<ErrorModal error={error} onHide={() => setError("")} />
							</Grid>
						</Grid>
					</form>
				</MesfModal>
			</div>
		);
	else
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<LazyLoading />
			</div>
		);
};
