import {
	ErrorModal,
	ShiftDayNavigatorControl,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import get from "lodash-es/get";
import { useEffect, useState } from "react";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { getProcessesByShift } from "../repositories/ProductionRepository";
import { useCasterProcessesContext } from "./CasterProcessesContext";
import { CasterProcessesTable } from "./components/CasterProcessesTable";

type Props = {};
export const CasterProcessesPage = (props: Props) => {
	const { state, actions } = useCasterProcessesContext();
	const [showDeletedProcesses, setShowDeletedProcesses] = useState(false);

	const { shiftInfo } = useShiftNavigator();

	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		if (shiftInfo) {
			const resp = await getProcessesByShift(
				shiftInfo.CurrentShiftId,
				showDeletedProcesses,
			);
			if (resp.ok && resp.data.tables.length > 0) {
				actions.setProcesses(resp.data.tables[0].rows);
			} else {
				console.log("something happened 2");
			}
			actions.setIsLoadingGrid(false);
		}
	};

	useEffect(() => {
		(async () => {
			await getDataFromAPI();
		})();
	}, [shiftInfo, showDeletedProcesses]);

	if (!state.isLoadingPage) {
		return (
			<>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "15px 20px 10px",
						borderRadius: "0.75rem",
						height: "93vh",
					}}
				>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item md={12} xs={12} style={{ margin: 0 }}>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h5" style={{ fontWeight: 600 }}>
										Caster Processes
									</Typography>
								</Grid>
							</Grid>
							<Grid item md={12} xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<ShiftDayNavigatorControl useRouter />
									<Grid item xs={4} style={{ margin: 0, padding: "15px" }}>
										<FormGroup>
											<FormControlLabel
												control={
													(
														<Checkbox
															checked={showDeletedProcesses}
															color="primary"
															onChange={(event) =>
																setShowDeletedProcesses(event.target.checked)
															}
														/>
													) as any
												}
												label="Show deleted processes"
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
							<Divider style={{ marginBottom: 10 }} />
							<ErrorModal
								error={state.errorMessage}
								onHide={() => actions.setErrorMessage("")}
							/>
							<NotificationSnackBar
								message={state.notificationMessage}
								onHide={() => actions.setNotificationMessage("")}
							/>
							<CasterProcessesTable
								loadingGrid={state.isLoadingGrid}
								rows={state.processes || []}
								refreshData={getDataFromAPI}
							/>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
	return <LazyLoading />;
};
