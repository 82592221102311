import { LazyLoading } from "@/controls/LazyLoading";
import {
	ErrorModal,
	dxtToLocalServerTime,
	getShiftByParameters,
} from "@dexteel/mesf-core";
import { getMomentTz } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Snackbar,
	TextField,
	makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Controller, get, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useLogbookContext } from "../../context/logbookContext";
import { useSearchEntries } from "../../hooks/useSearchEntries";
import { useSearchEntry } from "../../hooks/useSearchEntry";
import { Entry } from "../../models/Entry";
import { ENTRY_INITIAL_VALUES } from "../../models/EntryInitialValue";
import { upsertEntry } from "../../repository/LogbookRepository";
import { ContentInput } from "./content-input";

const moment = getMomentTz();
const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"& fieldset": {
				borderColor: "rgba(0, 0, 0, 0.23)",
			},
		},
		"& .MuiSelect-iconOutlined": {
			display: "none",
		},
	},
	datePicker: {
		width: "100%",
		"& .MuiInputBase-root": {
			height: "40px",
			paddingTop: 5,
		},
		"& .MuiOutlinedInput-root": {
			height: "40px",
		},
	},
	paper: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "20px 20px 10px",
		marginTop: 10,
		overflowX: "hidden",
	},
	content: {
		flexGrow: 1,
		overflowY: "auto",
		overflowX: "hidden",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	disabledInput: {
		"& .MuiInputBase-root.Mui-disabled": {
			color: "rgba(0, 0, 0, 0.6)",
		},
		"& .MuiInputLabel-root.Mui-disabled": {
			color: "rgba(0, 0, 0, 0.6)",
		},
	},
	titleInput: {
		fontSize: "1.5em",
		fontWeight: "bold",
		"& .MuiInputBase-input": {
			padding: "0.5rem",
		},
	},
}));

export const EntryViewer = () => {
	const classes = useStyles();
	const [addSuccess, setAddSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [isEntryLoading, setIsEntryLoading] = useState<boolean>(false);
	const [entryError, setEntryError] = useState<string>("");
	const [shiftAndCrew, setShiftAndCrew] = useState<any>(null);
	const [errorEntries, setErrorEntries] = useState<string>("");
	const { EntryID, params } = useParams();
	const isNewEntry = EntryID === "new";
	const navigate = useNavigate();

	const searchEntries = useSearchEntries({
		setIsLoading,
		setError: setErrorEntries,
	});

	const searchEntry = useSearchEntry({
		setIsEntryLoading,
		setEntryError,
		EntryID: EntryID as string,
	});

	const {
		state: {
			EntrySelectedInTable,
			EntrySelectedInTable: {
				ShiftId,
				Title,
				Content,
				CreateTimestamp,
				UpdateTimestamp,
				Shift,
				Crew,
				Author,
				UpdatedBy,
			},
			isEditing,
		},
		actions: { setEntrySelectedInTable, setIsEditing },
	} = useLogbookContext();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isValid },
		reset,
	} = useForm<Entry>({
		defaultValues: ENTRY_INITIAL_VALUES,
	});

	const convertToServerTime = useCallback((utcTime: string | Date) => {
		if (utcTime) {
			const serverTimeString = dxtToLocalServerTime(
				utcTime,
				"MM/dd/yy hh:mm a",
			);
			return moment(serverTimeString, "MM/DD/YY hh:mm a").toDate();
		}
		return null;
	}, []);

	const handleCancel = async () => {
		reset();
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setAddSuccess(false);
	};

	const onSubmit = async (data: Entry) => {
		setIsSubmitLoading(true);
		const { EntryID, ShiftId, Title, Content, Author, CreateTimestamp } = data;
		const res = await upsertEntry(
			EntryID,
			ShiftId,
			Title,
			Content,
			CreateTimestamp as Date,
			Author as string,
		);
		if (res.ok) {
			setIsSubmitLoading(false);
			setAddSuccess(true);
			searchEntries();
			setEntrySelectedInTable(res.data.tables[0].rows[0]);
			if (isNewEntry) {
				const newEntry = res.data.tables[0].rows[0];
				setEntrySelectedInTable(newEntry);
				const currentPath = location.pathname;
				const newPath = currentPath.replace(
					/\/new(?=\/|$)/,
					`/${newEntry.EntryID}`,
				);
				const newUrl = `${newPath}${location.search}`;
				getShiftAndCrew();
				navigate(newUrl, { replace: true });
			}
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	const getShiftAndCrew = async () => {
		const res = await getShiftByParameters({
			productionDate: new Date(),
			shiftId: 0,
		});
		if (res.ok) {
			const data = get(res, "data", null);
			setShiftAndCrew(data);
		}
	};

	const toggleEdit = () => {
		setIsEditing(!isEditing);
	};

	const toggleClose = () => {
		setEntrySelectedInTable([]);
		navigate(`/logbook`, { replace: true });
	};

	useEffect(() => {
		if (shiftAndCrew) {
			setValue("Shift", shiftAndCrew?.CurrentShift);
			setValue("Crew", shiftAndCrew?.CurrentCrew);
			setValue("ShiftId", shiftAndCrew?.CurrentShiftId);
		}
	}, [shiftAndCrew]);

	useEffect(() => {
		if (EntrySelectedInTable && !isNewEntry) {
			reset({
				EntryID: EntrySelectedInTable.EntryID,
				ShiftId: EntrySelectedInTable.ShiftId,
				Title: EntrySelectedInTable.Title,
				Shift: EntrySelectedInTable.Shift,
				Crew: EntrySelectedInTable.Crew,
				Author: EntrySelectedInTable.Author,
				Content: EntrySelectedInTable.Content,
				CreateTimestamp: convertToServerTime(
					EntrySelectedInTable.CreateTimestamp as Date,
				),
				UpdateTimestamp: EntrySelectedInTable.UpdateTimestamp,
				UpdatedBy: EntrySelectedInTable.UpdatedBy,
			});
		}
	}, [EntrySelectedInTable, isNewEntry]);

	useEffect(() => {
		if (isNewEntry) {
			reset({
				...ENTRY_INITIAL_VALUES,
				CreateTimestamp: new Date(),
			});
			getShiftAndCrew();
		} else if (EntryID) {
			handleCancel();
			searchEntry();
			const convertedTime = convertToServerTime(CreateTimestamp as Date);
			reset({
				EntryID: parseInt(EntryID),
				ShiftId,
				Title,
				Shift,
				Crew,
				Author,
				Content,
				CreateTimestamp: convertedTime as Date,
				UpdateTimestamp,
				UpdatedBy,
			});
		}
		setIsSubmitLoading(false);
	}, [EntryID, isNewEntry]);

	return (
		<>
			{(isNewEntry || EntryID) && (
				<Paper elevation={1} className={classes.paper}>
					{isEntryLoading ? (
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<LazyLoading />
						</div>
					) : (
						<form
							onSubmit={handleSubmit(onSubmit)}
							style={{
								height: "100%",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<DialogTitle
								style={{
									padding: "0px",
									marginLeft: 0,
									width: "100%",
									flexBasis: "10%",
									minHeight: "10%",
								}}
							>
								<Grid item md={12} xs={12}>
									<Controller
										name="Title"
										control={control}
										rules={{
											validate: (value) => value !== "",
										}}
										render={({ field: { onChange, value } }) => (
											<TextField
												label="Title"
												variant="standard"
												fullWidth
												error={!!errors.Title}
												value={value}
												onChange={(e) => onChange(e)}
												autoFocus={isNewEntry}
												focused={isNewEntry}
											/>
										)}
									/>
								</Grid>
							</DialogTitle>
							<DialogContent
								dividers
								style={{
									padding: "20px 0 0",
									flexGrow: 1,
									flexBasis: "90%",
									overflowY: "auto",
									display: "flex",
									flexDirection: "column",
									borderTop: 0,
								}}
								className={classes.root}
							>
								<Grid
									container
									spacing={2}
									style={{
										width: "100%",
										marginBottom: "0px",
										paddingLeft: 0,
										display: "flex",
										justifyContent: "space-between",
										margin: 0,
									}}
								>
									<Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
										<Controller
											name="CreateTimestamp"
											control={control}
											render={({ field: { value, onChange } }) => (
												<DatePicker
													label="Creation Time"
													format="MM/DD/YYYY hh:mm a"
													value={moment(value)}
													onChange={(newValue: Moment | null) => {
														if (newValue && newValue.isValid()) {
															onChange(newValue.toDate());
														}
													}}
													disabled={true}
													slotProps={{
														textField: {
															variant: "outlined",
															size: "small",
															className: classes.datePicker,
															disabled: true,
														},
													}}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} md={2}>
										<Controller
											name="Shift"
											control={control}
											render={({ field }) => (
												<TextField
													label="Shift"
													variant="outlined"
													disabled
													fullWidth
													autoComplete="off"
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														classes: {
															root: classes.disabledInput,
														},
														style: { height: "40px" },
													}}
													{...field}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} md={2}>
										<Controller
											name="Crew"
											control={control}
											render={({ field }) => (
												<TextField
													label="Crew"
													variant="outlined"
													disabled
													fullWidth
													autoComplete="off"
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														classes: {
															root: classes.disabledInput,
														},
														style: { height: "40px" },
													}}
													{...field}
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={2}
										style={{ paddingRight: 0, marginRight: "0px" }}
									>
										<Controller
											name="Author"
											control={control}
											rules={{
												required: "Author is required",
											}}
											render={({ field: { onChange, value } }) => (
												<TextField
													label="Author"
													variant="outlined"
													fullWidth
													error={!!errors.Author}
													helperText={errors.Author?.message}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														classes: {
															root: classes.disabledInput,
														},
														style: { height: "40px" },
													}}
													autoComplete="off"
													value={value}
													onChange={onChange}
													disabled={!isNewEntry}
													className={isNewEntry ? classes.inputEditable : ""}
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={12}
									className={classes.inputEditable}
									style={{ flexGrow: 1, height: "calc(100% - 200px)" }}
								>
									<Grid item xs={12} style={{ padding: 0, height: "100%" }}>
										<Controller
											name="Content"
											control={control}
											render={({ field: { onChange, value } }) => (
												<ContentInput
													UpdatedBy={UpdatedBy as string}
													UpdateTimestamp={UpdateTimestamp as Date}
													setContentSelectedInTable={onChange}
													content={value as string}
													isEditing={isEditing}
													isNewEntry={isNewEntry}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<Grid
								container
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									marginTop: 10,
									flexBasis: "10%",
									minHeight: "10%",
									paddingLeft: 0,
								}}
							>
								<Grid item container md={6} xs={12} spacing={2}>
									<Grid item md={6} xs={12}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={toggleClose}
										>
											Close
										</Button>
									</Grid>
									<Grid item md={6} xs={12}>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											onClick={toggleEdit}
										>
											{isEditing ? "Preview" : "Edit"}
										</Button>
									</Grid>
								</Grid>
								<Grid
									item
									container
									md={6}
									xs={12}
									style={{ display: "flex", justifyContent: "flex-end" }}
								>
									<Grid item md={8} xs={12}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={!isValid || isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											{isNewEntry ? "Create" : "Save"}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Paper>
			)}
			<Snackbar open={addSuccess} autoHideDuration={2500} onClose={handleClose}>
				<Alert severity="info" onClose={handleClose}>
					{isNewEntry
						? "Entry created successfully"
						: "Entry updated successfully"}
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
			<ErrorModal error={entryError} onHide={() => setEntryError("")} />
			<ErrorModal error={errorEntries} onHide={() => setErrorEntries("")} />
		</>
	);
};
