import { get } from "react-hook-form";
import { useLogbookContext } from "../context/logbookContext";
import { getShiftsAndCrews } from "../repository/LogbookRepository";

export const useSearchCrews = () => {
	const {
		actions: { setCrewList },
	} = useLogbookContext();
	return async () => {
		setCrewList([]);
		const res = await getShiftsAndCrews();
		if (res.ok) {
			const rowsSource = get(res, "data", []);
			const crewTable = rowsSource?.tables[1];
			const uniqueCrews = new Set(crewTable.rows.map((row: any) => row.Crew));

			const crewsListFromApi = [
				{ Crew: "All" },
				...Array.from(uniqueCrews).map((crew) => ({ Crew: crew })),
			];

			setCrewList(crewsListFromApi);
		}
	};
};
