import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { DynamicGenericReportFilter } from "../models/GenericReportFilter";

export const getGenericReportByCode = async (code: string) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "Code", value: code });

	try {
		const resp = await apiService.call("[MES].[GetReport]", parameters);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getGenericReportData = async (
	filters: DynamicGenericReportFilter,
	storeProcedure: string,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	if (filters.showStartDate)
		parameters.push({ name: "StartDate", value: filters.startDate });
	if (filters.showEndDate)
		parameters.push({ name: "EndDate", value: filters.endDate });
	Object.keys(filters).forEach((key) => {
		if (
			key !== "startDate" &&
			key !== "endDate" &&
			key !== "showStartDate" &&
			key !== "showEndDate"
		)
			parameters.push({
				name: key,
				value: filters[key] === "" ? null : filters[key],
			});
	});

	try {
		const resp = await apiService.call(storeProcedure, parameters);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
