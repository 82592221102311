import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-enterprise";
import { Probe } from "../../../../models/eaf.models";
import { EditAndDeleteButtons } from "../../components/EditAndDeleteButtons";
import { ManualAutoButton } from "../../components/ManualAutoButton";

type Props = {
	deleteProbe: (probeId: number) => void;
	editProbe: (probeId: number) => void;
};
const moment = getMomentTz();

export const useTableData = ({ deleteProbe, editProbe }: Props) => {
	const columns: ColGroupDef[] = [
		{
			headerName: "Probes",
			children: [
				{
					field: "manual-auto",
					headerName: " ",
					flex: 1,
					minWidth: 20,
					maxWidth: 40,
					cellRenderer: ManualAutoButton,
					cellRendererParams: (params: ICellRendererParams) => ({
						isManual: (params.data as Probe).IsProbeManual,
					}),
				},
				{
					field: "Timestamp",
					headerName: "Time",
					flex: 1,
					minWidth: 60,
					valueGetter: (params: any) => {
						if (params.data.Timestamp) {
							return dxtToLocalServerTime(params.data.Timestamp, "HH:mm");
						}
					},
				},
				{
					field: "Temperature",
					headerName: "Temp",
					flex: 1,
					minWidth: 60,
				},
				{
					field: "Oxygen",
					headerName: "O2",
					flex: 1,
					minWidth: 50,
				},
				{
					field: "actions",
					headerName: " ",
					flex: 1,
					minWidth: 60,
					maxWidth: 80,
					cellRenderer: EditAndDeleteButtons,
					cellRendererParams: (params: ICellRendererParams) => ({
						deleteItem: deleteProbe,
						edit: editProbe,
						id: (params.data as Probe).ProbeId,
					}),
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	return { columns, defaultColDef };
};
