import {
	MenuOptionType,
	dxtToLocalServerTime,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { useNavigate } from "react-router-dom";

import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import MemoryIcon from "@material-ui/icons/Memory";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { get } from "lodash-es";
import { ReactNode, useCallback, useState } from "react";
import { useEAFProcessesContext } from "../context/EAFProcessesContext";
import { ProcessByShift } from "../models/ProcessByShift";
const moment = getMomentTz();

export const useEAFOptionFunctions = () => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showActiveModal, setShowActiveModal] = useState(false);

	const [showLinkModal, setShowLinkModal] = useState(false);
	const [showUnlinkModal, setShowUnlinkModal] = useState(false);

	const [messageDeleteModal, setMessageDeleteModal] = useState("");
	const [messageActiveModal, setMessageActiveModal] = useState("");
	const [messageUnlinkModal, setMessageUnlinkModal] = useState("");

	const {
		state: {},
		actions: { setSelectedProcess },
	} = useEAFProcessesContext();

	const { shiftInfo } = useShiftNavigator();
	const navigate = useNavigate();
	const CurrentStart = get(shiftInfo, "CurrentStart", null);

	//Create
	const createData = useCallback(() => {
		setSelectedProcess({
			ProcessId: 0,
			eafOpen: moment.utc(CurrentStart).toDate(),
			eafClose: moment.utc(CurrentStart).add(180, "minutes").toDate(),
			eafOpenString: "",
			eafCloseString: "",
			HeatId: 0,
			HeatName: "",
			IsActive: true,
		});
		setShowEditModal(true);
	}, [shiftInfo?.CurrentProductionDate, setSelectedProcess]);

	//Edit
	const editData = (data: ProcessByShift) => {
		setSelectedProcess(data);
		setShowEditModal(true);
	};

	//Link
	const linkData = (data: ProcessByShift) => {
		setSelectedProcess(data);
		setShowLinkModal(true);
	};

	//Unlink
	const unlinkData = (data: ProcessByShift) => {
		setSelectedProcess(data);
		const dateOpen = dxtToLocalServerTime(
			`${data.IsOpen}Z`,
			"YYYY/MM/dd HH:mm",
		);
		setMessageUnlinkModal(
			`The heat ${data.HeatName} from ${dateOpen} will be unlinked.  Do you want to continue?`,
		);
		setShowUnlinkModal(true);
	};

	//Active Data
	const activeData = (data: ProcessByShift) => {
		setSelectedProcess(data);
		const dateOpen = dxtToLocalServerTime(
			`${data.IsOpen}Z`,
			"YYYY/MM/dd HH:mm",
		);
		setMessageActiveModal(
			"The heat " +
				data.HeatName +
				" from " +
				dateOpen +
				" will be activated.  Do you want to continue?",
		);
		setShowActiveModal(true);
	};

	const inactiveData = (data: ProcessByShift) => {
		setSelectedProcess(data);
		const dateOpen = dxtToLocalServerTime(data.Start, "yyyy/MM/dd HH:mm");

		setMessageDeleteModal(
			"The heat " +
				data.HeatName +
				" from " +
				dateOpen +
				" will be deleted.  Do you want to continue?",
		);
		setShowDeleteModal(true);
	};

	//Toggle Status
	const toggleStatusData = (data: ProcessByShift) => {
		if (data.IsActive) inactiveData(data);
		else activeData(data);
	};
	//navigate functions
	const goToHeats = (HeatId: string) => {
		if (HeatId)
			navigate(`/meltshop-heats/${shiftInfo?.CurrentShiftId}?heatId=${HeatId}`);
	};

	const goToDataEntry = (ProcessId: number | null) => {
		if (ProcessId === null) return;
		navigate(`/eaf-data-entry/${ProcessId}`);
	};

	//Get Menu options
	const getMenuOptions = (data: ProcessByShift) => {
		let options: MenuOptionType[] = [];

		if (data.ProcessId && data.IsActive) {
			options.push({
				name: "See in Data Entry",
				key: "go_to_eaf",
				onClick: () => {
					//GO TO EAF
					goToDataEntry(data.ProcessId);
				},
				style: { fontWeight: "bold" },

				icon: <MemoryIcon />,
			});
		}
		if (data.HeatId && data.IsActive) {
			options = options.concat([
				{
					name: "See in Heat Table",
					key: "go_to_heat",
					onClick: () => goToHeats(data.HeatId as string),
					icon: (
						<i
							style={{ fontSize: "1.5em", marginLeft: "-3px" }}
							className="material-icons"
						>
							thermostat
						</i>
					),
				},
				{
					name: "Unlink Process",
					key: "unlink",
					onClick: () => unlinkData(data),
					icon: <LinkOffIcon />,
				},
			]);
		}
		if (!data.HeatId && data.IsActive) {
			options = options.concat([
				{
					name: "Link Process",
					key: "link",
					onClick: () => linkData(data),
					icon: <LinkIcon />,
				},
			]);
		}
		options.push(
			{
				name: "Edit Process",
				key: "toggle_status",
				onClick: () => editData(data),
				icon: <EditIcon />,
			},
			{
				name: data.IsActive ? "Delete Process" : "Undelete Process",
				key: "toggle_status",
				onClick: () => toggleStatusData(data),
				icon: data.IsActive ? <DeleteIcon /> : <AddBoxIcon />,
			},
			{
				name: "New Process",
				key: "create_new_process",
				onClick: () => createData(),
				icon: <PlaylistAddIcon />,
			},
		);
		return options;
	};

	return {
		createData,
		editData,
		linkData,
		unlinkData,
		toggleStatusData,
		getMenuOptions,
		goToHeats,
		goToDataEntry,
		showActiveModal,
		showDeleteModal,
		showEditModal,
		showLinkModal,
		showUnlinkModal,
		messageActiveModal,
		messageDeleteModal,
		messageUnlinkModal,
		setShowActiveModal,
		setShowDeleteModal,
		setShowEditModal,
		setShowLinkModal,
		setShowUnlinkModal,
		setMessageActiveModal,
		setMessageDeleteModal,
		setMessageUnlinkModal,
	};
};
