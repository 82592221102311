import { isNil } from "lodash-es";

export const formatNumberWithDecimals = (
	number: number | null,
	pattern: string,
) => {
	if (isNil(number)) return;

	// Determine the format according to the received pattern (Ex: XX.XX)
	let integerDigits = (pattern.match(/X/g) || []).length;
	let decimalDigits = (pattern.match(/\./g) || []).length
		? pattern.split(".")[1].length
		: 0;

	// Format the number according the pattern
	let formattedNumber = Number(number).toFixed(decimalDigits);

	//If the number of integer digits is more than in the pattern, truncate the integer digits
	let parts = formattedNumber.split(".");
	let integerPart = parts[0];
	let decimalPart = parts[1] || "";

	if (integerPart.length > integerDigits) {
		integerPart = integerPart.substr(integerPart.length - integerDigits);
	} // Reconstruir el número con los dígitos enteros y decimales formateados
	return decimalDigits > 0 ? `${integerPart}.${decimalPart}` : `${integerPart}`;
};
