import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useRef,
	useState,
} from "react";
import { ContextMenu } from "./ContextMenu";
import { MenuOptionType } from "./models/MenuOptionType";

type OptionsGetterContextMenuMESF = (data: any) => MenuOptionType[];

// Define the shape of your context value
interface ContextMenuMESFContextType {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	position: { x: number; y: number };
	setPosition: Dispatch<SetStateAction<{ x: number; y: number }>>;
	onClickOutside: () => void;
	showContextMenu: (e: React.MouseEvent, data: any) => void;
	options: MenuOptionType[];
	setOptions: Dispatch<SetStateAction<MenuOptionType[]>>;
	setOptionsGetter: Dispatch<SetStateAction<any>>;
}

const useInternalContextMenuMESF = (): ContextMenuMESFContextType => {
	const [show, setShow] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [options, setOptions] = useState<MenuOptionType[]>([]);

	const optionsGetterRef = useRef<OptionsGetterContextMenuMESF>(() => []);

	const setOptionsGetter = (optionsGetter: OptionsGetterContextMenuMESF) => {
		optionsGetterRef.current = optionsGetter;
	};

	const onClickOutside = () => {
		setShow(false);
	};

	const showContextMenu = (e: React.MouseEvent, data: any) => {
		e.preventDefault();
		e.stopPropagation();
		setShow(true);

		setPosition({ x: e.clientX, y: e.clientY });
		setOptions(optionsGetterRef.current(data));
	};

	return {
		show,
		setShow,
		position,
		setPosition,
		onClickOutside,
		showContextMenu,
		options,
		setOptions,
		setOptionsGetter,
	};
};

export const useContextMenuMESF = (
	optionsGetter?: OptionsGetterContextMenuMESF,
) => {
	const ctx = useContext(ContextMenuMESFContext);
	if (optionsGetter) ctx.setOptionsGetter(optionsGetter);
	return ctx;
};

// Create the context with the defined type
const ContextMenuMESFContext = createContext<ContextMenuMESFContextType>({
	show: false,
	setShow: () => {},
	position: { x: 0, y: 0 },
	setPosition: () => {},
	onClickOutside: () => {},
	showContextMenu: () => {},
	options: [],
	setOptions: () => {},
	setOptionsGetter: () => {},
});

export const ContextMenuMESFProvider = ({
	children,
}: { children: ReactNode }) => {
	const contextMenu = useInternalContextMenuMESF();

	return (
		<ContextMenuMESFContext.Provider value={contextMenu}>
			<div
				onKeyDown={() => contextMenu.onClickOutside()}
				onClick={() => contextMenu.onClickOutside()}
			>
				{children}
			</div>
			<ContextMenu />
		</ContextMenuMESFContext.Provider>
	);
};
