import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";

export interface CommentDialogRawProps {
	title: string;
	text?: string;
	open: boolean;
	onClose: (value?: string, text?: string) => void;
}
export const CommentDialogRaw = ({
	title,
	text,
	open,
	onClose,
	...other
}: CommentDialogRawProps) => {
	const [value, setValue] = React.useState(text);
	const handleCancel = () => {
		onClose("Cancel");
	};
	const handleOk = () => {
		onClose("OK", value);
	};
	useEffect(() => {
		if (!open) {
			setValue(text);
		}
	}, [text, open]);

	return (
		<Dialog maxWidth="sm" fullWidth open={open} {...other}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				<TextField
					autoFocus
					margin="dense"
					type="text"
					fullWidth
					variant="filled"
					multiline
					maxRows={4}
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel}>
					Cancel
				</Button>
				<Button onClick={handleOk}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};
