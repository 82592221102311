import { createSlice } from "@reduxjs/toolkit";
import { CatalogMaterial } from "../../models/CatalogMaterial";

const CatalogMaterialInitialState: {
	materials: CatalogMaterial[] | null;
} = {
	materials: null,
};

export const CatalogMaterialReducer = createSlice({
	name: "__",
	initialState: CatalogMaterialInitialState,
	reducers: {
		setMaterials(state, { payload }) {
			state.materials = payload;
		},
	},
});
