import * as React from "react";
import { CatalogSampleTypeProvider } from "./CatalogSampleTypeContext";
import { CatalogSampleTypePage } from "./CatalogSampleTypePage";

type Props = {};
const CatalogSampleType = (props: Props) => {
	return (
		<CatalogSampleTypeProvider>
			<CatalogSampleTypePage />
		</CatalogSampleTypeProvider>
	);
};
export default CatalogSampleType;
