import { createSlice } from "@reduxjs/toolkit";
import { CatalogResultSource } from "../../models/CatalogResultSource";

const CatalogResultSourceInitialState: {
	resultSources: CatalogResultSource[] | null;
} = {
	resultSources: null,
};

export const CatalogResultSourceReducer = createSlice({
	name: "__",
	initialState: CatalogResultSourceInitialState,
	reducers: {
		setResultSources(state, { payload }) {
			state.resultSources = payload;
		},
	},
});
