import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import React, { useEffect, useRef, useState } from "react";
import { BarTask } from "../../types/bar-task";
import { Task } from "../../types/public-types";
import useMousePosition from "../gantt/hooks/useMousePosition";
import styles from "./tooltip.module.css";

export type TooltipProps = {
	task: BarTask;
	arrowIndent: number;
	svgContainerHeight: number;
	svgContainerWidth: number;
	svgWidth: number;
	headerHeight: number;
	taskListWidth: number;
	rowHeight: number;
	fontSize: string;
	fontFamily: string;
	TooltipContent: React.FC<{
		task: Task;
		fontSize: string;
		fontFamily: string;
	}>;
};

export const Tooltip: React.FC<TooltipProps> = ({
	task,
	rowHeight,
	svgContainerHeight,
	svgContainerWidth,
	arrowIndent,
	fontSize,
	fontFamily,
	headerHeight,
	taskListWidth,
	TooltipContent,
}) => {
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	const [relatedY, setRelatedY] = useState(0);
	const [relatedX, setRelatedX] = useState(0);

	const mousePosition = useMousePosition();

	useEffect(() => {
		if (tooltipRef.current) {
			const tooltipHeight = tooltipRef.current.offsetHeight * 1.1;
			const tooltipWidth = tooltipRef.current.offsetWidth * 1.1;

			let newRelatedX = mousePosition.x;
			let newRelatedY = mousePosition.y;

			const tooltipRightmostPoint = newRelatedX + tooltipWidth;
			const tooltipLowerPoint = newRelatedY + tooltipHeight;
			const fullChartWidth = taskListWidth + svgContainerWidth;
			const fullChartHeight = svgContainerHeight;

			if (tooltipRightmostPoint > fullChartWidth) {
				newRelatedX = fullChartWidth - tooltipWidth;
			}

			if (tooltipLowerPoint > fullChartHeight) {
				newRelatedY = fullChartHeight - tooltipHeight;
			}

			setRelatedX(newRelatedX);
			setRelatedY(newRelatedY);
		}
	}, [mousePosition, tooltipRef.current]);

	return (
		<>
			<div
				ref={tooltipRef}
				className={
					relatedX
						? styles.tooltipDetailsContainer
						: styles.tooltipDetailsContainerHidden
				}
				style={{
					left: mousePosition.x,
					top: mousePosition.y,
					position: "fixed",
					opacity: mousePosition.x && mousePosition.y ? 1 : 0,
				}}
			>
				<TooltipContent
					task={task}
					fontSize={fontSize}
					fontFamily={fontFamily}
				/>
			</div>
		</>
	);
};

export const StandardTooltipContent: React.FC<{
	task: Task;
	fontSize: string;
	fontFamily: string;
}> = ({ task, fontSize, fontFamily }) => {
	const style = {
		fontSize,
		fontFamily,
	};

	return (
		<div className={styles.tooltipDefaultContainer} style={style}>
			{task.type === "taskLine" ? (
				<>{task.source.comments}</>
			) : (
				<>
					<p>
						{task.name ? (
							<b style={{ fontSize: parseInt(fontSize) + 6 }}>
								{`Heat: ${task.name}`}{" "}
							</b>
						) : null}
					</p>
					<p>
						<b>Grade: </b>
						{task.source.GradeName}
					</p>
					<p>
						<b>Ladle: </b> {task.source.LadleName}
					</p>
					<p>{task.source.Comments}</p>
					<p className={styles.tooltipDefaultContainerParagraph}>
						{`From: ${dxtToLocalServerTime(task.start, "MM/dd HH:mm")} to: ${dxtToLocalServerTime(task.end, "MM/dd HH:mm")}`}
					</p>
				</>
			)}
		</div>
	);
};
