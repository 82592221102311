import React, { useEffect, useMemo, useRef, useState } from "react";
import { convertToBarAssets } from "../../helpers/bar-helper";
import { ganttDateRange, seedDates } from "../../helpers/date-helper";
import {
	getAllBarTasks,
	getAllTasks,
	sortAssets,
} from "../../helpers/other-helper";
import { BarAsset } from "../../types/bar-asset";
import { BarTask } from "../../types/bar-task";
import { DateSetup } from "../../types/date-setup";
import { GanttEvent } from "../../types/gantt-asset-actions";
import { GanttProps, Task, ViewMode } from "../../types/public-types";
import { AssetList, AssetListProps } from "../asset-list/asset-list";
import { AssetListHeaderDefault } from "../asset-list/asset-list-header";
import { AssetListTableDefault } from "../asset-list/asset-list-table";
import { CalendarProps } from "../calendar/calendar";
import { GridProps } from "../grid/grid";
import { StandardTooltipContent, Tooltip } from "../other/tootltip";
import { AssetGantt } from "./asset-gantt";
import { AssetGanttContentProps } from "./asset-gantt-content";
import styles from "./gantt.module.css";
import { useHandleKeyDown } from "./hooks/useHandleKeyDown";
import { useCustomScroll } from "./hooks/useScroll";

export const Ganttv2: React.FunctionComponent<GanttProps> = ({
	assets,
	headerHeight = 50,
	columnWidth = 100,
	showAssetList = true,
	assetCellWidth = 160,
	rowHeight = 50,
	ganttHeight = 0,
	viewMode = ViewMode.Hour,
	startCalendarDate,
	endCalendarDate,
	locale = "en-US",

	barFill = 50, //50% of rowHeight
	barCornerRadius = 3,
	barProgressColor = "#FF8080",
	barProgressSelectedColor = "#FF8080",
	barBackgroundColor = "#FF8080",
	barBackgroundSelectedColor = "#FF8080",
	barBorderColor = "#802020",
	borderWidth = 1,

	lineSectionFill = 30, //30% of rowHeight
	lineFill = 30, //30% of lineSectionFill

	handleWidth = 8,
	arrowColor = "grey",
	showArrowTriangle = true,
	fontFamily = "Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue",
	fontSize = "14px",
	arrowIndent = 20,
	todayColor = "rgba(252, 248, 227, 0.5)",
	TooltipContent = StandardTooltipContent,
	AssetListHeader = AssetListHeaderDefault,
	AssetListTable = AssetListTableDefault,
	onDoubleClick,
	onSelect,
	onContextMenu,
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const assetListRef = useRef<HTMLDivElement>(null);
	const [dateSetup, setDateSetup] = useState<DateSetup>(() => {
		const allTasks = getAllTasks(assets);
		const [startDate, endDate] = ganttDateRange(allTasks, viewMode);
		return { viewMode, dates: seedDates(startDate, endDate, viewMode) };
	});
	const [svgContainerWidth, setSvgContainerWidth] = useState(0);
	const [svgContainerHeight, setSvgContainerHeight] = useState(ganttHeight);
	const [taskListWidth, setTaskListWidth] = useState(0);
	const [barAssets, setBarAssets] = useState<BarAsset[]>([]);
	const [ganttEvent, setGanttEvent] = useState<GanttEvent>({
		action: "",
	});
	const taskHeight = useMemo(
		() => (rowHeight * barFill) / 150,
		[rowHeight, barFill],
	);
	const lineSectionHeight = useMemo(
		() => (rowHeight * lineSectionFill) / 80,
		[rowHeight, lineSectionFill],
	);
	const lineHeight = useMemo(
		() => (rowHeight * lineSectionFill * lineFill) / 20000,
		[rowHeight, lineSectionFill, lineFill],
	);

	const [selectedAsset, setSelectedAsset] = useState<BarTask>();
	const svgWidth = dateSetup.dates.length * columnWidth;
	const ganttFullHeight = barAssets.length * rowHeight;

	const [scrollY, setScrollY] = useState(0);
	const [scrollX, setScrollX] = useState(-1);

	const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(false);

	// task change events
	useEffect(() => {
		let startDate: Date;
		let endDate: Date;
		if (
			startCalendarDate !== undefined &&
			endCalendarDate !== undefined &&
			endCalendarDate > startCalendarDate
		) {
			[startDate, endDate] = [startCalendarDate, endCalendarDate];
		} else {
			const allTasks: Task[] = getAllTasks(assets);
			let filteredAssets: Task[];
			filteredAssets = allTasks;
			filteredAssets = filteredAssets.sort(sortAssets);
			[startDate, endDate] = ganttDateRange(filteredAssets, viewMode);
		}

		let newDates = seedDates(startDate, endDate, viewMode);
		setDateSetup({ dates: newDates, viewMode });

		const barAssetsGenerated = convertToBarAssets(
			assets,
			newDates,
			columnWidth,
			rowHeight,
			taskHeight,
			lineSectionHeight,
			lineHeight,
			barCornerRadius,
			handleWidth,
			barProgressColor,
			barProgressSelectedColor,
			barBackgroundColor,
			barBackgroundSelectedColor,
			barBorderColor,
			borderWidth,
		);
		setBarAssets(barAssetsGenerated);
	}, [
		assets,
		viewMode,
		rowHeight,
		barCornerRadius,
		columnWidth,
		taskHeight,
		handleWidth,
		barProgressColor,
		barProgressSelectedColor,
		barBackgroundColor,
		barBackgroundSelectedColor,
		barBorderColor,
		borderWidth,
		scrollX,
	]);

	useEffect(() => {
		if (showAssetList) {
			setTaskListWidth(0);
		}
		if (assetListRef.current) {
			setTaskListWidth(assetListRef.current.offsetWidth);
		}
	}, [assetListRef, assetCellWidth, showAssetList]);

	useEffect(() => {
		if (ganttHeight) {
			setSvgContainerHeight(ganttHeight + headerHeight);
		} else {
			setSvgContainerHeight(assets.length * rowHeight + headerHeight);
		}
	}, [ganttHeight, assets]);

	// // scroll events
	const customScroll = useCustomScroll({
		wrapperRef,
		scrollX,
		setScrollX,
		scrollY,
		setScrollY,
		ganttHeight,
		ganttFullHeight,
		svgWidth,
		setIgnoreScrollEvent,
	});

	const { handleKeyDown } = useHandleKeyDown({
		rowHeight,
		columnWidth,
		svgWidth,
		setScrollX,
		setScrollY,
		ganttFullHeight,
		ganttHeight,
		setIgnoreScrollEvent,
	});

	const handleSelectedTask = (taskId: string) => {
		const allTasks = getAllBarTasks(barAssets);
		const newSelectedTask = allTasks.find((t) => t.id === taskId);
		const oldSelectedTask = allTasks.find(
			(t) => !!selectedAsset && t.id === selectedAsset.id,
		);
		if (onSelect) {
			if (oldSelectedTask) {
				onSelect(oldSelectedTask, false);
			}
			if (newSelectedTask) {
				onSelect(newSelectedTask, true);
			}
		}
		setSelectedAsset(newSelectedTask);
	};
	const gridProps: GridProps = {
		columnWidth,
		svgWidth,
		assets: assets,
		rowHeight,
		dates: dateSetup.dates,
		todayColor,
	};
	const calendarProps: CalendarProps = {
		dateSetup,
		locale,
		viewMode,
		headerHeight,
		columnWidth,
		fontFamily,
		fontSize,
	};
	const barProps: AssetGanttContentProps = {
		assets: barAssets,
		ganttEvent,
		selectedAsset,
		rowHeight,
		taskHeight,
		arrowColor,
		showArrowTriangle,
		fontFamily,
		fontSize,
		arrowIndent,
		svgWidth,
		setSelectedAsset: handleSelectedTask,
		onDoubleClick,
		setGanttEvent,
		onContextMenu,
	};
	const tableProps: AssetListProps = {
		rowHeight,
		rowWidth: assetCellWidth,
		fontFamily,
		fontSize,
		assets: barAssets,
		locale,
		headerHeight,
		scrollY,
		ganttHeight,
		horizontalContainerClass: styles.horizontalContainer,
		selectedAsset,
		assetListRef,
		setSelectedAsset: handleSelectedTask,
		AssetListHeader,
		AssetListTable,
	};
	return (
		<div>
			<div
				className={styles.wrapper}
				onKeyDown={handleKeyDown}
				tabIndex={0}
				ref={wrapperRef}
			>
				{showAssetList && <AssetList {...tableProps} />}
				<AssetGantt
					gridProps={gridProps}
					calendarProps={calendarProps}
					barProps={barProps}
					ganttHeight={ganttHeight}
					scrollX={scrollX}
					scrollY={scrollY}
				/>
				{ganttEvent.changedAsset && (
					<Tooltip
						arrowIndent={arrowIndent}
						rowHeight={rowHeight}
						svgContainerHeight={svgContainerHeight}
						svgContainerWidth={svgContainerWidth}
						fontFamily={fontFamily}
						fontSize={fontSize}
						task={ganttEvent.changedAsset}
						headerHeight={headerHeight}
						taskListWidth={taskListWidth}
						TooltipContent={TooltipContent}
						svgWidth={svgWidth}
					/>
				)}
				{/* <VerticalScroll
					ganttFullHeight={ganttFullHeight}
					ganttHeight={ganttHeight}
					headerHeight={headerHeight}
					scroll={scrollY}
					onScroll={handleScrollY}
				/> */}
			</div>
		</div>
	);
};
