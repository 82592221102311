import { createSlice } from "@reduxjs/toolkit";
import { CatalogMold } from "../../../models/catalogs/CatalogMold";

const CatalogMoldInitialState: {
	molds: CatalogMold[] | null;
} = {
	molds: null,
};

export const CatalogMoldReducer = createSlice({
	name: "__",
	initialState: CatalogMoldInitialState,
	reducers: {
		setMolds(state, { payload }) {
			state.molds = payload;
		},
	},
});
