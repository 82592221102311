import {
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../../controls/LazyLoading";
import { ItemOption } from "../../../../controls/autoComplete";
import { HeatNavigation } from "../../../../controls/heatNavigation";
import { ResultSearchHeatName } from "../../../meltshop/models/ResultSearchHeatName";
import {
	getHeat,
	searchHeatNames,
} from "../../../meltshop/repositories/HeatRepository";
import { getCatalogMechanicalTests } from "../../repositories/CatalogMechanicalTestRepository";
import { getCatalogResultSources } from "../../repositories/CatalogResultSourceRepository";
import { getMechanicalSamples } from "../../repositories/MechanicalSampleRepository";
import { useMechanicalByHeatContext } from "./MechanicalByHeatContext";
import { TableMechanical } from "./components/TableMechanical";

type Props = {};
export const MechanicalByHeatPage = (props: Props) => {
	const { state, actions } = useMechanicalByHeatContext();
	const [selectedOption, setSelectedOption] = useState<ItemOption | null>(null);
	const [selectedHeat, setSelectedHeat] = useState<ResultSearchHeatName | null>(
		null,
	);
	const [isEditing, setIsEditing] = useState(false);
	const location = useLocation();
	const updateEditing = (newValue: boolean) => {
		setIsEditing(newValue);
	};
	const updateHistory = (heatName: string, isValid: boolean) => {
		const paths = location.pathname.split("/");
		const newUrl =
			(paths[0] !== undefined && paths[0] !== "/" ? `${paths[0]}` : ``) +
			(paths[1] !== undefined && paths[1] !== "/" ? `${paths[1]}` : ``) +
			(paths[2] !== undefined && paths[2] !== "/" ? `${paths[2]}` : ``) +
			`/${heatName}` +
			`/${isValid}`;
		window.history.replaceState(null, "", newUrl);
	};
	const refreshGrid = async () => {
		updateHistory(
			state.heat === null ? "" : state.heat?.Name ?? "",
			state.showOnlyValid,
		);
		if (state.heat === null || state.heat.Id === null) {
			resetGrid();
			return;
		}
		await getSampleFromAPI();
	};
	const resetGrid = () => {
		actions.setMechanicalSamples([]);
		actions.setGrades([]);
	};
	const nextHeat = () => {
		if (selectedHeat !== null && selectedHeat.NextHeatName !== null) {
			resetGrid();
			actions.setSearchHeatNameText(selectedHeat.NextHeatName);
		}
	};
	const previousHeat = () => {
		if (selectedHeat !== null && selectedHeat.PreviousHeatName !== null) {
			resetGrid();
			actions.setSearchHeatNameText(selectedHeat.PreviousHeatName);
		}
	};
	const showValidSamples = (event: React.ChangeEvent<HTMLInputElement>) => {
		actions.setShowOnlyValid(event.target.checked);
	};
	// Api calls
	const searchHeatsFromAPI = async (searchText: string) => {
		const resp = await searchHeatNames(searchText);
		if (resp.ok) {
			if (resp.data !== null || true) {
				actions.setResultHeats(resp.data.tables[0].rows);
			}
		} else {
			console.log("something happened 1!");
		}
	};
	const getHeatFromAPI = async (id: number) => {
		if (id === 0) {
			actions.setHeat(null);
			return;
		}

		actions.setIsLoadingBackground(true);
		const resp = await getHeat(id);
		if (
			resp.ok &&
			resp.data !== null &&
			resp.data !== undefined &&
			resp.data.tables != undefined &&
			resp.data.tables[0].rows.length > 0
		) {
			actions.setHeat(resp.data.tables[0].rows[0]);
		} else {
			console.log("something happened 3!");
		}
		actions.setIsLoadingBackground(false);
	};
	const getCatalogMechanicalTestFromAPI = async () => {
		actions.setIsLoadingPage(true);
		const resp = await getCatalogMechanicalTests();
		if (resp.ok) {
			actions.setMechanicalTests(resp.data.tables[0].rows);
		} else {
			console.log("something happened 2!");
		}
		actions.setIsLoadingPage(false);
	};
	const getCatalogResultSourcesFromAPI = async () => {
		actions.setIsLoadingPage(true);
		const resp = await getCatalogResultSources();
		if (resp.ok) {
			actions.setSources(resp.data.tables[0].rows);
		} else {
			console.log("something happened 2!");
		}
		actions.setIsLoadingPage(false);
	};
	const getSampleFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getMechanicalSamples(
			state.heat?.Id ?? 0,
			state.showOnlyValid ? "V" : null,
		);
		if (resp.ok) {
			actions.setMechanicalSamples(resp.data.tables[0].rows);
			if (resp.data.tables[1] !== undefined)
				actions.setGrades(resp.data.tables[1].rows);
		} else {
			console.log("something happened 5!");
		}
		actions.setIsLoadingGrid(false);
	};
	//Effects
	useEffect(() => {
		getCatalogMechanicalTestFromAPI();
		getCatalogResultSourcesFromAPI();
		const interval = setInterval(() => {
			// setTicks((ticks) => ticks + 1);
		}, 20000);

		return () => {
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		(async () => {
			if (
				state.searchHeatNameText !== "" &&
				state.searchHeatNameText !== null
			) {
				await searchHeatsFromAPI(state.searchHeatNameText);
			} else {
				actions.setResultHeats([]);
			}
		})();
	}, [state.searchHeatNameText]);
	useEffect(() => {
		const itemList: ItemOption[] = [];
		(state.resultHeats ?? []).forEach((heat: ResultSearchHeatName) => {
			const itemOption: ItemOption = {
				id: heat.HeatId.toString(),
				name: heat.HeatName,
			};
			itemList.push(itemOption);
		});
		actions.setHeatOptions(itemList);
	}, [state.resultHeats]);
	useEffect(() => {
		let heat: ResultSearchHeatName | null = null;
		if (selectedOption !== null) {
			const id = false ? 0 : selectedOption.id;
			const searchedHeat = (state.resultHeats ?? []).find(
				(x) => x.HeatId === Number(id),
			);
			heat = searchedHeat === undefined ? null : searchedHeat;
		}
		setSelectedHeat(heat);
	}, [selectedOption]);
	useEffect(() => {
		(async () => {
			if (selectedHeat === null) actions.setHeat(null);
			await getHeatFromAPI(selectedHeat?.HeatId ?? 0);
		})();
	}, [selectedHeat]);
	useEffect(() => {
		(async () => {
			await refreshGrid();
		})();
	}, [state.heat]);

	if (!state.isLoadingPage) {
		return (
			<>
				<LazyBackDropLoading open={state.isLoadingBackground} />
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0, padding: "15px" }}>
						<Grid item md={12} xs={12}>
							<Typography variant="h5" style={{ fontWeight: 600 }}>
								Mechanical Test
							</Typography>
						</Grid>
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							<Grid item md={3} xs={12}>
								<HeatNavigation
									onTextChanged={actions.setSearchHeatNameText}
									onValueChanged={setSelectedOption}
									heats={state.heatOptions}
									onNextClicked={nextHeat}
									onPreviousClicked={previousHeat}
									defaultText={""}
								/>
							</Grid>
							<Grid item xs="auto">
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											checked={state.showOnlyValid}
											onChange={showValidSamples}
										/>
									}
									label="Show valid samples"
								/>
							</Grid>
						</Grid>
						<Divider />
						<TableMechanical
							loadingGrid={state.isLoadingGrid}
							heat={state.heat}
							mechanicalTests={state.mechanicalTests ?? []}
							mechanicalSamples={state.mechanicalSamples ?? []}
							gradeLimits={state.grades ?? []}
							resultSources={state.sources ?? []}
							updateEditing={updateEditing}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
	return <LazyLoading />;
};
