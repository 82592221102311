import { Badge, makeStyles } from "@material-ui/core";
import { NodeModel } from "@minoru/react-dnd-treeview";
import React from "react";
import { FileProperties } from "../../../models/FileProperties";
import { TypeIcon } from "../DelayConfiguration/TypeIcon";

const useStyles = makeStyles((theme) => ({
	root: {
		alignItems: "flex-start",
		backgroundColor: "#1967d2",
		borderRadius: "4px",
		boxShadow:
			"0 12px 24px -6px rgba(0, 0, 0, 0.25); 0 0 0 1px rgba(0, 0, 0, 0.08)",
		display: "flex",
		flexDirection: "column",
		gap: "4px",
		padding: "8px",
		/* pointer-events: none, */
	},

	item: {
		alignItems: "center",
		color: "#fff",
		display: "inline-grid",
		fontSize: "14px",
		gap: "8px",
		gridTemplateColumns: "auto auto",
	},

	icon: {
		alignItems: "center",
		display: "flex",
	},
	label: {
		alignItems: "center",
		display: "flex",
	},

	badge: {
		border: "solid 2px #fff",
	},
}));

type Props = {
	dragSources: NodeModel<FileProperties>[];
};

export const MultipleDragPreview: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<Badge
			classes={{ badge: classes.badge }}
			color="error"
			badgeContent={props.dragSources.length}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
		>
			<div className={classes.root} data-testid="custom-drag-preview">
				{props.dragSources.map((node) => (
					<div className={classes.item}>
						<div className={classes.icon}>
							<TypeIcon
								droppable={node.droppable || false}
								fileType={node?.data?.fileType}
							/>
						</div>
						<div className={classes.label}>{node.text}</div>
					</div>
				))}
			</div>
		</Badge>
	);
};
