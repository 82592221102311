import { getMomentTz } from "@dexteel/mesf-core";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import { isNaN, isNil } from "lodash-es";
const moment = getMomentTz();
import { useMemo } from "react";
import { ShortDateTimeFormat } from "../../../utils/date-time-formatter";
import { MESFTable } from "../models/AcquisitionStats";

export const useTableData = (acquisitionStats: MESFTable) => {
	const columnData: ColDef[] = useMemo(
		() =>
			acquisitionStats.columns.map((columnName) => ({
				headerName: columnName,
				field: columnName,
				valueGetter: (params: ValueGetterParams) => {
					const rowData = params.data[columnName];

					if (isNil(rowData)) return rowData;

					if (!isNaN(Number(rowData))) return rowData;

					const date = new Date(rowData);
					if (date.toString() !== "Invalid Date" && moment.isDate(date)) {
						return ShortDateTimeFormat(`${rowData}z`);
					}
					return rowData;
				},
			})),
		[acquisitionStats],
	);

	const defaultColDef: ColDef = {
		flex: 1,
		autoHeight: true,
		wrapText: true,
		sortable: true,
	};

	const rowData = useMemo(() => acquisitionStats.rows, [acquisitionStats]);

	return { rowData, columnData, defaultColDef };
};
