import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
	tableContainer: {
		maxHeight: "300px",
		overflowY: "auto",
		//  border: "1px solid #ccc",
		display: "block",
		width: "100%",
		borderCollapse: "collapse",
	},
	simpleCellHeader: {
		padding: "0.275rem 0.45rem",
		fontSize: "0.75rem",
		backgroundColor: "#ced2cc   !important",
		border: "1px solid #ced4da",
		color: "#495057",
		textAlign: "center",
	},
	simpleCellText: {
		padding: "0.275rem 0.45rem",
		fontSize: "0.75rem",
		borderRadius: "0px",
		height: "35px",
		whiteSpace: "nowrap",
	},
	simpleCellCheckBox: {
		border: "1px solid #ced4da",
		textAlign: "center",
		margin: "auto",
		height: "2.7rem",
	},
	simpleCellNumeric: {
		fontSize: "0.75rem",
		borderRadius: "0px",
		padding: "0px 0.6rem",
		height: "35px",
		minWidth: "30px",
		textAlign: "right",
	},
}));
