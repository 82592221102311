import { Button, Grid, Typography } from "@material-ui/core";
import { useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { CatalogSampleType } from "../../../models/CatalogSampleType";
import { useGridStyles } from "../../../styles/gridStyles";
import { CreateSampleType } from "./CreateSampleType";
import { EditSampleType } from "./EditSampleType";
import { useTableData } from "./hooks";
type Props = {
	getCatalogSampleTypesFromAPI: Function;
	isLoading: boolean;
};

export const TableSampleType = ({
	getCatalogSampleTypesFromAPI,
	isLoading,
}: Props) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [idSampleType, setIdSampleType] = useState<number | null>(null);
	const { rows, columns, defaultColDef } = useTableData({});
	const { columnDefaults, columnTypes } = useGridDefinitions({
		OnEdit: (data: CatalogSampleType) => {
			setShowEditModal(!showEditModal);
			setIdSampleType(data.SampleTypeId);
		},
	});

	const classes = useGridStyles();
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="stretch"
			style={{ height: "75vh" }}
			className={classes.root}
		>
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "1px 0 10px", fontWeight: 600 }}
				>
					Sample Types
				</Typography>
			</Grid>
			<CreateSampleType
				show={showCreateModal}
				onHide={() => setShowCreateModal(false)}
				refreshData={getCatalogSampleTypesFromAPI}
			/>
			<EditSampleType
				show={showEditModal}
				onHide={(updated: boolean) => {
					setShowEditModal(false);
					setIdSampleType(null);
					if (updated) getCatalogSampleTypesFromAPI();
				}}
				sampleTypeId={idSampleType}
			/>
			<Grid item md={12} xs={12} className={classes.gridContainer}>
				<div
					style={{ height: "100%", width: "100%" }}
					className="ag-theme-material"
				>
					<AgGridReact
						rowData={rows || []}
						getRowId={(params) => params.data.SampleTypeId}
						columnDefs={columns}
						columnTypes={columnTypes}
						defaultColDef={defaultColDef}
						rowHeight={34}
						headerHeight={44}
						animateRows={true}
						rowSelection="single"
					/>
				</div>
			</Grid>
			<Grid container justifyContent="flex-end">
				<Grid item md={2} xs={12} className={classes.btnModal}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{ margin: "0px 15px" }}
						onClick={() => setShowCreateModal(!showCreateModal)}
					>
						NEW SAMPLE TYPE
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
