import { Theme, makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-group-text ": {
			fontSize: 11,
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .info-limit": {
			color: "#000",
			backgroundColor: "#b2e5ed",
			fontWeight: 600,
		},
		"& .ag-header": {
			backgroundColor: "#f5f5f5",
			"&:hover": {
				backgroundColor: "#f5f5f5",
			},
			fontWeight: "bold",
		},
		"& .ag-row-hover": {
			backgroundColor: "#ecf0f1 !important",
		},
		"& .ag-row-odd.ag-row-hover": {
			backgroundColor: "#ecf0f1 !important",
		},
		"& .ag-row": {
			transition: "background-color 0.2s",
		},
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	gridContainer: {
		height: "61vh",
		border: "4px solid #ccc",
		borderRadius: 10,
		padding: 10,
		margin: "20px 10px",
	},
}));
