import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { MeltShopProductionShiftFilter } from "../models/Filters";

export const getShiftByParameters = async (
	parameters: MeltShopProductionShiftFilter,
) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "ShiftId",
			value: parameters.shiftId === 0 ? null : parameters.shiftId,
		});
		sqlParameters.push({
			name: "ProductionDate",
			value: JSON.parse(JSON.stringify(parameters.productionDate)),
		});
		const apiService = new MESApiService();
		const resp = await apiService.callV2(
			"[MES].[GetShiftByParameters]",
			sqlParameters,
		);
		if (resp.ok) {
			const data = get(resp, "data.tables[0].rows[0]", {});
			if (data.CurrentProductionDate) {
				return {
					ok: true,
					data: data,
				};
			}
			return {
				ok: true,
				data: get(resp, "data.tables[0].rows[0]", {}),
			};
		}
		return resp;
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

//EAF Processes
export const getProcessesByShift = async (
	shiftId: number,
	deleted: boolean,
) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });
	sqlParameters.push({ name: "GetDeleted", value: deleted });
	try {
		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[EAF].[GetProcessesByShift]",
			sqlParameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const upsertProcess = async (
	processId: number | null,
	ladleOpen: Date | null,
	ladleClose: Date | null,
	isManual: boolean,
	assetId?: number | null,
) => {
	try {
		const apiService = new MESApiService();
		const parameters: spParameter[] = [];
		parameters.push({ name: "ProcessId", value: processId });
		parameters.push({ name: "AssetId", value: assetId || null });
		parameters.push({
			name: "Start",
			value: ladleOpen === null ? null : new Date(ladleOpen).toISOString(),
		});
		parameters.push({
			name: "End",
			value: ladleClose === null ? null : new Date(ladleClose).toISOString(),
		});
		parameters.push({ name: "IsProcessManual", value: isManual });

		return await apiService.callV2("[EAF].[UpsertProcess]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const deleteProcess = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });

	const resp = await apiService.callV2("[EAF].[DeleteProcess]", parameters);
	return resp;
};

export const unDeleteProcess = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		return await apiService.callV2("[MS].[UnDeleteProcess]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const upsertEAFProcessData = async (
	processId: number,
	field: string,
	value: any,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: field, value: value });
	parameters.push({ name: "IsProcessDataManual", value: true });

	try {
		return await apiService.callV2("[EAF].[UpdateProcessData]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const linkEAFProcess = async (processId: number, heatId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: "HeatId", value: heatId });
	parameters.push({ name: "IsHeatIdManual", value: true });
	try {
		return await apiService.callV2(
			"[EAF].[ManualLinkProcessToHeat]",
			parameters,
		);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const unLinkEAFProcess = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		return await apiService.callV2("[EAF].[UnlinkProcessToHeat]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const searchUnlinkedHeats = async (
	searchText: string,
	start: Date,
	assetId?: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({
		name: "SearchText",
		value: searchText,
	});

	parameters.push({
		name: "Start",
		value: start,
	});

	parameters.push({
		name: "AssetId",
		value: assetId ?? null,
	});

	const resp = await apiService.callV2(
		"[EAF].[SearchUnlinkedHeats]",
		parameters,
	);
	return resp;
};
