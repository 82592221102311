import {
	ErrorModal,
	dxtToLocalServerTime,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { useContextMenuMESF } from "@dexteel/mesf-core";
import {
	Button,
	Grid,
	IconButton,
	MenuItem,
	makeStyles,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import ListIcon from "@material-ui/icons/List";
import { ICellRendererParams, RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	PopupCellRenderer,
	ToggleActiveButtonCellRenderer,
} from "../../../../controls/ag-grid/components/ButtonCellRenderer";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { useEAFProcessesContext } from "../context/EAFProcessesContext";
import { useEAFOptionFunctions } from "../hooks/useEAFOptionFunctions";
import { ProcessByShift } from "../models/ProcessByShift";
import {
	deleteProcess,
	unDeleteProcess,
	unLinkEAFProcess,
} from "../repositories/EAFRepository";
import { useGridStyles } from "../styles/gridStyles";
import { LinkEAFProcess } from "./LinkEAFProcess";
import { UpsertEAFProcess } from "./UpsertEAFProcess";
import { useTableData } from "./hooks";

const moment = getMomentTz();

const useStyles = makeStyles(() => ({
	contextMenu: {
		fontSize: "14px",
		backgroundColor: "#fff",
		borderRadius: "2px",
		padding: "5px 0 5px 0",
		width: "150px",
		height: "auto",
		margin: "0",
		position: "absolute",
		listStyle: "none",
		boxShadow: "0 0 20px 0 #ccc",
	},
	hiddenButton: {
		backgroundColor: "#c82121cf",
		color: "#FFF",
		borderRadius: "2px",
		padding: "0",
		width: "137px",
		minWidth: "137px !important",
		maxWidth: "137px !important",
		height: "auto",
		margin: "0",
		listStyle: "none",
		position: "absolute",
		textAlign: "center",
		right: 15,
		top: 70,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

type Props = {
	loadingGrid: boolean;
	rows: ProcessByShift[];
	refreshData: Function;
};

export const EAFProcessesTable = ({
	loadingGrid,
	rows,
	refreshData,
}: Props) => {
	const classes = useGridStyles();
	const gridRef = useRef<AgGridReact>(null);
	const { columns } = useTableData({});

	const {
		state: { selectedProcess, errorMessage },
		actions: {
			setSelectedProcess,
			setIsLoadingBackground,
			setNotificationMessage,
			setErrorMessage,
		},
	} = useEAFProcessesContext();

	const {
		createData,
		editData,
		linkData,
		unlinkData,
		getMenuOptions,
		toggleStatusData,
		goToDataEntry,
		showActiveModal,
		showDeleteModal,
		showEditModal,
		showLinkModal,
		showUnlinkModal,
		messageActiveModal,
		messageDeleteModal,
		setShowActiveModal,
		setShowDeleteModal,
		setShowEditModal,
		setShowLinkModal,
		setShowUnlinkModal,
		setMessageActiveModal,
		setMessageDeleteModal,
	} = useEAFOptionFunctions();

	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { shiftInfo } = useShiftNavigator();

	const messageUnlinkModal = `The Heat ${
		selectedProcess?.HeatName
	} from ${dxtToLocalServerTime(
		selectedProcess?.Start,
		"YYYY/MM/DD HH:mm",
	)} will be unlinked.  Do you want to continue?`;
	const getRowStyle = (params: RowClassParams) => {
		if (!params.data.IsActive) {
			return { background: "#E8EBEB" };
		}
	};

	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};

	const onUnlinkSubmit = async (value?: string) => {
		setShowUnlinkModal(false);
		if (value !== "OK") return;
		await unLinkEAFProcess(selectedProcess!.ProcessId);
		await refreshData();
	};

	const deleteEAFProcess = async (value?: string) => {
		setShowDeleteModal(false);
		setMessageDeleteModal("");
		if (value !== "OK") return;
		if (selectedProcess === null) return;
		setIsLoadingBackground(true);
		const resp = await deleteProcess(selectedProcess.ProcessId);
		if (resp.ok) {
			setIsLoadingBackground(false);
			setNotificationMessage("Process deleted successfully");
			setSelectedProcess(null);
			await refreshData();
		} else {
			setIsLoadingBackground(false);
			setErrorMessage(resp.message);
		}
	};

	const activeEAFProcess = async (value?: string) => {
		setShowActiveModal(false);
		setMessageActiveModal("");
		if (value !== "OK") return;
		if (selectedProcess === null) return;
		setIsLoadingBackground(true);
		const resp = await unDeleteProcess(selectedProcess.ProcessId);
		if (resp.ok) {
			setIsLoadingBackground(false);
			setNotificationMessage("Process activated successfully");
			setSelectedProcess(null);
			await refreshData();
		} else {
			setIsLoadingBackground(false);
			setErrorMessage(resp.message);
		}
	};

	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: editData,
	});

	columnTypes["activeButton"] = {
		field: "IsActive",
		headerName: "",
		cellRenderer: ToggleActiveButtonCellRenderer,
		cellRendererParams: {
			clicked: toggleStatusData,
		},
		cellClass: "ag-button-cell",
	};

	columnTypes["optionsButton"] = {
		field: "options",
		headerName: "",
		cellRenderer: (params: ICellRendererParams) => {
			return (
				<div className={classes.buttons}>
					<IconButton
						aria-label="link/unlink"
						title={params.data.HeatName ? "Unlink" : "Link"}
						style={{ cursor: "pointer" }}
						onClick={() => {
							params.data.HeatName
								? unlinkData(params.data)
								: linkData(params.data);
						}}
					>
						{params.data.HeatName ? (
							<LinkOffIcon
								fontSize="inherit"
								color="primary"
								style={{ cursor: "pointer" }}
							/>
						) : (
							<LinkIcon
								fontSize="inherit"
								color="primary"
								style={{ cursor: "pointer" }}
							/>
						)}
					</IconButton>
					<IconButton
						aria-label="delete-icon"
						title={params.data.IsActive ? "Delete" : "Activate"}
						style={{ cursor: "pointer" }}
						onClick={() => toggleStatusData(params.data)}
					>
						{params.data.IsActive ? (
							<DeleteIcon
								fontSize="inherit"
								color="secondary"
								style={{ cursor: "pointer" }}
							/>
						) : (
							<AddBoxIcon
								fontSize="inherit"
								color="primary"
								style={{ cursor: "pointer" }}
							/>
						)}
					</IconButton>
					<IconButton
						aria-label="Options"
						title="Options"
						style={{ cursor: "pointer" }}
						onClick={(e) => showContextMenu(e, params.data, "eafProcesses")}
					>
						<ListIcon
							fontSize="inherit"
							color="primary"
							style={{ cursor: "pointer" }}
						/>
					</IconButton>
				</div>
			);
		},
		cellClass: classes.buttons,
	};

	useEffect(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);

	useEffect(() => {
		registerConfig({
			id: "eafProcesses",
			getOptions: getMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);

	return (
		<div onContextMenu={handleContainerContextMenu}>
			<div>
				<Grid container className={classes.root}>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							height: "calc(100vh - 300px)",
							minHeight: "calc(100vh - 300px)",
							margin: "20px 0 10px",
						}}
						className={"ag-theme-balham"}
					>
						<ConfirmationDialogRaw
							title="Confirmation"
							message={messageDeleteModal}
							open={showDeleteModal}
							onClose={deleteEAFProcess}
						/>
						<ConfirmationDialogRaw
							title="Confirmation"
							message={messageActiveModal}
							open={showActiveModal}
							onClose={activeEAFProcess}
						/>
						<ConfirmationDialogRaw
							title="Confirmation"
							message={messageUnlinkModal}
							open={showUnlinkModal}
							onClose={onUnlinkSubmit}
						/>
						<UpsertEAFProcess
							show={showEditModal}
							onHide={() => setShowEditModal(false)}
							refreshData={refreshData}
						/>
						<LinkEAFProcess
							show={showLinkModal}
							onHide={() => setShowLinkModal(false)}
							refreshData={refreshData}
						/>
						<AgGridReact
							ref={gridRef}
							rowSelection="single"
							pagination={false}
							animateRows={true}
							rowHeight={35}
							headerHeight={35}
							singleClickEdit={true}
							tooltipShowDelay={0}
							tooltipHideDelay={2000}
							rowData={rows}
							columnDefs={columns}
							columnTypes={columnTypes}
							defaultColDef={columnSimpleDefaults}
							getRowStyle={getRowStyle}
							getRowId={(params) => params.data.ProcessId}
							suppressContextMenu={true}
							onRowDoubleClicked={(event: any) => {
								if (event.data.IsActive) {
									goToDataEntry(event.data.ProcessId);
								}
							}}
							onCellContextMenu={(event) => {
								event.api.deselectAll();
								event.node.setSelected(true);
								setSelectedProcess(event?.data);
								if (event.event)
									showContextMenu(
										event.event as any,
										event.data,
										"eafProcesses",
									);
							}}
						/>
						<Grid container justifyContent="flex-end">
							<Grid item md={2} xs={12} className={classes.btnModal}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									style={{ margin: "2rem 0px 0px" }}
									onClick={() => {
										createData();
									}}
								>
									NEW PROCESS
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			<ErrorModal error={errorMessage} onHide={() => setErrorMessage("")} />
		</div>
	);
};
