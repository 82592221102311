import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogLadle } from "../models/CatalogLadle";

export const getCatalogLadles = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[MS].[GetLadles]", []);
};

export const upsertCatalogLadle = async (ladle: CatalogLadle) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "LadleId", value: ladle.LadleId });
	parameters.push({ name: "LadleName", value: ladle.LadleName });
	parameters.push({ name: "IsActive", value: ladle.Active });

	return await apiService.callV2("[MS].[UpsertLadle]", parameters);
};

export const deleteCatalogLadle = async (LadleId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "LadleId", value: LadleId });

	return await apiService.callV2("[MS].[DeleteLadle]", parameters);
};

export const getCatalogLadle = async (LadleId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "LadleId", value: LadleId });

	return await apiService.callV2("[MS].[GetLadle]", parameters);
};
