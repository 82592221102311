import { isNil } from "lodash-es";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { DelaysManagerReducer } from "../reducers/DelaysManagerReducer";

const DelaysManagerContext = createContext({
	state: DelaysManagerReducer.getInitialState(),
	actions: DelaysManagerReducer.actions,
});

export const useDelaysManagerContext = () => useContext(DelaysManagerContext);

export const DelaysManagerProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const { shiftId: shiftIdParam } = useParams();

	const [state, actions] = useComplexState({
		initialState: DelaysManagerReducer.getInitialState(),
		reducers: DelaysManagerReducer.caseReducers,
	});
	useEffect(() => {
		const shiftId = Number(shiftIdParam);
		if (isNil(shiftIdParam) || isNaN(shiftId)) {
			actions.setShiftId(null);
		} else {
			actions.setShiftId(shiftId);
		}
	}, [shiftIdParam]);
	return (
		<DelaysManagerContext.Provider value={{ state, actions }}>
			{children}
		</DelaysManagerContext.Provider>
	);
};
