import { Theme, makeStyles } from "@material-ui/core";
export const useGenericReportStyles = makeStyles((theme: Theme) => ({
	root: {
		//Headers
		"& .ag-header": {
			backgroundColor: "#479dc4 !important",
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			color: "#fff !important",
		},
		"& .ag-cell-label-container": {
			padding: "0px !important",
		},
		"& .ag-header-cell-text": {
			margin: "auto !important",
		},
		"& .ag-menu": {
			background: "white",
			border: "1px solid #BDC3C7",
			boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
		},
		// "& .pixel-100":{
		//     minWidth: "100px !important",
		//     maxWidth: "100px !important",
		// },
		// "& .pixel-120": {
		//     minWidth: "120px !important",
		//     maxWidth: "120px !important",
		// },
		// "& .pixel-200": {
		//     minWidth: "200px !important",
		//     maxWidth: "200px !important",
		// },
		// "& .pixel-300": {
		//     minWidth: "300px !important",
		//     maxWidth: "300px !important",
		// },
		// "& .pixel-400": {
		//     minWidth: "400px !important",
		//     maxWidth: "400px !important",
		// },
		// "& .pixel-500": {
		//     minWidth: "500px !important",
		//     maxWidth: "500px !important",
		// },
		// "& .pixel-30": {
		//     minWidth: "30px !important",
		//     maxWidth: "30px !important",
		// },
		// "& .pixel-50": {
		//     minWidth: "50px !important",
		//     maxWidth: "50px !important",
		// },
		// "& .pixel-60": {
		//     minWidth: "60px !important",
		//     maxWidth: "60px !important",
		// },
		// "& .pixel-80": {
		//     minWidth: "80px !important",
		//     maxWidth: "80px !important",
		// },
		// "& .pixel-70": {
		//     minWidth: "70px !important",
		//     maxWidth: "70px !important",
		// },
		// "& .pixel-65": {
		//     minWidth: "65px !important",
		//     maxWidth: "65px !important",
		// },
		// "& .pixel-55": {
		//     minWidth: "55px !important",
		//     maxWidth: "55px !important",
		// },
		// "& .pixel-45": {
		//     minWidth: "45px !important",
		//     maxWidth: "45px !important",
		// },
		// "& .pixel-35": {
		//     minWidth: "35px !important",
		//     maxWidth: "35px !important",
		// },
		// "& .pixel-85": {
		//     minWidth: "85px !important",
		//     maxWidth: "85px !important",
		// },
		//Cells
		"& .cell-number": {
			textAlign: "right !important",
		},
		"& .cell-string": {
			textAlign: "left !important",
		},
		"& .red": {
			color: "red",
		},

		"& .ag-cell": {
			fontSize: "12px !important",
			padding: "0.5rem !important",
		},
		"& .error-limit": {
			color: "#fff",
			backgroundColor: "#f07171",
			fontWeight: 600,
		},
		"& .stripe-header ": {
			color: "#fff",
			background:
				"repeating-linear-gradient(45deg,#65c2ec,#65c2ec 5px,#479dc4 5px,#479dc4 10px)",
		},

		"& .ag-drag-handle": {},
		"& .ag-right-aligned-cell": {},
		"& .ag-button-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			cursor: "pointer",
		},
		"& .ag-center-aligned-header": {
			textAlign: "center !important",
		},
		"& .ag-center-aligned-cell": {
			textAlign: "center !important",
		},
		"& .custom-tooltip": {
			width: "170px",
			minHeight: "50px",
			border: "1px solid cornflowerblue",
			overflow: "hidden",
		},

		"& .custom-tooltip p": {
			margin: "5px",
			whiteSpace: "pre-line",
			wordWrap: "break-word",
		},

		"& .custom-tooltip span:first-of-type": {
			fontWeight: "bold",
		},
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	footerTitle: {
		width: 220,
		padding: "8px 0",
		textAlign: "center",
		backgroundColor: "#eee",
		borderBottom: "solid 1px #babfc7",
		borderLeft: "solid 1px #babfc7",
	},
	footerContainer: {
		backgroundColor: "#fff",
		height: "45px",
	},
	footerItem: {
		width: 70,
		padding: "8px 0",
		textAlign: "center",
		backgroundColor: "#eee",
		borderBottom: "solid 1px #babfc7",
	},
	footerTextItem: {
		fontSize: 12,
		verticalAlign: "middle",
	},
	footerTextTitle: {
		fontSize: 12,
		verticalAlign: "middle",
		fontWeight: 700,
	},
}));
