import { getMomentTz } from "@dexteel/mesf-core";
import { FilterParameters } from "./FilterParameters";
const moment = getMomentTz();

export const FILTER_INITIAL_VALUES: FilterParameters = {
	Start: moment().subtract(3, "days").startOf("day").toDate(),
	End: moment().endOf("day").toDate(),
	SearchText: "",
	Crew: "All",
};
