export const adjustTimestampDate = (
	timestamp: moment.Moment,
	start: moment.Moment,
	end: moment.Moment | null,
): moment.Moment => {
	if (!start || !timestamp) return timestamp;

	timestamp.year(start.year());
	timestamp.month(start.month());
	timestamp.date(start.date());

	if (timestamp.isBefore(start)) {
		if (end && end.isBefore(start)) {
			timestamp.add(1, "day");
		}
	}

	return timestamp;
};
