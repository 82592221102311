import { createTheme, makeStyles } from "@material-ui/core/styles";

export const useClassicStyles = makeStyles((theme) => ({
	defaultButton: {
		padding: "6px 16px",
		fontSize: "0.875rem",
		lineHeight: 1.75,
		borderRadius: "4px",
	},
}));

const theme = createTheme({
	overrides: {
		MuiButton: {
			root: {
				padding: "6px 12px",
				fontSize: "0.7rem",
				lineHeight: 1,
				borderRadius: 4,
			},
		},
		MuiButtonGroup: {
			grouped: {
				minWidth: 30,
				padding: "4px 8px",
			},
		},
		MuiTextField: {
			root: {
				fontSize: "0.7rem",
				"& .MuiInputBase-root": {
					// Remove fixed height for multiline
					minHeight: "35px",
				},
				"& .MuiOutlinedInput-input": {
					padding: "8px 14px",
				},
				"& label": {
					fontSize: "0.75rem",
				},
				// Add styles for multiline input
				"& .MuiOutlinedInput-multiline": {
					padding: "8px 14px",
				},
			},
		},
		// New table-related styles
		MuiTable: {
			root: {
				minWidth: 300,
			},
		},
		MuiTableCell: {
			root: {
				padding: 4,
				"&.narrow": {
					whiteSpace: "nowrap",
					maxWidth: "80px",
				},
			},
			head: {
				fontWeight: "bold",
			},
		},
		MuiTableRow: {
			root: {
				"&:nth-of-type(odd)": {
					backgroundColor: "#f5f5f5",
				},
			},
		},

		MuiFormControl: {
			root: {
				margin: "4px 0",
				minWidth: 120,
				width: "100%", // Ensure it takes full width of its container
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: "translate(14px, 12px) scale(1)",
				"&.MuiInputLabel-shrink": {
					transform: "translate(14px, -6px) scale(0.75)",
				},
			},
		},
		MuiOutlinedInput: {
			root: {
				height: "35px",
			},
			input: {
				padding: "8px 14px",
				height: "19px", // Adjust this to match other inputs
			},
		},
		MuiSelect: {
			select: {
				height: "35px",
				lineHeight: "35px",
				paddingTop: 0,
				paddingBottom: 0,
			},
			outlined: {
				paddingRight: "32px", // Space for the dropdown icon
			},
		},
		MuiMenuItem: {
			root: {
				fontSize: "0.7rem",
				minHeight: "35px",
				lineHeight: "35px",
			},
		},
	},
});

export default theme;
