import { createSlice } from "@reduxjs/toolkit";
import { CatalogMoldSize } from "../../../models/catalogs/CatalogMoldSize";

const CatalogMoldSizeInitialState: {
	moldSizes: CatalogMoldSize[] | null;
} = {
	moldSizes: null,
};

export const CatalogMoldSizeReducer = createSlice({
	name: "__",
	initialState: CatalogMoldSizeInitialState,
	reducers: {
		setMoldSizes(state, { payload }) {
			state.moldSizes = payload;
		},
	},
});
