import {
	MenuOptionType,
	getMomentTz,
	useContextMenuMESF,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import { Button, Grid, IconButton } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import ListIcon from "@material-ui/icons/List";
import { ICellRendererParams, RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import {
	LinkButtonCellRenderer,
	ToggleActiveButtonCellRenderer,
	UnLinkButtonCellRenderer,
} from "../../../../controls/ag-grid/components/ButtonCellRenderer";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { useStyles } from "../../caster-data-entry/components/styles";
import { ProcessByShift } from "../../models/ProcessByShift";
import {
	deleteProcess,
	unDeleteProcess,
	unLinkProcess,
} from "../../repositories/ProductionRepository";
import { useGridStyles } from "../../styles/gridStyles";
import { useCasterProcessesContext } from "../CasterProcessesContext";
import { useOptionFunctions } from "../hooks/useOptionFunctions";
import { LinkCasterProcess } from "./LinkCasterProcess";
import { UpsertCasterProcess } from "./UpsertCasterProcess";
import { useTableData } from "./hooks";

const moment = getMomentTz();

type Props = {
	loadingGrid: boolean;
	rows: ProcessByShift[];
	refreshData: Function;
};

export const CasterProcessesTable = ({
	loadingGrid,
	rows,
	refreshData,
}: Props) => {
	const classes = useGridStyles();
	const MUIclasses = useStyles();

	const [options, setOptions] = useState<MenuOptionType[]>([]);

	const {
		createData,
		editData,
		linkData,
		unlinkData,
		toggleStatusData,
		getMenuOptions,
		goToHeats,
		goToDataEntry,
		showActiveModal,
		showDeleteModal,
		showEditModal,
		showLinkModal,
		showUnlinkModal,
		messageActiveModal,
		messageDeleteModal,
		messageUnlinkModal,
		setShowActiveModal,
		setShowDeleteModal,
		setShowEditModal,
		setShowLinkModal,
		setShowUnlinkModal,
		setMessageActiveModal,
		setMessageDeleteModal,
		setMessageUnlinkModal,
	} = useOptionFunctions();

	const { showContextMenu, registerConfig } = useContextMenuMESF();
	const { shiftInfo } = useShiftNavigator();
	const gridRef = useRef<AgGridReact>(null);
	const { columns } = useTableData({});

	const {
		state: {
			selectedProcess,
			anchorPoint: { x, y },
		},
		actions: {
			setSelectedProcess,
			setIsLoadingBackground,
			setNotificationMessage,
			setErrorMessage,
		},
	} = useCasterProcessesContext();

	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};

	const getRowStyle = (params: RowClassParams) => {
		if (!params.data.IsActive) {
			return { background: "#E8EBEB" };
		}
	};

	const deleteCasterProcess = async (value?: string) => {
		setShowDeleteModal(false);
		setMessageDeleteModal("");
		if (value !== "OK") return;
		if (selectedProcess === null) return;
		setIsLoadingBackground(true);
		const resp = await deleteProcess(selectedProcess.ProcessId);
		if (resp.ok) {
			setIsLoadingBackground(false);
			setNotificationMessage("Process deleted successfully");
			await refreshData();
		} else {
			setIsLoadingBackground(false);
			setErrorMessage(resp.message);
		}
	};
	const activeCasterProcess = async (value?: string) => {
		setShowActiveModal(false);
		setMessageActiveModal("");
		if (value !== "OK") return;
		if (selectedProcess === null) return;
		setIsLoadingBackground(true);
		const resp = await unDeleteProcess(selectedProcess.ProcessId);
		if (resp.ok) {
			setIsLoadingBackground(false);
			setNotificationMessage("Process activated successfully");
			await refreshData();
		} else {
			setIsLoadingBackground(false);
			setErrorMessage(resp.message);
		}
	};
	const unlinkCasterProcess = async (value?: string) => {
		setShowUnlinkModal(false);
		setMessageUnlinkModal("");
		if (value !== "OK") return;
		if (selectedProcess === null) return;
		setIsLoadingBackground(true);
		const resp = await unLinkProcess(selectedProcess.ProcessId);
		if (resp.ok) {
			setIsLoadingBackground(false);
			setNotificationMessage("Process unlinked successfully");
			await refreshData();
		} else {
			setIsLoadingBackground(false);
			setErrorMessage(resp.message);
		}
	};

	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: editData,
	});
	columnTypes["linkButton"] = {
		field: "Link",
		cellRenderer: LinkButtonCellRenderer,
		cellRendererParams: {
			clicked: linkData,
		},
		cellClass: "ag-button-cell",
	};
	columnTypes["unlinkButton"] = {
		field: "Unlink",
		cellRenderer: UnLinkButtonCellRenderer,
		cellRendererParams: {
			clicked: unlinkData,
		},
		cellClass: "ag-button-cell",
	};
	columnTypes["activeButton"] = {
		field: "IsActive",
		headerName: "",
		cellRenderer: ToggleActiveButtonCellRenderer,
		cellRendererParams: {
			clicked: toggleStatusData,
		},
		cellClass: "ag-button-cell",
	};
	columnTypes["optionsButton"] = {
		field: "Options",
		headerName: "",
		cellRenderer: (params: ICellRendererParams) => {
			return (
				<div className={classes.buttons}>
					<IconButton
						aria-label="link/unlink"
						title={params.data.HeatName ? "Unlink" : "Link"}
						style={{ cursor: "pointer" }}
						onClick={() => {
							params.data.HeatName
								? unlinkData(params.data)
								: linkData(params.data);
						}}
					>
						{params.data.HeatName ? (
							<LinkOffIcon
								fontSize="inherit"
								color="primary"
								style={{ cursor: "pointer" }}
							/>
						) : (
							<LinkIcon
								fontSize="inherit"
								color="primary"
								style={{ cursor: "pointer" }}
							/>
						)}
					</IconButton>
					<IconButton
						aria-label="delete-icon"
						title={params.data.IsActive ? "Delete" : "Activate"}
						style={{ cursor: "pointer" }}
						onClick={() => toggleStatusData(params.data)}
					>
						{params.data.IsActive ? (
							<DeleteIcon
								fontSize="inherit"
								color="secondary"
								style={{ cursor: "pointer" }}
							/>
						) : (
							<AddBoxIcon
								fontSize="inherit"
								color="primary"
								style={{ cursor: "pointer" }}
							/>
						)}
					</IconButton>
					<IconButton
						aria-label="Options"
						title="Options"
						style={{ cursor: "pointer" }}
						onClick={(e) => showContextMenu(e, params.data, "casterProcesses")}
					>
						<ListIcon
							fontSize="inherit"
							color="primary"
							style={{ cursor: "pointer" }}
						/>
					</IconButton>
				</div>
			);
		},
		cellClass: classes.buttons,
	};

	useEffect(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
	}, [loadingGrid]);

	useEffect(() => {
		registerConfig({
			id: "casterProcesses",
			getOptions: getMenuOptions,
		});
	}, [shiftInfo?.CurrentShiftId]);
	return (
		<div onContextMenu={handleContainerContextMenu}>
			<Grid container className={classes.root}>
				<Grid
					item
					md={12}
					xs={12}
					style={{
						border: "4px solid #ccc",
						borderRadius: 10,
						padding: 10,
						height: "calc(100vh - 300px)",
						minHeight: "calc(100vh - 300px)",
						margin: "20px 0 10px",
					}}
					className={"ag-theme-balham"}
				>
					<ConfirmationDialogRaw
						title="Confirmation"
						message={messageDeleteModal}
						open={showDeleteModal}
						onClose={deleteCasterProcess}
					/>
					<ConfirmationDialogRaw
						title="Confirmation"
						message={messageActiveModal}
						open={showActiveModal}
						onClose={activeCasterProcess}
					/>
					<ConfirmationDialogRaw
						title="Confirmation"
						message={messageUnlinkModal}
						open={showUnlinkModal}
						onClose={unlinkCasterProcess}
					/>
					<UpsertCasterProcess
						show={showEditModal}
						onHide={() => setShowEditModal(false)}
						refreshData={refreshData}
					/>
					<LinkCasterProcess
						show={showLinkModal}
						onHide={async () => {
							setShowLinkModal(false);
							setNotificationMessage("Process linked successfully");
							await refreshData();
						}}
						process={selectedProcess}
					/>

					<AgGridReact
						ref={gridRef}
						rowSelection="single"
						pagination={false}
						animateRows={true}
						rowHeight={35}
						headerHeight={35}
						singleClickEdit={true}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={rows}
						columnDefs={columns}
						columnTypes={columnTypes}
						defaultColDef={columnSimpleDefaults}
						getRowStyle={getRowStyle}
						suppressContextMenu={true}
						onRowDoubleClicked={(event: any) => {
							if (event.data.ProcessId) goToDataEntry(event.data.ProcessId);
						}}
						onCellContextMenu={(event) => {
							event.api.deselectAll();
							event.node.setSelected(true);
							setSelectedProcess(event?.data);

							if (event.event)
								showContextMenu(
									event.event as any,
									event.data,
									"casterProcesses",
								);
						}}
					/>
					<Grid container justifyContent="flex-end">
						<Grid item md={2} xs={12} className={classes.btnModal}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								style={{ margin: "2rem 0px 0px" }}
								onClick={() => createData()}
							>
								NEW PROCESS
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
