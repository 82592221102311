import {
	MasterDetailPanel,
	SimpleTextControl,
	dxtToLocalServerTime,
} from "@dexteel/mesf-core";
import { getMomentTz } from "@dexteel/mesf-core";
import { Dialog, DialogContent } from "@material-ui/core";
const moment = getMomentTz();
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { DateControl } from "../../../controls/InputControls";
import { DefaultPanel, LongFilterPanel } from "../../../controls/PanelControls";
import { ItemOption } from "../../../controls/autoComplete";
import { HeatNavigation } from "../../../controls/heatNavigation";
import {
	DataTypeField,
	ReportColumn,
	ReportTable,
} from "../../../controls/reportTable";
import { useHeatReconciliationContext } from "../context/HeatReconciliationContext";
import { HeatResult } from "../models/Catalogs";
import { HeatReconciliation } from "../models/catalogs/heatReconciliation";
import {
	getProcessesList,
	saveHeatReconciliation,
	searchHeatName,
} from "../repositories/ProductionRepository";

type Props = {};

export const HeatReconciliationPage = (props: Props) => {
	const convertDateToString = (value: any, format = "") => {
		return dxtToLocalServerTime(value, format || "MM-dd-yyyy");
	};
	const rowClick = (data: any) => {
		setSelectedHeatReconciliation({
			ProcessId: data.ProcessId,
			LadleOpen: data.LadleOpen,
			HeatName: data.HeatName,
			ProductionYear: data.ProductionYear,
			Tundish: data.Tundish,
			HeatCountTundish: data.HeatCountTundish,
		});
	};

	const { state, actions } = useHeatReconciliationContext();
	const [dataSource, setDataSource] = React.useState<any[]>([]);
	const [date, setDate] = useState<Date | null>(null);
	const [selectedHeatReconciliation, setSelectedHeatReconciliation] =
		useState<HeatReconciliation | null>(null);
	const columns: ReportColumn[] = [
		{
			HeaderName: "Ladle Open",
			Field: "LadleOpen",
			Type: DataTypeField.DateTime,
			Width: "100",
			Format: "MM/dd/yyyy HH:mm",
			CellStyle: "",
		},
		{
			HeaderName: "Heat Number",
			Field: "HeatName",
			Type: DataTypeField.String,
			Width: "100",
			Format: "",
			CellStyle: "",
		},
		{
			HeaderName: "Year",
			Field: "ProductionYear",
			Type: DataTypeField.Number,
			Width: "100",
			Format: "0",
			CellStyle: "",
		},
		{
			HeaderName: "Tundish",
			Field: "TundishNumber",
			Type: DataTypeField.Number,
			Width: "100",
			Format: "0",
			CellStyle: "",
		},
		{
			HeaderName: "Tundish Heats",
			Field: "HeatCountTundish",
			Type: DataTypeField.Number,
			Width: "100",
			Format: "0",
			CellStyle: "",
		},
	];
	const resetFilters = () => {
		setSelectedHeatReconciliation(null);
		setDate(null);
		actions.setHeatResults([]);
	};
	const cancelEdit = () => {
		setSelectedHeatReconciliation(null);
	};
	const editHeatReconciliation = () => {
		(async () => {
			if (selectedHeatReconciliation !== null) {
				const resp = await saveHeatReconciliation(selectedHeatReconciliation);
				if (resp.ok) {
					actions.setSuccess({ message: "The values were changed." });
					await fetchProcessesList();
				} else {
					setSelectedHeatReconciliation(null);
					actions.setError(resp.error);
				}
			}
		})();
	};
	const closeMessage = () => {
		setTimeout(() => {
			actions.setSuccess(undefined);
		}, 2000);
	};
	const fetchProcessesList = () => {
		(async () => {
			setSelectedHeatReconciliation(null);
			let heatName = "";
			if (state.selectedHeat !== null) heatName = state.selectedHeat.HeatName;
			actions.setShowLoading(true);
			const resp = await getProcessesList(heatName, date);
			actions.setShowLoading(false);
			if (resp.data !== null || true) {
				setDataSource(resp.data.rows || []);
			}
		})();
	};
	useEffect(() => {
		(async () => {
			if (state.searchHeatName !== "" && state.searchHeatName !== null) {
				const resp = await searchHeatName(state.searchHeatName);
				if (resp.ok) {
					if (resp.data !== null || true) {
						actions.setHeatResults(
							resp.data.heats == null ? [] : resp.data.heats,
						);
					}
				}
			} else {
				actions.setHeatResults([]);
			}
		})();
	}, [state.searchHeatName]);
	useEffect(() => {
		const itemList: ItemOption[] = [];
		state.heatResults.forEach((heat) => {
			const itemOption: ItemOption = {
				id: heat.HeatId.toString(),
				name: heat.HeatName,
			};
			itemList.push(itemOption);
		});
		actions.setHeatItems(itemList);
	}, [state.heatResults]);
	useEffect(() => {
		let heat: HeatResult | null = null;
		if (state.selectedOption !== null) {
			const id = false ? 0 : state.selectedOption.id;
			const searchedHeat = state.heatResults.find(
				(x) => x.HeatId === Number(id),
			);
			heat = searchedHeat === undefined ? null : searchedHeat;
		}
		actions.setSelectedHeat(heat);
	}, [state.selectedOption]);
	useEffect(() => {
		if (state.selectedHeat !== null) setDate(null);
	}, [state.selectedHeat]);
	useEffect(() => {
		if (date !== null) actions.setHeatResults([]);
	}, [date]);
	useEffect(() => {
		if (state.success !== undefined) {
			closeMessage();
		}
	}, [state.success]); // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<>
			<Alert
				show={state.error !== undefined}
				variant="danger"
				dismissible={true}
				onClose={() => {
					actions.setError(undefined);
				}}
			>
				<strong>There was an error</strong>
				<p>
					{" "}
					{state.error === undefined
						? "There was an error"
						: state.error.internalError.message === undefined
							? state.error.internalError
							: state.error.internalError.message}
				</p>
			</Alert>
			<Alert show={state.success !== undefined} variant="success">
				<p>
					{state.success === undefined
						? "Operation was success"
						: state.success.message || "Operation was success"}
				</p>
			</Alert>
			<DefaultPanel title={"Heat Reconciliation"}>
				<LongFilterPanel
					showButtons={true}
					onClickReset={() => {
						resetFilters();
					}}
					OnClickGo={async () => {
						await fetchProcessesList();
					}}
					showExport={false}
				>
					<div className="col-md-2 ">
						<DateControl
							value={date}
							onChange={(date: Date) => {
								setDate(date);
							}}
							title={"Date"}
							styleGeneral={{ style: "flex" }}
						/>
					</div>
					<div className="col-md-6 col-lg-6 col-xl-4">
						<HeatNavigation
							onTextChanged={actions.setSearchHeatName}
							onValueChanged={actions.setSelectedOption}
							heats={state.heatOptions}
							onNextClicked={actions.nextHeat}
							onPreviousClicked={actions.previousHeat}
							defaultText={""}
						/>
					</div>
				</LongFilterPanel>
				<div className="row">
					<div className="col-md-6 col-lg-6 col-xl-6">
						<ReportTable
							name={"tblTemperatures"}
							columns={columns}
							rows={dataSource}
							onRowClick={rowClick}
						/>
					</div>
				</div>
				<Dialog
					open={selectedHeatReconciliation !== null}
					onClose={() => setSelectedHeatReconciliation(null)}
					aria-labelledby="form-dialog-title"
				>
					<DialogContent>
						{selectedHeatReconciliation !== null && (
							<MasterDetailPanel
								title={
									"Ladle Open: " +
									convertDateToString(
										selectedHeatReconciliation.LadleOpen,
										"MM/dd/yyyy HH:mm",
									)
								}
								saveStatus={true}
								showDelete={false}
								showSave={true}
								deleteStatus={false}
								onCancel={cancelEdit}
								onSave={editHeatReconciliation}
								onDelete={() => {}}
							>
								<div className="row">
									<div className="col-md-12 px-3 py-1">
										<SimpleTextControl
											title="Heat Number"
											styleLabel={{ minWidth: "150px" }}
											onChange={(value: string) => {
												setSelectedHeatReconciliation({
													...selectedHeatReconciliation,
													HeatName: value,
												});
											}}
											name="heatNumber"
											value={selectedHeatReconciliation.HeatName}
										></SimpleTextControl>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12 px-3 py-1">
										<SimpleTextControl
											title="Production Year"
											styleLabel={{ minWidth: "150px" }}
											onChange={(value: string) => {
												setSelectedHeatReconciliation({
													...selectedHeatReconciliation,
													ProductionYear: Number(value),
												});
											}}
											name="productionYear"
											value={selectedHeatReconciliation.ProductionYear}
										></SimpleTextControl>
									</div>
								</div>
							</MasterDetailPanel>
						)}
					</DialogContent>
				</Dialog>
			</DefaultPanel>
		</>
	);
};
