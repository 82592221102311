import { getMomentTz } from "@dexteel/mesf-core";
import { Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { Pause, PlayArrow } from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";
import UnLinkIcon from "@material-ui/icons/LinkOff";
import RestoreIcon from "@material-ui/icons/Restore";
import { useState } from "react";
import { ButtonWithLoading } from "../../../../controls/buttons/ButtonWithLoading";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { SimpleDialogRaw } from "../../../../controls/modals/SimpleDialogRaw";
import { getTimeFormatFromUTC } from "../../../../utils/date-time-formatter";
import { LinkCasterProcess } from "../../caster-processes/components/LinkCasterProcess";
import { ProcessData } from "../../models/ProcessData";
import { useCastersContext } from "../CastersContext";
import { OpenAndCloseButtons } from "./buttons/OpenAndCloseButtons";
import { useCasterDENavigation } from "./hooks/useCasterDENavigation";
import { useFormHandlers } from "./hooks/useFormHandlers";
import { useLinkUnlinkProcesses } from "./hooks/useLinkUnlinkProcesses";
import { DeliveryTicketModal } from "./modals/delivery-ticket/DeliveryTicketModal";
import { PourbackModal } from "./modals/pourback-modal/PourbackModal";
import { useStyles } from "./styles";
import { ElementTable } from "./tables/ElementTable";
import { HousingTable } from "./tables/HousingTable";
import { LiquidTable } from "./tables/LiquidTable";
import { ProductionTable } from "./tables/ProductionTable";
import { ScrapTable } from "./tables/ScrapTable";
import { StrandTable } from "./tables/StrandTable";
import { TundishTable } from "./tables/TundishTable";
import {
	CheckboxFieldCaster,
	DateTimeFieldCaster,
	DropdownFieldCaster,
	ProcessNavigatorField,
	TextFieldCaster,
} from "./ui/FieldsCaster";
import { ProcessNavigatorWithSearch } from "./ui/ProcessNavigatorWithSearch";

const moment = getMomentTz();

type Props = {
	reloadProcess: Function;
	calculateProcess: Function;
	refreshData: Function;
};
export const MainPanelCaster = ({
	reloadProcess,
	calculateProcess,
	refreshData,
}: Props) => {
	const classes = useStyles();
	const { state, actions } = useCastersContext();
	const process = state.processData || ({} as ProcessData);
	const pourBacks = state.pourBacks || [];
	const tundishShrouds = state.tundishShrouds || [];
	const ladles = state.ladles || [];
	const tundishes = state.tundishes || [];
	const tonnageReasons = state.tonnageReasons || [];

	const [deliveryTicketModalOpen, setDeliveryTicketModalOpen] = useState(false);
	const [pourbackModalOpen, setPourbackModalOpen] = useState(false);
	//Gets
	const getStatusDescription = (status: string | null) => {
		if (status === null) return "";
		if (status === "S") return "Scheduled";
		if (status === "M") return "Melting";
		if (status === "T") return "Tapped";
		if (status === "O") return "Opened";
		if (status === "C") return "Closed";
		if (status === "R") return "Returned";
		if (status === "F") return "Finished";
		return "";
	};
	const getStatusClass = (status: string | null) => {
		if (status === null) return classes.labelNormalClass;
		if (status === "S") return classes.labelScheduledClass;
		if (status === "M") return classes.labelMeltingClass;
		if (status === "T") return classes.labelTappedClass;
		if (status === "O") return classes.labelOpenedClass;
		if (status === "C") return classes.labelClosedClass;
		if (status === "R") return classes.labelReturnedClass;
		if (status === "F") return classes.labelFinishedClass;
		return classes.labelNormalClass;
	};
	const getShiftFormat = (
		shift: string,
		productionDate: Date,
		crew: string,
	) => {
		if (productionDate === null) return `${shift} ${crew}`;
		return `${moment(productionDate).format("MM/DD/yyyy")} ${shift} ${crew}`;
	};

	const {
		onProcessClicked,
		unlinkCasterProcess,
		showChangeModal,
		showLinkModal,
		showUnlinkModal,
		setShowChangeModal,
		setShowLinkModal,
	} = useLinkUnlinkProcesses({ refreshData });
	const {
		handleOnNextProcess,
		handleOnPreviousProcess,
		handleOnLastProcess,
		handleGoToProcessesByShiftId,
		handleGoToProcess,
		handleGotToHeatReport,
	} = useCasterDENavigation({ reloadProcess });

	const {
		handleBooleanValueChanged,
		handleOptionValueChanged,
		handleLadleValueChanged,
		handleNumericValueChanged,
		handleOnBlurTextValue,
		handleOnBlurNumericValueForProcess,
		handleOnBlurNumericValueForHeat,
		handleBooleanCountersChanged,
		handleOnBlurBooleanValueForHeat,
		handleProcessUpdate,
		handleOnFocus,
		handleValueChanged,
	} = useFormHandlers({
		reloadProcess,
		refreshData,
	});

	return (
		<>
			{/* Title */}
			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					style={{ padding: "5px 10px" }}
				>
					<Grid item md={10} xs={8}>
						<Typography variant="h5" style={{ fontWeight: 600 }}>
							Caster
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			{/* HEAT */}
			<Grid item container md={12} xs={12} style={{ paddingBottom: "3rem" }}>
				<Grid
					item
					container
					justifyContent="center"
					alignItems="flex-start"
					spacing={3}
					style={{ padding: "5px 10px" }}
				>
					{/* Process Navigator */}
					<Grid item container xs={12} sm={6} md={4} lg={2}>
						<Grid item>
							<ProcessNavigatorWithSearch
								isProcessLoading={
									state.isLoadingPage || state.isLoadingBackground
								}
								value={process}
								secondValue={process.ProductionYear?.toString()}
								iconButton={
									process.HeatId ? (
										<UnLinkIcon style={{ cursor: "pointer" }} />
									) : (
										<LinkIcon style={{ cursor: "pointer" }} color="primary" />
									)
								}
								showNext={process.NextProcessId !== null}
								showPrevious={process.PrevProcessId !== null}
								onChange={(newProcessId) => handleGoToProcess(newProcessId)}
								onNext={handleOnNextProcess}
								onPrevious={handleOnPreviousProcess}
								onLast={handleOnLastProcess}
								onFocus={() => {
									actions.setRefreshInPause(true);
								}}
								onBlur={() => {
									actions.clearFocusField();
									actions.setRefreshInPause(false);
								}}
								onButtonClick={onProcessClicked}
							/>
						</Grid>
						<OpenAndCloseButtons refreshData={refreshData} process={process} />
					</Grid>
					{/* Shift - Process */}
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<TextFieldCaster
							title={"Shift"}
							readonly
							value={getShiftFormat(
								process.Shift || "",
								process.ProductionDate,
								process.Crew || "",
							)}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalButtonClass}
							classInput={classes.inputNormalClass}
							styleLabel={{ width: "100px", maxWidth: "120px" }}
							styleTextBox={{ height: "30px" }}
							field="Shift"
						/>

						<DateTimeFieldCaster
							title={"Ladle Open"}
							readonly={false}
							value={
								process.LadleOpen
									? moment.utc(process.LadleOpen).toDate()
									: null
							}
							onChange={handleValueChanged}
							onBlur={handleProcessUpdate}
							onFocus={handleOnFocus}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalForDatesClass}
							classInput={classes.inputNormalClass}
							field="LadleOpen"
						/>

						<DateTimeFieldCaster
							title={"Ladle Close"}
							readonly={false}
							value={
								process.LadleClose
									? moment.utc(process.LadleClose).toDate()
									: null
							}
							onChange={handleValueChanged}
							onBlur={handleProcessUpdate}
							onFocus={handleOnFocus}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalForDatesClass}
							classInput={classes.inputNormalClass}
							field="LadleClose"
						/>

						<TextFieldCaster
							title={"Duration"}
							readonly
							value={
								process.Duration === null
									? ""
									: (process.Duration / 60).toFixed(1)
							}
							classContainer={classes.normalItem}
							styleLabel={{ width: "100px", maxWidth: "120px" }}
							styleTextBox={{ height: "30px" }}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="Duration"
						/>
					</Grid>
					{/* Process Data */}
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<TextFieldCaster
							title={"Nozzle"}
							value={process.NozzleSize || ""}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							styleContainer={{ marginBottom: "4px" }}
							styleTextBox={{ height: "30px" }}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="NozzleSize"
							onFocus={handleOnFocus}
							onChange={handleNumericValueChanged}
							onBlur={handleOnBlurNumericValueForProcess}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "stretch",
								height: "30px", // Set a fixed height for consistency
								marginBottom: "5px", // Add some bottom margin for spacing between rows
							}}
						>
							<div
								style={{
									flexGrow: 1,
									marginRight: "10px",
									display: "flex",
									alignItems: "center",
								}}
							>
								<TextFieldCaster
									title={"Heats on Nozzle"}
									value={process.HeatsOnNozzle?.toString() || ""}
									readonly
									classContainer={classes.normalItem}
									styleLabel={{ width: "150px", maxWidth: "150px" }}
									styleTextBox={{ height: "30px" }}
									classLabel={classes.labelNormalClass}
									classInput={classes.inputNormalClass}
									field="HeatsOnNozzle"
								/>
							</div>
							<div
								style={{
									width: "20%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CheckboxFieldCaster
									value={""}
									checked={!!process.ForceHeatsOnNozzle || false}
									classInput={classes.checkBoxItem}
									classLabel={classes.labelCheckboxClass}
									classCheckBoxGroup={classes.groupCheckBoxItem}
									styleLabel={{ display: "none" }}
									styleTextBox={{ height: "30px" }}
									field="ForceHeatsOnNozzle"
									onFocus={handleOnFocus}
									onChange={handleBooleanCountersChanged}
								/>
							</div>
						</div>

						{process.HeatStatusCode === null ? (
							<></>
						) : (
							<TextFieldCaster
								title={getStatusDescription(process.HeatStatusCode)}
								classLabel={getStatusClass(process.HeatStatusCode)}
								readonly
								value={""}
								styleContainer={{ padding: "1px 0px", marginTop: "3px" }}
								styleLabel={{
									width: "100px",
									maxWidth: "120px",
									borderTopLeftRadius: "0!important",
									borderBottomLeftRadius: "0!important",
								}}
								styleTextBox={{ height: "30px" }}
								classInput={getStatusClass(process.HeatStatusCode)}
								field="HeatStatusCode"
							/>
						)}
					</Grid>
					{/* Heat */}
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<TextFieldCaster
							title={"Tap Time"}
							readonly
							value={getTimeFormatFromUTC(process.TapTime, "HH:mm")}
							classContainer={classes.normalItem}
							styleLabel={{ width: "120px", maxWidth: "120px" }}
							styleTextBox={{ height: "30px" }}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="TapTime"
						/>
						<TextFieldCaster
							title={"Steel Weight"}
							value={process.SteelWeight?.toString() || ""}
							styleLabel={{ width: "120px", maxWidth: "120px" }}
							styleTextBox={{ textAlign: "right", height: "30px" }}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="SteelWeight"
							onFocus={handleOnFocus}
							onChange={handleNumericValueChanged}
							onBlur={handleOnBlurNumericValueForHeat}
							readonly={process.HeatId ? false : true}
						/>
						<DropdownFieldCaster
							title={"Ladle Name"}
							dataSource={ladles.map(({ LadleId, LadleName }) => ({
								value: LadleId,
								label: LadleName,
							}))}
							value=""
							selectedValue={process.LadleId || 0}
							styleLabel={{ width: "120px", maxWidth: "150px", height: "30px" }}
							styleInput={{ height: "30px" }}
							styleContainer={{ height: "30px", marginBottom: "5px" }}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="LadleId"
							onFocus={handleOnFocus}
							onChange={handleLadleValueChanged}
							readonly={process.HeatId ? false : true}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "space-between",
								height: "40px", // Set a fixed height for consistency
								marginBottom: "10px", // Add some bottom margin for spacing between rows
							}}
						>
							<div
								style={{
									flexGrow: 3,
									display: "flex",
									alignItems: "center",
								}}
								key={`HeatsOnLadle-${process.ProcessId}-${process.LadleId}-process`}
							>
								<TextFieldCaster
									title={"Ladle Life"}
									value={process.HeatsOnLadle?.toString() || "0"}
									readonly
									classContainer={classes.normalItem}
									styleLabel={{ width: "120px", maxWidth: "120px" }}
									styleTextBox={{ height: "30px" }}
									classLabel={classes.labelNormalClass}
									classInput={classes.inputNormalClass}
									field="HeatsOnLadle"
								/>
							</div>
							<div
								style={{
									flexGrow: 1,
									display: "flex",
									alignItems: "center",
								}}
							>
								<TextFieldCaster
									title={""}
									value={process.ContactTimeOnLadle?.toString() || "0"}
									readonly
									classContainer={classes.normalItem}
									styleLabel={{ display: "none" }}
									styleTextBox={{ height: "30px" }}
									styleContainer={{ maxWidth: "110px" }}
									classLabel={classes.labelNormalClass}
									classInput={classes.inputNormalClass}
									field="ContactTimeOnLadle"
								/>
							</div>
						</div>
					</Grid>
					{/* Actions */}
					<Grid
						item
						container
						xs={12}
						sm={6}
						md={4}
						lg={2}
						spacing={1}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleGoToProcessesByShiftId(process.ShiftId)}
								className={classes.headerButton}
							>
								PROCESSES
							</Button>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleGotToHeatReport(process.HeatName)}
								className={classes.headerButton}
							>
								HEAT REPORT
							</Button>
						</Grid>
					</Grid>
					{/* Refresh */}
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={2}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							padding: "3.5rem 0",
						}}
					>
						<ButtonGroup>
							<ButtonWithLoading
								variant="contained"
								color="primary"
								onClick={() => calculateProcess()}
								className={classes.headerButton}
								isLoading={state.isLoadingBackground}
								style={{
									textTransform: "none",
									minWidth: "220px",
									maxWidth: "220px",
								}}
							>
								{state.entityEditting !== null ? (
									<span>REFRESH</span>
								) : (
									<>
										<span>REFRESHING</span>
										<span style={{ margin: "0 8px" }}>
											{"- " + state.refreshStatus + "s "}
										</span>
									</>
								)}
								<span>
									<RestoreIcon fontSize="small" />
								</span>
							</ButtonWithLoading>
							<Button
								onClick={() =>
									actions.setRefreshInPause((prev: boolean) => !prev)
								}
								variant={"outlined"}
							>
								{state.refreshInPause ? <PlayArrow /> : <Pause />}
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</Grid>
			{/* Strand - Trim */}
			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="center"
					alignItems="flex-start"
					spacing={3}
					style={{ padding: "5px 10px" }}
				>
					{/* Housing - Liquid - Tundish */}
					<Grid item xs={12} md={4} lg={4}>
						<HousingTable />
						<div style={{ padding: "10px 0px" }}>
							<LiquidTable refreshData={refreshData} />
						</div>
						<div
							style={{ padding: "0px" }}
							key={`Tundish-temperature-${process.ProcessId}-process`}
						>
							<TundishTable refreshData={refreshData} />
						</div>
					</Grid>
					{/* Tundish */}
					<Grid item xs={12} md={4} lg={2}>
						<TextFieldCaster
							title={"Foreman"}
							value={process.Foreman || ""}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="Foreman"
							onFocus={handleOnFocus}
							onChange={handleValueChanged}
							onBlur={handleOnBlurTextValue}
						/>

						<CheckboxFieldCaster
							title={"Did Ladle Open"}
							value=""
							checked={process.IsFreeOpen || false}
							classContainer={classes.normalItem}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							classLabel={classes.labelNormalClass}
							classInput={classes.checkBoxItem}
							classCheckBoxGroup={classes.groupCheckBoxItem}
							field="IsFreeOpen"
							onFocus={handleOnFocus}
							onChange={handleBooleanValueChanged}
						/>
						<CheckboxFieldCaster
							title={"Burn"}
							value={""}
							checked={process.IsBurnOpen || false}
							classContainer={classes.normalItem}
							classInput={classes.checkBoxItem}
							classLabel={classes.labelNormalClass}
							classCheckBoxGroup={classes.groupCheckBoxItem}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							field="IsBurnOpen"
							onFocus={handleOnFocus}
							onChange={handleBooleanValueChanged}
						/>
						<CheckboxFieldCaster
							title={"First Heat"}
							value={""}
							checked={process.IsFirstHeat || false}
							classContainer={classes.normalItem}
							classInput={classes.checkBoxItem}
							classLabel={classes.labelNormalClass}
							classCheckBoxGroup={classes.groupCheckBoxItem}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							field="IsFirstHeat"
							onFocus={handleOnFocus}
							onChange={handleBooleanValueChanged}
						/>
						{/* Tundish section */}
						<DropdownFieldCaster
							title={"Tundish"}
							dataSource={tundishes.map(({ TundishId, TundishName }) => ({
								value: TundishId,
								label: TundishName,
							}))}
							value=""
							selectedValue={process.TundishId || 0}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="TundishId"
							onFocus={handleOnFocus}
							onChange={handleOptionValueChanged}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "stretch",
								height: "40px", // Set a fixed height for consistency
								marginBottom: "10px", // Add some bottom margin for spacing between rows
							}}
						>
							<div
								style={{
									flexGrow: 1,
									marginRight: "10px",
									display: "flex",
									alignItems: "center",
								}}
							>
								<TextFieldCaster
									title={"Heats on Tundish"}
									value={process.HeatsOnTundish?.toString() || ""}
									readonly
									classContainer={classes.normalItem}
									styleLabel={{ width: "150px", maxWidth: "150px" }}
									classLabel={classes.labelNormalClass}
									classInput={classes.inputNormalClass}
									field="HeatsOnTundish"
								/>
							</div>
							<div
								style={{
									width: "20%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CheckboxFieldCaster
									value={""}
									checked={!!process.ForceHeatsOnTundish || false}
									classInput={classes.checkBoxItem}
									classLabel={classes.labelCheckboxClass}
									classCheckBoxGroup={classes.groupCheckBoxItem}
									styleLabel={{ display: "none" }}
									field="ForceHeatsOnTundish"
									onFocus={handleOnFocus}
									onChange={handleBooleanCountersChanged}
								/>
							</div>
						</div>
						{/* Tundish Shroud section */}
						<DropdownFieldCaster
							title={"Tundish Shroud"}
							dataSource={tundishShrouds.map(
								({ TundishShroudId, TundishShroudName }) => ({
									value: TundishShroudId,
									label: TundishShroudName,
								}),
							)}
							value=""
							selectedValue={process.TundishShroudId || 0}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="TundishShroudId"
							onFocus={handleOnFocus}
							onChange={handleOptionValueChanged}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "stretch",
								height: "40px", // Set a fixed height for consistency
								marginBottom: "10px", // Add some bottom margin for spacing between rows
							}}
						>
							<div
								style={{
									flexGrow: 1,
									marginRight: "10px",
									display: "flex",
									alignItems: "center",
								}}
							>
								<TextFieldCaster
									title={"Heats on Tundish Shroud"}
									value={process.HeatsOnTundishShroud?.toString() || ""}
									readonly
									classContainer={classes.normalItem}
									styleLabel={{ width: "150px", maxWidth: "150px" }}
									classLabel={classes.labelNormalClass}
									classInput={classes.inputNormalClass}
									field="HeatsOnTundishShroud"
								/>
							</div>
							<div
								style={{
									width: "20%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CheckboxFieldCaster
									value={""}
									checked={!!process.ForceHeatsOnTundishShroud || false}
									classInput={classes.checkBoxItem}
									classLabel={classes.labelCheckboxClass}
									classCheckBoxGroup={classes.groupCheckBoxItem}
									styleLabel={{ display: "none" }}
									field="ForceHeatsOnTundishShroud"
									onFocus={handleOnFocus}
									onChange={handleBooleanCountersChanged}
								/>
							</div>
						</div>
						<Grid
							item
							container
							justifyContent="center"
							style={{ margin: "10px 0px" }}
						>
							<TextFieldCaster
								title={"Pourback Weight [tons]"}
								value={process.PourbackWeight?.toString() || ""}
								styleLabel={{ width: "150px", maxWidth: "150px" }}
								styleTextBox={{ textAlign: "right" }}
								classContainer={classes.normalItem}
								classLabel={classes.labelNormalClass}
								classInput={classes.inputNormalClass}
								field="PourbackWeight"
								onFocus={handleOnFocus}
								onChange={handleNumericValueChanged}
								onBlur={handleOnBlurNumericValueForProcess}
								readonly
							/>
							<Button
								variant="contained"
								color="primary"
								size="small"
								disabled={process.HeatId ? false : true}
								style={{ width: "100%" }}
								onClick={() => {
									setPourbackModalOpen(true);
									actions.setRefreshInPause(true);
								}}
							>
								Declare Pourback
							</Button>
						</Grid>
						<DropdownFieldCaster
							title={"Low Tonnage Reason"}
							value={""}
							selectedValue={process.LowTonnageReasonId || 0}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							dataSource={tonnageReasons.map(
								({ LowTonnageReasonId, LowTonnageReasonName }) => ({
									value: LowTonnageReasonId,
									label: LowTonnageReasonName,
								}),
							)}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							field="LowTonnageReasonId"
							onFocus={handleOnFocus}
							onChange={handleOptionValueChanged}
						/>
					</Grid>
					{/* Third Column */}
					<Grid
						item
						xs={12}
						md={4}
						lg={2}
						key={`${process.ProcessId}-Production-table`}
					>
						<div>
							<ProductionTable
								onUpdate={() => {
									refreshData();
								}}
								readonly={process.HeatId ? false : true}
							/>
						</div>
						<div
							style={{ paddingTop: "20px" }}
							key={`${process.ProcessId}-Scrap-table`}
						>
							<ScrapTable />
						</div>
						<div
							style={{
								paddingTop: "20px",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								style={{ width: "100%" }}
								onClick={() => {
									setDeliveryTicketModalOpen(true);
									actions.setRefreshInPause(true);
								}}
							>
								Delivery Ticket
							</Button>
						</div>
					</Grid>
					{/* Fourth Column */}
					<Grid item xs={12} md={8} lg={4}>
						<div key={`${process.ProcessId}-Strand-table`}>
							<StrandTable refreshData={refreshData} />
						</div>
						<div
							style={{ paddingTop: "20px" }}
							key={`${process.ProcessId}-Element-table`}
						>
							<ElementTable />
						</div>
					</Grid>
				</Grid>
			</Grid>
			{/* Comments */}
			<Grid item md={12} xs={12}>
				<Grid
					container
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={1}
					style={{ padding: "5px 10px" }}
				>
					<Grid item md={12} xs={12} style={{ paddingBottom: "5px" }}>
						<TextFieldCaster
							title={"Comments"}
							value={process.Comments || ""}
							classContainer={classes.normalItem}
							classLabel={classes.labelNormalClass}
							classInput={classes.inputNormalClass}
							styleLabel={{ width: "150px", maxWidth: "150px" }}
							field="Comments"
							onFocus={handleOnFocus}
							onChange={handleValueChanged}
							onBlur={handleOnBlurTextValue}
						/>
					</Grid>
				</Grid>
			</Grid>

			{/* MODALS */}
			<SimpleDialogRaw
				title="Change Modal"
				message={""}
				open={showChangeModal}
				onClose={() => setShowChangeModal(false)}
			/>
			{/* Pourback Modal */}
			<PourbackModal
				show={pourbackModalOpen}
				onHide={(shouldUpdate) => {
					setPourbackModalOpen(false);
					if (shouldUpdate) refreshData();
				}}
				process={process}
			/>
			<DeliveryTicketModal
				show={deliveryTicketModalOpen}
				onHide={(shouldUpdate) => {
					setDeliveryTicketModalOpen(false);
				}}
				processId={process.ProcessId}
			/>
			<ConfirmationDialogRaw
				title="Confirmation"
				message={showUnlinkModal.message}
				open={showUnlinkModal.show}
				onClose={unlinkCasterProcess}
			/>
			<LinkCasterProcess
				show={showLinkModal}
				onHide={(shouldUpdate) => {
					setShowLinkModal(false);
					if (shouldUpdate) reloadProcess();
				}}
				process={process as any}
			/>
		</>
	);
};
