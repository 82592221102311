import { ContextMenuMESFProvider } from "@dexteel/mesf-core";
import { CategoriesProvider } from "../categories/context/CategoriesContext";
import { ConfigurationDelayCodeProvider } from "../codes/context/ConfigurationDelayCodeContext";
import { DelaysParetoPage } from "./DelaysParetoPage";
import { DelaysParetoProvider } from "./context/DelaysParetoContext";

type Props = {};
const DelaysPareto = (props: Props) => {
	return (
		<ContextMenuMESFProvider>
			<ConfigurationDelayCodeProvider>
				<CategoriesProvider>
					<DelaysParetoProvider>
						<DelaysParetoPage />
					</DelaysParetoProvider>
				</CategoriesProvider>
			</ConfigurationDelayCodeProvider>
		</ContextMenuMESFProvider>
	);
};
export default DelaysPareto;
