import { Suspense } from "react";
import * as React from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import ChemistryByHeat from "../../pages/laboratory/chemistry/byHeat";
import L3Chemistry from "../../pages/laboratory/l3/chemistry";
import L3Mechanical from "../../pages/laboratory/l3/mechanical";
import MechanicalByHeat from "../../pages/laboratory/mechanical/byHeat";

export const LabRoutes = [
	<Route
		path="/lab-chemistry-by-heat"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<ChemistryByHeat />
			</Suspense>
		}
	>
		{" "}
	</Route>,
	<Route
		path="/lab-chemistry-by-heat/:heatName/:valid"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<ChemistryByHeat />
			</Suspense>
		}
	>
		{" "}
	</Route>,
	<Route
		path="/lab-mechanical-by-heat"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<MechanicalByHeat />
			</Suspense>
		}
	/>,
	<Route
		path="/lab-mechanical-by-heat/:heatName/:valid"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<MechanicalByHeat />
			</Suspense>
		}
	/>,
	<Route
		path="/lab-l3/chemistry"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				{" "}
				<L3Chemistry />
			</Suspense>
		}
	/>,
	<Route
		path="/lab-l3/chemistry/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				{" "}
				<L3Chemistry />
			</Suspense>
		}
	/>,
	<Route
		path="/lab-l3/mechanical"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				{" "}
				<L3Mechanical />
			</Suspense>
		}
	/>,
	<Route
		path="/lab-l3/mechanical/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				{" "}
				<L3Mechanical />
			</Suspense>
		}
	/>,
];
