import { ResponseMESF, useUTLSettingsContext } from "@dexteel/mesf-core";
import { Box, Paper } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import { useState } from "react";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useTimerContext } from "../../../context/timer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { readProbesFromPLC } from "../../../repositories/eaf.repositories";
import { CreateProbe } from "../../modals/probe/CreateProbe";
import { EditProbe } from "../../modals/probe/EditProbe";
import { AgGridFooterActions } from "../components/AgGridFooterActions";
import { useEAFDETablesStyles } from "../styles/useEAFDETablesStyles";
import { useTableData } from "./hooks/useProbesTableData";

export const TableProbes = () => {
	const { state } = useUTLSettingsContext();
	const MAX_PROBES = get(state.settings, "MAX_PROBES", 8);

	const {
		state: { deProcess },
		actions: { setDEProcess, setNotificationMessage, setErrorMessage },
	} = useDataEntryContext();

	const { saveProbe, deleteProbe, refreshData } = useEAFDataEntryActions();

	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedProbe, setSelectedProbe] = useState<any>();

	const { pause, resume } = useTimerContext();
	const probes = deProcess?.probes;
	const rowCount = probes ? probes.length : 0;
	const classes = useEAFDETablesStyles({ rowCount });
	const hasMaxProbes = (probes && probes.length >= MAX_PROBES) || false;

	const editProbe = (probeId: number) => {
		pause();
		const probe = probes?.find((p) => p.ProbeId === probeId);
		setSelectedProbe(probe);
		setShowEditModal(true);
	};
	const readFromPLC = async () => {
		const ProcessId = deProcess?.process?.ProcessId;

		if (ProcessId) {
			const resp = await readProbesFromPLC({ ProcessId });
			if (resp.ok) {
				await refreshData();
			} else {
				setErrorMessage(resp.message);
			}
			return resp;
		} else {
			setNotificationMessage("Process not found");
			return { ok: false, message: "Process not found" } as ResponseMESF;
		}
	};

	const { columns, defaultColDef } = useTableData({
		deleteProbe,
		editProbe,
	});

	return (
		<>
			<Paper className={classes.root} elevation={0}>
				<Box
					className={`ag-theme-balham ${classes.agGridBox} ${classes.dynamicHeight}`}
				>
					<AgGridReact
						rowSelection="single"
						suppressRowClickSelection={true}
						pagination={false}
						rowHeight={50}
						headerHeight={35}
						singleClickEdit={false}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={probes}
						columnDefs={columns}
						defaultColDef={defaultColDef}
						domLayout="normal"
					/>
					<AgGridFooterActions
						hasMaxItems={hasMaxProbes}
						showCreateModal={showCreateModal}
						setShowCreateModal={setShowCreateModal}
						readFromPLC={readFromPLC}
						table="probes"
					/>
				</Box>
			</Paper>

			<CreateProbe
				show={showCreateModal}
				onHide={() => {
					resume();
					setShowCreateModal(false);
				}}
				date={deProcess ? deProcess.process.Start : new Date()}
			/>
			<EditProbe
				show={showEditModal}
				onHide={() => {
					resume();
					setShowEditModal(false);
				}}
				probe={selectedProbe}
			/>
		</>
	);
};
