import { ContextMenuMESFProvider } from "@dexteel/mesf-core";
import { BucketProvider } from "./CatalogBucketContext";
import { BucketPage } from "./CatalogBucketPage";

type Props = {};
const CatalogBuckets = (props: Props) => {
	return (
		<ContextMenuMESFProvider>
			<BucketProvider>
				<BucketPage />
			</BucketProvider>
		</ContextMenuMESFProvider>
	);
};
export default CatalogBuckets;
