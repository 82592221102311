interface Props {
	letter: "M" | "V" | "A" | "C" | "H";

	top?: string;
	bottom?: string;
	left?: string;
	right?: string;
	height?: string;
	width?: string;

	color?: string;
	disabled?: boolean;

	iconButtonClass?: string;

	onClick?: () => void;
}

export const LetterButton = ({
	letter,
	top,
	bottom,
	left,
	right,
	height,
	width,
	color,
	disabled,
	iconButtonClass,
	onClick = () => {},
}: Props) => {
	return (
		<div
			onClick={onClick}
			style={{
				position: "absolute",
				top,
				bottom,
				left,
				right,
				margin: "auto",
				height: "1rem",
				width: "1rem",
			}}
		>
			<button
				className={iconButtonClass}
				disabled={disabled}
				style={{
					backgroundColor: disabled ? "grey" : color,
					height,
					width,
					border: "1px solid black",
				}}
			>
				{letter}
			</button>
		</div>
	);
};
