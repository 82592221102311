import { useCastersContext } from "../../CastersContext";
import { useSaveStrand } from "../../hooks/useSaveStrand";
import { useStyles } from "../styles";
import {
	CheckBoxFieldTable,
	InputFieldTable,
	ListBoxFieldTable,
} from "../ui/FieldTable";
import { MoldAutocomplete } from "../ui/MoldAutocomplete";

export const HousingTable = () => {
	const { state, actions } = useCastersContext();
	const classes = useStyles();
	const strands = state.strands || [];
	const housings = state.housings || [];
	const moldSizes = state.moldSizes || [];
	const { save } = useSaveStrand();

	//Events
	const onHandleFocus = (strandIndex: number, field: string) =>
		actions.setFocusField({ entity: "STRAND", index: strandIndex, field });
	const onHandleChange = (strandIndex: number, field: string, value: any) => {
		actions.updateStrandData({
			index: strandIndex,
			field,
			value,
			clear: true,
			forced: true,
		});
		save(strandIndex, field, value);
	};
	return (
		<>
			<table cellPadding={0} cellSpacing={0}>
				<thead>
					<tr>
						<td style={{ width: "150px" }}></td>
						{strands?.map((strand, index) => (
							<td
								key={index}
								className={classes.simpleCellHeader}
								style={{
									width: "110px",
									borderTopRightRadius:
										index === strands.length - 1 ? "0.25rem" : "0",
								}}
							>
								S{index + 1}
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{/* Housing */}
					<tr>
						<td className={classes.simpleCellHeader}>Housing</td>

						{strands?.map((strand, index) => (
							<td key={index}>
								<ListBoxFieldTable
									value={strand.HousingId || 0}
									options={housings?.map((option) => ({
										id: option.HousingId,
										name: option.HousingName,
									}))}
									classListBox={classes.inputNormalClass}
									styleListBox={{ borderRadius: "0" }}
									onChange={(value) =>
										onHandleChange(
											index,
											"HousingId",
											value === "" ? null : Number(value).valueOf(),
										)
									}
									onFocus={() => onHandleFocus(index, "HousingId")}
								/>
							</td>
						))}
					</tr>
					{/* Housing First */}
					<tr>
						<td className={classes.simpleCellHeader}>Housing First</td>
						{strands?.map((strand, index) => (
							<td key={index}>
								<div
									className={classes.simpleCellCheckBox}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CheckBoxFieldTable
										checked={!!strand.ForceHeatsOnHousing}
										title="Housing First"
										onFocus={() => onHandleFocus(index, "ForceHeatsOnHousing")}
										onChange={(value) =>
											onHandleChange(
												index,
												"ForceHeatsOnHousing",
												value ? 1 : null,
											)
										}
									/>
								</div>
							</td>
						))}
					</tr>
					{/* Housing Heats */}
					<tr>
						<td className={classes.simpleCellHeader}>Housing Heats</td>
						{strands?.map((strand, index) => (
							<td key={index}>
								<InputFieldTable
									classInput={classes.simpleCellText}
									readOnly={true}
									value={strand.HeatsOnHousing || ""}
								/>
							</td>
						))}
					</tr>
					{/* MoldId */}
					<tr>
						<td className={classes.simpleCellHeader}>MoldId</td>

						{strands?.map((strand, index) => (
							<td key={index}>
								<MoldAutocomplete
									value={strand.MoldId || 0}
									options={moldSizes?.map((option) => ({
										id: option.MoldId,
										name: option.MoldName + " - " + option.MoldSizeName,
									}))}
									onFocus={() => onHandleFocus(index, "MoldId")}
									onChange={(value) =>
										onHandleChange(
											index,
											"MoldId",
											value === "" ? null : Number(value).valueOf(),
										)
									}
								/>
							</td>
						))}
					</tr>
					{/* Mold Size */}
					<tr>
						<td
							className={classes.simpleCellHeader}
							style={{ borderBottomLeftRadius: "0.25rem" }}
						>
							Size
						</td>
						{strands?.map((strand, index) => (
							<td key={index}>
								<InputFieldTable
									classInput={classes.simpleCellText}
									readOnly={true}
									value={strand.MoldSizeName || ""}
								/>
							</td>
						))}
					</tr>
					{/* Mold First */}
					<tr>
						<td className={classes.simpleCellHeader}>Mold First</td>
						{strands?.map((strand, index) => (
							<td key={index}>
								<div
									className={classes.simpleCellCheckBox}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CheckBoxFieldTable
										checked={!!strand.ForceHeatsOnMold}
										title="Mold First"
										onFocus={() => onHandleFocus(index, "ForceHeatsOnMold")}
										onChange={(value) =>
											onHandleChange(
												index,
												"ForceHeatsOnMold",
												value ? 1 : null,
											)
										}
									/>
								</div>
							</td>
						))}
					</tr>
					{/* Mold Heats */}
					<tr>
						<td className={classes.simpleCellHeader}>Mold Heats</td>
						{strands?.map((strand, index) => (
							<td key={index}>
								<InputFieldTable
									classInput={classes.simpleCellText}
									styleTextBox={{ borderBottomRightRadius: "0.25rem" }}
									readOnly={true}
									value={strand.HeatsOnMold || ""}
								/>
							</td>
						))}
					</tr>
				</tbody>
			</table>
		</>
	);
};
