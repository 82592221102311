import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getHeatsByShift = async (
	shift: number | null,
	HeatId: number | string | null,
	showExternalsHeats?: boolean | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ShiftId", value: shift });
	parameters.push({ name: "HeatId", value: parseInt(HeatId as string) });
	parameters.push({
		name: "IncludeIsExternal",
		value: showExternalsHeats || null,
	});
	return await apiService.callV2("[SWV].[GetHeatsByShift]", parameters);
};
export const getLastestHeats = async (showExternalsHeats: boolean) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "IsExternal", value: showExternalsHeats });

	return await apiService.callV2("[MS].[GetLastestHeats]", parameters);
};
export const searchHeats = async (searchTerm: string, IsExternal: boolean) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "SearchTerm", value: searchTerm },
		{ name: "MaxResults", value: 100 },
		{ name: "IsExternal", value: IsExternal },
	];

	return await apiService.callV2("[MS].[SearchHeats]", parameters);
};

export const getGrades = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	const resp = await apiService.call("[STEEL].[GetGrades]", parameters);

	if (resp.errorMessage !== null)
		return { ok: false, message: resp.errorMessage };
	return {
		ok: true,
		data: resp,
	};
};

export const getLadles = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	const resp = await apiService.call("[MS].[GetLadles]", parameters);

	if (resp.errorMessage !== null)
		return { ok: false, message: resp.errorMessage };
	return {
		ok: true,
		data: resp,
	};
};

export const upsertHeat = async (params: any) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	Object.keys(params).forEach((key) =>
		parameters.push({ name: key, value: params[key] }),
	);

	const resp = await apiService.callV2("[MS].[UpsertHeat]", parameters);

	return resp;
};

export const deleteHeat = async (heatId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "HeatId", value: heatId });
	const resp = await apiService.callV2("[MS].[DeleteHeat]", parameters);

	return resp;
};
