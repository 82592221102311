import { Checkbox, InputAdornment, TextField } from "@material-ui/core";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useTimerContext } from "../../../context/timer.provider";
import { useStyles } from "../../../styles/useDEStyles";

interface TextFieldDEProps {
	textFieldValue: number | null;
	checkboxValue: boolean | null;
	label?: string;
	textFieldDisabled?: boolean;
	checkboxDisabled?: boolean;
	onNumberFieldChange: (value: number) => void;
	onCheckboxChange: (value: boolean) => void;
	onBlur: () => void;
}

export const NumberWithCheckboxFieldDE = ({
	textFieldValue,
	checkboxValue,
	label,
	onNumberFieldChange,
	onCheckboxChange,
	onBlur,
	textFieldDisabled = true,
	checkboxDisabled = false,
}: TextFieldDEProps) => {
	const classes = useStyles();
	const {
		state: { isSubmitLoading },
	} = useDataEntryContext();
	const { pause, resume } = useTimerContext();
	const onFocus = () => {
		pause();
	};

	return (
		<>
			<TextField
				fullWidth
				disabled={textFieldDisabled}
				label={label}
				variant={"outlined"}
				value={textFieldValue || ""}
				type={"number"}
				onFocus={onFocus}
				onChange={(e) => {
					if (
						e.target.value === "" ||
						/^(0|[1-9]\d*)(\.\d*)?$/.test(e.target.value)
					)
						onNumberFieldChange(Number(e.target.value));
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						resume();
						onBlur();
					}
				}}
				onBlur={() => {
					resume();
					onBlur();
				}}
				style={{
					minWidth: "50px",
				}}
				InputProps={{
					classes: { input: classes.input },
					endAdornment: (
						<InputAdornment position="end" className={classes.inputAdornment}>
							<Checkbox
								color="primary"
								size="small"
								disabled={checkboxDisabled || isSubmitLoading}
								className={classes.checkbox}
								checked={!!checkboxValue}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									onCheckboxChange(e.target.checked);
								}}
							/>
						</InputAdornment>
					),
				}}
			/>
		</>
	);
};
