import { makeStyles } from "@material-ui/core";
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDelaysReportContext } from "../../context/DelaysReportContext";

const useStyles = makeStyles(() => ({
	formControl: {
		minWidth: "100%",
	},
	select: {
		height: 40,
		paddingTop: 5,
		backgroundColor: "#FFF",
		"& .MuiOutlinedInput-input": {
			paddingTop: 10,
			paddingBottom: 10,
		},
	},
}));

type Props = {
	filterCrew: string;
	onFilterCrewChange: (value: string) => void;
};

export const CrewsFilter: React.FC<Props> = ({
	filterCrew,
	onFilterCrewChange,
}) => {
	const classes = useStyles();
	const {
		state: { crewsList },
	} = useDelaysReportContext();

	useEffect(() => {
		if (!filterCrew && crewsList.length > 0) {
			onFilterCrewChange("All");
		}
	}, [crewsList, filterCrew, onFilterCrewChange]);

	const handleFilter = (e: SelectChangeEvent<string>) => {
		onFilterCrewChange(e.target.value);
	};

	return (
		<FormControl variant="outlined" className={classes.formControl}>
			<InputLabel id="crew-select-label">Crew</InputLabel>
			<Select
				labelId="crew-select-label"
				id="crew-select"
				value={filterCrew}
				onChange={handleFilter}
				label="Crew"
				className={classes.select}
			>
				{crewsList.map((crew: any) => (
					<MenuItem key={`crew-${crew.Crew}`} value={crew.Crew}>
						{crew.Crew}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
