import { TextField } from "@material-ui/core";
import { Ref } from "react";
import { useTimerContext } from "../../../context/timer.provider";

interface TextFieldDEProps {
	value: number | null;
	label?: string;
	disabled?: boolean;
	onChange: (value: number) => void;
	onBlur: () => void;
	inputRef?: Ref<any | undefined>;
}

export const NumberTextFieldDE = ({
	value,
	label,
	onChange,
	onBlur,
	disabled = false,
}: TextFieldDEProps) => {
	const { pause, resume } = useTimerContext();
	const onFocus = () => {
		pause();
	};

	return (
		<TextField
			fullWidth
			disabled={disabled}
			label={label}
			variant={"outlined"}
			value={value || ""}
			type={"number"}
			onFocus={onFocus}
			onChange={(e) => {
				if (
					e.target.value === "" ||
					/^(0|[1-9]\d*)(\.\d?)?$/.test(e.target.value)
				)
					onChange(Number(e.target.value));
			}}
			onKeyDown={(e) => {
				if (e.key.toLowerCase() === "e" || e.key === "-" || e.key === "+") {
					e.preventDefault();
				}
				if (e.key === "Enter") {
					resume();
					onBlur();
				}
			}}
			onBlur={() => {
				resume();
				onBlur();
			}}
			style={{
				minWidth: "50px",
			}}
		/>
	);
};
