import { TimeService, getMomentTz } from "@dexteel/mesf-core";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";
import { useTimerContext } from "../../../context/timer.provider";

interface DateTimeDE {
	value: Date | null;
	format?: string;
	label?: string;
	disabled?: boolean;
	disableOpenPicker?: boolean;
	onChange: (value: Date | null) => void;
	onBlur: (value: Date | null) => void;
	error?: string;
}

const moment = getMomentTz();

export const DateTimePickerDE = ({
	value,
	format,
	label,
	onChange,
	onBlur,
	disabled = false,
	disableOpenPicker = false,
	error = "",
}: DateTimeDE) => {
	const additionalProps: { [key: string]: any } = {};
	if (format) additionalProps["format"] = format;

	const { pause, resume } = useTimerContext();
	const onFocus = () => {
		pause();
	};

	return (
		<DateTimePicker
			disableFuture
			disableOpenPicker={disableOpenPicker}
			disabled={disabled}
			timezone={TimeService.getInstance().getServerTimeZone()}
			label={label}
			value={moment(value)}
			onChange={(value: Moment | null) => {
				// if (value && value.isValid()) {
				onChange(value?.toDate() || null);
				// }
			}}
			slotProps={{
				textField: {
					variant: "outlined",
					fullWidth: true,
					onFocus,
					onBlur: () => {
						resume();
						onBlur(value || null);
					},
					error: !!error,
					helperText: error,
					inputProps: {
						style: {
							minWidth: "60px",
							maxWidth: "100%",
						},
					},
				},
			}}
			{...additionalProps}
		/>
	);
};
