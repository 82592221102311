import { TextField } from "@material-ui/core";
import React from "react";

interface TextFieldDEProps {
	value: number | null;
	label?: string;
	unit: "seconds" | "minutes" | "hours";
	disabled?: boolean;
	onChange: (value: number) => void;
	onBlur?: () => void;
}

const formatTime = (
	value: number | null,
	unit: "seconds" | "minutes" | "hours",
) => {
	if (value === null) return "";

	let totalSeconds: number;
	switch (unit) {
		case "hours":
			totalSeconds = value * 3600;
			break;
		case "minutes":
			totalSeconds = value * 60;
			break;
		case "seconds":
			totalSeconds = value;
			break;
		default:
			return "";
	}

	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = Math.round(totalSeconds % 60);

	return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const DurationFieldDE: React.FC<TextFieldDEProps> = ({
	value,
	label,
	onChange,
	onBlur,
	disabled = false,
	unit,
}) => {
	const displayValue = formatTime(value, unit);

	return (
		<TextField
			fullWidth
			disabled={disabled}
			label={label}
			variant="outlined"
			value={displayValue}
			type="text"
			InputProps={{
				readOnly: true,
			}}
			style={{
				minWidth: "50px",
			}}
		/>
	);
};
