import { ColDef } from "ag-grid-enterprise";
import { BooleanCellRenderer } from "../../../../../controls/ag-grid/components/ButtonCellRenderer";

type Props = {};
export const useTableData = ({}: Props) => {
	const columns: ColDef[] = [
		{
			field: "Heat",
			headerName: "Heat",
			flex: 1,
			minWidth: 52,
			maxWidth: 100,
		},
		{
			field: "Sample",
			headerName: "Sample",
			flex: 1,
			minWidth: 70,
			maxWidth: 100,
		},
		{
			field: "Grade",
			headerName: "Grade",
			flex: 1,
			minWidth: 70,
			maxWidth: 100,
		},
		{
			field: "LabDateTime",
			headerName: "Date",
			flex: 1,
			minWidth: 100,
			maxWidth: 150,
		},
		{
			field: "Technician",
			headerName: "Technician",
			flex: 1,
			minWidth: 97,
			maxWidth: 150,
		},
		{
			field: "ChecksPassed",
			headerName: "Check",
			flex: 1,
			minWidth: 80,
			maxWidth: 150,
			cellRenderer: BooleanCellRenderer,
		},
		{
			field: "ChecksResults",
			headerName: "Results",
			flex: 1,
			minWidth: 200,
		},
		{
			field: "Sent",
			headerName: "Sent",
			flex: 1,
			minWidth: 80,
			maxWidth: 150,
			cellRenderer: BooleanCellRenderer,
		},
		{
			field: "Ack",
			headerName: "Ack by L3",
			flex: 1,
			minWidth: 80,
			maxWidth: 150,
			cellRenderer: BooleanCellRenderer,
		},
		{
			field: "Name",
			headerName: "Force",
			type: ["nonSorteable", "nonFilterable", "sendButton"],
			flex: 1,
			minWidth: 80,
			maxWidth: 80,
		},
		{
			field: "SentBy",
			headerName: "Sent by",
			flex: 1,
			minWidth: 80,
			maxWidth: 80,
		},
	];

	return { columns };
};
