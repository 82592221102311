import { default as React, ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { L3MechanicalReducer as L3MechanicalReducer } from "./L3MechanicalReducer";

const L3MechanicalContext = createContext({
	state: L3MechanicalReducer.getInitialState(),
	actions: L3MechanicalReducer.actions,
});

export const useL3MechanicalContext = () => useContext(L3MechanicalContext);

export const L3MechanicalProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: L3MechanicalReducer.getInitialState(),
		reducers: L3MechanicalReducer.caseReducers,
	});
	return (
		<L3MechanicalContext.Provider value={{ state, actions }}>
			{children}
		</L3MechanicalContext.Provider>
	);
};
