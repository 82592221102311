import { createSlice } from "@reduxjs/toolkit";
import { ItemOption } from "../../../../controls/autoComplete";
import { Heat } from "../../../meltshop/models/Heat";
import { ResultSearchHeatName } from "../../../meltshop/models/ResultSearchHeatName";
import { CatalogGradeChemistryLimit } from "../../models/CatalogGradeChemistryLimit";
import { CatalogSampleType } from "../../models/CatalogSampleType";
import { ChemistrySample } from "../../models/ChemistrySample";

const ChemistryByHeatInitialState: {
	isLoadingPage: boolean;
	isLoadingBackground: boolean;
	isLoadingGrid: boolean;
	showOnlyValid: boolean;
	searchHeatNameText: string | null;
	resultHeats: ResultSearchHeatName[] | null;
	heatOptions: ItemOption[];
	heat: Heat | null;
	grades: CatalogGradeChemistryLimit[] | null;
	chemistrySamples: ChemistrySample[] | null;
	sampleTypes: CatalogSampleType[] | null;
} = {
	isLoadingPage: false,
	isLoadingBackground: false,
	isLoadingGrid: false,
	searchHeatNameText: null,
	resultHeats: null,
	heatOptions: [],
	heat: null,
	grades: null,
	chemistrySamples: null,
	sampleTypes: null,
	showOnlyValid: true,
};

export const ChemistryByHeatReducer = createSlice({
	name: "__",
	initialState: ChemistryByHeatInitialState,
	reducers: {
		setShowOnlyValid(state, { payload }) {
			state.showOnlyValid = payload;
		},
		setSearchHeatNameText(state, { payload }) {
			state.searchHeatNameText = payload;
		},
		setResultHeats(state, { payload }) {
			state.resultHeats = payload;
		},
		setHeatOptions(state, { payload }) {
			state.heatOptions = payload;
		},
		setHeat(state, { payload }) {
			state.heat = payload;
		},
		setGrades(state, { payload }) {
			state.grades = payload;
		},
		setChemistrySamples(state, { payload }) {
			state.chemistrySamples = payload;
		},
		setSampleTypes(state, { payload }) {
			state.sampleTypes = payload;
		},
		setIsLoadingPage(state, { payload }) {
			state.isLoadingPage = payload;
		},
		setIsLoadingBackground(state, { payload }) {
			state.isLoadingBackground = payload;
		},
		setIsLoadingGrid(state, { payload }) {
			state.isLoadingGrid = payload;
		},
	},
});
