import { GenericReportPage } from "./GenericReportPage";

import { useParams } from "react-router-dom";
import { GenericReportProvider } from "./GenericReportContext";

type Props = {};
const GenericReport = (props: Props) => {
	const { reportCode } = useParams();
	return (
		<GenericReportProvider key={reportCode}>
			<GenericReportPage />
		</GenericReportProvider>
	);
};
export default GenericReport;
