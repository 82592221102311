import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import React from "react";

const StyledTextField = styled(TextField)({
	"& .MuiInputBase-input": {
		textAlign: "center",
		padding: "8px 14px", // Adjusted padding to match EndFilter
	},
	"& .MuiInputBase-root": {
		width: "100%",
		height: "40px",
	},
	"& .MuiInputLabel-root": {
		transform: "translate(14px, 9px) scale(1)", // Adjusted label position
	},
	"& .MuiInputLabel-shrink": {
		transform: "translate(14px, -6px) scale(0.75)",
	},
});

type Props = {
	value: Moment;
	onChange: (date: Moment | null) => void;
};

export const StartFilter: React.FC<Props> = ({ value, onChange }) => {
	return (
		<DatePicker
			label="Start"
			format="MM/DD/YYYY"
			value={value}
			onChange={(newValue) => {
				onChange(newValue);
			}}
			slots={{
				textField: (params) => <StyledTextField {...params} />,
			}}
		/>
	);
};
